import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";


export default makeStyles(theme => {
    return ({
        container: {
            height: 40,
            width: 100,
        },
        textField: {
            height: 'inherit',
            width: 'inherit',
            display: 'flex',
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0
            },
            '& input': {
                backgroundColor: theme.palette.background.component,
                border: 'none',
                color: '#fff',
                paddingLeft: 5,
                mozAppearance: 'textfield',
                outline: 'none',
                '&:invalid': {
                    width: 100,
                },
                '&:valid': {
                    width: 80,
                },
                '&:focus': {
                    backgroundColor: theme.palette.background.paper,
                }
            },
            '& svg': {
                height: 20,
                width: 20,
            },
            '& button': {
                // display: 'none'
            },
        },
        loading: {
            '& .MuiCircularProgress': {
                width: 30,
                height: 30,
                margin: 0,
                padding:0
            }
        }
    })
})