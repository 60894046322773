import {snackBar as types} from '.';

export const triggerHideSnackbarMessage = () => ({
    type: types.SNACKBAR_MESSAGE,
    payload: { message: '' },
});

let timer;
export const triggerSnackbarMessage = ({ message, timeout = 4000 }) => dispatch => {
    dispatch({
        type: types.SNACKBAR_MESSAGE,
        payload: { message },
    });
    clearTimeout(timer);
    timer = setTimeout(() => dispatch(triggerHideSnackbarMessage()), timeout);
};
