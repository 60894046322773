export const dashboardDisplayOptions = {
    ALL_PLATFORMS: 'ALL_PLATFORMS',
    PROTECTED_PLATFORMS: 'PROTECTED_PLATFORMS',
    UNPROTECTED_PLATFORMS: 'MONITORED_PLATFORMS',
};

export const threatGroups = {
    BAD_BOT_ACTIVITY: 'Invalid Bot Activity',
    MALICIOUS_ACTIVITY: 'Invalid Malicious Activity',
    SUSPICIOUS_ACTIVITY: 'Invalid Suspicious Activity',
    CUSTOM_RULES: 'Custom Rules',
};
