import React from 'react';
import Chip from '@material-ui/core/Chip/Chip';
import ClearIcon from '@material-ui/icons/Clear';

// const theme = createMuiTheme({});

const clearIcon = <ClearIcon style={{ color: '#fe0072' }} />;

export default ({ label, onClick = console.log, size = 'small', color = 'primary', variant, onDelete = console.log, style, ...props }) => {
    return (
        <Chip
            label={label}
            style={{ backgroundColor: '#14172c', margin: '1px', fontFamily: 'inherit', color: '#fff' }}
            size={size}
            onDelete={() => onDelete(label)}
            deleteIcon={clearIcon}
            color={color}
            onClick={onClick}
            variant={variant}
            {...props}
        />
    );
};
