import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../../ui/checkbox';
import AdvancedSettings from './components/advanced';
import Select from '../../../ui/filters-list/components/select';
import styles from './settings.module.scss';
import { Filter } from './filter';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import { useSplit, useGetFilters } from '@hooks';
import { constants } from '@utils/split.io';
import Progress from '../../../../../widgets/progress';
const defaultFilters = [
    { title: 'Tags', value: 'tags' },
    { title: 'URL', value: 'urls' },
    { title: 'Campaigns', value: 'campaigns' },
];

const allPlatforms = { title: '', value: 'allPlatforms' };
const referrerVal = 'referrer';
const mediumVal = 'medium';
const campaignVal = 'campaigns';
const campaignTypeVal = 'campaignType';
const sourceVal = 'source';
const platformVal = 'platforms';

const defaultPlatform = 'default';
const Settings = ({ cm, state, updateState, onChangeHandler, channels }) => {
    const customFiltersDefault = [
        { title: 'Referrer', value: referrerVal, enabled: state.referrer?.length > 0 },
        { title: 'Medium', value: mediumVal, enabled: state.medium?.length > 0 },
        { title: 'CampaignType', value: campaignTypeVal, enabled: state.campaignType?.length > 0 },
        { title: 'Source', value: sourceVal, enabled: state.source?.length > 0 },
    ];
    const { type, ivt, paid, reportType } = state;

    const isDefault = () => {
        return !(state.medium.length > 0 || state.referrer?.length > 0 || state.campaignType?.length > 0 || state.source?.length > 0);
    };
    const [alignment, setAlignment] = React.useState(isDefault() ? defaultPlatform : 'custom');
    const [customFilters, setCustomFilters] = useState(customFiltersDefault);
    const [isExtended, setExtended] = useState(false);
    const { isOn: settingsMis } = useSplit(constants.PARADOME_MIS_SETTING);
    const { isFetching: isLoading, data: filtersResult = [] } = useGetFilters(settingsMis && isExtended);
    const { selectedLicense } = useSelector(state => state.settings);

    const misLicense = selectedLicense === 3;

    const filters = filtersResult.data;

    const isMisData = settingsMis && !isLoading && filters;

    useEffect(() => {
        if (filters?.[campaignVal]) {
            const mappedCampaigns = state?.[campaignVal].map(campaign => campaign.value);
            const selectedCampaigns = filters?.[campaignVal].filter(campaign => {
                if (mappedCampaigns.includes(campaign.value)) {
                    return campaign;
                }
            });

            updateState(prevState => ({
                ...prevState,
                campaigns: selectedCampaigns,
            }));
        }
    }, [JSON.stringify(filters)]);

    const onCheckIvt = () => {
        updateState(prevState => ({
            ...prevState,
            ivt: !ivt,
        }));
    };

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const onSelectChannel = item => {
        const selectedIds = state.channels.map(item => item.value);
        if (selectedIds.filter(id => id === item.value).length !== 0) {
            const selectedItems = state.channels.filter(row => row.value !== item.value);
            updateState(prevState => ({
                ...prevState,
                channels: selectedItems,
            }));
        } else {
            updateState(prevState => ({
                ...prevState,
                channels: [...prevState.channels, item],
            }));
        }
    };

    const onSelectFilter = (results, item) => {
        const selectedIds = state[item.value].map(filterItem => {
            return filterItem.value;
        });
        if (selectedIds.filter(id => id === item.value).length !== 0) {
            const selectedItems = state[item.value].filter(row => row.value !== item.value);
            updateState(prevState => ({
                ...prevState,
                [item.value]: selectedItems,
            }));
        } else {
            updateState(prevState => {
                return {
                    ...prevState,
                    [item.value]: results,
                };
            });
        }
    };

    const getExtendedIcon = () => {
        return <>{isExtended ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</>;
    };

    const mappedCustomFilters = alignment === defaultPlatform ? [] : customFilters.filter(item => item.enabled);

    return (
        <div className={styles.container} data-test-selector="exportables-create-report-settings">
            {settingsMis && misLicense && (
                <div
                    onClick={() => {
                        setExtended(!isExtended);
                    }}
                    className={styles.label}
                >
                    {getExtendedIcon()}
                    {cm.get('Filters')}
                </div>
            )}
            {isLoading && settingsMis && isExtended && (
                <Progress
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginBottom: '20px',
                    }}
                />
            )}

            {((isExtended && isMisData) || !settingsMis) && (
                <div className={styles.options}>
                    {(type === 'standard' || type === 'extended') && (
                        <div>
                            <AdvancedSettings onChangeHandler={onChangeHandler} cm={cm} paid={paid} reportType={reportType} />

                            <div className={styles.row}>
                                <Checkbox checked={ivt} onChange={onCheckIvt} label={cm.get('ShowOnlyInvalidTraffic')} selector="exportables-create-report-settings-ivt" />
                            </div>
                        </div>
                    )}
                    {!settingsMis && (
                        <div className={styles.row} style={{ flexFlow: 'column' }}>
                            <div className={styles.label}>Channels</div>
                            <Select
                                cm={cm}
                                type="channels"
                                onSelect={onSelectChannel}
                                selected={state.channels}
                                options={channels}
                                selector="exportables-create-report-settings-channels"
                            />
                        </div>
                    )}
                    {isMisData &&
                        defaultFilters.map(item => {
                            return (
                                <div className={styles.row} key={item.label} style={{ flexFlow: 'column' }}>
                                    <div key={item.label} className={styles.label}>
                                        {cm.get(item.title)}
                                    </div>
                                    <Filter
                                        defaultFilter={filters[item.value]}
                                        currFilter={[item.value]}
                                        selectedFilters={state[item.value]}
                                        handleChange={results => {
                                            onSelectFilter(results, item);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    {isMisData && (
                        <div className={styles.platforms}>
                            <span className={styles.platformsTitle}>Platforms</span>
                            <span></span>
                            <div className={styles.toggle}>
                                <ToggleButtonGroup color="primary" className={styles.toggle} value={alignment} exclusive onChange={handleChange} aria-label="Platform">
                                    <ToggleButton value="default">Default</ToggleButton>
                                    <ToggleButton value="custom">Custom</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>
                    )}
                    {isMisData && defaultPlatform !== alignment && (
                        <div className={styles.checkboxContainer}>
                            {customFilters.map(filter => {
                                return (
                                    <Checkbox
                                        className={styles.checkbox}
                                        key={filter.title}
                                        label={cm.get(filter.title)}
                                        checked={filter.enabled}
                                        onChange={() => {
                                            const res = customFilters.map(currFilter =>
                                                currFilter.value !== filter.value ? currFilter : { ...currFilter, enabled: !filter.enabled }
                                            );
                                            state[filter.value] = [];
                                            setCustomFilters(res);
                                        }}
                                        selector={filter.title}
                                        disabled={false}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {isMisData && defaultPlatform === alignment && (
                        <Filter
                            key={allPlatforms.value}
                            defaultFilter={filters[platformVal]}
                            currFilter={[allPlatforms.title.value]}
                            selectedFilters={state[allPlatforms.value]}
                            handleChange={results => {
                                onSelectFilter(results, allPlatforms);
                            }}
                        />
                    )}
                    {isMisData &&
                        defaultPlatform !== alignment &&
                        mappedCustomFilters.map(item => {
                            return (
                                <>
                                    <div key={item.title} className={styles.title}>
                                        {cm.get(item.title)}
                                    </div>
                                    <Filter
                                        key={item.value}
                                        defaultFilter={filters[item.value]}
                                        currFilter={[item.value]}
                                        selectedFilters={state[item.value]}
                                        handleChange={results => {
                                            onSelectFilter(results, item);
                                        }}
                                    />
                                </>
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default Settings;
