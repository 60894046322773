import { setParams, handleOnProgress, methodsWithBody, methods } from './helpers';
import { errors } from './errors';

function httpRequest({ baseURL = '', method = 'post', pathname = '', body = {}, onProgress = ({}) => '', params = {}, headers = {}, withCredentials = false }) {
    const xhr = new XMLHttpRequest();

    const response = new Promise((resolve, reject) => {
        const url = new URL(pathname, baseURL || httpRequest.baseURL);

        method = method.toLowerCase();

        // SET PARAMS
        setParams(params, url);

        // SET WITH CREDENTAILS
        xhr.withCredentials = withCredentials || httpRequest.withCredentials;

        xhr.open(method, url, true);

        // SET HEADERS
        const token = localStorage.getItem('auth0Token');
        const defaultHeaders = { ...httpRequest.defaultHeaders[methods.all], ...httpRequest.defaultHeaders[method], Authorization: `Bearer ${token}` };
        Object.entries({ ...defaultHeaders, ...headers }).forEach(([key, value]) => {
            xhr.setRequestHeader(key, value);
        });

        // SET BODY
        if (methodsWithBody[method]) {
            if (!(body instanceof FormData)) {
                body = JSON.stringify(body);
            }
        } else {
            body = '';
        }

        // ON LOAD
        xhr.onload = function () {
            if (xhr.status < 300 && xhr.status >= 200) {
                let res;
                try {
                    res = JSON.parse(xhr.response);
                } catch {
                    res = xhr.response;
                }
                resolve(res);
            }
            // ON ERROR LOAD
            else {
                let responseText;
                try {
                    responseText = JSON.parse(xhr.responseText).message;
                } catch {
                    responseText = xhr.statusText;
                }
                reject({ message: responseText, code: xhr.status });
            }
        };

        // ON ERROR
        xhr.onerror = function () {
            reject({ code: xhr.status, message: xhr.statusText });
        };

        // ON PROGRESSS
        xhr.onprogress = function (e) {
            const { loaded, contentLength } = handleOnProgress(e);
            onProgress({ loaded, contentLength });
        };

        // SEND REQUEST
        xhr.send(body);
    });

    const abort = () => xhr.abort();

    return { response, abort };
}

httpRequest.baseURL = '';

httpRequest.withCredentials = false;

httpRequest.defaultHeaders = {
    delete: {},
    put: {},
    patch: {},
    post: {},
    get: {},
    all: {},
};

httpRequest.setDefaultHeader = ({ method = methods.all, key, value }) => {
    method = method.toLowerCase();
    if (!Object.values(methods).includes(method)) throw errors.defaultHeader;

    httpRequest.defaultHeaders[method] = {
        ...httpRequest.defaultHeaders[method],
        [key]: value,
    };
};

httpRequest.removeDeafultHeader = ({ method = methods.all, key }) => {
    method = method.toLowerCase();
    if (!Object.keys(methods).includes(method)) throw errors.defaultHeader;

    delete httpRequest.defaultHeaders[method][key];
};

httpRequest.bind(this, httpRequest);

Object.seal(httpRequest.defaultHeaders);

Object.seal(httpRequest);

export { httpRequest };
