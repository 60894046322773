import { Table as MuiTable, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Tooltip from '@components/widgets/tooltip';
import { mapObject, values } from 'underscore';

import NoData from '../no-data/index';
import Pagination from '../pagination/index';
import Progress from '../progress/index';
import SearchBox from '../search-box/index';
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel';
import { camelToSnake } from '@combotag/public-common/dist/libs/utils';
import moment from 'moment';

const invertDirection = {
    asc: 'desc',
    desc: 'asc',
};

const Table = ({
    name,
    className,
    columns = [],
    width = '100%',
    search = 'footer',
    headCellPadding = 'normal',
    bodyCellPadding = 'normal',
    rows = [],
    RenderHead = ({ column }) => <div>{column.label}</div>,
    RenderBody = ({ value }) => <div>{value}</div>,
    pagination = true,
    isLastPageByDefault = false,
    lineaCount = true,
    stickyHeader = true,
    style = {},
    sortable = true,
    Actions = [],
    size = 'medium',
    alignHead = 'left',
    alignBody = 'left',
    buildColumns,
    getOrderBy = _getOrderBy,
    justifyHeaderContent = 'center',
    totalRow,
    error = undefined,
    isLoading = false,
    RenderEmpty = props => <NoData {...props} />,
    isError = '',
    RenderError = props => <div style={{ color: 'red' }}>something went wrong...</div>,
    showHeadersOnEmpty = false,
    rowsPerPage = 10,
    reset = false,
    cellsTooltip = false,
    cm,
    ...rest
}) => {
    const classes = makeStyles(() => style)();

    const _columns = columns.map(parseColumn);

    const [page, setPage] = useState(0);
    const [sortingColumn, setSortingColumn] = useState({});
    const [sortDirection, setSortDirection] = useState('desc');
    const [filterSearch, setFilterSearch] = useState('');

    useEffect(() => {
        reset && setPage(0);
        setSortDirection('desc');
        setSortingColumn({});
    }, [reset, columns]);

    useEffect(() => {
        if (pagination && isLastPageByDefault && rows.length > rowsPerPage) setPage(Math.ceil(rows.length / rowsPerPage) - 1);
    }, [isLastPageByDefault, pagination, rows, rowsPerPage]);

    const filterRows = rowValues =>
        filterSearch
            ? rowValues.some(value => {
                  return ![undefined, null].includes(value) && String(value).toLowerCase().includes(filterSearch.toLowerCase());
              })
            : true;

    const getDisplayRows = () => {
        const sortableRows = sortable ? rows.sort(getOrderBy(sortingColumn.id, sortDirection)) : rows;
        const searchedRows = filterSearch
            ? sortableRows.filter(row =>
                  filterRows(
                      values(
                          mapObject(row, (val, key, src) => {
                              const matchedColumn = _columns.find(({ id }) => id === key);
                              if (matchedColumn && !matchedColumn.notSearchable) {
                                  return !matchedColumn.searchTransform ? val : matchedColumn.searchTransform(val);
                              }
                          })
                      )
                  )
              )
            : sortableRows;
        return searchedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };
    const displayRows = getDisplayRows() || [];

    const handelSort = column => {
        setPage(0);
        setSortingColumn(column);
        setSortDirection(sortingColumn && sortingColumn.id === column.id ? invertDirection[sortDirection] : 'asc');
    };

    const getActions = (type, row) => {
        switch (type) {
            case 'head':
                return Actions.length === 0 ? null : (
                    <TableCell padding={headCellPadding} variant={'head'} style={style.headCell}>
                        ACTIONS
                    </TableCell>
                );
            case 'body':
                return Actions.length === 0 ? null : (
                    <TableCell style={style.bodyCell} padding={bodyCellPadding}>
                        <div style={style.actions}>{Actions.map(Action => Action(row))}</div>
                    </TableCell>
                );
            default:
                return null;
        }
    };

    const getLineCount = (type, count) => {
        if (lineaCount)
            switch (type) {
                case 'head':
                    return <TableCell padding={headCellPadding} variant={'head'} style={style.headCell} />;

                case 'body':
                    return !lineaCount ? null : (
                        <TableCell style={style.bodyCell} padding={bodyCellPadding}>
                            {count}
                        </TableCell>
                    );
                default:
                    <></>;
            }
    };

    const noResultBecauseOfSearch = filterSearch && rows.length > 0 && displayRows.length === 0;

    const getPages = () =>
        Math.ceil(
            rows.filter(row =>
                filterRows(
                    values(
                        mapObject(row, (val, key, src) => {
                            const matchedColumn = _columns.find(({ id }) => id === key);
                            if (matchedColumn && !matchedColumn.notSearchable) {
                                return !matchedColumn.searchTransform ? val : matchedColumn.searchTransform(val);
                            }
                        })
                    )
                )
            ).length / rowsPerPage
        );

    return (
        <div className={classes.root}>
            <div className={classes.tableWrapper}>
                {isLoading ? (
                    <Progress />
                ) : isError ? (
                    <RenderError />
                ) : (
                    <div className={classes.tableBody}>
                        {!showHeadersOnEmpty && rows.length === 0 ? (
                            <>{error ? <NoData children={'Something went wrong'} /> : <RenderEmpty {...rest} />}</>
                        ) : (
                            <>
                                {search === 'header' && rows.length > 0 && (
                                    <span style={{ position: 'absolute', right: 21, top: 23 }}>
                                        <SearchBox
                                            placeholder={cm.get('Search')}
                                            height={24}
                                            width={106}
                                            onChange={term => {
                                                setFilterSearch(term);
                                                setPage(0);
                                            }}
                                            value={filterSearch}
                                        />
                                    </span>
                                )}
                                <MuiTable size={size} style={style.table} className={className}>
                                    <TableHead style={style.head}>
                                        <TableRow key="head" style={{ position: 'sticky', ...style.headerRow }}>
                                            {getActions('head')}
                                            {getLineCount('head')}
                                            {_columns.map((column, idx) => {
                                                return (
                                                    <TableCell
                                                        key={idx}
                                                        padding={headCellPadding}
                                                        className={classes.head}
                                                        variant={'head'}
                                                        align={alignHead}
                                                        style={style.headerCell}
                                                        sortDirection={sortingColumn.id && sortingColumn.id === column.id ? sortDirection : false}
                                                    >
                                                        {(!showHeadersOnEmpty || rows.length !== 0) && sortable && !column.notSortable ? (
                                                            <TableSortLabel
                                                                active={sortingColumn.id && sortingColumn.id === column.id}
                                                                direction={sortDirection}
                                                                onClick={sortable ? () => handelSort(column) : () => {}}
                                                            >
                                                                {!!(!showHeadersOnEmpty || rows.length !== 0) && <RenderHead idx={idx} column={column} cm={cm} {...rest} />}
                                                                {sortingColumn.id === column.id ? (
                                                                    <span
                                                                        style={{
                                                                            border: 0,
                                                                            clip: 'rect(0 0 0 0)',
                                                                            height: 1,
                                                                            margin: -1,
                                                                            overflow: 'hidden',
                                                                            padding: 0,
                                                                            position: 'absolute',
                                                                            width: 1,
                                                                        }}
                                                                    >
                                                                        {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                    </span>
                                                                ) : null}
                                                            </TableSortLabel>
                                                        ) : (
                                                            <RenderHead idx={idx} column={column} cm={cm} {...rest} />
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!displayRows.length
                                            ? null
                                            : displayRows.map((row, idx) => (
                                                  <TableRow hover key={idx} style={!style.oddRow ? style.row : idx % 2 === 0 ? style.row : style.oddRow}>
                                                      {getActions('body', row)}

                                                      {getLineCount('body', ++idx)}

                                                      {_columns.map((column, i) => {
                                                          return (
                                                              <>
                                                                  {cellsTooltip ? (
                                                                      <Tooltip content={column.id == 'username' ? row.email : row[column.id]} key={column.id}>
                                                                          <TableCell align={alignBody} key={column.id} className={classes.bodyCell} padding={bodyCellPadding}>
                                                                              <RenderBody
                                                                                  value={row[column.id]}
                                                                                  column={column}
                                                                                  row={row}
                                                                                  filterSearch={filterSearch}
                                                                                  rowIndex={idx}
                                                                                  cm={cm}
                                                                                  {...rest}
                                                                              />
                                                                          </TableCell>
                                                                      </Tooltip>
                                                                  ) : (
                                                                      <TableCell align={alignBody} key={column.id} className={classes.bodyCell} padding={bodyCellPadding}>
                                                                          <RenderBody
                                                                              value={row[column.id]}
                                                                              column={column}
                                                                              row={row}
                                                                              filterSearch={filterSearch}
                                                                              rowIndex={idx}
                                                                              cm={cm}
                                                                              {...rest}
                                                                          />
                                                                      </TableCell>
                                                                  )}
                                                              </>
                                                          );
                                                      })}
                                                  </TableRow>
                                              ))}
                                        {!totalRow || !displayRows.length || Object.keys(totalRow)[0]?.includes('totalClicks') ? null : (
                                            <TableRow hover key={'total'} style={style.totalRow}>
                                                {_columns.map((column, i) => {
                                                    return (
                                                        <TableCell align={alignBody} key={column.id} className={classes.bodyCell} padding={bodyCellPadding}>
                                                            <RenderBody
                                                                value={i === 0 ? 'TOTAL' : totalRow[column.id]}
                                                                column={column}
                                                                row={totalRow}
                                                                filterSearch={filterSearch}
                                                                isTotal={true}
                                                            />
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </MuiTable>
                            </>
                        )}
                        {noResultBecauseOfSearch && (
                            <NoData multiLine={true} style={{ fontSize: 12, marginTop: '20%' }}>
                                <span>no results for search term</span>
                                <div style={{ fontStyle: 'italic' }}>{`'${filterSearch}'`}</div>
                            </NoData>
                        )}
                    </div>
                )}
            </div>
            {(pagination || search === 'footer') && rows.length > 0 && !isLoading && (
                <div style={{ backgroundColor: '#14172c', display: 'flex', marginTop: 'auto', alignItems: 'center', padding: '1rem' }}>
                    {!!(pagination && displayRows.length && rows.length) && (
                        <Pagination page={page} setPage={setPage} pages={getPages()} style={{ display: 'flex', alignItems: 'center' }} cm={cm} />
                    )}
                    {search === 'footer' && (displayRows.length > 0 || rows.length) ? (
                        <div style={{ ...style.search, display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                            <SearchBox
                                placeholder={cm.get('Search')}
                                onChange={term => {
                                    setFilterSearch(term);
                                    setPage(0);
                                }}
                                value={filterSearch}
                                height={24}
                                width={106}
                            />
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
};

function _getOrderBy(key, direction) {
    return (a, b) => {
        const asc = direction === 'asc';
        if (
            moment(a[key], 'YYYY-MM-DD HH:mm:ss.SSS', true).isValid() ||
            moment(a[key], 'YYYY-MM-DD HH:mm', true).isValid() ||
            moment(a[key], 'YYYY-MM-DD HH', true).isValid() ||
            moment(a[key], 'YYYY-MM-DD', true).isValid() ||
            moment(a[key], 'YYYY-MM', true).isValid()
        ) {
            return asc ? (moment(a[key]).isBefore(b[key]) ? -1 : 1) : moment(a[key]).isAfter(b[key]) ? -1 : 1;
        }
        if (!isNaN(parseFloat(a[key]))) {
            return asc ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
        }
        return asc ? (a[key] < b[key] ? -1 : 1) : a[key] > b[key] ? -1 : 1;
    };
}

function parseColumn(columnData) {
    const warn = msg => console.warn(msg + ' prepare for unexpected behaviour from ManageTable');

    const fromString = id => ({
        id,
        label: camelToSnake(id)
            .split('_')
            .map(str => str.toUpperCase())
            .join(' '),
    });

    const fromObject = object => ({
        ...fromString(object.id),
        ...object,
    });

    switch (typeof columnData) {
        case 'string':
            !columnData && warn('columnData is undefined.');
            return fromString(columnData);
        case 'object':
            !columnData.id && warn('columnData.id must be typeof string.');
            return fromObject(columnData);
        default:
            warn('columnData must be typeof string or object.');
            return columnData;
    }
}

export default Table;
