import Base from '../base';
import { pluck, uniq } from 'underscore';

const initialState = {
    heatmaps: [],
    isLoading: true,
    isError: '',
    isEnabled: true,
    isEnabledLoading: true,
    viewMode: {},
    queryInView: '',
};

export default ({ httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor() {
                    super('heatmaps');
                    this.state = initialState;
                }

                unMount() {
                    this.update({
                        heatmaps: [],
                        isLoading: true,
                        isError: '',
                        isEnabled: true,
                        isEnabledLoading: true,
                        viewMode: {},
                        queryInView: '',
                    });
                }

                closeHeatmap() {
                    this.update({
                        viewMode: {},
                        queryInView: '',
                    });
                }
            }
        ).from([
            httpRequest({
                name: 'getHeatmaps',
                type: 'post',
                preRequest: function () {
                    this.update({
                        isLoading: true,
                        isEnabledLoading: true,
                        heatmaps: [],
                        isError: '',
                    });
                },
                updateState: ({ heatmaps = [], isEnabled }) => {
                    return {
                        heatmaps,
                        isEnabled,
                        isLoading: false,
                        isEnabledLoading: false,
                        isError: '',
                    };
                },
                onError: function () {
                    this.update({
                        isLoading: false,
                        isEnabledLoading: false,
                        heatmaps: [],
                        isError: 'sorry something went wrong...',
                    });
                },
            }),
            httpRequest({
                name: 'toggleEnable',
                type: 'post',
                preRequest: function () {
                    this.update({
                        isEnabledLoading: true,
                        isError: '',
                    });
                },
                updateState: ({ isEnabled }) => {
                    return {
                        isEnabled,
                        isEnabledLoading: false,
                        isError: '',
                    };
                },
                onError: function () {
                    this.update({
                        isEnabledLoading: false,
                        isError: 'sorry something went wrong...',
                    });
                },
            }),
            httpRequest({
                name: 'queryHeatmapDate',
                type: 'post',
                preRequest: function (viewQuery) {
                    this.update({
                        viewMode: {
                            ...this.state.viewMode,
                            ...viewQuery,
                            isLoading: true,
                            location: false,
                            isError: '',
                            enabled: {
                                deviceType: [],
                                isValid: [],
                                eventType: [],
                            },
                        },
                        queryInView: JSON.stringify(viewQuery),
                    });
                },
                postRequest: function ({ possibilities }) {
                    if (possibilities.length) {
                        return possibilities;
                    } else {
                        this.update({
                            viewMode: {
                                ...this.state.viewMode,
                                isLoading: false,
                                location: false,
                                isError: `NO HEATMAP FOUND FOR THE ${this.state.viewMode.date}.`,
                                enabled: {
                                    deviceType: [],
                                    isValid: [],
                                    eventType: [],
                                },
                            },
                        });
                    }
                },
            }),
            httpRequest({
                name: 'queryHeatmap',
                type: 'post',
                axiosConfig: () => ({ responseType: 'blob' }),
                parseArgs: ({ name, date, deviceType, eventType, isValid }) => ({ name, date, deviceType, eventType, isValid }),
                preRequest: function (viewQuery) {
                    this.update({
                        viewMode: {
                            ...this.state.viewMode,
                            ...viewQuery,
                            isLoading: true,
                            location: false,
                            isError: '',
                            enabled: {
                                deviceType: uniq(pluck(viewQuery.possibilities, 'deviceType')),
                                isValid: uniq(pluck(viewQuery.possibilities, 'isValid')),
                                eventType: uniq(pluck(viewQuery.possibilities, 'eventType')),
                            },
                        },
                        queryInView: JSON.stringify(viewQuery),
                    });
                },
                postRequest: function (data, params) {
                    const img = new Image();
                    const location = URL.createObjectURL(data);
                    img.src = location;
                    img.onload = () => {
                        if (this.state.queryInView === JSON.stringify(params)) {
                            this.update({
                                viewMode: {
                                    ...this.state.viewMode,
                                    isLoading: false,
                                    isError: '',
                                    location,
                                },
                            });
                        }
                    };
                    img.onerror = () => {
                        // if(this.state.queryInView === JSON.stringify(params)){
                        // 	this.update({
                        // 		viewMode: {
                        // 			...this.state.viewMode,
                        // 			isLoading: false,
                        // 			isError: 'sorry something went wrong...',
                        // 		},
                        // 	});
                        // }
                    };
                },
                onError: function (err, params) {
                    if (this.state.queryInView === JSON.stringify(params)) {
                        if (err && err.response && err.response.status === 404) {
                            this.update({
                                viewMode: {
                                    ...this.state.viewMode,
                                    isLoading: false,
                                    isError: 'NO HEATMAP FOUND FOR YOUR QUERY.',
                                },
                            });
                        } else {
                            this.update({
                                viewMode: {
                                    ...this.state.viewMode,
                                    isLoading: false,
                                    isError: 'sorry something went wrong...',
                                },
                            });
                        }
                    }
                },
            }),
        ]);

const objToGet = params =>
    Object.entries(params)
        .map(([key, value]) => [key, encodeURIComponent(value)])
        .map(pair => pair.join('='))
        .join('&');
