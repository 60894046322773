import * as React from 'react';

import { Snackbar, SnackbarContent } from '@material-ui/core';

import classnames from 'classnames/bind';
import styles from './style.module.css';
import { useSelector } from 'react-redux';

const cx = classnames.bind(styles);

const SnackbarContainer = () => {
    const message = useSelector(state => state.snackbar.message);
    return (
        <Snackbar
            classes={{
                root: cx('snackbar-root'),
            }}
            open={!!message}
        >
            <SnackbarContent
                classes={{
                    root: cx('snackbar-content-root'),
                    message: cx('snackbar-content-message'),
                }}
                message={message}
            />
        </Snackbar>
    );
};

export default SnackbarContainer;
