import React from 'react';
import ReactTooltip from 'react-tooltip';
import ContentManager from '../../../../../../services/content-manager';
import { _String } from 'chopin-methods';
import { style } from '../style';

function RenderHead({ column, onClick = console.log, actions = [], idx, ...props }) {
    const cm = ContentManager.by('Analytics.ReportTable.SummaryReport');

    const mapStringId = colId =>
        ({
            affiliateId: 'AffiliateId',
            affiliateTraffic: 'AffiliateTraffic',
            invalidAffiliateTraffic: 'AffiliateInvalidTraffic',
            hiddenIframeTraffic: 'HiddenIframeAffiliateTraffic',
            affiliateFraudVisitRate: 'affiliateFraudVisitRate',
            invalidClickRate: 'InvalidVisitRate',
        }[colId] || _String.camelToPascal(colId));

    const stringId = mapStringId(column.id);
    const label = cm.get(stringId);

    return (
        <div
            style={{
                cursor: 'pointer',
                justifyContent: 'space-between',
                display: 'flex',
                maxHeight: '50px',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
            }}
            onClick={() => onClick(column)}
        >
            <div style={{}}>{label}</div>
        </div>
    );
}

function getTotal(value, columnId) {
    return value !== 'TOTAL' &&
        columnId !== 'affiliateTraffic' &&
        columnId !== 'invalidAffiliateTraffic' &&
        columnId !== 'hiddenIframeTraffic' &&
        columnId !== 'invalidClickRate' &&
        columnId !== 'affiliateFraudVisitRate'
        ? ''
        : value;
}

function RenderBody({ filterSearch, column = {}, value = '', isTotal }) {
    let formattedString = value;
    if (column.id === 'invalidClickRate' || column.id === 'affiliateFraudVisitRate') {
        formattedString = `${formattedString}`;
    }

    if (column.id === 'affiliateTraffic' || column.id === 'invalidAffiliateTraffic' || column.id === 'hiddenIframeTraffic') {
        formattedString = Number(value).toLocaleString();
    }

    if (isTotal) {
        formattedString = getTotal(formattedString, column.id);
    }

    const isLongText = formattedString.length > 11;
    return (
        <div
            data-tip-disable={!isLongText}
            data-tip={formattedString}
            style={{
                display: 'inline-block',
                float: 'center',
                width: `${Math.min(14 * formattedString.length, 150)}px`,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            }}
        >
            {highlightSearch(formattedString, filterSearch)}
            <ReactTooltip />
        </div>
    );
}

function highlightSearch(value, search) {
    if (typeof value === 'string' && value.toLowerCase() && search && value.toLowerCase().includes(search.toLowerCase())) {
        const startIdx = value.toLowerCase().indexOf(search.toLowerCase());
        const endIdx = startIdx + search.length;
        const before = value.substring(0, startIdx);
        const highlight = value.substring(startIdx, endIdx);
        const after = value.substring(endIdx, value.length);
        const highlightSearchColor = '#fe0072';
        return (
            <span>
                {before}
                <span style={{ background: highlightSearchColor }}>{highlight}</span>
                {after}
            </span>
        );
    } else {
        return value;
    }
}

export default {
    name: 'buildAffiliate',
    RenderHead: RenderHead.bind(this),
    RenderBody: RenderBody.bind(this),
    style: style,
    pagination: true,
    lineaCount: false,
    stickyHeader: true,
    Actions: [],
    alignHead: 'center',
    alignBody: 'center',
};
