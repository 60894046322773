import { FC, useEffect, useState } from 'react';
/* @ts-ignore */
import { useContentManager } from '@hooks';
import { HubSpotForm } from '../../../../../../types/hubspot';
import { createColumnHelper } from '@tanstack/react-table';
import { Checkbox, Tooltip } from '@material-ui/core';
import { CustomTable } from 'components/widgets/custom-table';

type HubSpotFormsProps = {
    hubSpotForms: HubSpotForm[];
    handleUpdateForm: (formId: number, checked: boolean) => void;
    checkedAllForms: (checked: boolean) => void;
};

export const HubSpotFormsList: FC<HubSpotFormsProps> = (props) => {
    const { hubSpotForms, handleUpdateForm, checkedAllForms } = props;
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const cm = useContentManager();
    const columnHelper = createColumnHelper<HubSpotForm>();

    useEffect(() => setIsAllChecked(hubSpotForms.every(form => form.checked)), [hubSpotForms]);

    const checkedHeader = (): any => <Checkbox checked={isAllChecked} onClick={() => { checkedAllForms(!isAllChecked); }} />;
    const columns = [
        columnHelper.accessor('checked', {
            cell: (info) => <Checkbox checked={info?.row?.original?.checked} onClick={() => handleUpdateForm(info?.row?.original?.id, !info?.row?.original?.checked)} />,
            header: checkedHeader(),
            enableSorting: false,
            meta: {
                isSortable: false,
            },
        }),
        columnHelper.accessor('name', {
            cell: (info) =>
                <div style={{ width: '2000px' }}><Tooltip title={info.getValue()}><span>{info.getValue()}</span></Tooltip></div>,
            header: cm.get('Name'),
            sortDescFirst: true,
            meta: {
                isSortable: true,
            },
        }),
    ];

    return (<CustomTable columns={columns} data={hubSpotForms} />);
};
