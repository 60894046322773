import { users as types } from '.';


export const inviteUser = data => {
    return {
        type: types.INVITE_USER,
        payload: data,
    };
};
export const setUsers = users => {
    return {
        type: types.SET_USERS,
        payload: users,
    };
};
export const setLoading = data => {
    return {
        type: types.SET_IS_LOADING,
        payload: data,
    };
};
export const setChildrenTypes = data => {
    return {
        type: types.SET_CHILDREN_TYPE,
        payload: data,
    };
};
export const editUser = data => {
    return {
        type: types.EDIT_USER,
        payload: data,
    };
};
export const handleUserStatus = data => {
    return {
        type: types.HANDLE_USER_STATUS,
        payload: data,
    };
};
export const removeUserFromOrganization = data => {
    return {
        type: types.REMOVE_USER,
        payload: data,
    };
};