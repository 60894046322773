export const constants = {
    SPLIT_RTI_DASHBOARD: 'paradom_feature_rti_dashboard',
    SPLIT_BLOCKING_SINCE: 'paradom_feature_blocking_since',
    SPLIT_GOOGLE_ADS: 'paradom_feature_google_ads',
    SPLIT_RTI_GENERATE_KEY: 'paradom_feature_rti_generate_key',
    SPLIT_GOOGLE_DISCONNECT_SNS: 'paradom_feature_google_disconnect_sns',
    SPLIT_RTI_UNIQUE_VISITORS: 'paradom_rti_unique_visitors',
    SPLIT_CONVERSION_EVENT_GUARD: 'paradom_conversion_event_guard',
    SPLIT_PIXEL_GUARD_DASHBOARD: 'paradome_pixel_guard_dashboard',
    SPLIT_MAINTENANCE_PAGE: 'paradome_maintenance_page',
    PARADOME_MIS_SETTING: 'PARADOME_MIS_SETTING',
    SPLIT_DASHBOARD_TRENDS: 'paradome_dashboard_trends',
    SPLIT_DASHBOARD_TRENDS_HIDE_ALL_PLATFORMS: 'paradome_dashboard_trends_hide_all_platforms',
    SPLIT_DATA_AND_ANALYTICS_API_INTEGRATION: 'paradome_data_and_analytics_api_integration',
    SPLIT_DATA_AND_ANALYTICS_GOOGLE_ANALYTICS_INTEGRATION: 'paradome_data_and_analytics_google_analytics_integration',
    SPLIT_HIDE_DATA_TOOLS_ON_SITE_CONVERSION: 'paradome_osc_hide_data_tools',
    SPLIT_POLICY_MANAGEMENT_TOGGLE: 'Paradome_policy_toggle',
    SPLIT_POLICY_MANAGEMENT: 'Paradome_Policy_Management',
    SPLIT_PARADOME_GOOGLE_PIXEL_GUARD: 'PARADOME_GOOGLE_PIXEL_GUARD',
    SPLIT_PARADOME_GA_UA: 'PARADOME_GA_UA',
    SPLIT_PARADOME_NEW_RTI_DASHBOARD: 'paradome_new_rti_dashboard',
    SPLIT_PARADOME_POLICY_MANAGEMENT_FEATURE: 'PARADOME_POLICY_MANAGEMENT_FEATURE',
    SPLIT_PARADOME_NEW_PD_DASHBOARD: 'paradome_new_pd_dashboard',
    PARADOME_HUB_SPOT_FEATURE: 'PARADOME_HUB_SPOT_FEATURE',
    PARADOME_HUBSPOT_PROTECT_NEW_FROM_FEATURE: 'PARADOME_HUBSPOT_PROTECT_NEW_FROM_FEATURE',
    PARADOME_CAS_DASHBOARD_VIEW_ALL: 'PARADOME_CAS_DASHBOARD_VIEW_ALL',
    PARADOME_ZENDESK: 'PARADOME_ZENDESK',
    PARADOME_ZENDESK_EXTERNAL_LINK_REDIRECT: 'PARADOME_ZENDESK_EXTERNAL_LINK_REDIRECT',
    PARADOME_COMPREHENSIVE_DETECTION_REPORT: 'paradome_comprehensive_detection_report',
    PARADOME_EXTENDED_RESPONSE_CLIENT_REPORT: 'paradome_extended_response_client_report',
    PARADOME_USER_SETTINGS_V2: 'PARADOME_USER_SETTINGS_V2',
    PARADOME_ACCOUNT_SETTINGS: 'PARADOME_ACCOUNT_SETTINGS',
    PARADOME_SHOW_REPORT_SCHEDULER_IN_CAS: 'PARADOME_SHOW_REPORT_SCHEDULER_IN_CAS',
    PARADOME_DOMAIN_FILTER: 'paradome_doamin_filter',
    PARADOME_BACKBLAZE_S3: 'paradome_backblaze_s3',
    PARADOME_AFFILIATE_FILTER: 'PARADOME_AFFILIATE_FILTER',
    PARADOME_MARKETO: 'PARADOME_MARKETO',
    PARADOME_GOOGLE_V2: 'PARADOME_GOOGLE_V2',
    PARADOME_GOOGLE_MULTI_MCC: 'PARADOME_GOOGLE_MULTI_MCC',
    PARADOME_NEW_SLP_DASHBOARD: 'PARADOME_NEW_SLP_DASHBOARD',
    PARADOME_CAS_SETTINGS_V2: 'PARADOME_CAS_SETTINGS_V2',
    PARADOME_ADOBE_ANALYTICS: 'PARADOME_ADOBE_ANALYTICS',
    PARADOME_ADOBE_ANALYTICS_V2: 'PARADOME_ADOBE_ANALYTICS_V2',
    PARADOME_SLP_SETTINGS_V2: 'PARADOME_SLP_SETTINGS_V2',
    PARADOME_EXTENDED_RESPONSE_CLIENT_FOR_MIS: 'PARADOME_EXTENDED_RESPONSE_CLIENT_FOR_MIS',
    PARADOME_EXTENDED_RESPONSE_LIMIT_ONLY_TO_OSS: 'PARADOME_EXTENDED_RESPONSE_LIMIT_ONLY_TO_OSS',
    PARADOME_REPACKAGING: 'PARADOME_REPACKAGING',
    PARADOME_SLP_ANALYTICS: 'PARADOME_SLP_ANALYTICS',
    DATA_API_FOR_EXTENDED_RESPONSE: 'DATA_API_FOR_EXTENDED_RESPONSE',
};
