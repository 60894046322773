import Base from '../base';
import { getDashboardSettings } from '../../../redux/thunks/dashboard';
import { setRTIToken, setServices } from '@redux/actions/settings';
import thirdParty from './3party';
import { triggerSnackbarMessage } from '../../../redux/actions/snackbar';
import { applyAuth0AuthorizationHeader } from '@utils/http/view-models';

import mixpanel from '@services/mixpanel';
import CheqUI from '@combotag/cheq-ui';
import _ from 'lodash';

const unFormatCustomerId = raw => raw.replace(/\-/g, '');
const { Meiosis } = CheqUI.React;
const { useMeiosis } = Meiosis;

export default ({ httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor() {
                    super('sso');

                    let ssos;
                    try {
                        ssos = JSON.parse(localStorage.getItem('ssos') || '{}');
                    } catch (e) {
                        ssos = {};
                    }

                    this.state = { ready: false, ssos };
                    thirdParty.map(S => new S(this)).forEach(service => this.addService(service.name, service));
                }

                init() {
                    this.getNetwork();
                    Object.values(this.state.services).forEach(service => service.init && service.init());
                    this.getSettings();
                }

                hasAuthenticator(authenticator) {
                    return !!(this.state.ssos[authenticator] && (this.state.ssos[authenticator].is_sso || this.state.ssos[authenticator].is_mcc));
                }

                isProtected(authenticator) {
                    return !!(this.state.ssos[authenticator] && this.state.ssos[authenticator].integrated);
                }

                isConnected(authenticator) {
                    return !!(this.state.ssos[authenticator] && this.state.ssos[authenticator].connected);
                }
            },
        ).from([
            httpRequest({
                name: 'skipAdwords',
                type: 'post',
                preRequest: function () {
                    this.update({
                        requestInvite: true,
                    });
                },
                postRequest: function () {
                    this.update({
                        requestInvite: false,
                        ssos: {
                            ...this.state.ssos,
                            google: {
                                ...this.state.ssos.google,
                                skippedAdwords: true,
                            },
                        },
                    });
                },
            }),
            httpRequest({
                name: 'saveGoogleManagement',
                type: 'post',
                preRequest: function () {
                    this.update({
                        requestSaveManagement: true,
                    });
                },
                postRequest: function ({ status, error }, params, dispatch) {
                    this.update({
                        requestSaveManagement: false,
                    });
                    const dispatchSnackbar = message => dispatch(triggerSnackbarMessage({ message }));
                    if (!status) {
                        switch (error) {
                            case 'invalid_ips':
                                dispatchSnackbar('Invalid IP addresses were supplied');
                                break;
                            case 'no_protected_customers':
                                dispatchSnackbar('In order to apply IP-Exclusion, you must pick at-least one account to be protected');
                                break;
                            case 'ip_limit_exceeded':
                                dispatchSnackbar('IP addresses limit exceeded 100');
                                break;
                            case 'no_integration':
                                dispatchSnackbar('You must pick at-least one protection feature to enable protection');
                                break;
                            default:
                                dispatchSnackbar(error);
                                break;
                        }
                        return { error };
                    }
                    const {
                        isIpBanIntegrated,
                        isAudienceTagIntegrated,
                        isPixelsGuardIntegrated,
                        isAudienceExclusionIntegrated
                    } = params;
                    let isProtected;
                    let integrated;
                    let connected;
                    if (!isIpBanIntegrated
                        && !isAudienceTagIntegrated
                        && !isPixelsGuardIntegrated
                        && !isAudienceExclusionIntegrated) {
                        isProtected = true;
                        connected = true;
                        integrated = false;
                    } else {
                        isProtected = true;
                        connected = true;
                        integrated = true;
                    }
                    const ssos = {
                        ...this.state.ssos,
                        google: {
                            ...this.state.ssos.google,
                            isProtected,
                            integrated,
                            connected,
                        },
                    };
                    localStorage.setItem('ssos', JSON.stringify(ssos));
                    this.update({
                        ssos,
                    });
                    mixpanel.postEvent('Google configure save', {
                        'Google IP exclusion': isIpBanIntegrated ? 'On' : 'Off',
                        'Google analytics audience tag': isAudienceTagIntegrated ? 'On' : 'Off',
                        'Google ads audience exclusion': isAudienceExclusionIntegrated ? 'On' : 'Off',
                    });
                    this.reduxStore.dispatch(getDashboardSettings());
                    return { success: true };
                },
                onError: function (error, c, dispatch) {
                    const dispatchSnackbar = message => dispatch(triggerSnackbarMessage({ message }));
                    dispatchSnackbar('Sorry something went wrong...');
                    this.update({
                        requestSaveManagement: false,
                    });
                    return { error };
                },
            }),
            httpRequest({
                name: 'fetchGoogleManagement',
                type: 'post',
                preRequest: function () {
                    this.update({
                        requestGoogleManagement: true,
                        errorGoogleManagement: '',
                        googleCustomers: [],
                    });
                },
                postRequest: function ({
                    status,
                    googleCustomers,
                    protectedCampaigns = {},
                    isIpBanIntegrated,
                    isAudienceTagIntegrated,
                    isPixelsGuardIntegrated,
                    defaultIpBanList,
                    isAudienceExclusionIntegrated,
                }) {
                    if (status) {
                        this.update({
                            googleCustomers: googleCustomers.map(({
                                clientId,
                                ...rest
                            }) => ({ clientId: unFormatCustomerId(clientId), ...rest })),
                            protectedCampaigns,
                            isIpBanIntegrated,
                            isAudienceTagIntegrated,
                            isPixelsGuardIntegrated,
                            isAudienceExclusionIntegrated,
                            defaultIpBanList,
                            errorGoogleManagement: '',
                            requestGoogleManagement: false,
                        });
                    } else {
                        this.update({
                            errorGoogleManagement: 'It is appears as CHEQ access was removed from your account',
                            requestGoogleManagement: false,
                        });
                    }
                },
                onError: function () {
                    this.update({
                        errorGoogleManagement: 'Sorry, it appears there was some error fetching your account and campaigns from google',
                        requestGoogleManagement: false,
                    });
                },
            }),
            httpRequest({
                name: 'cancelGoogleId',
                type: 'post',
                preRequest: function () {
                    this.update({
                        requestCancel: true,
                        errorCancel: '',
                    });
                },
                postRequest: function () {
                    this.update({
                        ssos: {
                            ...this.state.ssos,
                            google: {
                                ...this.state.ssos.google,
                                clientId: null,
                                status: null,
                            },
                        },
                        errorCancel: '',
                        requestCancel: false,
                    });
                    this.reduxStore.dispatch(getDashboardSettings());
                },
                onError: function () {
                    this.update({
                        errorCancel: 'Sorry, something went wrong',
                        requestCancel: false,
                    });
                },
            }),
            httpRequest({
                name: 'inviteGoogleId',
                type: 'post',
                preRequest: function () {
                    this.update({
                        requestInvite: true,
                    });
                },
                postRequest: function ({ status, error, isAlreadyManaged }, data, dispatch) {
                    if (!status) {
                        const dispatchSnackbar = message => dispatch(triggerSnackbarMessage({
                            message,
                            timeout: 6000,
                        }));
                        switch (error) {
                            case 'invalid_id':
                                dispatchSnackbar('Invalid Google Ads ID supplied.');
                                return this.update({
                                    requestInvite: false,
                                });
                            case 'child_managed':
                                dispatchSnackbar('It seems as this account already have a child account managed.');
                                return this.update({
                                    requestInvite: false,
                                });
                            case 'mcc_already_managed':
                                dispatchSnackbar('It seems as this account is already managed by another manager, alternatively - you can use Google sign in');
                                try {
                                    const googleSigninBtn = document.getElementById('google-signin');
                                    googleSigninBtn.style.transition = 'box-shadow 2s ease-in-out';
                                    googleSigninBtn.style.boxShadow = '0px 0px 4px 4px rgba(254,0,114,0.5)';
                                    setTimeout(() => {
                                        googleSigninBtn.style.boxShadow = 'none';
                                    }, 6000);
                                } catch (e) {
                                    return;
                                }
                                return this.update({
                                    requestInvite: false,
                                });
                            default:
                                dispatchSnackbar(error || 'Sorry, something went wrong');
                                return this.update({
                                    requestInvite: false,
                                });
                        }
                    } else {
                        this.update({
                            ssos: {
                                ...this.state.ssos,
                                google: {
                                    ...this.state.ssos.google,
                                    clientId: data.id,
                                    status: isAlreadyManaged ? 'MANAGED' : 'PENDING',
                                },
                            },
                            requestInvite: false,
                        });
                    }
                },
                onError: function (err, params, dispatch) {
                    dispatch(triggerSnackbarMessage({ message: 'Sorry, something went wrong' }));
                    this.update({
                        requestInvite: false,
                    });
                },
            }),
            httpRequest({
                name: 'getNetwork',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    this.update({
                        requestGetNetwork: true,
                    });
                },
                updateState: ssos => ({ ssos, ready: true }),
                postRequest: function (ssos) {
                    localStorage.setItem('ssos', JSON.stringify(ssos));
                    this.update({
                        requestGetNetwork: false,
                    });
                    return ssos;
                },
                onError: function () {
                    this.update({
                        requestGetNetwork: false,
                    });
                },
            }),
            httpRequest({
                name: 'touch',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: touched => state => ({ ...state, ssos: { ...(state.ssos || {}), touched } }),
                postRequest: ssos => localStorage.setItem('ssos', JSON.stringify(ssos)),
            }),
            httpRequest({
                name: 'getCampaigns',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: campaigns => ({ campaigns }),
            }),
            httpRequest({
                name: 'getSettings',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: function (servicesSettings) {
                    const rtiToken = servicesSettings.find(({ service }) => service === 'cheq')?.settings.key;
                    if (rtiToken) {
                        this.reduxStore.dispatch(setRTIToken(rtiToken));
                    }
                    this.reduxStore.dispatch(setServices(servicesSettings));
                    return { servicesSettings };
                },
            }),
            httpRequest({
                name: 'updateSettings',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: function ({ settings, service }) {
                    return state => ({ servicesSettings: [...state.servicesSettings.filter(({ service: s }) => service !== s), { service, settings }] });
                },
                postRequest: function () {
                    this.reduxStore.dispatch(getDashboardSettings());
                },
            }),
            ...['getProtectionFeatures', 'invertAudienceTag', 'deleteAudienceTag', 'addAudienceTag'].map(name =>
                httpRequest({
                    name,
                    type: 'post',
                    preRequest: function () {
                        applyAuth0AuthorizationHeader.call(this);
                    },
                    updateState: function updateProtectionFeatures(protectionFeatures, params) {
                        return state => ({
                            protectionFeatures: {
                                ...state.protectionFeatures,
                                [params.serviceName]: protectionFeatures,
                            },
                        });
                    },
                    postRequest: function (protectionFeatures, params) {
                        if (name === 'getProtectionFeatures') return;
                        if (name === 'deleteAudienceTag' || name === 'addAudienceTag') {
                            mixpanel.postEvent(name === 'deleteAudienceTag' ? 'Delete pixel protection' : 'New pixel protection', {
                                'Platform name': params.serviceName,
                            });
                        }
                        if (name === 'invertAudienceTag') {
                            mixpanel.postEvent(params.isProtected ? 'Enable pixel protection' : 'Disable pixel protection', {
                                'Platform name': params.serviceName,
                            });
                        }
                        this.reduxStore.dispatch(getDashboardSettings());
                    },
                }),
            ),
            httpRequest({
                name: 'invertAudienceService',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                postRequest: function (protectionFeatures, params, callback = () => { }) {
                    this.update(state => ({
                        ssos: {
                            ...state.ssos,
                            [params.serviceName]: {
                                ...state.ssos[params.serviceName],
                                integrated: protectionFeatures.isEnabled,
                            },
                        },
                        protectionFeatures: { ...state.protectionFeatures, [params.serviceName]: protectionFeatures },
                    }));
                    mixpanel.postEvent('Platform status change', {
                        'Status': protectionFeatures.isEnabled ? 'On' : 'Off',
                        'Platform name': params.serviceName,
                    });
                    this.reduxStore.dispatch(getDashboardSettings());
                    callback();
                },
            }),
            httpRequest({
                name: 'getGoogleAdsAudienceExclusions',
                type: 'get',
                route: 'google/audience_exclusion',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    this.update({
                        requestingGoogleAdsAudienceExclusions: true,
                    });
                },
                updateState: googleAdsAudienceExclusions => state => ({ ...state, googleAdsAudienceExclusions }),
                postRequest: function () {
                    this.update({
                        requestingGoogleAdsAudienceExclusions: false,
                    });
                },
                onError: function (error) {
                    this.update({
                        requestingGoogleAdsAudienceExclusions: false,
                    });
                    console.error(error);
                    this.reduxStore.dispatch(triggerSnackbarMessage({ message: 'Sorry, something went wrong' }));
                },
            }),
            httpRequest({
                name: 'saveGoogleAdsAudienceExclusions',
                type: 'post',
                route: '/google/audience_exclusion',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    this.update({
                        requestSaveGoogleAdsAudienceExclusions: true,
                    });
                },
                postRequest: function (result, params) {
                    this.update({
                        requestSaveGoogleAdsAudienceExclusions: false,
                    });
                    if (!result.success) {
                        console.error(result);
                        return this.reduxStore.dispatch(triggerSnackbarMessage({ message: 'Sorry, something went wrong' }));
                    }
                    this.update({
                        googleAdsAudienceExclusions: {},
                    });
                    // this.reduxStore.dispatch(triggerSnackbarMessage({ message: 'Google Ads Audience Exclusion Saved Successfully!' }));
                    return { success: true };
                },
                onError: function (error) {
                    this.update({
                        requestSaveGoogleAdsAudienceExclusions: false,
                    });
                    console.error(error);
                    this.reduxStore.dispatch(triggerSnackbarMessage({ message: 'Sorry, something went wrong' }));
                    return { error };
                },
            }),
        ]);
