import { SetStateAction, useEffect, useState, Dispatch } from "react"

export default function useFetch<T = unknown>(callback: (params:any)=>Promise<T>, params :any=[],sortFunction: any = null):
    [T | [], Dispatch<SetStateAction<T | []>>, boolean, any] {
    const [data, setData] = useState<T | []>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<any>()

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const  data = await callback([...params])
                if (sortFunction && Array.isArray(data)) {
                    data.sort(sortFunction)
                }
                setData(data)
                setError(null)
            } catch (_err) {
                let e: any = _err;
                setError(e.data);
            } finally {
                setIsLoading(false)
            }
        })()
    }, [])

    return [data, setData, isLoading, error]
}
