import { httpRequest, methods } from '../http';
/* @ts-ignore */
import config from '@config';

const BASE_URL = config.cqAccountsCcBaseAPI

class ClickTrueTagsService {
    static generateApiKey = async (searchId: number) => {
        const { response } :{response:Promise<void>} = httpRequest({
            baseURL: `${BASE_URL}/tags/protection-config`,
             method: methods.put,
             body:{searchId}
            })
        await response
    }

}

export default ClickTrueTagsService;
