export enum SETTINGS_TABS_NAMES {
    HubSpot = 'HubSpot',
    GoogleAnalytics = 'GoogleAnalytics',
    DataAndAnalytics = 'DataAndAnalytics',
    RealTimeInterception = 'RealTimeInterception',
    Affiliates = 'Affiliates',
    Settings = 'Settings',
    Users = 'Users',
    Tags = 'Tags',
    ThirdPartyAudiences = 'ThirdPartyAudiences',
    Accounts = 'Accounts',
    PixelGuard = 'PixelGuard',
    Marketo = 'Marketo',
    AdobeAnalytics = 'AdobeAnalytics',
}

export enum Products {
    CAS = 1,
    OSS = 2,
    MIS = 3,
    SLP = 8,
    PRIVACY = 5,
}

export const getSolutionSettings = (isDataApiForExtendedResponseOn: boolean) => {
    const solutionSettings = {
        [Products.CAS]: [SETTINGS_TABS_NAMES.Accounts, SETTINGS_TABS_NAMES.Affiliates, SETTINGS_TABS_NAMES.PixelGuard],
        [Products.MIS]: [SETTINGS_TABS_NAMES.GoogleAnalytics, SETTINGS_TABS_NAMES.AdobeAnalytics, SETTINGS_TABS_NAMES.DataAndAnalytics],
        [Products.SLP]: [SETTINGS_TABS_NAMES.Marketo, SETTINGS_TABS_NAMES.HubSpot, SETTINGS_TABS_NAMES.RealTimeInterception],
        [Products.OSS]: [SETTINGS_TABS_NAMES.RealTimeInterception],
    };
    if (isDataApiForExtendedResponseOn) {
        solutionSettings[Products.OSS].push(SETTINGS_TABS_NAMES.DataAndAnalytics);
    }

    return solutionSettings;
};

enum Packages {
    Marketing = 1,
    Security = 2,
    Privacy = 3,
}

export const package2SolutionMap = {
    [Packages.Marketing]: [Products.CAS, Products.MIS, Products.SLP],
    [Packages.Security]: [Products.OSS],
    [Packages.Privacy]: [Products.PRIVACY],
};

export const solution2PackageMap = {
    [Products.CAS]: Packages.Marketing,
    [Products.MIS]: Packages.Marketing,
    [Products.SLP]: Packages.Marketing,
    [Products.OSS]: Packages.Security,
    [Products.PRIVACY]: Packages.Privacy,
};

export const showTab = (setting: SETTINGS_TABS_NAMES, product: Products, isDataApiForExtendedResponseOn: boolean): boolean => {
    return product === Products.PRIVACY ? false : getSolutionSettings(isDataApiForExtendedResponseOn)[product].includes(setting);
};
