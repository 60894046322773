import React from 'react';
import styles from './confirm.module.scss';
import Button from '../button';

export default ({ onSubmit, onCancel, title, confirmLabel = 'OK', cancelLabel = 'CANCEL' }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title}>{title}</div>
                <div className={styles.buttonsContainer}>
                    <div className={styles.button}>
                        <Button onClick={onSubmit}>{confirmLabel}</Button>
                    </div>
                    <div className={styles.button}>
                        <Button className={styles.button} onClick={onCancel}>
                            {cancelLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
