import React from "react";
import { Drawer } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import widgets from "../../../../widgets";
import styles from "./drawer.module.scss";
const { Button } = widgets;

export default ({ cm, showDrawer, onClose, title, body, onClick, disabled, scheduleReports, frequency }) => (
    <Drawer
        anchor="right"
        open={showDrawer}
        onClose={onClose}
    >
        <div className={styles.container}>
            <div>
                <div className={styles.header}>
                    <div className={styles.title}>{cm.get(title)}</div>
                    <CloseOutlinedIcon onClick={onClose}/>
                </div>
                <div className={styles.body}>
                    {body}
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.text} onClick={onClose}>Cancel</div>
                <Button onClick={onClick}
                        disabled={disabled}
                        className={styles.button}
                >
                    {(scheduleReports || frequency !== 'onetime') ? cm.get('ScheduleReportButtonText') : cm.get('GetReport')}
                </Button>
            </div>
        </div>
    </Drawer>
)
