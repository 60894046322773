import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { _String } from 'chopin-methods';
import { style } from '../style';
import { htmlDecode } from '../../../../../../utils/common'
import ContentManager from '../../../../../../services/content-manager';

function RenderHead({ column, onClick = console.log, actions = [], idx, ...props }) {
    const cm = ContentManager.by('Analytics.ReportTable.SummaryReport');

    const mapStringId = colId =>
        ({
            totalApiCalls: 'TotalApiCalls',
            validResponses: 'ValidResponses',
            invalidResponses: 'InvalidResponses',
            invalidResponseRate: 'InvalidResponseRate',
            affiliateId: 'AffiliateId',
            badBotActivity: 'BadBotActivity',
            maliciousActivity: 'MaliciousActivity',
            suspiciousActivity: 'SuspiciousActivity',
            customRulesActivity: 'CustomRulesActivity',
            totalClicks: 'TotalVisits',
            validClicks: 'ValidVisits',
            invalidClicks: 'InvalidVisits',
            invalidClickRate: 'InvalidVisitRate',
            totalLeads: 'TotalLeads',
            benignLeads: 'BenignLeads',
            suspiciousLeads: 'SuspiciousLeads',
            maliciousLeads: 'MaliciousLeads',
        }[colId] || _String.camelToPascal(colId));

    const stringId = mapStringId(column.id);
    const label = cm.get(stringId);
    return (
        <div
            style={{
                cursor: 'pointer',
                justifyContent: 'space-between',
                display: 'flex',
                maxHeight: '50px',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
            }}
            onClick={() => onClick(column)}
        >
            <div style={{}}>{label}</div>
        </div>
    );
}

function getTotal(value, columnId) {
    return value !== 'TOTAL' &&
        columnId !== 'badBotActivity' &&
        columnId !== 'maliciousActivity' &&
        columnId !== 'suspiciousActivity' &&
        columnId !== 'invalidClickRate' &&
        columnId !== 'totalClicks' &&
        columnId !== 'invalidClicks' &&
        columnId !== 'validClicks' &&
        columnId !== 'totalApiCalls' &&
        columnId !== 'validResponses' &&
        columnId !== 'invalidResponses' &&
        columnId !== 'totalLeads' &&
        columnId !== 'maliciousLeads' &&
        columnId !== 'suspiciousLeads' &&
        columnId !== 'benignLeads' &&
        columnId !== 'invalidResponseRate'
        ? ''
        : value;
}

function RenderBody({ filterSearch, column = {}, value = '', isTotal }) {
    const {
        currentReport: { reportType },
    } = useSelector(state => state.analytics);

    let formattedString = htmlDecode(value);
    if (
        column.id === 'suspiciousActivity' ||
        column.id === 'maliciousActivity' ||
        column.id === 'invalidClickRate' ||
        column.id === 'badBotActivity' ||
        column.id === 'customRulesActivity' ||
        column.id === 'invalidResponseRate'
    ) {
        formattedString = `${formattedString}`;
    }
    if (
        reportType === 'rti' &&
        (column.id === 'suspiciousActivity' || column.id === 'maliciousActivity' || column.id === 'badBotActivity' || column.id === 'customRulesActivity')
    ) {
        formattedString = Number(value).toLocaleString();
    }

    if (
        column.id === 'totalClicks' ||
        column.id === 'invalidClicks' ||
        column.id === 'validClicks' ||
        column.id === 'totalApiCalls' ||
        column.id === 'validResponses' ||
        column.id === 'totalLeads' ||
        column.id === 'maliciousLeads' ||
        column.id === 'suspiciousLeads' ||
        column.id === 'benignLeads' ||
        column.id === 'invalidResponses'
    ) {
        formattedString = Number(value).toLocaleString();
    }

    if (isTotal) {
        formattedString = getTotal(formattedString, column.id);
    }

    const isLongText = formattedString.length > 11;
    return (
        <div
            data-tip-disable={!isLongText}
            data-tip={formattedString}
            style={{
                display: 'inline-block',
                float: 'center',
                width: `${Math.min(14 * formattedString.length, 150)}px`,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            }}
        >
            {highlightSearch(formattedString, filterSearch)}
            <ReactTooltip />
        </div>
    );
}

function highlightSearch(value, search) {
    if (typeof value === 'string' && value.toLowerCase() && search && value.toLowerCase().includes(search.toLowerCase())) {
        const startIdx = value.toLowerCase().indexOf(search.toLowerCase());
        const endIdx = startIdx + search.length;
        const before = value.substring(0, startIdx);
        const highlight = value.substring(startIdx, endIdx);
        const after = value.substring(endIdx, value.length);
        const highlightSearchColor = '#fe0072';
        return (
            <span>
                {before}
                <span style={{ background: highlightSearchColor }}>{highlight}</span>
                {after}
            </span>
        );
    } else {
        return value;
    }
}

export default {
    name: 'buildSummary',
    RenderHead: RenderHead.bind(this),
    RenderBody: RenderBody.bind(this),
    style: style,
    pagination: true,
    lineaCount: false,
    stickyHeader: true,
    Actions: [],
    alignHead: 'center',
    alignBody: 'center',
};
