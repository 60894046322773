import { clickTrueTags as types } from '.';

export const clickTrueTagsRequestFailed = payload => ({
    type: types.CLICK_TRUE_TAGS_REQUEST_FAILED,
    payload,
});

export const clickTrueTagsDataLoading = () => ({
    type: types.CLICK_TRUE_TAGS_DATA_LOADING,
});

export const setClickTrueTags = payload => ({
    type: types.SET_CLICK_TRUE_TAGS,
    payload,
});
