import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './dashboard.module.scss';
import { useContentManager, useGetFilters, useSplit } from '@hooks';
import { setFilterOptions } from '@redux/actions/dashboard';
import Filter from './components/filter';
import VisitsSummary from './components/visits-summary';
import { InvalidVisitsInsights } from './components/invalid-visits-insights';
import UserActivity from './components/user-activity';
import Progress from '@components/widgets/progress';
import mixpanel from '@services/mixpanel';
import Header from '../../../components/containers/header';
import { constants } from '@utils/split.io';

const Dashboard = () => {
    const { data: casFilters } = useGetFilters();
    const cm = useContentManager();
    const { filterOptions, settingsLoading, selectedPlatformsGroup, policyToggleOptions } = useSelector(state => state.dashboard);
    const { isOn: showPolicyToggle } = useSplit(constants.SPLIT_POLICY_MANAGEMENT_TOGGLE);
    const { isOn: policyManagement } = useSplit(constants.SPLIT_POLICY_MANAGEMENT);
    const [currentPlatformsGroup, setCurrentPlatformsGroup] = useState(selectedPlatformsGroup?.group);
    delete filterOptions.policyTogglePM;
    delete filterOptions.policyToggleOSC;
    delete filterOptions.policyToggleA;
    filterOptions.policyToggleDA = policyToggleOptions.policyToggleDA;

    const [options, setOptions] = useState(filterOptions);

    const dispatch = useDispatch();

    const dashboardRef = useRef();
    const onScroll = () => {
        if (dashboardRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = dashboardRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                mixpanel.postEvent('Dashboard bottom view');
            }
        }
    };

    const getCasPlatforms = () => {
        return casFilters?.data?.platforms.map(({ value }) => value) || filterOptions.platforms;
    };

    // Set the platforms options from the casFilters
    useEffect(
        () =>
            setOptions(prevState => ({
                ...prevState,
                platforms: getCasPlatforms(),
            })),
        [casFilters]
    );

    useEffect(() => {
        setOptions(prevState => ({
            ...prevState,
            policyManagement,
            platforms: getCasPlatforms(),
            includePolicy: showPolicyToggle,
        }));
        dispatch(
            setFilterOptions({
                policyManagement,
                includePolicy: showPolicyToggle,
            })
        );
    }, [policyManagement, showPolicyToggle, casFilters]);

    useEffect(() => {
        setCurrentPlatformsGroup(selectedPlatformsGroup?.group);
        const platforms = getCasPlatforms();
        setOptions(prevState => ({ ...prevState, platform: selectedPlatformsGroup?.group, platforms }));
        dispatch(setFilterOptions({ platform: selectedPlatformsGroup?.group, platforms }));
    }, [selectedPlatformsGroup?.group]);

    const buildMixpanelObjectAndSend = (action, options) => {
        const dateRange = options.dateRange.startDate ? 'Custom' : options.dateRange;
        const body = {
            'Dashboard view type': action,
            'Channel filter': options.channelName ? options.channelName : 'All Channels',
            'Period filter': dateRange,
            'Start date filter': dateRange === 'Custom' ? options.dateRange.startDate : '',
            'End date filter': dateRange === 'Custom' ? options.dateRange.endDate : '',
            'Include known crawlers filter': options.includePPCCrawlers ? 'True' : 'False',
            'Platform Filter': !options.platform?.toLowerCase().includes('all') ? options.platform : '',
        };
        mixpanel.postEvent('Dashboard Data & Analytics view', body);
    };

    const handleRefresh = (action, state) => {
        buildMixpanelObjectAndSend(action, state);
        setOptions(state);
        dispatch(setFilterOptions(state));
    };

    if (settingsLoading) return <Progress />;

    if (window.performance.navigation.type === 1) {
        const object = {
            'Page name': 'Dashboard D&A',
            'Navigation type': 'Refresh',
        };

        mixpanel.postEvent('PageView', object);
    }
    return (
        <>
            <Header title={cm.get('Dashboard')} />
            <div className={styles.root} onScroll={onScroll} ref={dashboardRef}>
                <div className={styles.dataContainer}>
                    <Filter
                        setCurrentPlatformsGroup={setCurrentPlatformsGroup}
                        currentPlatformsGroup={currentPlatformsGroup}
                        state={filterOptions}
                        options={options}
                        handleRefresh={handleRefresh}
                    />
                    <VisitsSummary options={options} />
                    <InvalidVisitsInsights options={options} />
                    <UserActivity options={options} />
                </div>
            </div>
        </>
    );
};

export default Dashboard;
