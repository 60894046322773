import React, {useState} from "react";
import TableHeader from "@components/containers/exportables/ui/table/table-header";
import { getComparator, stableSort } from "./utils";
import styles from './table.module.scss';

export default ({ cm, reports, TableRow, headCells, reportMethods }) => {
    const [order, setOrder] = useState(null);
    const [orderBy, setOrderBy] = useState(null);

    const sortHandler = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <div className={styles.container} data-test-selector="exportables-table-container">
            <TableHeader
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                sortHandler={sortHandler}
            />
            <div className={styles.tableBody}>
                <div className={styles.rows}>
                    {stableSort(reports, getComparator(order, orderBy))
                        .map((row, index) => (
                            <TableRow
                                cm={cm}
                                key={index}
                                row={row}
                                reportMethods={reportMethods}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}