import { Chip, Input } from '@material-ui/core';
import {useRef, useState} from 'react';
import styles from './styles.module.scss';

const ChipsInput = ({ onAdd, onDelete, data = [], placeholder, errors = [] }) => {
    const [text, setText] = useState('');
    const inputRef = useRef();
    const handleOnAdd = e => {
        e.stopPropagation();
        e.preventDefault();

        const { key } = e;

        if (key === 'Enter') {
            if (!data.includes(text)) {
                onAdd(text);
            }
            setText('');
        }
    };

    const handleOnDelete = (value, i) => () => {
        onDelete(value, i);
    };

    const handleOnBlur = () => {
        if (!data.includes(text)) {
            onAdd(text);
        }
        setText('');
    };

    return (
        <div className={styles.root} onBlur={handleOnBlur}>
            <div className={styles.chips} style={{ alignItems: 'center', width: '100%' }}>
                {data.map((value, i) => (
                    <Chip key={value + i}
                          label={value}
                          style={{ margin: '1px', fontSize:14, fontFamily: 'inherit', color: '#fff' }}
                          onDelete={handleOnDelete(value, i)} size="small"
                    />
                ))}
                <span className={styles.input}>
                    <Input type="text"
                           inputRef={inputRef}
                           inputProps={{
                               itemRef: inputRef,
                               size: text.length || placeholder?.length || 1,
                           }}
                           value={text}
                           classes={{ root: styles.inputRoot }}
                           onChange={e => setText(e.target.value)}
                           fullWidth
                           disableUnderline
                           style={{ padding: '0', fontSize:14, fontFamily: 'inherit', color: '#fff' }}
                           placeholder={placeholder}
                           onKeyUp={handleOnAdd}
                    />
                </span>
            </div>
        </div>
    );
};

export default ChipsInput;
