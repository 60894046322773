import React, { useEffect, useState } from 'react';
import Input from '../ui/input';
import Select from '../ui/select';
import Tooltip from '@components/widgets/tooltip';
import Frequency from './components/frequency';
import Settings from './components/settings';
import PaidMarketing from './components/paid-marketing';
import ScheduleData from './components/scheduled';
import EmailInput from '../ui/email-input';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import server from '../server';
import config from '@config';
import { getFormOptions, getTooltip, isCreateReportDisabled } from '@components/containers/exportables/main/utils';
import styles from './create-report.module.scss';
import { parseDate } from '@utils/skewed';
import Drawer from '@components/containers/exportables/ui/drawer';
import { useSelector } from 'react-redux';
import { parseStateToBody } from '@components/containers/exportables/create-report/utils';
import mixpanel from '@services/mixpanel';

const ENGLISH_REGEX = /^[A-Za-z0-9.!\\-_ ]+$/;

export default ({ cm, title, showDrawer, setShowDrawer, setShowSnackbar, data, fetchReports, scheduleReportsOnly, reportTypes, frequencyList }) => {
    const { selectedLicense } = useSelector(state => state.settings);

    // new report data
    const [state, updateState] = useState({
        id: '',
        type: selectedLicense === 1 ? 'limited' : selectedLicense === 2 ? 'extended_response_client_report' : 'standard',
        name: '',
        filters: [],
        frequency: scheduleReportsOnly ? 'daily' : 'onetime',
        format: selectedLicense !== 2 ? 'CSVWithNames' : 'LineAsString',
        reportType: 'standard',
        startDate: '',
        endDate: '',
        emails: [],
        ivt: false,
        paid: '',
        dateRange: { value: getFormOptions().dateRange[0].value, format: parseDate(getFormOptions().dateRange[0].details) },
        month: '',
        accessKey: '',
        accessSecret: '',
        destination: 'external',
        cloudStorageProvider: 'aws',
        bucketName: '',
        bucketRegion: '',
        channels: [],
        testConnection: false,
        urls: [],
        referrer: [],
        tags: [],
        campaignType: [],
        medium: [],
        protected: [],
        campaigns: [],
        allPlatforms: [],
        //fileFormat: 'gzip'
    });

    const fileFormats = getFormOptions(state.type)?.format;

    const [channels, setChannels] = useState([]);
    const [reportNameError, setReportNameError] = useState(false);

    useEffect(() => {
        server.post('realtime/get_channels', { date: new Date() }).then(res => {
            const response = res.data.filter(item => item.label !== 'none');
            setChannels(response);
        });
    }, []);

    useEffect(() => {
        if (data) {
            const selectedChannels = data.channels.split(',').map(i => Number(i));
            const channelsToAdd = channels.filter(channel => {
                if (selectedChannels.includes(channel.value)) return channel;
            });

            let fullPathBucket = '',
                bucketRegion = '',
                cloudStorageProvider = '',
                splitBucket = '',
                bucketName = '';
            if (data.destination === 'external') {
                fullPathBucket = data.bucket;
                const regionRegex = /https:\/\/s3\.([^.]+)\./;
                const providerRegex = /https:\/\/s3\.[^/]+?\.([^.]+)\./;

                bucketRegion = (fullPathBucket.match(regionRegex) || [''])[1];
                cloudStorageProvider = (fullPathBucket.match(providerRegex) || [''])[1].includes('aws') ? 'aws' : 'backblaze';
                splitBucket = fullPathBucket.split('/');
                bucketName = splitBucket[splitBucket.length - 1];
            }

            const filters = data?.additionalFilters;
            const customPlatform = filters.platform.custom;

            updateState(prevState => ({
                ...prevState,
                id: data.id,
                type: data.type,
                name: data.name,
                networkId: data.networkId,
                format: data.format,
                frequency: data.frequency,
                accessKey: data.accessKey || '',
                accessSecret: data.accessSecret || '',
                destination: data.destination,
                emails: [data.emails],
                ivt: data.ivt,
                paid: data.paid ? 'paid' : '',
                channels: channelsToAdd,
                bucketName,
                bucketRegion,
                cloudStorageProvider,
                source: filters ? mapValues(customPlatform?.source) : [],
                referrer: filters ? mapValues(customPlatform?.referer) : [],
                campaignType: filters ? mapValues(customPlatform?.campaignType) : [],
                medium: filters ? mapValues(customPlatform?.medium) : [],
                allPlatforms: filters ? mapValues(filters?.platform.default) : [],
                campaigns: filters ? mapValues(filters?.campaigns) : [],
                urls: filters ? mapValues(filters?.urls) : [],
                tags: channelsToAdd,
            }));
        }
    }, [channels]);

    const onChangeHandler = ({ target: { name, value } }) => {
        updateState(prevState => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'name') {
            setReportNameError(false);
        }

        if (name === 'type') {
            if (value === 'comprehensive_detection_report' || value === 'extended_response_client_report') {
                updateState(prevState => ({
                    ...prevState,
                    format: 'LineAsString',
                }));
            } else {
                updateState(prevState => ({
                    ...prevState,
                    format: 'CSVWithNames',
                }));
            }
        }
    };

    const mapValues = results => {
        return results?.length > 0
            ? results.map(result => {
                  return { value: result, label: result };
              })
            : [];
    };

    const createDataForMixpanel = reportData => {
        const object = {
            'Exportable report type': reportData.type,
            'Exportable report frequency': state.frequency,
            'Exportable report frequency on': '',
            'Exportable report spend': '',
            'Exportable report start date': state.frequency === 'onetime' ? reportData.startDate : '',
            'Exportable report end date': state.frequency === 'onetime' ? reportData.endDate : '',
            'Show only invalid traffic': state.ivt,
            'Exportable report send to': state.destination,
            'Exportable Report Length': '',
            'Exportable Report Sources': '',
            'Exportable report Month': '',
            'Exportable report file format': reportData.format,
        };

        if (state.frequency !== 'onetime' && state.frequency !== 'daily') {
            object['Exportable report frequency on'] = state.frequency === 'weekly' ? 'Monday' : 'Every 1st of the month';
        }
        if (reportData.type === 'limited' && state.frequency === 'onetime') {
            object['Exportable report spend'] = state.dateRange.format;
        }
        if (reportData.type === 'standard') {
            object['Exportable Report Length'] = 'Standard';
            object['Exportable Report Sources'] = reportData.paid !== '' ? 'All Sourced' : 'Paid';
        } else if (reportData.type === 'extended') {
            object['Exportable Report Length'] = 'Extended';
            object['Exportable Report Sources'] = reportData.paid !== '' ? 'All Sourced' : 'Paid';
        }

        if (reportData.type === 'limited' && state.frequency === 'onetime') {
            object['Exportable report Month'] = state.month;
        }

        return object;
    };

    const createReport = async () => {
        try {
            if (!ENGLISH_REGEX.test(state.name)) {
                setReportNameError(true);
                return;
            }
            const { networkId, username, dataLocationId } = JSON.parse(localStorage.getItem('meta') || '{}');
            const parsedState = parseStateToBody(state);
            let onDemandUrl, scheduledReportsUrl;
            if (dataLocationId != 2) {
                onDemandUrl = config.skewedAnalyticsOnDemandReports;
                scheduledReportsUrl = config.skewedAnalyticsScheduledReports;
            } else {
                onDemandUrl = config.skewedAnalyticsOnDemandReportsEU;
                scheduledReportsUrl = config.skewedAnalyticsScheduledReportsEU;
            }
            parsedState.networkId = networkId;
            parsedState.createdBy = username;
            if (state.frequency === 'onetime') {
                // ondemand report
                await server.post('', parsedState, onDemandUrl);
            } else {
                await server.put('', parsedState, scheduledReportsUrl);
            }
            setShowDrawer(false);
            if (!data) setShowSnackbar(state.frequency, true);
            mixpanel.postEvent('Exportable report run', createDataForMixpanel(parsedState));
            fetchReports({});
        } catch (err) {
            setShowDrawer(false);
            let code = 0;
            if (err.code === 2013) code = -1;
            if (!data) setShowSnackbar(state.frequency, false, code);
            fetchReports({});
        }
    };

    const showSettings = reportType => {
        return reportType !== 'limited';
    };

    return (
        <>
            <Drawer
                cm={cm}
                showDrawer={showDrawer}
                title={title}
                disabled={isCreateReportDisabled(state)}
                onClose={() => setShowDrawer(!showDrawer)}
                scheduleReports={scheduleReportsOnly}
                frequency={state.frequency}
                body={
                    <div className={styles.container} data-test-selector="exportables-create-report-container">
                        <div className={styles.inputContainer} data-test-selector="exportables-create-report-type">
                            <div className={styles.label}>{cm.get('ReportType')}</div>
                            <div className={styles.flexItem}>
                                <Select
                                    cm={cm}
                                    selector="exportables-create-report-type-select"
                                    name="type"
                                    selected={state.type}
                                    onChange={onChangeHandler}
                                    options={reportTypes}
                                    largeWidth
                                />
                                <Tooltip content={cm.get(getTooltip(state.type))}>
                                    <InfoIcon cursor="pointer" />
                                </Tooltip>
                            </div>
                        </div>
                        <div className={styles.inputContainer} data-test-selector="exportables-create-report-name">
                            <div className={styles.label}>{scheduleReportsOnly ? cm.get('ScheduleReportName') : cm.get('ReportName')}</div>
                            {scheduleReportsOnly && data ? (
                                <div data-test-selector="exportables-create-report-name-input">{state.name}</div>
                            ) : (
                                <Input
                                    value={state.name}
                                    name="name"
                                    onChange={onChangeHandler}
                                    placeholder={cm.get('ReportNamePlaceholder')}
                                    testSelector="exportables-create-report-name-input"
                                />
                            )}
                            {reportNameError && <div className={styles.error}>{`Report name must be in English`}</div>}
                        </div>
                        {state.type === 'limited' && state.frequency === 'onetime' && <PaidMarketing state={state} updateState={updateState} cm={cm} />}
                        {state.type === 'limited' && state.frequency !== 'onetime' && (
                            <>
                                <Frequency
                                    state={state}
                                    cm={cm}
                                    onChangeHandler={onChangeHandler}
                                    updateState={updateState}
                                    scheduleReportsOnly={scheduleReportsOnly}
                                    data={data}
                                    frequencyList={frequencyList}
                                />
                                <div className={styles.inputContainer} data-test-selector="exportables-create-report-format">
                                    <div className={styles.label}>{cm.get('FileFormat')}</div>
                                    <Select
                                        cm={cm}
                                        selected={state.format}
                                        name="format"
                                        onChange={onChangeHandler}
                                        options={fileFormats}
                                        selector="exportables-create-report-format-select"
                                    />
                                </div>
                            </>
                        )}
                        {state.type !== 'limited' && (
                            <>
                                <Frequency
                                    state={state}
                                    cm={cm}
                                    onChangeHandler={onChangeHandler}
                                    updateState={updateState}
                                    scheduleReportsOnly={scheduleReportsOnly}
                                    data={data}
                                    frequencyList={frequencyList}
                                />
                                {showSettings(state.type) && <Settings cm={cm} state={state} updateState={updateState} onChangeHandler={onChangeHandler} channels={channels} />}
                                <div className={styles.inputContainer} data-test-selector="exportables-create-report-format">
                                    <div className={styles.label}>{cm.get('FileFormat')}</div>
                                    <Select
                                        cm={cm}
                                        selected={state.format}
                                        name="format"
                                        onChange={onChangeHandler}
                                        options={fileFormats}
                                        selector="exportables-create-report-format-select"
                                    />
                                </div>
                            </>
                        )}
                        {state.frequency === 'onetime' && (
                            <div className={styles.inputContainer} data-test-selector="exportables-create-report-sendTo">
                                <div className={styles.label}>{cm.get('SendTo')}</div>
                                <EmailInput cm={cm} state={state} updateState={updateState} />
                            </div>
                        )}
                        {state.frequency !== 'onetime' && <ScheduleData cm={cm} state={state} onChangeHandler={onChangeHandler} updateState={updateState} />}
                        {/* <div>
                            <FileFormatOptions name='fileFormat' value={state.fileFormat} handleFormatChange={onChangeHandler} />
                        </div> */}
                    </div>
                }
                onClick={async () => await createReport()}
            />
        </>
    );
};
