import { queryOptions } from '../../utils';
import debounce from 'lodash/debounce';
import { skewed } from '../../../../../../services/api';
import { MultiSelectCheckbox } from '../../../../../widgets/multi-select-checkbox-server';
import { mapFilters } from '@hooks';
import { useState } from 'react';
export const Filter = ({ defaultFilter, selectedFilters, handleChange, currFilter }) => {
    const [data, setData] = useState(defaultFilter);
    const [loading, setLoading] = useState(false);
    const noServerSearchFilters = ['tags', 'campaignType'];
    const loadData = inputValue => {
        const localSearchResult = localSearch(inputValue, false);
        if (!localSearchResult?.length) {
            if (inputValue && !loading) {
                const updatedQueryOptions = { ...queryOptions, filterName: currFilter, queryString: inputValue };
                setLoading(true);
                skewed.getCasFilterValuesWithSearchTerm(updatedQueryOptions).then(res => {
                    if (inputValue) {
                        const mappedData = mapFilters(res)?.data[currFilter];
                        setData(mappedData);
                    } else {
                        setData(defaultFilter);
                    }
                    setLoading(false);
                });
            } else {
                setData(defaultFilter);
            }
        }
        setData(localSearchResult);
    };

    const localSearch = (inputValue, setDataNeeded = true) => {
        let res = defaultFilter;
        if (inputValue) res = defaultFilter?.filter(item => item?.label?.toLowerCase().includes(inputValue.toLowerCase()));
        if (setDataNeeded) setData(res);
        else return res;
    };

    const search = debounce(loadData, 100);

    const onInputChangeHandler = inputValue => search(inputValue);

    return (
        <>
            {defaultFilter && (
                <MultiSelectCheckbox
                    isMulti={true}
                    options={data || []}
                    onInputChangeParent={currFilter[0] && !noServerSearchFilters.includes(currFilter[0]) ? onInputChangeHandler : localSearch}
                    onChange={handleChange}
                    passedValue={selectedFilters}
                    isSelectAll={true}
                    loading={loading}
                />
            )}
        </>
    );
};
