import React, {useEffect, useState} from "react";
import styles from './realtime.module.scss';
import server from '../../../containers/exportables/server';
import {useSelector} from "react-redux";
import ApiKeyItem from "./ApiKeyItem";
import AddApiKey from "./AddApiKey";
import { IconButton } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import widgets from '../../../widgets';
import {showDialog} from "../../../../widgets/dialog";
const { Progress } = widgets;

const ApiIntegration = ({ cm }) => {
    const { networkId } = useSelector(state => state.profile.meta);
    const [keys, setKeys] = useState([]);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true)
    const [showAddKeyComponent, setShowAddKeyComponent] = useState(false)

    useEffect(async () => {
        setLoading(true)
        await fetchData()
        server.post('realtime/get_channels', {date: new Date()})
            .then(res => {
                setTags(res.data);
                setLoading(false)
            })
    }, []);

    const fetchData = () => {
        setShowAddKeyComponent(false)
        server.post('clickTrueTags/get_api_key_data', {networkId})
            .then(res => {
                setKeys(res.data);
            })
    }

    const onDeleteHandler = (item) => {
            const dialogContent = {
                title: cm.get('RTIDeleteApiKey'),
                children: cm.get('RTIDeleteApiKeyWarning'),
                additional:  cm.get('RTIDeleteApiKeyAreYouSure'),
                userInteractive: { resolveText: cm.get('Yes'), rejectText: cm.get('Cancel') },
            };
            showGenerateKeyConfirmationDialog(dialogContent, item.id);
        console.log(item)
    }

    const showGenerateKeyConfirmationDialog = async (dialogContent, id) => {
        const confirmDialog = await showDialog(dialogContent);
        confirmDialog && deleteKey(id);
    };

    const deleteKey = (id) => {
        server.post('clickTrueTags/delete_api_key', {id})
            .then(res => {
                fetchData()
            });
    }

    const onAddHandler = () => {
        setShowAddKeyComponent(prevState => !prevState)
    }

    return (
        <div className={styles.secretKey}>
            <div className={styles.row}>
                <span className={styles.title}>{cm.get('RTIGenerateApiKey')}</span>
                <div className={styles.row} style={{marginLeft: 8}} onClick={onAddHandler} >
                    <IconButton size="small" data-test-selector="rti-settings-add-api-button">
                        <AddCircleOutlineIcon className={styles.button} fontSize="small" />
                    </IconButton>
                    <div className={styles.button}>{cm.get('RTIAddApiKey')}</div>
                </div>
            </div>
            {showAddKeyComponent &&
                <AddApiKey cm={cm}
                           tags={tags}
                           keys={keys}
                           fetchData={fetchData}
                />
            }
            {(!loading && tags) ? keys.map((item, index) => (
                <ApiKeyItem cm={cm}
                            key={index}
                            item={item}
                            index={index}
                            tags={tags}
                            onDeleteHandler={onDeleteHandler}
                />
            )) : <Progress />}
        </div>
    )
}

export default ApiIntegration;