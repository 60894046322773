import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import styles from './checkbox.module.scss';

export default ({ label, checked, onChange, selector, disabled = false, className = '' }) => {
    const classes = className ? `${styles.root} ${className}` : styles.root;
    return (
        <FormControlLabel
            label={label}
            classes={{
                root: styles.formRoot,
                label: styles.label,
            }}
            labelPlacement="end"
            control={
                <Checkbox
                    disabled={disabled}
                    data-test-selector={selector}
                    size="small"
                    className={classes}
                    checked={checked}
                    onChange={() => onChange(!checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
        />
    );
};
