import { ui as types } from '../actions';

const initialState = {
    alert: { type: '', msg: '' },
};

export const ui = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ALERT: {
            const { payload: alert } = action;
            return { ...state, alert };
        }
        case types.CLEAR_ALERT:
            return { ...state, alert: { type: '', msg: '' } };
        default:
            return state;
    }
};
