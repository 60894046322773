import moment from 'moment';

export const translateDate = dateRange => {
    if (dateRange?.startDate && dateRange?.endDate) return [moment(dateRange.startDate).startOf('day').toDate(), moment(dateRange.endDate).endOf('day').toDate()];

    switch (dateRange) {
        case 'today':
            return [moment().startOf('day').toDate(), moment().toDate()];

        case 'yesterday':
            return [moment().subtract(1, 'day').startOf('day').toDate(), moment().subtract(1, 'day').endOf('day').toDate()];

        case 'last7Days':
            return [moment().subtract(6, 'day').toDate(), moment().toDate()];

        case 'last30Days':
            return [moment().subtract(29, 'day').toDate(), moment().toDate()];

        case 'last3Months':
            return [moment().subtract(3, 'month').toDate(), moment().toDate()];

        case 'last6Months':
            return [moment().subtract(6, 'month').toDate(), moment().toDate()];

        case 'lastYear':
            return [moment().subtract(1, 'year').toDate(), moment().toDate()];

        case 'last12Months':
            return [moment().subtract(12, 'month').startOf('month').startOf('day').toDate(), moment().subtract(1, 'month').endOf('month').endOf('day').toDate()];

        default:
            return [moment().startOf('day').toDate(), moment().toDate()];
    }
};

export const dateRangeParser = (dateRanges, cm) =>
    dateRanges.map(({ label = '', value }) => {
        let labelValue = cm.get(`${label.replace(/ /g, '')}`);
        if (labelValue.startsWith('DashboardV2')) labelValue = labelValue.split('.')[1];
        return {
            value,
            label: labelValue,
        };
    });

export const translateTopPlatform = (platformName, cm) => {
    switch (true) {
        case platformName.includes('ALL PLATFORMS'):
            return platformName.replace('ALL PLATFORMS', cm.get('AllPlatforms'));
        case platformName.includes('ALL PROTECTED PLATFORMS'):
            return platformName.replace('ALL PROTECTED PLATFORMS', cm.get('AllProtectedPlatforms'));
        case platformName.includes('ALL MONITORED PLATFORMS'):
            return platformName.replace('ALL MONITORED PLATFORMS', cm.get('AllMonitoredPlatforms'));
        default:
            return platformName;
    }
};

/**
 *
 * @param {*} date date range  in array of two dates
 * @returns string that represnts the available data in the range dates according to a pivot static date
 */
export const policyToggleOffDatesInRange = (date, cm) => {
    const start = moment(date[0], 'YYYY-MM-DD HH:mm');
    const end = moment(date[1], 'YYYY-MM-DD HH:mm');
    const pivot = moment(moment.unix(process.env.REACT_APP_MIN_POLICY_DATE), 'YYYY-MM-DD HH:mm');
    if (end.isBefore(pivot)) return `${cm.get('NoAvailableDataForTheRequestedDates')}`;
    else if (start.isAfter(pivot)) {
        return '';
    } else {
        return `${cm.get('AvailableDataFrom')} ${pivot.format('MMM D, YYYY')}`;
    }
};
