import * as actions from '../actions/analytics';
import { LEAD_PROTECTION, CHEQ_ACQUISITION_ROUTE, CHEQ_ANALYTICS_ROUTE, CHEQ_DEFEND } from '../../utils/common';
import { SLP_REPORT_TYPE } from '../../utils/analytics/helpers';

export const openAnalyticsWithPresetReportParams = (summaryReportSettings, naviagte, selectedLicense, isRTI, isSlp) => {
    return dispatch => {
        dispatch(actions.setSummaryReportSettings(summaryReportSettings, isRTI, isSlp));
        switch (selectedLicense) {
            case 1: {
                naviagte(`/${CHEQ_ACQUISITION_ROUTE}/reports`);
                break;
            }
            case 2: {
                naviagte(`/${CHEQ_DEFEND}}/reports`);
                break;
            }
            case 3: {
                naviagte(`/${CHEQ_ANALYTICS_ROUTE}}/reports`);
                break;
            }
            case 8: {
                naviagte(`/${LEAD_PROTECTION}/reports`);
                break;
            }
            default: {
                naviagte(`/${CHEQ_ACQUISITION_ROUTE}/reports`);
                break;
            }
        }
    };
};

const CAMPAIGN_TYPE_MAPPER = {
    g: 'Google Search',
    s: 'Search Partner',
    d: 'Google Display ',
    yt: 'YouTube',
    ytv: 'Youtube',
    u: 'Smart Shopping',
    x: 'Performance Max',
    fb: 'Facebook',
    ig: 'Instagram',
    msg: 'Messenger',
    an: 'Audience Network',
    o: 'Owned and Operated ',
    a: 'Microsoft Audience Network placements',
};
export const platfromsNamesFromDB = {
    googlePaid: 'GOOGLE PAID',
    duckduckgoOrganic: 'DUCKDUCKGO ORGANIC',
};

export const MAP_PLATFORMS_CUSTOM_NAME = {
    [platfromsNamesFromDB.googlePaid]: 'Google Search',
    [platfromsNamesFromDB.duckduckgoOrganic]: 'DuckDuckGo Organic',
};

export const getPlatformLabel = platformNameFromDb => {
    if (Object.values(platfromsNamesFromDB).includes(platformNameFromDb)) {
        return MAP_PLATFORMS_CUSTOM_NAME[platformNameFromDb];
    }
    return getUpperCaseFirstLetterEachWord(platformNameFromDb);
};

const getUpperCaseFirstLetterEachWord = str => {
    return str
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
        .join(' ');
};

export const campaignTypeNameConverter = campaignTypeName => {
    return Object.keys(CAMPAIGN_TYPE_MAPPER).includes(campaignTypeName) ? CAMPAIGN_TYPE_MAPPER[campaignTypeName] : campaignTypeName;
};
export const getSummary = params => async (dispatch, _, api) => {
    dispatch(actions.analyticsDataLoading());
    try {
        const data = await api.analytics.getSummary(params);
        if (data && data.reportResults && data.reportType !== SLP_REPORT_TYPE) {
            const reportResults = data.reportResults.reduce((acc, obj) => {
                acc.push({
                    ...obj,
                    ['campaignType']: campaignTypeNameConverter(obj.campaignType),
                });
                return acc;
            }, []);
            data.reportResults = reportResults;
        }
        dispatch(actions.analyticsDataUpdated(data));
    } catch (err) {
        dispatch(actions.analyticsRequestFailed(err));
    }
};

export const getAllPlatformsGroup = (date = new Date()) => {
    return async (dispatch, _, api) => {
        dispatch(actions.analyticsDataLoading());
        try {
            return await api.realtime.getPlatformsGroups(date);
        } catch (error) {
            dispatch(actions.analyticsRequestFailed(error));
        }
    };
};
