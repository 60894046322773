import { useRef, useState, useEffect } from 'react';
import * as Styled from './select.styled';
import arrowIcon from '../arrow_down.svg';
import searchIcon from '../search.svg';
import Checkbox from '../../../../../../../widgets/checkbox';
import { LongTextWithTooltip } from '../long-text-with-tooltip';
import { SelectItem, FilterWidth, FilterHeight } from '../types';
import { useOnClickOutside } from '../hooks';
import { filterBySearch } from './utils';

type SelectProps = {
    testSelector?: string;
    label?: string;
    options?: SelectItem[];
    selected: SelectItem | null;
    onSelect: (selected: SelectItem | null) => void;
    placeHolder?: string;
    searchPlaceholder?: string;
    isSearch?: boolean;
    width?: FilterWidth.NARROW | FilterWidth.WIDE;
    labelWidthStyle?: 'normal' | 'extended';
    readonly?: boolean;
    height?: FilterHeight.NARROW;
    isResponsive?: boolean;
    showCheckbox?: boolean;
};

const FOCUS_DELAY = 300;

export const Select = ({
    testSelector = 'test-selector',
    label,
    options = [],
    selected,
    onSelect,
    placeHolder,
    searchPlaceholder = 'Enter Text',
    isSearch = true,
    width = FilterWidth.WIDE,
    height,
    labelWidthStyle = 'normal',
    readonly = false,
    isResponsive,
    showCheckbox = true,
}: SelectProps) => {
    const [opened, setOpened] = useState(false);
    const searchRef = useRef<HTMLInputElement>(null);

    const [searchedValue, setSearchedValue] = useState('');
    const [filterOptions, setFilterOptions] = useState(options);

    const container = useRef(null);
    useOnClickOutside(container, () => {
        setOpened(false);
    });

    const focusOnSearch = () => {
        setTimeout(() => {
            if (searchRef.current != null) {
                searchRef.current.focus();
            }
        }, FOCUS_DELAY);
    };

    const handleOpen = () => {
        const shouldOpen = !opened;
        setOpened(shouldOpen);
        if (shouldOpen) {
            focusOnSearch();
        }
    };

    useEffect(() => {
        setFilterOptions(options);
    }, [options, opened]);

    useEffect(() => {
        if (!isSearch) return;
        if (!searchedValue) {
            return setFilterOptions(options);
        }
        const filterResult = filterBySearch(options, searchedValue.split('-').join(''));
        setFilterOptions(filterResult);
    }, [isSearch, searchedValue, options]);

    // -------------------------------------- //

    return (
        <Styled.Select
            ref={container}
            activeStyle={opened}
            width={isResponsive ? { '@laptop': 'narrow', '@desktop': 'wide' } : width}
            height={height}
            className="multi-select"
            readonly={readonly}
        >
            <Styled.Input height={height} aria-hidden onClick={handleOpen}>
                <Styled.Label>
                    {label ? `${label}:` : ''}
                    {!selected ? (
                        <Styled.InputValue placeHolder>{placeHolder}</Styled.InputValue>
                    ) : (
                        <Styled.InputValue labelWidthStyle={isResponsive ? { '@desktop': labelWidthStyle, '@laptop': 'normal' } : labelWidthStyle}>
                            <LongTextWithTooltip content={selected.label?.toString() || ''} />
                        </Styled.InputValue>
                    )}
                </Styled.Label>
                <img aria-hidden src={arrowIcon} onClick={() => setOpened(!opened)} alt="icon" />
            </Styled.Input>
            {opened && (
                <>
                    <Styled.Dropdown data-test-id={`${testSelector}-filters-dropdown`}>
                        <Styled.DropDownList>
                            {isSearch && (
                                <Styled.SearchBox>
                                    <img src={searchIcon} alt="search" />
                                    <input
                                        ref={searchRef}
                                        className="input"
                                        placeholder={`${searchPlaceholder}...`}
                                        value={searchedValue}
                                        onChange={e => setSearchedValue(e.target.value)}
                                        data-test-id={`${testSelector}-filter-search"`}
                                    />
                                </Styled.SearchBox>
                            )}
                            {filterOptions?.map((item, index) =>
                                showCheckbox ? (
                                    <Styled.SingleDropDownItem key={index}>
                                        <Checkbox
                                            style={{ gap: '1em', color: 'white' }}
                                            onChange={() => {
                                                //toggle selected checkbox
                                                onSelect(item === selected ? null : item);
                                            }}
                                            checked={selected?.value === item.value}
                                            label={item.label}
                                            disabled={item.disabled}
                                        />
                                    </Styled.SingleDropDownItem>
                                ) : (
                                    <Styled.SingleDropDownItem
                                        key={index}
                                        onClick={() => {
                                            onSelect(item);
                                            setOpened(false);
                                        }}
                                        disabled={!!item.disabled}
                                    >
                                        {item.label}
                                    </Styled.SingleDropDownItem>
                                )
                            )}
                        </Styled.DropDownList>
                    </Styled.Dropdown>
                </>
            )}
        </Styled.Select>
    );
};
