import { REPORT_TYPE_DICT } from '../constants';

const EXTENDED_RESPONSE_CLIENT = { id: 8, label: 'ExtendedResponseClientReport', value: 'extended_response_client_report' };
const EXTENDED_RESPONSE_RTI = { id: 7, label: 'ExtendedResponseRTI', value: 'comprehensive_detection_report' };
const USER_VALIDATION = { id: 6, label: REPORT_TYPE_DICT.UDV.label, value: REPORT_TYPE_DICT.UDV.value };

export const filterItems = [
    { id: 1, name: 'Type', type: 'type', show: true },
    { id: 2, name: 'Created by', type: 'users', show: true },
    { id: 3, name: 'Frequency', type: 'frequency', show: true },
    { id: 4, name: 'Status', type: 'status', show: true },
];

export const statusFilter = [
    { id: 1, label: 'Completed', value: 'completed' },
    { id: 2, label: 'CompletedNoData', value: 'completednodata' },
    { id: 3, label: 'Running', value: 'running' },
    { id: 4, label: 'Failed', value: 'failed' },
    { id: 5, label: 'Queued', value: 'queued' },
    { id: 6, label: 'Cancelled', value: 'cancelled' },
];

export const reportTypesPaidMarketingOnly = [
    { id: 1, label: 'Acquisition', value: 'limited' },
    { id: 4, label: 'AffiliateFraud', value: 'affiliate_fraud' },
    { id: 5, label: 'InvalidAffiliateVisits', value: 'affiliate_fraud_invalid' },
];

export const getReportTypesOnsiteConversion = (limitExtendedReportsToOss, showComprehensiveDetectionReport, showExtendedResponseClientReport, isNewSlpDashboardOn) => {
    const reports = [];
    if (!isNewSlpDashboardOn) {
        reports.push(USER_VALIDATION);
    }
    if (limitExtendedReportsToOss) {
        reports.push(EXTENDED_RESPONSE_CLIENT);
        reports.push(EXTENDED_RESPONSE_RTI);
    } else {
        getExtendedReportTypes(reports, showComprehensiveDetectionReport, showExtendedResponseClientReport);
    }

    return reports;
};

export const getReportTypesMIS = (limitExtendedReportsToOss, showExtendedResponseClientForMIS, showComprehensiveDetectionReport, showExtendedResponseClientReport) => {
    const reports = [
        { id: 2, label: 'StandardReportType', value: 'standard' },
        { id: 3, label: 'ExtendedReportType', value: 'extended' },
    ];
    if (limitExtendedReportsToOss && showExtendedResponseClientForMIS) {
        reports.push(EXTENDED_RESPONSE_CLIENT);
    }
    if (!limitExtendedReportsToOss) {
        getExtendedReportTypes(reports, showComprehensiveDetectionReport, showExtendedResponseClientReport);
    }
    return reports;
};

export const getReportTypesSLP = () => {
    return [USER_VALIDATION];
};

const getExtendedReportTypes = (reports, showComprehensiveDetectionReport, showExtendedResponseClientReport) => {
    if (showComprehensiveDetectionReport) {
        reports.push(EXTENDED_RESPONSE_RTI);
    }
    if (showExtendedResponseClientReport) {
        reports.push(EXTENDED_RESPONSE_CLIENT);
    }
    return reports;
};

export const frequencyList = [
    { id: 1, label: 'Frequency_Onetime', value: 'onetime' },
    { id: 2, label: 'Frequency_Daily', value: 'daily' },
    { id: 3, label: 'Frequency_Weekly', value: 'weekly' },
    { id: 4, label: 'Frequency_Monthly', value: 'monthly' },
];

export const frequencyListMonthlyOnly = [{ id: 1, label: 'Frequency_Monthly', value: 'monthly' }];

export const frequencyScheduleList = [
    { id: 1, label: 'Frequency_Daily', value: 'daily' },
    { id: 2, label: 'Frequency_Weekly', value: 'weekly' },
    { id: 3, label: 'Frequency_Monthly', value: 'monthly' },
];

export const createReportTypes = [
    { id: 1, label: 'PaidMarketing', value: 'limited' },
    { id: 2, label: 'StandardReportType', value: 'standard' },
    { id: 3, label: 'ExtendedReportType', value: 'extended' },
    { id: 4, label: 'AffiliateFraud', value: 'affiliate_fraud' },
    { id: 5, label: 'InvalidAffiliateVisits', value: 'affiliate_fraud_invalid' },
];

export const CLOUD_STORAGE_PROVIDER_OPTIONS = [
    {
        value: 'aws',
        label: 'AWS S3',
    },
    {
        value: 'backblaze',
        label: 'BackBlaze',
    },
];

export const getTooltip = type => {
    switch (type) {
        case 'limited':
            return 'PaidMarketingDescription';
        case 'standard':
            return 'ReportTypeStandartInfo';
        case 'extended':
            return 'ReportTypeExtendedInfo';
        case 'affiliate_fraud':
        case 'affiliate_fraud_invalid':
            return 'AffiliateFraudDescription';
        case REPORT_TYPE_DICT.UDV.value:
            return REPORT_TYPE_DICT.UDV.label;
        case 'comprehensive_detection_report':
            return 'ExtendedResponseRTI';
        case 'extended_response_client_report':
            return 'ExtendedResponseClientReport';
        default:
            return '';
    }
};

export const getFormOptions = type => {
    return {
        format:
            type === 'comprehensive_detection_report' || type === 'extended_response_client_report'
                ? [{ value: 'LineAsString', label: 'JSON' }]
                : [
                      { value: 'CSV', label: 'CSV' },
                      { value: 'CSVWithNames', label: 'CSVWithNames' },
                      { value: 'TabSeparated', label: 'TSV' },
                      { value: 'TabSeparatedWithNames', label: 'TSVWithNames' },
                  ],
        dateRange: [
            {
                label: 'Today',
                value: 'today',
                details: { type: 'days', range: 0 },
            },
            {
                label: 'Yesterday',
                value: 'yesterday',
                details: { type: 'days', range: 1 },
            },
            {
                label: 'Last 7 days',
                value: 'last7days',
                details: { type: 'days', range: 7 },
            },
            {
                label: 'Last 30 days',
                value: 'last30days',
                details: { type: 'days', range: 30 },
            },
            {
                label: 'Last 3 months',
                value: 'last3months',
                details: { type: 'months', range: 3 },
            },
            {
                label: 'Last 6 months',
                value: 'last6months',
                details: { type: 'months', range: 6 },
            },
            {
                label: 'Custom',
                value: 'Custom',
            },
        ],
    };
};

export const isCreateReportDisabled = state => {
    const nameIsEmpty = state.name.length === 0;
    if (state.type === 'limited' && state.frequency === 'onetime') {
        if (state.startDate === '' || state.endDate === '') return true;
    }
    if (state.frequency === 'onetime') {
        return nameIsEmpty;
    } else {
        if (state.destination === 'external') {
            return (
                nameIsEmpty ||
                state.bucketName?.length === 0 ||
                state.bucketRegion?.length === 0 ||
                state.accessSecret?.length === 0 ||
                state.accessKey?.length === 0 ||
                !state.testConnection
            );
        } else {
            return nameIsEmpty || state.emails.length === 0;
        }
    }
};

export const getUsersFilter = reports => {
    let idx = 1;
    let users =
        reports &&
        reports.map(report => {
            return { id: idx++, label: report.createdBy };
        });
    if (users && users.length > 0) {
        users = [...new Map(users.map(item => [item['label'], item])).values()];
        return users;
    } else {
        return [];
    }
};

export const parseFilters = ({ selected }) => {
    let queryString = [];
    if (selected) {
        if (selected.searchValue.length > 0) {
            queryString.push(`searchValue=${selected.searchValue}`);
        }
        if (selected.type.length > 0) {
            const typeStr = selected.type.map(type => `'${type.value}'`).join(',');
            queryString.push(`type=${typeStr}`);
        }
        if (selected.status.length > 0) {
            const statusStr = selected.status.map(status => `'${status.value}'`).join(',');
            queryString.push(`status=${statusStr}`);
        }
        if (selected.frequency.length > 0) {
            const freqStr = selected.frequency.map(frequency => frequency.value).join(',');
            queryString.push(`frequency=${freqStr}`);
        }
        if (selected.users.length > 0) {
            const usersStr = selected.users.map(user => `'${user.label}'`).join(',');
            queryString.push(`createdBy=${usersStr}`);
        }
    }
    return queryString.join('&');
};

export const getReportList = (
    selectedLicense,
    isAffiliateFraudEnabled,
    showComprehensiveDetectionReport,
    showExtendedResponseClientReport,
    showExtendedResponseClientForMIS,
    limitExtendedReportsToOss,
    isNewSlpDashboardOn
) => {
    let finalReportTypes = [];
    switch (selectedLicense) {
        case 1:
            finalReportTypes = reportTypesPaidMarketingOnly;
            if (!isAffiliateFraudEnabled) {
                finalReportTypes = finalReportTypes.filter(type => type.id !== 4 && type.id !== 5);
            }
            break;
        case 2:
            finalReportTypes = getReportTypesOnsiteConversion(limitExtendedReportsToOss, showComprehensiveDetectionReport, showExtendedResponseClientReport, isNewSlpDashboardOn);
            break;
        case 3:
            finalReportTypes = getReportTypesMIS(limitExtendedReportsToOss, showExtendedResponseClientForMIS, showComprehensiveDetectionReport, showExtendedResponseClientReport);
            break;
        case 8:
            finalReportTypes = getReportTypesSLP();
            break;
        default:
            break;
    }
    return finalReportTypes;
};

export const getDefaultSelectedFilters = (selected, finalReportTypes, filterFrequencyList) => {
    if (!selected) {
        selected = {
            type: finalReportTypes,
            users: [],
            status: [],
            frequency: filterFrequencyList,
            searchValue: '',
        };
    } else {
        if (selected?.type.length === 0) {
            selected.type = finalReportTypes;
        }
        if (selected?.frequency.length === 0) {
            selected.frequency = filterFrequencyList;
        }
    }
    return selected;
};
