/**
 * this file is auto generated by chopin-scripts.
 * you can check the project package.json
 * to see how it was generated.
 */

import Baidu from './baidu';
import Bing from './bing';
import Facebook from './facebook';
import Google from './google';
import Yandex from './yandex';
import Criteo from './criteo';
import Duckduckgo from './duckduckgo';
import Instagram from './instagram';
import Linkedin from './linkedin';
import Pinterest from './pinterest';
import Twitter from './twitter';
import Yahoo from './yahoo';
import Youtube from './youtube';

export default {
    Baidu,
    Bing,
    Yandex,
    Criteo,
    Duckduckgo,
    Instagram,
    Linkedin,
    Pinterest,
    Twitter,
    Yahoo,
    Youtube,
    Facebook,
    Google,
};
