import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
        boxSizing: 'border-box',
    },
    progress: {
        margin: '0 auto',
    },
}));

export const ProgressBar = ({ speed = 20, className, absolute, ...props }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{ position: absolute ? 'absolute' : 'relative' }}>
            <CircularProgress className={`${classes.progress} ${className}`} color="secondary" {...props} />
        </div>
    );
};
