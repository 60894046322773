const def = {
    columns: [],
    Actions: null,
    RenderHead: null,
    RenderBody: null
}
export default (...args) => ({ viewFeature }) => viewFeature(`${nameByType(...args)}Table`, methodByType(...args));
const nameByType = arg => arg.name || arg;
const methodByType = (arg, ...rest) => function () {
    switch (typeof arg) {
        case 'string': return methodByType(...rest).call(this);
        case 'function': return handleByName.call(this, arg);
        case 'object': return handleByName.call(this, () => arg);
        default: throw new Error('tableable') // TODO handel
    }
}
function handleByName(options = () => def) {
    return {
        tableable: true,
        ...options.call(this)
    }
}