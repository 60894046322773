import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClosedEyeIcon from '../../../svgs/close-view';
import CopyIcon from '../../../svgs/copy';

import EyeIcon from '../../../svgs/view';
import ToolTip from '../../../../widgets/tool-tip';
import copy from 'clipboard-copy';
import { generateFeatureKey } from '@redux/thunks/settings';
import { showDialog } from '../../../../widgets/dialog';
import { useContentManager } from '@hooks';
import widgets from '../../../widgets';
import { Checkbox, FormControlLabel, TextField, IconButton, Divider } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Toggler } from '@widgets';
import Url from './Url';
import { v4 as uuidv4 } from 'uuid';
import styles from './realtime.module.scss';
import { isValidURL } from '@utils/common';

import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import Tooltip from '@components/widgets/tooltip';

import api from '@services/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSplit } from '@hooks';
import { constants } from '@utils/split.io';
import * as _ from 'lodash';
import ApiIntegration from './ApiIntegration';
import {PlayArrow} from "@material-ui/icons";
const { Button, Progress } = widgets;

const RealTimeInterception = ({ networkFeatureId }) => {
    const dispatch = useDispatch();
    const cm = useContentManager();
    const queryClient = useQueryClient();

    const urlInputRef = useRef();
    const { isOn: isRTISettingsOn } = useSplit(constants.SPLIT_RTI_DASHBOARD);
    const { isOn: isRTIGenerateKeyOn } = useSplit(constants.SPLIT_RTI_GENERATE_KEY);

    const { isLoading: isLoadingRtiSettings, data } = useQuery(['rti_settings'], api.settings.getRtiSettings);

    const mutation = useMutation(
        state => {
            api.settings.setRtiSettings(state);
        },
        {
            onSuccess: (_, variables) => {
                queryClient.setQueryData(['rti_settings'], variables);
            },
        }
    );

    const [isIncludeAllSubdomains, setIncludeAllSubdomains] = useState(false);
    const [state, setState] = useState(undefined);

    const [copied, setCopied] = useState(false);
    const [revealSecretKey, setRevealSecretKey] = useState(false);
    const [isURLErr, setIsURLErr] = useState(false);

    const { isLoading, rtiToken: secretKey } = useSelector(state => state.settings);

    const Icon = revealSecretKey ? EyeIcon : ClosedEyeIcon;
    const secretKeyButtonText = secretKey ? cm.get('ReGenerateSecretKey') : cm.get('GenerateSecretKey');
    const secretKeyInputText = revealSecretKey ? secretKey : secretKey?.replaceAll(/./g, '●');

    useEffect(() => {
        if (data) {
            const newData = _.cloneDeep(data);
            setState({ ...newData });
        }
    }, [data]);

    useEffect(() => {
        if (state !== undefined && data !== undefined && JSON.stringify(data) !== JSON.stringify(state)) {
            submitHandler(state);
        }
    }, [state]);

    const generateSecretKey = () => {
        setRevealSecretKey(true);
        setCopied(false);
        dispatch(generateFeatureKey(networkFeatureId));
    };

    const handleOnGenerateSecretKey = () => {
        const dialogContent = {
            title: cm.get('ResetSecretKey'),
            children: cm.get('ResetSecretKeyQuestion'),
            userInteractive: { resolveText: cm.get('Yes'), rejectText: cm.get('Cancel') },
        };
        secretKey ? showGenerateKeyConfirmationDialog(dialogContent) : generateSecretKey();
    };

    const showGenerateKeyConfirmationDialog = async dialogContent => {
        const confirmDialog = await showDialog(dialogContent);
        !!confirmDialog && generateSecretKey();
    };

    const handleOnSecretKeyCopy = () => {
        copy(secretKey);
        setCopied(true);
    };

    const onChangeIntegrationHandler = event => {
        setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));
    };

    const addUrlHandler = () => {
        const value = urlInputRef.current?.value?.trim();

        if (value) {
            if (!isValidURL(value)) {
                setIsURLErr(true);
                return;
            }
            const newUrl = {
                id: uuidv4(),
                value,
                isIncludeAllSubdomains,
            };
            setState(prevState => ({
                ...prevState,
                urls: [newUrl, ...prevState.urls],
            }));
            urlInputRef.current.value = '';
            setIncludeAllSubdomains(false);
            setIsURLErr(false);
        } else {
            setIsURLErr(false);
        }
    };

    const deleteUrlHandler = value => {
        const urls = state.urls.filter(url => url.id !== value);
        setState(prevState => ({
            ...prevState,
            urls,
        }));
    };

    const updateUrlHandler = url => {
        const urls = [...state.urls];
        let index = urls.findIndex(item => item.id === url.id);

        urls[index] = { ...url };

        setState(prevState => ({
            ...prevState,
            urls,
        }));
    };

    const checkUrlErr = e => {
        !e.target?.value?.trim() && setIsURLErr(false);
    };

    const submitHandler = () => {
        mutation.mutate(state);
    };

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{cm.get('RealtimeInterception')}</div>
            </div>
            {isRTISettingsOn &&
                (isLoadingRtiSettings || state === undefined ? (
                    <Progress />
                ) : (
                    <div className={styles.integrationsContainer}>
                        <div>{cm.get('PleaseSelectTypeOfRealTimeInterception')}</div>
                        <div className={styles.togglers}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={state?.isApiIntegration}
                                        onChange={onChangeIntegrationHandler}
                                        name={'isApiIntegration'}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={
                                    <div className={styles.label}>
                                        <span>{cm.get('ApiIntegration')}</span>
                                        <Tooltip content={cm.get('ApiIntegrationDescription')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </div>
                                }
                            />
                            {state?.isApiIntegration && <ApiIntegration cm={cm}/>}
                            <FormControlLabel
                                classes={{ label: styles.label }}
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={state?.isClientSideIntegration}
                                        onChange={onChangeIntegrationHandler}
                                        name={'isClientSideIntegration'}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={
                                    <div className={styles.label}>
                                        <span>{cm.get('ClientSideIntegration')}</span>
                                        <Tooltip content={cm.get('ClientSideIntegrationDescription')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                ))}
            {isLoadingRtiSettings || state === undefined ? (
                <Progress />
            ) : (
                (state?.isClientSideIntegration || isRTIGenerateKeyOn) && (
                    <div className={styles.secretKey}>
                        <span className={styles.title}>{cm.get('GenerateSecretKeyTitle')}</span>
                        <div className={styles.buttonGroupContainer}>
                            <div className={styles.row}>
                                <IconButton size="small" onClick={handleOnGenerateSecretKey} data-test-selector="rti-settings-add-api-key-button">
                                    <PlayArrow className={styles.button} fontSize="small" />
                                </IconButton>
                                <div className={styles.button}>{secretKeyButtonText}</div>
                            </div>
                            <div className={styles.textContainer}>
                                <div className={styles.text}>{!isLoading && secretKeyInputText}</div>
                                {!isLoading && secretKey && (
                                    <div onClick={() => setRevealSecretKey(!revealSecretKey)} className={styles.icon}>
                                        <Icon />
                                    </div>
                                )}
                            </div>

                            {!isLoading && revealSecretKey && (
                                <div className={styles.copyBtnContainer}>
                                    <ToolTip content={copied ? cm.get('Copied') : cm.get('CopyToClipboard')}>
                                        <div>
                                            <CopyIcon onClick={handleOnSecretKeyCopy} className={styles.icon} />
                                        </div>
                                    </ToolTip>
                                </div>
                            )}
                        </div>

                        <div className={styles.infoContainer}>
                            <InfoIcon />
                            <div className={styles.infoContent}>
                                <div className={styles.text}>{cm.get('RealtimeInfo')}</div>
                                <div className={styles.text}>{cm.get('RealtimeCopyInfo')}</div>
                            </div>
                        </div>
                    </div>
                )
            )}
            {isRTISettingsOn &&
                (isLoadingRtiSettings || state === undefined ? (
                    <Progress />
                ) : (
                    state?.isClientSideIntegration && (
                        <>
                            <div className={styles.urls}>
                                <div className={styles.title}>
                                    <span>{cm.get('EnterTheUrlsWhereInterceptionsWillBePerformed')}</span>
                                    <Tooltip content={cm.get('EnterUrlsDescription')}>
                                        <InfoIcon cursor="pointer" />
                                    </Tooltip>
                                </div>
                                <div className={styles.includeSubDomains}>
                                    {cm.get('IncludeAllSubdomains')}
                                    <Tooltip content={cm.get('IncludeAllSubdomainsDescription')}>
                                        <InfoIcon cursor="pointer" />
                                    </Tooltip>
                                </div>
                                <div className={styles.addUrl}>
                                    <div className={styles.input}>
                                        <TextField
                                            required
                                            inputRef={urlInputRef}
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            data-test-selector={'rti-settings-add-url-input'}
                                            placeholder={cm.get('EnterNewURL')}
                                            classes={{ root: styles.marginDense }}
                                            error={isURLErr}
                                            onKeyPress={e => e.key === 'Enter' && addUrlHandler()}
                                            onChange={checkUrlErr}
                                        />
                                        <div className={styles.actions}>
                                            <IconButton size="small" onClick={addUrlHandler} data-test-selector="rti-settings-add-url-button">
                                                <AddCircleOutlineIcon className={styles.addUrlButton} fontSize="small" />
                                            </IconButton>
                                        </div>
                                    </div>
                                    {isURLErr && <div className={styles.error}>{cm.get('NotValidURL')}</div>}
                                </div>
                                <div className={styles.toggler}>
                                    <Toggler
                                        data-test-selector="rti-settings-add-url-include-all-subdomains"
                                        checked={isIncludeAllSubdomains}
                                        onChange={(e, checked) => setIncludeAllSubdomains(checked)}
                                    />
                                </div>
                                <div className={styles.divider}>
                                    <Divider className={styles.hr} />
                                </div>
                                {state?.urls?.map((url = {}, index) => {
                                    return (
                                        <Url
                                            index={index}
                                            cm={cm}
                                            key={url.id}
                                            updateUrl={updateUrlHandler}
                                            url={url}
                                            className={styles.url}
                                            deleteUrl={() => deleteUrlHandler(url.id)}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )
                ))}
        </div>
    );
};

export default RealTimeInterception;
