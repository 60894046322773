import React, { useEffect } from 'react';
import { TABS_OPTIONS } from '@utils/skewed';
import Reports from './reports';
import ScheduleReport from './scheduled-reports';
import { licenses } from '@utils/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useContentManager } from '@hooks';
import { setTab } from '@redux/actions/skewed';
import styles from './exportables.module.scss';
import Header from '../header';
import mixpanel from '@services/mixpanel';
import { Tab, Tabs } from '@material-ui/core';
import classNames from 'classnames/bind';
import { useSplit } from '../../../hooks';
import { constants } from '../../../utils/split.io';

const cx = classNames.bind(styles);

let tabsData = [
    {
        value: TABS_OPTIONS.OnDemand,
        dataTest: 'generate report tab',
        component: props => <Reports {...props} />,
        permissions: [licenses.PaidMarketing.id, licenses.AdvancedAnalytics.id],
    },
    {
        value: TABS_OPTIONS.Scheduele,
        dataTest: 'schedule report tab',
        component: props => <ScheduleReport {...props} />,
        permissions: [licenses.PaidMarketing.id, licenses.AdvancedAnalytics.id],
    },
];

const Exportables = () => {
    const dispatch = useDispatch();
    const { isOn: showReportSchedulerInCas } = useSplit(constants.PARADOME_SHOW_REPORT_SCHEDULER_IN_CAS);
    const { selectedLicense } = useSelector(state => state.settings);

    const cm = useContentManager();
    const networkFeatures = useSelector(state => state.settings.networkFeatures);

    if (!showReportSchedulerInCas) {
        tabsData = selectedLicense === 1 ? [tabsData[0]] : tabsData;
    }
    const { isAffiliateFraudEnabled } = JSON.parse(localStorage.getItem('networkSettings'))
    const parsedTabsData = (selectedLicense === 1 && isAffiliateFraudEnabled === 0) ? [tabsData[0]] : tabsData;
    const { selectedTab = tabsData[0]?.value } = useSelector(state => state.skewed);
    const tabs = parsedTabsData.map(({ value }, i) => (
        <Tab key={i} classes={{ root: cx('tab'), wrapper: styles.wrapper }} label={cm.get(value)} value={value} className={styles.tab} />
    ));

    const handleTabChange = (_, value) => {
        dispatch(setTab(value));
        if (value === TABS_OPTIONS.Scheduele) mixpanel.postEvent('Exportable schedule report tab click', {});
        else mixpanel.postEvent('Exportable report tab click', {});
    };

    useEffect(() => {
        if (performance.navigation.type === 1) {
            const object = {
                'Page name': 'Exportable',
                'Navigation type': 'Refresh',
            };

            mixpanel.postEvent('PageView', object);
        }

        const zendeskWidget = document.getElementById('launcher');
        if (zendeskWidget && zendeskWidget.style) {
            zendeskWidget.style.display = 'none';
        }
        return () => {
            if (zendeskWidget && zendeskWidget.style) {
                zendeskWidget.style.display = 'block';
            }
        };
    }, []);

    return (
        <>
            <Header title={cm.get('DataTools')} data-test-selector="exportables header" />
            <div className={styles.root}>
                <div className={styles.header}>
                    <Tabs
                        data-test-selector="exportables header tabs"
                        onChange={handleTabChange}
                        value={selectedTab}
                        classes={{ flexContainer: cx('flex-container'), indicator: cx('indicator'), root: cx('root') }}
                    >
                        {tabs}
                    </Tabs>
                </div>
                {parsedTabsData.map(
                    ({ value, component: Component, dataTest }, i) =>
                        value === selectedTab && (
                            <div key={`${value}_${i}`} data-test-selector={dataTest} className={styles.content}>
                                <Component {...{ cm, networkFeatures }} />
                            </div>
                        )
                )}
            </div>
        </>
    );
};

export default Exportables;
