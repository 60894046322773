import React, { useState } from 'react';
import moment from 'moment-timezone';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import Toast from '../../../services/notifications/index';
import 'react-calendar/dist/Calendar.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DatePiker from '../date-piker';
import Slider from '../slider';
import useStyle from './style';
import ContentManager from '../../../../src/services/content-manager';

import widgets from '../../widgets/button';

const { Button } = widgets;

const DateTimeRange = ({ date = {}, onSubmit, asString = parseCustomDate }) => {
    const [state, setState] = useState(toDate(date));
    const cm = ContentManager.by('DateTimeRange');
    const errors = () => {
        return [
            !state.startDate || !state.startDate instanceof Date ? cm.get('Error.SelectStartDate') : null,
            !state.endDate || !state.endDate instanceof Date ? cm.get('Error.SelectEndDate') : null,
            state.endDate instanceof Date &&
            state.startDate instanceof Date &&
            !getMomentDate(state.endDate, state.endTime).isAfter(getMomentDate(state.startDate, state.startTime))
                ? cm.get('Error.DatesMustBeDifferent')
                : null,
        ].filter(val => !!val);
    };
    const { container, containerTitle, periodTitle, closeButton } = useStyle();
    const formatTimeLabel = value => moment(value, 'HH:00').format('HH:00');

    const SelectDate = ({ key, ...props }) => <DatePiker date={state[key]} onChange={date => setState(s => ({ ...s, [key]: date }))} {...props} />;

    const SelectTime = ({ key, min, max, ...props }) => (
        <Slider formatLabel={formatTimeLabel} max={max || 23} min={min || 0} step={1} value={state[key]} onChange={time => setState(s => ({ ...s, [key]: time }))} {...props} />
    );

    return (
        <div className={container}>
            <HighlightOffIcon onClick={() => onSubmit()} className={closeButton} style={{ color: '#fe0072', marginLeft: '5px' }} />
            <div className={containerTitle}>{cm.get('CustomDateRange')}</div>

            <div className={periodTitle}>{cm.get('FromDate')}</div>
            <Expansion
                title={(state.startDate && moment(state.startDate).format('YYYY-MM-DD')) || cm.get('Date')}
                children={SelectDate({
                    key: 'startDate',
                    maxDate: state.endDate,
                })}
                Icon={<CalendarTodayOutlinedIcon />}
                value={state.startDate}
            />
            <Expansion
                title={(state.startTime !== undefined && formatTimeLabel(state.startTime)) || cm.get('Time')}
                children={SelectTime({
                    key: 'startTime',
                    max: state.endDate && state.startDate && state.endDate.toString() === state.startDate.toString() && state.endTime,
                })}
                Icon={<ScheduleOutlinedIcon />}
                value={state.startTime}
            />

            <div className={periodTitle}>{cm.get('ToDate')}</div>
            <Expansion
                title={(state.endDate && moment(state.endDate).format('YYYY-MM-DD')) || cm.get('Date')}
                children={SelectDate({
                    key: 'endDate',
                    minDate: state.startDate,
                })}
                Icon={<CalendarTodayOutlinedIcon />}
                value={state.endDate}
            />
            <Expansion
                title={(state.endTime !== undefined && formatTimeLabel(state.endTime)) || cm.get('Time')}
                children={SelectTime({
                    key: 'endTime',
                    min: state.endDate && state.startDate && state.endDate.toString() === state.startDate.toString() && state.startTime,
                })}
                Icon={<ScheduleOutlinedIcon />}
                value={state.endTime}
            />

            <Button
                style={{ width: 150 }}
                onClick={() => (errors().length ? errors().map(message => Toast({ message })) : onSubmit({ ...state, asString: () => asString(state), getMomentDate }))}
            >
                {cm.get('Save')}
            </Button>
        </div>
    );
};

const parseCustomDate = date =>
    getMomentDate(date.startDate, date.startTime).format('YYYY/MM/DD HH:00') + ' - ' + getMomentDate(date.endDate, date.endTime).format('YYYY/MM/DD HH:00');

const toDate = ({ startDate, endDate, startTime, endTime }) =>
    !endDate instanceof Date || !startDate instanceof Date
        ? {}
        : {
              startDate,
              endDate,
              startTime: startTime,
              endTime: endTime,
          };

const getMomentDate = (date, time) => moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), time || 0));

function Expansion({ expanded, children, Icon, title, props, value }) {
    const { expansionPanel, expansionPanelDetails, expansionPanelIcon, expansionPanelSummary, summaryContent, expansionTitle, valueSelected } = useStyle();

    return (
        <ExpansionPanel TransitionProps={{ unmountOnExit: true }} expanded={expanded} className={expansionPanel} {...props}>
            <ExpansionPanelSummary className={expansionPanelSummary}>
                <div className={summaryContent}>
                    <div className={expansionPanelIcon}>{Icon}</div>
                    <div className={`${expansionTitle} ${value !== undefined && valueSelected}`}>{title}</div>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={expansionPanelDetails}>{children}</ExpansionPanelDetails>
        </ExpansionPanel>
    );
}

export default DateTimeRange;
