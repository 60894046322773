import InlineCss from 'react-inline-css';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import React from 'react';
import Search from '../../svgs/search/index';
import TextField from '@material-ui/core/TextField/TextField';
import { makeStyles } from '@material-ui/core';

export default function SearchBox({ height, width, onChange, value, placeholder = 'Search' }) {
    const classes = useStyles({ height, width });
    return (
        <InlineCss
            stylesheet={`
            & ::placeholder {
                color : #fffefe;
                font-size:12px;
                opacity:1;
                font-family:'Open Sans'
            }
        `}
        >
            <TextField style={{ width: '100%'}}
                onChange={e => onChange(e.target.value)}
                value={value}
                className={classes.root}
                placeholder={placeholder}
                variant="filled"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <Search width={12} height={12} color={'#fe0072'} />
                        </InputAdornment>
                    ),
                }}
            />
        </InlineCss>
    );
}

function useStyles({ height, width }) {
    return makeStyles(theme => ({
        root: {
            backgroundColor: '#383851',
            '& .MuiInputBase-root': {
                width: '100%',
                border: 'none',
                height,
                paddingRight: 5,
            },
            '& .MuiSvgIcon-root': {
                height: 13,
                width: 13,
                padding: 0,
                color: '#fe0072',
            },
            '& .MuiInputBase-input': {
                color: '#fff',
                padding: 0,
                paddingLeft: 8,
                fontFamily: 'Open Sans',
                fontSize: 12,
            },

            '& .MuiFilledInput-underline::before': {
                borderBottom: 'none',
            },
            '& .MuiFilledInput-underline::after': {
                borderBottom: 'none',
            },
            '& .MuiTextField-root': {
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
            },
            '& .MuiFilledInput-root.Mui-focused': {
                border: 'solid 1px',
                borderColor: '#fe0072',
            },
            '& .MuiFilledInput-root': {
                border: 'solid 1px',
                borderColor: '#383851',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
            },
        },
    }))();
}
