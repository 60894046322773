import React, { useEffect, useMemo } from 'react';
import CheqUI from '@combotag/cheq-ui';
import styles from './styles.module.scss';
import widgets from '../../widgets';
import { getSummary, getAllPlatformsGroup } from '@redux/thunks/analytics';
import { deleteReportHistory, loadHistoryReport } from '@redux/actions/analytics';
import { useSelector, useDispatch } from 'react-redux';
import { getRequestObject, casToAnalytictsFilters, SLP_REPORT_TYPE, toSelectList } from './utils';
import mixpanel from '@services/mixpanel';
import Header from '../header';
import { useSplit, useContentManager, useGetFilters } from '@hooks';
import { constants } from '@utils/split.io';
import * as analyticsActions from '../../../redux/actions/analytics';
import { useState } from 'react';
import { Products } from '../../containers/management/constants';
import { getPageTypeLabel } from '@utils/naming/pageTypeNaming';
import { find } from 'lodash';

const LOCAL_KEY_ANALYTICS_FILTERS = 'AnalyticsFilters';
const { useActions, useMeiosis } = CheqUI.React.Meiosis;
const { Table, ReportHistory } = widgets;
const LOCAL_KEY_REPORT_HISOTRY = 'reportHistory';

const Analytics = () => {
    const actions = useActions();
    const dispatch = useDispatch();
    const analytics = useMeiosis(actions.analytics.buildStream);
    const [allPlatforms, setAllPlatforms] = useState();
    const { networkId } = useSelector(state => state.profile.meta);
    const { data: filtersData } = useGetFilters(true);

    const cm = useContentManager();
    const casFilters = JSON.parse(localStorage.getItem(LOCAL_KEY_ANALYTICS_FILTERS));
    const [isViewAllView, setIsViewAllView] = useState(false);
    const { selectedLicense } = useSelector(state => state.settings);

    useEffect(() => {
        const reportHistory = JSON.parse(localStorage.getItem(LOCAL_KEY_REPORT_HISOTRY)) || [];
        dispatch(analyticsActions.updateReportHistory(reportHistory));
    }, []);

    const {
        currentReport: { columns = [], timezone, reportDate, reportType },
        isLoading,
        reportHistory,
        err,
        summaryReportSettings,
    } = useSelector(state => state.analytics);

    const columnsNewOrder = [...columns];
    const indexOfInvalidBotActivity = columns.indexOf('badBotActivity');
    if (selectedLicense === Products.CAS) {
        const indexOfInvalidClickRate = columns.indexOf('invalidClickRate');
        columnsNewOrder.splice(indexOfInvalidClickRate, 1);
        columnsNewOrder.splice(indexOfInvalidBotActivity, 0, 'invalidClickRate');
    }
    let {
        currentReport: { reportResults = [] },
    } = useSelector(state => state.analytics);

    const getPlatforms = async () => {
        const allPlatforms = await dispatch(getAllPlatformsGroup());
        const platforms = allPlatforms.data.map(platformGroups => platformGroups.platforms).flat(1);
        setAllPlatforms(allPlatforms);
        dispatch(analyticsActions.analyticsPlatformsUpdated(allPlatforms));
        return platforms;
    };

    useEffect(() => {
        async function casViewAll() {
            if (isViewAll && casFilters) {
                setIsViewAllView(true);
                const flatPlatforms = await getPlatforms();
                const filters = casToAnalytictsFilters(
                    casFilters,
                    flatPlatforms.map(platform => {
                        return {
                            value: platform,
                            label: platform,
                        };
                    })
                );
                dispatch(analyticsActions.setSummaryReportSettingsFromCas(false, filters));
                localStorage.removeItem(LOCAL_KEY_ANALYTICS_FILTERS);
            } else {
                await getPlatforms();
            }
        }
        casViewAll();
    }, []);

    useEffect(() => {
        dispatch(
            analyticsActions.updateReportType(
                selectedLicense === 1
                    ? {
                          label: 'Summary',
                          value: 'summary',
                      }
                    : selectedLicense === 8
                    ? {
                          ladel: 'Lead Protection',
                          value: SLP_REPORT_TYPE,
                      }
                    : {
                          label: 'RTI',
                          value: 'rti',
                      }
            )
        );
    }, [selectedLicense]);

    const { buildTable, getReportForm } = actions.analytics;
    const {
        campaignsList = [],
        sourcesList = [],
        affiliateList = [],
        contentList = [],
        mediumList = [],
        eventTypeList = [],
        eventNameList = [],
        urlsList = [],
        domainsList = [],
        slpEmailDomains = [],
        threatTypes = [],
        slpDomains = [],
    } = analytics;
    const { Form } = getReportForm((reportType && reportType.value) || 'summary');
    const { isOn: showPolicyToggle } = useSplit(constants.SPLIT_POLICY_MANAGEMENT_TOGGLE);
    const { isOn: policyManagement } = useSplit(constants.SPLIT_POLICY_MANAGEMENT);
    const { isOn: isViewAll } = useSplit(constants.PARADOME_CAS_DASHBOARD_VIEW_ALL);
    const clickTrueTags = useSelector(state => state.clickTrueTags.tags);

    const channelsList = useMemo(() => {
        return (clickTrueTags || []).map(({ id, name }) => ({
            value: id,
            label: name,
        }));
    }, [clickTrueTags]);

    useEffect(() => {
        if (performance.navigation.type === 1) {
            const object = {
                'Page name': 'Analytics',
                'Navigation type': 'Refresh',
            };

            mixpanel.postEvent('PageView', object);
        }
    }, []);

    const onSubmit = async data => {
        let params = getRequestObject(data, allPlatforms);
        const mixpanelData = {
            'Report view type': isViewAllView ? 'View All Button' : 'Run Report click',
            'Report Type': params.reportType,
            'Report breakdown': params.breakdownBy,
            'Report group by': params.groupBy,
            'Report include PPC crawlers': params.includePPCCrawlers,
            'Report advanced filtering countries': params.advancedFiltering.country,
            'Report advanced filtering devices': params.advancedFiltering.device,
            'Report advanced filtering browsers': params.advancedFiltering.browser,
            'Report advanced filtering sources': params.advancedFiltering.source,
            'Report advanced filtering campaigns': params.advancedFiltering.campaign,
            'Report advanced filtering channels': params.advancedFiltering.channel,
            'Report advanced filtering platform origin': params.advancedFiltering.platformOrigin,
            'Report advanced filtering affiliate IDs': params.advancedFiltering.affiliates,
            'Report advanced filtering Event Types': params.advancedFiltering.eventType,
        };
        setIsViewAllView(false);
        if (typeof params.dateRange === 'string') {
            mixpanelData['Report period filter'] = params.dateRange;
        } else {
            mixpanelData['Report period filter'] = 'custom';
            mixpanelData['Report start date filter'] = params.dateRange.startDate;
            mixpanelData['Report end date filter'] = params.dateRange.endDate;
        }
        mixpanel.postEvent('Analytics view', mixpanelData);
        params.policyManagement = policyManagement;
        params.includePolicy = showPolicyToggle;
        params.policyToggleA = summaryReportSettings.policyToggleA;
        dispatch(getSummary(params));
    };

    const handleOnDeleteReportHistory = item => {
        dispatch(deleteReportHistory(item));
    };

    const handleLoadingHistory = item => {
        dispatch(loadHistoryReport(item));
    };

    // HACK TO FIX UNORDERED RETURN DATA WITH TOTAL
    const [totalRow, rows] = useMemo(() => {
        const [firstRow = {}, secondRow = {}] = reportResults;
        const total = reportResults.find(({ platformOrigin = '' }) => platformOrigin === 'Total');
        let totalRow = {};

        if (total) totalRow = total;
        else if (Object.keys(firstRow).length > Object.keys(secondRow).length) totalRow = secondRow;
        else totalRow = firstRow;

        const index = reportResults.indexOf(totalRow);
        const platforms = filtersData?.data?.platforms || [];
        let rows = reportResults.filter((_, i) => i !== index);

        rows.map(object => {
            try {
                const { platformOrigin, source, medium, campaign } = object;
                if (campaign) object.campaign = decodeURIComponent(campaign);
                if (source) object.source = decodeURIComponent(source);
                if (medium) object.medium = decodeURIComponent(medium);
                if (platformOrigin && platforms.length) object.platformOrigin = getPageTypeLabel(find(platforms, { value: platformOrigin })?.label || platformOrigin);
            } catch {}
            return object;
        });
        if (Object.keys(totalRow).length && Object.keys(totalRow).length === Object.keys(rows[0]).length) totalRow = null;

        return [totalRow, rows];
    }, [reportResults, selectedLicense, columns, filtersData]);

    const networkReportHistory = reportHistory.filter(report => report.networkId === networkId);

    const threatTypesSelectList = toSelectList(threatTypes);
    const slpEmailDomainsSelectList = toSelectList(slpEmailDomains);
    const slpDomainsList = toSelectList(slpDomains);

    return (
        <>
            <Header title={cm.get('Analytics')} />
            <div className={styles.root}>
                <div className={styles.left}>
                    <Form
                        onSubmit={onSubmit}
                        sources={sourcesList}
                        campaigns={campaignsList}
                        channels={channelsList}
                        affiliates={affiliateList}
                        eventType={eventTypeList}
                        eventName={eventNameList}
                        urls={urlsList}
                        domains={domainsList}
                        contents={contentList}
                        mediums={mediumList}
                        isLoading={isLoading}
                        slpEmailDomains={slpEmailDomainsSelectList}
                        threatTypes={threatTypesSelectList}
                        slpDomains={slpDomainsList}
                    />
                    {!!networkReportHistory.length && (
                        <ReportHistory reportHistory={networkReportHistory} onClick={handleLoadingHistory} onDelete={handleOnDeleteReportHistory} cm={cm} />
                    )}
                </div>
                <div className={styles.right}>
                    <>
                        <div className={styles.header}>
                            {reportDate && !isLoading && (
                                <>
                                    <div className={styles.timezone}>{`${cm.get('SelectedTimezone')}: ${timezone}`}</div>
                                    <div className={styles.date}>{`${cm.get('Date')}: ${reportDate || '-'}`}</div>
                                </>
                            )}
                        </div>
                        <div className={styles.tableWrapper}>
                            {/* <MaterialTable columns={parseColumns(columns, cm.by('ReportTable.SummaryReport'))} rows={rows} totalRow={totalRow} isLoading={isLoading} /> */}
                            <Table
                                columns={columnsNewOrder}
                                rows={rows}
                                totalRow={totalRow}
                                reportType={reportType}
                                isLoading={isLoading}
                                error={err}
                                reset={true}
                                {...buildTable(reportType === 'affiliate_fraud' ? 'affiliate' : 'summary')}
                                cm={cm}
                            />
                        </div>
                    </>
                </div>
            </div>
        </>
    );
};

export default Analytics;
