import { axiosHttp } from '../../utils/http/view-models';
import config from '@config';

class ZendeskService {
    base_url = config.baseAPI;
    config = {
        headers: {
        Authorization: `Bearer ${localStorage.getItem('auth0Token')}`},
    };
    updateAuthHeader() {
        this.config.headers.Authorization = `Bearer ${localStorage.getItem('auth0Token')}`;
    }
    createTicket = async (body, url, base_url = this.base_url) => {
        try {
            this.updateAuthHeader()
            const { data } = await axiosHttp.post(`${base_url}/${url}`, body, this.config);
            return data;
        } catch (err) {
            console.log({ err });
        }
    };
}

export const zendeskService = new ZendeskService();

export default zendeskService;
