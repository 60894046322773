type ReportTypeDictionary = {
    UDV: {
        label: string;
        value: string;
    };
};

export const REPORT_TYPE_DICT: ReportTypeDictionary = {
    UDV: {
        label: 'UserValidation',
        value: 'user_validation',
    },
};
