import CheqUI from '@combotag/cheq-ui';
import Base from '../base';
const { Profile } = CheqUI.ViewModels.Models

export default  Profile(class extends Base {
    constructor() { super('profile') }
    init(permissions) {
        super.init();
        this.state.permissions = permissions
    }

    isAuthorized(model, action) {
        return (this.state.permissions || []).find(perm => perm.model === model && perm.action === action)
    }
    setMeta(callback) {
        this.update(callback)
    }

})