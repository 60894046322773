/**
 * this file is auto generated by chopin-scripts.
 * you can check the project package.json
 * to see how it was generated.
 */

import Analytics from './analytics';
import FraudFlow from './fraud-flow';
import Management from './management';
import Welcome from './welcome';
import Exportables from './exportables';
import Maintenance from './maintenance';

export default {
    Analytics,
    FraudFlow,
    Management,
    Welcome,
    Exportables,
    Maintenance
};
