import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import useStyles from './style';
import Tooltip from '@material-ui/core/Tooltip';


export default ({
    ref = 'https://cheqai.freshdesk.com/support/login',
    tooltip = 'Help'
}) => {
    const styles = useStyles();
    return <div
        onClick={() => window.open(ref)}
        className={styles['container']} >
        <Tooltip title={tooltip}>
            <HelpIcon
                className={styles['icon']} />
        </Tooltip></div>
}