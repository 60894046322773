import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const useStyle = makeStyles(theme => ({
    tooltip:{ backgroundColor: '#fe0072' }
}));

export default ({title = ' ', children, disable=false, ...params })=> {
    const classes = useStyle();
    return <Tooltip classes={classes} title={title} disableHoverListener={disable || !title.trim()} {...params}>
        {children}
    </Tooltip>
}