import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheqTag from '../../widgets/cheq-tag';
import CheqUI from '@combotag/cheq-ui';
import { getNetworkServingDomain } from '@redux/thunks/settings';
import integrators from '../management/integrators/3parties';
import { makeStyles } from '@material-ui/core/styles';
import svgs from '../../svgs/third-parties';
import widgets from '../../widgets';

const { useActions, useMeiosis } = CheqUI.React.Meiosis;
const { Stepper, Button } = widgets;

const useStyles = makeStyles(theme => ({
    icon: {
        display: 'inline-block',
        border: `solid`,
        borderColor: 'transparent',
        margin: 30,
        padding: 20,
        borderRadius: 100,
        backgroundColor: theme.palette.background.component,
    },
    iconSelected: { borderColor: theme.palette.custom.text.primary },
    iconDisabled: { fill: 'gray', backgroundColor: theme.palette.background.default },
    actions: {
        '& div': {
            display: 'inline-block',
            padding: 10,
        },
    },
}));

export default ({ history }) => {
    const actions = useActions();
    const [shouldContinue, setShouldContinue] = useState(true);
    const clickTrueTags = useMeiosis(actions.clickTrueTags.buildStream);
    const sso = useMeiosis(actions.sso.buildStream);
    const styles = useStyles();
    const dispatch = useDispatch();

    const servingDomain = useSelector(state => state.settings.servingDomain);
    const networkFeatures = useSelector(s => s.settings?.networkFeatures);
    const licences = {
        onSiteConversionEnabled: !!networkFeatures?.filter(n => n.id === 2 && n.enabled),
        paidMarketingEnabled: !!networkFeatures?.filter(n => n.id === 1 && n.enabled),
    };

    const { ready } = sso;
    const { mainTag } = clickTrueTags;

    useEffect(() => {
        if (!servingDomain) dispatch(getNetworkServingDomain());
    });

    useEffect(() => {
        actions.clickTrueTags.getMainTag();
    }, [actions.clickTrueTags]);

    useEffect(() => {
        ready &&
            mainTag &&
            actions.dialog.show(
                <Stepper>
                    {({ next }) => (
                        // eslint-disable-next-line
                        <div style={{ padding: 5 }} subtitle={'CHEQ for PPC Tag'}>
                            <p>
                                CHEQ&#39;s invocation code uses advanced fingerprinting techniques to monitor user behavior across your sites. This script should be installed on
                                every page of any site with ads. Copy and paste the following script directly after the opening &lt;body&gt; tag of your site.
                            </p>
                            <CheqTag
                                licences={licences}
                                servingDomain={servingDomain}
                                tag={clickTrueTags.mainTag}
                                RenderCopy={({ copy, value }) => <Button onClick={() => copy(value)}>COPY TO CLIPBOARD</Button>}
                                RenderNext={() => <Button onClick={next}>NEXT</Button>}
                            />
                        </div>
                    )}
                    {({ prev }) => {
                        const [selected, setSelected] = useState('');
                        useEffect(() => {
                            const Integration = integrators[selected.toLowerCase()];
                            Integration &&
                                actions.sso.touch() &&
                                actions.dialog.show(<Integration initial={true} />, { id: 'first-login', width: 900, noCancel: true, Header: () => <div /> });
                        }, [selected]);

                        return (
                            // eslint-disable-next-line
                            <div subtitle={'Sync Account'}>
                                <div className={styles.icons}>
                                    {Object.entries(svgs).map(([name, Icon], idx) => (
                                        <div
                                            key={idx}
                                            onClick={() => integrators[name.toLowerCase()] && (setSelected(name) || setShouldContinue(false))}
                                            className={`
                            ${styles.icon}
                            ${selected === name ? styles.iconSelected : ''}
                            ${!integrators[name.toLowerCase()] ? styles.iconDisabled : ''}
                            `}
                                        >
                                            <Icon />
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.actions}>
                                    <Button onClick={prev}>BACK</Button>
                                    <Button onClick={() => actions.sso.touch().then(actions.dialog.hide)}>SKIP</Button>
                                </div>
                            </div>
                        );
                    }}
                </Stepper>,
                { width: 900, noCancel: true, Header: () => <div /> }
            );
        return () => {
            !actions.dialog.has('id') && actions.dialog.hide();
        };
    }, [ready, mainTag]);
    return <div />;
};
