import { useQuery } from 'react-query';
import { generateAuthorizationUrl } from '../../services/api/hubspot.service';

export const useGenerateAuthorizationUrl = () => {
    return useQuery({
        queryKey: ['generateAuthorizationUrl'],
        queryFn: () => generateAuthorizationUrl(),
        select: data => {
            if (data instanceof Error) {
                console.error('Error in Hubspot generateAuthorizationUrl hook');
            }
            return data;
        },
    });
};
