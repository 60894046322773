import {makeStyles} from '@material-ui/core/styles'

export default makeStyles(theme => ({
    calenderDateNotAvailable: {
        disabled: 'true !important',
        backgroundColor: '#696969 !important',
        color: '#ffff !important'
    },
    calenderDate: {
        backgroundColor: theme.palette.custom.text.primary,
        fontSize: 18,
        color: '#fff',
    },
    calender: {
        zIndex: 10,
        border: 'none',
        width: 352,
        '& .react-calendar__tile': {
            '&:hover': {
                backgroundColor: '#0C1021',
            },
            '&:disabled': {
                backgroundColor: '#696969 !important',
            },
        },
        '& .react-calendar__tile--active':{
            backgroundColor: '#0C1021 !important',
            background: '#0C1021 !important',
        },
        backgroundColor: theme.palette.background.default,
        '& .react-calendar__tile--now': {
            backgroundColor: theme.palette.custom.text.primary
        },
        '& .react-calendar__navigation__label': {
            color: '#fff',
            fontSize: 24,
            '&:hover': {
                backgroundColor: '#0C1021 !important',
            },
            '&:focus': {
                backgroundColor: '#0C1021 !important',
            },

        },
        '& .react-calendar__navigation__prev2-button': {
            display: 'none'
        },
        '& .react-calendar__navigation__next2-button': {
            display: 'none'
        },
        '& .react-calendar__navigation__prev-button': {
            marginLeft: 10,
            backgroundColor: `${theme.palette.background.paper} !important`,
            color: '#fff !important'
        },
        '& .react-calendar__navigation__next-button': {
            marginRight: 10,
            backgroundColor: `${theme.palette.background.paper} !important`,
            color: '#fff !important'
        },
        '& .react-calendar__tile--hasActive': {
            backgroundColor: `#0C1021 !important`,
        },

        '& .react-calendar__month-view__days': {
            backgroundColor: '#fff',
        }
    }
}))