import React from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
const cx = classNames.bind(styles);

export const Menu = ({ current, onChange, dashboardCM }) => {
    const getChangeHandler = state => () => onChange(state);

    return (
        <div className={cx('menuItems')} data-test-selector="top threat types menu">
            <div onClick={getChangeHandler('total')} className={current === 'total' ? cx('current') : ''} data-test-selector="total">
                {dashboardCM.get('Total')}
            </div>
            <div onClick={getChangeHandler('paid')} className={current === 'paid' ? cx('current') : ''} data-test-selector="paid">
                {dashboardCM.get('Paid')}
            </div>
            <div onClick={getChangeHandler('organic')} className={current === 'organic' ? cx('current') : ''} data-test-selector="organic">
                {dashboardCM.get('Organic')}
            </div>
        </div>
    );
};
