import { settings as actions } from '../actions';

const settingsInitialState = {
    err: null,
    isLoading: false,
    networkFeatures: [],
    getNetworkFeatureCalled: false,
    servingDomain: undefined,
    googleScopes: {},
    rtiToken: undefined,
    services: [],
    rtiSettings: {
        isLoading: false,
        isApiIntegration: true,
        isClientSideIntegration: true,
        urls: [],
        err: null,
    },
    selectedLicense: 0,
    updatedLanguage: null,
    isGAConnectorEnabled: false,
    pxgVersion: 0,
};

export default (state = settingsInitialState, action) => {
    switch (action.type) {
        case actions.SET_NETWORK_FEATURES: {
            const { payload: networkFeatures } = action;
            return {
                ...state,
                networkFeatures: networkFeatures.map(nf => ({ ...nf, settings: JSON.parse(nf.settings || '{}') })),
                isLoading: false,
                getNetworkFeatureCalled: true,
            };
        }

        case actions.SET_GOOGLE_SCOPES: {
            const { payload: googleScopes } = action;
            return { ...state, googleScopes };
        }
        case actions.SET_RTI_TOKEN: {
            const { payload } = action;
            return { ...state, rtiToken: payload, isLoading: false };
        }

        case actions.SET_SERVICES: {
            const { payload } = action;
            return { ...state, services: payload, isLoading: false };
        }

        case actions.SET_NETWORK_FEATURE_SETTINGS: {
            const { payload: networkFeature } = action;
            const { id, settings } = networkFeature;
            const newState = { ...state };
            const index = newState.networkFeatures.indexOf(newState.networkFeatures.find(e => e.id === id));
            index >= 0 && newState.networkFeatures.splice(index, 1, { ...newState.networkFeatures[index], settings });
            return { ...state, ...newState, isLoading: false };
        }

        case actions.SETTINGS_REQUEST_FAILED: {
            const { payload: err } = action;
            return { ...state, err, isLoading: false };
        }

        case actions.SET_SERVING_DOMAIN: {
            const { payload: servingDomain } = action;
            return { ...state, servingDomain, isLoading: false };
        }

        case actions.SET_DEDICATED_WLDOMAINS: {
            const { payload: dedicatedWLDomainsData } = action;
            return { ...state, dedicatedWLDomainsData, isLoading: false };
        }

        case actions.SETTINGS_DATA_LOADING: {
            return { ...state, isLoading: true, err: null };
        }
        case actions.SET_IS_LOADING: {
            return { ...state, isLoading: action.payload, err: null };
        }
        case actions.SET_IS_ENABLED_AFFILIATE_FRAUD: {
            return { ...state, isAffiliateFraudEnabled: action.payload === 1 ? true : false, isLoading: false };
        }
        case actions.SET_IS_GA_CONNECTOR_ENABLED: {
            return { ...state, isGAConnectorEnabled: action.payload === 1 ? true : false, isLoading: false };
        }
        case actions.SET_PXG_VERSION: {
            return { ...state, pxgVersion: action.payload };
        }
        case actions.SET_DEDICATED_WLD: {
            return { ...state, dedicatedWLDomains: action.payload };
        }
        case actions.SET_CURRENT_LICENSE: {
            return { ...state, selectedLicense: action.payload };
        }
        case actions.SET_UPDATED_LANGUAGE: {
            return { ...state, updatedLanguage: action.payload };
        }
        default:
            return state;
    }
};
