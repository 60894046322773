class CM {
    constructor(content = {}, prefix = '') {
        this.content = content;
        this.prefix = prefix;
    }

    load(content) {
        this.content = content;
        return this;
    }

    get(stringId) {
        const { content = {}, prefix } = this;
        let string;
        if (prefix && content[prefix + stringId]) {
            string = content[prefix + stringId];
        } else {
            if (content[stringId]) {
                string = content[stringId];
            }
        }
        if (string) return string;
        if (this.ready()) {
            console.warn('missing string id', `${prefix + stringId}`);
            return '_' + stringId;
        }
    }

    by(prefix) {
        return new CM(this.content, this.prefix + prefix ? `${prefix}.` : '');
    }

    ready() {
        return !!Object.keys(this.content || {}).length;
    }
}

export default CM;
