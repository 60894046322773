import { useState, useEffect } from 'react';
import { ReactComponent as RefreshIcon } from '@components/svgs/icons/refresh.svg';
import styles from './Filter.module.scss';

import { useContentManager } from '@hooks';

const LastUpdated = ({ update, cm }) => {
    const [time, setTime] = useState(1);

    useEffect(() => {
        setTime(1);
        const interval = setInterval(() => {
            setTime(prevState => prevState + 1);
        }, 60000);
        return () => clearInterval(interval);
    }, [update]);

    return (
        <div className={styles.lastUpdated}>
            <RefreshIcon /> {cm.get('Updated')} {time} {cm.get('MinutesAgo')}
        </div>
    );
};

export default LastUpdated;
