import axios from 'axios';
import { getSubDomain } from '@utils/common';

export function applyAuth0AuthorizationHeader() {
    this.http.interceptors.request.use(function(config) {
        const token = localStorage.getItem('auth0Token');
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    });
}

export function addReqPayload(payload) {
    this.http.interceptors.request.use(function(config) {
       config.data = payload
        return config;
    });
}

export const getCsrfToken = () => {
    let csrfToken = null;
    try {
        const meta = JSON.parse(localStorage.meta);
        csrfToken = meta.csrfToken;
    } catch (e) {
    }
    if (!csrfToken) {
        csrfToken = localStorage.csrf;
    }

    return csrfToken;
};

export const axiosHttp = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-T-CSRF': getCsrfToken(),
        'X-CHEQ-LOCATION': getSubDomain(),
    },
    withCredentials: true,
});
