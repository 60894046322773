import { Box, Typography } from '@material-ui/core';

export function TabPanel(props) {
    const { children, value, index, height, ...other } = props;

    return (
        <Typography
            style={{ display: 'flex', flexDirection: 'column', marginBottom: 16, flex: 1 }}
            component="div"
            role="tabpanel"
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box style={{ flex: 1, height: height }} p={3}>
                {children}
            </Box>
        </Typography>
    );
}
