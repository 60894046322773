import { useState, useRef } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import styles from './realtime.module.scss';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { isValidURL } from '@utils/common';

import cx from 'classnames';
import { useContentManager } from '@hooks';

import { Toggler } from '@widgets';

const Url = ({ url, updateUrl, deleteUrl, cm, index }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [item, setItem] = useState(url);
    const [isErr, setIsErr] = useState(false);

    const inputRef = useRef();

    const onChange = ({ target }) => {
        setItem(prevState => ({
            ...prevState,
            value: target.value,
        }));
        if (!isValidURL(target.value)) {
            setIsErr(true);
        } else {
            setIsErr(false);
        }
    };

    const onToggle = (e, isIncludeAllSubdomains) => {
        setItem(prevState => ({
            ...prevState,
            isIncludeAllSubdomains,
        }));
        updateUrl({
            ...item,
            isIncludeAllSubdomains,
        });
    };

    const onBlur = () => {
        const value = item.value.trim();
        if (!value || isErr) {
            setItem(url);
            setIsErr(false);
        } else {
            updateUrl(item);
        }
        setIsFocused(false);
    };

    const onFocus = () => {
        setIsFocused(true);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    return (
        <>
            <div className={styles.input}>
                <div className={styles.inputWrapper}>
                    <TextField
                        inputRef={inputRef}
                        onChange={onChange}
                        onKeyPress={e => e.key === 'Enter' && onBlur()}
                        value={item.value}
                        margin="dense"
                        variant={isFocused ? 'outlined' : 'filled'}
                        classes={{ root: styles.marginDense }}
                        fullWidth
                        data-test-selector={`rti-settings-url-input-${index}`}
                        InputProps={{
                            onBlur,
                            onFocus,
                            disableUnderline: !isFocused,
                            readOnly: !isFocused,
                            classes: { inputMarginDense: cx(styles.inputMarginDense, { [styles.pointer]: !isFocused }) },
                            margin: 'dense',
                        }}
                        error={isErr}
                    />
                    {isErr && <div className={styles.error}>{cm.get('NotValidURL')}</div>}
                </div>

                <div className={styles.actions}>
                    <IconButton data-test-selector="rti-settings-url-delete-button" size="small" onClick={() => deleteUrl(url.id)}>
                        <DeleteForeverIcon fontSize="small" />
                    </IconButton>
                </div>
            </div>
            <div className={styles.toggler}>
                <Toggler data-test-selector="rti-settings-url-include-all-subdomains" checked={!!item.isIncludeAllSubdomains} onChange={onToggle} />
            </div>
        </>
    );
};

export default Url;
