import { FC, useRef, useState } from 'react';
import ArrowIcon from '../../../../../assets/svgs/arrow.svg';
import UserIcon from '../../../../../assets/svgs/user.svg';
import LogoutIcon from '../../../../../assets/svgs/Logout.svg';
import styles from './Header.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizations } from 'hooks/use-organizations/useOrganizations';

interface IProps {
    username: string;
}
const Header: FC<IProps> = ({ username }) => {
    const container = useRef(null);
    const [opened, setOpened] = useState(false);
    const { logout } = useAuth0();
    const [selectedOrgName] = useOrganizations();

    const handleOnLogout = () => {
        window.localStorage.clear();
        logout();
    };

    return (
        <div className={styles.container}>
            <div aria-hidden className={styles.userContainer} ref={container} onClick={() => setOpened(prevOpened => !prevOpened)}>
                <img src={UserIcon} alt="user" />
                {username} {selectedOrgName && <>| {selectedOrgName}</>}
                <img src={ArrowIcon} className={styles.dropdown} alt="arrow" />
            </div>
            {opened && (
                <div className={styles.actionsContainer} onClick={handleOnLogout}>
                    <img src={LogoutIcon} alt="logout" />
                    Logout
                </div>
            )}
        </div>
    );
};

export default Header;
