import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    container: {
        backgroundColor: '#14172c',
        padding: 20,
        minHeight: 600,
        width: '100%',
        paddingTop: 70,
        alignItems: 'center',
        '& .MuiAccordion-root': {
            width: '80%',
            margin: 'auto',
            backgroundColor: '#21223d',
            '&::before': {
                height: 0,
            },
        },
        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            padding: 0,
            '& svg': {
                height: 20,
                marginTop: -3,
                transform: 'rotate(45deg)',
            },
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            '& svg': {
                transform: 'rotate(90deg)',
            },
        },
        '& .MuiAccordionSummary-root': {
            backgroundColor: '#21223d',
            fontSize: 14,
        },
        '& .MuiAccordionDetails-root': {
            flexDirection: 'column',
            backgroundColor: '#21223c',
            padding: '4px 24px 24px 4px',
        },
    },
    formContainer: {
        width: '100%',
        margin: 'auto',
        display: 'block',
    },
    submitContainer: {
        marginTop: 68,
        margin: 20,
    },
    submit: {
        fontSize: 24,
        height: 40,
        outline: 'none',
        border: 'none',
        color: '#FFF',
        width: 278,
    },
    progress: { textAlign: 'center' },
    title: {
        marginBottom: 20,
    },
    subTitle: {
        paddingTop: 20,
    },
    userProfileTitle: {
        fontSize: 24,
        textAlign: 'center',
        color: '#ffffff',
        padding: '17px 0px 19px 10px',
        whiteSpace: 'nowrap',
    },
    header: {
        display: 'flex',
        width: '100%',
        height: 40,
        padding: 10,
        justifyContent: 'space-between',
    },
    userProfile: {
        paddingLeft: 20,
        paddingRight: 20,
        display: 'block',
        '& .MuiAccordion-root': {
            // width: '80%',
            backgroundColor: '#21223d',
            // boxShadow: 'none',
            '&::before': {
                height: 0,
            },
        },
        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            padding: 0,
            '& svg': {
                height: 20,
                marginTop: -3,
                transform: 'rotate(45deg)',
            },
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            '& svg': {
                transform: 'rotate(90deg)',
            },
        },
        '& .MuiAccordionSummary-root': {
            backgroundColor: '#21223d',
            fontSize: 14,
            textAlign: 'center',
        },
        '& .MuiAccordionDetails-root': {
            flexDirection: 'column',
            backgroundColor: '#21223c',
            padding: '4px 24px 24px 4px',
        },
        '& input': {
            width: '90% !important',
            backgroundColor: '#14172c !important',
        },
    },
    btnContainer: {
        padding: 20,
    },
    btn: {
        fontFamily: 'Open Sans',
        height: 34,
        paddingRight: 10,
        '& input': {
            fontSize: 18,
            height: '100%',
            width: '100%',
            backgroundColor: `${theme.palette.custom.text.primary} !important`,
            '&:disabled': {
                backgroundColor: '#696969 !important',
            },
        },
    },
    textField: {
        zIndex: '0',
        backgroundColor: '#14172c',
        width: 290,
        height: 41,
        marginLeft: 7,
        marginTop: 8,
        marginBottom: 15,
        fontSize: 14,
        '&:placeholder': {
            fontSize: 14,
        }
    }
}));
