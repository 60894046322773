import ListItem from './ListItem';
import styles from './List.module.scss';
import { PixelSettingsType } from '../../types';
interface IProps {
    cm: any;
    data: PixelSettingsType[];
    onDelete: (tagId: number) => void;
}

const List = ({ cm, data, onDelete }: IProps) => {


    return (
        <div className={styles.listContainer}>
            {data.map((item, index) => (
                <ListItem key={index} index={index} cm={cm} item={item} onDelete={onDelete} />
            ))}
        </div>
    );
};

export default List;
