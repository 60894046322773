import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    icon: {
        margin: theme.spacing(2)
    },
    iconHover: {
        margin: theme.spacing(2),
        '&:hover': {
            color: red[800],
        },
    },
}));

const Icon = ({ d, ...props }) => <SvgIcon {...props}><path d={d} /></SvgIcon>

export default function ({ d, ...props }) {
    const classes = useStyles();
    return <div className={classes.root}>
        <Icon className={classes.icon}
            d={d} {...props} />
    </div>
}