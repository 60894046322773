import React from 'react';
import Highcharts from 'highcharts'
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from 'highcharts-react-official'
import { useTheme } from '@material-ui/styles';
import { _String } from 'chopin-methods'

highchartsMore(Highcharts);
solidGauge(Highcharts);

export default function ({ size = '70%', data = [], colors = [], space = 4, thickness = 12, radius = 112,
    RenderEmpty = props => <div className={'no-data'}  {...props}> No data to display</div>,
    style = {}, legend = true, ...props }) {
    const theme = useTheme();
    const series = data.map(({ name, value: y }, idx) => ({
        name, data: [{
            y, color: colors[idx],
            radius: `${radius - (idx * thickness) - space}%`,
            innerRadius: `${radius - ((idx + 1) * thickness)}%`,
            margin: 1
        }]
    }))

    const options = {
        title: { text: '' },
        credits: false,
        chart: {
            backgroundColor: theme.palette.background.component,
            type: 'solidgauge',
            height: size,
            marginLeft: -100
        },
        tooltip: false,
        // tooltip: {
        //     borderWidth: 0,
        //     backgroundColor: 'none',
        //     shadow: false,
        //     style: {
        //         fontSize: '16px'
        //     },
        //     pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}%</span>',
        //     positioner: function (labelWidth) {
        //         return {
        //             x: (this.chart.chartWidth - labelWidth) / 2,
        //             y: (this.chart.plotHeight / 2) - 40
        //         };
        //     }
        // },

        pane: {
            startAngle: 0,
            endAngle: 360,
            background: series
                .map(({ data }) => data[0])
                .map(({ radius: outerRadius, innerRadius }) => ({
                    outerRadius, innerRadius, backgroundColor: '#4c3f8f', borderWidth: 0
                }))
        },

        yAxis: {
            min: 0,
            max: data.reduce((a, b) => a > b.value ? a : b.value, 0),
            lineWidth: 0,
            tickPositions: []
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true,
                animation : false,
            }
        }, series
    }

    return <div {...props} style={{ position: 'relative', ...style }}>
        <div style={{ ...style, display: 'inline-block' }} >
            {!data.length
                ? <RenderEmpty style={{
                    backgroundColor: theme.palette.background.component,
                    height: '100%',
                }} />
                : <HighchartsReact
                    highcharts={Highcharts}
                    options={options} />}
        </div>
        {legend && <div style={{
            position: 'absolute',
            left: '60%',
            top: 0,
            height: '97.7%',
            background: 'transparent',
            verticalAlign: 'middle',
            display: 'inline-block'
        }}>
            <ul style={{ marginTop: 0, marginRight: 10 }}>
                {series
                    .map(({ name, data }) => ({ name, ...data[0] }))
                    .map(({ name, color }, idx) => <li key={idx} style={{
                        color,
                        marginTop: 15,
                        fontSize: 30, height: 20, lineHeight: 0.5
                    }}>
                        <div style={{
                            fontSize: 10, verticalAlign: 'middle',
                            color: theme.palette.custom.text.secondary,
                            width: 75,
                            overflow:'hidden',
                            lineHeight:'35px',
                            whiteSpace:'nowrap',
                            textOverflow:'ellipsis'
                        }}>{name}</div>
                    </li>)}
            </ul>
        </div>}
    </div >
}