import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import BulletIcon from '../../../components/svgs/bullet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import classNames from 'classnames/bind';
import collapseStyle from './collapse-info-list.module.css';
import style from '../info-list.module.css';

const cxm = classNames.bind(style);
const cx = classNames.bind(collapseStyle);

const CollapseInfoList = ({ data = [] }) => {
    return (
        <div>
            {data.map((item, index) => (
                <Accordion key={`${index}_${item.key}`} classes={{ root: cx('container') }}>
                    <AccordionSummary classes={{ root: cx('title-container') }} expandIcon={<ExpandMoreIcon className={cx('expand-icon')} />}>
                        <div className={cxm('bullet')}>
                            <BulletIcon />
                        </div>
                        <div className={cxm('title')}>{item.title}</div>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: cxm('contnet-container') }}>
                        <p>{item.content}</p>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default CollapseInfoList;
