import { FC, useEffect, useState } from 'react';
/* @ts-ignore */
import { useContentManager } from '@hooks';
import styles from '../styles.module.scss';
import ArrowIcon from '../../../../../assets/svgs/arrow.svg';
import ErrorIcon from '../../../../containers/exportables/assets/failed-triangle.svg';
import searchIcon from '../../../../../assets/svgs/search.svg';
import { MarketoForm } from 'types/marketo';
import { MarketoFormsTable } from '../components/forms-table';

type MarketoFormsProps = {
    marketoForms: MarketoForm[];
    updatedForms: MarketoForm[];
    isFetchFormsError: boolean;
    handleSetMarketoForms: (forms: MarketoForm[]) => void;
    setUpdatedForms: (forms: MarketoForm[]) => void;
    marketoXSignature?: string;
};

const RESULT_PER_PAGE = 50;

export const MarketoForms: FC<MarketoFormsProps> = props => {
    const { marketoForms, handleSetMarketoForms, updatedForms, setUpdatedForms, isFetchFormsError } = props;
    const cm = useContentManager();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [chunkForms, setChunkForms] = useState<MarketoForm[][]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        const timerId = setTimeout(() => {
            setSearchTerm(searchValue);
        }, 500);
        return () => clearTimeout(timerId);
    }, [searchValue]);

    useEffect(() => {
        handleOnSearch();
    }, [searchTerm]);

    useEffect(() => {
        if (marketoForms.length / RESULT_PER_PAGE < 1) {
            setChunkForms([marketoForms]);
        } else {
            setChunkForms(createChunkForms(marketoForms));
        }
        handleOnSearch();
    }, [marketoForms]);

    const handleOnSearch = () => {
        setSearchValue(searchTerm);
        const filteredForms = marketoForms?.filter(form => form?.formName?.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()));
        if (filteredForms.length / RESULT_PER_PAGE < 1) {
            setChunkForms([filteredForms]);
        } else {
            setChunkForms(createChunkForms(filteredForms));
        }
        setPageIndex(0);
    };

    const handleUpdateForm = (formId: number, checked: boolean) => {
        const updatedFormIndex = marketoForms.findIndex(form => form.formId === formId);
        const newMarketoForms = [...marketoForms];
        newMarketoForms[updatedFormIndex] = { ...newMarketoForms[updatedFormIndex], formId: formId, isProtected: checked };
        handleSetMarketoForms(newMarketoForms);
        const updatedForm = updatedForms.find(form => form.formId === formId);
        if (!updatedForm) {
            setUpdatedForms([...updatedForms, { ...newMarketoForms[updatedFormIndex], formId: formId, isProtected: checked }]);
        } else if (updatedForm) {
            const newUpdatedForms = updatedForms.filter(form => form.formId !== formId);
            setUpdatedForms(newUpdatedForms);
        }
    };

    const checkedAllForms = (checkedState: boolean) => {
        const allChunksForms = chunkForms.flat();
        const checkedForms = marketoForms.map(form => {
            const isFormInChunk = allChunksForms.some(chunkForm => form.formId === chunkForm.formId);
            return isFormInChunk ? { ...form, isProtected: checkedState } : form;
        });
        handleSetMarketoForms(checkedForms);
        setUpdatedForms(checkedForms);
    };

    function createChunkForms(forms: MarketoForm[]): MarketoForm[][] {
        const result = [];
        for (let i = 0; i < forms.length; i += RESULT_PER_PAGE) {
            result.push(forms.slice(i, i + RESULT_PER_PAGE));
        }
        return result;
    }

    const onNextPageClick = () => {
        if (chunkForms[pageIndex + 1]) {
            setPageIndex(pageIndex + 1);
        }
    };

    const onPrevPageClick = () => {
        if (chunkForms[pageIndex - 1]) {
            setPageIndex(pageIndex - 1);
        }
    };

    return (
        <div>
            <div className={styles.formsHeaderContainer}>
                <div className={styles.formsTitleContainer}>
                    <span className={styles.mainTitle}>
                        {cm.get('MarketoProtectedFormsTitle')} {'(' + marketoForms.reduce((acc, obj) => acc + (obj.isProtected ? 1 : 0), 0) + '/' + marketoForms.length + ')'}
                    </span>
                    {!isFetchFormsError && (
                        <div className={styles.formsSubtitles}>
                            <div className={styles.subTitleContainer}>
                                <span className={styles.subTitle}>{cm.get('MarketoSelectFormsSubTitle')}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.actionContainer}>
                    <div className={styles.inputContainer} data-test-selector="exportables-header-search-container">
                        <div>
                            <img src={searchIcon} alt="search" className={styles.searchIcon} width="15" height="15" />
                        </div>
                        <input value={searchValue} className={styles.searchInput} onChange={e => setSearchValue(e.target.value)} placeholder={cm.get('Search')} />
                    </div>
                </div>
            </div>
            {!isFetchFormsError ? (
                chunkForms[pageIndex]?.length ? (
                    <MarketoFormsTable marketoForms={chunkForms[pageIndex]} handleUpdateForm={handleUpdateForm} checkedAllForms={checkedAllForms} />
                ) : (
                    <></>
                )
            ) : (
                <div className={styles.fetchFormsErrorContainer}>
                    <div>
                        <img className={styles.errorIcon} src={ErrorIcon} alt="error" width={45} height={45} />
                    </div>
                    <span>{cm.get('BasicErrorFetchDataMessage')}</span>
                </div>
            )}
            {!isFetchFormsError ? (
                <div className={styles.paginationContainer}>
                    <span className={styles.resultText}>
                        {cm.get('Showing')} {RESULT_PER_PAGE} {cm.get('ResultPerPage')}
                    </span>
                    <div className={styles.pageControlContainer}>
                        <div onClick={onPrevPageClick} className={styles.pageNavigationButton}>
                            <img src={ArrowIcon} className={styles.prevIcon} alt="arrow" />
                        </div>
                        <div>{pageIndex + 1}</div>
                        <span className={styles.separatePageInfo}>/</span>
                        <span>{Math.ceil(chunkForms.flat().length / RESULT_PER_PAGE) || '1'}</span>
                        <div onClick={onNextPageClick} className={styles.pageNavigationButton}>
                            <img src={ArrowIcon} className={styles.nextIcon} alt="arrow" />
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
