
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json
      * to see how it was generated.
      */
    
import formable from './formable';
import httpRequest from './httpRequest';
import tableable from './tableable';

export default {
        formable,
		httpRequest,
		tableable
}
