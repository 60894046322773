import {httpRequest, methods} from "@services/http";

class AffiliatesService {
    baseUrl = '/affiliates';

    isEnabled = () => {
        const { response } = httpRequest({ method: methods.get, pathname: `${this.baseUrl}/isEnabled` });
        return response;
    };

    getAffiliateData = () => {
        const { response } = httpRequest({ method: methods.get, pathname: this.baseUrl });
        return response;
    };

    setAffiliateData = ({ affiliateId, affiliatePattern }) => {
        const { response } = httpRequest({ method: methods.post, pathname: this.baseUrl, body: {affiliateId, affiliatePattern} });
        return response;
    };
}

export const affiliatesService = new AffiliatesService();

export default affiliatesService;
