import { CHEQ_ACQUISITION_ROUTE, CHEQ_ANALYTICS_ROUTE, CHEQ_DEFEND, CHEQ_SLP_ROUTE } from '../../utils/common';
const PAID_MARKETING = 'PaidMarketing';
const DATA_AND_ANALYTICS = 'AdvancedAnalytics';
const ON_SITE_CONVERSION = 'OnSiteConversion';
const PRIVACY = 'Privacy';
const CUSTOMER_ACQUISITION_SECURITY = 'CustomerAcquisitionSecurity';
const MARKETING_INTELLIGENCE_SECURITY = 'MarketingIntelligenceSecurity';
const ON_SITE_SECURITY = 'OnSiteSecurity';
const PRIVACY_COMPLIANCE_ENFORCEMENT = 'PrivacyComplianceEnforcement';
const SIGNUP_AND_LEAD_PROTECTION = 'LeadProtection';
const NETWORK_SETTINGS = 'networkSettings';

const licenses = {
    [PAID_MARKETING]: {
        key: CUSTOMER_ACQUISITION_SECURITY,
        id: 1,
    },
    [DATA_AND_ANALYTICS]: {
        key: CUSTOMER_ACQUISITION_SECURITY,
        id: 3,
    },
    [ON_SITE_CONVERSION]: {
        key: CUSTOMER_ACQUISITION_SECURITY,
        id: 2,
    },
    [PRIVACY]: {
        key: PRIVACY_COMPLIANCE_ENFORCEMENT,
        id: 5,
    },
    [SIGNUP_AND_LEAD_PROTECTION]: {
        key: SIGNUP_AND_LEAD_PROTECTION,
        id: 8,
    },
};

const newLicenses = {
    [CUSTOMER_ACQUISITION_SECURITY]: {
        key: CUSTOMER_ACQUISITION_SECURITY,
        path: CHEQ_ACQUISITION_ROUTE,
        id: 1,
    },
    [MARKETING_INTELLIGENCE_SECURITY]: {
        key: MARKETING_INTELLIGENCE_SECURITY,
        path: CHEQ_ANALYTICS_ROUTE,
        id: 3,
    },
    [ON_SITE_SECURITY]: {
        key: ON_SITE_SECURITY,
        path: CHEQ_DEFEND,
        id: 2,
    },
    [PRIVACY_COMPLIANCE_ENFORCEMENT]: {
        key: PRIVACY_COMPLIANCE_ENFORCEMENT,
        path: '',
        id: 5,
    },
    [SIGNUP_AND_LEAD_PROTECTION]: {
        key: SIGNUP_AND_LEAD_PROTECTION,
        path: CHEQ_SLP_ROUTE,
        id: 8,
    },
};

export { licenses, newLicenses, NETWORK_SETTINGS };
