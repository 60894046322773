import CheqUI from '@combotag/cheq-ui';
import config from '../../../config';
import { store } from '../../../redux/store';

const { Base } = CheqUI.ViewModels.Models;

export default class extends Base {
    reduxStore;

    constructor(name, baseRoute = name) {
        super(name, { baseRoute, baseURL: config.baseAPI });
        this.reduxStore = store;
    }

    init() {
        return super.init ? super.init() : this;
    }
}
