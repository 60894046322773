import React from 'react';

const TapIcon = ()=>

<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" fill="currentColor" stroke="currentColor"
	 viewBox="0 0 416.271 416.271"  >
<g>
	<g>
		<path d="M348.729,261.682c-0.642-0.641-1.329-1.196-2.065-1.634c-24.714-15.54-57.702-12.623-79.225,8.9l-11.312,11.313V128.022
			c0-17.601-14.4-32-32-32c-17.6,0-32,14.399-32,32v70.402c-5.848-7.163-14.634-11.832-24.614-11.832
			c-6.933,0-13.309,2.274-18.55,6.037l-68.92,39.89c-9.495,5.534-15.917,15.782-15.917,27.557v52.616
			c0,57.212,46.374,103.58,103.579,103.58c27.71,0,52.814-10.944,71.399-28.666l0.012,0.151l109.611-109.62
			C353.281,273.593,353.281,266.227,348.729,261.682z"/>
	</g>
</g>
<g>
	<g>
		<rect x="208.128" width="32" height="64"/>
	</g>
</g>
<g>
	<g>
		<rect x="140.25" y="28.088" transform="matrix(0.7071 -0.7071 0.7071 0.7071 3.2761 128.0847)" width="32" height="63.999"/>
	</g>
</g>
<g>
	<g>
		<rect x="260.006" y="44.092" transform="matrix(0.7071 -0.7071 0.7071 0.7071 43.0352 224.0798)" width="63.999" height="32"/>
	</g>
</g>
<g>
	<g>
		<rect x="96.128" y="112" width="64" height="32"/>
	</g>
</g>
<g>
	<g>
		<rect x="288.128" y="112" width="64" height="32"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>



export default TapIcon;




