import ParadomeIcon from '@assets/paradome_sidebar.png';
import HelpIcon from '@/assets/svgs/help-circle.svg';
import PlatformIcon from '@/assets/svgs/platform-icon.svg';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
// @ts-ignore
import { Tooltip } from '@material-ui/core';
import { getIcon, getProductByPackage, getProducts, MAP_PRODUCT_URL_NAME_TO_ID, SidebarProduct } from './utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { routeItemType, routes } from './routes';
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedLicense } from '../../redux/actions/settings';
// @ts-ignore
import config from '../../config';
// @ts-ignore
import { useContentManager } from '@hooks';
import { useOnClickOutside } from '../../components/containers/exportables/hooks';
import styles from './Sidebar.module.scss';
import { makeStyles } from '@material-ui/core/styles';
// @ts-ignore
import { zendeskService } from '@services/api/zendesk.service';
// @ts-ignore
import mixpanel from '@services/mixpanel';
import Snackbar from '../components/Snackbar';
import ToolTip from '../../widgets/tool-tip/index';
import { useAuth0 } from '@auth0/auth0-react';
import { useSplit } from 'hooks';
import { constants } from 'utils/split.io';
const useStyles = makeStyles(() => ({
    tooltip: {
        width: '216px',
        padding: '6px 12px',
        fontSize: '12px',
        lineHeight: '130%',
    },
}));

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cm = useContentManager();
    const classes = useStyles();
    const selectRef = useRef(null);
    const { selectedLicense, networkFeatures } = useSelector((state: { settings: any }) => state.settings);
    const isNewLandingPage = useSplit(constants.PARADOME_REPACKAGING);
    const isNewSlpDashboard = useSplit(constants.PARADOME_NEW_SLP_DASHBOARD);
    const [opened, setOpened] = useState(false);
    const [tooltipOpened, setTooltipOpened] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    useOnClickOutside(selectRef, () => {
        if (!tooltipOpened) setOpened(false);
    });
    const { user } = useAuth0();

    const products = useMemo(() => (isNewLandingPage.isOn ? getProductByPackage(networkFeatures) : getProducts(networkFeatures)) || [], [networkFeatures, isNewLandingPage]);
    const [selected, setSelected] = useState<SidebarProduct>(products[0]);
    const productsToShow = useMemo(
        () =>
            products?.filter(item => {
                return item.value !== selected?.value && (isNewSlpDashboard.isOn ? true : item.value !== 8);
            }) || [],
        [products, selected?.value, isNewSlpDashboard.isOn]
    );
    const userMeta = user && user[`${window.location.origin}/meta`];

    if (userMeta?.networkId == config.outbrainNetworkId) {
        routes['CustomerAcquisitionSecurity'] = routes['CustomerAcquisitionSecurity'].filter(item => item.id !== 2);
        routes['CustomerAcquisitionSecurity'] = routes['CustomerAcquisitionSecurity'].filter(item => item.id !== 3);
        routes['CustomerAcquisitionSecurity'] = routes['CustomerAcquisitionSecurity'].filter(item => item.id !== 4);
    }

    useEffect(() => {
        if (selectedLicense) {
            const selectedLicenseData = products.filter(item => item.value === selectedLicense)[0];
            setSelected(selectedLicenseData);
        }
    }, [selectedLicense]);

    const onChangeProduct = (product: any) => {
        if (!product.active) {
            return;
        }
        setSelected(product);
        if (product.label !== 'PrivacyComplianceEnforcement') {
            mixpanel.postEvent('Sidebar change product', {
                'Chosen product name': cm.get(product.label),
            });
            navigate(routes[product.label][0].route);
            dispatch(setSelectedLicense(product.value));
            setOpened(false);
        } else {
            navigate(routes[product.label][0].route);
        }
    };

    useEffect(() => {
        const path = window.location.pathname;
        const productName = path?.split('/')[1];
        const productId = MAP_PRODUCT_URL_NAME_TO_ID[productName];
        const license = products.find(item => item.value === productId);
        if (license) {
            setSelected(license);
        }
    }, []);

    const isSelected = (path: string) => {
        return path === document.location.pathname;
    };

    const onClickContactUsHandler = async (label: string) => {
        mixpanel.postEvent('Sidebar disabled product contact us click', {
            'Disabled product name': cm.get(label),
        });
        setTooltipOpened(false);
        setOpened(false);
        const data = await zendeskService.createTicket({ product: label }, 'zendesk');
        data ? setShowSnackbar(true) : setShowSnackbar(false);
    };

    const onClickSolutionHandler = (solutionId: number) => {
        let eventName = '';
        switch (solutionId) {
            case 1:
                eventName = 'Sidebar dashboard click';
                break;
            case 2:
                eventName = 'Sidebar analytics click';
                break;
            case 3:
                eventName = 'Sidebar data-tools click';
                break;
            case 4:
                eventName = 'Sidebar settings click';
                break;
            case 5:
                eventName = 'Sidebar policy management click';
                break;
        }

        mixpanel.postEvent(eventName, {
            'Current product': cm.get(products.filter(item => item.value === selectedLicense)[0].label),
        });
    };

    const sideBarItem = (r: routeItemType, i: number) => (
        <Link to={r.route} key={i} className={`${styles.link} ${isSelected(r.route) && styles.isSelected}`} onClick={() => onClickSolutionHandler(r.id)}>
            <img src={getIcon(r.id)} alt="icon" className={styles.componentIcon} />
            {cm.get(r.name)}
        </Link>
    );

    return (
        <div id="Sidebar" className={styles.container}>
            <img src={ParadomeIcon} alt="logo" className={styles.icon} />
            <div className={styles.products}>
                <div ref={selectRef} className={styles.selectContainer} data-test-id="sidebar-dropdown-menu">
                    {isNewLandingPage.isOn && products.length === 1 ? (
                        <div className={styles.label}>
                            <ToolTip content={cm.get(products[0]?.label)} leaveDelay={100}>
                                <div className={styles.selectedNameContainer}>{cm.get(products[0]?.label)}</div>
                            </ToolTip>
                        </div>
                    ) : (
                        <>
                            <div className={`${styles.select} ${opened && styles.open}`} onClick={() => setOpened(!opened)}>
                                <div className={styles.label}>
                                    <img src={PlatformIcon} alt="icon" />
                                    <ToolTip content={cm.get(selected?.label)} leaveDelay={100}>
                                        <div className={styles.selectedNameContainer}>{cm.get(selected?.label)}</div>
                                    </ToolTip>
                                </div>
                                {opened ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />}
                            </div>
                            {opened && (
                                <div className={styles.dropdown}>
                                    {productsToShow.map((item: any) => (
                                        <div key={item.value} onClick={() => onChangeProduct(item)} className={`${styles.menuItem} ${!item.active && styles.disabled}`}>
                                            {cm.get(item.label)}
                                            {!item.active && (
                                                <div className={styles.help}>
                                                    <Tooltip
                                                        arrow
                                                        interactive={true}
                                                        classes={{
                                                            tooltip: classes.tooltip,
                                                        }}
                                                        title={
                                                            <div onMouseOver={() => setTooltipOpened(true)} onMouseOut={() => setTooltipOpened(false)}>
                                                                {cm.get(item.tooltip)}
                                                                <div
                                                                    onClick={() => onClickContactUsHandler(item.label)}
                                                                    style={{
                                                                        color: '#ea3385',
                                                                        fontSize: '12px',
                                                                        textDecoration: 'underline',
                                                                        textTransform: 'uppercase',
                                                                        lineHeight: '18px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    {cm.get('ContactUs')}
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                        <img src={HelpIcon} alt="help" />
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className={styles.pages}>
                    {routes[selected?.label]?.map((r: routeItemType, i: number) => {
                        return sideBarItem(r, i);
                    })}
                </div>
            </div>
            {showSnackbar && <Snackbar cm={cm} open={showSnackbar} onClose={() => setShowSnackbar(false)} />}
        </div>
    );
};
export default Sidebar;
