import React from "react";
import widgets from "../../../../widgets";
import styles from "./header.module.scss";
import { AddCircleOutline } from '@material-ui/icons';
import searchIcon from "../../assets/search.svg";
import CloseIcon from "../../assets/close.svg";
const { Button } = widgets;

export default ({ title, headerButtonText, count, searchValue, onSearchHandler, onClick, onClearHandler }) => (
    <div className={styles.container} data-test-selector="exportables-header-container">
        <div className={styles.title} data-test-selector="exportables-header-title">{`${title} (${count})`}</div>
        <div className={styles.options} data-test-selector="exportables-header-options">
            <div className={styles.inputItem} data-test-selector="exportables-header-search-container">
                <img src={searchIcon} alt="search"/>
                <input value={searchValue}
                       className={styles.input}
                       onChange={onSearchHandler}
                       placeholder="Search..."
                       data-test-selector="exportables-header-search-input"
                />
                {searchValue.length > 0 && <img src={CloseIcon} alt="clear-search" onClick={onClearHandler} className={styles.clear}/>}
            </div>
            <Button onClick={onClick}
                    className={styles.button}
                    data-test-selector="exportables-header-button"
            >
                <AddCircleOutline/> {headerButtonText}
            </Button>
        </div>
    </div>
)