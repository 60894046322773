import React from 'react';

const Exclamation = () => {
    return (
        <svg fill="#fff" height="1.8rem" width="1.8rem" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
            <path d="m256 420.570312c-4.757812 0-9.507812-2.015624-12.984375-5.300781-3.292969-3.476562-5.300781-8.234375-5.300781-12.984375 0-4.757812 2.007812-9.507812 5.300781-12.980468 5.117187-5.125 13.351563-6.769532 19.929687-3.851563 2.199219.917969 4.207032 2.195313 6.039063 3.851563 3.285156 3.472656 5.300781 8.222656 5.300781 12.980468 0 4.75-2.019531 9.507813-5.300781 12.984375-1.832031 1.640625-3.839844 2.917969-6.039063 3.839844-2.195312.910156-4.570312 1.460937-6.945312 1.460937zm0 0" />
            <path d="m256 347.429688c-10.097656 0-18.285156-8.1875-18.285156-18.285157v-201.144531c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v201.144531c0 10.097657-8.1875 18.285157-18.285156 18.285157zm0 0" />
        </svg>
    );
};

export default Exclamation;
