import React, { useState, useEffect } from 'react';

import styles from './Filter.module.scss';
import DatePicker from '@components/widgets/date-picker';

import { dateRange, parseDate } from '@utils/common';
import { Select, MenuItem } from '@material-ui/core';
import { Button, DarkSeparator,Toggler, ToolTip } from '@widgets';
import LastUpdated from './LastUpdated';
import { useContentManager } from '@hooks';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';

const Filter = ({ handleRefresh, state: defaultState }) => {
    const cm = useContentManager();
    const [state, setState] = useState(defaultState);
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const handleDateChange = ({ target: { name, value } }) => {
        if (value === 'Custom') return setDatePickerOpen(true);
        const { details } = dateRange.find(range => range.value === value);
        setState(prevState => ({
            ...prevState,
            [name]: { ...prevState[name], value, format: parseDate(details) },
        }));
    };

    const handleCustomDateRange = format => {
        const custom = dateRange.find(range => range.value === 'Custom');
        setState(prevState => ({
            ...prevState,
            dateRange: { ...prevState.dateRange, ...custom, format },
        }));
    };

    const handelCloseDateRangePicker = date => {
        if (date.length <= 1) {
            setState(state);
        }
        setDatePickerOpen(false);
    };

    const refresh = () => {
        handleRefresh(state);
    };

    const handleIncludePolicy = (e, checked) => {
        setState(prevState => ({
            ...prevState,
            policyToggleOSC: checked,

        }));

    };

    useEffect(() => {
        if (defaultState) {
            setState(defaultState);
        }
    }, [defaultState]);

    const isDisabled = JSON.stringify(defaultState) === JSON.stringify(state);

    return (
        <div className={styles.root} data-test-selector="rti-filter-section">
            <span>{cm.get('ShowingDataFor')}</span>
            <div className={styles.select}>
                <Select
                    data-test-selector="rti-daterange-select"
                    fullWidth
                    onChange={handleDateChange}
                    name="dateRange"
                    value={state.dateRange?.value}
                    variant="outlined"
                    margin="dense"
                    className={styles.selectInput}
                    classes={{ outlined: styles.small, select: styles.small }}
                >
                    {dateRange.map(({ label, value }, i) => (
                        <MenuItem key={value + i} value={value}>
                            {cm.get(label)}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <DatePicker
                open={datePickerOpen}
                value={state.dateRange?.format || []}
                options={{
                    onChange: handleCustomDateRange,
                    onClose: handelCloseDateRangePicker,
                }}
                className={styles.datePickerInput}
            />
            <DarkSeparator />
            <LastUpdated update={defaultState} cm={cm} />
            {state.includePolicy && <Toggler
                    data-test-selector='paidmarketing-known-bots-toggle'
                    onChange={handleIncludePolicy}
                    checked={state.policyToggleOSC}
                    label={
                        <>
                            <span>{cm.get('IncludePolicy')}</span>
                            <ToolTip title={cm.get('IncludePolicy.Title')}
                                     content={cm.get('IncludePolicy.Description')}>
                                <InfoIcon cursor='pointer' />
                            </ToolTip>
                        </>
                    }
                />}
            <Button disabled={isDisabled} className={styles.refreshButton} onClick={refresh}>
                {cm.get('Refresh')}
            </Button>
        </div>
    );
};

export default Filter;
