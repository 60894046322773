import { FC, useEffect, useState } from 'react';
/* @ts-ignore */
import { useContentManager } from '@hooks';
import styles from '../styles.module.scss';
import Select from '../../../exportables/ui/filters-list/components/select';
import EditIcon from '../../../exportables/assets/edit.svg';
import { CircularProgress, Divider } from '@material-ui/core';
import { HubSpotFieldsValues } from '../../../../../types/hubspot';
import { CustomModal } from "../../../exportables/ui/custom-modal";
import { hubSpotFieldsOptions } from '../utils';
import ToolTip from 'widgets/tool-tip';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';

type HubSpotConnectorProps = {
    isConnected: boolean;
    isDisconnectLoading: boolean;
    selectedHubSpotFields: HubSpotFieldsValues[];
    handleConnect: (selectedHubSpotFields: HubSpotFieldsValues[]) => void;
    handleDisconnect: () => void;
    handleEdit: (selectedHubSpotFields: HubSpotFieldsValues[]) => void;
};

export const HubSpotConnector: FC<HubSpotConnectorProps> = (props) => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const { isConnected, handleConnect, handleDisconnect, handleEdit, selectedHubSpotFields, isDisconnectLoading } = props;
    const cm = useContentManager();
    const [editedSelectedHubSpotFields, setEditedSelectedHubSpotFields] = useState<HubSpotFieldsValues[]>([]);
    const [editFieldsMode, setEditFieldsMode] = useState<boolean>(false);
    const [isRequestEnd, setIsRequestEnd] = useState<boolean>(true);

    useEffect(() => setEditedSelectedHubSpotFields(selectedHubSpotFields), [selectedHubSpotFields]);

    const selectOneItem = (row: HubSpotFieldsValues) => {
        const foundedField: boolean = editedSelectedHubSpotFields.some(selectedField => selectedField.value === row.value);
        if (foundedField) {
            setEditedSelectedHubSpotFields(previousSelectedFields => previousSelectedFields.filter(selectedField => selectedField.value !== row.value));
        } else {
            setEditedSelectedHubSpotFields(previousSelectedFields => [...previousSelectedFields, row]);
        }

    };

    const updateFields = async () => {
        try {
            if (editedSelectedHubSpotFields?.length && isRequestEnd) {
                setIsRequestEnd(false);
                await handleEdit(editedSelectedHubSpotFields);
                setEditFieldsMode(false);
                setIsRequestEnd(true);
            }
        }
        catch (error) {
            setIsRequestEnd(true);
            return;
        }
    };

    return (
        <div className={styles.connectorContainer}>
            <div className={styles.enrichedFieldsTitle}>
                <span>{cm.get('HubFieldsToBeValidated')}</span>
                <ToolTip content={cm.get('HubFieldsToBeValidatedToolTip')}>
                    <InfoIcon className={styles.icon} cursor="pointer" />
                </ToolTip></div>
            <div className={styles.selectContainer}>
                <div className={styles.selectors}>
                    <Select
                        cm={cm}
                        selector={'hubspot-selector'}
                        key={'index'}
                        type={''}
                        options={hubSpotFieldsOptions}
                        selected={editedSelectedHubSpotFields}
                        onSelect={selectOneItem}
                        placeholder={cm.get('SelectFields') + '...'}
                        style={{ background: '#383852', height: '40px', border: 0, borderRadius: '4px', cursor: !editFieldsMode && isConnected ? 'unset' : 'pointer' }}
                        dropDownStyle={{ top: '50px', background: '#282A3F', border: '0.5px solid #FFFFFF' }}
                        disabled={!editFieldsMode && isConnected}
                    />
                </div>
                <div>
                    {isConnected
                        ? editFieldsMode
                            ? <div className={styles.editActiveModeContainer}>
                                <div className={styles.edit}><img src={EditIcon} alt="edit" width="18" height="18" /> {cm.get('Edit')}</div>
                                <Divider orientation="vertical" />
                                <span className={`${styles.save} ${!editedSelectedHubSpotFields?.length && styles.disabledSaveButton}`} onClick={updateFields}>{cm.get('Save')}</span>
                                <span className={styles.discard} onClick={() => { setEditFieldsMode(false); setEditedSelectedHubSpotFields(selectedHubSpotFields); }}>{cm.get('Discard')}</span>
                            </div>
                            : <div className={styles.editContainer} onClick={() => setEditFieldsMode(true)}><img src={EditIcon} alt="edit" width="18" height="18" /> {cm.get('Edit')}</div>
                        : <></>}
                </div>
            </div>
            {isConnected
                ? <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={`
                    ${styles.connectorButton}
                    ${styles.disconnectButton}
                    ${isDisconnectLoading && styles.disabledSaveDisconnectButton}`
                    } onClick={() => !isDisconnectLoading && setShowPopup(true)}>
                        {isDisconnectLoading && <span style={{ color: '#788397' }}><CircularProgress size={14} color='inherit' /></span>}
                        <span className={`${styles.connectionText} ${isDisconnectLoading && styles.disconnectLoaderButtonText}`}>
                            {cm.get('Disconnect').toUpperCase()}
                        </span>
                    </div>
                    {isDisconnectLoading && <div className={styles.disconnectTextLoading}>{cm.get('DisconnectHubSpotLoadingText')}</div>}
                </div>
                : <div className={`${styles.connectorButton} ${styles.connectButton} ${!editedSelectedHubSpotFields?.length && styles.disabledButton}`} onClick={() => editedSelectedHubSpotFields?.length && handleConnect(editedSelectedHubSpotFields)}><span className={styles.connectionText}>{cm.get('Connect').toUpperCase()}</span></div>
            }
            {showPopup ? <CustomModal onClose={() => setShowPopup(false)} title={cm.get('DisconnectHubSpotModalTitle')} description={cm.get('DisconnectHubSpotModalDescription')} onSubmitButtonText={cm.get('Yes')} onCancelButtonText={cm.get('Cancel')} onSubmit={handleDisconnect} /> : <></>}
        </div>
    );
};
