import { Button as MuiButton } from '@material-ui/core';
import React from 'react';
import cx from 'classnames';
import styles from './button.module.scss';

const Button = ({ disabled = false, className, isLoading, onEnter, ...props }) => (
    <MuiButton {...props} disabled={disabled} className={cx(styles.root, className, { [styles.disabled]: disabled })} />
);

export default Button;
