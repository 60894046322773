import React, { useState } from 'react';
import styles from './styles.module.css';

export default function TreeView({
    tree = {},
    depth = 0,
    selectedNode = '',
    RenderNode = ({ value }) => <span>{value}</span>,
    ...props
}) {
    const [activeNodes, setActiveNodes] = useState([]);
    const toggleNode = node => activeNodes.includes(node)
        ? setActiveNodes(activeNodes.filter(n => n !== node))
        : setActiveNodes([node, ...activeNodes]);

    return <div {...props}>
        {Object.entries(tree).map(([prop, propTree], idx) =>
            <div className={styles['container']}
                key={idx}>
                <TreeToggle
                    style={{ visibility: Object.keys(propTree).length ? 'initial' : 'hidden' }}
                    isOpen={activeNodes.includes(prop)}
                    onClick={() => toggleNode(prop)} />
                <div
                    className={`${styles['node']} ${selectedNode === prop ? styles['selected'] : ''}`}>
                    <RenderNode value={prop} />
                </div>

                {activeNodes.includes(prop) ?
                    <TreeView tree={propTree}
                        depth={depth + 1}
                        selectedNode={selectedNode}
                        RenderNode={RenderNode} /> : null}
            </div>)}
    </div>;
}

const TreeToggle = ({ isOpen, onClick, style }) => <div
    style={style} onClick={onClick} className={styles['toggle-container']}>
    <div className={`${styles['toggle']} ${isOpen ? styles['open'] : ''}`}></div>
</div>;