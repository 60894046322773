import React from 'react';

export default ({ color, size = 60, top = size, bottom = size, left = size, style, children, opacity, ...rest }) =>
    <div style={{
        width: 0,
        height: 0,
        borderTop: `${top}px solid transparent`,
        borderBottom: `${bottom}px solid transparent`,
        borderLeft: `${left}px solid ${hexToRgb(color, opacity)}`,
        display: 'inline-block',
        ...style
    }} {...rest} >{children}</div>

function hexToRgb(hex, o) {
    const [h, ...result] = (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || []).map(c => parseInt(c, 16));
    return !result ? '' : `rgb(${result.join(',')} ${o ? ',' + o : ''})`;
}