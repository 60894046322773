import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import AddApiButton from '../../../../widgets/add-api-button';
import CreateApi from './CreateApi';
import styles from '../styles.module.scss';
import { UserAPI } from './Types';
import UserApi from './UserApi';
import { iam } from 'services/api';
import Progress from '../../../../widgets/progress';
import useFetch from 'components/hooks/use-fetch';

interface IProps {
    cm: any;
}

const ApiIntegration: FC<IProps> = ({ cm }) => {
    const [showCreateApi, setShowCreateApi] = useState(false);
    const networkId: number = useSelector((state: any) => state.profile.meta.networkId);
    const [usersApis, setUsersApis, isLoading, error] = useFetch<UserAPI[]>(iam.getUsers, [networkId]);

    const toggleCreateApi = () => setShowCreateApi(prevState => !prevState);

    const addUserApi = (userApi: UserAPI) => {
        setUsersApis(prevState => [...prevState, userApi]);
    };

    const updateUserApi = (username: string, newPassword: string) => {
        const foundUserIndex = usersApis.findIndex(user => user.username === username);
        if (foundUserIndex === -1) return;

        usersApis[foundUserIndex] = { ...usersApis[foundUserIndex], password: newPassword };
        setUsersApis([...usersApis]);
    };

    return (
        <div className={styles.secretKey}>
            <div className={styles.createContainer}>
                <div className={styles.row}>
                    <AddApiButton onClickHandler={toggleCreateApi} text={cm.get('AddIntegration')} />
                </div>
                {showCreateApi && <CreateApi addUserToUI={addUserApi} networkId={networkId} cm={cm} />}
            </div>
            {error && <span className={styles.error}>{error}</span>}
            {isLoading ? <Progress /> : usersApis.map((userApi, i) => <UserApi key={i} user={userApi} updateUserToUI={updateUserApi} cm={cm} />)}
        </div>
    );
};

export default ApiIntegration;
