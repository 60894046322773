import { httpRequest, methods } from '../http';

export type TokenResponse = {
    token: string;
};
const BASE_URL = 'api/v2/superset';

class SupersetService {
    static getGuestToken = async (dashboardId: string): Promise<TokenResponse> => {
        const responsePromise = httpRequest({
            pathname: `${BASE_URL}/token`,
            method: methods.get,
            params: { dashboardId: dashboardId },
        });
        const response: Promise<TokenResponse> = responsePromise.response;
        return response;
    };
}

export default SupersetService;
