import React, { useEffect, useRef, useState } from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import styles from './style.module.scss';

const SelectBox = ({ value, onChange, options }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectContainer = useRef(null);
    const popup = useRef(null);
    const [popupStyle, setPopupStyle] = useState({ top: 0, left: 0 });

    const syncSelectContainerWithPopupStyle = () => {
        if (selectContainer.current) {
            const { bottom, left, width } = selectContainer.current.getBoundingClientRect();
            setPopupStyle({ top: bottom, left, width });
        }
    };

    useEffect(() => {
        syncSelectContainerWithPopupStyle();
    }, [selectContainer.current]);

    const listenToBackgroundClick = e => {
        if (selectContainer.current && popup.current) {
            const clickedElements = document.elementsFromPoint(e.clientX, e.clientY);
            if (!clickedElements.find(element => selectContainer.current === element || popup.current === element)) {
                setIsOpen(false);
            }
        }
    };

    const listenToResize = () => {
        syncSelectContainerWithPopupStyle();
    };

    useEffect(() => {
        window.addEventListener('mousedown', listenToBackgroundClick);
        window.addEventListener('resize', listenToResize);

        return () => {
            window.removeEventListener('mousedown', listenToBackgroundClick);
            window.removeEventListener('resize', listenToResize);
        };
    }, []);

    return (
        <div className={styles.selectContainer} onMouseDown={e => e.preventDefault()} onClick={() => setIsOpen(!isOpen)} ref={selectContainer}>
            <div className={styles.label}>{options.find(({ value: optionValue }) => value === optionValue)?.label}</div>
            <div className={styles.icon}>{isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div>
            {isOpen && (
                <div className={styles.selectPopup} ref={popup}>
                    {options.map(
                        ({ value: optionValue, label }, idx) =>
                            value !== optionValue && (
                                <div
                                    className={styles.selectOption}
                                    style={{ height: 18 }}
                                    onClick={() => {
                                        if (value !== optionValue) {
                                            setIsOpen(false);
                                            onChange(optionValue);
                                        }
                                    }}
                                    key={idx}
                                >
                                    {label}
                                </div>
                            )
                    )}
                </div>
            )}
        </div>
    );
};

export default SelectBox;
