import APIInterceptions from './api-interceptions';
import ClientSideInterceptions from './client-interceptions';

import styles from './interceptions.module.scss';
import { DashboardSection, Button } from '@widgets';
import { useContentManager } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import CogWheel from '@assets/svgs/cog-wheel.svg';
import { CHEQ_DEFEND } from '../../../../../utils/common';

const Interceptions = ({ isClientSideIntegration, isApiIntegration, options, isSettingsLoading }) => {
    const navigate = useNavigate();
    const cm = useContentManager();

    const navigateToRTISettings = () => {
        navigate(`/${CHEQ_DEFEND}/settings`, {
            state: {
                isFromRTIInterceptions: true,
            },
        });
    };

    const isShowSetup = !isClientSideIntegration && !isApiIntegration && !isSettingsLoading;

    return (
        <DashboardSection
            title={
                <div className={styles.title}>
                    <div>{cm.get('InvalidInterceptionsOpportunities').toUpperCase()}</div>
                    {!isShowSetup && !isSettingsLoading && (
                        <div>
                            <Button onClick={navigateToRTISettings} startIcon={<FiSettings />}>
                                {cm.get('Settings').toUpperCase()}
                            </Button>
                        </div>
                    )}
                </div>
            }
            data-test-selector="rti-intercptions"
            className={styles.root}
        >
            {isShowSetup && (
                <div className={styles.setUpSettings}>
                    <div>
                        <img src={CogWheel} />
                    </div>
                    <div className={styles.setUpTitle}>{cm.get('SetRealTimeInterceptioIntegrationType')}</div>
                    <div className={styles.description}>
                        {cm
                            .get('RTIInterceptionSetUpDescription')
                            .split('\n')
                            .map(text => (
                                <p key={text}>{text}</p>
                            ))}
                    </div>
                    <div>
                        <Button onClick={navigateToRTISettings}>{cm.get('SetUpNow')}</Button>
                    </div>
                </div>
            )}
            {(isApiIntegration || isSettingsLoading) && <APIInterceptions options={options} cm={cm} isSettingsLoading={isSettingsLoading} />}
            {(isClientSideIntegration || isSettingsLoading) && <ClientSideInterceptions options={options} cm={cm} isSettingsLoading={isSettingsLoading} />}
        </DashboardSection>
    );
};

export default Interceptions;
