import { FC, PropsWithChildren } from 'react';
import CheqUI, { InputProps } from '@combotag/cheq-ui';
import styles from './input.module.scss';
import ToolTip from 'widgets/tool-tip';
import ExclamationIcon from '../../components/svgs/exclamation';

export type InputWithTooltipProps = PropsWithChildren<InputProps & { label?: string; className?: string; tooltipContent?: string; disabled?: boolean }>;

export const InputWithTooltip: FC<InputWithTooltipProps> = ({ label, className, tooltipContent, children, disabled = false, errorMessage, value, ...rest }) => (
    <div className={`${styles.inputWrapper} ${className ? className : ''} ${disabled ? styles.disabled : ''}`}>
        <div className={styles.labelWrapper}>
            {label && <label>{label}</label>}
            {tooltipContent && (
                <ToolTip content={tooltipContent}>
                    <div className={styles.exclamationIcon}>{children || <ExclamationIcon />}</div>
                </ToolTip>
            )}
        </div>
        <CheqUI.React.Widgets.Input {...rest} value={value || ''} styles={rest.styles || { container: styles.container, input: styles.input }} height="2.75rem" />
        <div className={styles.error}>{errorMessage}</div>
    </div>
);
