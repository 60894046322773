import { iam } from '@services/api';

import * as actions from '../actions/users';

const inviteUser = user => async dispatch => {
    try {
        dispatch(actions.setLoading(true));
        const userResponse = await iam.inviteUser(user);
        dispatch(actions.inviteUser({ ...user, id: userResponse.id, userTypeName: user.userType }));
        dispatch(actions.setLoading(false));
    } catch (e) {
        console.error(e);
        return {};
    }
};

const getUsersInOrganization = (orgId, connectionName, sso) => async dispatch => {
    try {
        dispatch(actions.setLoading(true));
        // const validDomains = (await accounts.getValidDomains()) || [];
        const res = (await iam.getUsersInOrganization(orgId, connectionName, sso /*, validDomains*/)) || [];
        dispatch(actions.setUsers(res));
        dispatch(actions.setLoading(false));
    } catch (e) {
        console.error(e);
        return {};
    }
};

const deleteUser = (id, orgId, isPrivayProduct) => async dispatch => {
    try {
        dispatch(actions.setLoading(true));
        await iam.removeUserFromOrganization(id, orgId, isPrivayProduct);
        dispatch(actions.removeUserFromOrganization(id));
        dispatch(actions.setLoading(false));
    } catch (e) {
        console.error(e);
        return {};
    }
};

const handleUserStatus = params => async dispatch => {
    try {
        dispatch(actions.setLoading(true));
        await iam.handleUserStatus(params);
        dispatch(actions.handleUserStatus(params));
        dispatch(actions.setLoading(false));
    } catch (e) {
        console.error(e);
        return {};
    }
};
const editUser = params => async dispatch => {
    try {
        dispatch(actions.setLoading(true));
        await iam.editUser(params);
        dispatch(actions.editUser(params));
        dispatch(actions.setLoading(false));
    } catch (e) {
        console.error(e);
        return {};
    }
};

export { inviteUser, getUsersInOrganization, deleteUser, editUser, handleUserStatus };
