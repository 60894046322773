import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    helloContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    username: {
        display: 'flex',
        // width: '80px',
        height: '13px',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: '0.16px',
        textAlign: 'left',
        color: '#fe0072',
        marginTop: '5px',
        textTransform: 'capitalize',
    },
}));

export default ({ name }) => {
    const styles = useStyles();
    return (
        <div className={styles.helloContainer}>
            <div>
                <PermIdentityIcon style={{ color: '#fe0072' }} />
            </div>
            <div className={styles.username}>{`${name}`}</div>
        </div>
    );
};
