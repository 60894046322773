import React, { useEffect, useState } from 'react';

import Arrows from '../arrows';
import styles from './style.module.css';
import { useTheme } from '@material-ui/core/styles';

export default function ({ size = 40, factor = 80, children = [], style, onupdate = console.log, defaultSelected = 0, ...props }) {
    if (!Array.isArray(children)) return <div />;

    const theme = useTheme();
    const [selected, select] = useState(defaultSelected);

    const next = () => select(selected + 1);
    const prev = () => select(selected - 1);
    const jumpTo = val => select(val);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (onupdate !== console.log) onupdate({ next, prev, jumpTo, select, selected });
    });

    const components = children.filter(child => child).map(child => child({ next, prev, jumpTo, select }));

    const Component = components[selected];
    const { background } = theme.palette;

    const getColors = (selected, current) =>
        selected === current
            ? { text: theme.palette.custom.text.secondary, background: background.paper }
            : selected > current
            ? { text: theme.palette.custom.text.primary, background: background.component }
            : { text: theme.palette.custom.text.disabled, background: background.component };

    const Step = ({ idx, title = `STEP ${idx + 1}`, subtitle, colors, isLast, isFirst, handleStepClick = null }) => (
        <div
            onClick={handleStepClick}
            className={`${styles['step-container']} ${handleStepClick ? styles['step-container-clickable'] : ''}`}
            style={{
                height: size * 2,
                width: `${100 / components.length}%`,
                color: colors.text,
                backgroundColor: colors.background,
            }}
        >
            <div className={styles['content']} style={{ marginLeft: isFirst ? 20 : 50 }}>
                <div style={{ fontSize: '1.5rem' }}>{title}</div>
                <div style={{ fontSize: '1.2rem' }}>{subtitle}</div>
            </div>
            {isLast ? (
                <>
                    <Arrows.TwinLeft className={styles['arrow']} factor={factor} size={size} color={'#0b0c12'} color2={colors.background} />
                    <div className={styles['arrow-top-fix']} />
                    <div className={styles['arrow-bottom-fix']} />
                </>
            ) : (
                <div />
            )}
        </div>
    );

    return (
        <div
            className={styles['container']}
            style={{
                color: theme.palette.custom.text.secondary,
                backgroundColor: theme.palette.background.paper,
            }}
            {...props}
        >
            <div style={{ overflow: 'hidden' }}>
                <div className={styles['steps-container']}>
                    {components.map((child, idx) => (
                        <Step
                            isFirst={!idx}
                            isLast={idx !== components.length - 1}
                            colors={getColors(idx, selected)}
                            key={idx}
                            idx={idx}
                            selected={idx === selected}
                            {...child.props}
                        />
                    ))}
                </div>
            </div>
            <div className={styles['component-container']}>{Component}</div>
        </div>
    );
}
