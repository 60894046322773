import * as actions from '../actions/settings';
import networksServices from '../../services/api/networks.service';

export const getNetworkFeatures = networkId => async (dispatch, _, api) => {
    dispatch(actions.settingsDataLoading());
    try {
        const data = await api.settings.getNetworkFeatures(networkId);
        dispatch(getGoogleScopes());
        dispatch(actions.setNetworkFeatures(data.products));
        localStorage.setItem('products', JSON.stringify(data.products));
    } catch (err) {
        console.error(err);
        dispatch(actions.settingsRequestFailed(err));
    }
};

export const getGoogleScopes = () => async (dispatch, _, api) => {
    try {
        let { data } = await api.sso.getGoogleScopes();
        data = data.reduce((acc, scope) => {
            acc[scope] = true;
            return acc;
        }, {});
        dispatch(actions.setGoogleScopes(data));
    } catch (err) {
        console.log({ err });
    }
};

export const generateFeatureKey = id => async (dispatch, _, api) => {
    dispatch(actions.settingsDataLoading());
    try {
        const { data } = await api.sso.generateFeatureKey(id);
        const [featureObject] = data;
        dispatch(actions.setRTIToken(featureObject?.settings?.key));
    } catch (err) {
        dispatch(actions.settingsRequestFailed(err));
    }
};

export const getNetworkServingDomain = () => async (dispatch, _, api) => {
    dispatch(actions.settingsDataLoading());
    try {
        const data = await api.settings.getNetworkServingDomain();
        dispatch(actions.setServingDomain(data));
    } catch (err) {
        console.error(err);
        dispatch(actions.settingsRequestFailed(err));
    }
};

export const getDedicatedWLDomains = () => async (dispatch, _) => {
    try {
        const dedicatedWLDomainsData = await networksServices.getDedicatedWLDomains();
        dispatch(actions.setDedicatedWLDomains(dedicatedWLDomainsData));
    } catch (err) {
        console.error(err);
        dispatch(actions.settingsRequestFailed(err));
    }
};

export const getSettings = () => async (dispatch, _, api) => {
    try {
        const { data } = await api.sso.getSettings();
        dispatch(actions.setServices(data));
    } catch (err) {
        dispatch(actions.settingsRequestFailed(err));
    }
};

export const getNetworkSettings = data => async (dispatch, _) => {
    try {
        dispatch(actions.setPxgVersion(data.pxgVersion));
        dispatch(actions.setIsEnabledAffiliateFraud(data.isAffiliateFraudEnabled));
        dispatch(actions.setIsGAConnertorIsEnabled(data.isGaConnectorEnabled));
        dispatch(actions.setDedicatedWLD(data.dedicatedWLDomains));

    } catch (err) {
        dispatch(actions.settingsRequestFailed(err));
    }
};
