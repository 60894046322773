import * as React from "react"

function RightArrow(props) {
    return (
        <svg
            width={15}
            height={26}
            viewBox="0 0 15 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.184 13.379c.488-.488.488-1.172 0-1.66L2.758.39c-.488-.489-1.27-.489-1.66 0l-.684.683c-.488.488-.488 1.172 0 1.66l9.961 9.766L.316 22.363a1.322 1.322 0 000 1.66l.782.684c.39.488 1.172.488 1.66 0l11.426-11.328z"
                fill="#fff"
            />
        </svg>
    )
}

export default RightArrow;
