import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useQuery } from 'react-query';
import { DashboardItem, DashboardSection, DonutGraph, LegendColor } from '@widgets';
import * as api from '@services/api/dashboard';
import { getPercentage, formatToK } from '@utils/dashboard';
import { useContentManager } from '@hooks';
import styles from './VisitsSummary.module.scss';
import { getPlatformLabel, getPlatformValue, MAX_SCREEN_WIDTH } from './helper';
import { VISIT_TYPES } from '../../constants';

const { paid, organic, direct, unclassified } = VISIT_TYPES;

export const VisitsSummary = ({ options }) => {
    const dashboardCM = useContentManager(cm => cm.dashboard);
    const { selectedPlatformsGroup, visiblePlatformsGroups = {} } = useSelector(state => state.dashboard);
    const { isError, isLoading, data: reason = [] } = useQuery([api.paidMarketingKeys.INVALID_VISITS_BY_REASON, options], () => api.getInvalidVisitsByReason(options));
    const queryOptions = { ...options, platform: '', platformGroups: visiblePlatformsGroups };
    const { data: visitsSummaryForVisiblePlatformsGroups = [] } = useQuery([api.paidMarketingKeys.TOP_PLATFORMS_BY_TOTAL_VISITS, JSON.stringify(queryOptions)], () =>
        api.getTopPlatformsByTotalVisits(queryOptions)
    );

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < MAX_SCREEN_WIDTH);
    // Change Pie size on window resize
    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth < MAX_SCREEN_WIDTH);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize); // Cleanup event listener on component unmount
    }, []);

    let platforms = [];
    if (selectedPlatformsGroup?.group.toLowerCase().includes('all') && !selectedPlatformsGroup?.group.includes('ALL PROTECTED PLATFORMS')) {
        visitsSummaryForVisiblePlatformsGroups?.slice(1, 6).forEach(item => platforms.push(item.name));
    }

    let trendsOptions = options;
    if (!trendsOptions.platform) {
        trendsOptions.platform = 'ALL PLATFORMS (17)';
    }
    if (platforms.length > 0) {
        trendsOptions = { ...trendsOptions, platforms };
    }

    /**
     *x
     * @param {string} platformName - google/facebook/direct etc...
     * @returns will return the visit data of the input platform
     */
    const getDataByPlatform = platformName => {
        const platformData = visitsSummaryForVisiblePlatformsGroups.find(plat => plat.name === platformName);
        return platformData;
    };
    const directData = getDataByPlatform(direct.toUpperCase()) || {
        organic: {
            total: 0,
            invalidClicks: 0,
        },
    };
    const parsedData = reason?.[0] || {
        total: 0,
        invalid: 0,
        organic: {
            total: 0,
            invalid: 0,
        },
        paid: {
            total: 0,
            invalid: 0,
        },
        threatTypes: {
            paid: {},
            organic: {},
        },
    };

    const values = [
        {
            label: dashboardCM.get(getPlatformLabel(paid)),
            invalid: parsedData?.paid?.invalid,
            value: parsedData?.paid?.total,
            color: '#65C4CE',
            percentage: getPercentage(parsedData?.paid?.total, parsedData?.total),
            key: getPlatformValue(paid),
            title: dashboardCM.get('InvalidPaid.Title'),
            info: {
                title: dashboardCM.get('InvalidPaid.Title'),
                content: dashboardCM.get('InvalidPaid.Description'),
            },
            dataTestSelector: 'dataanalytics-invalid-paid-visits',
        },
        {
            label: dashboardCM.get(getPlatformLabel(organic)),
            invalid: parsedData?.organic?.invalid - directData?.organic?.invalidClicks,
            value: parsedData?.organic?.total - directData?.organic?.total,
            color: '#43A5FF',
            percentage: getPercentage(parsedData?.organic?.total - directData?.organic?.total, parsedData?.total),
            key: getPlatformValue(organic),
            title: dashboardCM.get('InvalidOrganic.Title'),
            info: {
                title: dashboardCM.get('InvalidOrganic.Title'),
                content: dashboardCM.get('InvalidOrganic.Description'),
            },
            dataTestSelector: 'dataanalytics-invalid-organic-visits',
        },
        {
            label: dashboardCM.get(getPlatformLabel(direct)),
            invalid: directData?.organic?.invalidClicks,
            value: directData?.organic?.total,
            color: '#808DDA',
            percentage: getPercentage(directData?.organic?.total, parsedData?.total),
            key: getPlatformValue(direct),
            title: dashboardCM.get('InvalidDirect.Title'),
            info: {
                title: dashboardCM.get('InvalidDirect.Title'),
                content: dashboardCM.get('InvalidDirect.Description'),
            },
            dataTestSelector: 'dataanalytics-invalid-direct-visits',
        },
    ];

    if (parsedData?.unclassified?.total) {
        values.push({
            label: dashboardCM.get(getPlatformLabel(unclassified)),
            invalid: parsedData?.unclassified?.invalid || 0,
            value: parsedData?.unclassified?.total || 0,
            color: '#f5da84',
            percentage: getPercentage(parsedData?.unclassified?.total, parsedData?.total) || 0,
            key: getPlatformValue(unclassified),
            dataTestSelector: 'dataanalytics-invalid-unclassified-visits',
        });
    }

    const getOutOfTotalTemplate = (title, total) => {
        const totalValue = total && !isNaN(total) ? total.toLocaleString() : '';
        let suffix = '';

        switch (title) {
            case dashboardCM.get('TotalInvalid.Title'):
                suffix = 'Visits';
                break;
            case dashboardCM.get('InvalidPaid.Title'):
                suffix = 'PaidVisits';
                break;
            case dashboardCM.get('InvalidOrganic.Title'):
                suffix = 'OrganicVisits';
                break;
            case dashboardCM.get('InvalidDirect.Title'):
                suffix = 'DirectVisits';
                break;
            default:
                return null;
        }

        return dashboardCM.get(`OutOfTotal${suffix}`).replace('{number}', totalValue);
    };

    const renderBody = () => {
        return (
            <div className={styles.root}>
                <div className={styles.row}>
                    <DashboardItem
                        key="dataanalytics-total-visits"
                        data-test-selector="dataanalytics-total-visits"
                        isError={isError}
                        isLoading={isLoading}
                        className={styles.totalValues}
                        title={dashboardCM.get('TotalVisits.Title')}
                        info={{
                            title: dashboardCM.get('TotalVisits.Title'),
                            content: dashboardCM.get('TotalVisits.Description'),
                        }}
                    >
                        <div className={styles.wrapper}>
                            <DonutGraph total={parsedData.total} values={values} wrapperSize={isSmallScreen ? '130px' : '160px'} />
                            <div className={styles.values}>
                                {values.map((v, i) => (
                                    <div className={styles.value} key={v.label}>
                                        <LegendColor color={v.color} />
                                        <span>{`${v.label} ${formatToK(v.value)} (${v.percentage}%)`}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </DashboardItem>
                    <DashboardItem
                        key="dataanalytics-total-invalid-visits"
                        data-test-selector="dataanalytics-total-invalid-visits"
                        isError={isError}
                        isLoading={isLoading}
                        className={styles.item}
                        title={dashboardCM.get('TotalInvalid.Title')}
                        info={{
                            title: dashboardCM.get('TotalInvalid.Title'),
                            content: dashboardCM.get('TotalInvalid.Description'),
                        }}
                    >
                        <div className={styles.wrapper}>
                            <div className={styles.numbers}>
                                <span className={styles.value}>{parsedData.invalid.toLocaleString()}</span>
                                <span className={styles.percentage}>({getPercentage(parsedData.invalid, parsedData.total)}%)</span>
                            </div>
                            <div className={styles.outOfTotal}>
                                <span className={styles.spacer}>{getOutOfTotalTemplate(dashboardCM.get('TotalInvalid.Title'), parsedData.total)}</span>
                            </div>
                        </div>
                    </DashboardItem>
                    {values.map(item => {
                        if (selectedPlatformsGroup?.group === getPlatformValue(direct).toUpperCase() && item.label === dashboardCM.get(getPlatformLabel(paid))) return null;
                        if (item.label === dashboardCM.get(getPlatformLabel(unclassified))) return null;
                        return (
                            <DashboardItem
                                key={item.dataTestSelector}
                                data-test-selector={item.dataTestSelector}
                                isError={isError}
                                isLoading={isLoading}
                                info={item.info}
                                className={styles.item}
                                title={item.title}
                            >
                                <div className={styles.wrapper}>
                                    <span className={styles.color}>
                                        <LegendColor color={item.color} />
                                    </span>
                                    <div className={styles.numbers}>
                                        <span className={styles.value}>{item.invalid && item.invalid.toLocaleString()}</span>
                                        <span className={styles.percentage}>({getPercentage(item.invalid, item.value)}%)</span>
                                    </div>
                                    <div className={styles.outOfTotal}>
                                        <span className={cx({ [styles.spacer]: !!item.color })}>{getOutOfTotalTemplate(item.title, item.value)}</span>
                                    </div>
                                </div>
                            </DashboardItem>
                        );
                    })}
                </div>
            </div>
        );
    };
    return (
        <DashboardSection title={dashboardCM.get('VisitsSummary').toUpperCase()} data-test-selector="visits-summary-section">
            {renderBody()}
        </DashboardSection>
    );
};

export default VisitsSummary;
