import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import styles from './select.module.scss';

export default ({ cm, name, selected, options, onChange, smallWidth, largeWidth, selector, isMulti }) => {
    return (
        <div className={styles.root}>
            <Select
                isMulti={isMulti}
                data-test-selector={selector}
                onChange={onChange}
                name={name}
                value={selected}
                variant="outlined"
                margin="dense"
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                classes={{
                    select: `${styles.selectInput} ${smallWidth && styles.small} ${largeWidth && styles.large}`,
                    nativeInput: styles.item,
                }}
            >
                {options.map(({ label, value }, i) => (
                    <MenuItem key={value + i} value={value} className={styles.item}>
                        {name !== 'channels' ? cm.get(label) : label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};
