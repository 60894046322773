import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import mapData from './meta-data/world';
import { useTheme } from '@material-ui/core/styles';

export default function ({ dataLabel = '', data = [], tooltip = {}, ...props }) {
    const theme = useTheme();

    const d = mapData.features.map(({ properties }) => data.find(({ key }) => properties['hc-key'] === key) || { key: properties['hc-key'], value: 0 });
    const maxColorValue = data.reduce((a, { value }) => (a > value ? a : value), 0);
    const options = {
        credits: false,
        plotOptions: { map: { color: theme.palette.chart.b } },
        title: { text: '' },
        chart: {
            type: 'map',
            backgroundColor: theme.palette.background.component,
        },
        series: [
            {
                name: dataLabel,
                mapData,
                data: d,
                joinBy: ['hc-key', 'key'],
                states: {
                    hover: {
                        color: theme.palette.heatMap.maxColor,
                    },
                },
            },
        ],
        colorAxis: {
            min: 0,
            minColor: theme.palette.heatMap.minColor,
            maxColor: theme.palette.heatMap.maxColor,
            max: maxColorValue - maxColorValue * 0.75,
        },
        tooltip,
    };

    return (
        <HighchartsReact
            options={options}
            highcharts={Highcharts}
            constructorType={'mapChart'}
            allowChartUpdate={true}
            containerProps={{ className: 'chartContainer' }}
            {...props}
        />
    );
}
