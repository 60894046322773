import { useEffect } from 'react';
import { iam, accounts } from '../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import LogRocket from 'logrocket';

export const useFirstLogin = (isFirstLogin: boolean, auth0UserId: string, networkID: number, email: string, username: string) => {
    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        (async () => {
            if (isFirstLogin) {
                try {
                    let token = await getAccessTokenSilently();
                    console.log('The token is: ', token);
    
                    window.localStorage.setItem('auth0Token', token);
    
                    const res = await accounts.saveUserInDB(auth0UserId, networkID);
                    console.info('New user Id: ', JSON.stringify(res.id));
                    await iam.activateUser(auth0UserId, email, username, res.id, networkID, res.hasPrivacy);
                    await iam.editProfile({ name: email.substring(0, email.indexOf('@')), combotagUserId: res.id });
    
                    token = await getAccessTokenSilently();
                    window.localStorage.setItem('auth0Token', token);
                    window.location.reload();
                } catch (error) {
                    LogRocket.error('An error occurred during the first login process.', {
                        error,
                        auth0UserId,
                        networkID,
                        email,
                        username,
                    });
                }
            }
        })();
    }, [isFirstLogin]);
};
