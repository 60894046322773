import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useEffect } from 'react';
import Header from '../../containers/header';
import SnackbarContainer from '../main/SnackbarContainer';
import config from '@config';
import { isViewer } from '../../../utils/permissions';
import { useState, useCallback } from 'react';
import Tab from '@material-ui/core/Tab';
import { useTheme } from '@material-ui/core/styles';
import { useTokenOutbrain } from '../../../hooks/use-token-outbrain';
import Tabs from '@material-ui/core/Tabs';
import { useRef } from 'react';
import { TabPanel } from '../../tabs';
import styles from './outbrain.module.scss';

export default function Outbrain() {
    const DashboardTypes = {
        BusinessDashboard: 1,
        FraudDashboard: 2,
    };
    const viewer = isViewer();
    const [value, setValue] = useState(DashboardTypes.BusinessDashboard);
    const dashboardRef = useRef();
    const theme = useTheme();
    const [dashboardId, setDashboardId] = useState(config.outbrainBusinessDashboardId);
    const { data: token } = useTokenOutbrain(dashboardId);
    const openDashboard = useCallback(() => {
        if (token) {
            embedDashboard({
                id: dashboardId,
                supersetDomain: config.outbrainSuperSetUrl,
                mountPoint: dashboardRef.current,
                fetchGuestToken: () => token,
                dashboardUiConfig: { hideTitle: true },
            });
        }
    }, [dashboardId, token]);

    useEffect(() => {
        openDashboard();
    }, [openDashboard]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        const dashboardId = newValue === DashboardTypes.BusinessDashboard ? config.outbrainBusinessDashboardId : config.outbrainFraudDashboardId;
        setDashboardId(dashboardId);
    };

    return (
        <>
            <Header title={'Outbrain'} />
            <Tabs value={value} onChange={handleTabChange} aria-label="outbrain dashboard">
                <Tab key={DashboardTypes.BusinessDashboard} value={DashboardTypes.BusinessDashboard} label={'Business Dashboard'} />

                {!viewer && <Tab key={DashboardTypes.FraudDashboard} label={'Fraud Dashboard'} value={DashboardTypes.FraudDashboard} />}
            </Tabs>
            <SnackbarContainer />

            <TabPanel height={'100%'} style={{ flex: 1 }} key={value} dir={theme.direction}>
                <div className={styles.dashboard} ref={dashboardRef}></div>
            </TabPanel>
        </>
    );
}
