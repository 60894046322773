import { profile as actions } from '../actions';
import _ from 'underscore';

export default (state = profileInitialState, action) => {
    switch (action.type) {
        case actions.SET_PROFILE_DATA: {
            return { ...state, ...action.payload, ready: true, isLoading: false };
        }
        case actions.CONTENT_LOADING: {
            return { ...state, contentLoading: action.payload };
        }
        case actions.SET_META : {
            return {...state, meta: action.payload}
        }

        case actions.SET_FIRST_LOGIN : {
            return {...state, meta: {...state.meta, firstLogin: !!action.payload}}
        }
        case actions.SET_NAME : {
            return {...state, meta:{...state.meta, name: action.payload}}
        }
        case actions.SET_USERNAME : {
            return {...state, meta:{...state.meta, username: action.payload}}
        }
        case actions.SET_LANGUAGE : {
            return {...state, meta:{...state.meta, settings: {...state.meta.settings, language: action.payload}}}
        }
        case actions.SET_TIMEZONE : {
            return {...state, meta:{...state.meta, settings: {...state.meta.settings, tz: action.payload}}}
        }
        case actions.SET_USER_PROFILE_IS_LOADING : {
            return {...state, uerProfileLoading: action.payload}
        }
        case actions.UPDATE_USER_PROFILE : {
            return {...state, ...Object.entries(action.payload).reduce((total, [key, value]) => state[key] !== value ? Object.assign(total, {[key]: value}) : total, {})}
        }
        case actions.SET_PERMISSIONS : {
            return {...state, permissions: parsePermissions(action.payload)}
        }
        case actions.SET_CONTENT : {
            return {...state, content: action.payload, hasContent: !!(action.payload && !_.isEmpty(action.payload))}
        }
        case actions.CHANGE_PASSWORD_IS_LOADING : {
            return {...state, changePasswordLoading: action.payload}
        }
        case actions.SET_DEFAULT_PRODUCT: {
            return { ...state, meta: { ...state.meta, defaultProduct: action.payload} };
        }
        default:
            return state;
    }
};

const profileInitialState = {
    ready: false,
    isLoading: false,
    contentLoading: false,
    authenticated: false,
    changePasswordLoading: false,
    hasContent: false,
    permissions: {},
    meta: {
        name: '',
        username: '',
        email: '',
        id: 0,
        dateCreated: '',
        supportToken: '',
        childrenUserTypes: [],
        firstLogin: true,
        sections: [],
        userRole: '',
        analyticsQueries: {
            reportTypes: {},
        },
        settings: {
            tz: '',
            language: '',
        },
        csrfToken: '',
        permissions: [
            {
                model: '',
                action: '',
            },
        ],
        passwordExpireIn: 0,
        regionId: 0,
        status: false,
        defaultProduct: null
    },
};


const parsePermissions = (permissions = []) => permissions
    .reduce((total, perm) => {
        const [action, model] = perm.split(':')
        return Object.assign(total, {[model]: {...total[model], [action]: true}})
}, {})
