/* @ts-ignore */
import Tooltip from '@components/widgets/tooltip';
import styles from './styles.module.scss'
import { FC } from 'react';
import CopyIcon from "../../../svgs/copy";

interface IProps {
    copied: boolean;
    handleCopy: () => void;
    cm: any;
    className?: string
}

const CopyToClipboard: FC<IProps> = ({ copied, handleCopy, cm, className = '' }) => {
    return (
        <Tooltip content={copied ? cm.get('Copied') : cm.get('CopyToClipboard')}>
            <div className={className}>
                <CopyIcon onClick={handleCopy} className={styles.icon} />
            </div>
        </Tooltip>
    )
}

export default CopyToClipboard