import { FC } from 'react';
import CancelIcon from '../../assets/close.svg';
import styles from './index.module.scss';

type CustomModalProps = {
    title: string;
    description: string;
    onSubmitButtonText: string;
    onCancelButtonText: string;
    onClose: () => void;
    onSubmit: () => void;
};

export const CustomModal: FC<CustomModalProps> = (props) => {
    const { title, description, onSubmitButtonText, onCancelButtonText, onClose, onSubmit } = props;

    return (<div className={styles.modal} onClick={onClose}>
        <div className={styles.container}>
            <div className={styles.title}>
                {title}
                <img src={CancelIcon} alt="close" onClick={onClose} />
            </div>
            <div className={styles.content}>
                <div className={styles.label}>
                    {description}<br />
                </div>
                <div className={styles.actions}>
                    <div className={styles.cancel} onClick={onClose}>{onCancelButtonText}</div>
                    <div onClick={onSubmit} className={styles.confirmButton}>{onSubmitButtonText}</div>
                </div>
            </div>
        </div>
    </div>);
};
