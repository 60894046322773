import { FC, useEffect, useMemo, useState } from 'react';
/* @ts-ignore */
import { useContentManager } from '@hooks';
import { createColumnHelper } from '@tanstack/react-table';
import { Checkbox, Tooltip } from '@material-ui/core';
import { CustomTable } from 'components/widgets/custom-table';
import { MarketoForm } from 'types/marketo';

type MarketoFormsTableProps = {
    marketoForms: MarketoForm[];
    handleUpdateForm: (formId: number, checked: boolean) => void;
    checkedAllForms: (checked: boolean) => void;
};

export const MarketoFormsTable: FC<MarketoFormsTableProps> = props => {
    const { marketoForms, handleUpdateForm, checkedAllForms } = props;
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const cm = useContentManager();
    const columnHelper = createColumnHelper<MarketoForm>();

    useEffect(() => setIsAllChecked(marketoForms.every(form => form.isProtected)), [marketoForms]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('isProtected', {
                cell: info => (
                    <Checkbox
                        checked={info?.row?.original?.isProtected}
                        onClick={() => {
                            return handleUpdateForm(info?.cell?.row?.original?.formId, !info?.cell?.row?.original?.isProtected);
                        }}
                    />
                ),
                header: (): any => (
                    <Checkbox
                        checked={isAllChecked}
                        onClick={() => {
                            checkedAllForms(!isAllChecked);
                        }}
                    />
                ),
                enableSorting: false,
                meta: {
                    isSortable: false,
                },
            }),
            columnHelper.accessor('formName', {
                cell: info => (
                    <div style={{ width: '2000px' }}>
                        <Tooltip title={info.getValue()}>
                            <span>{info.getValue()}</span>
                        </Tooltip>
                    </div>
                ),
                header: cm.get('Name'),
                sortDescFirst: true,
                meta: {
                    isSortable: true,
                },
            }),
        ],
        [columnHelper, cm, handleUpdateForm, isAllChecked, checkedAllForms]
    );

    return <CustomTable columns={columns} data={marketoForms} />;
};
