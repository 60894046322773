import { SplitSdk } from '@splitsoftware/splitio-react';

export const createSDK = key =>
    SplitSdk({
        core: {
            authorizationKey: process.env.REACT_APP_SPLIT_ENV,
            key: String(key),
        },
        scheduler: {
            eventsPushRate: 5,
            impressionsRefreshRate: 5,
            metricsRefreshRate: 5,
        },
        streamingEnabled: true,
        debug: false,
    });

export const isFlagOn = treatment => treatment === 'on';
