import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import icons from '@components/svgs/third-parties';
import styles from './dashboard-monitoring.css';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';

import { useSplit } from '@hooks';
import { constants } from '@utils/split.io';
import {useContentManager} from "../../hooks";

const cx = classNames.bind(styles);

export const PlatformSelector = ({ onClick, current, title, paid, orgaic, dashboardCM, isDirect, isLoading }) => {
    const cm = useContentManager();
    if (isLoading)
        return (
            <div className={cx('item')}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </div>
        );

    // const { services } = useSelector(state => state.settings);

    const { selectedDisplayOption } = useSelector(state => state.dashboard);
    // const { isOn: isBlockedSinceOn } = useSplit(constants.SPLIT_BLOCKING_SINCE);

    // let currentService = services.filter(service => title.toLowerCase().includes(service.service));

    // if (currentService && currentService.length > 0) {
    //     currentService = currentService[0];
    // } else {
    //     if (title.toLowerCase().includes('youtube')) {
    //         currentService = services.filter(service => service.service.toLowerCase().includes('google'))[0]
    //     }
    //     if (title.toLowerCase().includes('instagram')) {
    //         currentService = services.filter(service => service.service.toLowerCase().includes('facebook'))[0]
    //     }
    // }

    const Icon = useMemo(() => {
        const iconName = title.split(' ')[0];
        const findIcon = Object.keys(icons).find(f => f.toLowerCase() === iconName.toLowerCase());
        return findIcon ? icons[findIcon] : () => <></>;
    }, [title]);

    return (
        <div onClick={onClick} className={cx('item', current ? 'current' : '')} data-test-selector="platform selector">
            <h3>
                {title}
                <div className={cx('icon')}>
                    <Icon />
                </div>
            </h3>
            <h3 className={cx('not-visible')}>&nbsp;</h3>
            <div className={cx('paid-organic')}>
                {!isDirect && (
                    <div>
                        {dashboardCM.get('Paid')}: <strong>{paid}%</strong>
                    </div>
                )}
                {!isDirect && <div className={cx('seperator')} />}
                <div>
                    {dashboardCM.get('Organic')}: <strong>{orgaic}%</strong>
                </div>
            </div>
            {/*{selectedDisplayOption === 'PROTECTED_PLATFORMS'  && (*/}
            {/*    <div className={cx('blocking-since')}>{isBlockedSinceOn && currentService.protectedSince && `${cm.get('BlockingSince')} ${moment(currentService.protectedSince).format('DD/MM/YYYY')}`}</div>*/}
            {/*)}*/}
        </div>
    );
};
