import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Progress from '../progress/index';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {IconWrapper}  from "../icon-wrapper";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        flexGrow: 1,
        backgroundColor: theme.palette.background.component,
        display: 'flex',
        textAlign: 'center',
        padding: 10,
        color: theme.typography.color,
        '& div': { display: 'block' }
    },
    label: { fontSize: 14 },
    value: { fontSize: 30,  },
    infoIcon: {
        right: 10,
        top: 10,
        position: 'absolute',
        '& :hover': {
            color: '#fe0072'
        }
    }
}));

let _id = 0;
export default ({tooltipText, id = `counter-${_id++}`, value = 0, label, width = 305, height = 65.5, style, ease, formatter = v => v, isLoading, ...props }) => {
    const [_value, _setValue] = useState(value);
    const classes = useStyles();

    useEffect(() => {
        if (ease) {
            const destroy = setTimeout(() => {
                if (_value < value) _value
                    ? _setValue(prev => {
                        localStorage.setItem(id, prev);
                        return ++prev;
                    })
                    : _setValue(parseInt(value))
            }, calcTimeout(value, _value));
            return () => clearTimeout(destroy);
        } else _setValue(value);
    }, [_value, value, ease])

    useEffect(() => {
        const prevVal = localStorage.getItem(id);
        if (prevVal) _setValue(prevVal);
    }, [])
    return <div className={classes.root} style={{ width, height, ...style }} {...props}>
        {isLoading ? <Progress style={{ marginTop: 13, opacity: 0.5 }}/> :
            <>
            {tooltipText
                ? <IconWrapper className={classes.infoIcon}  icon={<InfoOutlinedIcon style={{height: 14, width:14}}/>} tooltip={tooltipText}/>
                : null}
                <div className={classes.value}>{formatter(_value)}</div>
                <div className={classes.label}>{label}</div>
            </>
        }
    </div>

}

const calcTimeout = (t, i) => i ? (i / t) * (Math.random() * 1000) : 20
