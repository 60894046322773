import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Route } from 'react-router-dom';
import Tabs from '../tabs';
import { push } from 'connected-react-router';

const parseValue = ({ label = '', value = '' }) => value || label.split(' ').join('_').toLowerCase();

const getDefaultTab = ({ root, children, location }) => {
    return children.map(c => `${root}${parseValue(c.props)}`).includes(location.pathname) ? location.pathname.replace('/', '') : parseValue(children[0].props);
};

export default props => {
    const { defaultTab, root = '/', children, ...rest } = props;
    const dispatch = useDispatch();
    const location = useSelector(state => state.router.location);

    useEffect(() => {
        const availableLocations = children.filter(({ props }) => !props.disabled).map(c => `${root}${parseValue(c.props)}`);
        if (!availableLocations.includes(location.pathname)) dispatch(push(`/${defaultTab}`));
    }, [children, defaultTab, dispatch, location.pathname, root]);

    return (
        <Tabs
            {...rest}
            defaultTab={getDefaultTab({ root, location, children })}
            onTabChange={(e, value) => window.scrollTo(0, 0) || dispatch(push(`/${value}`))}
            panel={Component => !Component.props.disabled && <Route path={`${root}${parseValue(Component.props)}`} render={() => Component} />}
        >
            {children}
        </Tabs>
    );
};
