import moment from 'moment';
import { getUnixTime } from 'date-fns';
import momentTZ from 'moment-timezone';

const currentTimeOfToday = momentTZ.utc().toDate();
const timeZone = JSON.parse(localStorage.getItem('meta') || '{}')?.settings?.tz;
const startOfDay = timeZone ? momentTZ().tz(timeZone).startOf('day').utc() : moment();
const fromStartOfLast6Months = startOfDay.add(-6, 'month').toDate();

export const queryOptions = { reqDates: [getUnixTime(fromStartOfLast6Months), getUnixTime(currentTimeOfToday)] };

const mapValues = vals => {
    return vals?.length > 0 ? vals.map(val => val.value) : [];
};
export const parseStateToBody = state => {
    const filters = [];
    if (state.ivt) filters.push('ivt');
    if (state.paid.length > 0) filters.push('paid');

    const { id, name, type, format, emails, frequency, reportType, dateRange, channels, urls, campaigns, medium, campaignType, referrer, allPlatforms, tags, source } = state;
    let sd = state.startDate,
        ed = state.endDate;
    if (type !== 'limited' || (type === 'limited' && frequency !== 'onetime')) {
        const [startDate, endDate] = dateRange.format;
        sd = startDate;
        ed = endDate;
    }

    let finalType = type;
    if (type === 'advanced') finalType = reportType;

    const parsedState = {
        startDate: moment(new Date(sd)).format('YYYY-MM-DD'),
        endDate: moment(new Date(ed)).format('YYYY-MM-DD'),
        type: finalType,
        name,
        format,
        filters,
        emails: emails.join(';'),
        channels: mapValues(channels?.length === 0 && tags ? tags : channels)?.join(','),
        additionalFilters: {
            urls: mapValues(urls),
            campaigns: mapValues(campaigns),
            platform: {
                default: allPlatforms.map(platform => platform.value),
                custom: {
                    medium: mapValues(medium),
                    campaignType: mapValues(campaignType),
                    referer: mapValues(referrer),
                    source: mapValues(source),
                },
            },
        },
    };

    if (frequency !== 'onetime') {
        const { destination, cloudStorageProvider, bucketName, bucketRegion, accessKey, accessSecret } = state;
        parsedState.destination = destination;
        parsedState.enabled = true;
        parsedState.bucket = generateBucketFullPath(bucketRegion, cloudStorageProvider, bucketName);
        parsedState.accessKey = accessKey;
        parsedState.accessSecret = accessSecret;
        parsedState.frequency = frequency;
        parsedState.endDate = '';
        if (id) {
            parsedState.id = id;
        }
        switch (parsedState.frequency) {
            case 'daily':
                parsedState.schedule = '0 0 * * *';
                break;
            case 'weekly':
                parsedState.schedule = '0 0 * * MON';
                break;
            case 'monthly':
                parsedState.schedule = '0 0 1 * *';
                break;
        }
    } else {
        parsedState.visible = true;
    }

    return parsedState;
};

export const generateBucketFullPath = (bucketRegion, cloudProvider, bucketName) => {
    return `https://s3.${bucketRegion}.${cloudProvider === 'aws' ? 'amazonaws' : 'backblazeb2'}.com/${bucketName}`;
};
