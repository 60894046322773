import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';

import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import CheqUI from '@combotag/cheq-ui';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import ViewModels from './view-models';
import { store } from './redux/store';
import InitSplit from './context/InitSplit';
import config from './config';

const { Meiosis } = CheqUI;
const { states, actions } = Meiosis(ViewModels.map(VM => new VM()));
const query = new URLSearchParams(window.location.search);
const connection = query.get('connection');
ReactDOM.render(
    <Auth0Provider
        connection={connection ? connection : undefined}
        domain={config.auth0Domain}
        clientId={config.auth0ClientId}
        redirectUri={window.location.origin}
        audience={config.auth0Audience}
    >
        <Provider store={store}>
            <BrowserRouter>
                <InitSplit>
                    <App states={states} actions={actions} />
                </InitSplit>
            </BrowserRouter>
        </Provider>
    </Auth0Provider>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
