import React from 'react';
import CheqUI from '@combotag/cheq-ui';
import { CloseHeatmap, ViewHeatmapHeader, HeatmapName, HeatmapDevicePicker, HeatmapEventPicker, HeatmapDatePicker, HeatmapImage, HeatmapUserPicker } from './components';

const { useActions } = CheqUI.React.Meiosis;

const ViewHeatmap = ({ viewMode }) => {
    const { name, deviceType, eventType, date, isLoading, dateCreated, isError, location, isValid, lastDate, enabled } = viewMode;
    const { heatmaps: heatmapsActions } = useActions();
    return (
        <>
            <ViewHeatmapHeader>
                <HeatmapName name={name} />
                <HeatmapDevicePicker
                    enabled={enabled.deviceType}
                    selected={deviceType}
                    onChangeDevice={deviceType => heatmapsActions.queryHeatmap({ ...viewMode, eventType: 'click', deviceType })}
                />
                <HeatmapUserPicker enabled={enabled.isValid} selected={isValid} onChangeValid={isValid => heatmapsActions.queryHeatmap({ ...viewMode, isValid })} />
                <HeatmapEventPicker
                    enabled={enabled.eventType}
                    selected={eventType}
                    onChangeEvent={eventType => heatmapsActions.queryHeatmap({ ...viewMode, eventType })}
                    deviceType={deviceType}
                />
                <HeatmapDatePicker
                    date={date}
                    onChangeDate={date => {
                        heatmapsActions.queryHeatmapDate({ ...viewMode, date }).then(possibilities => {
                            if (possibilities && possibilities.length) {
                                heatmapsActions.queryHeatmap({
                                    ...viewMode,
                                    possibilities,
                                    date,
                                    deviceType: possibilities[0].deviceType,
                                    eventType: possibilities[0].eventType,
                                    isValid: possibilities[0].isValid,
                                });
                            }
                        });
                    }}
                    minDate={dateCreated}
                    maxDate={lastDate}
                />
                <CloseHeatmap onClose={() => heatmapsActions.closeHeatmap()} />
            </ViewHeatmapHeader>
            <HeatmapImage isLoading={isLoading} isError={isError} location={location} deviceType={deviceType} />
        </>
    );
};

export default ViewHeatmap;
