import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddApiButton from 'components/widgets/add-api-button';
import useFetch from 'components/hooks/use-fetch-map';
import { googleAnalyticsClient } from 'services/api/ga-client.service';
import { ValueLabel, Channel } from 'types/types';
import CreateIntegration from './create-integration';
import Integration from './Integration';
import { Integration as IntegrationObject, IntegrationRaw } from '../types';
import { integrationsSorterFunction } from '../utils';
import styles from '../styles.module.scss';
import Progress from '../../../../widgets/progress';
import classNames from 'classnames';

interface IProps {
    cm: any;
    readonly: boolean;
}

const GoogleAnalyticsV4Integration: FC<IProps> = ({ cm, readonly }) => {
    const mapping = (data: IntegrationRaw[]) => {
        const parsedIntegrations: IntegrationObject[] = data.map(s => ({
            channel: {
                value: s.tag_id,
                label: s.tag_name,
            },
            measurementId: s.tracking_id,
        }));
        return parsedIntegrations;
    };
    const [showCreateIntegration, setShowCreateIntegration] = useState(false);
    const clickTrueTagsState = useSelector((state: any) => state.clickTrueTags);
    const networkId = clickTrueTagsState.tags && clickTrueTagsState?.tags[0]?.networkId;
    const [integrations, setIntegrations, isLoading, fetchIntegrationsError] = useFetch<IntegrationRaw[], IntegrationObject[]>(
        googleAnalyticsClient.getIntegrations,
        [networkId],
        mapping,
        integrationsSorterFunction
    );
    const channelOptions: ValueLabel[] =
        clickTrueTagsState.tags &&
        clickTrueTagsState?.tags.map((channel: Channel) => {
            return {
                value: channel.id,
                label: channel.name,
            };
        });

    useEffect(() => {
        if (networkId) {
            googleAnalyticsClient.getIntegrations(networkId).then(data => {
                const parsedIntegrations: IntegrationObject[] = data.map(s => ({
                    channel: {
                        value: s.tag_id,
                        label: s.tag_name,
                    },
                    measurementId: s.tracking_id,
                }));
                setIntegrations(parsedIntegrations);
            });
        }
    }, [networkId]);

    const toggleCreateIntegration = () => setShowCreateIntegration(prevState => !prevState);

    const handleAddIntegration = async (channel: ValueLabel, measurementId: string) => {
        try {
            await googleAnalyticsClient.addIntegration(channel, measurementId);
            setIntegrations(prevIntegrations => [
                ...prevIntegrations,
                {
                    channel,
                    measurementId,
                },
            ]);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteIntegration = async (integration: IntegrationObject) => {
        try {
            await googleAnalyticsClient.deleteIntegration(integration.channel, integration.measurementId);
            setIntegrations(prevIntegrations =>
                prevIntegrations.filter(element => element.channel.value !== integration.channel.value || element.measurementId !== integration.measurementId)
            );
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={styles.secretKey}>
            <div className={styles.createContainer}>
                <div className={classNames(styles.row, { [styles.disabled]: readonly })}>
                    <AddApiButton onClickHandler={toggleCreateIntegration} text={cm.get('AddIntegration')} />
                </div>
                {showCreateIntegration && !isLoading && (
                    <>
                        <CreateIntegration handleAddIntegration={handleAddIntegration} cm={cm} channelOptions={channelOptions} integrations={integrations} />
                        {fetchIntegrationsError && <span className={styles.error}>{fetchIntegrationsError}</span>}
                    </>
                )}
            </div>
            {isLoading ? (
                <Progress />
            ) : (
                integrations &&
                integrations.map((integration: IntegrationObject, i: number) => (
                    <Integration key={i} integration={integration} handleDeleteIntegration={handleDeleteIntegration} cm={cm} readonly={readonly} />
                ))
            )}
        </div>
    );
};

export default GoogleAnalyticsV4Integration;
