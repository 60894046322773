import React, { createContext, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Progress from '@components/widgets/progress';
import ContentManager from '@services/content-manager';
import axios from 'axios';
import config from '../config';
import { setUpdatedLanguage } from '../redux/actions/settings';
import { selectLanguage } from '../redux/thunks/profile';
const ContentManagerContext = createContext(null);

const ContentManagerProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [isContentReady, setIsContentReady] = useState(false);
    const language = useSelector(selectLanguage) || 'en';
    useEffect(() => {
        if (language) {
            axios
                .get(`${config.translationURL}/${language}.json`)
                .then(res => {
                    const { data: content } = res;
                    ContentManager.load(content);
                    dispatch(setUpdatedLanguage(language));
                    setIsContentReady(true);
                })
                .catch(err => {
                    setIsContentReady(true);
                });
        }
    }, [language]);

    return <ContentManagerContext.Provider value={{}}>{isContentReady ? children : <Progress />}</ContentManagerContext.Provider>;
};

export default ContentManagerProvider;
