import { MenuItem, Select } from '@material-ui/core';

import React from 'react';
import cx from 'classnames';
import styles from './select.module.scss';

export const CqSelect = ({ value, onChange, label, options = [], isOutlined = false, style = {}, ...rest }) => {
    return (
        <div>
            <Select
                fullWidth
                classes={{ root: cx(styles.selectRoot, { [styles.outlined]: isOutlined }) }}
                margin="dense"
                variant="outlined"
                value={value}
                onChange={onChange}
                label={label}
                color="secondary"
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    classes: {
                        paper: styles.selectPaper,
                    },
                }}
                {...rest}
            >
                {options.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};
