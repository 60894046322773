import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { noop } from 'underscore';
import InlineCss from 'react-inline-css';

//TODO:Make this a css style
const Input = styled.input`
    ${({ width, height, fontSize }) => `
	display: inline-flex;
    align-items: center;
    background: #282b3f;
    width: ${width}px;
    height: ${height}px;
    font-size: ${fontSize}px;
    font-family: 'Open Sans';
    color: white;
    border: none;
    background: #383851;
    padding : 0px 6px;
    &:focus {
        outline: none;
    }

`}
`;

const ErrorLabel = styled.div`
    color: red;
    font-size: 12px;
    font-style: italic;
    margin-top: 6px;
    ${({ width }) => `width:${width}px;`}
`;

const SimpleTextField = ({
    value = '',
    onChange = noop,
    placeholder = '',
    containerStyle,
    inputStyle,
    width = 92,
    height = 24,
    fontSize = 12,
    error,
    focusOnMount = false,
    disabled,
    errorTop = false,
}) => {
    const refInput = useRef(null);

    useEffect(() => {
        if (focusOnMount) {
            refInput.current.focus();
        }
    }, [focusOnMount]);

    return (
        <div style={{ ...containerStyle }}>
            {error && errorTop && <ErrorLabel width={width}>{error}</ErrorLabel>}
            <InlineCss
                stylesheet={`
	            & ::placeholder {
	                color : #fe0087;
	                font-size:${fontSize}px;
	                opacity:0.5;
	                font-family:'Open Sans'
	            }
	        `}
            >
                <Input
                    disabled={disabled}
                    ref={refInput}
                    fontSize={fontSize}
                    width={width}
                    height={height}
                    style={inputStyle}
                    onChange={event => onChange(event.target.value)}
                    value={value}
                    placeholder={placeholder}
                    type="text"
                />
            </InlineCss>
            {error && !errorTop && <ErrorLabel width={width}>{error}</ErrorLabel>}
        </div>
    );
};

export default SimpleTextField;
