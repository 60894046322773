import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Fab from '@material-ui/core/Fab';
import styles from './audience-exclusion.module.scss';
import { useContentManager } from '@hooks';
import widgets from '@components/widgets';

const { CheckBox, SelectBox, Table, NoData } = widgets;

const AudienceExclusion = ({ clickTrueTags, audienceExclusions = [], setAudienceExclusions, isLoading }) => {
    const cm = useContentManager();

    const [visibleAudienceExclusions, setVisibleAudienceExclusions] = useState(null);
    const clickTrueTagSelectOptions = useMemo(() => clickTrueTags?.map(cct => ({ value: cct.id, label: cct.name })) || [], [clickTrueTags]);

    const handleAddAudienceExclusion = () => {
        const minId = audienceExclusions
            ?.map(ae => ae.id)
            ?.sort((a, b) => b - a)
            ?.pop();
        const newId = minId && minId < 0 ? minId - 1 : -1;
        const newExclusion = { id: newId, isEnabled: true, conversionId: '', conversionLabel: '', clickTrueTagId: null };
        setAudienceExclusions([...audienceExclusions, newExclusion]);
    };

    const onInputChange = useCallback(
        (field, value, item, originalValue) => {
            return setAudienceExclusions(currentAudienceExclusions => {
                if (value === originalValue) return currentAudienceExclusions;
                const updatedItemIndex = currentAudienceExclusions.findIndex(ae => ae.id === item.id);
                if (updatedItemIndex < 0) return currentAudienceExclusions;
                const replaceItem = { ...currentAudienceExclusions[updatedItemIndex], [field]: value, isDirty: true };
                currentAudienceExclusions[updatedItemIndex] = replaceItem;
                return [...currentAudienceExclusions];
            });
        },
        [setAudienceExclusions]
    );

    const onDeleteItem = useCallback(
        item => {
            return setAudienceExclusions(currentAudienceExclusions => {
                const updatedItemIndex = currentAudienceExclusions.findIndex(ae => ae.id === item.id);
                if (updatedItemIndex < 0) return currentAudienceExclusions;
                const newAudienceExclusions = [...currentAudienceExclusions];
                if (newAudienceExclusions[updatedItemIndex].id > 0) {
                    const replaceItem = { ...newAudienceExclusions[updatedItemIndex], isDeleted: true };
                    newAudienceExclusions[updatedItemIndex] = replaceItem;
                } else {
                    newAudienceExclusions.splice(updatedItemIndex, 1);
                }
                return newAudienceExclusions;
            });
        },
        [setAudienceExclusions]
    );

    useEffect(() => {
        return setVisibleAudienceExclusions(currentVisibleExclusions => {
            if (!audienceExclusions) {
                //no exclusions loaded
                return null;
            }

            const newVisibleExclusions = audienceExclusions?.filter(ae => !ae.isDeleted);

            if (
                newVisibleExclusions &&
                newVisibleExclusions.length === currentVisibleExclusions?.length &&
                currentVisibleExclusions.every(ex => newVisibleExclusions.includes(ex))
            ) {
                //visible exclusions not changed
                return currentVisibleExclusions;
            }
            return newVisibleExclusions;
        });
    }, [clickTrueTagSelectOptions, audienceExclusions]);

    const renderCell = useCallback(
        ({ column, row }) => {
            const cellValue = row[column.id];
            switch (column.id) {
                case 'isEnabled': {
                    return (
                        <CellWrapper>
                            <CheckBox checked={!!cellValue} onChange={(_, value) => onInputChange('isEnabled', value, row, cellValue)} />
                        </CellWrapper>
                    );
                }
                case 'conversionId': {
                    return (
                        <CellWrapper>
                            <input
                                type={'text'}
                                maxLength={'50'}
                                className={styles.fieldInput}
                                value={cellValue}
                                onChange={({ target: { value } }) => onInputChange('conversionId', value, row, cellValue)}
                            />
                        </CellWrapper>
                    );
                }
                case 'conversionLabel': {
                    return (
                        <CellWrapper>
                            <input
                                type={'text'}
                                maxLength={'50'}
                                className={styles.fieldInput}
                                value={cellValue}
                                onChange={({ target: { value } }) => onInputChange('conversionLabel', value, row, cellValue)}
                            />
                        </CellWrapper>
                    );
                }
                case 'clickTrueTagId': {
                    return (
                        <CellWrapper>
                            <SelectBox options={clickTrueTagSelectOptions} value={cellValue} onChange={value => onInputChange('clickTrueTagId', value, row, cellValue)} />
                        </CellWrapper>
                    );
                }
                case 'actions': {
                    return (
                        <CellWrapper>
                            <div className={styles.deleteButton} onClick={() => onDeleteItem(row)}>
                                <DeleteForeverIcon />
                            </div>
                        </CellWrapper>
                    );
                }
                default: {
                    return <CellWrapper></CellWrapper>;
                }
            }
        },
        [clickTrueTagSelectOptions, onDeleteItem, onInputChange]
    );

    return (
        <>
            <div style={{ display: 'block', height: '410px', overflow: 'auto' }}>
                <Table
                    columns={[
                        { id: 'isEnabled', label: 'Status' },
                        { id: 'conversionId', label: 'Conversion ID' },
                        { id: 'conversionLabel', label: 'Conversion Label' },
                        { id: 'clickTrueTagId', label: 'Tag' },
                        { id: 'actions', label: '' },
                    ]}
                    lineaCount={false}
                    search={false}
                    pagination={false}
                    sortable={false}
                    RenderEmpty={() => (
                        <NoData lineHeight={40} style={{ padding: 20 }}>
                            <div>NO AUDIENCE EXCLUSIONS WERE DEFINED</div>
                        </NoData>
                    )}
                    RenderHead={({ column }) => <CellWrapper>{column.label}</CellWrapper>}
                    RenderBody={renderCell}
                    rows={visibleAudienceExclusions || []}
                    isLoading={isLoading}
                    style={tableStyle}
                    cm={cm}
                    headCellPadding={'none'}
                    rowsPerPage={100}
                />
            </div>
            <Fab onClick={handleAddAudienceExclusion} className={styles.addIcon} aria-label="add">
                <AddIcon />
            </Fab>
        </>
    );
};

export default AudienceExclusion;

const CellWrapper = ({ children }) => <div className={styles.tableCellWrapper}>{children && children}</div>;

const tableStyle = {
    root: { background: 'rgba(20, 23, 44, 0.5)' },
    head: { padding: 0 },
    headerRow: { padding: 0, backgroundColor: '#14172c', height: 36 },
    headerCell: { borderBottom: 'none', color: '#fe0072', fontFamily: 'Open Sans', fontSize: 12 },
    oddRow: { backgroundColor: '#21223c', height: 36 },
    row: { backgroundColor: '#383851', height: 36 },
    bodyCell: { border: 'none', fontSize: 12, padding: 0 },
};
