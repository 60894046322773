import api from '@services/api';
import Toast, { toastTypes } from '@services/notifications';
import * as actions from '../actions/profile';

const getMeta = user => async dispatch => {
    try {
        const { data } = (await api.profile.getMeta()) || {};
        dispatch(actions.setMeta({ ...data, ...user }));
    } catch (e) {
        console.error(e);
        return {};
    }
};

const updateUserProfile = userProfile => async dispatch => {
    try {
        dispatch(actions.setUerProfileLoading(true));
        await api.iam.editProfile(userProfile);
        dispatch(actions.setUserProfile(userProfile));
        dispatch(actions.setUerProfileLoading(false));
    } catch (e) {
        console.error(e);
        return {};
    }
};

const changePassword = () => async dispatch => {
    try {
        dispatch(actions.changePasswordLoading(true));
        await api.iam.changePassword();
        dispatch(actions.changePasswordLoading(true));
        Toast({ message: 'We have sent you an e-mail to reset your password.', type: toastTypes.info });
    } catch (e) {
        Toast({ message: "we're having some trouble completing your request. please try again shortly", type: toastTypes.error });
    }
};

const getContent = () => async dispatch => {
    try {
        dispatch(actions.contentIsLoading(true));
        const { data } = await api.profile.getContent();
        let content = Object.assign({}, data);
        delete content.status;
        window.localStorage.setItem('content', JSON.stringify(content));
        dispatch(actions.setContent(content));
        dispatch(actions.contentIsLoading(false));
    } catch (e) {
        console.error(e);
        return {};
    }
};

const selectLanguage = state => state?.profile?.meta?.settings?.language;

export { getMeta, getContent, updateUserProfile, changePassword, selectLanguage };

