/**
 * this file is auto generated by chopin-scripts.
 * you can check the project package.json
 * to see how it was generated.
 */

import google from './google';
import { GoogleIntegratorV2 as googleV2 } from './google/indexV2';
import { Facebook as facebook } from './facebook';
import { Bing as bing } from './bing';
import { Pinterest as pinterest } from './pinterest';
import { Yahoo as yahoo } from './yahoo';

export default {
    google,
    googleV2,
    facebook,
    bing,
    pinterest,
    yahoo,
};
