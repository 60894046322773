import BulletIcon from '../../../components/svgs/bullet';
import React from 'react';
import classNames from 'classnames/bind';
import scrollStyle from './scroll-info-list.module.css';
import style from '../info-list.module.css';

const cxm = classNames.bind(style);
const cx = classNames.bind(scrollStyle);

const ScrollInfoList = ({ data }) => {
    return (
        <div>
            {data.map((item, index) => (
                <div className={cxm('container')} key={`${index}_${item.key}`}>
                    <div className={cxm('title-container')}>
                        <div className={cxm('bullet')}>
                            <BulletIcon />
                        </div>
                        <div className={cxm('title')}>{item.title}</div>
                    </div>
                    <div className={cx('contnet-container')}>
                        <p>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ScrollInfoList;
