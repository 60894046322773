import { httpRequest, methods } from '../http';
/* @ts-ignore */
import config from '@config';
import { MarketoFieldsValues, MarketoForm, MarketoPartialFieldsValues, setFormsResponse } from 'types/marketo';

const CHEQ_API_BASE_URL = `${config.baseAPI}/api/v2`;
const MARKETO_BASE_URL = `${config.marketoBaseUrl}`;
const MARKETO_VERSION = 'v1';

export async function connectMarketoOAuth(marketoFieldsValues: MarketoFieldsValues) {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_BASE_URL}/marketo/v1/oauthIntegration`, method: methods.post, body: { payload: marketoFieldsValues } });
    return response;
}

export async function isMarketoConnected(networkId: number): Promise<MarketoPartialFieldsValues | null> {
    const { response } = httpRequest({ baseURL: `${MARKETO_BASE_URL}/oauth/${MARKETO_VERSION}/integration/${networkId}`, method: methods.get });
    return response;
}

export async function disconnectMarketoOAuth(): Promise<string> {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_BASE_URL}/marketo/v1/invalidate`, method: methods.delete });
    return response;
}

export async function getMarketoForms(networkID: string): Promise<MarketoForm[]> {
    const { response } = httpRequest({ baseURL: `${MARKETO_BASE_URL}/forms/${MARKETO_VERSION}/allForms/${networkID}`, method: methods.get });
    return response;
}

export async function setMarketoForms(networkID: string, payload: setFormsResponse[]): Promise<any> {
    const { response } = httpRequest({ baseURL: `${MARKETO_BASE_URL}/forms/${MARKETO_VERSION}/setForms/${networkID}`, method: methods.post, body: payload });
    return response;
}
