import React from "react";
import Radio from "@components/containers/exportables/ui/radio";
import styles from "../../settings.module.scss";

export default ({ cm, paid, onChangeHandler }) => (
    <>
        <div className={styles.row}>
            <Radio onChange={onChangeHandler}
                   selector="exportables-create-report-settings-sources"
                   name="paid"
                   value={paid}
                   items={[
                       {value: "", label: cm.get("AllSources")},
                       {value: "paid", label: cm.get("Paid")},
                   ]}
            />
        </div>
    </>
)