import Base from '../base';
import { _String } from 'chopin-methods';
import moment from 'moment-timezone';
import ContentManager from '../../../services/content-manager';
import Toast from '../../../services/notifications';
import { applyAuth0AuthorizationHeader } from '@utils/http/view-models';

const components = ['ua', 'invalidIps', 'browsers', 'dateRanges', 'platforms', 'channels'];

export default ({ httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor() {
                    super('realtime');
                    this.state = { ...this.state, params: {}, components: { total: [{}] } };
                }

                init(permissions) {
                    // this.update(s => ({ ...s, permissions: permissions.dashboard }));
                    // if (permissions.dashboard) {
                    //     this.getComponents({ date: new Date() });
                    // }
                }

                getComponents(date) {
                    return Promise.all(components.map(component => this[`get${_String.capitalize(component)}`](date)));
                }
            }
        ).from([
            httpRequest({
                name: 'components',
                type: 'post',
                preRequest: function (params) {
                    this.update(state => ({ ...state, params, components: {} }));
                },
                updateState: function (data = {}, params) {
                    return { components: parseComponents(data.queryResults, params) };
                },
                onError: handelError,
                // postRequest: function (data, args) { if (Object.keys(args).length === 1 && args.dateRange === 'today') this.initSocket() }
            }),

            ...components.map(dataType =>
                httpRequest({
                    name: `get${_String.capitalize(dataType)}`,
                    type: 'post',
                    preRequest: function (params) {
                        applyAuth0AuthorizationHeader.call(this)
                    },
                    updateState: data => ({ [dataType]: data }),
                    onError: function (err) {
                        handelError(err);
                        this.update({ [dataType]: [] });
                    },
                })
            ),
        ]);

const parseComponents = (data = [{}], { dateRange, ...rest }) =>
    Object.entries(data.reduce((groupBy = {}, current) => ({ ...groupBy, [current.component]: [...(groupBy[current.component] || []), current] }), {})).reduce(
        (components, [name, values]) => ({ ...components, [name]: parseByType(name, values, { dateRange, ...rest }) }),
        {}
    );

const fillMissingHoursForToday = lastHour => {
    const _increaseHour = hour => moment(hour, 'HH:mm').add(1, 'hour').format('HH:00');

    const missingHours = [];

    do {
        missingHours.push(_increaseHour(missingHours[missingHours.length - 1] || lastHour));
    } while (_increaseHour(missingHours[missingHours.length - 1] || lastHour) !== '00:00');

    return missingHours.map(hour => {
        return {
            hour,
            component: 'dateTime',
            format: 'HH:mm',
            total: 0,
            invalidClicks: 0,
            date: hour,
            formattedDate: hour,
        };
    });
};

const parseByType = (name, values, { dateRange, ...rest }) => {
    const p = {
        total: values =>
            values.map(parseClicks).reduce(
                (totals, { total = 0, invalidClicks = 0 }) => {
                    totals.total += total;
                    totals.invalidClicks += invalidClicks;
                    return totals;
                },
                { total: 0, invalidClicks: 0 }
            ),
        dateTime: values => {
            const dateTime = values
                .map(parseClicks)
                .map(enrichDate(dateRange))
                .sort((a, b) => (moment(b.date, b.format).isAfter(moment(a.date, a.format)) ? -1 : 1));
            if (dateRange === 'today' && dateTime[dateTime.length - 1].hour !== '23:00') {
                dateTime.push(...fillMissingHoursForToday(dateTime[dateTime.length - 1].hour));
            }

            return dateTime;
        },
        sourceCampaignReason: values => values.map(parseClicks),
        countries: values => values.map(parseClicks),
        reasonCategory: values => values.map(parseClicks).filter(({ reasonCategory }) => reasonCategory !== 'Valid'),
        term: values => values.map(parseClicks),
        savings: value => (Array.isArray(value) ? value[0] : value),
        invalidByPlatform: values =>
            values
                .filter(({ platformOrigin }) => !['UNDETERMINED_SOURCE', '- EMPTY -'].includes(platformOrigin))
                .map(parseClicks)
                .map(platform => ({
                    ...platform,
                    totalUsers: parseInt(platform.totalUsers) || 0,
                    validUsers: parseInt(platform.validUsers) || 0,
                    invalidUsers: parseInt(platform.invalidUsers) || 0,
                    savedClicks: parseInt(platform.savedClicks) || 0,
                    platformOrigin: _String.capitalize(platform.platformOrigin.toLowerCase()),
                })),
        industryCPC: value => value[0],
        platformsOrigins: value => {
            const platforms = (value[0] && value[0].platforms) || [];
            return [{ value: 'none', label: 'none' }, ...platforms.map(platform => ({ value: platform, label: _String.capitalize(platform.toLowerCase()) }))];
        },
    };
    return (p[name] && p[name](values)) || values;
};

const parseClicks = ({ total, invalidClicks, ...rest }) => ({ total: parseInt(total) || 0, invalidClicks: parseInt(invalidClicks) || 0, ...rest });

const enrichDate = dateRange => {
    const fromString = period => dateObject => {
        const formats = { today: 'HH:mm', yesterday: 'HH:mm', last7Days: 'YYY-MM-DD', last30Days: 'YYY-MM-DD', last3Months: 'YYY-MM-DD', last6Months: 'YYY-MM-DD' };
        const getDate = date =>
            ({
                today: dateObject.hour,
                yesterday: dateObject.hour,
                last7Days: dateObject.day,
                last30Days: dateObject.day,
                last3Months: dateObject.week,
                last6Months: dateObject.week,
            }[date]);
        const parseDate = date => {
            switch (date) {
                case 'last3Months':
                case 'last6Months':
                    return `${moment(getDate(date), formats[date]).format('MM/DD')}-${moment(getDate(date), formats[date]).endOf('isoWeek').format('MM/DD')}`;
                default:
                    return getDate(date);
            }
        };

        return { ...dateObject, format: formats[period], formattedDate: parseDate(period), date: getDate(period) };
    };
    const fromObject = () => {
        let period = 'today';
        const startDate = moment(dateRange.startDate, 'YYYY-MM-DD HH:mm');
        const endDate = moment(dateRange.endDate, 'YYYY-MM-DD HH:mm');
        if (endDate.diff(startDate, 'hours') <= 24) period = 'today';
        else if (endDate.diff(startDate, 'days') <= 30) period = 'last30Days';
        else if (endDate.diff(startDate, 'days') > 30) period = 'last3Months';
        return fromString(period);
    };

    return dateRange.startDate && dateRange.startDate ? fromObject() : fromString(dateRange);
};

const handelError = err => {
    let message;
    if (err && err.response && typeof err.response.data === 'string') {
        switch (err.response.data) {
            case 'REACHED_API_LIMIT':
                message = ContentManager.get('Error.ReachedAPILimit');
                break;
            default:
                message = err.response.data;
        }
    } else {
        message = (err && err.message) || 'sorry, something went wrong..';
    }
    Toast({ message });
};
