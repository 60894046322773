import Checkbox from '../../../../../widgets/checkbox';
import NoData from '../../../../../widgets/no-data/index';
import React from 'react';
import SettingsIcon from '../../../../../svgs/settings/index';
import Table from '../../../../../widgets/table/index';
import { useContentManager, useSplit } from '@hooks';
import { constants as splitConstants } from '@utils/split.io';

const formatCustomerId = raw => raw.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

const ClientsTable = ({ areEveryoneProtected, onToggleAccountsProtectAll, setIsClientProtected, setClientView, googleCustomers, sso }) => {
    const cm = useContentManager();
    const { isOn: isGoogleV2On } = useSplit(splitConstants.PARADOME_GOOGLE_V2);

    return (
        <Table
            columns={['name', { id: 'clientId', searchTransform: formatCustomerId }, 'actions']}
            RenderEmpty={() => (
                <NoData lineHeight={40} style={{ paddingTop: 80 }}>
                    <div>NO GOOGLE ACCOUNT WAS LINKED</div>
                </NoData>
            )}
            RenderHead={({ column }) => {
                switch (column.id) {
                    case 'clientId':
                        return <div>Account ID</div>;
                    case 'name':
                        return <div>Account Name</div>;
                    case 'actions':
                        return (
                            <div>
                                <span>
                                    <Checkbox checked={areEveryoneProtected} onClick={onToggleAccountsProtectAll} />
                                </span>
                                <span style={{ lineHeight: '40px' }}>Protected</span>
                            </div>
                        );
                    default:
                        return <div style={{ width: column.width }} />;
                }
            }}
            RenderBody={({ column, row }) => {
                const cellValue = row[column.id];
                switch (column.id) {
                    case 'actions':
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox checked={row.isProtected} onClick={() => setIsClientProtected(row.clientId)} />
                                <div
                                    style={{
                                        display: 'inline-block',
                                        color: 'rgb(245, 0, 87)',
                                        cursor: 'pointer',
                                        marginTop: 5,
                                        marginLeft: 8,
                                    }}
                                    onClick={() => setClientView(row)}
                                    onMouseDown={e => e.preventDefault()}
                                >
                                    <SettingsIcon />
                                </div>
                            </div>
                        );
                    case 'clientId':
                        return <div>{formatCustomerId(cellValue)}</div>;
                    default:
                        return <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: 'inherit' }}>{cellValue}</div>;
                }
            }}
            search={isGoogleV2On ? 'header' : 'footer'}
            pagination={true}
            sortable={false}
            rows={googleCustomers}
            isLoading={sso.requestGoogleManagement}
            isError={sso.errorGoogleManagement}
            lineaCount={false}
            rowsPerPage={6}
            style={{
                root: { background: 'rgba(20, 23, 44, 0.5)' },
                head: { padding: 0 },
                headerRow: { padding: 0, backgroundColor: '#14172c', height: 45 },
                headerCell: { borderBottom: 'none', color: '#fe0072', fontFamily: 'Open Sans', letterSpacing: 0.12, fontSize: 12 },
                oddRow: { backgroundColor: '#21223c', height: 41.5 },
                row: { backgroundColor: '#383851', height: 41.5 },
                bodyCell: { border: 'none', fontSize: 12, padding: 4 },
                pagination: { bottom: 9 },
                tableWrapper: {
                    '& th:nth-child(1)': {
                        width: 233,
                        paddingLeft: 40,
                        paddingRight: 7,
                    },
                    '& td:nth-child(1)': {
                        width: 233,
                        paddingLeft: 40,
                        paddingRight: 7,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        position: 'relative',
                    },
                    '& th:nth-child(2), & td:nth-child(2)': {
                        width: 122,
                        paddingLeft: 0,
                        paddingRight: 7,
                    },
                    '& th:nth-child(3), & td:nth-child(3)': {
                        width: 110,
                        paddingLeft: 0,
                        paddingRight: 7,
                    },
                    '& th:nth-child(4), & td:nth-child(4)': {
                        width: 117,
                        paddingLeft: 0,
                        paddingRight: 7,
                    },
                    '& th:nth-child(5), & td:nth-child(5)': {
                        width: 95,
                        paddingLeft: 0,
                        paddingRight: 7,
                    },
                },
            }}
            cm={cm}
            headCellPadding={'none'}
        />
    );
};

export default ClientsTable;
