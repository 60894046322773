const parseNetowrkFeature = (nf = [], licenses) => {
    const hasOnSiteConversion = !!nf.find(n => n.id === licenses.OnSiteConversion.id);
    const hasPaidMarketing = !!nf.find(n => n.id === licenses.PaidMarketing.id);
    const hasDataAndAnalytics = !!nf.find(n => n.id === licenses.AdvancedAnalytics.id);
    const hasPrivacy = !!nf.find(n => n.id === licenses.Privacy.id);
    const hasSlp = !!nf.find(n => n.id === licenses.LeadProtection.id);

    const constants = licenses;

    return { hasSlp, hasDataAndAnalytics, hasOnSiteConversion, hasPaidMarketing, hasPrivacy, constants };
};

export { parseNetowrkFeature };
