import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import CheqUI from '@combotag/cheq-ui';
import { HighlightOff } from '@material-ui/icons';
import { CheckCircle } from '@styled-icons/boxicons-regular';
import { Warning } from '@styled-icons/fluentui-system-filled';
import { TextField } from '@material-ui/core';
import { iam } from '@services/api';
import mixpanel from '@services/mixpanel';
import Toast from '@services/notifications';
import * as permissions from '@utils/permissions';
import { useContentManager } from '@hooks';
import { setProfileData, setMeta, setDefaultProduct } from '@redux/actions/profile';
import { updateUserProfile, getContent } from '@redux/thunks/profile';
import styles from './settings.module.scss';
import { isValidPassword, passwordRules, getProducts } from './utils';
import useStyles from './styles';
import widgets from '../../../widgets';

const { Progress, MultiSelectCheckbox, Button } = widgets;
const { Checkbox } = CheqUI.React.Widgets;
const { useActions, useMeiosis } = CheqUI.React.Meiosis;
const languages = [
    { value: 'en', label: 'English' },
    { value: 'ja', label: 'Japanese' },
];

const timezones = moment.tz
    .names()
    .filter(name => (name.includes('/') || name === 'UTC') && !name.startsWith('Etc/'))
    .map(zone => ({
        label: `${zone} (GMT ${moment().tz(zone).format('Z')})`,
        value: zone,
    }));

export default () => {
    const actions = useActions();
    const classes = useStyles();
    const profile = useSelector(state => state.profile);

    const { meta: { id, name, username, email, orgId, industry, settings: { tz, language } = {}, defaultProduct } = {} } = profile;

    const { industries = [] } = useMeiosis(actions.settings.buildStream);
    const dispatch = useDispatch();
    const cm = useContentManager();
    const networkFeatures = useSelector(state => state.settings?.networkFeatures);
    const initialState = { id, email, name, username: username || email, orgId, tz, language, active: true, firstLogin: false, password: '', newPassword2: '' };
    const [profileState, setProfileState] = useState(initialState);
    const [accountState, setAccountState] = useState({ industry });
    const [showPass, setShowPass] = useState(false);
    const [showPasswordValidation, setShowPasswordValidation] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const products = getProducts(networkFeatures);
    const [defaultProductValue, setDefaultProductValue] = useState(
        products.map(parseLabel(cm)).find(item => item.value === defaultProduct) || {
            value: null,
            label: 'None',
        }
    );
    const [isLoading, setIsLoading] = useState(actions.users.isLoading('update_user_profile') || profile.contentLoading || actions.settings.isLoading('update_settings'));
    useEffect(() => {}, [language, tz]);

    const onSaveDefaultProduct = () => {
        iam.editProfile({ id, defaultProduct: defaultProductValue.value });
        dispatch(setDefaultProduct(defaultProductValue.value));
        mixpanel.postEvent('Default product choose', {
            'Chosen product': defaultProductValue.label,
        });

        const message = `${cm.get('SuccessfullyUpdatedProfile')}
        ${cm.get('FieldsChanged')}: Default Product`;

        Toast({ message, type: 'success' });
    };

    const onPasswordChange = key => e => {
        const value = e.target.value;
        setProfileState(s => ({ ...s, [key]: value }));
        if (value.length === 0) {
            setShowPasswordValidation(false);
            return;
        }
        const errors = isValidPassword(value);
        if (key == 'password') {
            setShowPasswordValidation(!_.isEmpty(errors));
        }
    };

    const onChangeHandler = key => e => {
        handleChange(key)(e.target.value);
    };
    const handleChange = key => value => setProfileState(s => ({ ...s, [key]: value }));

    useEffect(() => {
        setIsLoading(actions.users.isLoading('update_user_profile') || profile.contentLoading || actions.settings.isLoading('update_settings'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions.users.isLoading('update_user_profile'), profile.contentLoading, actions.settings.isLoading('update_settings')]);

    const handleOnSettingsSave = async () => {
        const { password, newPassword2 } = profileState;
        if (password !== newPassword2) {
            setPasswordError(true);
            return;
        }
        try {
            const updateData = sanitize(profileState, { name, username, email, password, tz, language });
            dispatch(updateUserProfile(updateData)).then(() => {
                const { user, settings } = Object.entries(updateData).reduce(
                    (total, [key, value]) => {
                        if (key === 'tz' || key === 'language') total.settings[key] = value;
                        else if (key !== 'password' || key !== 'password' || key !== 'newPassword2') total.user[key] = value;
                        return total;
                    },
                    { user: {}, settings: {} }
                );
                const { language, tz } = profileState;
                if (language !== profileState.language || tz !== profileState.tz) {
                    dispatch(getContent({ app: 'ppc', language, tz }));
                }
                dispatch(
                    setProfileData({
                        ...profile,
                        settings: { ...profile.settings, tz, language },
                        meta: { ...profile.meta, settings: { ...profile.meta.settings, language, tz } },
                    })
                );
                dispatch(setMeta({ ...profile.meta, ...user, settings: { ...profile.meta.settings, language, tz } }));
                setIsLoading(false);

                // TODO fix sync issues when saving settings,its being updated correctly in redux and auth0 metadata, but not read correctly in the application
                window.location.reload();
            });
        } catch (e) {
            console.error(e);
        }

        const updatedFields = getChangedValues(profileState);

        const message = `${cm.get('SuccessfullyUpdatedProfile')}
            ${cm.get('FieldsChanged')}: ${updatedFields.join(', ')}`;

        Toast({ message, type: 'success' });
    };

    const getChangedValues = state => {
        const messages = [];
        const { name: newName, username: newUsername, language: newLanguage, tz: newTz } = state;

        if (newName !== name) messages.push(cm.get('Name'));
        if (newUsername !== username) messages.push(cm.get('Username'));
        if (newLanguage !== language) messages.push(cm.get('Language'));
        if (newTz !== tz) messages.push(cm.get('Timezone'));

        return messages;
    };

    const isPrivacyCompliance = () => {
        const products = JSON.parse(localStorage.getItem('products')) || [];
        console.log(products.some(item => item.id === 5));
        return products.some(item => item.id === 5);
    };

    return (
        <div className={classes.container}>
            {isLoading || profileState.changePasswordLoading ? (
                <div className="app-load" style={{ textAlign: 'center' }}>
                    <Progress style={{ verticalAlign: 'center' }} />
                </div>
            ) : (
                <div className={classes.formContainer}>
                    <Accordion key={1}>
                        <AccordionSummary>
                            <div className={styles.title}> {cm.get('EditProfile')}</div>
                            <HighlightOff />
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                variant="outlined"
                                label="Full Name"
                                defaultValue={profileState.name}
                                onChange={onChangeHandler('name')}
                                InputProps={{
                                    className: classes.textField,
                                }}
                            />
                            <TextField
                                disabled
                                variant="outlined"
                                label="Username"
                                defaultValue={profileState.username}
                                InputProps={{
                                    className: classes.textField,
                                }}
                            />
                            <TextField
                                disabled
                                variant="outlined"
                                label="Email"
                                defaultValue={profileState.email}
                                InputProps={{
                                    className: classes.textField,
                                }}
                            />
                            <div className={styles.checkboxTzLangPrivacyText}>
                                <div className={styles.checkboxTzLang}>
                                    <MultiSelectCheckbox
                                        className={classes.input}
                                        data-test-id={'TimeZoneSelectCheckbox'}
                                        styles={selectStyle(profileState.tz)}
                                        options={timezones}
                                        onChange={({ value }) => handleChange('tz')(value)}
                                        placeholder={cm.get('Timezone')}
                                        value={timezones.find(({ value }) => value === profileState.tz)}
                                    />
                                    <MultiSelectCheckbox
                                        className={classes.input}
                                        styles={selectStyle(profileState.language)}
                                        options={languages.map(parseLabel(cm))}
                                        onChange={({ value }) => handleChange('language')(value)}
                                        placeholder={cm.get('Language')}
                                        value={languages.find(({ value }) => value === profileState.language)}
                                    />
                                </div>
                                {(profileState.language !== 'en' || profileState.tz !== 'UTC') && isPrivacyCompliance() && (
                                    <span style={{ width: '35%', fontSize: '1.3rem' }}>{cm.get('TimeZoneLanguagePrivacyCompliance')}</span>
                                )}
                            </div>

                            <div className={styles.passwordContainer}>
                                <TextField
                                    variant="outlined"
                                    label={cm.get('UserProfile.Input.NewPassword')}
                                    defaultValue={profileState.password}
                                    type={showPass ? 'text' : 'password'}
                                    onChange={onPasswordChange('password')}
                                    InputProps={{
                                        className: classes.textField,
                                    }}
                                />
                                <div className={`${styles.passwordRules} ${showPasswordValidation && styles.show}`}>
                                    {passwordRules.map(({ checkRule, rule }) => (
                                        <div key={rule} className={styles.rule}>
                                            <div>{checkRule(profileState.password) ? <CheckCircle size={14} color="green" /> : <Warning size={14} color="red" />}</div>
                                            <div>{rule}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <TextField
                                variant="outlined"
                                label={cm.get('ReEnterNewPassword')}
                                defaultValue={profileState.newPassword2}
                                type={showPass ? 'text' : 'password'}
                                onChange={onPasswordChange('newPassword2')}
                                InputProps={{
                                    className: classes.textField,
                                }}
                            />
                            {passwordError && <div style={{ marginLeft: 7, color: 'red', fontSize: 14 }}>Passwords do not match</div>}
                            <div style={{ textAlign: 'left' }}>
                                <Checkbox
                                    label={cm.get('ShowPassword')}
                                    onClick={() => setShowPass(!showPass)}
                                    checked={showPass}
                                    style={{ position: 'relative', left: 21, top: -2 }}
                                />
                            </div>
                            <div className={classes.btnContainer}>
                                <Button
                                    onClick={handleOnSettingsSave}
                                    disabled={
                                        (_.isEmpty(profileState.password) && !_.isEmpty(profileState.newPassword2)) ||
                                        (_.isEmpty(profileState.newPassword2) && !_.isEmpty(profileState.password)) ||
                                        !isValidPassword(profileState.password)
                                    }
                                    styles={{ container: classes.btn }}
                                >
                                    {cm.get('Save')}
                                </Button>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    {permissions.isAuthorized(profile.permissions, 'CPC', 'write') && (
                        <Accordion key={2} style={{ marginTop: 20 }}>
                            <AccordionSummary>
                                <div className={styles.title}>{cm.get('Account')}</div>

                                <HighlightOff />
                            </AccordionSummary>
                            <AccordionDetails>
                                {industries && (
                                    <MultiSelectCheckbox
                                        className={classes.input}
                                        isLoading={actions.settings.isLoading('get_industries')}
                                        styles={selectStyle(accountState.industry)}
                                        options={industries.map(parseLabel(cm))}
                                        onChange={({ value }) => setAccountState(s => ({ ...s, industry: value }))}
                                        placeholder={cm.get('Industry')}
                                        value={industries.find(({ value }) => value === accountState.industry)}
                                    />
                                )}
                                <div className={classes.btnContainer}>
                                    <Button
                                        disabled={accountState.industry === industry}
                                        styles={{ container: classes.btn }}
                                        onClick={() => actions.settings.updateSettings(accountState)}
                                    >
                                        {cm.get('Save')}
                                    </Button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    <Accordion key={3} style={{ marginTop: 20 }}>
                        <AccordionSummary>
                            <div className={styles.title}>{cm.get('DefaultSolution')}</div>
                            <HighlightOff />
                        </AccordionSummary>
                        <AccordionDetails>
                            {industries && (
                                <MultiSelectCheckbox
                                    className={classes.input}
                                    styles={selectStyle(accountState.industry)}
                                    options={products.map(parseLabel(cm))}
                                    onChange={item => setDefaultProductValue(item)}
                                    placeholder={cm.get('ChooseProduct')}
                                    value={defaultProductValue}
                                    isOptionDisabled={option => !option.active}
                                    defaultProductSelection={true}
                                />
                            )}
                            <div className={classes.btnContainer}>
                                <Button styles={{ container: classes.btn }} onClick={onSaveDefaultProduct}>
                                    {cm.get('Save')}
                                </Button>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
        </div>
    );
};

const parseLabel =
    cm =>
    ({ label, ...rest }) => {
        const formattedLabel = label.replace(/[^a-zA-Z0-9]/g, '');
        return { ...rest, label: cm.get(formattedLabel) };
    };

const sanitize = (state, setting) => {
    const postData = {};
    Object.entries(setting).reduce((total, [key, value]) => (state[key] !== value ? Object.assign(total, { [key]: state[key] }) : total), postData);
    postData.password = state.password;
    return postData;
};

const selectStyle = value => ({
    container: {
        margin: '7px auto 7px auto',
        border: 'none',
        width: '87%',
        ...((value && { border: 'solid 1px #fe0072', backgroundColor: '#14172c' }) || {}),
    },
    placeholder: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 500,
        top: 10,
    },
    control: {
        ...((value && { backgroundColor: '#14172c' }) || {}),
    },
});
