import React from 'react';
import CheqUI from '@combotag/cheq-ui';
import Button from '../../../../widgets/button/index';
import LinearProgress from '../../../../widgets/liniar-progress/index';
import { useDispatch } from 'react-redux';
import { triggerSnackbarMessage } from '../../../../../redux/actions/snackbar';
const { Meiosis } = CheqUI.React;
const { useActions, useMeiosis } = Meiosis;

const ApproveGoogleAdsV2 = ({ onCancelInvitation, onApproved, styles }) => {
    const dispatch = useDispatch();
    const actions = useActions();
    const { ssoV2: ssoV2Actions } = actions;
    const sso = useMeiosis(ssoV2Actions.buildStream);

    const pendingClientIdToCancel = Object.keys(sso?.ssos?.google?.statuses || {}).find(key => sso?.ssos?.google?.statuses[key] === 'PENDING');

    return (
        <div className={styles.content}>
            {(sso.requestGetNetwork || sso.requestCancel) && (
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            )}
            <div style={{ lineHeight: '40px', marginTop: -10, display: 'inline-block' }}>
                1.{' '}
                <a href="https://ads.google.com/nav/login" style={{ color: '#fe0072' }} target="_blank" rel="noreferrer">
                    Sign in
                </a>{' '}
                to your Google Ads account ({pendingClientIdToCancel})<br />
                2. Click on Tools and then Account access
                <br />
                3. Switch to the Managers tab
                <br />
                4. Approve the request from CHEQ Manager
                <br />
            </div>
            <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                <img
                    alt=""
                    src="https://d364debm9gdlqk.cloudfront.net/google-setup/approvecheq2.gif"
                    style={{ marginLeft: 90, width: 400, height: 220, boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 15px 0px', borderRadius: 8 }}
                />
            </div>
            <div className={styles.footer} style={{ display: 'flex', gap: 8 }}>
                <Button
                    isLoading={sso.requestCancel}
                    style={{ width: 150 }}
                    onClick={() => ssoV2Actions.cancelGoogleIds({ clientIds: [pendingClientIdToCancel] }, onCancelInvitation)}
                >
                    CANCEL INVITE
                </Button>
                <Button
                    isLoading={sso.requestGetNetwork}
                    style={{ width: 150 }}
                    onClick={() => {
                        ssoV2Actions.getNetwork().then(ssos => {
                            if (!ssos.google || !ssos.google.statuses) {
                                return dispatch(triggerSnackbarMessage({ message: 'Invitation have been rejected or expired.', timeout: 8000 }));
                            }
                            if (ssos.google.statuses[pendingClientIdToCancel] === 'PENDING') {
                                return dispatch(triggerSnackbarMessage({ message: 'Request is still pending approval.' }));
                            } else {
                                return onApproved();
                            }
                        });
                    }}
                >
                    APPROVED
                </Button>
            </div>
        </div>
    );
};

export default ApproveGoogleAdsV2;
