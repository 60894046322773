import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isUndefined, omit } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import * as permissions from '../../../../../../utils/permissions';
import AudienceExclusion from './audience-exclusion/AudienceExclusion';
import PixelGuard from './pixel-guard/PixelGuard';
import Button from '../../../../../widgets/button';
import CampaignsTable from './CampaignsTable';
import CheqUI from '@combotag/cheq-ui';
import ClientCampaignsTable from './ClientCampaignsTable';
import ClientsTable from './ClientsTable';
import { IconWrapper } from '../../../../../widgets/icon-wrapper/index';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinearProgress from '../../../../../widgets/liniar-progress/index';
import { Select } from './components/select';
import { useContentManager } from '../../../../../../hooks';
import Switch from '../../../../../widgets/switch/index';
import styles from './styleV2.module.scss';
import { triggerSnackbarMessage } from '@redux/actions/snackbar';
import { useSplit } from '@hooks';
import { constants as splitConstants } from '@utils/split.io';
import integrators from '../../3parties';
import { Invalidator } from '../../../table-structures/accounts/indexV2';
import pixelGuardService from '../../../../../../services/api/pixelGuard.service';
import { GOOGLE_TOKEN_REVOKED_ERROR_MESSAGE } from '../../../../../../services/api/google/constants';

const { Meiosis } = CheqUI.React;
const { useActions, useMeiosis } = Meiosis;

const PIXEL_ACTIVE_STATUS = 1;
const PIXEL_INACTIVE_STATUS = 0;

const ProtectionManagementV2 = ({ onInvalidated, onCancel, onAddAccount, onSave }) => {
    const cm = useContentManager();
    const dispatch = useDispatch();

    const { isOn: isGoogleMultiMccOn } = useSplit(splitConstants.PARADOME_GOOGLE_MULTI_MCC);

    const actions = useActions();
    const { ssoV2: ssoV2Actions, dialog: dialogActions, clickTrueTags: clickTrueTagsActions } = actions;
    const ssoV2 = useMeiosis(ssoV2Actions.buildStream);

    const profile = useSelector(state => state.profile);
    const clickTrueTagsState = useSelector(state => state.clickTrueTags);
    const googleService = ssoV2Actions.getService('googleV2');
    const [googleCustomers, setGoogleCustomers] = useState(ssoV2.googleCustomers);
    const [selectedAccount, setSelectedAccount] = useState();
    const [isIpBanIntegrated, setIsIpBanIntegrated] = useState(ssoV2.isIpBanIntegrated);
    const [defaultIpBanList, setDefaultIpBanList] = useState(ssoV2.defaultIpBanList);
    const [protectedCampaigns, setProtectedCampaigns] = useState(ssoV2.protectedCampaigns);
    const [clientView, setClientView] = useState('clients');
    const [isAudienceTagIntegrated, setIsAudienceTagIntegrated] = useState(ssoV2.isAudienceTagIntegrated);
    const [googleAdsAudienceExclusions, setGoogleAdsAudienceExclusions] = useState(undefined);
    const [isAudienceExclusionIntegrated, setIsAudienceExclusionIntegrated] = useState(ssoV2.isAudienceExclusionIntegrated);
    const [pixelGuardToggleStatus, setPixelGuardToggleStatus] = useState(false);
    const [sortedPixelsGuard, setSortedPixelsGuard] = useState([]);
    const [editedClickTrueTags, setEditedClickTrueTags] = useState(clickTrueTagsState.tags);
    const [emptyPixel, setEmptyPixel] = useState(null);
    const { isOn: isGoogleSnsOn } = useSplit(splitConstants.SPLIT_GOOGLE_DISCONNECT_SNS);
    const { isOn: isGooglePixelGuardSplitOn } = useSplit(splitConstants.SPLIT_PARADOME_GOOGLE_PIXEL_GUARD);
    const { pxgVersion } = useSelector(state => state.settings);

    const protectedAccountIdOptions = useMemo(() => {
        if (!googleCustomers) return [];
        return googleCustomers.reduce((acc, currGc) => {
            acc.push({ value: currGc.clientId, label: currGc.clientId });
            return acc;
        }, []);
    }, [googleCustomers]);

    useEffect(() => {
        if (protectedAccountIdOptions?.length) {
            setSelectedAccount(protectedAccountIdOptions[0]);
        } else {
            setSelectedAccount();
        }
    }, [protectedAccountIdOptions?.length]);

    const isGooglePixelGuardOn = isGooglePixelGuardSplitOn && pxgVersion === 0;
    const clickTrueTags = clickTrueTagsState.tags;
    const clickTrueTagsStateLoading = clickTrueTagsState?.isLoading;
    const hasAuthenticator = ssoV2Actions.hasAuthenticator('google');
    const isConnected = ssoV2Actions.isConnected('google');
    const Integration = integrators['google'];
    const initialProtectedCampaigns = ssoV2.protectedCampaigns;
    const isAuthorized = (model, action) => permissions.isAuthorized(profile.permissions, model, action);
    const dialogOptions = {
        width: 'auto',
        noCancel: true,
        Header: () => <div />,
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalStyle: {
            display: 'flex',
            margin: 0,
            padding: 0,
        },
    };

    const setIsClientProtected = (clientId, value) => {
        const clientIdx = googleCustomers.findIndex(customer => clientId === customer.clientId);
        value = isUndefined(value) ? !googleCustomers[clientIdx].isProtected : value;
        setGoogleCustomers([
            ...googleCustomers.slice(0, clientIdx),
            {
                ...googleCustomers[clientIdx],
                isProtected: value,
            },
            ...googleCustomers.slice(clientIdx + 1),
        ]);
        setProtectedCampaigns(omit(protectedCampaigns, clientId));
        if (clientView !== 'clients' && clientView !== 'campaigns' && clientView !== 'googleAdsAudienceExclusion') {
            setClientView({ ...googleCustomers[clientIdx], isProtected: value });
        }
    };

    const areEveryoneProtected = googleCustomers && googleCustomers.every(({ isProtected }) => isProtected);
    const showClientCampaignsTable = clientView !== 'clients' && clientView !== 'campaigns' && clientView !== 'audience-exclusion' && clientView !== 'pixel-guard-view';

    const onToggleAccountsProtectAll = () => {
        setGoogleCustomers(
            googleCustomers.map(googleCustomer => ({
                ...googleCustomer,
                isProtected: !areEveryoneProtected,
            }))
        );
        setProtectedCampaigns({});
    };

    const onToggleCampaignsProtectAll = (clientId, isProtectedAll) => () => {
        if (isProtectedAll) {
            setIsClientProtected(clientId, false);
        } else {
            setIsClientProtected(clientId, true);
        }
        setProtectedCampaigns(omit(protectedCampaigns, clientId));
    };

    const toggleProtectCampaign = (clientId, campaignId, toAdd, campaigns) => {
        const protectedCampaignsForClient = protectedCampaigns[clientId];
        if (toAdd) {
            if (!protectedCampaignsForClient) {
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: [campaignId],
                });
                const clientIdx = googleCustomers.findIndex(customer => clientId === customer.clientId);
                setGoogleCustomers([
                    ...googleCustomers.slice(0, clientIdx),
                    {
                        ...googleCustomers[clientIdx],
                        isProtected: true,
                    },
                    ...googleCustomers.slice(clientIdx + 1),
                ]);
                if (clientView !== 'clients' && clientView !== 'campaigns') {
                    setClientView({ ...googleCustomers[clientIdx], isProtected: true });
                }
            } else {
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: [...protectedCampaignsForClient, campaignId],
                });
            }
        } else {
            if (!protectedCampaignsForClient) {
                //remove 1 campaign from fully protected mode - so, batch insert other campaigns to protected cmapaigns
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: campaigns.filter(({ id }) => id !== campaignId).map(({ id }) => id),
                });
            } else {
                if (protectedCampaignsForClient.length === 1) {
                    setIsClientProtected(clientId, false);
                } else {
                    setProtectedCampaigns({
                        ...protectedCampaigns,
                        [clientId]: protectedCampaignsForClient.filter(id => campaignId !== id),
                    });
                }
            }
        }
    };

    const onChangeProtectionMode = clientId => protectionMode => {
        switch (protectionMode) {
            case 'disabled':
                return setIsClientProtected(clientId, false);
            case 'default':
                return setIsClientProtected(clientId, true);
            case 'campaigns':
                setIsClientProtected(clientId, true);
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: googleCustomers.find(customer => customer.clientId === clientId).campaigns.map(({ id }) => id),
                });
                break;
            case 'label':
                setIsClientProtected(clientId, true);
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: ['LABEL'],
                });
                break;
            default:
                return;
        }
    };

    const saveGoogleAudienceExclusions = async () => {
        if (!isAudienceExclusionIntegrated) return { success: true };

        return ssoV2Actions.saveGoogleAdsAudienceExclusions({
            exclusions: {
                created: googleAdsAudienceExclusions?.filter(ex => ex.id < 0 && !ex.isDeleted),
                updated: googleAdsAudienceExclusions?.filter(ex => ex.id > 0 && !ex.isDeleted && ex.isDirty),
                deleted: googleAdsAudienceExclusions?.filter(ex => ex.id > 0 && ex.isDeleted),
            },
        });
    };

    const createSortedPixelGuardsData = clickTrueTagsData => {
        const data =
            clickTrueTagsData
                .map(tag => {
                    const protectionConfig = JSON.parse(tag.protectionConfig) || {};
                    if (protectionConfig.pxg && protectionConfig.pxg.trackingIds) {
                        return protectionConfig.pxg.trackingIds.map(pixel => ({
                            active: pixel.active,
                            pixelId: pixel.pixelId.replace('AW-', ''),
                            tagId: tag.id,
                        }));
                    }
                })
                .filter(element => !!element)
                .flat() || [];

        if (emptyPixel) {
            data.push(emptyPixel);
        }

        return data;
    };

    const isInvalidPixelInSortedPixelsGuards = () => {
        const foundedInvalidGoogleTagId = sortedPixelsGuard.some(pixel => !pixel.pixelId.length);
        const foundedInvalidCheqTag = sortedPixelsGuard.some(pixel => !pixel.tagId);
        if (foundedInvalidGoogleTagId) {
            return { isValid: false, errMessage: 'Google Tag ID is mandatory for Pixel Guard' };
        } else if (foundedInvalidCheqTag) {
            return { isValid: false, errMessage: 'Tag is mandatory for Pixel Guard' };
        }
        return { isValid: true, errMessage: '' };
    };

    const buildPixelGuardData = () => {
        const pixelGuardChanges = { success: true, changes: [], errMessage: '' };
        const sortedPixelsStatus = isInvalidPixelInSortedPixelsGuards();
        if (sortedPixelsStatus.isValid) {
            const pixelsData = {};
            for (const pixel of sortedPixelsGuard) {
                const pixelIdlValue = pixel.pixelId.startsWith('AW-') ? pixel.pixelId : 'AW-' + pixel.pixelId;
                const pixelValue = { pixelId: pixelIdlValue, active: pixelGuardToggleStatus ? pixel.active : PIXEL_INACTIVE_STATUS };
                if (pixelsData[pixel.tagId]) {
                    pixelsData[pixel.tagId].pxg.trackingIds.push(pixelValue);
                } else {
                    pixelsData[pixel.tagId] = {
                        pxg: { trackingIds: [pixelValue] },
                    };
                }
            }
            const parsedPixels = Object.keys(pixelsData).map(key => ({ tagId: Number(key), ...pixelsData[key] })) || [];
            pixelGuardChanges.changes = parsedPixels;
        } else {
            pixelGuardChanges.success = false;
            pixelGuardChanges.errMessage = sortedPixelsStatus.errMessage;
        }

        return pixelGuardChanges;
    };

    const validateGoogleAudienceExclusions = () => {
        if (!isAudienceExclusionIntegrated) return true;

        let validated = true;

        const savedExclusions = googleAdsAudienceExclusions?.filter(ex => !ex.isDeleted);
        if (savedExclusions?.some(ex => !ex.clickTrueTagId)) {
            dispatch(triggerSnackbarMessage({ message: 'Tag is mandatory for audience-exclusion rule' }));
            validated = false;
        }

        if (!validated) setClientView('audience-exclusion');

        return validated;
    };

    const handleSaveGoogleManagement = async () => {
        const buildedPixelsGuard = buildPixelGuardData();
        if (buildedPixelsGuard.success) {
            if (!ssoV2.requestSaveManagement && !ssoV2.requestSaveGoogleAdsAudienceExclusions && validateGoogleAudienceExclusions()) {
                const updateIpsForNewelyProtectedCampaigns = Object.keys(protectedCampaigns).some(clientId => {
                    return !initialProtectedCampaigns[clientId] || initialProtectedCampaigns[clientId].length < protectedCampaigns[clientId].length;
                });

                const isPixelsGuardIntegrated = checkIfPixelsGuardIntegrated(buildedPixelsGuard.changes);

                const saveManagementRequestObject = {
                    googleCustomers: googleCustomers
                        .filter(({ isProtected }) => isProtected)
                        .map(({ isProtected, clientId }) => ({
                            isProtected,
                            clientId,
                        })),
                    updateIpsForNewelyProtectedCampaigns,
                    isIpBanIntegrated,
                    defaultIpBanList,
                    isAudienceTagIntegrated,
                    isPixelsGuardIntegrated,
                    isAudienceExclusionIntegrated,
                    protectedCampaigns,
                    pixelsGuard: buildedPixelsGuard.changes,
                    isIPExclusionStatusChanged:
                        ((ssoV2.isIpBanIntegrated === undefined || !ssoV2.isIpBanIntegrated) && isIpBanIntegrated) ||
                        (ssoV2.isIpBanIntegrated === true && ssoV2.isIpBanIntegrated !== isIpBanIntegrated),
                };

                const saveManagementResult = await ssoV2Actions.saveGoogleManagement(saveManagementRequestObject, dispatch);

                const saveExclusionsResult = await saveGoogleAudienceExclusions();

                await clickTrueTagsActions.getAll();

                //if we're enabling pxg v1 integration, remove pxg v2 integration
                if (isPixelsGuardIntegrated) {
                    await pixelGuardService.disableFeature(2);
                    await pixelGuardService.deletePxgV2Setting();
                }

                if (saveManagementResult?.success && saveExclusionsResult?.success) {
                    ssoV2.googleAdsAudienceExclusions = null;
                    return onSave();
                }

                console.error({
                    saveManagementResult,
                    saveExclusionsResult,
                });
            }
        } else {
            clientView !== 'pixel-guard-view' && setClientView('pixel-guard-view');
            dispatch(triggerSnackbarMessage({ message: buildedPixelsGuard.errMessage }));
        }
    };

    const checkIfPixelsGuardIntegrated = pixelsGuard => {
        let isPixelGuardIntegrated = false;
        if (pixelsGuard && pixelsGuard.length) {
            const allPixelsTracking = pixelsGuard.map(pixel => pixel.pxg.trackingIds).flat();
            for (const pixelTracking of allPixelsTracking) {
                if (pixelTracking.active) {
                    isPixelGuardIntegrated = true;
                    break;
                }
            }
        }

        return isPixelGuardIntegrated;
    };

    const isPixelToggleActive = tags => {
        return tags.some(
            tag =>
                JSON.parse(tag.protectionConfig) &&
                JSON.parse(tag.protectionConfig).pxg &&
                JSON.parse(tag.protectionConfig).pxg.trackingIds.some(pixel => pixel.active === PIXEL_ACTIVE_STATUS)
        );
    };

    const resetGoogleAdsAudienceExclusions = useCallback(() => {
        if (ssoV2.googleAdsAudienceExclusions?.items && ssoV2.googleAdsAudienceExclusions?.items.length > 0) {
            const initialGoogleAdsAudienceExclusions = ssoV2.googleAdsAudienceExclusions?.items.map(i => ({ ...i }));
            setGoogleAdsAudienceExclusions(initialGoogleAdsAudienceExclusions);
        }
    }, [ssoV2.googleAdsAudienceExclusions?.items]);

    const handleCancel = () => {
        resetGoogleAdsAudienceExclusions();
        return onCancel && onCancel();
    };

    const handleBack = () => {
        resetGoogleAdsAudienceExclusions();
        return googleService.invalidate({ isSendSns: isGoogleSnsOn }, onInvalidated).then(() => clickTrueTagsActions.getAll());
    };

    const handleAddAnotherAccount = () => {
        return onAddAccount();
    };

    useEffect(() => {
        setIsAudienceTagIntegrated(ssoV2.isAudienceTagIntegrated);
        setIsAudienceExclusionIntegrated(ssoV2.isAudienceExclusionIntegrated);
        setGoogleCustomers(ssoV2.googleCustomers);
        setIsIpBanIntegrated(ssoV2.isIpBanIntegrated);
        setProtectedCampaigns(ssoV2.protectedCampaigns);
        setDefaultIpBanList(ssoV2.defaultIpBanList);
    }, [ssoV2.googleCustomers, ssoV2.isIpBanIntegrated, ssoV2.defaultIpBanList, ssoV2.isAudienceTagIntegrated, ssoV2.protectedCampaigns, ssoV2.isAudienceExclusionIntegrated]);

    useEffect(() => {
        const googleStatuses = ssoV2.ssos.google.statuses || {};
        if (Object.values(googleStatuses).length && Object.values(googleStatuses).every(status => status === 'MANAGED')) {
            ssoV2Actions.fetchGoogleManagement();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ssoV2Actions, JSON.stringify(ssoV2.ssos.google.statuses)]);

    useEffect(() => {
        if (ssoV2.ssos.google.refresh_token || ssoV2.ssos.google.is_sso) {
            ssoV2Actions.fetchGoogleManagement();
        }
    }, [ssoV2Actions, ssoV2.ssos.google.refresh_token, ssoV2.ssos.google.is_sso]);

    useEffect(() => {
        if (ssoV2.ssos.google.skippedAdwords) {
            ssoV2Actions.fetchGoogleManagement();
        }
    }, [ssoV2Actions, ssoV2.ssos.google.skippedAdwords]);

    useEffect(() => {
        if (!googleAdsAudienceExclusions) {
            resetGoogleAdsAudienceExclusions();
        }
    }, [googleAdsAudienceExclusions, resetGoogleAdsAudienceExclusions]);

    useEffect(() => {
        if (!ssoV2.googleAdsAudienceExclusions?.success && !ssoV2.requestingGoogleAdsAudienceExclusions && isAudienceExclusionIntegrated) {
            ssoV2Actions.getGoogleAdsAudienceExclusions();
        }
    }, [ssoV2Actions, isAudienceExclusionIntegrated, ssoV2.googleAdsAudienceExclusions?.success, ssoV2.requestingGoogleAdsAudienceExclusions]);

    useEffect(() => {
        setPixelGuardToggleStatus(isPixelToggleActive(clickTrueTagsState.tags));
        clickTrueTagsState.tags.length && setSortedPixelsGuard(createSortedPixelGuardsData(clickTrueTagsState.tags));
    }, []);

    useEffect(() => {
        !editedClickTrueTags.length && clickTrueTagsState.tags.length && setEditedClickTrueTags(clickTrueTagsState.tags);
        if (editedClickTrueTags && clickTrueTagsState.tags && clickTrueTagsState.tags.length && !sortedPixelsGuard.length) {
            setSortedPixelsGuard(createSortedPixelGuardsData(clickTrueTagsState.tags));
            if (!pixelGuardToggleStatus) {
                setPixelGuardToggleStatus(isPixelToggleActive(clickTrueTagsState.tags));
            }
        }
    }, [clickTrueTagsState.tags]);

    const isGoogleTokenRevokedErr = ssoV2?.ssos?.isGoogleTokenRevoked && ssoV2.errorGoogleManagement === GOOGLE_TOKEN_REVOKED_ERROR_MESSAGE;

    const ProtectionManagementHeader = useMemo(
        () => (
            <div className={styles.protectionManagementHeader}>
                <div className={styles.protectionManagementTitle}>PROTECTION MANAGEMENT</div>
                {(isGoogleTokenRevokedErr || (isConnected && hasAuthenticator)) && (
                    <>
                        <div className={styles.accountConnectionTitle}>Account Connection</div>
                        <Switch
                            className={styles.accountConnectionToggle}
                            type="checkbox"
                            checkedLabel={'On'}
                            uncheckedLabel={'Off'}
                            checked={hasAuthenticator}
                            onChange={e => dialogActions.show(<Invalidator serviceName={'googleV2'} />, dialogOptions)}
                            style={{ marginRight: 0 }}
                            disabled={!Integration ? true : !isAuthorized('ip_block', 'write')}
                        />
                    </>
                )}
            </div>
        ),
        [isConnected, hasAuthenticator, Integration, isAuthorized, dialogActions, dialogOptions, isGoogleTokenRevokedErr]
    );

    if (ssoV2.requestGoogleManagement || !googleCustomers) {
        return (
            <>
                <LinearProgress />
                <div className={styles.protectionManagementContainer}>
                    <div className={styles.protectionManagementTitle}>PROTECTION MANAGEMENT</div>
                    <div className={`${styles.protectionManagementContent} ${styles.messageContainer}`}>
                        <div>Fetching your accounts and campaigns from Google</div>
                    </div>
                </div>
            </>
        );
    }

    if (ssoV2.errorGoogleManagement) {
        return (
            <div className={styles.protectionManagementContainer}>
                {isGoogleTokenRevokedErr ? ProtectionManagementHeader : <div className={styles.protectionManagementTitle}>PROTECTION MANAGEMENT</div>}
                <div className={`${styles.protectionManagementContent} ${styles.messageContainer}`}>
                    <div className={styles['error']}>{ssoV2.errorGoogleManagement || 'Unexpected Error Accured'}</div>
                </div>
            </div>
        );
    }

    return (
        <>
            <OperationProgress sso={ssoV2} />
            <div className={styles.protectionManagementContainer}>
                {ProtectionManagementHeader}
                <div className={styles.protectionManagementContent}>
                    <div className={styles.leftColumn}>
                        <div className={styles.protectionFeaturesHeader}>Protection Features</div>
                        <div className={styles.protectionFeaturesBody}>
                            <ProtectionFeatureToggle
                                title="IP Exclusion"
                                tooltip="Cheq will protect your campaigns with real-time IP Exclusions"
                                disabled={ssoV2.ssos.google.skippedAdwords}
                                checked={!!isIpBanIntegrated}
                                onChange={() => setIsIpBanIntegrated(!isIpBanIntegrated)}
                            />
                            {isIpBanIntegrated && (
                                <div className={styles.ipBanList}>
                                    <div className={styles.ipListLabel}>You can also insert your own list of IP addresses, separated by new line and up to 100 addresses.</div>
                                    <textarea
                                        value={defaultIpBanList?.join('\n')}
                                        className={styles.ipList}
                                        placeholder={'1.1.1.1'}
                                        onChange={({ target: { value } }) => {
                                            setDefaultIpBanList(value.split('\n').map(ip => ip.trim()));
                                        }}
                                    />
                                </div>
                            )}
                            <ProtectionFeatureToggle
                                title="Audience Tag"
                                subTitle="(Google Analytics)"
                                tooltip="Cheq will protect your campaigns with detargeting fraudulent users."
                                checked={!!isAudienceTagIntegrated}
                                onChange={() => setIsAudienceTagIntegrated(!isAudienceTagIntegrated)}
                            />
                            <ProtectionFeatureToggle
                                title="Audience Exclusion"
                                subTitle="(Google Ads)"
                                tooltip="Cheq will protect your campaigns with detargeting fraudulent users."
                                checked={!!isAudienceExclusionIntegrated}
                                onChange={() => {
                                    setIsAudienceExclusionIntegrated(!isAudienceExclusionIntegrated);
                                    if (!isAudienceExclusionIntegrated === false) {
                                        setClientView('clients');
                                    } else {
                                        setClientView('audience-exclusion');
                                    }
                                }}
                            />
                            {isGooglePixelGuardOn && (
                                <ProtectionFeatureToggle
                                    title="Pixel Guard"
                                    subTitle=""
                                    tooltip="Use Pixel Guard to further protect your campaigns by preventing them from optimizing on invalid traffic. Pixel guard is the only way to secure Performance Max Campaigns."
                                    checked={pixelGuardToggleStatus}
                                    onChange={() => {
                                        setPixelGuardToggleStatus(!pixelGuardToggleStatus);
                                        if (!pixelGuardToggleStatus === false) {
                                            setClientView('clients');
                                        } else {
                                            setClientView('pixel-guard-view');
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.rightColumn}>
                        {showClientCampaignsTable ? (
                            <ClientCampaignsTable
                                onClose={() => setClientView('clients')}
                                client={clientView}
                                protectedCampaigns={protectedCampaigns}
                                setIsClientProtected={setIsClientProtected}
                                toggleProtectCampaign={toggleProtectCampaign}
                                onChangeProtectionMode={onChangeProtectionMode(clientView.clientId)}
                                onToggleCampaignsProtectAll={onToggleCampaignsProtectAll(clientView.clientId)}
                                protectionMode={
                                    !clientView.isProtected
                                        ? 'disabled'
                                        : protectedCampaigns[clientView.clientId] && clientView.campaigns.length
                                        ? protectedCampaigns[clientView.clientId].includes('LABEL')
                                            ? 'label'
                                            : 'campaigns'
                                        : 'default'
                                }
                            />
                        ) : (
                            <>
                                <div className={styles.tabsContainer}>
                                    <div
                                        onMouseDown={e => e.preventDefault()}
                                        onClick={() => setClientView('clients')}
                                        className={styles.tab + ' ' + (clientView === 'clients' && styles.selectedTab)}
                                    >
                                        Accounts
                                    </div>
                                    <div
                                        onMouseDown={e => e.preventDefault()}
                                        onClick={() => setClientView('campaigns')}
                                        className={styles['tab'] + ' ' + (clientView === 'campaigns' && styles.selectedTab)}
                                    >
                                        Campaigns
                                    </div>
                                    {isAudienceExclusionIntegrated && (
                                        <div
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={() => setClientView('audience-exclusion')}
                                            className={styles['tab'] + ' ' + (clientView === 'audience-exclusion' && styles.selectedTab)}
                                        >
                                            Audience Exclusion
                                        </div>
                                    )}
                                    {pixelGuardToggleStatus && isGooglePixelGuardOn && (
                                        <div
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={() => setClientView('pixel-guard-view')}
                                            className={styles['tab'] + ' ' + (clientView === 'pixel-guard-view' && styles.selectedTab)}
                                        >
                                            Pixel Guard
                                        </div>
                                    )}
                                    {isGoogleMultiMccOn && ssoV2.ssos.google.is_mcc && <button onClick={handleAddAnotherAccount}>ADD ANOTHER ACCOUNT</button>}
                                </div>
                                <div style={{ display: clientView === 'clients' ? 'flex' : 'none' }} className={styles.tabsBody}>
                                    <div className={styles.tabsFilters}></div>
                                    <ClientsTable
                                        {...{
                                            areEveryoneProtected,
                                            onToggleAccountsProtectAll,
                                            setIsClientProtected,
                                            setClientView,
                                            googleCustomers,
                                            sso: ssoV2,
                                        }}
                                    />
                                </div>
                                <div style={{ display: clientView === 'campaigns' ? 'flex' : 'none' }} className={styles.tabsBody}>
                                    <div className={styles.tabsFilters}>
                                        <Select
                                            options={protectedAccountIdOptions}
                                            placeHolder="All"
                                            searchPlaceholder={cm.get('Search')}
                                            label="Account ID"
                                            selected={selectedAccount}
                                            onSelect={e => setSelectedAccount(e)}
                                            labelWidthStyle="extended"
                                            showCheckbox={false}
                                        />
                                    </div>
                                    <CampaignsTable
                                        {...{
                                            toggleProtectCampaign,
                                            protectedCampaigns,
                                            selectedAccountId: selectedAccount?.value,
                                            onToggleCampaignsProtectAll,
                                            googleCustomers,
                                        }}
                                    />
                                </div>
                                {isAudienceExclusionIntegrated && (
                                    <div style={{ display: clientView === 'audience-exclusion' ? 'flex' : 'none' }} className={styles.tabsBody}>
                                        <AudienceExclusion
                                            {...{
                                                clickTrueTags,
                                                audienceExclusions: googleAdsAudienceExclusions,
                                                setAudienceExclusions: setGoogleAdsAudienceExclusions,
                                                isLoading: ssoV2.requestingGoogleAdsAudienceExclusions || clickTrueTagsStateLoading,
                                            }}
                                        />
                                    </div>
                                )}
                                {pixelGuardToggleStatus && isGooglePixelGuardOn && (
                                    <div style={{ display: clientView === 'pixel-guard-view' ? 'flex' : 'none' }} className={styles.tabsBody}>
                                        <PixelGuard
                                            clickTrueTags={editedClickTrueTags}
                                            setEditedClickTrueTags={setEditedClickTrueTags}
                                            emptyPixel={emptyPixel}
                                            setEmptyPixel={setEmptyPixel}
                                            sortedPixelsGuard={sortedPixelsGuard}
                                            setSortedPixelsGuard={setSortedPixelsGuard}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={styles['footer']}>
                    {onInvalidated && (
                        <Button style={{ width: 150 }} onClick={handleBack}>
                            BACK
                        </Button>
                    )}
                    {onCancel && (
                        <Button style={{ width: 150 }} onClick={handleCancel}>
                            CANCEL
                        </Button>
                    )}
                    <Button style={{ width: 150 }} onClick={handleSaveGoogleManagement}>
                        SAVE
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ProtectionManagementV2;

const OperationProgress = ({ sso }) => {
    return sso.requestSaveManagement || sso.services.googleV2.state.requestingInvalidate ? <LinearProgress /> : <></>;
};

const ProtectionFeatureToggle = props => {
    const { title, subTitle, tooltip, ...restProps } = props;
    return (
        <div className={styles.protectionFeatureToggle}>
            <div>
                <IconWrapper className={styles.info} icon={<InfoOutlinedIcon style={{ height: 14, width: 14 }} />} tooltip={tooltip} />
                <div className={styles.protectionFeatureLabel}>
                    <div>{title}</div>
                    {subTitle && <div>{subTitle}</div>}
                </div>
            </div>
            <div className={styles.protectionFeatureSwitch}>
                <Switch {...restProps} type="checkbox" checkedLabel={'On'} uncheckedLabel={'Off'} />
            </div>
        </div>
    );
};
