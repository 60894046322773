import { settings as types } from '.';

export const settingsRequestFailed = payload => ({
    type: types.SETTINGS_REQUEST_FAILED,
    payload,
});

export const setGoogleScopes = payload => ({
    type: types.SET_GOOGLE_SCOPES,
    payload,
});

export const setRTIToken = payload => ({
    type: types.SET_RTI_TOKEN,
    payload,
});

export const setServices = payload => ({
    type: types.SET_SERVICES,
    payload,
});

export const settingsDataLoading = () => ({
    type: types.SETTINGS_DATA_LOADING,
});

export const setIsLoading = payload => ({
    type: types.SET_IS_LOADING,
    payload,
});

export const setUpdatedLanguage = payload => ({
    type: types.SET_UPDATED_LANGUAGE,
    payload,
});

export const setNetworkFeatures = payload => ({
    type: types.SET_NETWORK_FEATURES,
    payload,
});

export const setNetworkFeatureSettings = payload => ({
    type: types.SET_NETWORK_FEATURE_SETTINGS,
    payload,
});

export const setServingDomain = payload => ({
    type: types.SET_SERVING_DOMAIN,
    payload,
});

export const setDedicatedWLDomains = payload => ({
    type: types.SET_DEDICATED_WLDOMAINS,
    payload,
});

export const setIsEnabledAffiliateFraud = payload => ({
    type: types.SET_IS_ENABLED_AFFILIATE_FRAUD,
    payload,
});

export const setIsGAConnertorIsEnabled = payload => ({
    type: types.SET_IS_GA_CONNECTOR_ENABLED,
    payload,
});

export const setPxgVersion = payload => ({
    type: types.SET_PXG_VERSION,
    payload,
});

export const setDedicatedWLD = payload => ({
    type: types.SET_DEDICATED_WLD,
    payload,
});

export const setSelectedLicense = payload => ({
    type: types.SET_CURRENT_LICENSE,
    payload,
});
