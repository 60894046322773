import { httpRequest, methods } from '../http';
/* @ts-ignore */
import config from '@config';
const CHEQ_API_BASE_URL = `${config.baseAPI}/api/v2`;

type CreateOrUpdateReqBody = {
    email: string;
    name: string;
    accountId: number;
    networkId: number;
};

export async function createOrUpdateZendeskUser(user: CreateOrUpdateReqBody) {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_BASE_URL}/zendesk/create-or-update`, method: methods.post, body: user });
    return response;
}

export async function getChatLoginToken(user: CreateOrUpdateReqBody) {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_BASE_URL}/zendesk/auth/chat-token`, method: methods.post, body: user });
    return response;
}
