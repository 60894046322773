import React from 'react';
import styles from './style.module.scss';
import { formatToK } from '@utils/dashboard';
import { DashboardItem } from '@widgets';

export const CalculatedReplacedVisits = ({ isLoading, dashboardCM, blockedUsers, returnRate }) => {
    const fixedValueReturnRate = blockedUsers === 0 ? 0 : returnRate;
    const calculatedReplacedVisits = Math.round(blockedUsers * fixedValueReturnRate);
    return (
        <DashboardItem
            isLoading={isLoading}
            data-test-selector="calculated replaced visits"
            className={styles.calculatedReplacedVisitsContainer}
            title={<div className={styles.title}>{dashboardCM.get('CalculatedReplacedVisits.Title')}</div>}
            info={{ title: dashboardCM.get('CalculatedReplacedVisits.Title'), content: dashboardCM.get('CalculatedReplacedVisits.Description') }}
        >
            <div className={styles.content}>
                <div className={styles.replacedVisitsRow}>
                    <span className={styles.blockedUsersValue}>{formatToK(blockedUsers)}</span>
                    &nbsp; &nbsp;
                    <span>{dashboardCM.get('CalculatedReplacedVisits.BlockedUsers')}</span>
                </div>
                <div className={styles.replacedVisitsRow}>
                    <span className={styles.blockedUsersValue}>{fixedValueReturnRate.toLocaleString()}</span>
                    &nbsp; &nbsp;
                    <p className={styles.returnRate}>{dashboardCM.get('CalculatedReplacedVisits.ReturnRate')}</p>
                </div>
                <hr className={styles.separator} />
                <div className={styles.replacedVisitsRow}>
                    <span className={styles.blockedUsersValue}>{formatToK(calculatedReplacedVisits).toLocaleString()}</span>
                    &nbsp; &nbsp;
                    <span>{dashboardCM.get('CalculatedReplacedVisits.Title')}</span>
                </div>
                <div className={styles.replacedVisitsRow}>{dashboardCM.get('CalculatedReplacedVisits.ContentInfo')}</div>
            </div>
        </DashboardItem>
    );
};
