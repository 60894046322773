import React, { useEffect } from 'react';
import CheqUI from '@combotag/cheq-ui';
import HeatmapsTable from './heatmaps-table';
import ViewHeatmap from './view-heatmap';
import { isEmpty } from 'underscore';

const { useMeiosis, useActions } = CheqUI.React.Meiosis;

const Heatmaps = () => {
    const actions = useActions();
    const { viewMode } = useMeiosis(actions.heatmaps.buildStream);

    useEffect(() => {
        actions.heatmaps.getHeatmaps();
        return () => {
            actions.heatmaps.unMount();
        };
    }, []);

    return <div style={{ height: 567, background: 'rgba(20, 23, 44, 0.5)' }}>{isEmpty(viewMode) ? <HeatmapsTable /> : <ViewHeatmap viewMode={viewMode} />}</div>;
};

export default Heatmaps;
