import { DashboardItem, ScaleBar, OverflowTip } from '@widgets';
import Tooltip from '@components/widgets/tooltip';
import { calcPercentage, toLocaleString, parseInvalidUserActivityData } from '@utils/dashboard';
import { Table, TableRow, TableCell, TableHead, TableBody, TableContainer } from '@material-ui/core';

import { useState, useMemo } from 'react';
import WebIcon from '@material-ui/icons/Web';
import styles from './landing-pages.module.scss';

import { useQuery } from 'react-query';
import * as api from '@services/api/dashboard';

const LandingPages = ({ limit = 5, handleViewALl, selectedChannel, selectedPlatformsGroup, options, cm: dashboardCM }) => {
    const { data, isLoading, isError } = useQuery([api.paidMarketingKeys.INVALID_VISITS_BY_URL, options], () => api.getInvalidVisitsByUrl(options));

    const [columns] = useState({
        url: dashboardCM.get('PageName'),
        invalidClicks: dashboardCM.get('InvalidVisits'),
        ofAllVisits: `% ${dashboardCM.get('OfAllVisits')}`,
    });

    const parsedData = useMemo(() => {
        return parseInvalidUserActivityData(data, ['url'], selectedPlatformsGroup?.platforms, selectedChannel);
    }, [data, selectedPlatformsGroup, selectedChannel]);

    const [topLimit] = parsedData;

    const columnWidth = {
        url: 40,
        invalidClicks: 50,
        ofAllVisits: 10,
    };

    const renderColumns = columns => {
        return (
            <TableRow>
                {Object.entries(columns).map(([key, label]) => (
                    <TableCell width={columnWidth[key] + '%'} key={'landingPagaes' + key} variant="head">
                        {label}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    const renderRow = (row, index) => {
        const scaleValue = index === 0 ? 100 : calcPercentage(row['invalidClicks'], topLimit['invalidClicks']);
        const percentage = calcPercentage(row['invalidClicks'], row['total']);

        return (
            <TableRow hover key={`pm-landingpage-row-${index}`}>
                <TableCell>
                    <div className={styles.url}>
                        <WebIcon className={styles.icon} />
                        <div className={styles.urlText}>
                            <OverflowTip>{row.url}</OverflowTip>
                        </div>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={styles.invalidClicks}>
                        <ScaleBar width={scaleValue} className={styles.scaleBar} />
                        <div>{toLocaleString(row.invalidClicks)}</div>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={styles.allVisits}>{percentage ? percentage + '%' : dashboardCM.get('NoDataToDisplay')}</div>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <DashboardItem
            isLoading={isLoading}
            isError={isError}
            skeletonClassName={styles.skeletonLoader}
            data-test-selector="paid-marketing-landing pages"
            key="landingPages"
            className={styles.landingPages}
            title={dashboardCM.get('LandingPages.dnaTitle')}
            info={{
                title: dashboardCM.get('LandingPages.dnaTitle'),
                content: dashboardCM.get('LandingPages.Description'),
            }}
        >
            <TableContainer style={{ padding: '12px' }}>
                <Table>
                    <TableHead>{renderColumns(columns)}</TableHead>
                    <TableBody>{parsedData.slice(0, limit).map(renderRow)}</TableBody>
                </Table>
            </TableContainer>
        </DashboardItem>
    );
};

export {LandingPages};
