import { CHEQ_ACQUISITION_ROUTE, CHEQ_ANALYTICS_ROUTE, CHEQ_SLP_ROUTE, CHEQ_DEFEND } from '../../utils/common';

export type routeItemType = {
    id: number;
    name: string;
    route: string;
};

export const routes = {
    CustomerAcquisitionSecurity: [
        {
            id: 1,
            name: 'Dashboard',
            route: `/${CHEQ_ACQUISITION_ROUTE}/dashboard`,
        },
        {
            id: 2,
            name: 'Analytics',
            route: `/${CHEQ_ACQUISITION_ROUTE}/reports`,
        },
        {
            id: 3,
            name: 'DataTools',
            route: `/${CHEQ_ACQUISITION_ROUTE}/data-exports`,
        },
        {
            id: 4,
            name: 'PolicyManagement',
            route: `/${CHEQ_ACQUISITION_ROUTE}/policy-management`,
        },
        {
            id: 5,
            name: 'Settings',
            route: `/${CHEQ_ACQUISITION_ROUTE}/settings`,
        },
    ],
    OnSiteSecurity: [
        {
            id: 1,
            name: 'Dashboard',
            route: `/${CHEQ_DEFEND}/dashboard`,
        },
        {
            id: 2,
            name: 'Analytics',
            route: `/${CHEQ_DEFEND}/reports`,
        },
        {
            id: 3,
            name: 'DataTools',
            route: `/${CHEQ_DEFEND}/data-exports`,
        },
        {
            id: 4,
            name: 'PolicyManagement',
            route: `/${CHEQ_DEFEND}/policy-management`,
        },
        {
            id: 5,
            name: 'Settings',
            route: `/${CHEQ_DEFEND}/settings`,
        },
    ],
    MarketingIntelligenceSecurity: [
        {
            id: 1,
            name: 'Dashboard',
            route: `/${CHEQ_ANALYTICS_ROUTE}/dashboard`,
        },
        {
            id: 2,
            name: 'Analytics',
            route: `/${CHEQ_ANALYTICS_ROUTE}/reports`,
        },
        {
            id: 3,
            name: 'DataTools',
            route: `/${CHEQ_ANALYTICS_ROUTE}/data-exports`,
        },
        {
            id: 4,
            name: 'Settings',
            route: `/${CHEQ_ANALYTICS_ROUTE}/settings`,
        },
    ],
    PrivacyComplianceEnforcement: [
        {
            id: 1,
            name: 'Dashboard',
            route: '/privacy-compliance-enforcement/dashboard',
        },
    ],
    LeadProtection: [
        {
            id: 1,
            name: 'Dashboard',
            route: `/${CHEQ_SLP_ROUTE}/dashboard`,
        },
        {
            id: 2,
            name: 'Analytics',
            route: `/${CHEQ_SLP_ROUTE}/reports`,
        },
        {
            id: 3,
            name: 'DataTools',
            route: `/${CHEQ_SLP_ROUTE}/data-exports`,
        },
        {
            id: 4,
            name: 'PolicyManagement',
            route: `/${CHEQ_SLP_ROUTE}/policy-management`,
        },
        {
            id: 5,
            name: 'Settings',
            route: `/${CHEQ_SLP_ROUTE}/settings`,
        },
    ],
};
