import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { VisibilityOff, VisibilityOutlined } from '@material-ui/icons';
import CopyIcon from '../../../../../svgs/copy';
import copy from 'clipboard-copy';
import ToolTip from 'widgets/tool-tip';
/* @ts-ignore */
import { useContentManager } from '@hooks';

interface InputProps<T> {
    value: T;
    onChange: (value: T) => void;
    placeholder?: string;
    testSelector?: string;
    defaultType?: string;
    disabled?: boolean;
    showEyeIcon?: boolean;
    marketoXSignature?: string;
}

export const Input: React.FC<InputProps<string | number>> = ({
    value,
    onChange,
    testSelector,
    placeholder,
    disabled = false,
    showEyeIcon = false,
    defaultType,
    marketoXSignature,
}) => {
    const cm = useContentManager();
    const [inputValue, setInputValue] = useState(value);
    const [showPass, setShowPass] = useState(false);
    const [type, setType] = useState(showEyeIcon ? 'password' : 'text');
    const [copied, setCopied] = useState(false);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        onChange(newValue);
    };

    const onEyeIconClicked = () => {
        setType(type === 'text' ? 'password' : 'text');
        setShowPass(!showPass);
    };

    const handleCopy = () => {
        if (marketoXSignature) {
            copy(marketoXSignature);
            setCopied(true);
        }
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <div style={{ display: 'flex' }}>
            <input
                disabled={disabled}
                className={disabled ? `${styles.input} ${styles.disabled}` : `${styles.input}`}
                type={defaultType ? defaultType : type}
                value={inputValue}
                onChange={handleInputChange}
                placeholder={placeholder}
                data-test-selector={testSelector}
            />
            {showEyeIcon && (
                <div className={styles.showSecret}>
                    <div onClick={onEyeIconClicked} className={styles.eyeIcon}>
                        {showPass ? <VisibilityOutlined /> : <VisibilityOff />}
                    </div>
                    <div style={{ paddingLeft: '1rem' }}>
                        <ToolTip content={copied ? cm.get('Copied') : cm.get('CopyToClipboard')}>
                            <div>
                                <CopyIcon onClick={handleCopy} className={styles.icon} />
                            </div>
                        </ToolTip>
                    </div>
                </div>
            )}
        </div>
    );
};
