import { ScaleBar, DashboardItem, OverflowTip } from '@widgets';
import { calcPercentage, toLocaleString, parseInvalidUserActivityData } from '@utils/dashboard';
import { Table, TableRow, TableCell, TableHead, TableBody, TableContainer } from '@material-ui/core';

import React, { useState, useMemo } from 'react';
import Tooltip from '@components/widgets/tooltip';
import styles from './campaigns.module.scss';
import thirdPartyIcons from '@components/svgs/third-parties';

import { useContentManager } from '@hooks';

import { useQuery } from 'react-query';
import * as api from '@services/api/dashboard';

const Campaigns = ({ limit = 5, handleViewAll, selectedChannel, selectedPlatformsGroup, options }) => {
    const dashboardCM = useContentManager(cm => cm.dashboard);

    const { data, isLoading, isError } = useQuery([api.paidMarketingKeys.INVALID_VISITS_BY_CAMPAIGN, options], () => api.getInvalidVisitsByCampaigns(options));

    const [columns] = useState({
        source: dashboardCM.get('Source'),
        campaign: dashboardCM.get('CampaignName'),
        medium: dashboardCM.get('Medium'),
        invalidClicks: dashboardCM.get('InvalidVisits'),
        ofAllVisits: `% ${dashboardCM.get('OfAllVisits')}`,
    });

    const columnWidth = {
        source: 20,
        campaign: 25,
        medium: 20,
        invalidClicks: 25,
        ofAllVisits: 10,
    };

    const parsedData = useMemo(() => {
        return parseInvalidUserActivityData(data, ['campaign', 'source'], selectedPlatformsGroup?.platforms, selectedChannel);
    }, [data, selectedPlatformsGroup, selectedChannel]);

    const [topLimit] = parsedData;

    const renderColumns = columns => (
        <TableRow>
            {Object.entries(columns).map(([key, label]) => (
                <TableCell width={columnWidth[key] + '%'} key={key} variant="head">
                    {label}
                </TableCell>
            ))}
        </TableRow>
    );

    const renderRow = (row, index) => {
        let Icon = Object.keys(thirdPartyIcons).find(icon => icon.toLowerCase() === row.source.toLowerCase());
        const percentage = calcPercentage(row['invalidClicks'], row['total']);
        const scaleValue = index === 0 ? 100 : calcPercentage(row['invalidClicks'], topLimit['invalidClicks']);
        Icon = thirdPartyIcons[Icon];
        return (
            <TableRow hover key={`pm-campaign-row-${index}`}>
                <TableCell>
                    <div className={styles.source}>
                        {Icon && <Icon width={14} height={14} />}
                        <OverflowTip>{row.source}</OverflowTip>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={styles.campaign}>
                        <OverflowTip>{row.campaign}</OverflowTip>
                    </div>
                </TableCell>
                <TableCell>{row.medium}</TableCell>
                <TableCell>
                    <div className={styles.invalidVisits}>
                        <ScaleBar width={scaleValue} className={styles.scaleBar} />
                        <span>{toLocaleString(row.invalidClicks)}</span>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={styles.invalidClicks}>{percentage ? percentage + '%' : dashboardCM.get('NoDataToDisplay')}</div>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <DashboardItem
            isLoading={isLoading}
            isError={isError}
            skeletonClassName={styles.skeletonLoader}
            data-test-selector="paid-marketing-campaigns"
            action={{
                label: dashboardCM.get('ViewAll'),
                callBack: handleViewAll,
            }}
            key="campaigns"
            className={styles.campaigns}
            title={dashboardCM.get('Campaigns.Title')}
            info={{
                title: dashboardCM.get('Campaigns.Title'),
                content: dashboardCM.get('Campaigns.Description'),
            }}
        >
            <TableContainer style={{ padding: '12px' }}>
                <Table dense stickyHeader>
                    <TableHead>{renderColumns(columns)}</TableHead>
                    <TableBody>{parsedData.slice(0, limit).map(renderRow)}</TableBody>
                </Table>
            </TableContainer>
        </DashboardItem>
    );
};

export default Campaigns;
