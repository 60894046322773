import Base from './index';
import { _String } from 'chopin-methods';
import config from '../../../config';
import openSocket from 'socket.io-client';

const { baseAPI } = config;

export default class extends Base {
    constructor(name, baseRoute = _String.camelToSnake(name)) {
        super(name, baseRoute);
        this.state.subscribed = false;

        this._baseRoute = baseRoute;
    }

    subscribe() {
        if (this.state.subscribed) return;
        this.update({ subscribed: true });

        this.socket = openSocket.connect(`${baseAPI}/${this._baseRoute}`, { transports: ['websocket'] });
        ['error'].forEach(r => this.socket.on(r, console.error));
        this.socket.on('connect', () => {
            //console.info(`io ${this.name} connected`);
        });

        return this.unsubscribe.bind(this);
    }

    unsubscribe() {
        if (!this.state.subscribed) console.warn('realtime-dupe-unsubscribe');

        this.update({ subscribed: false });
        this.socket && this.socket.disconnect();
    }

    on(event, cb) {
        this.socket && this.socket.on(event, cb);
        return this;
    }
}
