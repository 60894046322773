import moment from 'moment-timezone';
import { analytics as actions } from '../actions';

const LOCAL_KEY_REPORT_HISOTRY = 'reportHistory';
const QUOTA_EXCEEDED_ERR = 'QuotaExceededError';

const analyticsInitialState = {
    summaryReportSettings: {
        isDirty: false,
        breakdownBy: undefined,
        browser: undefined,
        campaign: undefined,
        affiliates: undefined,
        domain: undefined,
        country: undefined,
        dateRange: { label: 'Today', value: 'today' },
        device: undefined,
        groupBy: undefined,
        includePPCCrawlers: undefined,
        policyToggleA: true,
        includePolicy: false,
        policyManagement: false,
        reportType: {
            label: 'Summary',
            value: 'summary',
        },
        source: undefined,
    },
    reportHistory: [],
    isLoading: false,
    err: null,
    currentReport: {},
    plaformOrigin: [],
    allPlatforms: {},
    platformStatus: undefined,
};

export default (state = analyticsInitialState, action) => {
    switch (action.type) {
        case actions.UPDATE_REPORT_HISTORY: {
            const { payload: reportHistory } = action;
            return {
                ...state,
                reportHistory: [...state.reportHistory, ...reportHistory],
            };
        }
        case actions.ANALYTICS_DATA_LOADING: {
            return { ...state, isLoading: true, err: null };
        }
        case actions.ANALYTICS_PLATFORMS_UPDATED: {
            const { payload: allPlatforms } = action;
            return {
                ...state,
                currentReport: {
                    ...state.currentReport,
                    advancedFiltering: {
                        ...state.currentReport.advancedFiltering,
                        allPlatforms: allPlatforms.data,
                        platformOrigin: allPlatforms.data.map(platformGroups => platformGroups.platforms).flat(1),
                    },
                },
                isLoading: false,
            };
        }
        case actions.ANALYTICS_DATA_UPDATED: {
            const { payload: currentReport } = action;
            const { timezone = '' } = currentReport;
            currentReport.reportDate = moment.tz(timezone).format('YYYY-MM-DD HH:mm');
            const reportHistory = state.reportHistory.find(item => item.queryId === currentReport.queryId) ? state.reportHistory : [...state.reportHistory, currentReport];
            isLocalStorageAvailable(reportHistory);
            return { ...state, currentReport, isLoading: false, reportHistory };
        }
        case actions.ANALYTICS_REQUEST_FAILED: {
            const { payload: err } = action;
            // if (err.isCancelled) return state;
            return { ...state, err, isLoading: false };
        }
        case actions.ANALYTICS_SET_SUMMARY_REPORT_SETTINGS: {
            return {
                ...state,
                summaryReportSettings: { ...state.summaryReportSettings, ...action.payload, isDirty: true },
            };
        }
        case actions.LOAD_REPORT_HISTORY: {
            const { payload: reportItem } = action;
            const currentReport = state.reportHistory.find(item => item === reportItem);
            return { ...state, currentReport };
        }
        case actions.DELETE_REPORT_HISTORY: {
            const { payload: reportItem } = action;
            const reportHistory = state.reportHistory.filter(item => item !== reportItem);
            let currentReport = JSON.stringify(reportItem.reportResults) === JSON.stringify(state.currentReport.reportResults) ? {} : state.currentReport;
            localStorage.setItem(LOCAL_KEY_REPORT_HISOTRY, JSON.stringify(reportHistory));
            return { ...state, reportHistory, currentReport };
        }
        case actions.ANALYTICS_CLEAR_SUMMARY_REPORT_SETTINGS: {
            return {
                ...state,
                summaryReportSettings: {
                    ...analyticsInitialState.summaryReportSettings,
                    includePolicy: state.summaryReportSettings.includePolicy,
                    policyManagement: state.summaryReportSettings.policyManagement,
                    policyToggleA: state.summaryReportSettings.policyToggleA,
                },
            };
        }
        case actions.UPDATE_REPORT_TYPE: {
            return { ...state, summaryReportSettings: { ...state.summaryReportSettings, reportType: action.payload } };
        }
        case actions.UPDATE_SUMMARY_REPORT_SETTINGS: {
            return { ...state, summaryReportSettings: { ...state.summaryReportSettings, ...action.payload } };
        }
        default:
            return state;
    }
};

const isLocalStorageAvailable = reportHistory => {
    /*************************************************************
     Since localStorage is limited to 5MB, the function will
     check if it is possible to write to localStorage, and if not,
     the first report added will be deleted until space is available.
     **************************************************************/

    // eslint-disable-next-line no-constant-condition
    while (true) {
        try {
            localStorage.setItem(LOCAL_KEY_REPORT_HISOTRY, JSON.stringify(reportHistory));
            break;
        } catch (e) {
            if ((e.code === 22 || e.code === 1014 || e.name === QUOTA_EXCEEDED_ERR) && localStorage?.length) {
                const reportHistoryLocalStorage = JSON.parse(localStorage.getItem(LOCAL_KEY_REPORT_HISOTRY));

                // A case where one report is larger than 5 MB
                if (!reportHistoryLocalStorage.length) break;

                // Delete the first report added to the localStorage
                reportHistoryLocalStorage.shift();
                try {
                    localStorage.setItem(LOCAL_KEY_REPORT_HISOTRY, JSON.stringify(reportHistoryLocalStorage));
                } catch (e) {
                    console.error(e);
                }

                // Delete the first report added to the reportHistory
                reportHistory.shift();
            } else {
                break;
            }
        }
    }
};
