import { FC } from 'react';
/* @ts-ignore */
import { useContentManager, useSplit } from '@hooks';
import { constants } from '../../../../utils/split.io';
import ApiIntegration from './api-integration';
import styles from './styles.module.scss';

const DataAndAnalytics: FC = () => {
    const cm = useContentManager();
    const { isOn: isApiIntegrationOn } = useSplit(constants.SPLIT_DATA_AND_ANALYTICS_API_INTEGRATION);

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{cm.get('ApiIntegration')}</div>
            </div>
            {isApiIntegrationOn && <ApiIntegration cm={cm} />}
        </div>
    );
};

export default DataAndAnalytics;
