import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import CustomTab from './custom.tab';
import styles from './style.module.css';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

const TabPanel = ({ children, ...props }) => (
    <Typography component="div" role="tabpanel" {...props}>
        {children}
    </Typography>
);

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
    },
    tabs: { width: '150px' },
    panels: {
        backgroundColor: theme.palette.background.paper,
        height: '100%',
    },
    container: {
        alignItems: 'center',
    },
    indicator: {
        display: 'none',
    },
}));

export default function ({ defaultTab = '', onTabChange = t => t, panel = c => c, children = [], Header, Footer, fixed, style = {}, ...props }) {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        defaultTab = newValue;
        onTabChange(event, newValue);
    };

    const menuStyle = fixed ? { ...style, position: 'fixed', height: '100%', zIndex: 9 } : style;

    return (
        <div className={classes.root}>
            <Tabs
                value={defaultTab}
                onChange={handleChange}
                classes={{ flexContainer: classes.container, indicator: classes.indicator }}
                className={classes.tabs}
                style={menuStyle}
                {...props}
            >
                {Header && <Header key={'tabs-header'} />}
                {children.map(({ props }, idx) => (
                    <CustomTab key={idx} label={props.label} icon={props.icon} current={defaultTab} value={getValue({ props })} />
                ))}
                <Footer className={classes.footer} key={'tabs-footer'} />
            </Tabs>
            {children.map((Component, idx) => (
                <TabPanel
                    hidden={defaultTab !== getValue(Component)}
                    style={{ marginLeft: fixed ? '150px' : 0, width: '100%', height: '100%' }}
                    className={cx('tab-panel', classes.panels)}
                    value={getValue(Component)}
                    key={idx}
                    index={Header ? idx + 1 : idx}
                >
                    {panel ? panel(Component) : null}
                </TabPanel>
            ))}
        </div>
    );
}

const getValue = ({ props } = {}) => props.value || props.label.split(' ').join('_').toLowerCase();
