import React, { useEffect, useRef } from 'react';

import Highcharts from 'highcharts';
import { sortBy } from 'underscore';
import { LegendColor } from '@widgets';
import classNames from 'classnames/bind';
import { formatToK, getPercentage } from '@utils/common';
import styles from './bar-graph.module.css';
import { threatGroups as threatGroupsConstants } from '@utils/dashboard';

const cx = classNames.bind(styles);

export const BarGraph = ({ values, legend, limit, graphClasses, withHighlightedColumns = false, highlightedColumnsLabel, cm }) => {
    const graphRef = useRef(null);

    if (Array.isArray(values) && limit) values = sortBy(values, 'value').reverse().slice(0, limit);

    useEffect(() => {
        if (!Array.isArray(values) || !graphRef.current) return;

        const total = values.reduce((result, current) => (result += parseInt(current.value)), 0);

        const sortedValues = values.length > 0 ? values.sort((a, b) => b.value - a.value) : [];

        if (sortedValues.length === 0) {
            sortedValues.push({
                label: '',
                value: 0,
            });
        }
        const maxValueItem = sortedValues[0];
        const tickInterval = getTickInterval(maxValueItem.value);
        const maxValue = maxValueItem.value + (tickInterval - (maxValueItem.value % tickInterval)) + tickInterval;

        Highcharts.chart(graphRef.current, {
            chart: {
                type: 'column',
            },
            title: {
                text: '',
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        fontSize: '1.2rem !important',
                    },
                },
            },
            yAxis: {
                tickInterval,
                max: maxValue,
                title: {
                    text: null,
                },
                maxPadding: 0.2,
                labels: {
                    style: {
                        fontSize: '1rem !important',
                    },
                },
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    animation: false,
                },
                column: {
                    grouping: false,
                    borderWidth: 0,
                    enableMouseTracking: false,
                    pointWidth: 80,
                    dataLabels: {
                        formatter: function () {
                            const intValue = parseInt(this.y);
                            return `${intValue.toLocaleString()} (${Math.round(getPercentage(intValue, total))}%)`;
                        },
                    },
                },
            },
            series: [
                {
                    name: cm.get('ThreatTypes'),
                    dataLabels: {
                        enabled: true,
                        color: '#FFFFFF',
                        style: {
                            border: 0,
                            textOutline: 'none',
                        },
                        inside: false,
                    },
                    borderRadius: 4,
                    maxWidth: 100,
                    data: sortedValues.map(v => {
                        return {
                            name: v.label && v.threatGroupKey !== threatGroupsConstants.CUSTOM_RULES ? cm.get(`${v.label.replace(/ /g, '')}.Title`) : v.label,
                            color: v.color,
                            y: v.value,
                        };
                    }),
                },
                {
                    name: 'Not Protected Markers',
                    color: 'rgba(255,255,255,0.1)',
                    pointPadding: 0,
                    groupPadding: 0,
                    data: withHighlightedColumns
                        ? sortedValues.map(v => {
                              if (!v.protected) {
                                  return maxValue;
                              } else {
                                  return 0;
                              }
                          })
                        : [],
                },
            ],
            credits: {
                enabled: false,
            },
        });
    }, [cm, values, withHighlightedColumns]);

    if (values === undefined) return <></>;

    return (
        <div className={cx('component', 'flex-column')}>
            <div className={cx('vertical-bar-graph', !!graphClasses && [...graphClasses])} ref={graphRef} style={{ width: '100%', height: '100%' }}></div>
            <div className={cx('legend-row', 'flex-row', 'flex-wrap')}>
                {legend.map((l, i) => {
                    return (
                        <div key={i} className={cx('legend-item', 'flex-row')}>
                            <LegendColor color={l.color} /> {l.label} <b>{!!l.value && formatToK(l.value)}</b>
                        </div>
                    );
                })}
            </div>
            {withHighlightedColumns ? (
                <div className={cx('legend-row', 'flex-row', 'flex-wrap')}>
                    <div className={cx('legend-item', 'flex-row')}>
                        <LegendColor color="rgba(255,255,255,0.1)" />
                        {highlightedColumnsLabel}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

const getTickInterval = maxValue => {
    let result = 2000;
    if (maxValue >= 13000) result = 5000;
    if (maxValue >= 50000) result = 1000;
    if (maxValue >= 100000) result = 25000;
    if (maxValue >= 150000) result = 50000;
    if (maxValue <= 3000) result = 500;
    if (maxValue <= 1000) result = 200;
    if (maxValue <= 100) result = 20;
    if (maxValue <= 50) result = 10;
    return result;
};
