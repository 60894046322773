import { Dialog, IconButton, DialogActions, Button } from '@material-ui/core';

import { Close } from '@material-ui/icons';
import React from 'react';
import classNames from 'classnames/bind';
import { create } from 'react-modal-promise';
import styles from './dialog.module.scss';

const cx = classNames.bind(styles);

const DialogBox = ({ isOpen, onResolve, onReject, title, children, additional, userInteractive }) => {
    return (
        <Dialog style={{ zIndex: 150 }} open={isOpen} classes={{ paper: cx('container') }}>
            <div className={cx('title')}>
                <div>{title}</div>
                <IconButton className={cx('close-icon')} onClick={onReject}>
                    <Close />
                </IconButton>
            </div>
            <div className={cx('content-container')}>
                <div className={cx('content')}>{children}</div>
                {additional && <div className={cx('content')}>{additional}</div>}
            </div>

            {!!userInteractive && (
                <>
                    <DialogActions className={styles.buttonContainer}>
                        <Button className={styles.button} size="large" onClick={onReject} color="secondary">
                            {userInteractive.rejectText}
                        </Button>
                        <Button className={`${styles.button} ${styles.okButton}`} size="large" onClick={onResolve} color="secondary">
                            {userInteractive.resolveText}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export const showDialog = create(DialogBox);
