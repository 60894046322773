import React from 'react';
import styles from './style.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default props => {
    const { title } = props;
    return (
        <div className={cx('header')}>
            <div className={cx('title')}>{title}</div>
            {props.children}
            <div className={cx('profile-info')}>notifications, settings, profile</div>
        </div>
    );
};
