import React from 'react';
import { iconWrapperStyles } from './styles';
import Tooltip from '../tool-tip';
import IconButton from '@material-ui/core/IconButton';

export const IconWrapper = ({ icon, tooltip, className }) => {
    const defaultStyles = iconWrapperStyles();
    return (
        <Tooltip
            title={tooltip}
            // eslint-disable-next-line react/no-children-prop
            children={
                <IconButton
                    className={`${defaultStyles.root} ${className}`}
                    // eslint-disable-next-line react/no-children-prop
                    children={icon}
                />
            }
        />
    );
};
