import * as React from "react"

function PlatformsIcon(props) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            style={{marginBottom: 0}}
        >
            <circle cx={9} cy={9} r={8.5} stroke="#fff" />
            <path
                d="M13 4.5H5a1 1 0 00-1 1v5a1 1 0 001 1h8a1 1 0 001-1v-5a1 1 0 00-1-1zM7 13.5h4M9 11.5v2"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default PlatformsIcon;
