import React from "react";
import { useContentManager } from '@hooks';
import fileIcon from "../../assets/file.svg"
import styles from './noData.module.scss';

export default ({ message = 'NoReportsFound' }) => {
    const cm = useContentManager();
    return (
        <div className={styles.container} data-test-selector="exportables-no-data-container">
            <img src={fileIcon} alt="file"/>
            <span className={styles.label}>{cm.get(message)}</span>
        </div>
    );
};