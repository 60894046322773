import React, { useEffect } from 'react';

import CheqUI from '@combotag/cheq-ui';
import { ProtectionManager } from '../../../../widgets/protection-management';

const { Meiosis } = CheqUI.React;
const { useActions, useMeiosis } = Meiosis;

export function Base({ serviceName, columns, platformTypes, ...rest }) {
    const actions = useActions();
    const sso = useMeiosis(actions.sso.buildStream);
    const { ready, protectionFeatures: { [serviceName]: { isEnabled, linkedTags = [], unlinkedTags = [] } = {} } = {} } = ({} = sso);
    const { getProtectionFeatures, invertAudienceTag, invertAudienceService, deleteAudienceTag, addAudienceTag, isLoading } = actions.sso;

    useEffect(() => {
        getProtectionFeatures({ serviceName });
    }, [getProtectionFeatures, serviceName]);

    return (
        <ProtectionManager
            linkedTags={linkedTags}
            unlinkedTags={unlinkedTags}
            isEnabled={isEnabled}
            serviceName={serviceName}
            columns={columns}
            platformTypes={platformTypes}
            onDisableTag={invertAudienceTag}
            onDisable={invertAudienceService}
            onDelete={deleteAudienceTag}
            onAdd={addAudienceTag}
            isLoading={isLoading() || !ready}
            {...rest}
        />
    );
}
