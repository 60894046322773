import { ScaleBar, DashboardItem, OverflowTip } from '@widgets';
import { calcPercentage, sortBy, toLocaleString, parseInvalidUserActivityData } from '@utils/dashboard';
import { Table, TableRow, TableCell, TableHead, TableBody, TableContainer } from '@material-ui/core';

import React, { useState, useMemo } from 'react';
import Tooltip from '@components/widgets/tooltip';
import styles from './keywords.module.scss';

import { useQuery } from 'react-query';
import * as api from '@services/api/dashboard';

const Keywords = ({ limit = 10, handleViewALl, selectedChannel, selectedPlatformsGroup, options, cm: dashboardCM }) => {
    const { data, isLoading, isError } = useQuery([api.paidMarketingKeys.INVALID_VISITS_BY_KEYWORD, options], () => api.getInvalidVisitsByKeywords(options));

    const [columns] = useState({
        term: dashboardCM.get('Keyword'),
        invalidClicks: dashboardCM.get('InvalidVisits'),
        ofAllvisits: `% ${dashboardCM.get('OfAllVisits')}`,
    });

    const columnWidth = {
        term: 50,
        invalidClicks: 40,
        ofAllvisits: 10,
    };

    const parsedData = useMemo(() => {
        return parseInvalidUserActivityData(data, ['term'], selectedPlatformsGroup?.platforms, selectedChannel);
    }, [data, selectedPlatformsGroup, selectedChannel]);

    const [topLimit] = parsedData;

    const renderColumns = columns => {
        return (
            <TableRow>
                {Object.entries(columns).map(([key, label]) => (
                    <TableCell width={columnWidth[key] + '%'} key={'keyword' + key} variant="head">
                        {label}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    const renderRow = (row, index) => {
        const percentage = calcPercentage(row['invalidClicks'], row['total']);
        const scaleValue = index === 0 ? 100 : calcPercentage(row['invalidClicks'], topLimit['invalidClicks']);

        return (
            <TableRow hover key={`pm-keyword-row-${index}`}>
                <TableCell>
                    <div className={styles.term}>
                        <div className={styles.termText}>
                            <OverflowTip>{row.term}</OverflowTip>
                        </div>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={styles.invalidVisits}>
                        <ScaleBar width={scaleValue} className={styles.scaleBar} />
                        {toLocaleString(row.invalidClicks)}
                    </div>
                </TableCell>
                <TableCell>
                    <div className={styles.allVisits}>{percentage ? percentage + '%' : dashboardCM.get('NoDataToDisplay')}</div>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <DashboardItem
            isLoading={isLoading}
            isError={isError}
            skeletonClassName={styles.skeletonLoader}
            data-test-selector="paid-marketing-terms"
            action={{
                label: dashboardCM.get('ViewAll'),
                callBack: handleViewALl,
            }}
            key="terms"
            className={styles.keywords}
            title={dashboardCM.get('Term.Title')}
            info={{
                title: dashboardCM.get('Term.Title'),
                content: dashboardCM.get('Term.Description'),
            }}
        >
            <TableContainer style={{ padding: '12px' }}>
                <Table>
                    <TableHead>{renderColumns(columns)}</TableHead>
                    <TableBody>{parsedData.slice(0, limit).map(renderRow)}</TableBody>
                </Table>
            </TableContainer>
        </DashboardItem>
    );
};

export default Keywords;
