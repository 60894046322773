import React from 'react';
import summary from './summary'
import affiliate from './affiliate';

export default function (type) {
    const tables = {
        summary,
        affiliate
    };

    return tables[type]
}

