import ApproveGoogleAds from './ApproveGoogleAds';
import Button from '../../../../widgets/button/index';
import CheqUI from '@combotag/cheq-ui';
import ProtectionManagement from './protection-management';
import React, { useEffect, useState } from 'react';
import SubmitGoogleAdsId from './SubmitGoogleAdsId';
import styles from './style.module.scss';
import widgets from '../../../../widgets';

const { Widgets, Meiosis } = CheqUI.React;
const { Box } = Widgets;
const { useActions, useMeiosis } = Meiosis;
const { Stepper } = widgets;

export default () => {
    const actions = useActions();
    const sso = useMeiosis(actions.sso.buildStream);
    const { inviteGoogleId } = actions.sso;
    const [isGoogleIntegrated, setIsGoogleIntegrated] = useState(false);

    useEffect(() => {
        setIsGoogleIntegrated(sso?.ssos?.google?.integrated);
    }, []);

    let defaultSelected = 0;
    if (sso.ssos.google) {
        if (sso.ssos.google.status) {
            defaultSelected = sso.ssos.google.status === 'PENDING' ? 1 : 2;
        }
        if (sso.ssos.google.refresh_token || sso.ssos.google.is_sso) {
            defaultSelected = 2;
        }
    }

    return (
        <div className={styles.root}>
            <Stepper defaultSelected={defaultSelected}>
                {({ next, jumpTo }) => (
                    // eslint-disable-next-line
                    <div subtitle={'Submit Google Ads ID'}>
                        <SubmitGoogleAdsId
                            next={next}
                            jumpTo={jumpTo}
                            onSubmit={inviteGoogleId}
                            styles={styles}
                            onCancel={() => {
                                actions.dialog.hide();
                            }}
                        />
                    </div>
                )}
                {({ next, prev }) => {
                    return (
                        // eslint-disable-next-line
                        <div subtitle={'Approve Google Ads Access Request'}>
                            <ApproveGoogleAds next={next} prev={prev} styles={styles} />
                        </div>
                    );
                }}
                {({ next, prev, jumpTo }) => (
                    // eslint-disable-next-line
                    <div subtitle={'Protection Management'}>
                        {isGoogleIntegrated
                            ? <ProtectionManagement next={next} prev={prev} styles={styles} onCancel={actions.dialog.hide} />
                            : <ProtectionManagement next={next} prev={prev} styles={styles} onInvalidated={() => jumpTo(0)} />}
                    </div>
                )}
                {() => (
                    // eslint-disable-next-line
                    <div style={{ padding: 15 }} subtitle={'Setup Completed'}>
                        <Box className={styles.completed}>SETUP COMPLETED!</Box>
                        <Button style={{ width: 150 }} onClick={actions.dialog.hide}>
                            GOT IT!
                        </Button>
                    </div>
                )}
            </Stepper>
        </div>
    );
};
