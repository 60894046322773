import moment from 'moment';
import { parseDate } from './helpers';
import { licenses } from '../settings';

const reports = {
    [licenses.PaidMarketing.id]: {
        values: {
            PAID_MARKETING: 'PaidMarketing',
        },
    },
    [licenses.AdvancedAnalytics.id]: {
        values: {
            ADVANCED_REPORT: 'AdvancedReport',
        },
    },
};

// DATA AND ANALYTICS //
const dataAndAnalytics = {
    createDefaultState() {
        return {
            dateRange: { value: this.formOptions.dateRange[0].value, format: parseDate(this.formOptions.dateRange[0].details) },
            type: 'standard',
            format: 'CSVWithNames',
            paid: '',
            name: '',
            emails: [],
            ivt: 'ivt',
        };
    },
    parseForm(state) {
        const { dateRange, name, type, format, emails, paid, ivt } = state;
        const [startDate, endDate] = dateRange.format;

        const parsedState = {
            startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
            endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
            type,
            name,
            format,
            filters: [paid, ivt].filter(Boolean),
            emails: emails.join(';'),
        };

        this.validateForm(parsedState);

        const filteredState = Object.entries(parsedState).reduce((acc, [key, value]) => {
            if (value) acc[key] = value;
            return acc;
        }, {});

        return filteredState;
    },
    validateForm(state) {
        const errors = [];
        // if (!state.emails) {
        //     errors.push('EmailsError');
        // }
        if (!state.name) {
            errors.push('ReportNameError');
        }
        if (!state.format) {
            errors.push('FormatError');
        }
        if (errors.length) {
            throw errors;
        }
    },
    formOptions: {
        format: [
            { value: 'CSV', label: 'CSV' },
            { value: 'CSVWithNames', label: 'CSVWithNames' },
            { value: 'TabSeparated', label: 'TSV' },
            { value: 'TabSeparatedWithNames', label: 'TSVWithNames' },
        ],
        dateRange: [
            {
                label: 'Today',
                value: 'today',
                details: { type: 'days', range: 0 },
            },
            {
                label: 'Yesterday',
                value: 'yesterday',
                details: { type: 'days', range: 1 },
            },
            {
                label: 'Last7Days',
                value: 'last7days',
                details: { type: 'days', range: 7 },
            },
            {
                label: 'Last30Days',
                value: 'last30days',
                details: { type: 'days', range: 30 },
            },
            {
                label: 'Last3Months',
                value: 'last3months',
                details: { type: 'months', range: 3 },
            },
            {
                label: 'Last6Months',
                value: 'last6months',
                details: { type: 'months', range: 6 },
            },
            {
                label: 'Custom',
                value: 'Custom',
            },
        ],
    },
};

// PAID MARKETING //
const paidMarketing = {
    createDefaultState(lastMonth) {
        return {
            startDate: moment(lastMonth).startOf('M').format(),
            endDate: moment(lastMonth).endOf('M').format(),
            name: '',
            emails: [],
        };
    },
    formOptions: {
        format: [
            { value: 'CSV', label: 'CSV' },
            { value: 'CSVWithNames', label: 'CSVWithNames' },
            { value: 'TabSeparated', label: 'TSV' },
            { value: 'TabSeparatedWithNames', label: 'TSVWithNames' },
        ],
    },
    parseForm(state) {
        const { startDate, endDate, emails, name = '' } = state;

        const parsedState = {
            startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
            endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
            type: 'limited',
            filters: ['ivt'],
            format: 'CSV',
            name,
            emails: emails.join(';'),
        };

        const filteredState = Object.entries(parsedState).reduce((acc, [key, value]) => {
            if (value) acc[key] = value;
            return acc;
        }, {});

        return filteredState;
    },
};

const createLicenseOptionsMenu = networkFeatures => {
    const optionsMenu = networkFeatures.reduce((acc, feature) => {
        if (reports[feature.id]) {
            const values = Object.values(reports[feature.id].values);
            acc.push(...values);
        }
        return acc;
    }, []);

    let defaultOption = networkFeatures.find(feature => feature.id === licenses.AdvancedAnalytics.id);
    defaultOption = defaultOption ? reports[licenses.AdvancedAnalytics.id].values.ADVANCED_REPORT : reports[licenses.PaidMarketing.id].values.PAID_MARKETING;

    return [optionsMenu, defaultOption];
};

const setDefaultReport = networkFeatures =>
    networkFeatures.find(feature => feature.id === licenses.AdvancedAnalytics.id)
        ? reports[licenses.AdvancedAnalytics.id].values.ADVANCED_REPORT
        : reports[licenses.PaidMarketing.id].values.PAID_MARKETING;

export const onDemand = { dataAndAnalytics, paidMarketing, setDefaultReport, reports, createLicenseOptionsMenu };
