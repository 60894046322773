import { HeaderTabs } from './components';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardStatusMessage } from '@widgets';

import { getSubHeader } from '@utils/dashboard';

import styles from './dashboard.module.scss';

import { useContentManager } from '@hooks';
import { setFilterOptions } from '@redux/actions/dashboard';

import Filter from './components/filter';
import VisitsSummary from './components/visits-summary';
import InvalidVisitsInsights from './components/invalid-visits-insights';
import UserActivity from './components/user-activity';
import Progress from '@components/widgets/progress';
import mixpanel from '@services/mixpanel';
import { setDashboardDisplayOption, setSelectedPlatformsGroup } from '../../../redux/actions/dashboard';
import { getPixelGuardNetworkStatus } from '../../../services/api';
import Header from "../../../components/containers/header";
import { useSplit } from '@hooks';
import { constants } from '@utils/split.io';

const Dashboard = () => {
    const { isBlockingMode, filterOptions, settingsLoading, selectedPlatformsGroup, selectedDisplayOption,policyToggleOptions } = useSelector(state => state.dashboard);
    const { isOn: showPolicyToggle } = useSplit(constants.SPLIT_POLICY_MANAGEMENT_TOGGLE);
    const { isOn: policyManagement } = useSplit(constants.SPLIT_POLICY_MANAGEMENT);

    const [currentPlatformsGroup, setCurrentPlatformsGroup] = useState(selectedPlatformsGroup?.group);
    const [currentDisplayOption, setCurrentDisplayOption] = useState(selectedDisplayOption);
    delete filterOptions.policyToggleDA;
    delete filterOptions.policyToggleOSC;
    delete filterOptions.policyToggleA;
    filterOptions.policyTogglePM = policyToggleOptions.policyTogglePM
    const [options, setOptions] = useState(filterOptions);
    const [pixelGuardIntegratedStatus, setPixelGuardIntegratedStatus] = useState({ isPixelGuardIntegrated: false });

    const dispatch = useDispatch();
    const cm = useContentManager();

    const dashboardCM = useContentManager(cm => cm.dashboard);
    const { visiblePlatformsGroups = {} } = useSelector(state => state.dashboard);

    const dashboardRef = useRef();
    const onScroll = () => {
        if (dashboardRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = dashboardRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                mixpanel.postEvent("Dashboard bottom view");
            }
        }
    };

    useEffect(() => getPixelGuardNetworkStatus().then(setPixelGuardIntegratedStatus), []);

    useEffect(() => {
        setOptions(prevState => ({
            ...prevState,
            policyManagement,
            includePolicy: showPolicyToggle,
        }));
        dispatch(setFilterOptions({
            policyManagement,
            includePolicy: showPolicyToggle,
        }));
    }, [policyManagement, showPolicyToggle]);

    useEffect(() => {
        setCurrentPlatformsGroup(selectedPlatformsGroup?.group);
        let platforms = [];
        if (selectedPlatformsGroup?.group.toLowerCase().includes('all')) {
            visiblePlatformsGroups.map(item => {
                item.platforms.forEach(platform => {
                    platforms.push(platform);
                });
            });
        }

        setOptions(prevState => ({ ...prevState, platform: selectedPlatformsGroup?.group, platforms }));
        dispatch(setFilterOptions({ platform: selectedPlatformsGroup?.group, platforms }));
    }, [selectedPlatformsGroup?.group]);

    useEffect(() => {
        dispatch(setDashboardDisplayOption({ selectedDisplayOption: currentDisplayOption, isPixelGuardIntegrated: pixelGuardIntegratedStatus.isPixelGuardIntegrated }));
        dispatch(setSelectedPlatformsGroup(visiblePlatformsGroups[0]));
    }, [currentDisplayOption, pixelGuardIntegratedStatus]);

    const buildMixpanelObjectAndSend = (action, options) => {
        const dateRange = options.dateRange.startDate ? 'Custom' : options.dateRange;
        const body = {
            'Dashboard view type': action,
            'Protected filter': currentDisplayOption,
            'Channel filter': options.channelName ? options.channelName : 'All Channels',
            'Period filter': dateRange,
            'Start date filter': dateRange === 'Custom' ? options.dateRange.startDate : '',
            'End date filter': dateRange === 'Custom' ? options.dateRange.endDate : '',
            'Include known crawlers filter': options.includePPCCrawlers ? 'True' : 'False',
            'Platform Filter': !options.platform?.toLowerCase().includes('all') ? options.platform : '',
        };
        mixpanel.postEvent('Dashboard view', body);
    };

    const handleRefresh = (action, state) => {
        buildMixpanelObjectAndSend(action, state);
        setOptions(state);
        dispatch(setFilterOptions(state));
    };

    if (settingsLoading) return <Progress />;

    if (performance.navigation.type === 1) {
        const object = {
            'Page name': 'Dashboard',
            'Navigation type': 'Refresh',
        };

        mixpanel.postEvent('PageView', object);
    }

    return (
        <>
            <Header title={cm.get('Dashboard')} />
            <div className={styles.root} onScroll={onScroll} ref={dashboardRef}>
                <div className={styles.header}>
                    <HeaderTabs isBlockingMode={isBlockingMode} onChangeDisplayOption={setCurrentDisplayOption} currentDisplayOption={currentDisplayOption} />
                </div>
                <div className={styles.dataContainer}>
                    <DashboardStatusMessage isBlockingMode={isBlockingMode} statusText={getSubHeader(selectedDisplayOption, dashboardCM, isBlockingMode)} />
                    <Filter
                        setCurrentPlatformsGroup={setCurrentPlatformsGroup}
                        currentPlatformsGroup={currentPlatformsGroup}
                        state={filterOptions}
                        options={options}
                        handleRefresh={handleRefresh}
                    />

                    <>
                        <VisitsSummary options={options} />
                        <InvalidVisitsInsights options={options} />
                        <UserActivity options={options} />
                    </>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
