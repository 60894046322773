import moment from 'moment-timezone';
import json2csv from 'json2csv';

const getRequestObject = (data, platformsGroups) => {
    let platformsGroupsList;
    if (data.platformStatus === undefined) {
        data.platformOrigin
            ? (platformsGroupsList = data.platformOrigin.map(platformsGroup => platformsGroup.value))
            : (platformsGroupsList = platformsGroups.data.map(platformsGroup => platformsGroup.group));
    } else {
        switch (data.platformStatus.map(getSelectValue)[0]) {
            case 'protected':
                platformsGroupsList = platformsGroups.data.filter(platformsGroup => platformsGroup.protected).map(platformsGroup => platformsGroup.group);
                break;
            case 'monitored':
                platformsGroupsList = platformsGroups.data.filter(platformsGroup => !platformsGroup.protected).map(platformsGroup => platformsGroup.group);
                break;
            default:
        }
    }
    const {
        reportType,
        dateRange,
        breakdownBy = {},
        channel = [],
        groupBy = [],
        country = [],
        browser = [],
        campaign = [],
        device = [],
        source = [],
        affiliates = [],
        medium = [],
        content = [],
        eventType = [],
        eventName = [],
        url = [],
        includePPCCrawlers = { value: false },
        policyToggleA = true,
        includePolicy = false,
        policyManagement = false,
        platformOrigin = [],
        platformStatus = [],
        domain = [],
        emailDomains = [],
        threatTypes = [],
    } = data;
    return {
        includePPCCrawlers: getSelectValue(includePPCCrawlers),
        policyToggleA: getSelectValue(policyToggleA),
        includePolicy: getSelectValue(includePolicy),
        policyManagement: getSelectValue(policyManagement),
        reportType: getSelectValue(reportType),
        dateRange: getDateObject(dateRange || {}),
        breakdownBy: getSelectValue(breakdownBy || {}),
        platformStatus: platformsGroupsList,
        groupBy: groupBy.map(getSelectValue),
        advancedFiltering: {
            country: country.map(getSelectValue),
            browser: browser.map(getSelectValue),
            campaign: campaign.map(getSelectValue),
            device: device.map(getSelectValue),
            source: source.map(getSelectValue),
            channel: channel.map(getSelectValue),
            platformOrigin: platformOrigin.map(getSelectValue),
            affiliates: affiliates.map(getSelectValue),
            eventType: eventType.map(getSelectValue),
            eventName: eventName.map(getSelectValue),
            medium: medium.map(getSelectValue),
            content: content.map(getSelectValue),
            url: url.map(getSelectValue),
            domain: domain.map(getSelectValue),
            emailDomain: emailDomains.map(getSelectValue),
            threatType: threatTypes.map(getSelectValue),
        },
    };
};
const SLP_REPORT_TYPE = 'user_validation';
const SLP_LICENSE_ID = 8;
const getSelectValue = ({ value }) => value;

const getDateObject = dateRange => {
    const value = getSelectValue(dateRange);
    return !value || typeof value === 'string'
        ? value
        : {
              startDate: moment(value.startDate).format('YYYY-MM-DD HH:mm'),
              endDate: moment(value.endDate).format('YYYY-MM-DD HH:mm'),
          };
};

const downloadReport = (jsonData, fileName) => {
    const csv = json2csv.parse(jsonData);

    const a = document.createElement('a');

    document.body.appendChild(a);
    a.style = 'display: none';

    const blob = new Blob([csv], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `${fileName}.csv`;
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
};

const casToAnalytictsFilters = (data, platformsGroups) => {
    if (data && data.popupFilterStore)
        return {
            reportType: { value: 'summary', label: 'Summary' },
            dateRange:
                data.dateObject.dateRange.value.toLowerCase() !== 'custom'
                    ? { value: data.dateObject.dateRange.value.charAt(0).toLowerCase() + data.dateObject.dateRange.value.slice(1), label: data.dateObject.dateRange.label }
                    : data.dateObject.dates[0]
                    ? { startDate: data.dateObject.dates[0], endDate: data.dateObject.dates[1] }
                    : { value: 'Today', label: 'Today' },
            includePPCCrawlers: false,
            policyToggleA: data.includePolicy,
            includePolicy: true,
            policyManagement: false,
            groupBy: data.groupBy,
            breakdownBy: undefined,
            advancedFiltering: {
                country:
                    data.popupFilterStore.countries && Object.keys(data.popupFilterStore.countries.data).length > 0
                        ? Object.entries(data.popupFilterStore.countries.data).map(([key, value]) => {
                              return { value: key, label: value };
                          })
                        : [],
                browser: [],
                content: [],
                device: [],
                affiliates:
                    data.popupFilterStore.affiliates && Object.keys(data.popupFilterStore.affiliates.data).length > 0
                        ? Object.keys(data.popupFilterStore.affiliates.data).map(element => {
                              return { value: element, label: element };
                          })
                        : [],
                eventType: [],
                eventName: [],
                campaign:
                    data.popupFilterStore.campaigns && Object.keys(data.popupFilterStore.campaigns.data).length > 0
                        ? Object.keys(data.popupFilterStore.campaigns.data).map(element => {
                              return { value: element, label: element };
                          })
                        : [],
                channel:
                    data.popupFilterStore.tags && Object.keys(data.popupFilterStore.tags.data).length > 0
                        ? Object.entries(data.popupFilterStore.tags.data).map(([key, value]) => {
                              return { value: key, label: value };
                          })
                        : [],
                platformOrigin:
                    data.popupFilterStore.platforms && Object.keys(data.popupFilterStore.platforms.data).length > 0
                        ? Object.keys(data.popupFilterStore.platforms.data).map(element => {
                              return { value: element, label: element };
                          })
                        : platformsGroups, // .map(platform => ({ value: platform, label: platform })),
                source:
                    data.popupFilterStore.source && Object.keys(data.popupFilterStore.source.data).length > 0
                        ? Object.keys(data.popupFilterStore.source.data).map(element => {
                              return { value: element, label: element };
                          })
                        : [],
                medium:
                    data.popupFilterStore.medium && Object.keys(data.popupFilterStore.medium.data).length > 0
                        ? Object.keys(data.popupFilterStore.medium.data).map(element => {
                              return { value: element, label: element };
                          })
                        : [],
                url:
                    data.popupFilterStore.domains && Object.keys(data.popupFilterStore.domains.data).length > 0
                        ? Object.keys(data.popupFilterStore.domains.data).map(element => {
                              return { value: element, label: element };
                          })
                        : [],
                domain:
                    data.popupFilterStore.domains && Object.keys(data.popupFilterStore.domains.data).length > 0
                        ? Object.keys(data.popupFilterStore.domains.data).map(element => {
                              return { value: element, label: element };
                          })
                        : [],
            },
        };
};
/**
 * @param {Array<string>} list
 * @returns {Array<{value: string, label: string,}>}
 */
const toSelectList = (list = []) =>
    list.reduce((accumulator, current) => {
        if (current)
            accumulator.push({
                value: current,
                label: current,
            });
        return accumulator;
    }, []);

export { getSelectValue, getRequestObject, getDateObject, downloadReport, casToAnalytictsFilters, SLP_REPORT_TYPE, SLP_LICENSE_ID, toSelectList };
