import { httpRequest, methods } from '../http';
/* @ts-ignore */
import config from '@config';
import { AdobeAnalyticsUpdateModel } from 'components/containers/management/adobe-analytics';

const cqAccount_url = config.cqAccountsPdBaseAPI;
const cqAccount_common_url = config.cqAccountsCommonBaseAPI;
const baseAPI = config.baseAPI;

class AccountsService {
    static async saveUserInDB(id: string, networkID: number) {
        const saveUserPromise = httpRequest({ baseURL: `${cqAccount_url}/admin/user/activate`, method: methods.post, body: { id, networkID } });
        return saveUserPromise.response;
    }

    static async getValidDomains() {
        const { response } = httpRequest({ baseURL: `${cqAccount_url}/admin/valid_domains`, method: methods.get });
        return await response;
    }

    static async getBrandOrgs(regionId: number) {
        let url: string = '/accounts';

        if (regionId != 0) {
            url = url + `?regionId=${regionId}`;
        }
        const { response } = httpRequest({ baseURL: `${cqAccount_url}${url}`, method: methods.get });
        return await response;
    }

    static async getDemoAccounts() {
        let url: string = '/admin/get_demo_accounts';

        const { response } = httpRequest({ baseURL: `${cqAccount_url}${url}`, method: methods.get });
        return await response;
    }

    static async getAccountDetails(networkId: number) {
        const url: string = `/api/v2/account/${networkId}`;

        const { response } = httpRequest({ baseURL: `${baseAPI}${url}`, method: methods.get });
        return await response;
    }

    static async updateAdobeAnalyticsIntegrations(body: AdobeAnalyticsUpdateModel) {
        const url: string = `/integrations/adobe`;

        const { response } = httpRequest({ baseURL: `${cqAccount_common_url}${url}`, method: methods.put, body });
        return await response;
    }
}

export default AccountsService;
