import { dashboard as types } from '.';

export const dashboardSettingsLoading = () => ({
    type: types.DASHBOARD_SETTINGS_LOADING,
});

export const dashboardSettingsUpdated = (payload, error) => ({
    type: types.DASHBOARD_SETTINGS_UPDATED,
    payload,
    error,
});

export const dashboardDataLoading = payload => ({
    type: types.DASHBOARD_DATA_LOADING,
    payload,
});

export const dashboardDataUpdated = (payload, error) => ({
    type: types.DASHBOARD_DATA_UPDATED,
    payload,
    error,
});

export const setDashboardDisplayOption = payload => ({
    type: types.SET_DASHBOARD_DISPLAY_OPTION,
    payload,
});

export const setSelectedPlatformsGroup = payload => ({
    type: types.SET_SELECTED_PLATFORMS_GROUP,
    payload,
});

export const setSelectedChannel = payload => ({
    type: types.SET_SELECTED_CHANNEL,
    payload,
});

export const setFilterOptions = payload => ({
    type: types.SET_FILTER_OPTIONS,
    payload,
});
export const setPolicyToggleOptions = payload => ({
    type: types.SET_POLICY_TOGGLE_OPTIONS,
    payload,
});

export const dashboardRequestFailed = payload => ({
    type: types.DASHBOARD_REQUEST_FAILED,
    payload,
});

export const getComponentsUp = payload => ({
    type: types.GET_COMPONENTS_UP,
    payload,
});

export const getComponentsDown = payload => ({
    type: types.GET_COMPONENTS_DOWN,
    payload,
});

export const getPotentialReplacedVisitsComponent = payload => ({
    type: types.GET_POTENTIAL_REPLACED_VISITS_COMPONENT,
    payload,
});

export const getCalculatedReplacedVisitsComponent = payload => ({
    type: types.GET_CALCULATED_REPLACED_VISITS_COMPONENT,
    payload,
});

export const getBlockedEntitiesComponent = payload => ({
    type: types.GET_BLOCKED_ENTITIES_COMPONENT,
    payload,
});

export const setRTISettings = payload => ({
    type: types.SET_RTI_SETTINGS,
    payload,
});

export const getInvalidVisitsByCampaigns = payload => ({
    type: types.GET_INVALID_BY_CAMPAIGNS,
    payload,
});

export const RTIDashboardError = payload => ({
    type: types.RTI_DASHBOARD_ERROR,
    payload,
});

export const getInvalidVisitsByCountries = payload => ({
    type: types.GET_INVALID_BY_COUNTRIES,
    payload,
});

export const getInvalidVisitsByKeywords = payload => ({
    type: types.GET_INVALID_BY_KEYWORD,
    payload,
});

export const getInvalidVisitsByReason = payload => ({
    type: types.GET_INVALID_BY_REASON,
    payload,
});

export const getInvalidVisitsByUrl = payload => ({
    type: types.GET_INVALID_BY_URL,
    payload,
});

export const rtiDashboardLoading = () => ({
    type: types.RTI_DASHBOARD_LOADING,
});

export const getInterceptions = payload => ({
    type: types.GET_RTI_INTERCEPTIONS,
    payload,
});

export const getRTIInvalidInsights = payload => ({
    type: types.GET_RTI_INVALID_INSIGHTS,
    payload,
});

export const getRTIVisitsSummary = payload => ({
    type: types.GET_RTI_VISITS_SUMMARY,
    payload,
});

export const getRTIPageviews = payload => ({
    type: types.GET_RTI_PAGEVIEWS,
    payload,
});

export const rtiDashbordUpdated = () => ({
    type: types.RTI_DASHBOARD_UPDATED,
});


