import { FC } from 'react';
/* @ts-ignore */
import { useContentManager, useSplit } from '@hooks';
import { constants } from '../../../../utils/split.io';
import styles from './styles.module.scss';
import GoogleAnalyticsV4Integration from './google-connector-v4/index';
import GoogleUniversalAnalytics from './google-universal';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isViewer } from '../../../../utils/permissions/index.js';

const GoogleAnalytics: FC = () => {
    const cm = useContentManager();
    const { isOn: isGaIntegrationOn } = useSplit(constants.SPLIT_DATA_AND_ANALYTICS_GOOGLE_ANALYTICS_INTEGRATION);
    const { isOn: isGaUaOn } = useSplit(constants.SPLIT_PARADOME_GA_UA);
    const readonly = isViewer();

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{cm.get('GaConnector')}</div>
            </div>

            {isGaIntegrationOn && (
                <div className={styles.integrationsContainer}>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <div className={styles.title}>
                                <span>{cm.get('GoogleAnalytics4Title')}</span>
                            </div>
                        </AccordionSummary>
                        <GoogleAnalyticsV4Integration cm={cm} readonly={readonly} />
                    </Accordion>
                    {isGaUaOn && (
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <div className={styles.title}>
                                    <span>{cm.get('GoogleUniversalAnalyticsTitle')}</span>
                                </div>
                            </AccordionSummary>
                            <GoogleUniversalAnalytics cm={cm} readonly={readonly} />
                        </Accordion>
                    )}
                </div>
            )}
        </div>
    );
};

export default GoogleAnalytics;
