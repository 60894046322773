import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../components/containers/exportables/hooks';
import styles from './select.module.scss';
import arrowIcon from '../../assets/svgs/arrow.svg';
import searchIcon from '../../assets/svgs/search.svg';

const SelectComponents = ({ selector, selected, options, onSelect, isSearchable }) => {
    const container = useRef(null);

    const [opened, setOpened] = useState(false);
    const [searchedValue, setSearchedValue] = useState('');
    useOnClickOutside(container, () => {
        setOpened(false);
    });

    useEffect(() => {
        if (searchedValue.length > 0) {
            setOpened(true);
        }
    }, [searchedValue]);
    const onSearch = e => setSearchedValue(e.target.value);
    let finalOptions = options;
    if (searchedValue.length > 0 && isSearchable) {
        finalOptions = options.filter(item => item.label.toLowerCase().includes(searchedValue.toLowerCase()));
    }

    return (
        <div ref={container} className={styles.container} data-test-selector={selector}>
            <div className={`${styles.selectInput} ${opened && styles.active}`} onClick={() => setOpened(prevOpened => !prevOpened)}>
                {!selected || selected.label.length === 0 ? <div className={styles.placeholder}>{'Select...'}</div> : <div className={styles.label}>{selected.label}</div>}
                <img src={arrowIcon} className={styles.tick} onClick={() => setOpened(prevOpened => !prevOpened)} alt="icon" />
            </div>
            {opened && (
                <div className={styles.dropdown} data-test-selector="selector-dropdown">
                    <div className={styles.items}>
                        {isSearchable && (
                            <div style={{ padding: '2px 6px' }}>
                                <div className={styles.searchBox}>
                                    <img src={searchIcon} alt="search" />
                                    <input className={styles.input} value={searchedValue} onChange={onSearch} placeholder="Search..." data-test-selector="selector-search" />
                                </div>
                            </div>
                        )}
                        {finalOptions.map((item, index) => (
                            <div
                                className={styles.item}
                                key={index}
                                onClick={() => {
                                    onSelect(item);
                                    setOpened(false);
                                }}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectComponents;
