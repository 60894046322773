import { createTheme } from '@material-ui/core/styles';

const COLORS = {
    DEFAULT: '#14172c',
    BACKGORUND: '#282b3f',
    PAPER: '#383851',
};

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 1280,
            md: 1366,
            lg: 1600,
            xl: 1920,
        },
    },
    transparent: '#00000000',
    palette: {
        primary: { main: '#fe0072' },
        secondary: { main: '#fe0072' },
        custom: {
            rainbow: ['#fe0072', '#c92477', '#ba2d93', '#b826db', '#7d2aa0', '#6b1d95', '#4a1f74'],
            rainbow2: ['#f50e77', '#e156dc', '#b21cea', '#bd85ff', '#823cf6'],
            text: {
                primary: '#fe0072',
                secondary: 'white',
                disabled: 'rgba(255, 255, 255, 0.43)',
            },
        },
        type: 'dark',
        background: {
            default: COLORS.DEFAULT,
            paper: COLORS.BACKGORUND,
            component: COLORS.PAPER,
        },
        chart: {
            a: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, '#9250d5'],
                    [1, '#fe0072'],
                ],
            },
            b: '#5545a0',
            c: '#fe0072',
        },
        heatMap: {
            minColor: '#ffd600',
            maxColor: '#ff4e4e',
        },
    },
    typography: {
        table: {
            display: 'inline-block',
        },
        body1: {},
        body2: {},
        fontFamily: ['Open Sans'],
        fontWeight: 500,
        color: '#f1f2f6',
        fontSize: 16 * 1.4, //compensation for reduced base size
    },
    overrides: {
        MuiMenuItem: {
            root: {
                '&:hover': {
                    backgroundColor: '#14172c !important',
                    color: '#fe0072',
                },
                fontSize: 'clamp(14px, 1vw, 16px) !important',
            },
        },
        MuiInput: {
            root: {
                borderColor: `red`,
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: 'clamp(10px, 1vw, 12px) !important',
                whiteSpace: 'nowrap',
            },
        },
        MuiFormControlLabel: {
            label: {
                fontSize: '1.4rem',
            },
        },

        MuiTabs: {
            root: {
                backgroundColor: COLORS.DEFAULT,
            },
            indicator: {
                backgroundColor: COLORS.BACKGORUND,
            },
        },
        MuiSelect: {
            outlined: {
                fontSize: 'clamp(14px, 1vw, 16px)',
                '&:focus': {
                    background: '$labelcolor',
                },
            },
            select: {
                fontSize: 'clamp(14px, 1vw, 16px)',
                '&:focus': {
                    background: '$labelcolor',
                },
            },
        },
        MuiSvgIcon: {
            fontSizeSmall: {
                fontSize: 'clamp(18px, 5vw, 22px) !important',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                color: '#383852',
                background: 'white !important',
                padding: 8,
            },
            arrow: {
                color: 'white !important',
            },
        },
        MuiTextField: {
            root: {
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: 'grey',
                    },
                    '&:hover fieldset': {
                        borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#ea3385',
                    },
                },
            },
        },
        MuiSwitch: {
            colorSecondary: {
                '&$checked + $track': {
                    backgroundColor: '#fe0072',
                    opacity: 1,
                },
            },
        },
        MuiInputBase: {
            marginDense: {
                paddingTop: '0px !important',
            },
        },
        MuiTable: {
            stickyHeader: {
                background: 'inherit',
            },
        },
        MuiTableRow: {
            root: {
                '&:last-child td': {
                    borderBottom: '0 !important',
                },

                '&:first-child th': {
                    background: '#383851 !important',
                    borderBottom: '1px solid white !important',
                    whiteSpace: 'nowrap',
                    fontWeight: 600,
                    fontSize: 14,
                    padding: 8,
                },
            },
        },

        MuiTableCell: {
            root: {
                padding: 8,
                background: 'inherit !important',
                borderBottom: '0.5px solid #282a3f !important',
                whiteSpace: 'nowrap',
            },
            head: {
                background: 'inherit !important',
                // borderBottom: '1px solid white !important',
                whiteSpace: 'nowrap',
            },
            stickyHeader: {
                // borderBottom: '1px solid white',
                background: '#383851 !important',
                whiteSpace: 'nowrap',
            },
        },
        MuiTab: {
            root: {
                '&$selected': {
                    color: 'white',
                    backgroundColor: COLORS.BACKGORUND,
                },
            },
            textColorInherit: {
                color: '#fe0072',
                opacity: 1,
            },
            wrapper: {
                marginBottom: 0,
            },
            labelIcon: {
                marginBottom: 0,
            },
        },

        AccountsTable: {
            MuiTableRow: { root: { padding: 0 } },
        },
        MuiCssBaseline: {
            body: {
                // fontSize: 16 * 1.4, //compensation for reduced base size
            },
        },
    },
});

export default theme;
