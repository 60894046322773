import React, { useState } from 'react';
import styles from '@components/containers/exportables/create-report/create-report.module.scss';
import Input from '@components/containers/exportables/ui/input';
import widgets from '@components/widgets';
import Radio from '@components/containers/exportables/ui/radio';
import EmailInput from '@components/containers/exportables/ui/email-input';
import server from '../../../server';
import Toast from '@services/notifications';
import Select from '../../../ui/select';
import { getTestConnectionFailedMessages } from '@utils/skewed';
import { ToolTip } from '@widgets';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import config from '@config';
import { CLOUD_STORAGE_PROVIDER_OPTIONS } from '../../../main/utils';
import { generateBucketFullPath } from '../../utils';
import { useSplit } from '../../../../../../hooks';
import { constants } from '../../../../../../utils/split.io';

const { Button } = widgets;

export default ({ state, cm, onChangeHandler, updateState }) => {
    const [bucketNameError, setBucketNameError] = useState(false);
    const { isOn: isBackBlazeS3Enabled } = useSplit(constants.PARADOME_BACKBLAZE_S3);
    let { bucketName, bucketRegion, accessKey, accessSecret, destination, cloudStorageProvider } = state;
    const { dataLocationId } = JSON.parse(localStorage.getItem('meta') || '{}');

    const handleOnTestConnection = async () => {
        try {
            if (!validBucketNameField()) {
                return;
            }
            let bucket = generateBucketFullPath(bucketRegion, cloudStorageProvider, bucketName);
            console.log(`%cBucket: ${bucket}`, 'color:green');
            const body = { bucket, accessKey, accessSecret, cloudStorageProvider };
            const skewedUrl = dataLocationId != 2 ? config.skewedAnalyticsScheduledReports : config.skewedAnalyticsScheduledReportsEU;
            await server.post('validate-bucket', body, skewedUrl);
            Toast({ type: 'success', message: 'Connected Successfully' });
            setBucketNameError(false);
            updateState(prevState => ({
                ...prevState,
                testConnection: true,
            }));
        } catch (err) {
            const { message } = err;
            const errMessage = getTestConnectionFailedMessages(cloudStorageProvider, message);
            Toast({ message: errMessage || 'Connection Failed', type: 'error' });
            throw errMessage;
        }
    };
    const validBucketNameField = () => {
        if (!bucketName) {
            setBucketNameError(true);
        }
        return !!bucketName && !!accessSecret && !!accessKey;
    };

    return (
        <>
            <div className={styles.sendToContainer} data-test-selector="exportables-create-report-sendTo">
                <div className={styles.label}>{cm.get('SendTo')}</div>
                <Radio
                    onChange={onChangeHandler}
                    name="destination"
                    value={destination}
                    selector="exportables-create-report-sendTo-select"
                    items={[
                        { value: 'external', label: cm.get('ExternalStorage') },
                        { value: 'email', label: cm.get('EmailAddress') },
                    ]}
                />
            </div>
            {destination === 'external' && (
                <>
                    {isBackBlazeS3Enabled && (
                        <div className={styles.inputContainer} data-test-selector="exportables-create-report-bucket">
                            <div className={styles.labelToolTip}>{cm.get('CloudStorageProvider')}</div>
                            <Select
                                cm={cm}
                                testSelector="schedule_report_cloud_storage_provider"
                                name="cloudStorageProvider"
                                selected={state.cloudStorageProvider}
                                onChange={onChangeHandler}
                                options={CLOUD_STORAGE_PROVIDER_OPTIONS}
                            />
                        </div>
                    )}
                    <div className={styles.inputContainer} data-test-selector="exportables-create-report-bucket">
                        <div className={styles.label}>{cm.get('BucketName')}</div>
                        <Input
                            value={bucketName}
                            name="bucketName"
                            onChange={onChangeHandler}
                            placeholder={cm.get('BucketNamePlaceholder')}
                            testSelector="schedule_report_bucket_name"
                        />
                        {bucketNameError && <div className={styles.error}>{cm.get('BucketNameValidationError')}</div>}
                    </div>
                    <div className={styles.inputContainer} data-test-selector="exportables-create-report-bucket">
                        <div className={styles.labelToolTip}>
                            {cm.get('BucketRegion')}
                            <ToolTip content={cm.get('BucketRegionToolTip')} title={cm.get('BucketRegion')}>
                                <InfoIcon className={styles.icon} cursor="pointer" />
                            </ToolTip>
                        </div>
                        <Input
                            value={bucketRegion}
                            name="bucketRegion"
                            onChange={onChangeHandler}
                            placeholder={cm.get('BucketRegionPlaceholder')}
                            testSelector="schedule_report_bucket_region"
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <div className={styles.label}>{cloudStorageProvider === 'aws' ? cm.get('AccessKey') : cm.get('ApplicationKeyID')}</div>
                        <Input
                            value={accessKey}
                            name="accessKey"
                            onChange={onChangeHandler}
                            placeholder={cloudStorageProvider === 'aws' ? cm.get('AccessKeyPlaceholder') : cm.get('ApplicationKeyIDPlaceholder')}
                            testSelector="schedule_report_access_key"
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <div className={styles.label}>{cloudStorageProvider === 'aws' ? cm.get('SecretKey') : cm.get('ApplicationKey')}</div>
                        <div className={`${styles.flexItem} ${styles.accessSecret}`}>
                            <Input
                                value={accessSecret}
                                name="accessSecret"
                                onChange={onChangeHandler}
                                placeholder={cloudStorageProvider === 'aws' ? cm.get('SecretKeyPlaceholder') : cm.get('ApplicationKeyPlaceholder')}
                                testSelector="schedule_report_access_secret"
                                showEyeIcon={true}
                            />
                            <div className={styles.right}>
                                <Button onClick={handleOnTestConnection} className={styles.button} data-test-selector="exportables-create-report-test-connection">
                                    {cm.get('TestConnection')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {destination === 'email' && <EmailInput cm={cm} updateState={updateState} state={state} />}
        </>
    );
};
