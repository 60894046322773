import Base from '../../../base';
import { applyAuth0AuthorizationHeader } from '@utils/http/view-models';
import { GOOGLE_V2_BASE_ROUTE } from '../../index';

export default ({ httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor(parent) {
                    super(`${parent.name}/google`, GOOGLE_V2_BASE_ROUTE);
                    this.name = 'googleV2';
                    this.parent = parent;
                }
            }
        ).from([
            httpRequest({
                name: 'connect',
                type: 'post',
                route: 'connect',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    this.update({
                        requestInvite: true,
                    });
                },
                updateState: google => ({ google, connected: true }),
            }),
            httpRequest({
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    this.update({
                        requestingInvalidate: true,
                    });
                },
                name: 'invalidate',
                type: 'post',
                route: 'invalidate',

                updateState: () => ({ connected: false }),
                postRequest: function (_a, _params, onInvalidated = () => {}) {
                    this.update({
                        requestingInvalidate: false,
                    });
                    this.parent.update({
                        googleCustomers: null,
                        ssos: {
                            ...this.parent.state.ssos,
                            google: {},
                        },
                    });
                    onInvalidated();
                },
                onError: function () {
                    this.update({
                        requestingInvalidate: false,
                    });
                },
            }),
        ]);
