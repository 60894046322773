import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openAnalyticsWithPresetReportParams } from '@redux/thunks/analytics';
import { useContentManager } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { DashboardSection } from 'widgets';
import { viewAllGroups } from 'utils/dashboard/user-activity';
import { Countries } from './domains/countries';
import { LandingPages } from './domains/landing-pages';
import styles from './user-activity.module.scss';

const UserActivity = ({ options }) => {
    const dashboardCM = useContentManager(cm => cm.dashboard);

    const { selectedPlatformsGroup, selectedChannel } = useSelector(state => state.dashboard);
    const { selectedLicense } = useSelector(state => state.settings);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleViewAll = groupBy => () => {
        groupBy = groupBy.filter(item => item.value !== 'platformOrigin')
        dispatch(openAnalyticsWithPresetReportParams(groupBy, navigate, selectedLicense));
    }

    return (
        <DashboardSection className={styles.container} title={dashboardCM.get('InvalidUserActivity').toUpperCase()} data-test-selector="invalid user activity section">
            <LandingPages
                options={options}
                selectedChannel={selectedChannel}
                selectedPlatformsGroup={selectedPlatformsGroup}
                handleViewALl={handleViewAll(viewAllGroups.landingPages)}
                cm={dashboardCM}
            />
            <div className={styles.countries}>
                <Countries
                    options={options}
                    selectedChannel={selectedChannel}
                    selectedPlatformsGroup={selectedPlatformsGroup}
                    handleViewAll={handleViewAll(viewAllGroups.country)}
                    cm={dashboardCM}
                />
            </div>
        </DashboardSection>
    );
};

export default memo(UserActivity);
