
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json
      * to see how it was generated.
      */
    
import Copy from './copy';
import Edit from './edit';
import Home from './home';
import Icon from './icon';
import Logo from './logo';
import ThirdParties from './third-parties';
import Upload from './upload';
import View from './view';

export default {
        Copy,
		Edit,
		Home,
		Icon,
		Logo,
		ThirdParties,
		Upload,
		View
}
