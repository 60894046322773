import React, { useState, Component } from 'react';
import Tooltip from '../tool-tip';
import SelectField, { components } from 'react-select';
import styles from './style.module.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Search from '@material-ui/icons/Search';
import AsyncSelect from 'react-select/async';
import { FixedSizeList as List } from 'react-window';

const height = 35;

function getMultiSelectParams(state = [], setState, isMenuOpen, setIsMenuOpen, _onChange) {
    const closeMenuOnSelect = false;
    const onMenuClose = () => {
        setIsMenuOpen(!isMenuOpen);
        _onChange(state);
    };

    return {
        closeMenuOnSelect,
        onMenuClose,
        onChange: option => {
            const checked = state.find(opt => opt.value === option.value);
            if (!checked && option.value !== 'none') {
                setState([...state, option]);
            } else if (checked && option.value !== 'none') {
                setState([...state.filter(c => c.value !== option.value)]);
            }
            if (option.value === 'none') {
                setState([]);
            }
        },
    };
}

//type ActionTypes = | 'clear' | 'create-option' | 'deselect-option' | 'pop-value' | 'remove-value' | 'select-option' | 'set-value'
function getSelectParams(onChange) {
    return { onChange: data => (data.value === 'none' ? onChange() : onChange(data)) };
}

function getAsyncOptions() {
    return { defaultOptions: true };
}

function getSelectAsyncParams(asyncOptions) {
    return asyncOptions ? { cacheOptions: true, loadOptions: asyncOptions, defaultOptions: true } : {};
}

const MultiSelectCheckbox = ({ isOpen, onChange, value, placeholder = '', options = [], asyncOptions, styles, isMulti, isClearable, isLoading, ...params }) => {
    const [state, setState] = useState(value);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const selectParams = isMulti ? getMultiSelectParams(state, setState, isMenuOpen, setIsMenuOpen, onChange) : getSelectParams(onChange);

    const selectAsyncParams = getSelectAsyncParams(asyncOptions);
    const Select = asyncOptions ? AsyncSelect : SelectField;
    const isSearchable = options.length > 10;

    const pl = isMenuOpen && isSearchable ? `type ${placeholder}` : placeholder;

    return (
        <Select
            options={isMulti ? orderBySelected(options, state) : filterSelected(options, value)}
            value={isMulti ? null : value || null}
            components={{ DropdownIndicator, MenuList }}
            styles={customStyles(state, styles, params?.defaultProductSelection)}
            placeholder={placeholder}
            isSearchable={isSearchable}
            defualtValue={''}
            menuIsOpen={isOpen}
            isClearable={isClearable}
            onMenuOpen={() => setIsMenuOpen(!isMenuOpen)}
            formatOptionLabel={option => formatOptionLabel(option, state, setState, isMulti, onChange)}
            {...selectParams}
            {...selectAsyncParams}
            isLoading={isLoading}
            noOptionsMessage={() => null}
            {...params}
        />
    );
};

export default MultiSelectCheckbox;

function DropdownIndicator(props) {
    const { isSearchable, menuIsOpen } = props.selectProps;
    return (
        <components.DropdownIndicator {...props}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                <Search
                    style={{
                        textAlign: 'left',
                        width: '100%',
                        height: '100%',
                        display: isSearchable && menuIsOpen ? 'block' : 'none',
                        color: '#fe0072',
                        position: 'absolute',
                        right: 11,
                    }}
                />
                <ArrowDropDownIcon style={{ transform: menuIsOpen ? 'rotate(180deg)' : '' }} />
            </div>
        </components.DropdownIndicator>
    );
}

class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;
        return (
            <List height={35 * Math.min(9, children.length) + 5} itemCount={children.length} itemSize={height} initialScrollOffset={initialOffset}>
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}

function formatOptionLabel(option = {}, state = [], setState, isMulti, onChange) {
    //element.offsetWidth < element.scrollWidth; // TODO useRef for ellipsis
    const label = option.label || '';
    let ellipsis = label.length > 16 ? { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } : {};
    if (!isMulti) {
        return (
            <Tooltip
                title={label}
                disable={!Object.keys(ellipsis).length}
                children={
                    <div style={ellipsis} onChange={onChange}>
                        {label}
                    </div>
                }
            />
        );
    } else {
        const checked = !!state.find(item => item.value === option.value);
        return (
            <label className={styles['container']}>
                <input onChange={() => {}} type="checkbox" checked={checked} />
                <span className={styles['checkmark']}></span>
                <Tooltip
                    title={label}
                    disable={!Object.keys(ellipsis).length}
                    children={<div style={{ color: checked ? '#fe0072' : '#fff', textAlign: 'left', ...ellipsis }}>{label}</div>}
                />
            </label>
        );
    }
}

function orderBySelected(selectOptions = [], currentState = []) {
    const orderedOpt = [...currentState, ...selectOptions.filter(option => !currentState.find(s => s.value === option.value) && option.value !== 'none')];

    if (selectOptions.filter(option => option.value === 'none')) {
        orderedOpt.unshift({ value: 'none', label: 'none' });
    }
    return orderedOpt;
}

function filterSelected(options, state = {}) {
    return options.filter(option => option.value !== state.value);
}

function customStyles(currentState, styles = {}, defaultProductSelection) {
    return {
        indicatorSeparator: provided => ({
            ...provided,
            display: 'none',
            ...(styles['indicatorSeparator'] || {}),
        }),
        dropdownIndicator: () => ({
            color: '#fe0072',
            marginRight: '10px',
            paddingBottom: 5,
            ...(styles['dropdownIndicator'] || {}),
        }),
        option: (provided, state) => ({
            ...provided,
            color: '#fff',
            zIndex: 100,
            backgroundColor: '#383851',
            ':hover': {
                backgroundColor: '#26273e',
            },
            pointerEvents: defaultProductSelection && state.isDisabled ? 'none' : 'auto',
            ...(styles['option'] || {}),
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff',
            textTransform: 'uppercase',
            marginLeft: '5px',
            ...(styles['input'] || {}),
        }),
        placeholder: (provided, state) => {
            return {
                color: currentState && currentState.length ? '#fff' : '#fe0072',
                textTransform: 'capitalize',
                position: 'absolute',
                top: 2,
                ...(styles['placeholder'] || {}),
            };
        },
        control: (provided, state) => ({
            display: 'flex',
            zIndex: 100,
            backgroundColor: state.selectProps.menuIsOpen ? '#14172c' : '#383851',
            fontColor: '#fff',
            height: '100%',
            borderRadius: 0,
            ':Input': {
                fontColor: '#fff',
            },
            ...(styles['control'] || {}),
        }),
        menu: (provided, state) => {
            return {
                ...provided,
                zIndex: 100,
                backgroundColor: '#14172c',
                borderRadius: 0,
                marginTop: '1px',
                boxShadow: 'none',
                ...(styles['menu'] || {}),
            };
        },
        container: (provided, state) => {
            const isValue =
                (currentState && currentState.length) || (state.selectProps.value && !Array.isArray(state.selectProps.value) && Object.keys(state.selectProps.value).length);
            return {
                ...provided,
                backgroundColor: '#383851',
                height: '41px',
                width: '278px',
                fontColor: isValue ? '#fe0072' : '#fff',
                marginBottom: '5px',
                boxShadow: 'none',
                border: isValue ? 'solid 1px #fe0072' : 'solid 1px #14172c',
                ':hover': {
                    border: isValue ? 'solid 1px #fe0072' : 'solid 1px #14172c',
                },
                ':focus': {
                    border: isValue ? 'solid 1px #fe0072' : 'solid 1px #14172c',
                },
                ...(styles['container'] || {}),
            };
        },
        singleValue: (provided, state) => {
            return {
                ...provided,
                color: '#fff',
                ...(styles['singleValue'] || {}),
            };
        },
    };
}
