import React from "react";
import ChipsInput from "../chips-input";
import validator from "validator";

export default ({ state, updateState, cm }) => {
    const handleAddEmail = email => {
        email = email.trim();
        if (validator.isEmail(email)) {
            updateState(prevState => ({
                ...prevState,
                emails: [...prevState.emails, email],
            }));
        }
    };

    const handleDeleteEmail = (_, idx) => {
        const emails = state.emails.filter((_, i) => i !== idx);
        updateState(prevState => ({
            ...prevState,
            emails,
        }));
    };

    return (
        <ChipsInput
            data-test-selector="exportables-data_and_analytics_email"
            data={state.emails}
            onAdd={handleAddEmail}
            onDelete={handleDeleteEmail}
            placeholder={cm.get('EmailPlaceholder')}
        />
    )
}