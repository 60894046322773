import { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { ValueLabel } from 'types/types';
import Tooltip from 'components/widgets/tooltip';
import MultiSelectCheckbox from 'components/widgets/multi-select-checkbox';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import AddApiButton from 'components/widgets/add-api-button';
import { Integration as IntegrationObject} from '../../types';
import { validateGaV4Params } from '../../utils';
import styles from '../../styles.module.scss';

interface IProps {
    cm: any;
    channelOptions: ValueLabel[];
    handleAddIntegration: (channel: ValueLabel, measurementId: string) => Promise<void>;
    integrations: IntegrationObject[]
}

const CreateIntegration: FC<IProps> = ({ cm, channelOptions, handleAddIntegration, integrations }) => {
    const [selectedChannel, setSelectedChannel] = useState<ValueLabel>({label:"", value:0});
    const [measurementId, setMeasurementId] = useState("");
    const [disableButton, setDisableButton] = useState(true)
    const [error, setError] = useState('');

    useEffect(() => {
        disableButtonCheck();
    }, [_.isEmpty(measurementId), (!selectedChannel.value)])
    
    const selectStyle = () => ({
        container: {
            border: 'none',
            width: '20rem',
            borderRadius: '4px'
        },
        control: {
            borderRadius: '4px'
        }
    });
    /**
     * 
     * @returns sets add button to disabled if not stands in validation
     */
    const disableButtonCheck = () => {
        if (!selectedChannel.value || _.isEmpty(measurementId)) {
            setDisableButton(true)
            return
        }
        setDisableButton(false)
    }

    const checkInputExists = (): boolean => {
        return !!integrations.find(ele => ele.channel.value === selectedChannel.value && ele.measurementId === measurementId)
    }

    const handleAdd = async () => {
        if (disableButton) return;
        if (!validateGaV4Params(measurementId)) {
            return setError(cm.get('GaInvalidMeasurementId'));
        }
        if (checkInputExists()){
            return setError(cm.get('GAInvalidExistsInput'));
        }
        setError('');
        handleAddIntegration(selectedChannel, measurementId)
    };

    return (
        <div className={styles.row}>
            <div className={styles.dFlexCol}>
                <span className={styles.label}>{cm.get('Channel')}</span>
                <MultiSelectCheckbox
                    onChange={(value: any) => setSelectedChannel(value)}
                    styles={selectStyle()}
                    options={channelOptions}
                    value={selectedChannel}
                    placeholder={cm.get('Channel')}
                    isOpen={undefined}
                    asyncOptions={undefined}
                    isMulti={false}
                    isClearable={false}
                    isLoading={false}
                />
            </div>
            <div className={`${styles.dFlexCol} ${styles.smallInput}`}>
                    <div className={`${styles.dFlexRow} ${styles.measurement}` }>
                        <span className={styles.label}>{cm.get('MeasurementId')}</span>
                        <Tooltip content={cm.get('MeasurementIdTooltip')} title={undefined} action={undefined} arrow={undefined}>
                            <InfoIcon />
                        </Tooltip> 
                     </div>
                    <input className={styles.textContainer} name="measurementId" value={`${measurementId}`} onChange={(event=>setMeasurementId(event.target.value))} />
                    {error && <span className={styles.error}>{error}</span>}
            </div>
            <AddApiButton onClickHandler={handleAdd} text={cm.get('Add')} className={`${disableButton ? styles.disabled : ''} ${styles.gaAddButton}`} />
        </div>
    );
};

export default CreateIntegration;
