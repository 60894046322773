import { FC, useState } from "react"
import { UserAPI } from "./Types"
import RevokeIcon from '@material-ui/icons/Autorenew'
import IconButton from '@material-ui/core/IconButton'
/* @ts-ignore */
import Tooltip from '@components/widgets/tooltip';
import styles from '../styles.module.scss'
import { generatePassword } from "./utils";
import { iam } from "services/api";
import { showDialog } from '../../../../../widgets/dialog'
import { useSecretKey } from "../useSecretKey";
import copy from "clipboard-copy";
import CopyToClipboard from "../CopyToClipboard";

interface IProps {
    user: UserAPI;
    updateUserToUI: (username: string, newPassword: string) => void;
    cm: any;
}

const UserApi: FC<IProps> = ({ user, updateUserToUI, cm = {} }) => {
    const [Icon, secretKeyText, handleToogleSecret, revealSecretKey] = useSecretKey(user.password)
    const [idCopied, setIdCopied] = useState(false);
    const [secretCopied, setSecretCopied] = useState(false);

    const handleShowRevokePopUp = async () => {
        const dialogContent = {
            title: cm.get("RegenerateClientSecret"),
            children: `${cm.get("RegenerateClientSecretReassurance")} ${user.name}?`,
            userInteractive: { resolveText: cm.get('Yes'), rejectText: cm.get('Cancel') },
        };
        const confirmDialog = await showDialog(dialogContent)
        !!confirmDialog && await handleRevoke();
    }

    const handleRevoke = async () => {
        const newPassword = generatePassword()
        await iam.revokeUser(user.username, newPassword)
        updateUserToUI(user.username, newPassword)
    }

    const handleOnSecretKeyCopy = () => {
        copy(secretKeyText);
        setSecretCopied(true);
        setIdCopied(false);
    };

    const handleOnIdCopy = () => {
        copy(user.username);
        setIdCopied(true);
        setSecretCopied(false);
    }

    return (
        <div className={styles.row} >

            <div className={`${styles.dFlexCol} ${styles.smallInput}`}>
                <span className={styles.label}>{cm.get("IntegrationName")}</span>
                <div className={styles.textContainer}>
                    <div className={styles.text}>{user.name}</div>
                </div>
            </div>

            <div className={`${styles.dFlexCol} ${styles.mediumInput}`}>
                <span className={styles.label}>{cm.get("ClientId")}</span>
                <div className={styles.textContainer}>
                    <div className={styles.text}>{user.username}</div>
                    <CopyToClipboard copied={idCopied} cm={cm} handleCopy={handleOnIdCopy} />
                </div>
            </div>

            <div className={`${styles.dFlexCol} ${styles.largeInput}`}>
                <span className={styles.label}>{cm.get("ClientSecret")}</span>
                <div className={styles.textContainer}>
                    <div className={styles.text}>{secretKeyText}</div>
                    <div className={styles.dFlexRow}>
                        <div className={styles.icon} onClick={handleToogleSecret} >
                            <Icon />
                        </div>
                        {revealSecretKey && <CopyToClipboard copied={secretCopied} cm={cm} handleCopy={handleOnSecretKeyCopy} />}
                    </div>
                </div>
            </div>

            <div className={styles.sideIcons}>
                <Tooltip content={cm.get("RegenerateClientSecret")}>
                    <IconButton size='small' onClick={handleShowRevokePopUp}>
                        <RevokeIcon />
                    </IconButton>
                </Tooltip>
            </div>

        </div >
    )
}

export default UserApi