import { clickTrueTags as actions } from '../actions';

const clickTrueTagsInitialState = {
    err: null,
    isLoading: false,
    tags: [],
};

export default (state = clickTrueTagsInitialState, action) => {
    switch (action.type) {
        case actions.CLICK_TRUE_TAGS_DATA_LOADING: {
            return { ...state, isLoading: true, err: null };
        }
        case actions.CLICK_TRUE_TAGS_REQUEST_FAILED: {
            const { payload: err } = action;
            return { ...state, err, isLoading: false };
        }
        case actions.SET_CLICK_TRUE_TAGS: {
            const { payload: tags } = action;
            return { ...state, tags, isLoading: false };
        }
        default:
            return state;
    }
};
