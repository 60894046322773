
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json
      * to see how it was generated.
      */
    
import google from './google';

export default {
        google
}
