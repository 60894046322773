import React from 'react';
import cx from 'classnames';
import styles from './StatusMessage.module.scss';

export const DashboardStatusMessage = ({ statusText, className }) => {
    return (
        <div className={styles.root} data-test-selector="monitoring status message">
            {statusText}
        </div>
    );
};
