import { useQuery } from 'react-query';
import SupersetService, { TokenResponse } from '../../services/api/superset.service';

export const useTokenOutbrain = (dashboardId: string) => {
    return useQuery({
        queryKey: ['getToken', dashboardId],
        queryFn: () => SupersetService.getGuestToken(dashboardId),
        select: mapToken,
        cacheTime: 10000,
    });
};

export const mapToken = (res: TokenResponse) => {
    return res.token;
};
