import { CSSProperties, FC, MouseEventHandler } from 'react'
import { IconButton } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import styles from './styles.module.scss'

interface IProps {
    onClickHandler: MouseEventHandler<HTMLDivElement>;
    text?: string;
    style?: CSSProperties;
    className?: string;
}

const AddApiButton: FC<IProps> = ({ onClickHandler, text = '', style = {}, className = '' }) => {
    return (
        <div className={`${styles.row} ${className}`} style={style} onClick={onClickHandler} >
            <IconButton size="small" data-test-selector="rti-settings-add-api-button">
                <AddCircleOutlineIcon className={styles.button} fontSize="small" />
            </IconButton>
            <div className={styles.button}>{text}</div>
        </div>
    )
}

export default AddApiButton