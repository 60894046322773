import React, { useEffect, useState } from 'react';
import styles from './brans.module.scss';
import Progress from '../../widgets/progress';
import { useAuth0 } from '@auth0/auth0-react';
import ParadomeLogo from '../../../assets/svgs/CHEQ_primary.svg';
import SelectComponent from '../../../widgets/new-select';
import Header from './components/Header/Header';
import AccoountsService from '../../../services/api/accounts.service';
import iamService from '../../../services/api/iam.service';

export default function Brands({ hasEmptyPermissions = false }) {
    const [selected, setSelected] = useState(null);
    const { isLoading, loginWithRedirect, user, getAccessTokenSilently, logout } = useAuth0();
    const loginToOrg = async ({ value, label }) => {
        await loginWithRedirect({ organization: value });
        localStorage.setItem('selectedOrg', JSON.stringify({ id: value, display_name: label }));
    };
    const consoleRoles = ['console_vp_admin', 'console_admin'];
    const [orgsLoaded, setOrgsLoaded] = useState(false);
    const [orgs, setOrgs] = useState([]);
    let res = {};
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                window.localStorage.setItem('auth0Token', token);
                let origin = window.location.origin;
                let { regionId, userGeneralRoles, cheqUser, id } = user && user[`${origin}/meta`];
                if (!cheqUser) {
                    res = await iamService.getOrganizationByAuth0UserId(id);
                } else {
                    for (const role of userGeneralRoles) {
                        if (consoleRoles.includes(role.description)) {
                            regionId = 0;
                            break;
                        }
                    }
                    const results = await AccoountsService.getDemoAccounts();
                    const demoAccounts = results != null ? results.map(result => ({ id: result.org_id, display_name: result.cname })) : [];
                    res = await AccoountsService.getBrandOrgs(regionId);
                    res = res.concat(demoAccounts);
                    const arrObjMap = res.reduce((acc, curr) => {
                        return {
                            ...acc,
                            [curr.id]: curr,
                        };
                    }, {});
                    res = Object.values(arrObjMap).flat();
                }
                setOrgs(res);
                localStorage.setItem('userOrgsCount', res.length);
                setOrgsLoaded(true);
            } catch (error) {
                setOrgsLoaded(false);
                console.error(error);
                if (error.code === 401) {
                    localStorage.removeItem('auth0Token');
                    logout();
                }
            }
        })();
    }, []);

    useEffect(() => {
        if (hasEmptyPermissions) {
            localStorage.removeItem('selectedOrg');
        }
    }, [hasEmptyPermissions]);

    if (!orgsLoaded) return <AppLoader />;

    const brandsList = orgs
        .map(item => {
            return { label: item.display_name, value: item.id };
        })
        .filter(item => item.label.toLowerCase() !== 'cheq');

    const onSelectItem = item => {
        setSelected(item);
    };

    return isLoading ? (
        <div>
            <Progress style={{ position: 'absolute', top: '50%', left: '50%' }} />
        </div>
    ) : (
        <div className={styles.brandsWrapper}>
            <Header username={user.name} />
            <div className={styles.container}>
                <img src={ParadomeLogo} alt="paradome-logo" className={styles.icon} />
                <div className={styles.label}>Please select your organization</div>
                <div className={styles.selectContainer}>
                    <SelectComponent options={brandsList} onSelect={onSelectItem} selector="brands-selector" selected={selected} isSearchable={true} />
                </div>
                <button disabled={!selected} className={styles.button} onClick={() => loginToOrg(selected)}>
                    CONTINUE
                </button>
            </div>
        </div>
    );
}

const AppLoader = () => {
    return (
        <Progress
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
            }}
        />
    );
};
