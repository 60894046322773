import React from "react";
import {Snackbar, SnackbarContent} from "@material-ui/core";
import SuccessIcon from "../../assets/check-circle-big.svg";
import FailureIcon from "../../assets/x-circle.svg";
import CloseIcon from "../../assets/close.svg";
import styles from "./snackbar.module.scss";

export default ({ cm, open, onClose, config }) => {
    const { reportFrequency, isSuccess, code } = config;
    let label, title;
    if (isSuccess) {
        title = `${cm.get('Success')}!`;
        label = cm.get('NewReportGenerated')
        if (reportFrequency !== 'onetime') {
            label = cm.get('NewScheduledReportCreated')
        }
    } else {
        title = cm.get("Error");
        label = cm.get('FailedToGenerateOnDemand')
        if (reportFrequency !== 'onetime') {
            label = cm.get('FailedToGenerateScheduled')
        }
        if (code === -1) {
            label = "Reach max parallel reports generation"
        }
    }

    const statusClass = isSuccess ? 'success' : ''

    const body = (
        <div className={styles.container} data-test-selector="exportables-snackbar-container">
            {isSuccess ? <img src={SuccessIcon} alt="success"/> :  <img src={FailureIcon} alt="close" /> }
            <div className={styles.textContainer}>
                <div className={`${styles.label} ${styles[statusClass]}`}>{title}</div>
                <div className={`${styles.label} ${styles[statusClass]}`}>{label}</div>
            </div>
        </div>
    )
    const action = (
        <React.Fragment>
            <img src={CloseIcon} alt="close" onClick={onClose} className={styles.icon} data-test-selector="exportables-snackbar-close-button"/>
        </React.Fragment>
    );

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            action={action}
        >
            <SnackbarContent
                className={isSuccess ? styles.root : styles.failure}
                message={body}
                action={action}/>
        </Snackbar>
    )
}