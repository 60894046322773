import Header from '../../components/containers/header';
// @ts-ignore
import { useContentManager } from '@hooks';
import { getProductByPackage, getProductsList, Product } from './utils';
import ProductElement from './Product';
import stylesV1 from './LandingPage.module.scss';
import stylesV2 from './LandingPageV2.module.scss';
import Snackbar from '../components/Snackbar';
// @ts-ignore
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setDefaultProduct } from '../../redux/actions/profile';
// @ts-ignore
import { zendeskService } from '@services/api/zendesk.service';
import { useNavigate } from 'react-router-dom';
import { setSelectedLicense } from '../../redux/actions/settings';
// @ts-ignore
import { constants } from 'utils/split.io';
import { useSplit } from 'hooks';
import { licenses } from '../../utils/settings';
import { Products } from '../../components/containers/management/constants';

const LandingPage = () => {
    const cm = useContentManager();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { networkFeatures } = useSelector((state: { settings: any }) => state.settings);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isInitialCheckProducts, setIsInitialCheckProducts] = useState<boolean>(false);
    const isNewLandingPage = useSplit(constants.PARADOME_REPACKAGING);
    const isNewSlpDashboard = useSplit(constants.PARADOME_NEW_SLP_DASHBOARD);
    const products: Product[] = isNewLandingPage.isOn ? getProductByPackage(networkFeatures) : getProductsList(networkFeatures) || [];
    const list: Product[] = products.filter(item => (isNewSlpDashboard.isOn ? true : item.id !== Products.SLP));
    const styles = isNewLandingPage.isOn ? stylesV2 : stylesV1;

    useEffect(() => {
        dispatch(setDefaultProduct(null));
    }, []);

    useEffect(() => {
        if (!isNewLandingPage) {
            if (networkFeatures?.length === 1) {
                const findProduct = list.find(product => product.id === networkFeatures[0].id);
                findProduct && onSelectProduct(findProduct);
            }
        } else {
            if (list?.length === 1) {
                onSelectProduct(list[0]);
            }
        }
        !isInitialCheckProducts && setIsInitialCheckProducts(true);
    }, [networkFeatures]);

    const onSelectProduct = (item: Product) => {
        if (!item.enabled) {
            return;
        }
        if (item.id === 5) {
            // privacy
            openPrivacy();
            return;
        }
        dispatch(setSelectedLicense(item.id));
        dispatch(setDefaultProduct(item.id));
        if (!window.localStorage.getItem('isReturnToZendesk')) {
            navigate(item.defaultRoute);
        }
        window.localStorage.removeItem('isReturnToZendesk');
    };
    const openPrivacy = () => {
        window.open(`${window.location.origin}/ensighten/login?cheq=true`, '_self', 'noopener,noreferrer');
    };

    const onClickContactUsHandler = async (item: Product) => {
        const data = await zendeskService.createTicket({ product: cm.get(item.name) }, 'zendesk');
        data ? setShowSnackbar(true) : setShowSnackbar(false);
    };
    const privacyExists = list.length == 1 && list[0].name === licenses.Privacy.key && list[0].enabled;
    return (
        <>
            {networkFeatures && isInitialCheckProducts ? (
                <>
                    <Header title={cm.get('LandingPage.Header')} data-test-selector="home header" />
                    {!privacyExists ? (
                        <div className={styles.container}>
                            <div className={styles.title}>{cm.get('LandingPage.Title')}</div>
                            {isNewLandingPage.isOn ? (
                                <div className={styles.productsList}>
                                    <div className={styles.row}>
                                        {list &&
                                            list
                                                .slice(0, list.length === 4 ? 2 : 3)
                                                .map((item: Product, index) => (
                                                    <ProductElement cm={cm} key={index} item={item} onClick={onSelectProduct} onClickContactUsHandler={onClickContactUsHandler} />
                                                ))}
                                    </div>
                                    <div className={styles.row}>
                                        {list &&
                                            list
                                                .slice(list.length === 4 ? 2 : 3, 5)
                                                .map((item: Product, index) => (
                                                    <ProductElement cm={cm} key={index} item={item} onClick={onSelectProduct} onClickContactUsHandler={onClickContactUsHandler} />
                                                ))}
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.productsList}>
                                    <div className={styles.row}>
                                        {list.slice(0, 2).map((item: Product, index) => (
                                            <ProductElement cm={cm} key={index} item={item} onClick={onSelectProduct} onClickContactUsHandler={onClickContactUsHandler} />
                                        ))}
                                    </div>
                                    <div className={styles.row}>
                                        {list.slice(2, 4).map((item: Product, index) => (
                                            <ProductElement cm={cm} key={index} item={item} onClick={onSelectProduct} onClickContactUsHandler={onClickContactUsHandler} />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        openPrivacy()
                    )}
                    {showSnackbar && <Snackbar cm={cm} open={showSnackbar} onClose={() => setShowSnackbar(false)} />}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default LandingPage;
