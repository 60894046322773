import * as utils from '@utils/dashboard';
import { memo } from 'react';
import { Campaigns, Countries, Keywords, LandingPages } from './domains';
import { DashboardSection } from '@widgets';
import { useDispatch, useSelector } from 'react-redux';

import { openAnalyticsWithPresetReportParams } from '@redux/thunks/analytics';
import styles from './user-activity.module.scss';
import { useNavigate } from 'react-router-dom';
import { useContentManager } from '@hooks';
import { updateSummeryReportSettings } from '../../../../../redux/actions/analytics';

const UserActivity = ({ options }) => {
    const dashboardCM = useContentManager(cm => cm.dashboard);

    const { selectedPlatformsGroup, selectedChannel } = useSelector(state => state.dashboard);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleViewAll = groupBy => () => {
        dispatch(updateSummeryReportSettings({
            policyToggleA: options.policyTogglePM,
            includePolicy: options.includePolicy,
            policyManagement: options.policyManagement,
        }));
        groupBy = groupBy.filter(item => item.value !== 'platformOrigin');
        dispatch(openAnalyticsWithPresetReportParams(groupBy, navigate));
    };

    return (
        <DashboardSection className={styles.container} title={dashboardCM.get('InvalidUserActivity').toUpperCase()}
                          data-test-selector='invalid user activity section'>
            <Campaigns
                options={options}
                selectedChannel={selectedChannel}
                selectedPlatformsGroup={selectedPlatformsGroup}
                handleViewAll={handleViewAll(utils.viewAllGroups.campaign)}
                cm={dashboardCM}
            />
            <LandingPages
                options={options}
                selectedChannel={selectedChannel}
                selectedPlatformsGroup={selectedPlatformsGroup}
                handleViewALl={handleViewAll(utils.viewAllGroups.landingPages)}
                cm={dashboardCM}
            />
            <Keywords
                cm={dashboardCM}
                options={options}
                handleViewALl={handleViewAll(utils.viewAllGroups.term)}
                selectedChannel={selectedChannel}
                selectedPlatformsGroup={selectedPlatformsGroup}
            />
            <Countries
                options={options}
                selectedChannel={selectedChannel}
                selectedPlatformsGroup={selectedPlatformsGroup}
                handleViewAll={handleViewAll(utils.viewAllGroups.country)}
                cm={dashboardCM}
            />
        </DashboardSection>
    );
};

export default memo(UserActivity);
