import { httpRequest, methods } from '../http';
/* @ts-ignore */
import config from '@config';
const BASE_URL = config.baseAPI;

export async function getBlockedPixelGuardSummary(dateRange: string | JSON, pageType: string, tagId: number) {
    const { response } = httpRequest({ baseURL: `${BASE_URL}/google-pixel-guard/blocked-pixels-summary`, method: methods.post, body: { dateRange, pageType, tagId } });
    return response;
}

export async function getPixelGuardNetworkStatus() {
    const { response } = httpRequest({ baseURL: `${BASE_URL}/google-pixel-guard/pixels-status`, method: methods.post });
    return response;
}
