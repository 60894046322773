import Base from '../base';
import { getDashboardSettings } from '../../../redux/thunks/dashboard';
import thirdParty from './3party';
import { triggerSnackbarMessage } from '../../../redux/actions/snackbar';
import { applyAuth0AuthorizationHeader } from '@utils/http/view-models';
import { setRTIToken, setServices } from '@redux/actions/settings';

import mixpanel from '@services/mixpanel';
import _ from 'lodash';
import { GOOGLE_TOKEN_REVOKED_ERROR_MESSAGE, GOOGLE_TOKEN_REVOKED_ERROR_RESPONSE } from '../../../services/api/google/constants';

export const GOOGLE_V2_BASE_ROUTE = 'api/v2/google';

const unFormatCustomerId = raw => raw.replace(/\-/g, '');

export default ({ httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor() {
                    super('ssoV2', GOOGLE_V2_BASE_ROUTE);

                    let ssos;
                    try {
                        ssos = JSON.parse(localStorage.getItem('ssos_v2') || '{}');
                    } catch (e) {
                        ssos = {};
                    }

                    this.state = { ready: false, ssos };
                    thirdParty.map(S => new S(this)).forEach(service => this.addService(service.name, service));
                }

                init() {
                    this.getNetwork();
                    Object.values(this.state.services).forEach(service => service.init && service.init());
                    this.getSettings();
                }

                hasAuthenticator(authenticator) {
                    return !!(this.state.ssos[authenticator] && (this.state.ssos[authenticator].is_sso || this.state.ssos[authenticator].is_mcc));
                }

                isProtected(authenticator) {
                    return !!(this.state.ssos[authenticator] && this.state.ssos[authenticator].integrated);
                }

                isConnected(authenticator) {
                    return !!(this.state.ssos[authenticator] && this.state.ssos[authenticator].connected);
                }
            }
        ).from([
            httpRequest({
                name: 'skipAdwords',
                type: 'get',
                preRequest: function () {
                    this.update({
                        requestInvite: true,
                    });
                },
                postRequest: function (_, onSkipAdwords, c) {
                    this.update({
                        requestInvite: false,
                        ssos: {
                            ...this.state.ssos,
                            google: {
                                ...this.state.ssos.google,
                                skippedAdwords: true,
                            },
                        },
                    });
                    onSkipAdwords();
                },
            }),
            httpRequest({
                name: 'saveGoogleManagement',
                type: 'post',
                preRequest: function () {
                    this.update({
                        requestSaveManagement: true,
                    });
                },
                postRequest: function (_, params) {
                    this.update({
                        requestSaveManagement: false,
                    });
                    const { isIpBanIntegrated, isAudienceTagIntegrated, isPixelsGuardIntegrated, isAudienceExclusionIntegrated } = params;
                    let isProtected;
                    let integrated;
                    let connected;
                    if (!isIpBanIntegrated && !isAudienceTagIntegrated && !isPixelsGuardIntegrated && !isAudienceExclusionIntegrated) {
                        isProtected = true;
                        connected = true;
                        integrated = false;
                    } else {
                        isProtected = true;
                        connected = true;
                        integrated = true;
                    }
                    const ssos = {
                        ...this.state.ssos,
                        google: {
                            ...this.state.ssos.google,
                            isProtected,
                            integrated,
                            connected,
                        },
                    };
                    localStorage.setItem('ssos_v2', JSON.stringify(ssos));
                    this.update({
                        ssos,
                    });
                    mixpanel.postEvent('Google configure save', {
                        'Google IP exclusion': isIpBanIntegrated ? 'On' : 'Off',
                        'Google analytics audience tag': isAudienceTagIntegrated ? 'On' : 'Off',
                        'Google ads audience exclusion': isAudienceExclusionIntegrated ? 'On' : 'Off',
                    });
                    this.reduxStore.dispatch(getDashboardSettings());
                    return { success: true };
                },
                onError: function (error, _c, dispatch) {
                    const dispatchSnackbar = message => dispatch(triggerSnackbarMessage({ message }));

                    const errMsg = error.response?.data;
                    switch (errMsg) {
                        case 'invalid_ips':
                            dispatchSnackbar('Invalid IP addresses were supplied');
                            break;
                        case 'no_protected_customers':
                            dispatchSnackbar('In order to apply IP-Exclusion, you must pick at-least one account to be protected');
                            break;
                        case 'ip_limit_exceeded':
                            dispatchSnackbar('IP addresses limit exceeded 100');
                            break;
                        case 'no_integration':
                            dispatchSnackbar('You must pick at-least one protection feature to enable protection');
                            break;
                        default:
                            dispatchSnackbar(errMsg);
                            break;
                    }
                    this.update({
                        requestSaveManagement: false,
                    });
                    return { error: errMsg };
                },
            }),
            httpRequest({
                name: 'fetchGoogleManagement',
                type: 'get',
                preRequest: function () {
                    this.update({
                        requestGoogleManagement: true,
                        errorGoogleManagement: '',
                        googleCustomers: [],
                    });
                },
                postRequest: function ({
                    googleCustomers,
                    protectedCampaigns = {},
                    isIpBanIntegrated,
                    isAudienceTagIntegrated,
                    isPixelsGuardIntegrated,
                    defaultIpBanList,
                    isAudienceExclusionIntegrated,
                }) {
                    this.update({
                        googleCustomers: googleCustomers.map(({ clientId, ...rest }) => ({ clientId: unFormatCustomerId(clientId), ...rest })),
                        protectedCampaigns,
                        isIpBanIntegrated,
                        isAudienceTagIntegrated,
                        isPixelsGuardIntegrated,
                        isAudienceExclusionIntegrated,
                        defaultIpBanList,
                        errorGoogleManagement: '',
                        requestGoogleManagement: false,
                    });
                },
                onError: function (error) {
                    const errMsg = error.response.data;
                    let errorGoogleManagement;
                    switch (errMsg) {
                        case GOOGLE_TOKEN_REVOKED_ERROR_RESPONSE:
                            errorGoogleManagement = GOOGLE_TOKEN_REVOKED_ERROR_MESSAGE;
                            break;
                        default:
                            errorGoogleManagement = 'Sorry, it appears there was some error fetching your account and campaigns from google';
                            break;
                    }
                    this.update({
                        errorGoogleManagement,
                        requestGoogleManagement: false,
                    });
                },
            }),
            httpRequest({
                name: 'cancelGoogleIds',
                type: 'post',
                preRequest: function () {
                    this.update({
                        requestCancel: true,
                        errorCancel: '',
                    });
                },
                postRequest: function (_a, _b, onCancelInvitation) {
                    this.getNetwork();
                    this.reduxStore.dispatch(getDashboardSettings());
                    this.update({
                        errorCancel: '',
                        requestCancel: false,
                    });
                    onCancelInvitation();
                },
                onError: function () {
                    this.update({
                        errorCancel: 'Sorry, something went wrong',
                        requestCancel: false,
                    });
                },
            }),
            httpRequest({
                name: 'inviteGoogleId',
                type: 'post',
                preRequest: function () {
                    this.update({
                        requestInvite: true,
                    });
                },
                postRequest: function (_, data, { onMccInvite }) {
                    this.update({
                        ssos: {
                            ...this.state.ssos,
                            google: {
                                ...this.state.ssos.google,
                                clientId: data.clientId,
                                statuses: { ...(this.state.ssos?.google?.statuses || {}), [data.clientId]: 'PENDING' },
                            },
                        },
                        requestInvite: false,
                    });
                    onMccInvite();
                },
                onError: function (error, _params, { dispatch }) {
                    const dispatchSnackbar = message =>
                        dispatch(
                            triggerSnackbarMessage({
                                message,
                                timeout: 6000,
                            })
                        );
                    const errMsg = error.response.data;
                    switch (errMsg) {
                        case 'invalid_id':
                            dispatchSnackbar('Invalid Google Ads ID supplied.');
                            return this.update({
                                requestInvite: false,
                            });
                        case 'child_managed':
                            dispatchSnackbar('It seems as this account already have a child account managed.');
                            return this.update({
                                requestInvite: false,
                            });
                        case 'mcc_already_managed':
                            dispatchSnackbar('It seems as this account is already managed by another manager, alternatively - you can use Google sign in');
                            try {
                                const googleSigninBtn = document.getElementById('google-signin');
                                googleSigninBtn.style.transition = 'box-shadow 2s ease-in-out';
                                googleSigninBtn.style.boxShadow = '0px 0px 4px 4px rgba(254,0,114,0.5)';
                                setTimeout(() => {
                                    googleSigninBtn.style.boxShadow = 'none';
                                }, 6000);
                            } catch (e) {
                                return;
                            }
                            return this.update({
                                requestInvite: false,
                            });
                        default:
                            dispatchSnackbar(errMsg || 'Sorry, something went wrong');
                            return this.update({
                                requestInvite: false,
                            });
                    }
                },
            }),
            httpRequest({
                name: 'getNetwork',
                type: 'get',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    this.update({
                        requestGetNetwork: true,
                    });
                },
                updateState: ssos => ({ ssos, ready: true }),
                postRequest: function (ssos) {
                    localStorage.setItem('ssos_v2', JSON.stringify(ssos));
                    this.update({
                        requestGetNetwork: false,
                    });
                    return ssos;
                },
                onError: function () {
                    this.update({
                        requestGetNetwork: false,
                    });
                },
            }),
            httpRequest({
                name: 'getSettings',
                type: 'post',
                baseRoute: 'sso',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: function (servicesSettings) {
                    const rtiToken = servicesSettings.find(({ service }) => service === 'cheq')?.settings.key;
                    if (rtiToken) {
                        this.reduxStore.dispatch(setRTIToken(rtiToken));
                    }
                    this.reduxStore.dispatch(setServices(servicesSettings));
                    return { servicesSettings };
                },
            }),
            httpRequest({
                name: 'updateSettings',
                type: 'post',
                baseRoute: 'sso',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: function ({ settings, service }) {
                    return state => ({ servicesSettings: [...state.servicesSettings.filter(({ service: s }) => service !== s), { service, settings }] });
                },
                postRequest: function () {
                    this.reduxStore.dispatch(getDashboardSettings());
                },
            }),
            httpRequest({
                name: 'getGoogleAdsAudienceExclusions',
                type: 'get',
                baseRoute: 'sso',
                route: 'google/audience_exclusion',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    this.update({
                        requestingGoogleAdsAudienceExclusions: true,
                    });
                },
                updateState: googleAdsAudienceExclusions => state => ({ ...state, googleAdsAudienceExclusions }),
                postRequest: function () {
                    this.update({
                        requestingGoogleAdsAudienceExclusions: false,
                    });
                },
                onError: function (error) {
                    this.update({
                        requestingGoogleAdsAudienceExclusions: false,
                    });
                    console.error(error);
                    this.reduxStore.dispatch(triggerSnackbarMessage({ message: 'Sorry, something went wrong' }));
                },
            }),
            httpRequest({
                name: 'saveGoogleAdsAudienceExclusions',
                type: 'post',
                baseRoute: 'sso',
                route: 'google/audience_exclusion',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    this.update({
                        requestSaveGoogleAdsAudienceExclusions: true,
                    });
                },
                postRequest: function (result, params) {
                    this.update({
                        requestSaveGoogleAdsAudienceExclusions: false,
                    });
                    if (!result.success) {
                        console.error(result);
                        return this.reduxStore.dispatch(triggerSnackbarMessage({ message: 'Sorry, something went wrong' }));
                    }
                    this.update({
                        googleAdsAudienceExclusions: {},
                    });
                    // this.reduxStore.dispatch(triggerSnackbarMessage({ message: 'Google Ads Audience Exclusion Saved Successfully!' }));
                    return { success: true };
                },
                onError: function (error) {
                    this.update({
                        requestSaveGoogleAdsAudienceExclusions: false,
                    });
                    console.error(error);
                    this.reduxStore.dispatch(triggerSnackbarMessage({ message: 'Sorry, something went wrong' }));
                    return { error };
                },
            }),
        ]);
