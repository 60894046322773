import Base from '../base';
import Toast from '../../../services/notifications';
import ContentManager from '../../../services/content-manager';
import { applyAuth0AuthorizationHeader } from '@utils/http/view-models';

export default ({ httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor() {
                    super('users');
                    this.state = { defaultUserPermissions: {}, allUsers: [] };
                }

                init(permissions) {
                    this.update(s => ({ ...s, permissions: permissions.user }));
                    if (permissions.user) {
                        // this.getChildrensDefaultPermissions();
                        return this;
                    }
                }
            }
        ).from([
            httpRequest({
                name: 'getChildrensDefaultPermissions',
                type: 'post',
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                onError: handleError,
                postRequest: function (data, params) {
                    const childrenTypes = data.reduce((total, { user_type }) => (total.includes(user_type) ? total : [...total, user_type]), []);
                    this.update(s => ({
                        ...s,
                        childrenTypes: childrenTypes.map(user_type => {
                            const getUserTypeLabel = userType => {
                                let label = '';
                                switch (userType) {
                                    case 'user_admin':
                                        label = 'Admin';
                                        break;
                                    case 'user_manager':
                                        label = 'Manager';
                                        break;
                                    case 'user':
                                        label = 'Viewer';
                                        break;
                                    default:
                                        label = userType;
                                        break;
                                }
                                return label;
                            };
                            return { userType: user_type, label: getUserTypeLabel(user_type) };
                        }),
                    }));
                    this.getByType({ types: childrenTypes });
                },
            }),
            httpRequest({
                name: 'updateUser',
                type: 'post',
                route: 'update',
                onError: handleError,
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                postRequest: function (data, params) {
                    this.update(s => ({ ...s, allUsers: s.allUsers.map(user => (user.id !== params.id ? user : { ...user, ...params })) }));
                },
            }),
            httpRequest({
                name: 'onDelete',
                type: 'post',
                route: 'delete',
                onError: handleError,
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                postRequest: function (data, params) {
                    this.update(s => ({ ...s, allUsers: s.allUsers.filter(({ id }) => id !== params.id) }));
                },
            }),
            httpRequest({
                name: 'create',
                type: 'post',
                onError: handleError,
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                postRequest: function ({ data }, params) {
                    const user = {
                        ...data,
                        permissions: data.permissions.reduce((perm, { model, action }) => {
                            perm[model] = [...(perm[model] || []), action];
                            return perm;
                        }, {}),
                    };
                    this.update(s => ({ ...s, allUsers: [...s.allUsers, user] }));
                },
            }),
            httpRequest({
                name: 'getByType',
                type: 'post',
                onError: handleError,
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                postRequest: function (data, params) {
                    const allUsers = data.map(({ name, username, email, dateCreated, userTypeName, active, id, permissions }) => ({
                        permissions,
                        name,
                        username,
                        email,
                        dateCreated,
                        userType: userTypeName,
                        active,
                        id,
                    }));
                    this.update(s => ({ ...s, allUsers: allUsers }));
                },
            }),
            // httpRequest({
            //     name: 'getUserTypeHierarchy',
            //     type: 'post',
            //     onError: handleError,
            //     postRequest: function (data, params) {
            //         this.update(s => ({...s, childrenTypes: data}))
            //     }
            // }),
            httpRequest({
                name: 'updatePassword',
                type: 'post',
                onError: handleError,
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                postRequest: function () {
                    Toast({ message: 'successfully updated password' });
                },
            }),
            httpRequest({
                name: 'userActivation',
                type: 'post',
                onError: handleError,
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                postRequest: function () {
                    const { protocol, host } = window.location;
                    window.location.href = `${protocol}//${host}/dashboard`;
                },
            }),
            httpRequest({
                name: 'activation',
                type: 'post',
                onError: handleError,
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                postRequest: function () {
                    const { protocol, host } = window.location;
                    window.location.href = `${protocol}//${host}/dashboard`;
                },
            }),
            httpRequest({
                name: 'activeToggle',
                type: 'post',
                onError: handleError,
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                postRequest: function (data, params) {
                    this.update(s => ({ ...s, allUsers: s.allUsers.map(user => (user.id !== params.id ? user : params)) }));
                },
            }),
            httpRequest({
                name: 'updateUserProfile',
                type: 'post',
                onError: handleError,
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                postRequest: function (data, params) {
                    const userData = Object.entries(params).reduce((total, [key, value]) =>
                        ['password', 'oldPassword'].includes(key) ? total : Object.assign(total, { [key]: value })
                    );
                    this.update(s => ({ ...s, allUsers: s.allUsers.map(user => (user.id !== params.id ? user : { ...user, ...userData })) }));
                },
            }),
            httpRequest({
                name: 'isValidActivation',
                type: 'post',
                preRequest: function (params) {
                    applyAuth0AuthorizationHeader.call(this)
                },
                onError: () => Toast({ message: 'Activation link expired' }),
                postRequest: function (data, params) {
                    this.update(s => ({ ...s, isValidActivationHash: data.isValid }));
                },
            }),
        ]);

function handleError(e, err) {
    let message;
    if (e.response && e.response.data) {
        message = ContentManager.get(`AccountManagement.Users.Error.${e.response.data}`);
    } else if (err) {
        message = ContentManager.get(`AccountManagement.Users.Error.${err}`);
    } else message = 'GENERAL ERROR';
    Toast({ message });
}
