import React, { useCallback, useEffect, useState } from 'react';
import { isUndefined, omit } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import * as permissions from '../../../../../../utils/permissions';
import AudienceExclusion from './audience-exclusion/AudienceExclusion';
import PixelGuard from './pixel-guard/PixelGuard';
import Button from '../../../../../widgets/button';
import CampaignsTable from './CampaignsTable';
import CheqUI from '@combotag/cheq-ui';
import ClientCampaignsTable from './ClientCampaignsTable';
import ClientsTable from './ClientsTable';
import { IconWrapper } from '../../../../../widgets/icon-wrapper/index';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinearProgress from '../../../../../widgets/liniar-progress/index';
import Switch from '../../../../../widgets/switch/index';
import styles from './style.module.scss';
import { triggerSnackbarMessage } from '@redux/actions/snackbar';
import { useSplit } from '@hooks';
import { constants as splitConstants } from '@utils/split.io';
import integrators from '../../3parties';
import { Invalidator } from '../../../table-structures/accounts';
import pixelGuardService from '../../../../../../services/api/pixelGuard.service';

const { Meiosis } = CheqUI.React;
const { useActions, useMeiosis } = Meiosis;

const PIXEL_ACTIVE_STATUS = 1;
const PIXEL_INACTIVE_STATUS = 0;

const ProtectionManagement = ({ next, onInvalidated, onCancel }) => {
    const dispatch = useDispatch();
    const actions = useActions();
    const profile = useSelector(state => state.profile);
    const clickTrueTagsState = useSelector(state => state.clickTrueTags);
    const sso = useMeiosis(actions.sso.buildStream);
    const googleService = actions.sso.getService('google');
    const [googleCustomers, setGoogleCustomers] = useState(sso.googleCustomers);
    const [isIpBanIntegrated, setIsIpBanIntegrated] = useState(sso.isIpBanIntegrated);
    const [defaultIpBanList, setDefaultIpBanList] = useState(sso.defaultIpBanList);
    const [protectedCampaigns, setProtectedCampaigns] = useState(sso.protectedCampaigns);
    const [clientView, setClientView] = useState('clients');
    const [isAudienceTagIntegrated, setIsAudienceTagIntegrated] = useState(sso.isAudienceTagIntegrated);
    const [googleAdsAudienceExclusions, setGoogleAdsAudienceExclusions] = useState(undefined);
    const [isAudienceExclusionIntegrated, setIsAudienceExclusionIntegrated] = useState(sso.isAudienceExclusionIntegrated);
    const [pixelGuardToggleStatus, setPixelGuardToggleStatus] = useState(false);
    const [sortedPixelsGuard, setSortedPixelsGuard] = useState([]);
    const [editedClickTrueTags, setEditedClickTrueTags] = useState(clickTrueTagsState.tags);
    const [emptyPixel, setEmptyPixel] = useState(null);
    const { isOn: isGoogleSnsOn } = useSplit(splitConstants.SPLIT_GOOGLE_DISCONNECT_SNS);
    const { isOn: isGooglePixelGuardSplitOn } = useSplit(splitConstants.SPLIT_PARADOME_GOOGLE_PIXEL_GUARD);
    const { pxgVersion } = useSelector(state => state.settings);

    const isGooglePixelGuardOn = isGooglePixelGuardSplitOn && pxgVersion === 0;
    const clickTrueTags = clickTrueTagsState.tags;
    const clickTrueTagsStateLoading = clickTrueTagsState?.isLoading;
    const hasAuthenticator = actions.sso.hasAuthenticator('google');
    const isConnected = actions.sso.isConnected('google');
    const Integration = integrators['google'];
    const initialProtectedCampaigns = sso.protectedCampaigns;
    const isAuthorized = (model, action) => permissions.isAuthorized(profile.permissions, model, action);
    const dialogOptions = {
        width: 'auto',
        noCancel: true,
        Header: () => <div />,
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalStyle: {
            display: 'flex',
            margin: 0,
            padding: 0,
        },
    };

    const setIsClientProtected = (clientId, value) => {
        const clientIdx = googleCustomers.findIndex(customer => clientId === customer.clientId);
        value = isUndefined(value) ? !googleCustomers[clientIdx].isProtected : value;
        setGoogleCustomers([
            ...googleCustomers.slice(0, clientIdx),
            {
                ...googleCustomers[clientIdx],
                isProtected: value,
            },
            ...googleCustomers.slice(clientIdx + 1),
        ]);
        setProtectedCampaigns(omit(protectedCampaigns, clientId));
        if (clientView !== 'clients' && clientView !== 'campaigns' && clientView !== 'googleAdsAudienceExclusion') {
            setClientView({ ...googleCustomers[clientIdx], isProtected: value });
        }
    };

    const areEveryoneProtected = googleCustomers && googleCustomers.every(({ isProtected }) => isProtected);
    const showClientCampaignsTable = clientView !== 'clients' && clientView !== 'campaigns' && clientView !== 'audience-exclusion' && clientView !== 'pixel-guard-view';

    const onToggleAccountsProtectAll = () => {
        setGoogleCustomers(
            googleCustomers.map(googleCustomer => ({
                ...googleCustomer,
                isProtected: !areEveryoneProtected,
            }))
        );
        setProtectedCampaigns({});
    };

    const onToggleCampaignsProtectAll = clientId => () => {
        const clientCampaigns = googleCustomers.find(customer => customer.clientId === clientId).campaigns;
        const isProtectedAll = protectedCampaigns[clientId] && clientCampaigns.every(({ id }) => protectedCampaigns[clientId].includes(id));

        if (isProtectedAll) {
            setIsClientProtected(clientId, false);
        } else {
            setIsClientProtected(clientId, true);
            setProtectedCampaigns({
                ...protectedCampaigns,
                [clientId]: clientCampaigns.map(({ id }) => id),
            });
        }
    };

    const toggleProtectCampaign = (clientId, campaignId, toAdd) => {
        const protectedCampaignsForClient = protectedCampaigns[clientId];
        if (toAdd) {
            if (!protectedCampaignsForClient) {
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: [campaignId],
                });
                const clientIdx = googleCustomers.findIndex(customer => clientId === customer.clientId);
                setGoogleCustomers([
                    ...googleCustomers.slice(0, clientIdx),
                    {
                        ...googleCustomers[clientIdx],
                        isProtected: true,
                    },
                    ...googleCustomers.slice(clientIdx + 1),
                ]);
                if (clientView !== 'clients' && clientView !== 'campaigns') {
                    setClientView({ ...googleCustomers[clientIdx], isProtected: true });
                }
            } else {
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: [...protectedCampaignsForClient, campaignId],
                });
            }
        } else {
            if (!protectedCampaignsForClient) {
                //remove 1 campaign from fully protected mode - so, batch insert other campaigns to protected cmapaigns
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: googleCustomers
                        .find(customer => customer.clientId === clientId)
                        .campaigns.filter(({ id }) => id !== campaignId)
                        .map(({ id }) => id),
                });
            } else {
                if (protectedCampaignsForClient.length === 1) {
                    setIsClientProtected(clientId, false);
                } else {
                    setProtectedCampaigns({
                        ...protectedCampaigns,
                        [clientId]: protectedCampaignsForClient.filter(id => campaignId !== id),
                    });
                }
            }
        }
    };

    const onChangeProtectionMode = clientId => protectionMode => {
        switch (protectionMode) {
            case 'disabled':
                return setIsClientProtected(clientId, false);
            case 'default':
                return setIsClientProtected(clientId, true);
            case 'campaigns':
                setIsClientProtected(clientId, true);
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: googleCustomers.find(customer => customer.clientId === clientId).campaigns.map(({ id }) => id),
                });
                break;
            case 'label':
                setIsClientProtected(clientId, true);
                setProtectedCampaigns({
                    ...protectedCampaigns,
                    [clientId]: ['LABEL'],
                });
                break;
            default:
                return;
        }
    };

    const saveGoogleAudienceExclusions = async () => {
        if (!isAudienceExclusionIntegrated) return { success: true };

        return actions.sso.saveGoogleAdsAudienceExclusions({
            exclusions: {
                created: googleAdsAudienceExclusions?.filter(ex => ex.id < 0 && !ex.isDeleted),
                updated: googleAdsAudienceExclusions?.filter(ex => ex.id > 0 && !ex.isDeleted && ex.isDirty),
                deleted: googleAdsAudienceExclusions?.filter(ex => ex.id > 0 && ex.isDeleted),
            },
        });
    };

    const createSortedPixelGuardsData = clickTrueTagsData => {
        const data =
            clickTrueTagsData
                .map(tag => {
                    const protectionConfig = JSON.parse(tag.protectionConfig) || {};
                    if (protectionConfig.pxg && protectionConfig.pxg.trackingIds) {
                        return protectionConfig.pxg.trackingIds.map(pixel => ({
                            active: pixel.active,
                            pixelId: pixel.pixelId.replace('AW-', ''),
                            tagId: tag.id,
                        }));
                    }
                })
                .filter(element => !!element)
                .flat() || [];

        if (emptyPixel) {
            data.push(emptyPixel);
        }

        return data;
    };

    const isInvalidPixelInSortedPixelsGuards = () => {
        const foundedInvalidGoogleTagId = sortedPixelsGuard.some(pixel => !pixel.pixelId.length);
        const foundedInvalidCheqTag = sortedPixelsGuard.some(pixel => !pixel.tagId);
        if (foundedInvalidGoogleTagId) {
            return { isValid: false, errMessage: 'Google Tag ID is mandatory for Pixel Guard' };
        } else if (foundedInvalidCheqTag) {
            return { isValid: false, errMessage: 'Tag is mandatory for Pixel Guard' };
        }
        return { isValid: true, errMessage: '' };
    };

    const buildPixelGuardData = () => {
        const pixelGuardChanges = { success: true, changes: [], errMessage: '' };
        const sortedPixelsStatus = isInvalidPixelInSortedPixelsGuards();
        if (sortedPixelsStatus.isValid) {
            const pixelsData = {};
            for (const pixel of sortedPixelsGuard) {
                const pixelIdlValue = pixel.pixelId.startsWith('AW-') ? pixel.pixelId : 'AW-' + pixel.pixelId;
                const pixelValue = { pixelId: pixelIdlValue, active: pixelGuardToggleStatus ? pixel.active : PIXEL_INACTIVE_STATUS };
                if (pixelsData[pixel.tagId]) {
                    pixelsData[pixel.tagId].pxg.trackingIds.push(pixelValue);
                } else {
                    pixelsData[pixel.tagId] = {
                        pxg: { trackingIds: [pixelValue] },
                    };
                }
            }
            const parsedPixels = Object.keys(pixelsData).map(key => ({ tagId: Number(key), ...pixelsData[key] })) || [];
            pixelGuardChanges.changes = parsedPixels;
        } else {
            pixelGuardChanges.success = false;
            pixelGuardChanges.errMessage = sortedPixelsStatus.errMessage;
        }

        return pixelGuardChanges;
    };

    const validateGoogleAudienceExclusions = () => {
        if (!isAudienceExclusionIntegrated) return true;

        let validated = true;

        const savedExclusions = googleAdsAudienceExclusions?.filter(ex => !ex.isDeleted);
        if (savedExclusions?.some(ex => !ex.clickTrueTagId)) {
            dispatch(triggerSnackbarMessage({ message: 'Tag is mandatory for audience-exclusion rule' }));
            validated = false;
        }

        if (!validated) setClientView('audience-exclusion');

        return validated;
    };

    const handleSaveGoogleManagement = async () => {
        const buildedPixelsGuard = buildPixelGuardData();
        if (buildedPixelsGuard.success) {
            if (!sso.requestSaveManagement && !sso.requestSaveGoogleAdsAudienceExclusions && validateGoogleAudienceExclusions()) {
                const updateIpsForNewelyProtectedCampaigns = Object.keys(protectedCampaigns).some(clientId => {
                    return !initialProtectedCampaigns[clientId] || initialProtectedCampaigns[clientId].length < protectedCampaigns[clientId].length;
                });

                const isPixelsGuardIntegrated = checkIfPixelsGuardIntegrated(buildedPixelsGuard.changes);

                const saveManagementRequestObject = {
                    googleCustomers: googleCustomers
                        .filter(({ isProtected }) => isProtected)
                        .map(({ isProtected, clientId }) => ({
                            isProtected,
                            clientId,
                        })),
                    updateIpsForNewelyProtectedCampaigns,
                    isIpBanIntegrated,
                    defaultIpBanList,
                    isAudienceTagIntegrated,
                    isPixelsGuardIntegrated,
                    isAudienceExclusionIntegrated,
                    protectedCampaigns,
                    pixelsGuard: buildedPixelsGuard.changes,
                    isIPExclusionStatusChanged:
                        ((sso.isIpBanIntegrated === undefined || !sso.isIpBanIntegrated) && isIpBanIntegrated) ||
                        (sso.isIpBanIntegrated === true && sso.isIpBanIntegrated !== isIpBanIntegrated),
                };

                const saveManagementResult = await actions.sso.saveGoogleManagement(saveManagementRequestObject, dispatch);

                const saveExclusionsResult = await saveGoogleAudienceExclusions();

                await actions.clickTrueTags.getAll();

                //if we're enabling pxg v1 integration, remove pxg v2 integration
                if (isPixelsGuardIntegrated) {
                    await pixelGuardService.disableFeature(2);
                    await pixelGuardService.deletePxgV2Setting();
                }

                if (saveManagementResult?.success && saveExclusionsResult?.success) {
                    sso.googleAdsAudienceExclusions = null;
                    return next();
                }

                console.error({
                    saveManagementResult,
                    saveExclusionsResult,
                });
            }
        } else {
            clientView !== 'pixel-guard-view' && setClientView('pixel-guard-view');
            dispatch(triggerSnackbarMessage({ message: buildedPixelsGuard.errMessage }));
        }
    };

    const checkIfPixelsGuardIntegrated = pixelsGuard => {
        let isPixelGuardIntegrated = false;
        if (pixelsGuard && pixelsGuard.length) {
            const allPixelsTracking = pixelsGuard.map(pixel => pixel.pxg.trackingIds).flat();
            for (const pixelTracking of allPixelsTracking) {
                if (pixelTracking.active) {
                    isPixelGuardIntegrated = true;
                    break;
                }
            }
        }

        return isPixelGuardIntegrated;
    };

    const isPixelToggleActive = tags => {
        return tags.some(
            tag =>
                JSON.parse(tag.protectionConfig) &&
                JSON.parse(tag.protectionConfig).pxg &&
                JSON.parse(tag.protectionConfig).pxg.trackingIds.some(pixel => pixel.active === PIXEL_ACTIVE_STATUS)
        );
    };

    const resetGoogleAdsAudienceExclusions = useCallback(() => {
        if (sso.googleAdsAudienceExclusions?.items && sso.googleAdsAudienceExclusions?.items.length > 0) {
            const initialGoogleAdsAudienceExclusions = sso.googleAdsAudienceExclusions?.items.map(i => ({ ...i }));
            setGoogleAdsAudienceExclusions(initialGoogleAdsAudienceExclusions);
        }
    }, [sso.googleAdsAudienceExclusions?.items]);

    const handleCancel = () => {
        resetGoogleAdsAudienceExclusions();
        return onCancel && onCancel();
    };

    const handleBack = () => {
        resetGoogleAdsAudienceExclusions();
        return googleService.invalidate({ isGoogleSnsOn }, onInvalidated).then(() => actions.clickTrueTags.getAll());
    };

    useEffect(() => {
        setIsAudienceTagIntegrated(sso.isAudienceTagIntegrated);
        setIsAudienceExclusionIntegrated(sso.isAudienceExclusionIntegrated);
        setGoogleCustomers(sso.googleCustomers);
        setIsIpBanIntegrated(sso.isIpBanIntegrated);
        setProtectedCampaigns(sso.protectedCampaigns);
        setDefaultIpBanList(sso.defaultIpBanList);
    }, [sso.googleCustomers, sso.isIpBanIntegrated, sso.defaultIpBanList, sso.isAudienceTagIntegrated, sso.protectedCampaigns, sso.isAudienceExclusionIntegrated]);

    useEffect(() => {
        if (sso.ssos.google.status === 'MANAGED') {
            actions.sso.fetchGoogleManagement();
        }
    }, [actions.sso, sso.ssos.google.status]);

    useEffect(() => {
        if (sso.ssos.google.refresh_token || sso.ssos.google.is_sso) {
            actions.sso.fetchGoogleManagement();
        }
    }, [actions.sso, sso.ssos.google.refresh_token, sso.ssos.google.is_sso]);

    useEffect(() => {
        if (sso.ssos.google.skippedAdwords) {
            actions.sso.fetchGoogleManagement();
        }
    }, [actions.sso, sso.ssos.google.skippedAdwords]);

    useEffect(() => {
        if (!googleAdsAudienceExclusions) {
            resetGoogleAdsAudienceExclusions();
        }
    }, [googleAdsAudienceExclusions, resetGoogleAdsAudienceExclusions]);

    useEffect(() => {
        if (!sso.googleAdsAudienceExclusions?.success && !sso.requestingGoogleAdsAudienceExclusions && isAudienceExclusionIntegrated) {
            actions.sso.getGoogleAdsAudienceExclusions();
        }
    }, [actions.sso, isAudienceExclusionIntegrated, sso.googleAdsAudienceExclusions?.success, sso.requestingGoogleAdsAudienceExclusions]);

    useEffect(() => {
        setPixelGuardToggleStatus(isPixelToggleActive(clickTrueTagsState.tags));
        clickTrueTagsState.tags.length && setSortedPixelsGuard(createSortedPixelGuardsData(clickTrueTagsState.tags));
    }, []);

    useEffect(() => {
        !editedClickTrueTags.length && clickTrueTagsState.tags.length && setEditedClickTrueTags(clickTrueTagsState.tags);
        if (editedClickTrueTags && clickTrueTagsState.tags && clickTrueTagsState.tags.length && !sortedPixelsGuard.length) {
            setSortedPixelsGuard(createSortedPixelGuardsData(clickTrueTagsState.tags));
            if (!pixelGuardToggleStatus) {
                setPixelGuardToggleStatus(isPixelToggleActive(clickTrueTagsState.tags));
            }
        }
    }, [clickTrueTagsState.tags]);

    if (sso.requestGoogleManagement || !googleCustomers) {
        return (
            <>
                <LinearProgress />
                <div className={styles.protectionManagementContainer}>
                    <div className={styles.protectionManagementTitle}>PROTECTION MANAGEMENT</div>
                    <div className={`${styles.protectionManagementContent} ${styles.messageContainer}`}>
                        <div>Fetching your accounts and campaigns from Google</div>
                    </div>
                </div>
            </>
        );
    }

    if (sso.errorGoogleManagement) {
        return (
            <div className={styles.protectionManagementContainer}>
                <div className={styles.protectionManagementTitle}>PROTECTION MANAGEMENT</div>
                <div className={`${styles.protectionManagementContent} ${styles.messageContainer}`}>
                    <div className={styles['error']}>{sso.errorGoogleManagement || 'Unexpected Error Accured'}</div>
                </div>
            </div>
        );
    }

    return (
        <>
            <OperationProgress sso={sso} />
            <div className={styles.protectionManagementContainer}>
                <div className={styles.protectionManagementHeader}>
                    <div className={styles.protectionManagementTitle}>PROTECTION MANAGEMENT</div>
                    {isConnected && hasAuthenticator && <div className={styles.accountConnectionTitle}>Account Connection</div>}
                    {isConnected && hasAuthenticator && (
                        <Switch
                            className={styles.accountConnectionToggle}
                            type="checkbox"
                            checkedLabel={'On'}
                            uncheckedLabel={'Off'}
                            checked={hasAuthenticator}
                            onChange={e => actions.dialog.show(<Invalidator serviceName={'google'} />, dialogOptions)}
                            style={{ marginRight: 0 }}
                            disabled={!Integration ? true : !isAuthorized('ip_block', 'write')}
                        />
                    )}
                </div>
                <div className={styles.protectionManagementContent}>
                    <div className={styles.leftColumn}>
                        <div className={styles.protectionFeaturesHeader}>Protection Features</div>
                        <div className={styles.protectionFeaturesBody}>
                            <ProtectionFeatureToggle
                                title="IP Exclusion"
                                tooltip="Cheq will protect your campaigns with real-time IP Exclusions"
                                disabled={sso.ssos.google.skippedAdwords}
                                checked={!!isIpBanIntegrated}
                                onChange={() => setIsIpBanIntegrated(!isIpBanIntegrated)}
                            />
                            {isIpBanIntegrated && (
                                <div className={styles.ipBanList}>
                                    <div className={styles.ipListLabel}>You can also insert your own list of IP addresses, separated by new line and up to 100 addresses.</div>
                                    <textarea
                                        value={defaultIpBanList?.join('\n')}
                                        className={styles.ipList}
                                        placeholder={'1.1.1.1'}
                                        onChange={({ target: { value } }) => {
                                            setDefaultIpBanList(value.split('\n').map(ip => ip.trim()));
                                        }}
                                    />
                                </div>
                            )}
                            <ProtectionFeatureToggle
                                title="Audience Tag"
                                subTitle="(Google Analytics)"
                                tooltip="Cheq will protect your campaigns with detargeting fraudulent users."
                                checked={!!isAudienceTagIntegrated}
                                onChange={() => setIsAudienceTagIntegrated(!isAudienceTagIntegrated)}
                            />
                            <ProtectionFeatureToggle
                                title="Audience Exclusion"
                                subTitle="(Google Ads)"
                                tooltip="Cheq will protect your campaigns with detargeting fraudulent users."
                                checked={!!isAudienceExclusionIntegrated}
                                onChange={() => {
                                    setIsAudienceExclusionIntegrated(!isAudienceExclusionIntegrated);
                                    if (!isAudienceExclusionIntegrated === false) {
                                        setClientView('clients');
                                    } else {
                                        setClientView('audience-exclusion');
                                    }
                                }}
                            />
                            {isGooglePixelGuardOn && (
                                <ProtectionFeatureToggle
                                    title="Pixel Guard"
                                    subTitle=""
                                    tooltip="Use Pixel Guard to further protect your campaigns by preventing them from optimizing on invalid traffic. Pixel guard is the only way to secure Performance Max Campaigns."
                                    checked={pixelGuardToggleStatus}
                                    onChange={() => {
                                        setPixelGuardToggleStatus(!pixelGuardToggleStatus);
                                        if (!pixelGuardToggleStatus === false) {
                                            setClientView('clients');
                                        } else {
                                            setClientView('pixel-guard-view');
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.rightColumn}>
                        {showClientCampaignsTable ? (
                            <ClientCampaignsTable
                                onClose={() => setClientView('clients')}
                                client={clientView}
                                protectedCampaigns={protectedCampaigns}
                                setIsClientProtected={setIsClientProtected}
                                toggleProtectCampaign={toggleProtectCampaign}
                                onChangeProtectionMode={onChangeProtectionMode(clientView.clientId)}
                                onToggleCampaignsProtectAll={onToggleCampaignsProtectAll(clientView.clientId)}
                                protectionMode={
                                    !clientView.isProtected
                                        ? 'disabled'
                                        : protectedCampaigns[clientView.clientId] && clientView.campaigns.length
                                        ? protectedCampaigns[clientView.clientId].includes('LABEL')
                                            ? 'label'
                                            : 'campaigns'
                                        : 'default'
                                }
                            />
                        ) : (
                            <>
                                <div className={styles.tabsContainer}>
                                    <div
                                        onMouseDown={e => e.preventDefault()}
                                        onClick={() => setClientView('clients')}
                                        className={styles.tab + ' ' + (clientView === 'clients' && styles.selectedTab)}
                                    >
                                        Accounts
                                    </div>
                                    <div
                                        onMouseDown={e => e.preventDefault()}
                                        onClick={() => setClientView('campaigns')}
                                        className={styles['tab'] + ' ' + (clientView === 'campaigns' && styles.selectedTab)}
                                    >
                                        Campaigns
                                    </div>

                                    {isAudienceExclusionIntegrated && (
                                        <div
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={() => setClientView('audience-exclusion')}
                                            className={styles['tab'] + ' ' + (clientView === 'audience-exclusion' && styles.selectedTab)}
                                        >
                                            Audience Exclusion
                                        </div>
                                    )}
                                    {pixelGuardToggleStatus && isGooglePixelGuardOn && (
                                        <div
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={() => setClientView('pixel-guard-view')}
                                            className={styles['tab'] + ' ' + (clientView === 'pixel-guard-view' && styles.selectedTab)}
                                        >
                                            Pixel Guard
                                        </div>
                                    )}
                                </div>
                                <div style={{ display: clientView === 'clients' ? 'flex' : 'none' }} className={styles.tabsBody}>
                                    <ClientsTable
                                        {...{
                                            areEveryoneProtected,
                                            onToggleAccountsProtectAll,
                                            setIsClientProtected,
                                            setClientView,
                                            protectedCampaigns,
                                            googleCustomers,
                                            sso,
                                        }}
                                    />
                                </div>
                                <div style={{ display: clientView === 'campaigns' ? 'flex' : 'none' }} className={styles.tabsBody}>
                                    <CampaignsTable
                                        {...{
                                            areEveryoneProtected,
                                            toggleProtectCampaign,
                                            onToggleAccountsProtectAll,
                                            setIsClientProtected,
                                            setClientView,
                                            protectedCampaigns,
                                            googleCustomers,
                                            sso,
                                        }}
                                    />
                                </div>
                                {isAudienceExclusionIntegrated && (
                                    <div style={{ display: clientView === 'audience-exclusion' ? 'flex' : 'none' }} className={styles.tabsBody}>
                                        <AudienceExclusion
                                            {...{
                                                clickTrueTags,
                                                audienceExclusions: googleAdsAudienceExclusions,
                                                setAudienceExclusions: setGoogleAdsAudienceExclusions,
                                                isLoading: sso.requestingGoogleAdsAudienceExclusions || clickTrueTagsStateLoading,
                                            }}
                                        />
                                    </div>
                                )}
                                {pixelGuardToggleStatus && isGooglePixelGuardOn && (
                                    <div style={{ display: clientView === 'pixel-guard-view' ? 'flex' : 'none' }} className={styles.tabsBody}>
                                        <PixelGuard
                                            clickTrueTags={editedClickTrueTags}
                                            setEditedClickTrueTags={setEditedClickTrueTags}
                                            emptyPixel={emptyPixel}
                                            setEmptyPixel={setEmptyPixel}
                                            sortedPixelsGuard={sortedPixelsGuard}
                                            setSortedPixelsGuard={setSortedPixelsGuard}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={styles['footer']}>
                    {onInvalidated && (
                        <Button style={{ width: 150 }} onClick={handleBack}>
                            BACK
                        </Button>
                    )}
                    {onCancel && (
                        <Button style={{ width: 150 }} onClick={handleCancel}>
                            CANCEL
                        </Button>
                    )}
                    <Button style={{ width: 150 }} onClick={handleSaveGoogleManagement}>
                        SAVE
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ProtectionManagement;

const OperationProgress = ({ sso }) => {
    return sso.requestSaveManagement || sso.services.google.state.requestingInvalidate ? <LinearProgress /> : <></>;
};

const ProtectionFeatureToggle = props => {
    const { title, subTitle, tooltip, ...restProps } = props;
    return (
        <div className={styles.protectionFeatureToggle}>
            <div>
                <IconWrapper className={styles.info} icon={<InfoOutlinedIcon style={{ height: 14, width: 14 }} />} tooltip={tooltip} />
                <div className={styles.protectionFeatureLabel}>
                    <div>{title}</div>
                    {subTitle && <div>{subTitle}</div>}
                </div>
            </div>
            <div className={styles.protectionFeatureSwitch}>
                <Switch {...restProps} type="checkbox" checkedLabel={'On'} uncheckedLabel={'Off'} />
            </div>
        </div>
    );
};
