export type SelectItem<T = string> = {
    value: T;
    label: React.ReactNode;
    disabled?: boolean;
};

export enum FilterWidth {
    WIDE = 'wide',
    NARROW = 'narrow',
}

export enum FilterHeight {
    NARROW = 'narrow',
}
