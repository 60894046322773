import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    container: {
        width: '100%',
        minHeight: 110,
        backgroundColor: theme.palette.background.component,
        paddingLeft: 19,
        paddingRight: 19,
        zIndex: 10,
    },
    containerTitle: {
        fontSize: 18,
        color: '#fff',
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'left',
    },
    track: {
        width: 310,
        height: 11,
        backgroundColor: theme.palette.custom.text.primary
    },
    rail: {
        width: 310,
        height: 11,
        opacity: 1,
        backgroundColor: theme.palette.background.default
    },
    thumb: {
        width: 20,
        height: 20,
        color: '#fff'
    },
    tooltip:{
        width:50,
        height: 25,
        backgroundColor: theme.palette.background.default,
        fontSize: 18,
        textAlign: 'center',
        borderRadius: 0,
        boxShadow: ' -7px 0px 6px 0 rgba(0, 0, 0, 0.14)'
    }
}))