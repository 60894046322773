export const snackBar = {
    SNACKBAR_MESSAGE: 'SNACKBAR_MESSAGE',
};

export const dashboard = {
    DASHBOARD_SETTINGS_LOADING: 'DASHBOARD_SETTINGS_LOADING',
    DASHBOARD_SETTINGS_UPDATED: 'DASHBOARD_SETTINGS_UPDATED',
    DASHBOARD_DATA_LOADING: 'DASHBOARD_DATA_LOADING',
    DASHBOARD_DATA_UPDATED: 'DASHBOARD_DATA_UPDATED',
    SET_DASHBOARD_DISPLAY_OPTION: 'SET_DASHBOARD_DISPLAY_OPTION',
    SET_SELECTED_PLATFORMS_GROUP: 'SET_SELECTED_PLATFORMS_GROUP',
    SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
    SET_POLICY_TOGGLE_OPTIONS: 'SET_POLICY_TOGGLE_OPTIONS',
    DASHBOARD_REQUEST_FAILED: 'DASHBOARD_REQUEST_FAILED',
    GET_COMPONENTS_UP: 'GET_COMPONENTS_UP',
    GET_COMPONENTS_DOWN: 'GET_COMPONENTS_DOWN',
    GET_POTENTIAL_REPLACED_VISITS_COMPONENT: 'GET_POTENTIAL_REPLACED_VISITS_COMPONENT',
    GET_CALCULATED_REPLACED_VISITS_COMPONENT: 'GET_CALCULATED_REPLACED_VISITS_COMPONENT',
    GET_BLOCKED_ENTITIES_COMPONENT: 'GET_BLOCKED_ENTITIES_COMPONENT',
    SET_RTI_SETTINGS: 'SET_RTI_SETTINGS',
    SET_SELECTED_CHANNEL: 'SET_SELECTED_CHANNEL',
    GET_INVALID_BY_URL: 'GET_INVALID_BY_URL',
    GET_INVALID_BY_CAMPAIGNS: 'GET_INVALID_BY_CAMPAIGNS',
    GET_INVALID_BY_REASON: 'GET_INVALID_BY_REASON',
    GET_INVALID_BY_KEYWORD: 'GET_INVALID_BY_KEYWORD',
    GET_INVALID_BY_COUNTRIES: 'GET_INVALID_BY_COUNTRIES',
    RTI_DASHBOARD_LOADING: 'RTI_DASHBOARD_LOADING',
    GET_RTI_INVALID_INSIGHTS: 'GET_RTI_INVALID_INSIGHTS',
    GET_RTI_INTERCEPTIONS: 'GET_RTI_INTERCEPTIONS',
    GET_RTI_VISITS_SUMMARY: 'GET_RTI_VISITS_SUMMARY',
    GET_RTI_PAGEVIEWS: 'GET_RTI_PAGEVIEWS',
    RTI_DASHBOARD_UPDATED: 'RTI_DASHBOARD_UPDATED',
    RTI_DASHBOARD_ERROR: 'RTI_DASHBOARD_ERROR',
    RTI_SET_FILTER: 'RTI_SET_FILTER',
};

export const profile = {
    SET_PROFILE_DATA: 'SET_PROFILE_DATA',
    SET_DEFAULT_PRODUCT: 'SET_DEFAULT_PRODUCT',
    SET_META: 'SET_META',
    SET_LANGUAGE: 'SET_LANGUAGE',
    SET_TIMEZONE: 'SET_TIMEZONE',
    SET_NAME: 'SET_NAME',
    SET_USERNAME: 'SET_USERNAME',
    SET_CONTENT: 'SET_CONTENT',
    SET_PERMISSIONS: 'SET_PERMISSIONS',
    CONTENT_LOADING: 'CONTENT_LOADING',
    UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
    SET_USER_PROFILE_IS_LOADING: 'SET_USER_PROFILE_IS_LOADING',
    SET_FIRST_LOGIN: 'SET_FIRST_LOGIN',
    CHANGE_PASSWORD_IS_LOADING: 'CHANGE_PASSWORD_IS_LOADING',
};
export const users = {
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_USERS: 'SET_USERS',
    INVITE_USER: 'INVITE_USER',
    SET_CHILDREN_TYPE: 'SET_CHILDREN_TYPE',
    REMOVE_USER: 'REMOVE_USER',
    EDIT_USER: 'EDIT_USER',
    HANDLE_USER_STATUS: 'HANDLE_USER_STATUS',
};

export const analytics = {
    ANALYTICS_SET_SUMMARY_REPORT_SETTINGS: 'ANALYTICS_SET_SUMMARY_REPORT_SETTINGS',
    ANALYTICS_CLEAR_SUMMARY_REPORT_SETTINGS: 'ANALYTICS_CLEAR_SUMMARY_REPORT_SETTINGS',
    ANALYTICS_DATA_UPDATED: 'ANALYTICS_DATA_UPDATED',
    ANALYTICS_DATA_LOADING: ' ANALYTICS_DATA_LOADING',
    ANALYTICS_REQUEST_FAILED: 'ANALYTICS_REQUEST_FAILED',
    DELETE_REPORT_HISTORY: 'DELETE_REPORT_HISTORY',
    LOAD_REPORT_HISTORY: 'LOAD_REPORT_HISTORY',
    UPDATE_REPORT_TYPE: 'UPDATE_REPORT_TYPE',
    UPDATE_SUMMARY_REPORT_SETTINGS: 'UPDATE_SUMMARY_REPORT_SETTINGS',
    ANALYTICS_PLATFORMS_UPDATED: 'ANALYTICS_PLATFORMS_UPDATED',
    UPDATE_REPORT_HISTORY: 'UPDATE_REPORT_HISTORY',
};

export const skewed = {
    SET_TAB: 'SET_TAB',
    SET_REPORT_FILTERS: 'SET_REPORT_FILTERS',
};

export const settings = {
    SETTINGS_REQUEST_FAILED: 'SETTINGS_REQUEST_FAILED',
    SETTINGS_DATA_LOADING: 'SETTINGS_DATA_LOADING',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_NETWORK_FEATURES: 'SET_NETWORK_FEATURES',
    SET_NETWORK_FEATURE_SETTINGS: 'SET_NETWORK_FEATURE_SETTINGS',
    SET_SERVING_DOMAIN: 'SET_SERVING_DOMAIN',
    SET_DEDICATED_WLDOMAINS: 'SET_DEDICATED_WLDOMAINS',
    SET_GOOGLE_SCOPES: 'SET_GOOGLE_SCOPES',
    SET_RTI_TOKEN: 'SET_RTI_TOKEN',
    SET_IS_ENABLED_AFFILIATE_FRAUD: 'SET_IS_ENABLED_AFFILIATE_FRAUD',
    SET_IS_GA_CONNECTOR_ENABLED: 'SET_IS_GA_CONNECTOR_ENABLED',
    SET_PXG_VERSION: 'SET_PXG_VERSION',
    SET_DEDICATED_WLD: 'SET_DEDICATED_WLD',
    SET_SERVICES: 'SET_SERVICES',
    SET_CURRENT_LICENSE: 'SET_CURRENT_LICENSE',
    SET_UPDATED_LANGUAGE: 'SET_UPDATED_LANGUAGE',
};

export const ui = {
    SET_ALERT: 'SET_ALERT',
    CLEAR_ALERT: 'CLEAR_ALERT',
};

export const clickTrueTags = {
    CLICK_TRUE_TAGS_REQUEST_FAILED: 'CLICK_TRUE_TAGS_REQUEST_FAILED',
    CLICK_TRUE_TAGS_DATA_LOADING: 'CLICK_TRUE_TAGS_DATA_LOADING',
    SET_CLICK_TRUE_TAGS: 'SET_CLICK_TRUE_TAGS',
};

export const rtiDashboard = {
    SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
};
