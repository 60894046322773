import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles(theme => ({
	tabRoot : {
		minHeight:32,
		padding : '0 12px'
	},
	tabIndicator : {
		top : 32,
	},
	root: {
		width: 940,
		margin: 0,
		marginTop: 10,

		backgroundColor: theme.palette.background.paper,

		'& .MuiTab-root.Mui-selected': {
			backgroundColor: theme.palette.background.default,
			color: '#fff'
		},
		'& .MuiTabs-indicator': {
			backgroundColor: theme.palette.background.default
		},
		'& .MuiTabs-root': {
			color: theme.palette.custom.text.primary,
			backgroundColor: theme.palette.background.paper,
			minHeight:32,
		},
		'& .MuiTab-root': {
			color: theme.palette.custom.text.primary,
			backgroundColor: theme.palette.background.paper,
			minWidth: '0px !important',
		},
		'& .MuiBox-root': {
			padding: 0,
			backgroundColor: theme.palette.background.paper,
			// boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
		},
		'& .MuiAppBar-colorDefault': {
			// width: '166px !important',
			boxShadow: 'none'
		},
		'& .MuiTab-wrapper': {
			fontSize: 14,
			letterSpacing:'0.14px'
		}

	}
}));