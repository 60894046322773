import DashboardIcon from '@/assets/svgs/Dashboard.svg';
import ExploreIcon from '@/assets/svgs/Explore.svg';
import ReportsIcon from '@/assets/svgs/Reports.svg';
import SettingsIcon from '@assets/svgs/Settings.svg';
import PolicyIcon from '@/assets/svgs/Policy.svg';
import { createSolutionListByPackages } from 'multi-product/LandingPage/utils';

export type SidebarProduct = { value: number; label: string; tooltip: string; active: Boolean };

export const getProducts = (networkFeatures: any) => {
    const activeProducts = networkFeatures.map((item: { id: number; name: string }) => item.id);
    return products.map(item => {
        return {
            ...item,
            active: activeProducts.includes(item.value),
        };
    });
};

export const getProductByPackage = (networkFeatures: { id: number; name: string }[]) => {
    const resultObj = createSolutionListByPackages(networkFeatures);
    return (
        products &&
        products.reduce((acc: SidebarProduct[], item: SidebarProduct) => {
            if (resultObj[item.value] !== undefined) {
                acc.push({
                    ...item,
                    active: resultObj[item.value],
                });
            }
            return acc;
        }, [])
    );
};

export const products: SidebarProduct[] = [
    {
        value: 1,
        label: 'CustomerAcquisitionSecurity',
        tooltip: 'PaidMarketingTooltip',
        active: false,
    },
    {
        value: 2,
        label: 'OnSiteSecurity',
        tooltip: 'OnSiteConversionTooltip',
        active: false,
    },
    {
        value: 8,
        label: 'LeadProtection',
        tooltip: 'SlpTooltip',
        active: false,
    },
    {
        value: 3,
        label: 'MarketingIntelligenceSecurity',
        tooltip: 'DataAndAnalyticsTooltip',
        active: false,
    },
    {
        value: 5,
        label: 'PrivacyComplianceEnforcement',
        tooltip: 'PrivacyTooltip',
        active: false,
    },
];
export const MAP_PRODUCT_URL_NAME_TO_ID = products.reduce((mapObj, currProduct) => {
    return {
        ...mapObj,
        [currProduct.label
            .replace(/([A-Z])/g, '-$1')
            .substring(1)
            .toLowerCase()]: currProduct.value,
    };
}, {});

export const isProduct = (path: string) => Object.keys(MAP_PRODUCT_URL_NAME_TO_ID).includes(path);

// @ts-ignore
export const getIcon = (id: number) => {
    switch (id) {
        case 1:
            return DashboardIcon;
        case 2:
            return ExploreIcon;
        case 3:
            return ReportsIcon;
        case 4:
            return PolicyIcon;
        case 5:
            return SettingsIcon;
    }
};
