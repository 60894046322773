import { SelectItem } from '../types';

export const filterBySearch = (options: SelectItem[], searchedValue: string): SelectItem[] => {
    if (searchedValue?.length) {
        const multiSearchRegex = RegExp(/^\d+((\s*),(\s*)(\d+))+/);
        const isMultiSearch = searchedValue.match(multiSearchRegex);
        if (!isMultiSearch) {
            return options?.filter(item => item && item.label?.toString().toLowerCase().includes(searchedValue.toLowerCase()));
        } else {
            const searchValues = searchedValue.replace(/\s/g, '').split(',');
            const filtered = options?.filter(item =>
                searchValues.some(searchValue => {
                    const numericSearchValue = Number(searchValue);
                    return !isNaN(numericSearchValue) && searchValue === item.value;
                })
            );
            return filtered;
        }
    }
    return [];
};
