import React from 'react';
import cx from 'classnames';
import styles from './dashboard-section.module.scss';

export const DashboardSection = ({ title, className, children, ...rest }) => {
    return (
        <div className={styles.root} {...rest}>
            <span className={styles.sectionTitle}>{title}</span>
            <div className={cx(styles.content, className)}>{children}</div>
        </div>
    );
};
