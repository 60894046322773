import Checkbox from '../../../../../widgets/checkbox';
import Chip from '@material-ui/core/es/Chip/Chip';
import CloseSquareIcon from '../../../../../svgs/close-square/index';
import { IconWrapper } from '../../../../../widgets/icon-wrapper/index';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NoData from '../../../../../widgets/no-data/index';
import React from 'react';
import SelectBox from '../../../../../widgets/select-box/index';
import Table from '../../../../../widgets/table/index';
import styles from './style.module.scss';
import { useContentManager } from '@hooks';

const ClientCampaigns = ({
    onToggleCampaignsProtectAll,
    protectionMode,
    onChangeProtectionMode,
    onClose,
    protectedCampaigns,
    toggleProtectCampaign,
    client: { isProtected, name, clientId, campaigns = [] },
}) => {
    const cm = useContentManager();

    const protectedCampaignsForClient = protectedCampaigns[clientId];
    const protectedCampaignsCount = !isProtected
        ? 0
        : !protectedCampaignsForClient
        ? campaigns.length
        : protectedCampaignsForClient.includes('LABEL')
        ? campaigns.filter(({ byLabel }) => byLabel).length
        : campaigns.filter(({ id }) => protectedCampaignsForClient.includes(id)).length;
    const totalCampaignsCount = campaigns.length;
    return (
        <div>
            <div className={styles['client-campaigns-header']}>
                <div className={styles['header-label']}>Account</div>
                <div className={styles['account-id']}>{clientId} -</div>
                <div className={styles['account-name']}>{name}</div>
                <div className={styles['back-to-clients']} onMouseDown={e => e.preventDefault()} onClick={onClose}>
                    <CloseSquareIcon />
                </div>
            </div>
            <div className={styles['client-campaigns-second-header']}>
                <div className={styles['header-label']}>Campaigns</div>
                <div className={styles['protection-mode-label']}>Protection Mode</div>
                <div className={styles['protection-mode-select']}>
                    <SelectBox
                        value={protectionMode}
                        onChange={onChangeProtectionMode}
                        options={[
                            { value: 'disabled', label: 'Disabled' },
                            { value: 'default', label: 'Account Default' },
                            { value: 'campaigns', label: 'Per Campaign' },
                            {
                                value: 'label',
                                label: (
                                    <>
                                        By
                                        <Chip size="small" label={'CHEQ'} classes={{ sizeSmall: styles['chip-size-small'], labelSmall: styles['chip-label-small'] }} />
                                        Label
                                    </>
                                ),
                            },
                        ].filter(({ value }) => (campaigns.length === 0 ? value !== 'campaigns' : true))}
                    />
                </div>
                <div className={styles['protection-mode-desc']}>
                    {protectionMode === 'default' && 'Every campaign under this account is protected by default.'}
                    {protectionMode === 'campaigns' && <>Only selected campaigns will be protected.</>}
                    {protectionMode === 'label' && (
                        <>
                            Only campaigns that are labeled with{' '}
                            <Chip size="small" label={'CHEQ'} classes={{ sizeSmall: styles['chip-size-small'], labelSmall: styles['chip-label-small'] }} /> label
                            <br />
                            will be protected.{' '}
                            <a
                                className={styles['link']}
                                target={'_blank'}
                                href={'https://support.google.com/google-ads/answer/7486653?hl=en#:~:text=In%20the%20page%20menu%2C%20click,new%20label%2C%20click%20New%20label.'}
                                rel="noreferrer"
                            >
                                click here to learn more.
                            </a>
                        </>
                    )}
                </div>
            </div>
            <div className={styles['client-campaigns-table-wrapper']}>
                <Table
                    isProtected={isProtected}
                    protectedCampaigns={protectedCampaigns}
                    columns={['name', 'id', 'status', 'advertisingChannelType', 'actions']}
                    RenderEmpty={() => (
                        <NoData lineHeight={40} style={{ paddingTop: 80 }}>
                            <div>NO CAMPAIGNS FOUND</div>
                        </NoData>
                    )}
                    RenderHead={({ column }) => {
                        switch (column.id) {
                            case 'name':
                                return <div>Campaign Name</div>;
                            case 'id':
                                return <div>ID</div>;
                            case 'status':
                                return <div>Status</div>;
                            case 'advertisingChannelType':
                                return (
                                    <div style={{ lineHeight: '15px' }}>
                                        Adv.
                                        <br />
                                        Channel Type
                                    </div>
                                );
                            case 'actions':
                                return (
                                    <div style={{ lineHeight: '15px' }}>
                                        {(protectionMode === 'campaigns' || protectionMode === 'disabled') && (
                                            <div className={styles['protected-header-cell-checkbox']}>
                                                <Checkbox
                                                    checked={isProtected && protectedCampaigns[clientId] && campaigns.every(({ id }) => protectedCampaigns[clientId].includes(id))}
                                                    onClick={onToggleCampaignsProtectAll}
                                                />
                                            </div>
                                        )}
                                        <div className={styles['protected-header-cell-label']}>
                                            <div>Protected</div>
                                            <div>
                                                <span>{protectedCampaignsCount}</span> / {totalCampaignsCount}
                                            </div>
                                        </div>
                                    </div>
                                );
                            default:
                                return <div style={{ width: column.width }} />;
                        }
                    }}
                    RenderBody={({ column, row: campaign, protectedCampaigns, isProtected }) => {
                        const cellValue = campaign[column.id];
                        const clientProtectedCampaigns = protectedCampaigns[campaign.clientId];
                        const isCampaignProtected = !isProtected
                            ? false
                            : !clientProtectedCampaigns
                            ? true
                            : clientProtectedCampaigns.includes(campaign.id) || (clientProtectedCampaigns.includes('LABEL') && campaign.byLabel);
                        switch (column.id) {
                            case 'actions':
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            disabled={protectionMode === 'label'}
                                            checked={isCampaignProtected}
                                            onClick={() => toggleProtectCampaign(campaign.clientId, campaign.id, !isCampaignProtected)}
                                        />
                                        {protectionMode === 'label' && !isCampaignProtected && (
                                            <IconWrapper
                                                className={styles['info']}
                                                icon={<InfoOutlinedIcon style={{ height: 14, width: 14 }} />}
                                                tooltip={`In order to apply protection, you'll need to add a 'CHEQ' label for this campaign on your Google account`}
                                            />
                                        )}
                                    </div>
                                );
                            case 'advertisingChannelType':
                            case 'status':
                                return cellValue[0].toUpperCase() + cellValue.substring(1).toLowerCase();
                            default:
                                return <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: 'inherit' }}>{cellValue}</div>;
                        }
                    }}
                    search={'footer'}
                    pagination={true}
                    sortable={false}
                    rows={campaigns}
                    isLoading={false}
                    isError={''}
                    lineaCount={false}
                    rowsPerPage={7}
                    style={{
                        root: { background: 'rgba(20, 23, 44, 0.5)' },
                        head: { padding: 0 },
                        headerRow: { padding: 0, backgroundColor: '#14172c', height: 36 },
                        headerCell: { borderBottom: 'none', color: '#fe0072', fontFamily: 'Open Sans', letterSpacing: 0.12, fontSize: 12 },
                        oddRow: { backgroundColor: '#21223c', height: 30 },
                        row: { backgroundColor: '#383851', height: 30 },
                        bodyCell: { border: 'none', fontSize: 11, padding: 4 },
                        pagination: { bottom: 9 },
                        tableWrapper: {
                            '& th:nth-child(1)': {
                                width: 250,
                                paddingLeft: 40,
                                paddingRight: 7,
                            },
                            '& td:nth-child(1)': {
                                width: 250,
                                paddingLeft: 40,
                                paddingRight: 7,
                                lineHeight: '15px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                position: 'relative',
                            },
                            '& th:nth-child(2), & td:nth-child(2)': {
                                width: 80,
                                paddingLeft: 0,
                                paddingRight: 7,
                            },
                            '& th:nth-child(3), & td:nth-child(3)': {
                                width: 60,
                                paddingLeft: 0,
                                paddingRight: 7,
                            },
                            '& th:nth-child(4), & td:nth-child(4)': {
                                width: 75,
                                paddingLeft: 0,
                                paddingRight: 14,
                            },
                            '& th:nth-child(5), & td:nth-child(5)': {
                                width: 95,
                                paddingLeft: 0,
                                paddingRight: 7,
                            },
                        },
                    }}
                    cm={cm}
                    headCellPadding={'none'}
                />
            </div>
        </div>
    );
};

export default ClientCampaigns;
