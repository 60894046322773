import { analytics as types } from '.';
import mixpanel from '@services/mixpanel';
import { SLP_REPORT_TYPE } from '../../utils/analytics/helpers';
import contentManager from '@services/content-manager';

export const setSummaryReportSettingsFromCas = (isRTI, data) => (dispatch, getStore) => {
    const payload = {
        reportType: data.reportType,
        platformOrigin: data.advancedFiltering.platformOrigin,
        dateRange: data.dateRange,
        includePPCCrawlers: { value: false },
        policyToggleA: data.policyToggleA,
        includePolicy: data.includePolicy,
        policyManagement: data.policyManagement,
        channel: data.advancedFiltering.channel,
        groupBy: data.groupBy,
        campaign: data.advancedFiltering.campaign,
        medium: data.advancedFiltering.medium,
        isDirty: false,
        breakdownBy: undefined,
        browser: undefined,
        country: data.advancedFiltering.country,
        device: undefined,
        source: data.advancedFiltering.source,
        domain: data.advancedFiltering.domain,
        affiliates: data.advancedFiltering.affiliates,
    };

    dispatch({
        type: types.ANALYTICS_SET_SUMMARY_REPORT_SETTINGS,
        payload: payload,
    });
};

export const setSummaryReportSettings = (groupBy, isRTI, isSlp) => (dispatch, getStore) => {
    const {
        dashboard: {
            filterOptions,
            settings: { channels, dateRanges },
            selectedPlatformsGroup,
        },
        rtiDashboard: { filterOptions: rtiFilterOptions },
        analytics: { summaryReportSettings },
    } = getStore();

    const RTIpayload = {
        ...summaryReportSettings,
        reportType: { label: 'Real-time Interception API', value: 'rti' },
        platformOrigin: selectedPlatformsGroup.platforms.map(value => ({ value, label: value })),
        dateRange:
            rtiFilterOptions.dateRange.value === 'Custom'
                ? { startDate: rtiFilterOptions.dateRange.format[0], endDate: rtiFilterOptions.dateRange.format[1] }
                : dateRanges.find(dr => dr.value === rtiFilterOptions.dateRange.value),
        includePPCCrawlers: false,
        policyToggleA: summaryReportSettings.policyToggleA,
        includePolicy: summaryReportSettings.includePolicy,
        policyManagement: summaryReportSettings.policyManagement,
        channel: channels,
        groupBy,
    };

    const SLPpayload = {
        ...summaryReportSettings,
        reportType: { label: contentManager.get('UserValidation'), value: SLP_REPORT_TYPE },
        platformOrigin: selectedPlatformsGroup.platforms.map(value => ({ value, label: value })),
        dateRange:
            rtiFilterOptions.dateRange.value === 'Custom'
                ? { startDate: rtiFilterOptions.dateRange.format[0], endDate: rtiFilterOptions.dateRange.format[1] }
                : dateRanges.find(dr => dr.value === rtiFilterOptions.dateRange.value),
        includePPCCrawlers: false,
        policyToggleA: true,
        includePolicy: true,
        policyManagement: true,
        groupBy,
    };

    const payload = {
        ...summaryReportSettings,
        reportType: { label: 'Summary', value: 'summary' },
        platformOrigin: selectedPlatformsGroup.platforms.map(value => ({ value, label: value })),
        dateRange: filterOptions.dateRange.startDate
            ? { startDate: filterOptions.dateRange.startDate, endDate: filterOptions.dateRange.endDate }
            : dateRanges.find(dr => dr.value === filterOptions.dateRange),
        includePPCCrawlers: filterOptions.includePPCCrawlers ? { value: true } : { value: false },
        policyToggleA: summaryReportSettings.policyToggleA,
        includePolicy: summaryReportSettings.includePolicy,
        policyManagement: summaryReportSettings.policyManagement,
        channel:
            filterOptions.channel && filterOptions.channel.value && filterOptions.channel.value !== 'none'
                ? [channels.find(c => c.value === filterOptions.channel.value)]
                : undefined,
        groupBy,
    };
    try {
        const mixpanelData = {
            'Report view type': 'View all button click',
            'Report Type': 'summary',
            'Report group by': groupBy,
            'Report include PPC crawlers': payload.includePPCCrawlers,
            'Report advanced filtering channels': payload.channel,
            'Report advanced filtering platform origin': payload.platformOrigin,
        };
        if (typeof payload.dateRange === 'string') {
            mixpanelData['Report period filter'] = payload.dateRange;
        } else {
            mixpanelData['Report period filter'] = 'custom';
            mixpanelData['Report start date filter'] = payload.dateRange.startDate;
            mixpanelData['Report end date filter'] = payload.dateRange.endDate;
        }
        mixpanel.postEvent('Analytics view', mixpanelData);
    } catch (e) {
        console.log('failed to send to mixpanel');
    }

    dispatch({
        type: types.ANALYTICS_SET_SUMMARY_REPORT_SETTINGS,
        payload: isRTI ? RTIpayload : isSlp ? SLPpayload : payload,
    });
};

export const clearSummaryReportSettings = () => {
    return {
        type: types.ANALYTICS_CLEAR_SUMMARY_REPORT_SETTINGS,
    };
};

export const analyticsDataUpdated = payload => ({
    type: types.ANALYTICS_DATA_UPDATED,
    payload,
});

export const analyticsPlatformsUpdated = payload => ({
    type: types.ANALYTICS_PLATFORMS_UPDATED,
    payload,
});

export const analyticsDataLoading = () => ({
    type: types.ANALYTICS_DATA_LOADING,
});

export const analyticsRequestFailed = payload => ({
    type: types.ANALYTICS_REQUEST_FAILED,
    payload,
});

export const updateReportHistory = payload => ({
    type: types.UPDATE_REPORT_HISTORY,
    payload,
});

export const deleteReportHistory = payload => ({
    type: types.DELETE_REPORT_HISTORY,
    payload,
});

export const loadHistoryReport = payload => ({
    type: types.LOAD_REPORT_HISTORY,
    payload,
});

export const updateReportType = payload => ({
    type: types.UPDATE_REPORT_TYPE,
    payload,
});

export const updateSummeryReportSettings = payload => ({
    type: types.UPDATE_SUMMARY_REPORT_SETTINGS,
    payload,
});
