import CheqUI from '@combotag/cheq-ui';
import ContentManager from '../../../../../services/content-manager';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import LinearProgress from '../../../../widgets/liniar-progress/index';
import React, { useRef } from 'react';
import Switch from '../../../../widgets/switch/index';
import Toast from '../../../../../services/notifications';
import { _String } from 'chopin-methods';
import integrators from '../../integrators/3parties';
import styles from './accounts.module.scss';
import svgs from '../../../../svgs/third-parties';
import { useSplit } from '@hooks';
import { constants as splitConstants } from '@utils/split.io';
import widgets from '../../../../widgets';

const { useActions, useMeiosis } = CheqUI.React.Meiosis;
const { Button, TextField, Tooltip } = widgets;

const accountsTableStyle = {
    head: { padding: 0 },
    headerRow: { padding: 0, height: 40 },
    headerCell: { fontSize: 12, letterSpacing: 0.12, fontFamily: 'Open Sans' },
    bodyCell: { padding: 10 },
    tableWrapper: {
        '& th:nth-child(1)': {
            width: 70,
            paddingLeft: 117,
            paddingRight: 7,
        },
        '& td:nth-child(1)': {
            display: 'inline-block',
            paddingLeft: 117,
            paddingRight: 7,
            overflow: 'hidden',
            lineHeight: '35px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        '& th:nth-child(2), & td:nth-child(2)': {
            width: 149 - 30,
            paddingLeft: 0,
            paddingRight: 7,
        },
        '& th:nth-child(3), & td:nth-child(3)': {
            width: 154 - 40,
            paddingLeft: 0,
            paddingRight: 7,
        },
        '& th:nth-child(4), & td:nth-child(4)': {
            width: 117,
            paddingLeft: 0,
            paddingRight: 7,
        },
    },
};

function RenderHead({ column }) {
    const cm = ContentManager.by('AccountManagement.Tab.Accounts.Table');
    switch (column.id) {
        case 'active':
            return <div>{cm.get('Status')}</div>;
        case 'name':
            return (
                <Tooltip title={cm.get('Info.Platform')}>
                    <div>{cm.get('Platform')}</div>
                </Tooltip>
            );
        case 'cpc':
            return (
                <Tooltip title={cm.get('Info.AvgCPC')}>
                    <div>{cm.get('AvgCPC')}</div>
                </Tooltip>
            );
        default:
            return <div style={{ width: column.width }} />;
    }
}

export const Invalidator = ({ serviceName }) => {
    const { isOn: isGoogleSnsOn } = useSplit(splitConstants.SPLIT_GOOGLE_DISCONNECT_SNS);
    const actions = useActions();
    const { ssoV2: ssoV2Actions, sso: ssoActions, clickTrueTags: clickTrueTagsActions, dialog: dialogActions } = actions;

    const service = ssoV2Actions.getService(serviceName);
    const sso = useMeiosis(ssoV2Actions.buildStream);
    const serviceState = sso.services[serviceName];

    const displayName = useRef(serviceName === 'googleV2' ? 'google' : serviceName);

    const invalidate = serviceState
        ? () => service.invalidate({ isSendSns: isGoogleSnsOn }, dialogActions.hide).then(() => clickTrueTagsActions.getAll())
        : () => {
              ssoActions.invertAudienceService({ serviceName, isEnabled: false }, dialogActions.hide);
          };
    return (
        <div style={{ background: '#282b3f', position: 'relative', textAlign: 'center', width: 600, margin: '0 auto' }}>
            {serviceState && serviceState.state.requestingInvalidate && (
                <div style={{ position: 'absolute', width: '100%' }}>
                    <LinearProgress />
                </div>
            )}
            <div style={{ padding: 40 }}>
                This action will disconnect {displayName.current[0].toUpperCase() + displayName.current.substring(1, serviceName.length)}. Are you sure?
            </div>
            <Button style={{ width: 150 }} isLoading={serviceState && serviceState.requestingInvalidate} onClick={dialogActions.hide}>
                CANCEL
            </Button>
            <Button style={{ width: 150 }} isLoading={(serviceState && serviceState.requestingInvalidate) || ssoActions.isLoading('invert_audience_service')} onClick={invalidate}>
                CONFIRM
            </Button>
        </div>
    );
};

function RenderBody({ column, row, rowIndex, isAuthorized }) {
    const actions = useActions();
    const { ssoV2: ssoV2Actions, sso: ssoActions, dialog: dialogActions } = actions;

    const Integration = integrators[row.name === 'google' ? 'googleV2' : row.name];
    const Logo = svgs[_String.capitalize(row.name)];
    const cellStyle = { textAlign: 'left' };
    const dialogOptions = {
        width: 'auto',
        noCancel: true,
        Header: () => <div />,
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalStyle: {
            display: 'flex',
            margin: 0,
            padding: 0,
        },
    };

    const ssoAction = row.name === 'google' ? ssoV2Actions : ssoActions;

    const hasAuthenticator = ssoAction.hasAuthenticator(row.name);
    const isProtected = ssoAction.isProtected(row.name);

    const cm = ContentManager.by('AccountManagement.Tab.Accounts.Table.Body');
    switch (column.id) {
        case 'name':
            return (
                <div
                    style={{
                        justifyContent: 'left',
                    }}
                >
                    {' '}
                    {Logo ? (
                        <div style={{ ...cellStyle, letterSpacing: '0.12', fontFamily: 'Open Sans', fontSize: 12 }}>
                            <Logo style={{ display: 'inline-block' }} />{' '}
                            <span
                                style={{
                                    position: 'relative',
                                    top: -7,
                                    left: 4,
                                }}
                            >
                                {cm.get(_String.capitalize(row.name))}
                            </span>
                        </div>
                    ) : (
                        <div
                            style={{
                                ...cellStyle,
                                letterSpacing: '0.12',
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                            }}
                        >
                            {cm.get(_String.capitalize(row.name))}
                        </div>
                    )}
                </div>
            );
        case 'active':
            return (
                <div style={cellStyle}>
                    <Switch
                        type="checkbox"
                        checkedLabel={'On'}
                        uncheckedLabel={'Off'}
                        checked={isProtected}
                        onChange={e =>
                            dialogActions.show(!isProtected ? <Integration /> : <Invalidator serviceName={row.name === 'google' ? 'googleV2' : row.name} />, dialogOptions)
                        }
                        style={{ marginRight: 0 }}
                        disabled={!Integration ? true : row.name === 'google' ? !isAuthorized('ip_block', 'write') : !isAuthorized('audience', 'write')}
                    />
                </div>
            );
        case 'actions':
            return !isAuthorized('audience', 'write') ? (
                <></>
            ) : hasAuthenticator || isProtected ? (
                <div>
                    <Button styles={{ container: styles.viewConfigBtn }} onClick={() => dialogActions.show(<Integration />, dialogOptions)}>
                        {cm.get('Configuration')}
                    </Button>
                </div>
            ) : (
                <></>
            );

        case 'cpc':
            return (
                <TextField
                    divider={true}
                    disabled={!isAuthorized('CPC', 'write')}
                    InputIcon={<DoneOutlinedIcon />}
                    style={{
                        fontSize: 13,
                        backgroundColor: 'transparent',
                        border: `1px solid ${rowIndex % 2 === 0 ? '#383851' : '#21223c'}`,
                        width: 100,
                        textAlign: 'center',
                        padding: 5,
                    }}
                    AIL={DoneOutlinedIcon}
                    AIR={DoneOutlinedIcon}
                    placeholder={'Enter CPC'}
                    value={row.settings.cpc}
                    onSubmit={value => {
                        (Number(value) && Number(value) <= 500 && Number(value) >= 0) || !value
                            ? ssoAction.updateSettings({
                                  settings: { ...row.settings, cpc: value },
                                  service: row.name,
                              })
                            : Number(value) <= 0
                            ? Toast({ message: cm.get('Cpc.ValueTooSmall') })
                            : Toast({ message: cm.get('Cpc.ValueTooBig') });
                    }}
                />
            );
        default:
            return <div />;
    }
}

export default {
    headCellPadding: 'none',
    lineaCount: false,
    justifyHeaderContent: 'left',
    RenderBody,
    RenderHead,
    columns: ['active', 'name', 'cpc', 'actions'],
    style: accountsTableStyle,
};
