import { skewed as types } from '.';

export const setTab = payload => ({
    type: types.SET_TAB,
    payload,
});

export const setReportFilters = payload => ({
    type: types.SET_REPORT_FILTERS,
    payload
})
