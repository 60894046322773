// HTTP Methods - assinged true / false if method is with body
const methods = { delete: 'delete', patch: 'patch', put: 'put', get: 'get', post: 'post', head: 'head', all: 'all' };
const methodsWithBody = { put: true, post: true, patch: true };

const setParams = (params, url) => {
    Object.entries(params).reduce((url, [key, value]) => {
        url.searchParams.set(key, value);
        return url;
    }, url);
};

const handleOnProgress = e => {
    const { loaded, total, lengthComputable, target } = e;
    let contentLength;
    if (lengthComputable) {
        contentLength = total;
    } else {
        contentLength = parseInt(target.getResponseHeader('content-length'), 10);
    }

    return { loaded, contentLength };
};

export { handleOnProgress, setParams, methods, methodsWithBody };
