import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import ToolTip from '../tool-tip';

import cx from 'classnames';
import styles from './dashboard-item.module.scss';

export const DashboardItem = ({ title, info, children, action, skeletonClassName, className, isLoading, isError, ...rest }) => {
    if (isLoading)
        return (
            <div className={cx(styles.dashboardItemSkeleton, className, skeletonClassName)}>
                <div className={styles.loaderContent}>
                    <div className={styles.texts}>
                        <Skeleton variant="text" animation="wave" />
                        <Skeleton variant="text" animation="wave" />
                        <Skeleton variant="text" animation="wave" />
                        <Skeleton variant="text" animation="wave" />
                    </div>
                </div>
            </div>
        );

    return (
        <div className={cx(styles.dashboardItem, className)} {...rest}>
            <div className={cx(styles.dashboardItemTitle)}>
                <div className={styles.title} data-test-selector="title">
                    {title}
                </div>
                <div className={styles.flexRow}>
                    {action && (
                        <div className={styles.actionTrigger} onClick={action.callBack !== undefined ? () => action.callBack() : () => {}} data-test-selector="action-trigger">
                            {action.label}
                        </div>
                    )}
                    {info && (
                        <ToolTip title={info.title} content={info.content} action={info.action}>
                            <InfoIcon cursor="pointer" data-test-selector="info-icon" />
                        </ToolTip>
                    )}
                </div>
            </div>
            {isError ? (
                <div className={styles.errorContent}>Oops, Something went wrong</div>
            ) : (
                <div data-test-selector="content" className={styles.content}>
                    {children}
                </div>
            )}
        </div>
    );
};
