import React from 'react';
import useStyle from './styles';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

const DatePicker = ({date, onChange = console.log, view = 'month', className = '', maxDate = new Date(), minDate =  new Date(2019, 7, 1),...props}) => {
    const {calenderDateNotAvailable, calenderDate, calender} = useStyle();

    return  <Calendar
        value={date}
        onChange={onChange}
        className={`${calender} ${className}`}
        view={view}
        minDate={minDate}
        maxDate={maxDate}
        tileClassName={({ date }) => date > maxDate || date < minDate ? calenderDateNotAvailable : calenderDate}
        {...props}
    />
}

export default  DatePicker;