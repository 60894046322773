import { toast, Flip, Slide, Bounce, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './style.module.css';
import 'react-toastify/dist/ReactToastify.css';

export const toastTypes = {
    info: 'info',
    success: 'success',
    warning: 'warning',
    error: 'error',
};

export default ({ message, type = '', transition = 'flip', promise, ...props }) => {
    type = toastTypes[type] || toastTypes.info;

    const transitions = {
        slide: Slide,
        flip: Flip,
        bounce: Bounce,
        zoom: Zoom,
    };

    const schema = {
        transition: transitions[transition],
        className: styles.container,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        ...props,
    };

    if (promise) {
        const { promiseFunction, status } = promise;
        return toast.promise(promiseFunction, status, schema);
    }

    return toast[type](message, schema);
};
