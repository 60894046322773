import React from 'react';
import cx from 'classnames';
import styles from './scale-bar.module.css';

export const ScaleBar = ({ width, className }) => {
    return (
        <div className={cx(styles.scaleBar, className)}>
            <div className={styles.bar} style={{ width: `${width}%` }} />
        </div>
    );
};
