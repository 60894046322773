import styles from "../../PixelGuard.module.scss";
import {IconButton} from "@material-ui/core";
import {PlayArrow} from "@material-ui/icons";
// @ts-ignore
import widgets from '@components/widgets';
const { MultiSelectCheckbox } = widgets;

interface IProps {
    cm: any;
    selected: Object;
    setSelected: (item: Object) => void;
    tags: Object[];
    onGenerateHandler: () => void;
}
const AddPixel = ({ cm, selected, setSelected, tags, onGenerateHandler }: IProps) => (
    <div className={styles.row}>
        <div>
            <div className={styles.text}>{cm.get('Channel')}</div>
            <MultiSelectCheckbox
                className={styles.select}
                options={tags.map((item: any) => {
                    return { value: item.id, label: item.name };
                })}
                value={selected}
                onChange={(selected: any) => setSelected(selected)}
                placeholder={cm.get('SelectChannel')}
            />
        </div>
        <div className={`${styles.row} ${styles.generate} ${!selected['value'] && styles.disabled}`} onClick={onGenerateHandler}>
            <IconButton size="small" data-test-selector="add-pixel-button" disabled={!selected['value']}>
                <PlayArrow className={`${styles.button} ${!selected['value'] && styles.disabled}`} fontSize="small" />
            </IconButton>
            <div className={`${styles.button} ${!selected['value'] && styles.disabled}`}>{cm.get('GenerateParameters')}</div>
        </div>
    </div>
)

export default AddPixel;