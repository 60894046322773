import { useEffect, useState } from 'react';

type Organization = {
    display_name: string;
    id: string;
};

export const useOrganizations = () => {
    const [selectedOrgName, setSelectedOrgName] = useState('');
    const [userOrgsCount, setUserOrgsCount] = useState(0);

    useEffect(() => {
        const selectedOrg: Organization = JSON.parse(localStorage.getItem('selectedOrg') || '{}');
        if (selectedOrg.display_name) {
            setSelectedOrgName(selectedOrg.display_name);
        }
        const orgsCount: number = JSON.parse(localStorage.getItem('userOrgsCount') || '{}');
        setUserOrgsCount(orgsCount);
    }, []);

    return [selectedOrgName, userOrgsCount];
};
