import React from "react";

const MobileFrame = ()=>
	<>
		<svg  xmlns="http://www.w3.org/2000/svg"  width="265" height="527"
		 viewBox="0 0 509.83 1016.07" style={{enableBackground:'new 0 0 509.83 1016.07'}} >
	<g id="Page-1_1_">
		<g id="iPhone-X-Mockup_1_" transform="translate(-480.000000, -331.000000)">
			<g id="iPhone-X-Black" transform="translate(480.000000, 331.000000)">
				<g id="Fill-1_1_">
					<path style={{fillRule : 'evenodd', clipRule:'evenodd'}} d="M481.65,506.65c0-139.37,0-278.73,0-418.09c0-2.51,0.03-5.02-0.06-7.52
						c-1.05-28.23-23.82-50.51-52.04-50.79c-14.42-0.14-28.84-0.05-43.27-0.02c-5.96,0.01-8.59,2.44-9.16,8.33
						c-0.3,3.12-0.42,6.26-0.82,9.36c-0.85,6.57-4.48,11.23-10.29,14.23c-3.13,1.61-6.52,1.96-10.03,1.96
						c-66.78-0.04-133.56-0.03-200.35-0.03c-0.78,0-1.57,0.02-2.35-0.01c-9.31-0.28-16.76-6.76-18.15-15.94
						c-0.49-3.25-0.58-6.55-0.9-9.83c-0.57-5.91-2.83-8.06-8.74-8.08c-14.42-0.04-28.85-0.27-43.27,0.06
						c-19.12,0.43-33.87,9.04-44.19,25.1c-6.04,9.4-7.98,19.89-7.98,30.95c0.04,279.83,0.03,559.65,0.03,839.48
						c0,1.41-0.16,2.85,0.04,4.23c0.72,4.95,1.05,10.02,2.46,14.78c6.57,22.2,26.94,37.25,50.35,37.28
						c48.44,0.07,96.88,0.02,145.32,0.02c66.78,0,133.56,0.14,200.35-0.08c22.72-0.08,38.93-11.18,48.7-31.59
						c3.66-7.65,4.39-15.89,4.39-24.29C481.64,786.32,481.65,646.49,481.65,506.65L481.65,506.65z M504.19,234.77
						c5.44,1.34,5.64,1.54,5.64,6.72c0.01,33.39,0.01,66.79,0,100.18c0,5.39-0.15,5.55-5.64,7.14v6.44
						c0,193.62-0.02,387.24,0.03,580.87c0.01,18.05-5.08,34.33-16.04,48.6c-13.41,17.46-31.08,27.84-53.04,30.6
						c-3.41,0.43-6.86,0.72-10.3,0.72c-113.82,0.04-227.64,0.05-341.46,0.02c-37.37-0.01-69.01-24.92-77.41-61.2
						c-1.47-6.35-2.05-13.03-2.09-19.56c-0.21-31.2-0.1-62.4-0.1-93.6c0-153.33,0-306.66,0-459.99c0-1.57,0.14-3.16-0.06-4.7
						c-0.13-0.96-0.53-2.23-1.25-2.69c-2.21-1.41-2.46-3.42-2.46-5.71c0.02-21.17,0.02-42.33,0-63.5c0-2.28,0.27-4.26,2.43-5.73
						c0.79-0.54,1.2-2.04,1.28-3.14c0.18-2.65,0.01-5.33,0.08-7.99c0.05-2.09-0.25-3.65-2.18-5.2c-1.22-0.99-1.52-3.63-1.53-5.54
						c-0.11-18.81-0.06-37.63-0.06-56.44c0-1.88,0.04-3.76-0.01-5.64c-0.06-2.58,0.05-4.97,2.71-6.53c0.67-0.39,1-1.85,1.01-2.82
						c0.07-9.41,0.09-18.81-0.02-28.22c-0.01-1.05-0.6-2.48-1.4-3.06c-1.94-1.39-2.3-3.2-2.3-5.32c0.02-8.78-0.06-17.56,0.07-26.34
						c0.02-1.6,0.43-3.93,1.5-4.61c2.37-1.53,2.2-3.49,2.2-5.67C3.78,114.83,3.6,96.8,3.84,78.77c0.29-22.21,8.35-41.28,24.4-56.71
						C43.25,7.64,61.38,0.13,82.15,0.1c114.61-0.14,229.21-0.13,343.82-0.03c38.58,0.03,71.73,28.94,77.28,67.02
						c0.63,4.32,0.89,8.73,0.89,13.1c0.06,49.54,0.04,99.08,0.04,148.63V234.77z"/>
				</g>
				<g>
					<path style={{fillRule : 'evenodd', clipRule:'evenodd'}} d="M481.65,506.65c0-139.37,0-278.73,0-418.09c0-2.51,0.03-5.02-0.06-7.52
						c-1.05-28.23-23.82-50.51-52.04-50.79c-14.42-0.14-28.84-0.05-43.27-0.02c-5.96,0.01-8.59,2.44-9.16,8.33
						c-0.3,3.12-0.42,6.26-0.82,9.36c-0.85,6.57-4.48,11.23-10.29,14.23c-3.13,1.61-6.52,1.96-10.03,1.96
						c-66.78-0.04-133.56-0.03-200.35-0.03c-0.78,0-1.57,0.02-2.35-0.01c-9.31-0.28-16.76-6.76-18.15-15.94
						c-0.49-3.25-0.58-6.55-0.9-9.83c-0.57-5.91-2.83-8.06-8.74-8.08c-14.42-0.04-28.85-0.27-43.27,0.06
						c-19.12,0.43-33.87,9.04-44.19,25.1c-6.04,9.4-7.98,19.89-7.98,30.95c0.04,279.83,0.03,559.65,0.03,839.48
						c0,1.41-0.16,2.85,0.04,4.23c0.72,4.95,1.05,10.02,2.46,14.78c6.57,22.2,26.94,37.25,50.35,37.28
						c48.44,0.07,96.88,0.02,145.32,0.02c66.78,0,133.56,0.14,200.35-0.08c22.72-0.08,38.93-11.18,48.7-31.59
						c3.66-7.65,4.39-15.89,4.39-24.29C481.64,786.32,481.65,646.49,481.65,506.65L481.65,506.65z M504.19,234.77
						c5.44,1.34,5.64,1.54,5.64,6.72c0.01,33.39,0.01,66.79,0,100.18c0,5.39-0.15,5.55-5.64,7.14v6.44
						c0,193.62-0.02,387.24,0.03,580.87c0.01,18.05-5.08,34.33-16.04,48.6c-13.41,17.46-31.08,27.84-53.04,30.6
						c-3.41,0.43-6.86,0.72-10.3,0.72c-113.82,0.04-227.64,0.05-341.46,0.02c-37.37-0.01-69.01-24.92-77.41-61.2
						c-1.47-6.35-2.05-13.03-2.09-19.56c-0.21-31.2-0.1-62.4-0.1-93.6c0-153.33,0-306.66,0-459.99c0-1.57,0.14-3.16-0.06-4.7
						c-0.13-0.96-0.53-2.23-1.25-2.69c-2.21-1.41-2.46-3.42-2.46-5.71c0.02-21.17,0.02-42.33,0-63.5c0-2.28,0.27-4.26,2.43-5.73
						c0.79-0.54,1.2-2.04,1.28-3.14c0.18-2.65,0.01-5.33,0.08-7.99c0.05-2.09-0.25-3.65-2.18-5.2c-1.22-0.99-1.52-3.63-1.53-5.54
						c-0.11-18.81-0.06-37.63-0.06-56.44c0-1.88,0.04-3.76-0.01-5.64c-0.06-2.58,0.05-4.97,2.71-6.53c0.67-0.39,1-1.85,1.01-2.82
						c0.07-9.41,0.09-18.81-0.02-28.22c-0.01-1.05-0.6-2.48-1.4-3.06c-1.94-1.39-2.3-3.2-2.3-5.32c0.02-8.78-0.06-17.56,0.07-26.34
						c0.02-1.6,0.43-3.93,1.5-4.61c2.37-1.53,2.2-3.49,2.2-5.67C3.78,114.83,3.6,96.8,3.84,78.77c0.29-22.21,8.35-41.28,24.4-56.71
						C43.25,7.64,61.38,0.13,82.15,0.1c114.61-0.14,229.21-0.13,343.82-0.03c38.58,0.03,71.73,28.94,77.28,67.02
						c0.63,4.32,0.89,8.73,0.89,13.1c0.06,49.54,0.04,99.08,0.04,148.63V234.77z"/>
				</g>
				<g transform="translate(13.000000, 6.000000)">
				</g>
				<g id="Group_1_" transform="translate(113.000000, 21.000000)">
					<g id="Rectangle-7_1_">
						<path style={{fillRule : 'evenodd', clipRule:'evenodd',fill:'#777777'}} d="M105.11,18.66h73.65c2.61,0,4.72,2.11,4.72,4.72l0,0c0,2.61-2.11,4.72-4.72,4.72h-73.65
							c-2.61,0-4.72-2.11-4.72-4.72l0,0C100.38,20.77,102.5,18.66,105.11,18.66z"/>
					</g>
					<g id="Oval_1_">
						<circle style={{fillRule : 'evenodd', clipRule:'evenodd',fill:'#777777'}} cx="197.64" cy="23.38" r="4.72"/>
					</g>
				</g>
			</g>
		</g>
	</g>
	</svg>

	</>

export  default  MobileFrame