import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    calender: {
        '& .react-calendar__year-view__months__month': {
            backgroundColor: '#383851 !important',
                border: 'solid 0.1px #fff',
                flexBasis: '25% !important',
                maxWidth: '25% !important',
                height: 93,
                width: 88,
        },
        '& .react-calendar__tile--hasActive': {
            backgroundColor: '#0C1021 !important',
                border: 'solid 3px #fe0072 !important',
                color: '#fe0072  !important',
                zIndex: '2 !important',
        },
        '& abbr': {
            display: 'none',
        },
        '& .react-calendar__navigation button': {
            marginTop: 10,
        },
    }
}));