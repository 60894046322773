const TABS_OPTIONS = {
    OnDemand: 'ReportsTab',
    Scheduele: 'ScheduledReportsTab',
    APIAccess: 'APIAccess',
};

const getTestConnectionFailedMessages = (cloudStorageProvider, message) => {
    switch (message) {
        case 'InvalidAccessKeyId':
            return cloudStorageProvider === 'backblaze' ? 'Invalid Application key ID' : 'Invalid Access Key';
        case 'SignatureDoesNotMatch':
            return cloudStorageProvider === 'backblaze' ? 'Applicaiton Key Does Not Match' : 'Secret Key Does Not Match';
        case 'NoSuchBucket':
            return 'No Such Bucket';
        default:
            return null;
    }
};

const DEFAULT_ON_DEMAND = 1;
const REPORTS_INTERVAL = 3000;

const bucketPrefix = 'cheq-reports';

export { TABS_OPTIONS, DEFAULT_ON_DEMAND, REPORTS_INTERVAL, getTestConnectionFailedMessages, bucketPrefix };
