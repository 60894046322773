import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { constants } from '@utils/split.io';
import { useSplit } from '@hooks';

import CheqUI from '@combotag/cheq-ui';
import ContentManager from '../../../../../services/content-manager';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Toast from '../../../../../services/notifications';
import Zoom from '@material-ui/core/Zoom';
import { _String } from 'chopin-methods';
import styles from './form.module.scss';
import { getNetworkServingDomain } from '@redux/thunks/settings';
import htmlTagValidator from 'html-tag-validator';
import { makeStyles } from '@material-ui/core';
import svgs from '../../../../../components/svgs/index';
import widgets from '../../../../../components/widgets/index';
import Progress from '../../../../../components/widgets/progress';

const { Upload } = svgs;
const { useMeiosis, useActions } = CheqUI.React.Meiosis;
const { Codemirror, Button, Chip, Confirm, MultiSelectCheckbox, Tooltip, CheqTag } = widgets;

const useStyle = makeStyles(() => ({
    title: {
        display: 'flex',
        marginBottom: 5,
        textAlign: 'left',
        fontSize: 14,
    },
    inline: { display: 'inline-block' },
    code: { width: 600 },
    upload: {
        cursor: 'pointer !important',
        '& svg': {
            color: '#fff !important',
        },
    },
    invalidScript: {
        cursor: 'auto',
        marginLeft: 25,
        verticalAlign: 'top',
        marginTop: 55,
        '& svg': {
            marginTop: 20,
            color: 'gray',
        },
    },
    deleteBtnContainer: {
        textAlign: 'center',
        height: 24,
        width: 109,
        marginTop: 7,

        '& input': {
            outline: 'none',
            height: '100%',
            width: '100%',
        },
    },
    chipSelected: {
        '& .MuiChip-label': {
            color: '#fe0072',
        },
        '& .MuiChip-deleteIcon': {
            color: '#fff',
        },
    },
    audienceTagsInfoIcon: {
        height: 14,
        width: 14,
        marginLeft: 10,
        cursor: 'pointer',
        marginTop: 5,
        '& :hover': {
            color: '#fe0072',
        },
    },
}));

const selectStyle = {
    container: {
        width: '160px',
        height: '24px',
        marginLeft: '10px',
        marginBottom: 0,
        backgroundColor: '#14172c',
    },
    option: {
        backgroundColor: '#14172c',
    },
    control: { backgroundColor: '#14172c', height: '20px' },
    placeholder: { lineHeight: '17px', overflow: 'visible' },
    value: { lineHeight: '19px' },
    input: { lineHeight: '17px', margin: 0, paddingBottom: 2, paddingTop: 0 },
    dropdownIndicator: { height: 16, width: 16 },
    singleValue: { lineHeight: '17px' },
    menu: { zIndex: 100 },
};

export default ({ audienceTags, parentId, refresh, isAuthorized }) => {
    const cm = ContentManager.by('AccountManagement.Form.EditTag');
    const actions = useActions();
    const clickTrueTags = useMeiosis(actions.clickTrueTags.buildStream);
    const classes = useStyle();
    const [editTag, setEditTag] = useState();
    const [inputError, setInputError] = useState(false);
    const [editorFocus, setEditorFocus] = useState(false);
    const { edit, delete: onDelete, isLoading } = actions.clickTrueTags;
    const { name, id, mainTag, jsonTags, tagHash } = audienceTags;
    const { servicesList = [] } = clickTrueTags;
    const sso = useMeiosis(actions.sso.buildStream);
    const dedicatedWLDomainsData = useSelector(state => state.settings.dedicatedWLDomainsData);
    const { isOn: isNewAccountSettings } = useSplit(constants.PARADOME_ACCOUNT_SETTINGS);

    const dispatch = useDispatch();

    let servingDomain;
    if (audienceTags.whiteLabelDomain == null) {
        servingDomain = useSelector(state => state.settings.servingDomain);
    } else {
        // const dedicatedWLDomains = useSelector(state => state.settings.dedicatedWLDomainsData);
        servingDomain = dedicatedWLDomainsData ? dedicatedWLDomainsData.filter(wldData => wldData.id === audienceTags.whiteLabelDomain)[0] : {};
    }

    const networkFeatures = useSelector(s => s.settings?.networkFeatures);
    const licences = {
        onSiteConversionEnabled: !!networkFeatures?.filter(n => n.id === 2 && n.enabled),
        paidMarketingEnabled: !!networkFeatures?.filter(n => n.id === 1 && n.enabled),
    };

    const { protectionFeatures: { facebook: { linkedTags = [] } = {} } = {} } = sso;

    useEffect(() => {
        if (!servingDomain) dispatch(getNetworkServingDomain());
    });

    useEffect(() => {
        actions.sso.getProtectionFeatures({ serviceName: 'facebook' });
    }, [actions.sso]);

    useEffect(() => {
        if (!editorFocus && inputError && editTag && editTag.text.length) {
            Toast({ message: cm.get('InvalidAudienceTagsFormat') });
        }
        if (editorFocus && (!editTag || !editTag.service)) {
            Toast({ message: cm.get('Input.SelectAudienceServiceFirst') });
        }
    }, [inputError, editorFocus, editTag, cm]);

    const confirm = dialogConfirm(actions.dialog);

    const onBeforeChange = (editor, data, text) => {
        htmlTagValidator(text, (err, { document, ...rest } = {}) => {
            const isValid = isValidInput(err, text, document);
            setInputError(!isValid);
            if (isValid) setEditTag({ ...editTag, tags: [text], text });
            else setEditTag({ ...editTag, text });
        });
    };

    const isValidInput = (err, value, document) =>
        !err && value && Array.isArray(document) && document.some(({ type }) => type !== 'text') && !value.includes('clicktrue_invocation.js?id=');

    const uploadTags = () => {
        const overriddenTags = jsonTags.find(t => t.service === editTag.service);
        if (!canUpload(jsonTags, editTag)) return;
        editTag.status = 'new';
        const tags = jsonTags.map(tag => {
            return { ...tag, status: 'exists' };
        });
        if (overriddenTags) {
            confirm({
                onSubmit: () =>
                    edit({
                        jsonTags: [...tags.filter(({ service }) => service !== editTag.service), editTag],
                        name,
                        id,
                    }),
                title: cm.get('Confirm.Override.Audiance').replace('[SERVICE]', editTag.service), //`Are You Sure You Want To Override ${overriddenTags.service}?`
            });
        } else {
            return (
                editTag.tags.length &&
                edit({
                    jsonTags: [...tags, editTag],
                    name,
                    id,
                })
            );
        }
    };

    const onDeleteAudience = tagService => {
        const newArray = jsonTags.map(item => {
            return { ...item, status: item.service === tagService.service ? 'deleted' : 'exists' };
        });
        confirm({
            onSubmit: () => edit({ jsonTags: newArray, name, id }),
            title: cm.get('Confirm.Title.Delete.Audiance').replace('[SERVICE]', tagService.service),
            cancelLabel: cm.get('No'),
            confirmLabel: cm.get('Yes'),
        });
    };
    const handleOnTagDelete = () => {
        const linked = linkedTags
            .filter(({ id: clickTrueTagId }) => clickTrueTagId === id)
            .map(({ serviceName }) => serviceName)
            .filter((service, idx, src) => idx === src.indexOf(service));
        const lineBreak = text => text.split('\\n').map((item, i) => <div key={i}>{item}</div>);

        if (!linked.length) {
            confirm({ onSubmit: () => onDelete({ id }), title: `Are You Sure You Want To Delete ${name}?`, cancelLabel: 'No', confirmLabel: 'Yes' });
            document.getElementById(parentId).click();
        } else {
            confirm({
                onSubmit: () => onDelete({ id }),
                title: lineBreak(
                    linked.length > 1
                        ? cm.get('DeleteTag.TagIsConnectedToMoreThanOne')
                        : String(cm.get('DeleteTag.TagIsConnectedToAudience')).replace('[SERVICE]', _String.capitalize(linked[0]))
                ),
                cancelLabel: 'No',
                confirmLabel: 'Yes',
            });
        }
    };
    return servingDomain ? (
        <div>
            <CheqTag type={'TAG_ID'} tag={{ id, name, tagHash }} forceRefresh={refresh} servingDomain={servingDomain} licences={licences} />
            <div style={{ marginTop: 7 }}>
                <CheqTag type={'TAG_HASH'} tag={{ id, name, tagHash }} forceRefresh={refresh} servingDomain={servingDomain} licences={licences} />
            </div>
            <div style={{ marginTop: 25 }}>
                <CheqTag tag={{ id, name, tagHash }} forceRefresh={refresh} servingDomain={servingDomain} licences={licences} />
            </div>
            <div style={{ marginTop: 25 }}>
                <CheqTag type={'NO_SCRIPT'} tag={{ id, name, tagHash }} forceRefresh={refresh} servingDomain={servingDomain} licences={licences} />
            </div>
            <div style={{ marginTop: 25 }}>
                <div className={classes.title}>
                    <div style={{ alignSelf: 'start' }}>{cm.get('Title.Audience')}</div>
                    <MultiSelectCheckbox
                        styles={selectStyle}
                        disabled={isAuthorized('audience', 'write')}
                        value={editTag && editTag.service && { label: editTag.service, value: editTag.service }}
                        isLoading={isLoading('get_services_list')}
                        options={servicesList}
                        onChange={({ label }) => {
                            setEditTag(jsonTags.find(tag => tag.service === label) || { service: label, tags: [], text: '' });
                        }}
                        placeholder={cm.get('Select.Services')}
                    />
                    <Tooltip title={cm.get('Info.AudienceTag')}>
                        <div>
                            <InfoOutlined className={classes.audienceTagsInfoIcon} />
                        </div>
                    </Tooltip>
                </div>
                <div className={classes.inline}>
                    <Codemirror
                        readOnly={!editTag || !isAuthorized('audience', 'write')}
                        onFocus={() => setEditorFocus(true)}
                        onBlur={() => setEditorFocus(false)}
                        forceRefresh={refresh}
                        className={classes.code}
                        value={editTag && editTag.text}
                        height={160}
                        width={600}
                        options={{ mode: 'htmlembedded', placeholder: cm.get('Input.Audiance') }}
                        onBeforeChange={onBeforeChange}
                    />
                    <div style={{ display: 'flex', width: 600, justifyContent: 'space-between' }}>
                        <TagsChips
                            isAuthorized={isAuthorized}
                            refresh={refresh}
                            className={classes.chipSelected}
                            jsonTags={jsonTags}
                            onDelete={onDeleteAudience}
                            editTag={editTag}
                            onClick={setEditTag}
                        />
                        {!(mainTag || !isAuthorized('click_true_tag', 'write')) && (
                            <div className={styles.button}>{!isNewAccountSettings && <Button onClick={handleOnTagDelete}>{'DELETE TAG'}</Button>}</div>
                        )}
                    </div>
                </div>
                <Tooltip TransitionComponent={Zoom} placement="bottom" title={cm.get('Info.UploadTag')}>
                    <div className={`${classes.inline} ${classes.invalidScript} ${!inputError && canUpload(jsonTags, editTag) ? classes.upload : ''}`}>
                        <Upload onClick={() => !inputError && canUpload(jsonTags, editTag) && uploadTags()} />
                    </div>
                </Tooltip>
            </div>
        </div>
    ) : (
        <Progress />
    );
};

const canUpload = (jsonTags, editTag) => {
    return (
        editTag &&
        editTag.service &&
        Array.isArray(editTag.tags) &&
        editTag.tags.length &&
        (!jsonTags.find(tag => tag.service === editTag.service) || jsonTags.find(tag => tag.service === editTag.service).tags.join('\n') !== editTag.tags.join('\n'))
    );
};

const dialogConfirm =
    dialog =>
    ({ onSubmit = console.log, onCancel = console.log, title, confirmLabel, cancelLabel }) =>
        dialog.show(
            <Confirm
                title={title}
                confirmLabel={confirmLabel}
                cancelLabel={cancelLabel}
                onSubmit={() => {
                    onSubmit();
                    dialog.hide();
                }}
                onCancel={() => {
                    onCancel();
                    dialog.hide();
                }}
            />,
            { noCancel: true, width: 'auto' }
        );

const TagsChips = ({ jsonTags, editTag = {}, className, onClick, onDelete, isAuthorized }) => {
    const chips = [...jsonTags.filter(({ service }) => service !== editTag.service), ...(editTag.service ? [editTag] : [])];
    return (
        <div style={{ marginTop: 5, width: 480, overflowY: 'auto' }}>
            {chips.map((audience, index) => (
                <Chip
                    className={audience.service === editTag.service ? className : ''}
                    key={index}
                    label={audience.service}
                    onDelete={
                        !isAuthorized('audience', 'write') ? undefined : () => (jsonTags.some(({ service }) => service === audience.service) ? onDelete(audience) : onClick())
                    }
                    onClick={() => onClick(audience)}
                />
            ))}
        </div>
    );
};
