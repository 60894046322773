import React, { useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import CheqUI from '@combotag/cheq-ui';
import hooks from '../../../components/hooks';
import styles from './style.module.css';

const { useActions } = CheqUI.React.Meiosis;
const { UseTween } = hooks;
const { Title } = CheqUI.React.Widgets;

const useStyle = makeStyles(team => {
    return {
        blackBox: {
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0,0,0,.72)',
            opacity: 0,
            position: 'fixed',
            zIndex: 10,
        },
    };
});

export default ({ Component, delay = 0.4, options: { Header, title, width = 400, noCancel, style, modalStyle = {} } = {} }) => {
    const actions = useActions();
    const container = useRef(null);
    const blackBox = useRef(null);
    const theme = useTheme();
    const classes = useStyle(theme);

    UseTween(TweenMax =>
        TweenMax.to(blackBox.current, delay, {
            css: { opacity: 1 },
            onComplete: () => TweenMax.to(container.current, delay - delay * 0.5, { css: { opacity: 1 } }),
        })
    );

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div style={style} className={classes.blackBox} ref={blackBox}>
            <div className={styles['container']} style={{ width, ...modalStyle }} ref={container}>
                {Header ? <Header title={title} /> : <Title>{title}</Title>}
                {!noCancel ? (
                    <div onClick={actions.dialog.hide} className={styles['close-dialog']}>
                        x
                    </div>
                ) : (
                    <div />
                )}
                <div className={styles['content-container']}>{Component}</div>
            </div>
        </div>
    );
};
