import React, { useMemo } from 'react';

import { Divider } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';
import ReactDOMServer from 'react-dom/server';
import styles from './heatmap.module.scss';
import { toLocaleString } from '@utils/dashboard';
import widgets from '@components/widgets';

const { Map } = widgets;

const HeatMap = ({ data = [], dashboardCM }) => {
    const tooltip = {
        formatter: function () {
            const {
                options: { key, value, total = 0 },
                name,
            } = this.point;
            return ReactDOMServer.renderToStaticMarkup(
                <div>
                    <div className={styles.tooltipHeader}>
                        <ReactCountryFlag countryCode={key} svg style={{ fontSize: '1.8rem' }} />
                        <span style={{ fontSize: '1.6rem' }}>{name}</span>
                    </div>
                    <Divider className={styles.tooltipDivider} />
                    <div className={styles.tooltipBody}>
                        <div>
                            <div>{dashboardCM.get('TotalVisits.Title')}</div>
                            <strong> {toLocaleString(total)}</strong>
                        </div>
                        <div>
                            <div>{dashboardCM.get('InvalidVisits')}</div> <strong> {toLocaleString(value)}</strong>
                        </div>
                    </div>
                </div>
            );
        },
        useHTML: true,
        backgroundColor: 'white',
        shadow: true,
        borderWidth: 0,
        borderRadius: 12,
        padding: 0,
    };

    const mapData = useMemo(
        () =>
            data.map(({ country, invalidClicks, total }) => {
                country = country === 'UK' ? 'GB' : country;
                return { key: country.toLowerCase(), value: invalidClicks, total };
            }),
        [data]
    );

    return <Map {...{ data: mapData, tooltip }} />;
};

export default HeatMap;
