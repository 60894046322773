import React from 'react';
import { formatToK } from '@utils/dashboard';
import styles from './stacked-bar.module.css';

export const StackedBar = ({ barObj }) => {
    if (barObj === undefined) return <></>;

    const bar = [];
    const legend = [];
    for (const obj of barObj) {
        if (obj.value > 0) {
            bar.push(<div key={obj.label} className={styles.bar}
                          style={{ width: `${obj.percent}%`, backgroundColor: `${obj.color}` }}>
                <div className={styles.text}>{formatToK(obj.value)}</div>
            </div>);
            legend.push(<div key={obj.label} className={styles.legendLine}>
                <div className={styles.legend} style={{ backgroundColor: `${obj.color}` }}></div>
                <div className={styles.legendText}>{obj.label}</div>
                <b>{formatToK(obj.value)}</b></div>);
        }
    }

    return (
        <div>
            <div className={styles.container}>
                {bar}
            </div>
            <div>
                {legend}
            </div>
        </div>
    );
};
