import { useMemo, useEffect } from 'react';

import { DashboardItem, DashboardSection, ToolTip } from '@widgets';
import { useSelector } from 'react-redux';
import { parsePageViews } from '@utils/dashboard/on-site-converion';
import { getPercentage, formatToK } from '@utils/common';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';

import { useQuery } from 'react-query';
import * as api from '@services/api/rti-dashboard';

import cx from 'classnames';

import { useContentManager } from '@hooks';

import styles from './VisitsSummary.module.scss';

const VisitsSummary = ({ options, isSettingsLoading }) => {
    const cm = useContentManager();
    const {
        isLoading: isPageViewsLoading,
        isError: isPageViewsError,
        data: [pageViewsData] = [{ total: 0, invalid: 0 }],
    } = useQuery([api.RTIAPIkeys.PAGE_VIEWS, options], () => api.getPageViews(options));
    const {
        isLoading: isVisitsSummaryLoading,
        isError: isVisitsSummaryError,
        data: [visitsSummaryData] = [{ invalid: 0, total: 0 }],
    } = useQuery([api.RTIAPIkeys.VISITS_SUMMARY, options], () => api.getVisitsSummary(options));

    return (
        <div className={styles.visitsSummaryAndPageViewsContainer}>
            <DashboardSection title={cm.get('VisitsSummary').toUpperCase()} data-test-selector="rti-pageviews-visits-summary">
                <div className={styles.visitSummaryContent}>
                    <DashboardItem
                        isLoading={isVisitsSummaryLoading || isSettingsLoading}
                        isError={isVisitsSummaryError}
                        data-test-selector="rti-total-visits"
                        className={styles.item}
                        title={
                            <div className={styles.title}>
                                {cm.get('TotalVisits')}
                                <ToolTip content={cm.get('TotalVisitsDescription')} title={cm.get('TotalVisits')}>
                                    <InfoIcon className={styles.icon} cursor="pointer" />
                                </ToolTip>
                            </div>
                        }
                    >
                        <div className={styles.wrapper}>
                            <div className={styles.numbersWrraper}>
                                <div className={styles.numbers}>
                                    <span className={styles.value}>{visitsSummaryData.total.toLocaleString()}</span>
                                </div>
                            </div>
                        </div>
                    </DashboardItem>
                    <DashboardItem
                        isLoading={isVisitsSummaryLoading || isSettingsLoading}
                        isError={isVisitsSummaryError}
                        data-test-selector="rti-total-invalid-visits"
                        className={styles.item}
                        title={
                            <div className={styles.title}>
                                {cm.get('TotalInvalidVisits')}
                                <ToolTip content={cm.get('TotalInvalidVisitsDescription')} title={cm.get('TotalInvalidVisits')}>
                                    <InfoIcon className={styles.icon} cursor="pointer" />
                                </ToolTip>
                            </div>
                        }
                    >
                        <div className={styles.wrapper}>
                            <div className={styles.numbersWrraper}>
                                <div className={styles.numbers}>
                                    <span className={styles.value}>{visitsSummaryData.invalid?.toLocaleString()}</span>
                                    <span className={styles.percentage}>({getPercentage(visitsSummaryData.invalid, visitsSummaryData.total)}%)</span>
                                </div>
                            </div>

                            <div className={styles.outOfTotal}>
                                <span className={cx({ [styles.spacer]: true })}>{cm.get('OutOfTotal')}</span>
                                <span>{visitsSummaryData.total?.toLocaleString()}</span>
                            </div>
                        </div>
                    </DashboardItem>
                </div>
            </DashboardSection>
            <DashboardSection title={cm.get('PageViewsSummary').toUpperCase()} data-test-selector="rti-pageviews-visits-summary">
                <div className={styles.visitSummaryContent}>
                    <DashboardItem
                        isLoading={isPageViewsLoading || isSettingsLoading}
                        isError={isPageViewsError}
                        data-test-selector="rti-pageviews"
                        className={styles.item}
                        title={
                            <div className={styles.title}>
                                {cm.get('TotalPageViews')}
                                <ToolTip content={cm.get('TotalPageViewsDescription')} title={cm.get('TotalPageViews')}>
                                    <InfoIcon className={styles.icon} cursor="pointer" />
                                </ToolTip>
                            </div>
                        }
                    >
                        <div className={styles.wrapper}>
                            <div className={styles.numbersWrraper}>
                                <div className={styles.numbers}>
                                    <span className={styles.value}>{pageViewsData.total.toLocaleString()}</span>
                                </div>
                            </div>
                        </div>
                    </DashboardItem>
                    <DashboardItem
                        isLoading={isPageViewsLoading || isSettingsLoading}
                        isError={isPageViewsError}
                        data-test-selector="rti-invalid-pageviews"
                        className={styles.item}
                        title={
                            <div className={styles.title}>
                                {cm.get('TotalInvalidPageViews')}
                                <ToolTip content={cm.get('TotalInvalidPageViewsDescription')} title={cm.get('TotalInvalidPageViews')}>
                                    <InfoIcon className={styles.icon} cursor="pointer" />
                                </ToolTip>
                            </div>
                        }
                    >
                        <div className={styles.wrapper}>
                            <div className={styles.numbersWrraper}>
                                <div className={styles.numbers}>
                                    <span className={styles.value}>{pageViewsData.invalid?.toLocaleString()}</span>
                                    <span className={styles.percentage}>({getPercentage(pageViewsData.invalid, pageViewsData.total)}%)</span>
                                </div>
                            </div>
                            <div className={styles.outOfTotal}>
                                <span className={cx({ [styles.spacer]: true })}>{cm.get('OutOfTotal')}</span>
                                <span>{pageViewsData.total?.toLocaleString()}</span>
                            </div>
                        </div>
                    </DashboardItem>
                </div>
            </DashboardSection>
        </div>
    );
};

export default VisitsSummary;
