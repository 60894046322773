import { Button as MUIButton } from '@material-ui/core';
import styles from './Button.module.scss';
import cx from 'classnames';

export const Button = ({ children, className, ...rest }) => {
    return (
        <MUIButton className={cx(styles.root, className)} {...rest}>
            {children}
        </MUIButton>
    );
};
