import * as actions from '@redux/actions/dashboard';

import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import BlockedIcon from '@components/svgs/header/blocked';
// import IconWrapper from './icon.wrapper';
import PlatformsIcon from '@components/svgs/header/platforms';
import UnblockIcon from '@components/svgs/header/unblock';
import classNames from 'classnames/bind';
import { dashboardDisplayOptions } from '../../constants';
import styles from './header-tabs.module.css';
import { useContentManager } from '@hooks';
import { getPixelGuardNetworkStatus } from '../../../../../services/api';
const cx = classNames.bind(styles);

export const HeaderTabs = ({ isBlockingMode, onChangeDisplayOption, currentDisplayOption }) => {
    const dispatch = useDispatch();
    const [platformClasses] = useState({ labelIcon: cx('label-icon'), root: cx('tab'), wrapper: cx('wrapper') });
    const dashboardCM = useContentManager(cm => cm.dashboard);

    const setDisplayOption = (event, option) => {
        getPixelGuardNetworkStatus().then(res => {
            onChangeDisplayOption(option);
            setTimeout(() => {
                dispatch(actions.setDashboardDisplayOption({ selectedDisplayOption: option, isPixelGuardIntegrated: res.isPixelGuardIntegrated }));
            }, 0);
        });

    };

    const tabs = [
        <Tab
            key="0"
            icon={<PlatformsIcon />}
            classes={platformClasses}
            label={dashboardCM.get('AllPlatforms')}
            value={dashboardDisplayOptions.ALL_PLATFORMS}
            data-test-selector="all platforms tab"
        />,
        ...(isBlockingMode
            ? [
                <Tab
                    key="1"
                    icon={<BlockedIcon />}
                    classes={platformClasses}
                    label={dashboardCM.get('ProtectedPlatforms')}
                    value={dashboardDisplayOptions.PROTECTED_PLATFORMS}
                    data-test-selector="protected platforms tab"
                />,
                <Tab
                    key="2"
                    icon={<UnblockIcon />}
                    classes={platformClasses}
                    label={dashboardCM.get('MonitoredPlatforms')}
                    value={dashboardDisplayOptions.UNPROTECTED_PLATFORMS}
                    data-test-selector="unprotected platforms tab"
                />,
            ]
            : []),
    ];

    return (
        <Tabs
            data-test-selector="header tabs"
            onChange={setDisplayOption}
            value={currentDisplayOption}
            classes={{ flexContainer: cx('flex-container'), indicator: cx('indicator'), root: cx('root') }}
        >
            {tabs}
        </Tabs>
    );
};
