import React from "react";
import styled, {css} from 'styled-components'


const Container = styled.div`
    letter-spacing: 1.3px;
    font-size: 15px;
    text-align:center;
    ${({lineHeight,multiLine}) =>  multiLine ? css`
        margin-top: ${lineHeight}px;
    ` :css`
        line-height: ${lineHeight}px;
    `}
`;

const NoData = ({children = 'NO DATA TO DISPLAY', lineHeight = 190,multiLine = false, style}) =>{
	return (
		<Container lineHeight={lineHeight} multiLine={multiLine} style={{...style}}>
			{children}
		</Container>
	)
}

export default NoData;
