import React, { useEffect, useRef } from 'react';

import Highcharts from 'highcharts';
import { formatToK } from '@utils/dashboard';

export const DonutGraph = ({ values, total, wrapperSize = '160px', fontSize = '25px' }) => {
    const graphRef = useRef(null);

    const chartTitleStyle = {
        color: '#ffffff',
        'font-weight': 600,
        'font-size': fontSize,
        'line-height': fontSize,
    };

    useEffect(() => {
        if (!Array.isArray(values) || !graphRef.current) return;

        Highcharts.chart(graphRef.current, {
            chart: {
                plotBorderWidth: 0,
                plotShadow: false,
                spacing: [0, 0, 0, 0],
            },
            title: {
                text: formatToK(total),
                style: chartTitleStyle,
                align: 'center',
                verticalAlign: 'middle',
                y: parseInt(fontSize, 10),
            },
            tooltip: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    slicedOffset: 15,
                    startAngle: 0,
                    endAngle: 0,
                    size: '100%',
                    borderColor: '#383852',
                    borderWidth: '1px',
                    enableMouseTracking: false,
                },
                series: {
                    animation: false,
                },
            },
            series: [
                {
                    type: 'pie',
                    innerSize: '75%',
                    data: values.map(v => {
                        const value = v.value ? v.value : 0;
                        return { y: value, name: v.label, color: v.color };
                    }),
                },
            ],
            credits: {
                enabled: false,
            },
        });
    });

    if (values === undefined || values.length === undefined) return <></>;

    return <div className="donut-graph" ref={graphRef} style={{ minWidth: wrapperSize, minHeight: wrapperSize }}></div>;
};
