import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Fab from '@material-ui/core/Fab';
import styles from './pixel-guard.module.scss';
import widgets from '@components/widgets';
import {
    Input,
    Select,
    MenuItem,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Button,
} from '@material-ui/core';

const PIXEL_ACTIVE_STATUS = 1;
const PIXEL_INACTIVE_STATUS = 0;

const { CheckBox, NoData } = widgets;

const PixelGuard = ({ clickTrueTags, emptyPixel, setEmptyPixel, sortedPixelsGuard, setSortedPixelsGuard }) => {
    const [sortBy, setSortBy] = useState('');
    const [sortAscending, setSortAscending] = useState(true);
    const [tagValues, setTagValues] = useState([]);

    useEffect(() => sortedPixelsGuard && sortPixelData(), [sortBy, sortAscending]);

    useEffect(() => (!tagValues.length && clickTrueTags.length) && setTagValues(clickTrueTags.map(tag => ({ value: tag.id, label: tag.name }))), [clickTrueTags]);

    const sortPixelData = () => {
        setSortedPixelsGuard(sortedPixelsGuard.slice()
            .sort((a, b) => {
                if (sortAscending) {
                    return a[sortBy] > b[sortBy] ? 1 : -1;
                } else {
                    return a[sortBy] < b[sortBy] ? 1 : -1;
                }
            }));
    };


    const handleChangeTagId = (newTagId, sortedDataIndex) => {
        if (emptyPixel) {
            ((emptyPixel.pixelId.length) && newTagId)
                ? setEmptyPixel(null)
                : setEmptyPixel({ ...emptyPixel, tagId: newTagId });
        }
        const copiedSortedPixelsGuard = [...sortedPixelsGuard];
        copiedSortedPixelsGuard[sortedDataIndex].tagId = newTagId;
        setSortedPixelsGuard(copiedSortedPixelsGuard);
    };

    const handleChangePixelActive = (rowActive, sortedDataIndex) => {
        if (emptyPixel) {
            setEmptyPixel({ ...emptyPixel, active: emptyPixel.active ? PIXEL_INACTIVE_STATUS : PIXEL_ACTIVE_STATUS });
        }
        const copiedSortedPixelsGuard = [...sortedPixelsGuard];
        copiedSortedPixelsGuard[sortedDataIndex].active = rowActive ? PIXEL_INACTIVE_STATUS : PIXEL_ACTIVE_STATUS;
        setSortedPixelsGuard(copiedSortedPixelsGuard);
    };

    const handleDeleteTag = (sortedDataIndex) => {
        if (emptyPixel) {
            setEmptyPixel(null);
        }
        const copiedSortedPixelsGuard = [...sortedPixelsGuard];
        copiedSortedPixelsGuard.splice(sortedDataIndex, 1);
        setSortedPixelsGuard(copiedSortedPixelsGuard);
    };

    const handleChangeTagName = (pixelIdText, sortedDataIndex) => {
        const copiedSortedPixelsGuard = [...sortedPixelsGuard];
        if (emptyPixel) {
            ((pixelIdText.length || emptyPixel.pixelId.length) && emptyPixel.tagId)
                ? setEmptyPixel(null)
                : setEmptyPixel({ ...emptyPixel, pixelId: pixelIdText });
        }
        copiedSortedPixelsGuard[sortedDataIndex].pixelId = pixelIdText;
        setSortedPixelsGuard(copiedSortedPixelsGuard);
    };

    const handleAddPixel = () => {
        if (!emptyPixel && tagValues && tagValues.length) {
            const newPixel = {
                active: PIXEL_ACTIVE_STATUS,
                pixelId: '',
                tagId: 0,
            };
            const copiedSortedPixelsGuard = [...sortedPixelsGuard];
            copiedSortedPixelsGuard.push(newPixel);
            setSortedPixelsGuard(copiedSortedPixelsGuard);
            setEmptyPixel(newPixel);
        }
    };

    const handleSort = (column) => {
        if (!emptyPixel) {
            if (sortBy === column) {
                setSortAscending(!sortAscending);
            } else {
                setSortBy(column);
                setSortAscending(true);
            }
        }
    };

    return (
        <div>
            {(tagValues.length && sortedPixelsGuard.length) || emptyPixel
                ?
                <TableContainer style={{ maxHeight: 350, minHeight: 350, background: '#282b3f' }}>
                    <Table stickyHeader>
                        <TableHead style={{ background: '#383851' }}>
                            <TableRow className={styles.headers}>
                                <TableCell />
                                <TableCell >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Google Tag ID</span>
                                        <span onClick={() => handleSort('pixelId')} style={{ fontSize: 'xx-large', cursor: 'pointer' }}>{sortBy === 'pixelId' && sortAscending ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </div>
                                </TableCell>
                                <TableCell >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>CHEQ Tag</span>
                                        <span onClick={() => handleSort('tagId')} style={{ fontSize: 'xx-large', cursor: 'pointer' }}>{sortBy === 'tagId' && sortAscending ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </div>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedPixelsGuard.map((row, index) => (
                                <TableRow key={index} className={index % 2 ? styles.rowColorTwo : styles.rowColorOne}>
                                    <TableCell className={styles.cellContainer} style={{ padding: '0 0 0 10px' }}>
                                        <CheckBox disabled={!!emptyPixel && index + 1 !== sortedPixelsGuard.length}
                                            checked={row.active === PIXEL_ACTIVE_STATUS} onChange={() => handleChangePixelActive(row.active, index)} />
                                    </TableCell>
                                    <TableCell className={styles.cellContainer}>
                                        <Input
                                            className={styles.pixelIdInput}
                                            disabled={!!emptyPixel && index + 1 !== sortedPixelsGuard.length}
                                            value={row.pixelId}
                                            variant="outlined"
                                            onChange={(event) => handleChangeTagName(event.target.value, index)} />
                                    </TableCell>
                                    <TableCell className={styles.cellContainer}>
                                        <Select
                                            className={styles.selectTagId}
                                            disabled={!!emptyPixel && index + 1 !== sortedPixelsGuard.length}
                                            data-test-selector={'select-selector'}
                                            onChange={(event) => handleChangeTagId(event.target.value, index)}
                                            value={(row.tagId)}
                                            classes={{
                                                select: `${styles.selectInput}`,
                                                nativeInput: styles.item
                                            }}
                                        >
                                            {tagValues.map(({ label, value }, i) => (
                                                <MenuItem key={value + i} value={value} className={styles.item}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell className={styles.cellContainer}>
                                        <Button className={styles.deleteIcon} onClick={() => handleDeleteTag(index)} disabled={!!emptyPixel && index + 1 !== sortedPixelsGuard.length}>
                                            <DeleteForeverIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                : <NoData lineHeight={40} style={{ padding: 20 }}>
                    <div>NO PIXEL GUARD WAS DEFINED</div>
                </NoData>}

            <Fab onClick={handleAddPixel} className={styles.addIcon} aria-label='add'>
                <AddIcon />
            </Fab>
        </div>
    );
};

export default PixelGuard;
