import React from "react";
import styles from "./maintenance.module.scss";

const Maintenance = () => (
    <div className={styles.container}>
        <h1>Sorry, we're down for maintenance.</h1>
        <h3>We'll be back shortly.</h3>
    </div>
)

export default Maintenance;