import IconButton from '@material-ui/core/IconButton';
import { PlayArrow } from '@material-ui/icons';
import { ChangeEvent, FC, useState } from 'react';
import { iam } from 'services/api';
import { UserAPI } from './Types';
import styles from '../styles.module.scss';
import { formatErrorMessage, generatePassword } from './utils';

interface IProps {
    addUserToUI: (params: UserAPI) => void;
    networkId: number;
    cm: any;
}

const CreateApi: FC<IProps> = ({ addUserToUI, networkId, cm }) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);

    const handleGenerateClientIdAndSecret = async () => {
        if (!name) return;
        const password = generatePassword();
        try {
            const res = await iam.createUser(name, password, networkId);
            const { username } = res;
            addUserToUI({ name, username, password });
            setError('');
            setName('');
        } catch (_err) {
            //temporary workaround for tslint error on line 27
            let e: any = _err;
            const formattedError = formatErrorMessage(e.data, cm);
            setError(formattedError);
        }
    };

    return (
        <>
            <div className={styles.row}>
                <div className={styles.smallInput}>
                    <div className={styles.dFlexCol}>
                        <span className={styles.label}>{cm.get('IntegrationName')}</span>
                        <input className={styles.textContainer} value={name} onChange={handleNameChange} />
                    </div>
                    {error && <span className={styles.error}>{error}</span>}
                </div>
                <div onClick={handleGenerateClientIdAndSecret} className={`${!name ? styles.disabled : ''} ${styles.row} ${styles.generateButton}`}>
                    <IconButton size="small">
                        <PlayArrow className={styles.button} fontSize="small" />
                    </IconButton>
                    <div className={styles.button}>{cm.get('GenerateClientIdAndSecret')}</div>
                </div>
            </div>
        </>
    );
};

export default CreateApi;
