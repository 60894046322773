import React from "react";
import { FormControl, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import styles from './radio.module.scss';

export default ({ name, value, items, onChange, selector }) => {
    return (
        <FormControl component="fieldset">
            <RadioGroup
                row
                data-test-selector={selector}
                className={styles.root}
                defaultValue="female"
                name={name}
                value={value}
                onChange={onChange}
            >
                {items.map((item, index) => (
                    <FormControlLabel key={index}
                                      value={item.value}
                                      classes={{
                                          label: styles.label
                                      }}
                                      control={<Radio disableRipple size="small" />}
                                      label={item.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}