import * as common from './common';
import { threatGroups as threatGroupsConstants } from '@utils/dashboard';
import { VISIT_TYPES } from '../../modules/data-analytics/dashboard/constants';

export const getThreatsByThreatGroups = (invalidByThreatType, threatGroups) => {
    if (!invalidByThreatType || !Array.isArray(threatGroups)) return {};

    if (!Array.isArray(invalidByThreatType)) invalidByThreatType = [invalidByThreatType];

    const threatTypesObject = threatGroups.reduce((result, current) => {
        current.threatTypes.forEach(tt => {
            result[tt] = current.threatGroup;
        });
        return result;
    }, {});

    const paidTrafficThreats = [];
    const organicTrafficThreats = [];

    invalidByThreatType.forEach(element => {
        if (common.isOrganicPlatform(element.platformOrigin)) {
            organicTrafficThreats.push(element);
        } else {
            paidTrafficThreats.push(element);
        }
    });

    const threatGroupReducer = (result, current) => {
        const threatGroup = threatTypesObject[current.threatTypeReason];
        const threatType = current.threatTypeReason;
        const invalidClicks = common.getIntOrZero(current.invalidClicks);

        if (!result[threatGroup]) {
            result[threatGroup] = { total: 0 };
        }

        if (result[threatGroup][threatType]) {
            result[threatGroup][threatType]['invalidClicks'] += invalidClicks;
        } else {
            result[threatGroup][threatType] = { invalidClicks: invalidClicks };
        }

        result[threatGroup][threatType]['protected'] = !!current.protected;
        result[threatGroup].total += invalidClicks;

        return result;
    };

    const clicksReducer = (result, current) => {
        return (result += current.total);
    };

    const paid = paidTrafficThreats.reduce(threatGroupReducer, {});
    paid.total = Object.values(paid).reduce(clicksReducer, 0);

    const organic = organicTrafficThreats.reduce(threatGroupReducer, {});
    organic.total = Object.values(organic).reduce(clicksReducer, 0);

    const total = invalidByThreatType.reduce(threatGroupReducer, {});
    total.total = Object.values(total).reduce(clicksReducer, 0);

    return {
        paid,
        organic,
        total,
    };
};

export const getRTIThreatsByThreatGroups = (invalidByThreatType, threatGroups, showUnclassified = false) => {
    if (!invalidByThreatType || !Array.isArray(threatGroups)) return {};

    if (!Array.isArray(invalidByThreatType)) invalidByThreatType = [invalidByThreatType];

    const threatTypesObject = threatGroups.reduce((result, current) => {
        current.threatTypes.forEach(tt => {
            result[tt] = current.threatGroup;
        });
        return result;
    }, {});

    const paidTrafficThreats = [];
    const organicTrafficThreats = [];
    const directTrafficThreat = [];
    const unclassifiedTrafficThreat = [];

    invalidByThreatType.forEach(element => {
        if (element.platformOrigin === VISIT_TYPES.direct.toUpperCase()) {
            directTrafficThreat.push(element);
        } else if (common.isOrganicPlatform(element.platformOrigin)) {
            organicTrafficThreats.push(element);
        } else if (showUnclassified && element.platformOrigin === VISIT_TYPES.unclassified.toUpperCase()) {
            unclassifiedTrafficThreat.push(element);
        } else {
            paidTrafficThreats.push(element);
        }
    });

    const threatGroupReducer = (result, current) => {
        if (current.threatGroup !== threatGroupsConstants.CUSTOM_RULES && !threatTypesObject[current.threatTypeReason]) {
            return result;
        }
        const threatGroup = current.threatGroup;
        const threatType = current.threatTypeReason;
        const invalidClicks = common.getIntOrZero(current.invalidClicks);

        if (!result[threatGroup]) {
            result[threatGroup] = { total: 0 };
        }

        if (result[threatGroup][threatType]) {
            result[threatGroup][threatType]['invalidClicks'] += invalidClicks;
        } else {
            result[threatGroup][threatType] = { invalidClicks: invalidClicks };
        }

        result[threatGroup][threatType]['protected'] = !!current.protected;
        result[threatGroup].total += invalidClicks;

        return result;
    };

    const clicksReducer = (result, current) => {
        return (result += current.total);
    };

    const paid = paidTrafficThreats.reduce(threatGroupReducer, {});
    paid.total = Object.values(paid).reduce(clicksReducer, 0);

    const organic = organicTrafficThreats.reduce(threatGroupReducer, {});
    organic.total = Object.values(organic).reduce(clicksReducer, 0);

    const direct = directTrafficThreat.reduce(threatGroupReducer, {});
    direct.total = Object.values(direct).reduce(clicksReducer, 0);

    const unclassified = unclassifiedTrafficThreat.reduce(threatGroupReducer, {});
    unclassified.total = Object.values(unclassified).reduce(clicksReducer, 0);

    const total = invalidByThreatType.reduce(threatGroupReducer, {});
    total.total = Object.values(total).reduce(clicksReducer, 0);

    const returnObject = {
        paid,
        organic,
        total,
        direct,
    };

    if (showUnclassified) returnObject.unclassified = unclassified;

    return returnObject;
};
