import { httpRequest, methods } from '../http';
/* @ts-ignore */
import config from '@config';

class PixelGuardService {
    pixelTypes = {
        GET_PIXEL_SETTINGS: 'get_pixel_settings',
    };

    BASE_URL = config.baseAPI;

    PATH_NAME_PREFIX = '/pixel_guard_service';

    getAccounts = () => {
        try {
            const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.get, pathname: `${this.PATH_NAME_PREFIX}/account` });
            return response;
        } catch (err) {
            console.log({ err });
        }
    };

    getContainers = ({ accountId }) => {
        try {
            const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.get, pathname: `${this.PATH_NAME_PREFIX}/account`, params: { accountId } });
            return response;
        } catch (err) {
            console.log({ err });
        }
    };

    getWorkspaces = ({ accountId, containerId }) => {
        try {
            const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.get, pathname: `${this.PATH_NAME_PREFIX}/account`, params: { accountId, containerId } });
            return response;
        } catch (err) {
            console.log({ err });
        }
    };

    getTags = ({ accountId, workspaceId, containerId }) => {
        try {
            const { response } = httpRequest({
                baseURL: this.BASE_URL,
                method: methods.get,
                pathname: `${this.PATH_NAME_PREFIX}/account`,
                params: { accountId, containerId, workspaceId },
            });
            return response;
        } catch (err) {
            console.log({ err });
        }
    };

    saveTags = ({ accountId, workspaceId, containerId, body }) => {
        httpRequest({ baseURL: this.BASE_URL, method: methods.put, pathname: `${this.PATH_NAME_PREFIX}/account`, body, params: { accountId, containerId, workspaceId } });
    };

    deleteTag = ({ searchId, internalConversionId }) => {
        try {
            httpRequest({ baseURL: this.BASE_URL, method: methods.delete, pathname: `${this.PATH_NAME_PREFIX}/conversion`, params: { searchId, internalConversionId } });
        } catch (err) {
            let error = err.message || err.Error;
            throw error;
        }
    };

    deleteWorkspace = ({ searchId, workspaceName }) => {
        try {
            httpRequest({ baseURL: this.BASE_URL, method: methods.delete, pathname: `${this.PATH_NAME_PREFIX}/conversion`, params: { searchId, workspaceName } });
        } catch (err) {
            let error = err.message || err.Error;
            throw error;
        }
    };

    getAllSavedConversions = ({ searchId, workspaceName }) => {
        try {
            const params = { searchId };
            if (workspaceName) {
                params.workspaceName = workspaceName;
            }
            const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.get, pathname: `${this.PATH_NAME_PREFIX}/conversion`, params });
            return response;
        } catch (err) {
            console.log({ err });
        }
    };

    getTagData = ({ tagId }) => {
        try {
            const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.get, pathname: `${this.PATH_NAME_PREFIX}/settings/info`, params: { searchId: tagId } });
            return response;
        } catch (err) {
            console.log({ err });
        }
    };

    getSettings = () => {
        console.log(this.BASE_URL);
        const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.get, pathname: `${this.PATH_NAME_PREFIX}/settings` });
        return response;
    };

    addPixelGuard = tagId => {
        try {
            const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.post, pathname: `${this.PATH_NAME_PREFIX}/settings`, body: { tagId } });
            return response;
        } catch (err) {
            console.log({ err });
        }
    };

    deletePixel = tagId => {
        const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.post, pathname: `${this.PATH_NAME_PREFIX}/settings/delete`, body: { tagId } });
        return response;
    };

    enableFeature = () => {
        const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.get, pathname: `${this.PATH_NAME_PREFIX}/enable_pxg_v2` });
        return response;
    };

    disableFeature = version => {
        const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.get, pathname: `${this.PATH_NAME_PREFIX}/disable_pxg/?version=${version}` });
        return response;
    };

    deletePxgV2Setting = () => {
        const { response } = httpRequest({ baseURL: `${this.BASE_URL}/api/v2/pixel-guard/delete_settings`, method: methods.delete });
        return response;
    };

    updatePlatforms = platforms => {
        try {
            const { response } = httpRequest({ baseURL: this.BASE_URL, method: methods.put, pathname: `${this.PATH_NAME_PREFIX}/settings/update`, body: { platforms } });
            return response;
        } catch (err) {
            console.log({ err });
        }
    };
}

export const pixelGuardService = new PixelGuardService();

export default pixelGuardService;
