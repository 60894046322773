import React from 'react';
import styles from './style.module.css';
import CheckboxMui from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Checkbox = ({label, onChange, checked, onClick = onChange, style, disabled = false}) => {
	return (
		<FormControlLabel
			control={
				<CheckboxMui
					disabled={disabled}
					checked={checked}
					onChange={onClick}
					value="checkedB"
					classes={{root: styles['checkbox-root']}}
				/>
			}
			label={label}
			style={{color: '#fe0072', ...style}}
			className={styles['label']}
			classes={{label: styles['label-text'], root : styles['label-root']}}
		/>
	);
};

export default Checkbox;
