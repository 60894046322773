import copy from 'clipboard-copy';
import { pixelGuardService } from '../../../../../../services/api/pixelGuard.service';
// @ts-ignore
import CopyIcon from '../../../../../svgs/copy';
import { useEffect, useState } from 'react';
// @ts-ignore
import Tooltip from '../../../../../widgets/tool-tip';
/* @ts-ignore */
import { useSelector } from 'react-redux';
import { PixelSettingsType } from '../../types';
import styles from './List.module.scss';
import Zoom from '@material-ui/core/Zoom';
import { DeleteForever } from '@material-ui/icons';

type Data = {
    searchId: number;
    tagName: string;
    eventName: string;
    validCode: string;
    invalidCode: string;
};
type TagManagerResponse = {
    cookieName: string;
    eventName: string;
    invalidCode: string;
    validCode: string;
};

interface IProps {
    index: number;
    cm: any;
    item: PixelSettingsType;
    onDelete: (tagId: number) => void;
}
const ListItem = ({ index, cm, item, onDelete }: IProps) => {
    const [copiedCookie, setCopiedCookie] = useState(false);
    const [copiedValid, setCopiedValid] = useState(false);
    const [copiedInvalid, setCopiedInvalid] = useState(false);
    const [data, setData] = useState<Data | Object>({});
    const [error, setError] = useState(false);
    const { tags } = useSelector((state: any) => state.clickTrueTags);
    const channelName = tags.filter((tag: any) => tag.id === item.tagId)[0]?.name;
    const onCopy = (input: string, cb: (status: boolean) => void) => {
        copy(input);
        cb(true);
    };

    useEffect(() => {
        // @ts-ignore
        pixelGuardService.getTagData({ tagId: item.tagId }).then((res: TagManagerResponse) => {
            if (res && Object.keys(res).length) {
                setData({
                    searchId: item.tagId,
                    tagName: channelName,
                    eventName: res.eventName,
                    validCode: res.validCode,
                    invalidCode: res.invalidCode,
                });
            } else {
                setError(true);
            }
        });
    }, []);

    return Object.keys(data).length > 0 ? (
        <div key={index} className={styles.container}>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <div>{cm.get('Channel')}</div>
                        <div className={styles.data}>
                            {data['tagName']?.length > 24 ? (
                                <Tooltip TransitionComponent={Zoom} placement="bottom" title={data['tagName']}>
                                    <div className={styles.channel}>{data['tagName']}</div>
                                </Tooltip>
                            ) : (
                                <div className={styles.channel}>{data['tagName']}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <div>{cm.get('EventName')}</div>
                        <div className={styles.data}>
                            <div>{data['eventName']}</div>
                            <Tooltip TransitionComponent={Zoom} placement="bottom" title={copiedCookie ? cm.get('Copied') : cm.get('CopyToClipboard')}>
                                <div className={styles.icon}>
                                    <CopyIcon onClick={() => onCopy(data['eventName'], setCopiedCookie)} />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <div>{cm.get('ValidCode')}</div>
                        <div className={styles.data}>
                            <div>{data['validCode']}</div>
                            <Tooltip TransitionComponent={Zoom} placement="bottom" title={copiedValid ? cm.get('Copied') : cm.get('CopyToClipboard')}>
                                <div className={styles.icon}>
                                    <CopyIcon onClick={() => onCopy(data['validCode'], setCopiedValid)} />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <div>{cm.get('InvalidCode')}</div>
                        <div className={styles.data}>
                            <div>{data['invalidCode']}</div>
                            <Tooltip TransitionComponent={Zoom} placement="bottom" title={copiedInvalid ? cm.get('Copied') : cm.get('CopyToClipboard')}>
                                <div className={styles.icon}>
                                    <CopyIcon onClick={() => onCopy(data['invalidCode'], setCopiedInvalid)} />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <DeleteForever className={styles.deleteIcon} onClick={() => onDelete(data['searchId'])} />
            </div>
        </div>
    ) : error ? (
        <div className={styles.error}>{`Failed to fetch pixel data for channel - ${channelName}`}</div>
    ) : (
        <></>
    );
};

export default ListItem;
