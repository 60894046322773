import { getAccountCampaigns } from '../google';
import { GoogleQueryKeys } from '../constants';
import { useQuery } from 'react-query';
import { useGetHierarchy } from './use-get-hierarchy';
import { GetHierarchyResponse } from '../types';

export const useGetCampaigns = (googleAccountId: string) => {
    const { data } = useGetHierarchy();

    let parentId = '';
    if (!(data instanceof Error) && !!data) {
        parentId = getParent(googleAccountId, data);
    }

    return useQuery([GoogleQueryKeys.GET_CAMPAIGNS, googleAccountId, parentId], () => getAccountCampaigns(googleAccountId, parentId), {
        enabled: !!googleAccountId && !!parentId,
        cacheTime: 60000 * 5,
    });
};

const getParent = (accountId: string, data: GetHierarchyResponse) => {
    for (const item of data) {
        if (item.children.some(child => child.accountId === accountId)) {
            return item.accountId;
        }
    }
    return accountId;
};
