import './highcharts-config';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import CheqUI from '@combotag/cheq-ui';
import HttpsRedirect from 'react-https-redirect';
import Sidebar from './multi-product/Sidebar';
import Brands from './components/containers/authentication/brands';
import Theme from './styles/theme';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import config from './config';
import layouts from './components/layouts';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import widgets from './components/widgets';
import StartupProvider from './context/Startup';
import ContentManagerProvider from './context/ContentManager';
import { getNetworkFeatures } from '@redux/thunks/settings';
import { setMeta } from '@redux/actions/profile';
import { getContent } from '@redux/thunks/profile';
import { getUsersInOrganization } from '@redux/thunks/users';
import ModalContainer from 'react-modal-promise';
import { getNetworkSettings } from './redux/thunks/settings';
import { useFirstLogin } from '../src/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import Authentication from './components/containers/authentication';
import Maintenance from './components/containers/maintenance';
import { setPermissions } from '../src/redux/actions/profile';
import { setChildrenTypes } from '../src/redux/actions/users';
import { setSelectedLicense } from './redux/actions/settings';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSplit } from '../src/hooks';
import { constants } from '../src/utils/split.io';
import { isProduct, MAP_PRODUCT_URL_NAME_TO_ID } from './multi-product/Sidebar/utils';
import { queryClient } from '@services/react-query';
import {
    CAS_ROUTE,
    CHEQ_ACQUISITION_ROUTE,
    CHEQ_ANALYTICS_ROUTE,
    MIS_ROUTE,
    CHEQ_SLP_ROUTE,
    LEAD_PROTECTION,
    MAP_PRODUCT_ID_TO_PATH,
    OSS_ROUTE,
    CHEQ_DEFEND,
    CHEQ_PRIVACY
} from './utils/common';
import { iam, accounts, networksServices } from '../src/services/api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mixpanel from '@services/mixpanel';
import { createOrUpdateZendeskUser, getChatLoginToken } from './services/api/zendesk-v2.service';
import LogRocket from 'logrocket';
import { NETWORK_SETTINGS } from './utils/settings/constants';
import { Products } from './components/containers/management/constants';

toast.configure();

const { ActionsProvider, MeiosisProvider } = CheqUI.React.Meiosis;
const { Main } = layouts;
const { Progress } = widgets;

export default ({ actions, states }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({});
    const [isZendeskRedirect, setIsZendeskRedirect] = useState(false);
    const [hasEmptyPermissions, setHasEmptyPermissions] = useState(false);
    const [metaLoaded, setMetaLoaded] = useState(false);
    const profileState = useSelector(state => state.profile);
    const { networkFeatures, getNetworkFeatureCalled } = useSelector(state => state.settings);
    const { filterOptions } = useSelector(state => state.dashboard);
    const { filterOptions: rtiFilterOptions } = useSelector(state => state.rtiDashboard);
    const summaryReportSettings = useSelector(state => state.analytics.summaryReportSettings);
    const [tokenLocalStorage, setTokenLocalStorage] = useState('');
    const { isOn: showMaintenancePage } = useSplit(constants.SPLIT_MAINTENANCE_PAGE);
    const [searchParams] = useSearchParams();
    const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const userMeta = user && user[`${window.location.origin}/meta`];
    // combine lines 66-67 into a custom hook useOrgId
    const singleOrgId = user && user[`${window.location.origin}/single_org_id`];
    const userOrgId = singleOrgId || user?.org_id;
    const firstLogin = ((user && userMeta) || {}).firstLogin;
    const isFirstLogin = isAuthenticated && firstLogin;
    const isPrivayProduct = JSON.parse(localStorage.getItem('products'))?.find(product => product.name === 'Privacy') != undefined;
    const createMetaData = async () => {
        const userMeta = user[`${window.location.origin}/meta`] || {};
        const accountData = await accounts.getAccountDetails(userMeta.networkId);
        const rolesKey = Object.keys(user).find(key => key.includes('/roles'));
        const data = {
            ...userMeta,
            orgId: userOrgId,
            userRole: user[rolesKey][0],
            name: user?.name || userMeta?.name,
            seName: accountData?.SE_name,
            csmName: accountData?.CSM_name,
            account_type: accountData?.account_type,
            operation_region: accountData?.operation_region,
        };
        return data;
    };

    useFirstLogin(isFirstLogin, userMeta?.id, userMeta?.networkId, userMeta?.email, userMeta?.username);

    useEffect(() => {
        mixpanel.init();
    }, []);

    useEffect(() => {
        states.map(s => {
            setDialog(s.dialog);
        });
    }, [states]);

    useEffect(() => {
        if (isAuthenticated && getNetworkFeatureCalled && userOrgId && userMeta?.permissions.length) {
            const path = window.location.pathname;
            const productName = path?.split('/')[1];
            const allowedProductdIds = networkFeatures.map(nf => nf.id);

            // protects from reaching unlicnesed products
            if (
                isProduct(productName) &&
                !allowedProductdIds.includes(MAP_PRODUCT_URL_NAME_TO_ID[productName]) &&
                !isZendeskRedirect &&
                !window.sessionStorage.getItem('zendesk-external-link-redirect')
            ) {
                return navigate('home');
            }

            if ((path === '/' || path === '/dashboard') && !isZendeskRedirect && !window.sessionStorage.getItem('zendesk-external-link-redirect')) {
                //if user has only 1 license, navigate to it
                if (allowedProductdIds.length === 1 && !isZendeskRedirect) {
                    navigate(MAP_PRODUCT_ID_TO_PATH[allowedProductdIds[0]]);
                }
                //upon login, navigate to default product if you have any (TODO: check if default product is licnesed)
                else if (userMeta?.defaultProduct != null && !isZendeskRedirect && !window.sessionStorage.getItem('zendesk-external-link-redirect')) {
                    navigate(MAP_PRODUCT_ID_TO_PATH[userMeta?.defaultProduct]);
                } else {
                    navigate('home');
                }
            }
        }
    }, [userMeta?.permissions, userMeta?.defaultProduct, getNetworkFeatureCalled, networkFeatures, isAuthenticated, userOrgId]);

    useEffect(() => {
        if (Object.keys(profileState.permissions).length) {
            Object.values(actions).forEach(({ init }) =>
                init(
                    profileState.permissions,
                    true,
                    true,
                    filterOptions.policyTogglePM,
                    filterOptions.policyToggleDA,
                    rtiFilterOptions.policyToggleOSC,
                    summaryReportSettings.policyToggleA
                )
            );
        }
    }, [actions, filterOptions.policyToggleDA, filterOptions.policyTogglePM, profileState.permissions, rtiFilterOptions.policyToggleOSC, summaryReportSettings.policyToggleA]);

    useEffect(() => {
        if (isAuthenticated && getNetworkFeatureCalled && userOrgId && isPrivayProduct) {
            const userRoles = user && user[`${window.location.origin}/roles`];
            const isCheqUser = userRoles[0] == 'internal_brand_admin' && userMeta?.email.includes('cheq.ai');
            if (isCheqUser) {
                iam.AddUserToEnsighten(userMeta?.username, userMeta?.email, userMeta?.userId);
            }
        }
    }, [isPrivayProduct, isAuthenticated, getNetworkFeatureCalled, userOrgId]);

    useEffect(() => {
        const getAuth0Token = async () => {
            await getAccessTokenSilently()
                .then(async token => {
                    window.localStorage.setItem('auth0Token', token);
                    setTokenLocalStorage(window.localStorage.getItem('auth0Token'));
                    const data = await createMetaData();
                    localStorage.setItem('meta', JSON.stringify(data));
                    mixpanel.login();
                    console.log('### config.hostEnv: ', config.hostEnv);
                    console.log('### data.email: ', data.email);
                    if (config.hostEnv === 'production' && data.email !== 'tech-ops@ensighten.com' && data.email !== 'automationadmin@gmail.com') {
                        console.log('### LogRocket - Record Session ###');
                        LogRocket.init(config.logRocketEnv);
                        LogRocket.identify(data.id, {
                            name: data.name,
                            email: data.email,
                            networkId: data.networkId,
                        });
                    }
                })
                .catch(console.error);
        };

        if (!isLoading && user) {
            return getAuth0Token();
        }
    }, [isLoading, isAuthenticated, firstLogin]);

    useEffect(() => {
        (async () => {
            if (tokenLocalStorage && user && userOrgId) {
                const networkSettings = await networksServices.getSettings();
                window.localStorage.setItem(NETWORK_SETTINGS, JSON.stringify(networkSettings));
                const data = await createMetaData();
                dispatch(getContent());
                dispatch(setMeta({ ...data, industryId: networkSettings?.industryId }));
                setMetaLoaded(true);
                dispatch(setChildrenTypes(user[`${window.location.origin}/childRoles`]));
                const { name, sso } = userMeta?.connection || {};
                if (!userMeta.permissions.length) {
                    return setHasEmptyPermissions(true);
                }
                dispatch(getUsersInOrganization(userOrgId, name, sso));
                dispatch(getNetworkFeatures(userMeta?.networkId));
                dispatch(setPermissions(userMeta.permissions));
                window.Appcues.identify(userMeta.id.toString(), {
                    ...userMeta,
                    createdAt: new Date(userMeta.dateCreated).getTime(),
                });

                dispatch(getNetworkSettings(networkSettings));
            }
        })();
    }, [tokenLocalStorage]);

    let payload = {};
    const getZendeskUser = async () => {
        payload = {
            email: userMeta.email,
            accountId: userMeta.accountId,
            name: userMeta.email.split('@')[0],
            networkId: userMeta.networkId,
        };
        return await createOrUpdateZendeskUser(payload);
    };

    useEffect(() => {
        (() => {
            setImmediate(async () => {
                if (tokenLocalStorage) {
                    const zendeskUser = await getZendeskUser();

                    window.localStorage.setItem('zendesk', JSON.stringify(zendeskUser));
                    if (window.sessionStorage.getItem('zendesk-external-link-redirect') && isAuthenticated && zendeskUser) {
                        const baseUrl = zendeskUser.redirectUrl.split('return_to=')[0];
                        const finalRedirectUrl = `${baseUrl}return_to=${window.sessionStorage.getItem('zendesk-external-link-redirect')}`;
                        window.sessionStorage.removeItem('zendesk-external-link-redirect');
                        window.localStorage.removeItem('zendesk');
                        window.localStorage.setItem('isReturnToZendesk', {});
                        setIsZendeskRedirect(true);
                        window.location.href = finalRedirectUrl;
                    }
                    const chatLoginToken = await getChatLoginToken(payload);
                    window.localStorage.setItem('cq_zdt', chatLoginToken.token);
                }
            });
        })();
    }, [tokenLocalStorage, isAuthenticated]);

    useEffect(() => {
        console.log("--revert not done 2--")
        const pathname = document.location.pathname;
        if (pathname === '/logout') return;
        const routeToLicenseMap = {
            [CAS_ROUTE]: Products.CAS,
            [CHEQ_ACQUISITION_ROUTE]: Products.CAS,
            [OSS_ROUTE]: Products.OSS,
            [CHEQ_DEFEND]: Products.OSS,
            [MIS_ROUTE]: Products.MIS,
            [CHEQ_ANALYTICS_ROUTE]: Products.MIS,
            [LEAD_PROTECTION]: Products.SLP,
            [CHEQ_SLP_ROUTE]: Products.SLP,
            [CHEQ_PRIVACY]: Products.PRIVACY
        };
        const firstSegment = pathname.split('/')[1];
        const selectedLicense = routeToLicenseMap[firstSegment];
        if (selectedLicense) {
            dispatch(setSelectedLicense(selectedLicense));
        } else if (networkFeatures.length) {
            const licenses = JSON.parse(localStorage.getItem('products'));
            dispatch(setSelectedLicense(licenses?.[0]?.id));
        }
    },[dispatch,networkFeatures.length]);

    return (
        <HttpsRedirect disabled={!!config.disableSSL}>
            <QueryClientProvider client={queryClient}>
                {showMaintenancePage ? (
                    <Maintenance />
                ) : !isLoading ? (
                    <MeiosisProvider states={states}>
                        <ActionsProvider actions={actions}>
                            <ThemeProvider theme={Theme}>
                                <actions.dialog.Container Component={dialog.Component} options={dialog.options} />
                                {hasEmptyPermissions ? (
                                    <Brands hasEmptyPermissions />
                                ) : !isAuthenticated || !userOrgId || (!!searchParams.get('brand_id') && !!searchParams.get('return_to')) ? (
                                    <Authentication />
                                ) : (
                                    metaLoaded &&
                                    getNetworkFeatureCalled && (
                                        <>
                                            <StartupProvider isAuthenticated={!isLoading && user && userOrgId && tokenLocalStorage}>
                                                <ContentManagerProvider>
                                                    <CssBaseline />
                                                    <>
                                                        <Sidebar />
                                                        <Main />
                                                    </>
                                                    <ModalContainer />
                                                </ContentManagerProvider>
                                            </StartupProvider>
                                        </>
                                    )
                                )}
                                <ToastContainer />
                            </ThemeProvider>
                        </ActionsProvider>
                    </MeiosisProvider>
                ) : (
                    <AppLoader />
                )}
            </QueryClientProvider>
        </HttpsRedirect>
    );
};

const AppLoader = () => {
    return (
        <Progress
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
            }}
        />
    );
};
