import React from 'react';

const DesktopIcon =  ()=>
	<>
		<svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" >
			<path className="st0" stroke="currentColor" style={{fill:'none',strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} d="M4,3h16c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V5C2,3.9,2.9,3,4,3z"/>
			<line className="st0" stroke="currentColor" style={{fill:'none',strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} x1="8" y1="21" x2="16" y2="21"/>
			<line className="st0" stroke="currentColor" style={{fill:'none',strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} x1="12" y1="17" x2="12" y2="21"/>
		</svg>
	</>

export default DesktopIcon





