import React, {useEffect, useMemo, useState} from 'react';
import {Menu} from "../../invalid-visits-insights/menu";
import {DashboardItem} from "../../../../../../widgets";
import styles from './trends.module.scss';
import LineGraph from "./components/LineGraph/LineGraph";
import moment from "moment";
import {useSelector} from "react-redux";
import {useContentManager} from "../../../../../../hooks";

const colors = ['#65C4CE', '#808DDA']
const color = {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [
        [0, colors[0]],
        [1, colors[1]],
    ],
};

function GetSortOrder(prop) {
    return function(a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

const Trends = ({ dashboardCM, isLoading, isError, className, data, dateOption }) => {
    const [trafficType, setTrafficType] = useState('total');
    const cm = useContentManager();

    useEffect(() => {
        setTrafficType('total')
    }, [data]);

    const {
        selectedPlatformsGroup = {},
        selectedDisplayOption
    } = useSelector(state => state.dashboard);
    const showProtectedSince = !selectedPlatformsGroup.group.toLowerCase().includes('all') && selectedDisplayOption === 'PROTECTED_PLATFORMS' && selectedPlatformsGroup.protected;
    const isAllPlatforms = selectedPlatformsGroup.group.toLowerCase().includes('all');
    const parseValues = (values) => {
        return values.sort(GetSortOrder("label")).map(v => {
            return { name: getLabelValue(v.label), label: v.label, color, y: v.visits, invalidVisitRate: v.invalidVisitRate, visits: v.visits, validVisits: v.validVisits, invalidVisits: v.invalidVisits};
        })
    }

    const getLabelValue = (value) => {
        switch (dateOption) {
            case "today":
            case "yesterday":
                return `${value}:00`
            case "currentWeek":
            case "last7Days":
            case "last30Days":
            case "currentMonth":
            case "lastMonth":
                return moment(value).format("MMM Do");
            case "last3Months":
            case "last6Months":
                return `Week ${value}`;
            default: {
                let startDate = moment(dateOption.startDate).startOf('day');
                const endDate = moment(dateOption.endDate).endOf('day').add(1, 'second');
                let difference = endDate.toDate().getTime() - startDate.toDate().getTime() ;
                let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
                if (totalDays <= 2) {
                    return `${value}:00`
                } else if (totalDays <= 30) {
                    return moment(value).format("MMM Do");
                } else {
                    return `Week ${value}`;
                }
            }
        }
    }

    const getAllPlatformsData = (data) => {
        let xAxisArray = [];
        Object.keys(data).forEach(key => {
            data[key].forEach(item => {
                const index = xAxisArray.findIndex(label => label === item.label);
                if (index === -1) {
                    xAxisArray.push(item.label);
                }
            })
        })
        xAxisArray.sort((a,b) => {
            return a > b ? 1 : -1;
        })
        xAxisArray = xAxisArray.map(item => getLabelValue(item))
        return  {
            xAxis: xAxisArray,
            lineGraphValues: data
        }
    }

    const getXAxis = (data) => {
        let xAxisArray = [];
        data.forEach(item => {
            const index = xAxisArray.findIndex(label => label === item.label);
            if (index === -1) {
                xAxisArray.push(item.label);
            }
        })
        xAxisArray = xAxisArray.map(item => getLabelValue(item))
        return xAxisArray;
    }

    const values = useMemo(() => {
        switch (trafficType) {
            case "total": {
                if (isAllPlatforms) {
                   return getAllPlatformsData(data.total);
                }
                getXAxis(data.total)
                return {
                    barGraphValues: parseValues(data.total),
                    xAxis: getXAxis(data.total),
                    lineGraphValues: data.total.map(item => { return {name: item.label, invalidVisitRate: item.invalidVisitRate}})
                }
            }
            case "direct": {
                if (isAllPlatforms) {
                    return getAllPlatformsData(data.direct);
                }
                return {
                    barGraphValues: parseValues(data.direct),
                    xAxis: getXAxis(data.direct),
                    lineGraphValues: data.direct.map(item => { return {name: item.label, invalidVisitRate: item.invalidVisitRate}})
                }
            }
            case "organic": {
                if (isAllPlatforms) {
                    return getAllPlatformsData(data.organic);
                }
                return {
                    barGraphValues: parseValues(data.organic),
                    xAxis: getXAxis(data.organic),
                    lineGraphValues: data.organic.map(item => { return {name: item.label, invalidVisitRate: item.invalidVisitRate}})
                }
            }
            case "paid": {
                if (isAllPlatforms) {
                    return getAllPlatformsData(data.paid);
                }
                return {
                    barGraphValues: parseValues(data.paid),
                    xAxis: getXAxis(data.paid),
                    lineGraphValues: data.paid.map(item => { return {name: item.label, invalidVisitRate: item.invalidVisitRate}})
                }
            }
        }
    }, [data, trafficType])

    return (
        <DashboardItem
            isLoading={isLoading}
            isError={isError}
            data-test-selector="trends types"
            className={className}
            removeBorder
            title={
                <div className={styles.title}>
                    <div>{dashboardCM.get(isAllPlatforms ? 'InvalidVisitRateAllPlatforms.Title' : 'InvalidVisitRate.Title')}</div>
                    {selectedPlatformsGroup.group !== 'DIRECT' && <Menu dashboardCM={dashboardCM} current={trafficType} onChange={setTrafficType} showDirect={selectedPlatformsGroup.group.includes('ALL') && !selectedPlatformsGroup?.group.includes('ALL PROTECTED PLATFORMS')}/> }
                </div>
            }
        >
            <div>
                {!Object.keys(values.lineGraphValues).length ? <div style={{height: '380px', display: "flex", alignItems: "center", justifyContent: "center"}}>{cm.get('NoDataToDisplay')}</div> : (
                    <>
                        <LineGraph cm={dashboardCM}
                                   values={values.lineGraphValues}
                                   xAxis={values.xAxis}
                                   barGraphValues={values.barGraphValues}
                                   showProtectedSince={showProtectedSince}
                                   selectedPlatform={selectedPlatformsGroup}
                                   dateOption={dateOption}
                                   isAllPlatforms={isAllPlatforms}
                        />
                    </>
                )}
            </div>
        </DashboardItem>
    )
}

export default Trends;