import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import * as api from '@services/api/rti-dashboard';
import RightArrow from '@components/svgs/right-arrow';
import { showDialog } from '@widgets/dialog';
import { BarGraph, DonutGraph, LegendColor, InfoList, ToolTip, DashboardSection, DashboardItem } from '@widgets';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import { getRTIThreatsByThreatGroups, threatGroups as threatGroupsConstants, formatToK, getPercentage } from '@utils/dashboard';
import { useContentManager } from '@hooks';
import { Divider } from '@material-ui/core';
import { openAnalyticsWithPresetReportParams } from '@redux/thunks/analytics';
import styles from './InvalidVisitsInsights.module.scss';
import { menuOption, VISIT_TYPES } from './constants';
import { updateSummeryReportSettings } from '../../../../../redux/actions/analytics';
import { getThreatGroupsInfo, getThreatTypesInfo, colors } from './helper';

export const InvalidVisitsInsights = ({ options, isSettingsLoading }) => {
    const cm = useContentManager(cm => cm.dashboard);
    const newCm = useContentManager();
    const { isLoading, isError, data = [] } = useQuery([api.RTIAPIkeys.VISITS_INSIGHTS, options], () => api.getVisitsInsights(options));
    const { selectedLicense } = useSelector(state => state.settings);
    const threatGroups = [
        ...useSelector(state => state.dashboard.settings.threatGroups),
        {
            threatGroup: threatGroupsConstants.CUSTOM_RULES,
            threatTypes: [],
        },
    ];

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [trafficType, setTrafficType] = useState(VISIT_TYPES.total);

    const hasUnclassifiedData = data?.some(({ platformOrigin, total }) => platformOrigin === VISIT_TYPES.unclassified.toUpperCase() && !!total);

    const threatTypesData = useMemo(() => getRTIThreatsByThreatGroups(data, threatGroups, hasUnclassifiedData), [data, hasUnclassifiedData]);

    const total = threatTypesData && threatTypesData[trafficType] && threatTypesData[trafficType].total ? threatTypesData[trafficType].total : 0;

    const selectedThreatGroups = useMemo(() => {
        if (!threatTypesData) {
            return [
                { threatTypes: { total: 0 }, threatGroup: cm.get('InvalidBotActivity.Title'), color: colors[0], threatGroupKey: threatGroupsConstants.BAD_BOT_ACTIVITY },
                { threatTypes: { total: 0 }, threatGroup: cm.get('InvalidMaliciousActivity.Title'), color: colors[1], threatGroupKey: threatGroupsConstants.MALICIOUS_ACTIVITY },
                { threatTypes: { total: 0 }, threatGroup: cm.get('InvalidSuspiciousActivity.Title'), color: colors[2], threatGroupKey: threatGroupsConstants.SUSPICIOUS_ACTIVITY },
                { threatTypes: { total: 0 }, threatGroup: cm.get('CustomRulesActivity.Title'), color: colors[3], threatGroupKey: threatGroupsConstants.CUSTOM_RULES },
            ];
        }
        return [
            {
                threatTypes: { ...threatTypesData[trafficType][threatGroupsConstants.BAD_BOT_ACTIVITY] },
                threatGroup: cm.get('InvalidBotActivity.Title'),
                color: colors[0],
                threatGroupKey: threatGroupsConstants.BAD_BOT_ACTIVITY,
            },
            {
                threatTypes: { ...threatTypesData[trafficType][threatGroupsConstants.MALICIOUS_ACTIVITY] },
                threatGroup: cm.get('InvalidMaliciousActivity.Title'),
                color: colors[1],
                threatGroupKey: threatGroupsConstants.MALICIOUS_ACTIVITY,
            },
            {
                threatTypes: { ...threatTypesData[trafficType][threatGroupsConstants.SUSPICIOUS_ACTIVITY] },
                threatGroup: cm.get('InvalidSuspiciousActivity.Title'),
                color: colors[2],
                threatGroupKey: threatGroupsConstants.SUSPICIOUS_ACTIVITY,
            },
            {
                threatTypes: { ...threatTypesData[trafficType][threatGroupsConstants.CUSTOM_RULES] },
                threatGroup: cm.get('CustomRulesActivity.Title'),
                color: colors[3],
                threatGroupKey: threatGroupsConstants.CUSTOM_RULES,
            },
        ];
    }, [threatTypesData, trafficType]);

    const selectedThreatTypes = useMemo(() => {
        return selectedThreatGroups.reduce((result, current) => {
            Object.entries(current.threatTypes).forEach(([key, value]) => {
                if (key === VISIT_TYPES.total) {
                    return;
                } else {
                    result.push({ threatType: key, value: value.invalidClicks, color: current.color, protected: value.protected, threatGroupKey: current.threatGroupKey });
                }
            });
            return result;
        }, []);
    }, [selectedThreatGroups]);

    const donutGraphValues = useMemo(
        () =>
            selectedThreatGroups.map(item => ({
                label: item.threatGroup,
                value: item.threatTypes.total,
                color: item.color[0],
                id: item.threatGroup,
            })),
        [selectedThreatGroups]
    );

    const barGraphValues = useMemo(
        () =>
            selectedThreatTypes.map(item => {
                return {
                    label: item.threatType,
                    value: item.value,
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        stops: [
                            [0, item.color[0]],
                            [1, item.color[1]],
                        ],
                    },
                    id: item.threatType,
                    protected: !!item.protected,
                    threatGroupKey: item.threatGroupKey,
                };
            }),
        [selectedThreatTypes]
    );

    const showThreatGroupsInfoDialog = async ({ title, children }) => {
        await showDialog({ title, children });
    };

    const showTopThreatTypeInfoDialog = async ({ title, children }) => {
        await showDialog({ title, children });
    };

    const getThreatGroupsDialogHandler = () =>
        showThreatGroupsInfoDialog({
            title: cm.get('InvalidVisitsInsights'),
            children: <InfoList data={getThreatGroupsInfo(cm)} />,
        });

    const handleViewAllClick = () => {
        dispatch(updateSummeryReportSettings({ policyToggleA: options.policyToggleDA, includePolicy: options.includePolicy, policyManagement: options.policyManagement }));
        dispatch(
            openAnalyticsWithPresetReportParams(
                [
                    { value: 'threatType', label: 'Threat Type' },
                    { value: 'platformOrigin', label: 'Platform Origin' },
                ],
                navigate,
                selectedLicense,
                false
            )
        );
    };
    const getTopThreatTypeDialogHandler = () => showTopThreatTypeInfoDialog({ title: cm.get('TopThreats.Title'), children: <InfoList data={getThreatTypesInfo(cm)} /> });

    const withUnprotected = false;

    return (
        <DashboardSection data-test-selector="rti-invalid-insights-selector" title={<div className={styles.header}>{cm.get('InvalidVisitsInsights').toUpperCase()}</div>}>
            <DashboardItem
                isLoading={isLoading || isSettingsLoading}
                isError={isError}
                title={
                    <div className={styles.title}>
                        {newCm.get('TopInvalidVisitTypes')}
                        <div className={styles.divider}>
                            <Divider orientation="vertical" />
                        </div>
                        <div className={styles.selector}>
                            {menuOption.map(option => {
                                if (option.value === VISIT_TYPES.unclassified && !hasUnclassifiedData) return null;
                                return (
                                    <div
                                        onClick={() => setTrafficType(option.value)}
                                        className={cx(styles.option, { [styles.selected]: option.value === trafficType })}
                                        key={option}
                                    >
                                        {option.label}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
                action={{
                    label: cm.get('ViewAll'),
                    callBack: handleViewAllClick,
                }}
                info={{
                    title: cm.get('TopThreats.Title'),
                    content: cm.get('TopThreats.Description'),
                    action: { label: cm.get('LearnMore'), handler: getTopThreatTypeDialogHandler },
                }}
            >
                <div className={styles.container}>
                    <div className={styles.barGraph}>
                        <BarGraph
                            cm={cm}
                            values={barGraphValues}
                            legend={donutGraphValues}
                            limit={8}
                            withHighlightedColumns={false}
                            highlightedColumnsLabel={cm.get('TopThreats.NotProtectedThreats')}
                        />
                    </div>
                    <div className={styles.arrow}>
                        <RightArrow />
                    </div>
                    <DonutGraph total={total} values={donutGraphValues} />
                    <div className={styles.threatGroupTable} data-test-selector="threat groups table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className={styles.threatGroupsTitleContainer}>
                                            <div className={styles.threatGroupsTitle}>{cm.get('ThreatGroups.Title')}</div>
                                            <ToolTip
                                                title={cm.get('ThreatGroups.Title')}
                                                content={cm.get('ThreatGroups.Description')}
                                                action={{
                                                    label: cm.get('LearnMore'),
                                                    handler: getThreatGroupsDialogHandler,
                                                }}
                                            >
                                                <InfoIcon cursor="pointer" data-test-selector="info-icon-rti-threttypes" />
                                            </ToolTip>
                                        </div>
                                    </th>
                                    <th>{cm.get('TopThreats.Title')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedThreatGroups.map((item, index) => {
                                    return (
                                        <tr key={item}>
                                            <td valign="top">
                                                <div className={styles.flexRow}>
                                                    <LegendColor color={item.color[0]} />
                                                    <div className={styles.flexColumn}>
                                                        <div className={styles.threatGroupTitle}>{item.threatGroup}</div>
                                                        <div className={styles.flexRow}>
                                                            {formatToK(item.threatTypes.total)} ({Math.round(getPercentage(item.threatTypes.total, total))}%)
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td valign="top">
                                                <ul>
                                                    {Object.entries(item.threatTypes)
                                                        .filter(([k]) => k !== VISIT_TYPES.total)
                                                        .sort(([, vA], [, vB]) => {
                                                            if (!vB.invalidClicks || !vA.invalidClicks) return -1;
                                                            return vB.invalidClicks - vA.invalidClicks;
                                                        })
                                                        .slice(0, 3)
                                                        .map(([k, v], idx) => {
                                                            return (
                                                                <li key={idx} className={cx(!v.protected && withUnprotected ? styles.unprotected : '')}>
                                                                    <div className={styles.topThreatTitle}>
                                                                        {item.threatGroupKey !== threatGroupsConstants.CUSTOM_RULES ? cm.get(`${k.replace(/ /g, '')}.Title`) : k}
                                                                    </div>
                                                                    <div>{v.invalidClicks.toLocaleString()}</div>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </DashboardItem>
        </DashboardSection>
    );
};
