import MuiLinearProgress  from "@material-ui/core/LinearProgress/LinearProgress";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'transparent'
    },
	bar : {
        backgroundColor: '#fe0072'
    }
}));

const LinearProgress = ({}) => {
    const classes = useStyles()
    return (
        <MuiLinearProgress
            classes={classes}
            variant='query'
        />
    )
}

export default LinearProgress;