import React, {useState} from "react";
import moment from "moment";
import style from "./style";
import Calender from "../../../ui/calender";
import styles from "@components/containers/exportables/create-report/create-report.module.scss";

export default ({ updateState, cm}) => {
    const classes = style();
    const lastMonth = moment().subtract(1, 'month').toDate();
    const [month, setMonth] = useState(lastMonth);

    const handleMonthChange = month => {
        setMonth(month);
        updateState(prevState => ({
            ...prevState,
            startDate: moment(month).startOf('M').format(),
            endDate: moment(month).endOf('M').format(),
            month: moment(month).format('MMMM')
        }));
    };

    const tileContent = ({ date, view }) => <p style={{ margin: 'auto' }}>{cm.get(`Month${date.getMonth()}`)}</p>;

    return (
        <div className={styles.inputContainer}>
            <div className={styles.label}>
                {cm.get('Period')}
            </div>
            <div>
                <Calender
                    data-test-selector="exportables-paid_marketing_date_picker"
                    date={month}
                    maxDate={lastMonth}
                    view={'year'}
                    className={classes.calender}
                    onClickMonth={handleMonthChange}
                    tileContent={tileContent}
                />
            </div>

        </div>
    )
}