import React from 'react';

const ScrollIcon = () =>
	<>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
			<polyline stroke="currentColor" style={{fill: 'none', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round'}} points="15,3 21,3 21,9 "/>
			<polyline stroke="currentColor" style={{fill: 'none', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round'}} points="9,21 3,21 3,15 "/>
			<line     stroke="currentColor" style={{fill: 'none', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round'}}  x1="21" y1="3" x2="14.38" y2="9.62"/>
			<line     stroke="currentColor" style={{fill: 'none', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round'}}  x1="3" y1="21" x2="9.62" y2="14.38"/>
			<circle   stroke="currentColor" style={{fill: 'none', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round'}}  cx="12.43" cy="12.43" r="3.43"/>
		</svg>
	</>;

export default ScrollIcon;


