import { useEffect, useRef, useMemo, useState } from 'react';
import flatpickr from 'flatpickr';
import moment from 'moment';
import l10n from 'flatpickr/dist/l10n/';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import styles from './styles.module.scss';
import cx from 'classnames';
import 'flatpickr/dist/themes/dark.css';
import './date-picker.css';
import { selectLanguage } from '../../redux/thunks/profile';

const DatePicker = ({ open, options, value, className, fullWidth = false, includeLast12Months = false }) => {
    const inputElement = useRef();
    const datePicker = useRef();
    const [size, setSize] = useState();

    const language = useSelector(selectLanguage) || 'en';

    const activeOptions = useMemo(() => {
        const defaultOptions = {
            mode: 'range',
            enableTime: false,
            maxDate: moment().endOf('day').toDate(),
            locale: { ...l10n[language], rangeSeparator: ' – ' },
            minDate: moment()
                .subtract(includeLast12Months ? 12 : 6, 'months')
                .startOf('month')
                .startOf('day')
                .toDate(),
            dateFormat: 'M d, Y',
        };
        return {
            ...defaultOptions,
            ...options,
        };
    }, [language, options]);

    useEffect(() => {
        return () => {
            if (datePicker.current) datePicker.current.destroy();
        };
    }, []);

    useEffect(() => {
        if (!datePicker.current) {
            datePicker.current = flatpickr(inputElement.current, activeOptions);
        }
    }, [activeOptions]);

    useEffect(() => {
        if (!datePicker.current) return;
        if (open) datePicker.current.open();
    }, [open]);

    useEffect(() => {
        if (!datePicker.current) return;
        if (value) datePicker.current.setDate(value);
    }, [value]);

    useEffect(() => {
        if (inputElement.current?.value) {
            setSize(inputElement.current?.value?.length);
        }
    }, [inputElement.current?.value, value]);

    return (
        <div className={styles.wrapper}>
            <TextField
                InputProps={{ className: cx(styles.datePickerInput, className) }}
                classes={{ root: cx(styles.root, { [styles.fullWidth]: fullWidth }) }}
                variant="outlined"
                aria-readonly
                margin="dense"
                inputProps={{ size, className: styles.input }}
                inputRef={inputElement}
            />
        </div>
    );
};

export default DatePicker;
