import httpClient from '../ajax';
import { httpRequest, methods } from '@services/http';

class AnalyticsService {
    endpoint = 'analytics';

    post = httpClient({ baseRoute: this.endpoint });

    getSummaryRequest = '';

    getSummary = body => {
        const newPromise = httpRequest({ method: methods.post, pathname: 'analytics/summary_v4', body });
        if (this.getSummaryRequest) this.getSummaryRequest.abort();
        this.getSummaryRequest = newPromise;
        return newPromise.response;
    };

    getCampaigns = () => {
        return this.post('get_campaigns');
    };

    getSources = () => {
        return this.post('get_sources');
    };

    getAffiliates = () => {
        return this.post('get_affiliates')
    };

    getRtiEventTypes = () => {
        return this.post('get_rti_event_types')
    }

    getContents = () => {
        return this.post('get_contents')
    };

    getMedium = () => {
        return this.post('get_medium')
    };

    getAll = () => {
        const promises = [this.getCampaigns(), this.getSources(), this.getContents(), this.getMedium(), this.getAffiliates(), this.getRtiEventTypes()];
        return Promise.all(promises);
    };
}

export const analyticsService = new AnalyticsService();

export default analyticsService;
