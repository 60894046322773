import httpClient from '../ajax';

class SsoService {
    endpoint = 'sso';

    post = httpClient({ baseRoute: this.endpoint });

    generateFeatureKey = featureId => this.post('generate_feature_key', { featureId });

    getGoogleScopes = () => this.post('google/get_scopes');

    connectGoogle = body => this.post('google/connect', body);
}

export const ssoService = new SsoService();

export default ssoService;
