export function isAuthorized(permissions = {}, model, action) {
    return permissions[model] && permissions[model][action];
}

const USER_ROLES = {
    USER: 'user',
};

export const getMeta = () => {
    const meta = localStorage.getItem('meta') || '{}';
    return JSON.parse(meta);
};

export const getUserRole = () => {
    const { userRole } = getMeta();
    return userRole;
};

export const isViewer = () => getUserRole() === USER_ROLES.USER;
