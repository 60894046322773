import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import styles from './style.module.css';
import NoData from '../no-data/index';

export default function ({
    width = 500,
    height = 320,
    style = {},
    onClick,
    valueBy = 'value',
    items = [],
    limit = 10,
    fontColor = 'white',
    RenderEmpty = props => <NoData {...props}/>,
    colors = [
        '#f10a7e',
        '#d14ca1',
        '#b6489e',
        '#bd4db3',
        '#a54ab0',
        '#8644a2',
        '#8446b0',
        '#7e47b8',
        '#7648bf',
        '#5a3f9c'
    ] }) {
    const { palette } = useTheme();
    const [selected, select] = useState();

    useEffect(() => { items.length && onSelect(items[0])() }, [items.length]);

    const onSelect = item => () => onClick && (select(item.label) || onClick(item));

    return <div
        className={styles['container']}
        style={{ width, verticalAlign: 'bottom', backgroundColor: palette.background.component, ...style }}>
        {items.length ? items
            .slice(0, limit)
            .map((item, idx) => <XylophoneItem
                label={item.label}
                key={idx}
                style={{
                    height: height / Math.min(items.length, limit), color: fontColor,
                    cursor: onClick ? 'pointer' : 'initial'
                }}
                onClick={onSelect(item)}
                score={idx + 1} color={colors[idx]}
                selected={selected === item.label}
                value={item[valueBy]} />)
            : <RenderEmpty height={350} />
        }
    </div>
}

const XylophoneItem = ({ score, value, label, color, selected, style, ...props }) => <div
    className={styles['item-container']}
    style={{
        backgroundColor: selected ? '#00000000' : color, ...style
    }}
    {...props}>
    <div className={styles['score-container']} style={{ color }}>{`${score.toString().length === 1 ? 0 : ''}${score}`}</div>
    <div style={{ width: '90%' }}>
        <div className={styles['label']}>{label}</div>
        <div className={styles['value']}>{value}</div>
    </div>
</div>