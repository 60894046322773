import React, { useState, useEffect, useRef } from "react";
import styles from './affiliates.module.scss';
import { useDispatch, useSelector } from "react-redux";
import {useContentManager} from "@hooks";
import widgets from '../../../widgets';
import { TextField } from '@material-ui/core';
import ExclamationIcon from '../../../svgs/exclamation';
import { affiliatesService } from "@services/api/affiliates.service";
import { setIsLoading } from "@redux/actions/settings";
import cx from 'classnames';
import mixpanel from "@services/mixpanel";

import ChipsInput from "./PatternMultiValues";
import Modal from "../../exportables/ui/modal"
const { Progress, Button } = widgets;
const ENGLISH_REGEX = /^[A-Za-z0-9-_=%$#@]+$/;
export default () => {
    const dispatch = useDispatch();
    const cm = useContentManager();
    const { isLoading } = useSelector(state => state.settings);

    const [affiliateId, setAffiliateId] = useState('')
    const [affiliateError, setAffiliateError] = useState(false);
    const [affiliateUrlPattern, setAffiliateUrlPattern] = useState([])
    const [isFocusedParameter, setIsFocusedParameter] = useState(false);
    const [isFocusedPattern, setIsFocusedPattern] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const inputRef = useRef(null);
    const patternRef = useRef(null);

    const handleOnAddPattern = pattern => {
        pattern = pattern.trim();
        if (pattern.length > 0)
            setAffiliateUrlPattern([...affiliateUrlPattern, pattern]);
    };

    const handleDeletePattern = (_, idx) => {
        const patterns = affiliateUrlPattern.filter((_, i) => i !== idx);
        setAffiliateUrlPattern(patterns);
    };

    const onClickHandler = async () => {
        setShowPopup(false)
        if (!ENGLISH_REGEX.test(affiliateId)) {
            setAffiliateError(true)
            return
        }

        setAffiliateError(false)
        await affiliatesService.setAffiliateData({affiliateId, affiliatePattern: affiliateUrlPattern.join(',')});
        mixpanel.postEvent("Save affiliate ID", {"Affiliate ID parameter": affiliateId})
        await fetchData();
    }

    useEffect(async () => {
        await fetchData();
    }, [])

    const fetchData = async () => {
        dispatch(setIsLoading(true))
        const res = await affiliatesService.getAffiliateData();
        if (res?.data?.affiliateId) {
            setAffiliateId(res.data.affiliateId)
            setIsFocusedParameter(false)
        }
        if (res?.data?.affiliatePattern) {
            setAffiliateUrlPattern(res.data.affiliatePattern.split(','))
            setIsFocusedPattern(false)
        }
        dispatch(setIsLoading(false))
    }

    const onFocusParameter = () => {
        setIsFocusedParameter(true);
        setIsFocusedPattern(false)
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const onFocusPattern = () => {
        setIsFocusedPattern(true);
        setIsFocusedParameter(false);
        setTimeout(() => {
            patternRef.current?.focus();
        }, 0);
    };

    const onChangeHandler = e => {
        setAffiliateError(false)
        setAffiliateId(e.target.value)
    }

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{cm.get('AffiliatesSettings')}</div>
            </div>
            {isLoading ?
                <Progress className={styles.progress} />
                : (
                    <>
                        <div className={styles.inputContainer}>
                            <div className={styles.label}>{cm.get('AffiliateIdPattern')}</div>
                            <div className={styles.inputBox}>
                                <div className={styles.inputItem}>
                                    <ChipsInput
                                        patternRef={patternRef}
                                        isFocusedPattern={isFocusedPattern}
                                        data={affiliateUrlPattern}
                                        onAdd={handleOnAddPattern}
                                        onDelete={handleDeletePattern}
                                        onFocusPattern={onFocusPattern}
                                    />
                                </div>
                                <div className={styles.exampleLabel}>
                                    {cm.get('AffiliateIdKeyExample')}
                                    <span className={styles.blue}>{cm.get('AffiliateIdKeyExampleWebsite')}</span>
                                    {cm.get('AffiliatePatternExampleSuffix')}
                                </div>
                            </div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className={styles.label}>{cm.get('AffiliateIdKey')}</div>
                            <div className={styles.inputBox}>
                                <div className={styles.inputItem}>
                                    <TextField
                                        ref={inputRef}
                                        required
                                        fullWidth
                                        value={affiliateId}
                                        variant={isFocusedParameter ? 'outlined' : 'filled'}
                                        margin="dense"
                                        data-test-selector={'affiliates-add-parameter-input'}
                                        placeholder={'utm_content'}
                                        classes={{ root: styles.marginDense }}
                                        onChange={onChangeHandler}
                                        InputProps={{
                                            onFocus: onFocusParameter,
                                            disableUnderline: !isFocusedParameter,
                                            readOnly: !isFocusedParameter,
                                            classes: { inputMarginDense: cx(styles.inputMarginDense, { [styles.pointer]: !isFocusedParameter }) },
                                            margin: 'dense',
                                        }}
                                    />
                                </div>
                                <div className={styles.exampleLabel}>
                                    {cm.get('AffiliateIdKeyExample')}
                                    <span className={styles.blue}>{cm.get('AffiliateIdKeyExampleWebsite')}</span>
                                    {cm.get('AffiliateIdKeyExampleSuffix')}
                                </div>
                                {affiliateError && <div className={styles.error}>Space and special characters are not allowed</div>}
                            </div>
                        </div>
                        <div className={styles.info}>
                            <ExclamationIcon />
                            <div className={styles.text}>
                                <div>{cm.get('AffiliatesSettingsInfo')}</div>
                                <div>{cm.get('AffiliatesSettingsInfo2')}</div>
                            </div>
                        </div>
                        <Button
                            disabled={affiliateId === ''}
                            className={styles.button}
                            data-test-selector="affiliate button"
                            onClick={() => setShowPopup(!showPopup)}
                        >
                            {cm.get('Save')}
                        </Button>
                        {showPopup && <Modal onClose={() => setShowPopup(false)} cm={cm} onApproveHandler={onClickHandler} reportData={{action: "affiliate_management"}}/>}
                    </>
                )}
        </div>
    )
}