import axios from 'axios';
import config from '../../config';
import { getSubDomain } from '@utils/common';

const { baseAPI: baseURL } = config;

const getCsrfToken = () => {
    let csrfToken = null;
    try {
        const meta = JSON.parse(localStorage.meta);
        csrfToken = meta.csrfToken;
    } catch (e) {}
    if (!csrfToken) {
        csrfToken = localStorage.csrf;
    }

    return csrfToken;
};

const http = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-T-CSRF': getCsrfToken(),
        'X-CHEQ-LOCATION': getSubDomain(),
        Authorization: `Bearer ${localStorage.getItem('auth0Token')}`
    },
});

http.interceptors.response.use(undefined, err => {
    const error = err.response;
    if (error?.status === 401 || error?.status === 403) {
        http.defaults.headers.common['Authorization'] = undefined;
        //ToDo: redirect to login
    }
    throw error;
});
http.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('auth0Token');
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    error => {
        console.error(error);
    }
);

const ajax =
    ({ method = 'post', baseRoute = '', config = {} }) =>
    (route, params) => {
        return http[method](`${baseRoute}/${route}`, params, { withCredentials: true, ...config })
            .then(d => {
                return d;
            })
            .catch(e => {
                console.error(e);
            });
    };

export default ajax;
