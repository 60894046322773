import React from 'react';
import styles from './filters.module.scss';
import Select from './components/select';
import { useDispatch } from 'react-redux';
import { setReportFilters } from '@redux/actions/skewed';

export default ({ cm, list, selected, setSelected, typeFilter, createdByFilter, frequencyFilter, statusFilter }) => {
    const dispatch = useDispatch();
    const selectOneItem = (row, type) => {
        const selectedItem = selected[type];
        const selectedIds = selectedItem.map(item => item.id);

        if (selectedIds.filter(id => id === row.id).length !== 0) {
            const selectedItems = selectedItem.filter(item => item.id !== row.id);
            setSelected(prevState => ({
                ...prevState,
                [type]: selectedItems,
            }));
            dispatch(
                setReportFilters({
                    ...selected,
                    [type]: selectedItems,
                })
            );
        } else {
            setSelected(prevState => ({
                ...prevState,
                [type]: [...selectedItem, row],
            }));
            dispatch(
                setReportFilters({
                    ...selected,
                    [type]: [...selectedItem, row],
                })
            );
        }
    };

    const getOptions = type => {
        switch (type) {
            case 'type':
                return typeFilter;
            case 'users':
                return createdByFilter;
            case 'status':
                return statusFilter;
            case 'frequency':
                return frequencyFilter;
            default:
                break;
        }
    };

    const onClearButtonHandler = () => {
        setSelected(prevState => ({
            ...prevState,
            type: [],
            users: [],
            status: [],
            frequency: [],
            searchValue: '',
        }));
        dispatch(
            setReportFilters({
                type: typeFilter,
                users: [],
                status: statusFilter,
                frequency: frequencyFilter,
                searchValue: '',
            })
        );
    };

    return (
        <div className={styles.container} data-test-selector="exportables-filters-container">
            <div className={styles.flex}>
                <div className={styles.filters} data-test-selector="exportables-filters-label">
                    Filters:
                </div>
                <div className={styles.selectors} data-test-selector="exportables-filters-selectors">
                    {list.map((item, index) => {
                        const options = getOptions(item.type);
                        return (
                            <Select
                                cm={cm}
                                selector={`exportables-filters-selector-${index}`}
                                key={index}
                                type={item.type}
                                label={item.name}
                                options={options}
                                selected={selected[item.type]}
                                onSelect={selectOneItem}
                            />
                        );
                    })}
                </div>
            </div>
            <div onClick={onClearButtonHandler} className={styles.button}>
                {cm.get('ClearAll')}
            </div>
        </div>
    );
};
