import { FC, MouseEvent } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

/* @ts-ignore */
import CheqUI from '@combotag/cheq-ui';
/* @ts-ignore */
import mixpanel from '@services/mixpanel';
import { getDefaultColumns } from './constants';
import { useSelector } from 'react-redux';

const { useActions } = CheqUI.React.Meiosis;

const useStyles = makeStyles(theme => ({
    menuItem: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: 'inherit',
        },
    },
}));

interface IProps {
    handleClose: () => void;
    historyItem: any;
    anchorEl: any;
    open: boolean;
    cm: any;
}

const DownloadSelect: FC<IProps> = ({ open, handleClose, historyItem = {}, anchorEl, cm = {} }) => {
    const classes = useStyles();
    const { analytics: analyticsActions } = useActions();
    const { selectedLicense } = useSelector((state: { settings: any }) => state.settings);
    const handleDownloadFile = (event: MouseEvent<HTMLElement>) => {
        const { compressed } = event.currentTarget.dataset;

        analyticsActions.history({
            includePPCCrawlers: historyItem.includePPCCrawlers,
            reportType: historyItem.reportType,
            dateRange: historyItem.dateRange,
            breakdownBy: historyItem.breakdownBy,
            groupBy: historyItem.groupBy,
            advancedFiltering: historyItem.advancedFiltering,
            queryId: historyItem.queryId,
            columns: historyItem.columns.length > 0 ? historyItem.columns : getDefaultColumns(selectedLicense),
            isCompressed: compressed === 'true',
            includePolicy: historyItem.includePolicy,
            policyManagement: historyItem.policyManagement,
            policyToggleA: historyItem.policyToggleA,
        });

        const mixpanelData = {
            'Report view type': 'Run Report click',
            'Report Type': historyItem.reportType,
            'Report breakdown': historyItem.breakdownBy,
            'Report group by': historyItem.groupBy,
            'Report include PPC crawlers': historyItem.includePPCCrawlers,
            'Report advanced filtering countries': historyItem.advancedFiltering?.country,
            'Report advanced filtering devices': historyItem.advancedFiltering?.device,
            'Report advanced filtering browsers': historyItem.advancedFiltering?.browser,
            'Report advanced filtering sources': historyItem.advancedFiltering?.source,
            'Report advanced filtering campaigns': historyItem.advancedFiltering?.campaign,
            'Report advanced filtering channels': historyItem.advancedFiltering?.channel,
            'Report advanced filtering platform origin': historyItem.advancedFiltering?.platformOrigin,
            'Report advanced filtering affiliate IDs': historyItem.advancedFiltering?.affiliates,
        };
        if (typeof historyItem.dateRange === 'string') {
            mixpanelData['Report period filter'] = historyItem.dateRange;
        } else {
            mixpanelData['Report period filter'] = 'custom';
            mixpanelData['Report start date filter'] = historyItem.dateRange?.startDate;
            mixpanelData['Report end date filter'] = historyItem.dateRange?.endDate;
        }
        mixpanel.postEvent('Analytics report download', mixpanelData);

        handleClose();
    };

    return (
        <Popper open={open} anchorEl={anchorEl} transition disablePortal style={{ zIndex: 999 }}>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow">
                                <MenuItem className={classes.menuItem} onClick={handleDownloadFile} data-compressed={true} disabled={historyItem.columns.length === 0}>
                                    {cm.get('Analytics.ReportHistory.CompressedDownload')}
                                </MenuItem>
                                <MenuItem className={classes.menuItem} onClick={handleDownloadFile} data-compressed={false}>
                                    {cm.get('Analytics.ReportHistory.Download')}
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

export default DownloadSelect;
