import moment from 'moment';

const parseDate = ({ type, range }) => {
    const startAt = new Date(moment().subtract(range, type).startOf('D').format());
    const endAt = range === 1 ? new Date(moment().subtract(range, type).endOf('D').format()) : new Date(moment().endOf('D').format());
    return [startAt, endAt];
};

const parseTabsData = (tabsData, networkFeatures) => {
    return tabsData.filter(tab => tab.permissions.some(permission => networkFeatures.find(feature => feature.id === permission)));
};

export { parseDate, parseTabsData };
