import * as actions from '@redux/actions/dashboard';

import { CqSelect, DarkSeparator, DashboardSection, PlatformSelector, Toggler, ToolTip } from '@widgets';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContentManager } from '@hooks';
import DatePicker from '@components/widgets/date-picker';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import { translateDate, translateTopPlatform, policyToggleOffDatesInRange } from '@utils/dashboard';
import { isObject } from 'underscore';
import moment from 'moment';
import styles from './filter.module.scss';
import widgets from '@components/widgets';
import LastUpdated from './LastUpdated';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useQuery } from 'react-query';
import * as api from '@services/api/dashboard';
import { setFilterOptions, setPolicyToggleOptions } from '@redux/actions/dashboard';
import config from '../../../../../config';

const { Button } = widgets;
const Filter = ({ options, handleRefresh, currentPlatformsGroup, setCurrentPlatformsGroup, state: defaultState }) => {
    const {
        visiblePlatformsGroups,
        selectedChannel,
        settings: { channels, dateRanges: datesRangesFromRedux },
    } = useSelector(state => state.dashboard);

    const [dateRanges, setDateRanges] = useState(datesRangesFromRedux || []);

    const { networkId } = useSelector(state => state.profile.meta);
    useEffect(() => {
        if (networkId === parseInt(config.dellNetworkId)) {
            setDateRanges(oldDateRanges => {
                oldDateRanges.splice(6, 0, { label: 'Last 12 Months', value: 'last12Months' });
                return oldDateRanges;
            });
        }
    }, [networkId]);

    const queryOptions = { ...options, platform: '', platformGroups: visiblePlatformsGroups };
    const { isFetching: isLoading, data: visitsSummaryForVisiblePlatformsGroups = [] } = useQuery(
        [api.paidMarketingKeys.TOP_PLATFORMS_BY_TOTAL_VISITS, JSON.stringify(queryOptions)],
        () => api.getTopPlatformsByTotalVisits(queryOptions)
    );

    const dashboardCM = useContentManager(cm => cm.dashboard);

    const dispatch = useDispatch();

    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [filterOptions, updateFilterOptions] = useState(defaultState);
    const [datePickerValue, setDatePickerValue] = useState();

    const {
        settings: { language },
    } = useSelector(state => state.profile.meta);

    const onChangePlatformGroup = platformsGroupName => {
        const selectedGroup = visiblePlatformsGroups?.find(g => g?.group === platformsGroupName);
        setCurrentPlatformsGroup(platformsGroupName);
        handleRefresh('Filter Click', { ...filterOptions, platform: platformsGroupName });
        dispatch(actions.setSelectedPlatformsGroup(selectedGroup));
    };

    const handleChannelSelect = e => {
        const selectedChannelValue = e.target.value;
        const selectedChannel = channels.find(c => c.value === selectedChannelValue);
        handleRefresh('Filter Click', {
            ...filterOptions,
            channel: selectedChannel.value,
            channelName: selectedChannel.label,
        });
        dispatch(actions.setSelectedChannel(selectedChannel));
    };

    const handlePlatformsGroupSelect = e => {
        const selectedGroup = visiblePlatformsGroups?.find(f => f?.group === e.target.value);
        setCurrentPlatformsGroup(e.target.value);
        handleRefresh('Filter Click', { ...filterOptions, platform: selectedGroup.group });
        dispatch(actions.setSelectedPlatformsGroup(selectedGroup));
    };

    const handleRefreshClick = () => {
        handleRefresh('Dashboard Refresh button click', filterOptions);
    };

    const handleOnDateFilterChange = event => {
        if (event.target.value === 'Custom') {
            setDatePickerValue([]);
            setDatePickerOpen(true);
        }
        updateFilterOptions(prevState => ({
            ...prevState,
            dateRange: event.target.value,
        }));
    };

    const handleOnDatePickerChange = date => {
        if (date.length === 2) {
            const startDate = moment(date[0]).format('YYYY-MM-DD HH:mm');
            const endDate = moment(date[1]).format('YYYY-MM-DD HH:mm');
            updateFilterOptions(prevState => ({
                ...prevState,
                dateRange: { startDate, endDate },
            }));
        }
    };

    const handleOnDatePickerClose = date => {
        if (date.length <= 1)
            updateFilterOptions(prevState => ({
                ...prevState,
                dateRange: filterOptions.dateRange,
            }));
        setDatePickerOpen(false);
    };

    const selectChannelsList = useMemo(() => {
        let result = [];
        if (channels && channels.length > 1) {
            result = channels.map(f => {
                if (f.value === 'none') {
                    return { label: `${dashboardCM.get('AllChannels')} (${channels.length - 1})`, value: 'none' };
                }
                return f;
            });
        } else {
            result.push({ label: dashboardCM.get('NoChannels'), value: '' });
        }
        return result;
    }, [channels]);

    const topInvalidVisitsByPlatformGroup = useMemo(() => {
        return visitsSummaryForVisiblePlatformsGroups.slice(0, isMD ? 5 : 6);
    }, [visitsSummaryForVisiblePlatformsGroups, isMD]);

    const dateRangeParser = (dateRanges, cm) =>
        dateRanges.map(({ label = '', value }) => {
            let labelValue = cm.get(`${label.replace(/ /g, '')}`);
            if (labelValue.startsWith('DashboardV2')) labelValue = labelValue.split('.')[1];
            return {
                value,
                label: labelValue,
            };
        });

    const handleIncludeCrawler = (e, checked) => {
        updateFilterOptions(prevState => ({
            ...prevState,
            includePPCCrawlers: checked,
        }));
    };

    const handleIncludePolicy = (e, checked) => {
        updateFilterOptions(prevState => ({
            ...prevState,
            policyTogglePM: checked,
            policyToggleDA: false,
        }));
        dispatch(setPolicyToggleOptions({ policyTogglePM: checked }));
    };

    useEffect(() => {
        const dateValue = translateDate(filterOptions.dateRange);
        setDatePickerValue(dateValue);
    }, [filterOptions]);

    useEffect(() => {
        defaultState && updateFilterOptions(defaultState);
    }, [defaultState]);

    const isDirty = JSON.stringify(defaultState) !== JSON.stringify(filterOptions);
    return (
        <DashboardSection title={dashboardCM.get('TopPlatforms')} data-test-selector="paidmarketing-filter-section">
            <div className={styles.component}>
                <div className={styles.platformsRow} data-test-selector="top platforms selectors">
                    {topInvalidVisitsByPlatformGroup?.map(pg => {
                        return (
                            <PlatformSelector
                                dashboardCM={dashboardCM}
                                key={pg.name}
                                onClick={() => onChangePlatformGroup(pg.name)}
                                current={pg.name === currentPlatformsGroup}
                                title={translateTopPlatform(pg.name, dashboardCM)}
                                paid={pg.paidRate ? pg.paidRate.toFixed(2) : 0}
                                orgaic={pg.organicRate ? pg.organicRate.toFixed(2) : 0}
                                isDirect={pg.name === 'DIRECT'}
                                isLoading={isLoading}
                            />
                        );
                    })}
                </div>
                <div className={styles.filterRow} data-test-selector="filters">
                    {isLoading ? (
                        <div style={{ width: '100%' }}>
                            <Skeleton />
                            <Skeleton />
                        </div>
                    ) : (
                        <>
                            <div
                                className={styles.content}
                                style={!filterOptions.policyTogglePM && policyToggleOffDatesInRange(datePickerValue, dashboardCM) !== '' ? { gap: '0.4rem' } : {}}
                            >
                                <CqSelect
                                    data-test-selector="paidmarketing-platforms-select"
                                    isOutlined={!visitsSummaryForVisiblePlatformsGroups.find(tp => tp?.name === currentPlatformsGroup)}
                                    value={currentPlatformsGroup}
                                    options={visitsSummaryForVisiblePlatformsGroups?.map(pg => ({
                                        label: translateTopPlatform(pg.name, dashboardCM),
                                        value: pg?.name,
                                    }))}
                                    onChange={handlePlatformsGroupSelect}
                                    className={styles.select}
                                />
                                <CqSelect
                                    data-test-selector="paidmarketing-channels-select"
                                    isOutlined={selectedChannel.value !== 'none'}
                                    disabled={selectChannelsList && selectChannelsList.length < 2}
                                    value={selectedChannel.value}
                                    options={selectChannelsList}
                                    onChange={handleChannelSelect}
                                    className={styles.select}
                                />
                                <DarkSeparator
                                    customStyle={!filterOptions.policyTogglePM && policyToggleOffDatesInRange(datePickerValue, dashboardCM) !== '' ? { margin: '0 5px' } : {}}
                                />
                                <span style={!filterOptions.policyTogglePM && policyToggleOffDatesInRange(datePickerValue, dashboardCM) !== '' ? { fontSize: '1.1rem' } : {}}>
                                    {dashboardCM.get('ShowingDataFor')}
                                </span>
                                <CqSelect
                                    data-test-selector="paidmarketing-date-range-select"
                                    value={isObject(filterOptions.dateRange) ? 'Custom' : filterOptions.dateRange}
                                    options={dateRangeParser(dateRanges, dashboardCM) || []}
                                    onChange={handleOnDateFilterChange}
                                    className={styles.select}
                                />
                                <DatePicker
                                    open={datePickerOpen}
                                    value={datePickerValue || []}
                                    locale={language}
                                    options={{
                                        onChange: handleOnDatePickerChange,
                                        onClose: handleOnDatePickerClose,
                                    }}
                                    className={
                                        !filterOptions.policyTogglePM && policyToggleOffDatesInRange(datePickerValue, dashboardCM) !== ''
                                            ? `${styles.policyToggleOff}`
                                            : `${styles.inputContainer}`
                                    }
                                    includeLast12Months={networkId === parseInt(config.dellNetworkId)}
                                />
                                <div style={!filterOptions.policyTogglePM && policyToggleOffDatesInRange(datePickerValue, dashboardCM) !== '' ? { fontSize: '1.1rem' } : {}}>
                                    <LastUpdated update={defaultState} />
                                </div>

                                <DarkSeparator
                                    customStyle={!filterOptions.policyTogglePM && policyToggleOffDatesInRange(datePickerValue, dashboardCM) !== '' ? { margin: '0 5px' } : {}}
                                />

                                {filterOptions.includePolicy ? (
                                    <Toggler
                                        data-test-selector="paidmarketing-known-bots-toggle"
                                        onChange={handleIncludePolicy}
                                        checked={filterOptions.policyTogglePM}
                                        style={!filterOptions.policyTogglePM ? { fontSize: '1.1rem !important' } : {}}
                                        label={
                                            <>
                                                <span>{dashboardCM.get('IncludePolicy')}</span>
                                                <ToolTip title={dashboardCM.get('IncludePolicy.Title')} content={dashboardCM.get('IncludePolicy.Description')}>
                                                    <InfoIcon cursor="pointer" />
                                                </ToolTip>
                                            </>
                                        }
                                    />
                                ) : (
                                    <Toggler
                                        data-test-selector="paidmarketing-known-bots-toggle"
                                        onChange={handleIncludeCrawler}
                                        checked={filterOptions.includePPCCrawlers}
                                        label={
                                            <>
                                                <span>{dashboardCM.get('CrawlersToggler.Title')}</span>
                                                <ToolTip title={dashboardCM.get('CrawlersToggler.Title')} content={dashboardCM.get('CrawlersToggler.Description')}>
                                                    <InfoIcon cursor="pointer" />
                                                </ToolTip>
                                            </>
                                        }
                                    />
                                )}
                                {!filterOptions.policyTogglePM && <span className={styles.chosenDates}>{policyToggleOffDatesInRange(datePickerValue, dashboardCM)}</span>}
                            </div>
                            <div data-test-selector="rti-refresh-button" className={styles.button}>
                                <Button disabled={!isDirty} onClick={handleRefreshClick}>
                                    {dashboardCM.get('Refresh')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </DashboardSection>
    );
};

export const getLastUpdatedTimespanText = dashboardCM => {
    const now = new Date();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    return `${dashboardCM.get('Updated')} ${minutes ? minutes : seconds} ${minutes ? dashboardCM.get('MinutesAgo') : dashboardCM.get('SecondsAgo')}`;
};

export default Filter;
