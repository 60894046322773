import { IntegrationRaw } from '../../components/containers/management/google-analytics/types';
import { ValueLabel } from 'types/types';
import httpClient from '../ajax';

const BASE_URL = 'integrations';
const get = httpClient({ method: 'get', baseRoute: BASE_URL });
const put = httpClient({ method: 'put', baseRoute: BASE_URL });
const post = httpClient({ method: 'post', baseRoute: BASE_URL });

/** 
 * @param channel tag
 * @param measurementId google accounts (G-...)
 * will update combotag for the new measurements of the specific tag
 */

const addIntegration = async (channel: ValueLabel, measurementId: string) => {
    return await put(`add/${channel.value}`, { tracking_id: measurementId });
};

const deleteIntegration = async (channel: ValueLabel, measurementId: string) => {
    return await put(`delete/${channel.value}`, { tracking_id: measurementId });
};

/**
 * 
 * @param networkId 
 * @returns the integrations related to that networkId from ClickTrueTags DB table
 */

const getIntegrations = async (networkId: number): Promise<IntegrationRaw[]> => {
    const res = await get(`${networkId}`);
    return res.data;
};

export const getGaUniversalPixels = async () => {
    const res = await post('get-ga-universal-pixels');
    return res.data;
};

export const googleAnalyticsClient = {
    addIntegration, deleteIntegration, getIntegrations, getGaUniversalPixels
};