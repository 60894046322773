import { useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import styles from './header.module.scss';
import UserIcon from '../../../assets/svgs/user.svg';
import cogWheel from '@components/svgs/icons/cog-wheel.svg';
import ArrowIcon from '../../../assets/svgs/arrow.svg';
import LogoutIcon from '../../../assets/svgs/Logout.svg';
import SwitchIcon from '../../../assets/svgs/Switch.svg';
import ReleaseNotes from '../../../assets/svgs/ReleaseNotes.svg';
import { newLicenses } from '../../../utils/settings';
import { useContentManager } from '../../../hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizations } from 'hooks/use-organizations/useOrganizations';
import { useNavigate } from 'react-router-dom';
import { useSplit } from '../../../hooks';
import { constants } from '../../../utils/split.io';
import { createOrUpdateZendeskUser } from '../../../services/api/zendesk-v2.service';
import Button from '../../widgets/button';
import { CHEQ } from '../../../multi-product/LandingPage/utils';

export default props => {
    const cm = useContentManager();
    const userMeta = JSON.parse(window.localStorage.getItem('meta'));
    const isCheqUser = userMeta.email.includes('@cheq.ai') || userMeta.email.includes('@combotag');
    const container = useRef(null);
    const [opened, setOpened] = useState(false);
    const navigate = useNavigate();
    const { name } = useSelector(state => state.profile.meta);
    const { selectedLicense } = useSelector(state => state.settings);
    const { title } = props;
    const { logout } = useAuth0();
    const [selectedOrgName, userOrgsCount] = useOrganizations();
    const { isOn: showZendesk } = useSplit(constants.PARADOME_ZENDESK);
    const { isOn: useUserSettingsV2 } = useSplit(constants.PARADOME_USER_SETTINGS_V2);
    const { isOn: useAccountSettings } = useSplit(constants.PARADOME_ACCOUNT_SETTINGS);
    const usersState = useSelector(s => s.users);

    const showAccountSettings = useAccountSettings && (usersState?.childrenTypes || []).includes('user_manager');

    const handleLogout = () => {
        window.localStorage.clear();
        window.sessionStorage.removeItem('zendesk-external-link-redirect');
        logout();
    };

    const handleSwitchOrganization = () => {
        const sidebar = document.getElementById('Sidebar');
        if (sidebar && sidebar.style) {
            sidebar.remove();
        }
        navigate('/brands');
    };

    const getZendeskBaseRedirect = async () => {
        try {
            const payload = {
                email: userMeta.email,
                accountId: userMeta.accountId,
                name: userMeta.name,
                networkId: userMeta.networkId,
            };
            const zendeskUser = await createOrUpdateZendeskUser(payload);
            window.localStorage.setItem('zendesk', JSON.stringify(zendeskUser));
        } catch (error) {
            console.error(`error on Zendesk createOrUpdate - ${JSON.stringify(error)}`);
        }
    };

    const handleSWhatsNew = async () => {
        const zd = JSON.parse(window.localStorage.getItem('zendesk'));
        if (zd) window.open(`${zd.redirectUrl}/en-us/categories/4432894334225-Paradome-Release-Notes`, '_blank');
        await getZendeskBaseRedirect();
    };
    const handleHelp = async () => {
        const zd = JSON.parse(window.localStorage.getItem('zendesk'));
        if (zd) window.open(`${zd.redirectUrl}/en-us`, '_blank');
        await getZendeskBaseRedirect();
    };

    const currentLicense = Object.keys(newLicenses).filter(key => newLicenses[key].id === selectedLicense);
    let headerText = title;

    if (currentLicense.length && window.location.pathname !== '/home') {
        const licenseName = currentLicense[0];
        headerText = `${selectedLicense !== newLicenses.PrivacyComplianceEnforcement.id ? cm.get(CHEQ) : ''} ${cm.get(licenseName)}` + ' | ' + title;
    }

    const navigateToUserSettings = () => {
        const { path } = newLicenses[currentLicense];
        //Should be redirected to Paradome 2
        window.location.href = `${window.location.origin}/${path}/user-settings`;
    };

    const navigateToAccountSettings = () => {
        const { path } = newLicenses[currentLicense];
        //Should be redirected to Paradome 2
        window.location.href = `${window.location.origin}/${path}/account-settings`;
    };

    return (
        <div className={styles.container} data-test-selector="page header">
            <div className={styles.label}>{headerText}</div>
            <div className={styles.rightSideWrapper}>
                {showZendesk && !isCheqUser && (
                    <div>
                        <Button className={styles.buttonWrapper} onClick={() => handleHelp()}>
                            {cm.get('help')}
                        </Button>
                    </div>
                )}

                <div className={styles.userContainer} ref={container} onClick={() => setOpened(prevOpened => !prevOpened)}>
                    <img src={UserIcon} alt="user" />
                    {name} {selectedOrgName && <>| {selectedOrgName}</>}
                    <img src={ArrowIcon} className={styles.dropdown} alt="arrow" />
                </div>
                {opened && (
                    <div className={styles.actionsContainer}>
                        {!!useUserSettingsV2 && (
                            <div className={styles.actionContainer} onClick={navigateToUserSettings}>
                                <img src={UserIcon} alt="User settings" className={styles['user-img']} />
                                {cm.content.userSettings?.title}
                            </div>
                        )}
                        {!!showAccountSettings && (
                            <div className={styles.actionContainer} onClick={navigateToAccountSettings}>
                                <img src={cogWheel} alt="Account settings" className={styles['user-img']} />
                                {cm.content.accountSettings?.title}
                            </div>
                        )}
                        {showZendesk && !isCheqUser && (
                            <div className={styles.actionContainer} onClick={handleSWhatsNew}>
                                <img src={ReleaseNotes} />
                                Whats New
                            </div>
                        )}
                        <div className={styles.actionContainer} onClick={handleLogout}>
                            <img src={LogoutIcon} alt="logout" />
                            Logout
                        </div>
                        {userOrgsCount > 1 && (
                            <div className={styles.actionContainer} onClick={handleSwitchOrganization}>
                                <img src={SwitchIcon} />
                                Switch organization
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
