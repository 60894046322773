import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    header: {
        width: '100%',
    },
    clearAllButton: {
        float: 'right',
        marginTop: '13px',
        width: '79px',
        height: '24px',
        outline: 'none',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        color: '#fff',
    },
    analytics: {
        fontSize: '24px',
        letterSpacing: '0.24px',
        color: '#fffeff',
        margin: '10px',
    },
    filtering: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    groupByList: {
        // minHeight: '50px',
        maxWidth: '300px',
        marginLeft: '10px',
        textAlign: 'left',
    },
    chip: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    infoCrawlers: {
        cursor: 'pointer',
        margin: '0 10px',
        '&:hover': {
            color: theme.palette.custom.text.primary,
        },
    },
    groupBy: {
        width: '45px',
        height: '10px',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ffffff',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    advancedFiltering: {
        width: '-webkit-fill-available',
        height: '18px',
        fontSize: '24.5px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',

        margin: '10px',
        marginTop: '20px',
    },
    expansionPanelSummary: {
        root: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            marginBottom: -1,
            minHeight: 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            '&$expanded': {
                // margin: '12px 0',
            },
        },
        expanded: {},
    },
    expansionPanel: {
        root: {
            float: 'left',
            width: '-webkit-fill-available',
            height: '69px',
            backgroundColor: 'rgb(56, 56, 81)',
            margin: '10px',
            fontSize: '24px',
            // border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
                // display: 'flex'
            },
        },
        expanded: {},
    },
    country: {
        width: '-webkit-fill-available',
        backgroundColor: '#14172c',
        background: '#14172c',
        margin: '10px',
    },
    runReport: {
        width: '299px',
        height: '40px',
        float: 'left',
        backgroundColor: '#fe0072',
        margin: '10px',
        // display: 'flex',
        justifyContent: 'center',
        '& input': {
            fontSize: '2.4rem',
            width: '100%',
            height: '100%',
            outline: 'none',
            fontFamily: 'inherit',
        },
    },
    datePickerInput: {
        // backgroundColor: '#383851',
        // height: '4.1rem',
        // width: '-webkit-fill-available',
        // color: 'inherit',
        // fontStyle: 'inherit',
        // fontSize: 'inherit',
        // fontFamily: 'inherit',
        // padding: '0.8rem 1.2rem 0.8rem 1.2rem',
        // marginBottom: '0.5rem',
        marginLeft: '1rem !important',
        padding: '1rem',
        // '&:hover': {
        //     border: '1px solid #fff',
        // },
    },
}));
