/**
 * this file is auto generated by chopin-scripts.
 * you can check the project package.json
 * to see how it was generated.
 */

import UseDialog from './use-dialog';
import UseLocalStorage from './use-local-storage';
import UseTween from './use-tween';
import useFetch from './use-fetch';

export default {
    UseDialog,
    UseLocalStorage,
    UseTween,
    useFetch,
};
