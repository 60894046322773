import analytics from './analytics';
import clickTrueTags from './click-true-tags';
import { combineReducers } from 'redux';
import dashboard from './dashboard';
import profile from './profile';
import users from './users';
import settings from './settings';
import { snackbar } from './snackbar';
import { skewed } from './skewed';
import { ui } from './ui';
import { rtiDashboard } from './rti-dashboard';

export const createRootReducer = () =>
    combineReducers({
        snackbar,
        analytics,
        profile,
        dashboard,
        skewed,
        settings,
        users,
        ui,
        clickTrueTags,
        rtiDashboard,
    });
