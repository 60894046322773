import Base from '../base';
import Container from './container';
import React from 'react';

let ID = 0;

const Dialog =
    () =>
    ({ model }) => {
        return model(
            class extends Base {
                constructor() {
                    super('dialog');
                    this.state = { Component: null, options: {}, loads: [] };
                }

                hide() {
                    this.update({ Component: null, options: {} });
                }

                show(Component, options = {}) {
                    this.update({ Component, options, _id: `dialog-${ID++}` });
                }

                has(key) {
                    return !!this.state.options[key];
                }

                Container({ Component, options }) {
                    return Component ? <Container Component={Component} options={options} /> : <div />;
                }
            }
        );
    };

export default Dialog;
