import { dashboardDisplayOptions } from './constants';

const ONE_BILLION = 1000000000,
    ONE_MILLION = 1000000,
    ONE_THOUSAND = 1000;

const getVisiblePlatformsGroups = (allPlatformsGroups, selectedDisplayOption, _, isPixelGuardIntegrated) => {
    let visiblePlatformsGroups;

    switch (selectedDisplayOption) {
        case dashboardDisplayOptions.PROTECTED_PLATFORMS: {
            visiblePlatformsGroups = allPlatformsGroups.filter(pg => !!pg.protected && (isPixelGuardIntegrated ? true : pg.group !== 'GOOGLE PERFORMANCE MAX'));
            break;
        }
        case dashboardDisplayOptions.UNPROTECTED_PLATFORMS: {
            visiblePlatformsGroups = allPlatformsGroups.filter(pg => !pg.protected);
            break;
        }
        default: {
            visiblePlatformsGroups = allPlatformsGroups;
        }
    }

    return [
        {
            group: `ALL ${selectedDisplayOption.replace(/_/g, ' ').replace('ALL ', '')} (${visiblePlatformsGroups.length})`,
            platforms: visiblePlatformsGroups.flatMap(pg => pg.platforms),
            isAllPlatforms: selectedDisplayOption === dashboardDisplayOptions.ALL_PLATFORMS,
            protected: selectedDisplayOption === dashboardDisplayOptions.PROTECTED_PLATFORMS,
        },
        ...visiblePlatformsGroups,
    ];
};

const isOrganicPlatform = platformName => {
    return platformName.endsWith('ORGANIC') || platformName === 'DIRECT';
};

const getPercentage = (value, total, round = false) => {
    if (isNaN(value) || isNaN(total)) return 0;
    const result = ((value / total) * 100);
    if (isNaN(result)) return 0;
    return round ? Math.round(result) : +result.toFixed(2);
};

const formatToK = num => {
    let tok = 0;
    switch (true) {
        case num >= ONE_BILLION: {
            tok = toLocaleString(num / ONE_BILLION) + 'B';
            break;
        }
        case num >= ONE_MILLION: {
            tok = toLocaleString(num / ONE_MILLION) + 'M';
            break;
        }
        case num >= ONE_THOUSAND: {
            tok = toLocaleString(num / ONE_THOUSAND) + 'K';
            break;
        }
        default: {
            tok = num;
        }
    }
    return tok;
};

const sortBy = (field, data) => data.sort((a, b) => b[field] - a[field]);
const toLocaleString = number => (!isNaN(number) ? Number(number).toLocaleString(undefined, { maximumFractionDigits: 1 }) : 'Not Valid');

const getSubHeader = (selectedDisplayOption, dashboardCM, isBlockingMode) => {
    switch (selectedDisplayOption) {
        case !isBlockingMode && dashboardDisplayOptions.ALL_PLATFORMS:
        case dashboardDisplayOptions.UNPROTECTED_PLATFORMS:
            return dashboardCM.get('VisitsMonitoredAndAnalyzed');
        case dashboardDisplayOptions.PROTECTED_PLATFORMS:
            return dashboardCM.get('AllClicksProtected');
        default:
            break;
    }
};

const getIntOrZero = stringValue => {
    if (!stringValue) return 0;
    return isNaN(stringValue) ? 0 : parseInt(stringValue);
};

export { sortBy, getPercentage, getSubHeader, getVisiblePlatformsGroups, formatToK, isOrganicPlatform, getIntOrZero };
