import React, { useState, useRef } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '../tool-tip';
import IconButton from '@material-ui/core/IconButton';
import styles from './reportHistory.module.scss';
import DownloadSelect from './DownloadSelect';
import { useSelector } from 'react-redux';
import { SLP_REPORT_TYPE } from '../../../utils/analytics/helpers';
import { pascalCaseWord } from '../../../utils/common';

const ReportHistory = ({ reportHistory, onDelete, onClick, cm }) => {
    const { networkId } = useSelector(state => state.profile.meta);

    return (
        <div className={styles.container}>
            <div className={styles.title}>{cm.get('ReportHistory')}</div>
            {reportHistory
                .filter(report => report.networkId === networkId)
                .map((historyItem, i) => (
                    <ReportHistoryItem key={i} historyItem={historyItem} onClick={onClick} onDelete={onDelete} cm={cm} />
                ))}
        </div>
    );
};

const ReportHistoryItem = ({ onDelete, historyItem, onClick, cm }) => {
    const anchorRef = useRef(null);
    const [showDownloadDialog, setShowDownloadDialog] = useState(false);

    const handleToggleDownload = () => setShowDownloadDialog(prevOpen => !prevOpen);
    const handleCloseDownload = () => setShowDownloadDialog(false);

    const parseReportHistoryItem = value => (typeof value === 'string' ? value[0].toUpperCase() + value.slice(1) : value);

    const aFStr = [
        historyItem.reportParams?.groupBy || [],
        ...Object.values(historyItem.reportParams?.advancedFiltering || {}),
        Object.values(typeof historyItem.reportParams?.dateRange === 'string' ? { date: historyItem.reportParams?.dateRange } : historyItem.reportParams?.dateRange || {}),
    ]
        .filter(af => af && af.length)
        .map(af => af.join(', '))
        .join(',');

    const formatter = (reportParamKey, reportParamValue) => {
        return reportParamKey === 'reportType' && reportParamValue === SLP_REPORT_TYPE
            ? (reportParamValue = cm.get('UserValidation'))
            : reportParamKey === 'dateRange'
            ? typeof reportParamValue === 'string'
                ? cm.get(`Analytics.Form.DropDown.Date.${pascalCaseWord(reportParamValue)}`)
                : Object.keys(reportParamValue).map(key => [`${key} : ${cm.get(reportParamValue[key])}`]) || []
            : reportParamKey === 'groupBy' && reportParamValue.length
            ? reportParamValue.map(item => cm.get(`Analytics.Form.DropDown.GroupBy.${pascalCaseWord(item)}`)).join(', ')
            : reportParamValue;
    };
    const displayText = Object.entries(historyItem.reportParams || {})
        .filter(
            ([key, value]) =>
                key !== 'advancedFiltering' &&
                key !== 'platformStatus' &&
                key !== 'includePPCCrawlers' &&
                key !== 'policyManagement' &&
                key !== 'includePolicy' &&
                key !== 'policyToggleA' &&
                value
        )
        .map(([key, value]) => parseReportHistoryItem(formatter(key, value)))
        .join(', ');

    const isEmptyReport = historyItem.columns.length === 0;

    return (
        <div id={historyItem.id} className={styles.historyItem}>
            <Tooltip title={displayText}>
                <span className={styles.reportParamsText}>{displayText}</span>
            </Tooltip>

            <div className={styles.iconsContainer}>
                <Tooltip title={isEmptyReport ? cm.get('NoDataToDisplay') : cm.get('DownloadReport')}>
                    <div>
                        <IconButton
                            className={styles.iconButton}
                            ref={anchorRef}
                            onClick={handleToggleDownload}
                            style={isEmptyReport ? { opacity: 0.5 } : {}}
                            disabled={isEmptyReport}
                        >
                            <GetAppIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <DownloadSelect handleClose={handleCloseDownload} open={showDownloadDialog} historyItem={historyItem} anchorEl={anchorRef.current} cm={cm} />

                <Tooltip title={cm.get('ReloadReport')}>
                    <IconButton className={styles.iconButton} onClick={() => onClick(historyItem)}>
                        <RepeatRoundedIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title={cm.get('DeleteReport')}>
                    <IconButton className={styles.iconButton} onClick={() => onDelete(historyItem)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};

export default ReportHistory;
