import { useEffect, useState } from "react";
import EyeIcon from "../../../svgs/view";
import ClosedEyeIcon from "../../../svgs/close-view";

export const useSecretKey = (key: string): [() => JSX.Element, string, () => void, boolean] => {
    const [revealSecretKey, setRevealSecretKey] = useState(false);
    const [icon, setIcon] = useState(ClosedEyeIcon);
    const [secretKeyInputText, setSecretKeyInputText] = useState(key?.replaceAll(/./g, '●'))

    useEffect(() => {
        setIcon(revealSecretKey ? EyeIcon : ClosedEyeIcon);
        setSecretKeyInputText(revealSecretKey ? key : key?.replaceAll(/./g, '●'));
    }, [revealSecretKey, key])

    const handleToogleSecret = () => setRevealSecretKey(prev => !prev)

    return [() => icon, secretKeyInputText, handleToogleSecret, revealSecretKey]
}