import React, { useState } from 'react';
import { ModelRuleSet } from '@combotag/public-common/dist/libs/AppValidator';
import { capitalize } from '@combotag/public-common/dist/libs/utils';

export default ({ name = '', Form, ruleset = new ModelRuleSet() }) =>
    ({ viewFeature }) => viewFeature(`${capitalize(name)}Form`,
        function ({ initial = {}, ...props }) {
            const [state, setState] = useState(buildInitial(initial, ruleset));
            const [errors, setErrors] = useState({});

            const setters = Object.keys(state)
                .filter((key, idx, src) => src.indexOf(key) === idx)
                .map(key => [key, value => {
                    setState({ ...state, [key]: value });
                    setErrors({ ...errors, [key]: ruleset.validate(state, key).pop() });
                }])
                .reduce((acc, [key, setter]) => ({ ...acc, [`set${capitalize(key)}`]: setter }), {})

            return <Form state={state} errors={errors} setters={setters} setState={setState} isValid={ruleset.isValid(state)} {...props} />;
        })

const buildInitial = (initial, ruleset) => ({
    ...ruleset.rules.map(({ prop }) => prop).reduce((acc, prop) => ({
        ...acc, [prop]: undefined
    }), {}),
    ...initial
})
