import React from "react";
import { Tooltip } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        maxWidth: 180,
        boxShadow: "0px 0px 50px rgba(21, 23, 44, 0.4)",
        borderRadius: 6,
        backgroundColor: '#FFFFFF',
        color: "#383852",
        fontSize: 12,
        textAlign: "center",
        lineHeight: '18px'
    },
    arrow: {
        color: '#FFFFFF'
    }
});

export default ({ title, component }) => {
    const classes = useStyles();

    return (
        <Tooltip title={title} arrow classes={{tooltip: classes.root, arrow: classes.arrow}} data-test-selector="exportables-tooltip">
            {component}
        </Tooltip>
    )
}