import { capitalizeSentence, isAllCapitals } from '@/utils/common';

const pageTypeDictionary = {
    direct: 'Direct',
    unclassified: 'Unclassified',
    criteo: 'Criteo',
    twitter: 'X Paid',
    'google organic': 'Google Organic',
    'google paid': 'Google Search',
    'google display': 'Google Display',
    'google performance max': 'Google Performance Max',
    'google shopping': 'Google Shopping',
    'google smart shopping': 'Google Smart Shopping',
    'youtube paid': 'YouTube Paid',
    'youtube organic': 'YouTube Organic',
    'facebook paid': 'Meta Paid',
    'instagram paid': 'Meta Paid',
    'facebook organic': 'Meta Organic',
    'instagram organic': 'Meta Organic',
    'bing paid': 'Microsoft Paid',
    'bing organic': 'Microsoft Organic',
    'tiktok paid': 'TikTok Paid',
    'tiktok organic': 'TikTok Organic',
    'twitter organic': 'X Organic',
    'yahoo display': 'Yahoo Display',
    'yahoo paid': 'Yahoo Paid',
    'yahoo organic': 'Yahoo Organic',
    'yandex paid': 'Yandex Paid',
    'yandex organic': 'Yandex Organic',
    'linkedin paid': 'LinkedIn Paid',
    'linkedin organic': 'LinkedIn Organic',
    'duckduckgo paid': 'DuckDuckGo Paid',
    'duckduckgo organic': 'DuckDuckGo Organic',
    'pinterest organic': 'Pinterest Organic',
    'baidu paid': 'Baidu Paid',
    'baidu organic': 'Baidu Organic',
};

export const getPageTypeLabel = pageType => {
    const lowerName = pageType.toLowerCase();

    if (lowerName in pageTypeDictionary) return pageTypeDictionary[lowerName];
    if (isAllCapitals(pageType)) return capitalizeSentence(pageType);
    else return pageType;
};
