import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';

const colors = ['#EA3385', '#b029c6', '#6936FB', '#E36AA0', '#65C4CE', '#FF5EDB', '#808DDA', '#4D94FF', '#7DC9A0', '#F59855'];
const LineGraph = ({ cm, values, graphClasses, showProtectedSince, selectedPlatform, dateOption, isAllPlatforms, xAxis, barGraphValues }) => {
    const graphRef = useRef(null);
    const { services } = useSelector(state => state.settings);
    let currentService = services.filter(service => selectedPlatform.group.toLowerCase().includes(service.service));
    if (currentService && currentService.length > 0) {
        currentService = currentService[0];
    } else {
        if (selectedPlatform.group.toLowerCase().includes('youtube')) {
            currentService = services.filter(service => service.service.toLowerCase().includes('google'))[0];
        }
        if (selectedPlatform.group.toLowerCase().includes('instagram')) {
            currentService = services.filter(service => service.service.toLowerCase().includes('facebook'))[0];
        }
    }

    let protectedDate = currentService && currentService.protectedSince;

    const getSeries = () => {
        if (!isAllPlatforms) {
            return [
                {
                    name: 'data',
                    type: 'column',
                    borderRadius: 4,
                    data: barGraphValues,
                    yAxis: 1,
                    tooltip: {
                        enabled: true,
                        backgroundColor: '#FFFFFF',
                        borderRadius: 6,
                        borderColor: '#FFFFFF',
                        customTooltipPerSeries: function () {
                            return ['<span style="padding-bottom: 3px;font-weight: 600;color: #383852;line-height: 16px;">' + this.point.name + '</span> <br/>'].concat([
                                `<div style="width: 150px;display: flex; justify-content: space-between;font-size: 12px;color: #383852;padding-bottom: 3px;">
                                <span>${cm.get('InvalidVisitRate')} - ${
                                    this.point.invalidVisitRate === 100 ? this.point.invalidVisitRate : this.point.invalidVisitRate.toFixed(2)
                                }%</span><br>
                                </div>`,
                                `<div style="width: 100%;display: flex; justify-content: space-between;font-size: 12px;color: #383852;padding-bottom: 3px;">
                                <span>${cm.get('Visits')} - ${this.point.visits}</span><br>
                            </div>`,
                                `<div style="width: 100%;display: flex; justify-content: space-between;font-size: 12px;color: #383852;padding-bottom: 3px;">
                                <span>${cm.get('ValidVisits')} - ${this.point.validVisits}</span><br>
                            </div>`,
                                `<div style="width: 100%;display: flex; justify-content: space-between;font-size: 12px;color: #383852;padding-bottom: 3px;">
                                <span>${cm.get('InvalidVisits')} - ${this.point.invalidVisits}</span><br>
                            </div>`,
                            ]);
                        },
                        useHTML: true,
                    },
                    plotOptions: {
                        series: {
                            states: {
                                hover: {
                                    borderWidth: 1,
                                    borderColor: '#ffffff',
                                },
                            },
                            cursor: 'pointer',
                        },
                        column: {
                            grouping: false,
                            borderWidth: 0,
                            enableMouseTracking: true,
                            maxPointWidth: 80,
                            dataLabels: {
                                formatter: function () {
                                    return '';
                                },
                            },
                        },
                    },
                },
                {
                    marker: {
                        fillColor: '#EA3385',
                        lineWidth: 1,
                        lineColor: '#FFFFFF',
                    },
                    dataLabels: {
                        enabled: false,
                        color: '#FFFFFF',
                        style: {
                            border: 0,
                            textOutline: 'none',
                        },
                        inside: false,
                    },
                    color: '#EA3385',
                    borderRadius: 4,
                    maxWidth: 100,
                    type: 'line',
                    id: 'series',
                    data: values.map(item => item.invalidVisitRate),
                    yAxis: 0,
                    tooltip: {
                        useHTML: true,
                        shape: 'square',
                        enabled: true,
                        backgroundColor: '#FFFFFF',
                        borderRadius: 4,
                        borderColor: showProtectedSince && protectedDate ? '#FFFFFF' : '#383852',
                        customTooltipPerSeries: function () {
                            return '<div style="font-size: 12px;font-weight: 400;color: #383852;">' + `${(this.point.y < 100) ? this.point.y.toFixed(2) : this.point.y}%` + '</div>';
                        },
                    },
                },
            ];
        } else {
            return Object.keys(values).map((key, index) => {
                return {
                    name: key,
                    marker: {
                        fillColor: colors[index],
                        lineWidth: 1,
                        lineColor: '#FFFFFF',
                    },
                    dataLabels: {
                        enabled: false,
                        color: '#FFFFFF',
                        style: {
                            border: 0,
                            textOutline: 'none',
                        },
                        inside: false,
                    },
                    color: colors[index],
                    borderRadius: 4,
                    maxWidth: 100,
                    data: values[key].map(item => item.invalidVisitRate),
                    tooltip: {
                        useHTML: true,
                        shape: 'square',
                        enabled: true,
                        backgroundColor: '#FFFFFF',
                        borderRadius: 4,
                        borderColor: showProtectedSince && protectedDate ? '#FFFFFF' : '#383852',
                        customTooltipPerSeries: function () {
                            return '<div style="font-size: 12px;font-weight: 400;color: #383852;">' + `${(this.point.y < 100) ? this.point.y.toFixed(2) : this.point.y}%` + '</div>';
                        },
                    },
                };
            });
        }
    };

    const getMaxValue = () => {
        if (!isAllPlatforms) {
            const max = Math.max.apply(
                Math,
                values.map(function (o) {
                    return o.invalidVisitRate;
                }));

            return max === 100 ? max : max+5;
        } else {
            let max = 0;
            Object.keys(values).forEach(key => {
                const tempMax =
                    Math.max.apply(
                        Math,
                        values[key].map(function (o) {
                            return o.invalidVisitRate;
                        })
                    );
                if (tempMax > max) {
                    max = tempMax;
                }
            });
            return max === 100 ? max : max+5;
        }
    };

    const getYAxis = () => {
        if (isAllPlatforms) {
            return {
                min: 0,
                max: getMaxValue(),
                tickInterval: '10',
                title: {
                    text: null,
                },
                maxPadding: 0.2,
                labels: {
                    formatter: function () {
                        return `${this.value}%`;
                    },
                    style: {
                        fontSize: '0.8rem !important',
                    },
                },
                opposite: false,
                gridLineWidth: 0, //remove grids
            }
        } else {
            return [
                {
                    min: 0,
                    max: getMaxValue(),
                    tickInterval: 10,
                    title: {
                        text: null,
                    },
                    maxPadding: 0.2,
                    labels: {
                        formatter: function () {
                            return `${this.value}%`;
                        },
                        style: {
                            fontSize: '0.8rem !important',
                        },
                    },
                    opposite: true,
                    gridLineWidth: 1, //remove grids
                },
                {
                    gridLineWidth: 0, //remove grids
                    type: 'logarithmic',
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: '1rem !important',
                        },
                    },
                },
            ]
        }
    }

    const chartConfig = {
        chart: {
            zoomType: 'xy',
        },
        title: {
            text: '',
        },
        tooltip: {
            formatter: function () {
                return this.series.tooltipOptions.customTooltipPerSeries.call(this);
            },
        },
        yAxis: getYAxis(),
        xAxis: [{
            gridLineWidth: 1,
            title: {
                text: null,
            },
            categories: xAxis,
            labels: {
                enabled: true,
                style: {
                    fontSize: '1.2rem !important',
                },
            },
        }],
        credits: {
            enabled: false,
        },
        legend: {
            enabled: isAllPlatforms,
            y: 20,
            margin: 30,
            width: '100%',
            borderWidth: 0,
            itemMarginTop: 5,
            itemMarginBottom: 15,
            itemStyle: {
                fontSize: 12,
                color: '#FFFFFF',
            },
        },
    };

    useEffect(() => {
        if ((!isAllPlatforms && (!Array.isArray(values) || !Array.isArray(barGraphValues))) || !graphRef.current) return;

        Highcharts.chart(
            graphRef.current,
            Highcharts.merge(chartConfig, {
                series: getSeries(),
            })
        );
    }, [cm, values]);
    return (
        <div className={cx('component', 'flex-column')}>
            <div
                className={cx('vertical-bar-graph', !!graphClasses && [...graphClasses])}
                ref={graphRef}
                style={{
                    width: '100%',
                    height: `${isAllPlatforms ? '380px' : '380px'}`,
                }}
            />
        </div>
    );
};

export default LineGraph;
