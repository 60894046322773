import React, { useState } from 'react';

import { ReactComponent as DoubleRightArrow } from '@components/svgs/icons/double-right-arrow.svg';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import classNames from 'classnames/bind';
import styles from './tool-tip.module.css';

const cx = classNames.bind(styles);

export const ToolTip = ({ title = '', content = '', action = {}, children, leaveDelay = 300 }) => {
    if (!content) return children;
    const isInteractive = !!(action && action.handler);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleOnAction = () => {
        action.handler();
        handleClose();
    };

    const tooltipContent = (
        <>
            <div className={cx('title', 'text')}>{title}</div>
            <div className={cx('text')}>{content}</div>
            {isInteractive && (
                <div className={cx('button-container', 'flex-row')} onClick={handleOnAction}>
                    <div className={cx('button')}>{action.label}</div>
                    <DoubleRightArrow />
                </div>
            )}
        </>
    );

    return (
        <MuiTooltip
            arrow
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            classes={{ tooltip: cx('tool-tip') }}
            title={tooltipContent}
            placement="top"
            leaveDelay={leaveDelay}
            interactive={isInteractive}
        >
            {children}
        </MuiTooltip>
    );
};

export default ToolTip;
