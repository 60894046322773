export const ADOBE_OBJECT_PROPERTY_NAME = 'adobeObject';

export const ERROR_STATE_KEYS = {
    TRIGGER_EVENT: 'triggerAdobeEvent',
    THREAT_TYPE: 'threatTypeId',
    THREAT_GROUP: 'threatGroupId',
    ADOBE_OBJECT: ADOBE_OBJECT_PROPERTY_NAME,
    TAGS: 'tags',
    FORM: 'formError',
};

export const DEFAULT_ADOBE_OBJECT_NAME = 's';
