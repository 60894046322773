import { useEffect, useState } from 'react';
import CheqUI from '@combotag/cheq-ui';

const { useMeiosis, useActions } = CheqUI.React.Meiosis;

export default (Component, options) => {
    const actions = useActions();
    const [hideHundler, setHideHandler] = useState();
    useEffect(() => setHideHandler(actions.dialog.show(Component, options)), []);
    return hideHundler;
}
