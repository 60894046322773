import { compress, decompress } from 'lz-string';

import Base from '../base';
import ContentManager from '../../../services/content-manager';
import { GoogleAnalyticsEvents } from '../../../services/google-analytics';
import ReportTable from './summary-report/report-table';
import SummaryReport from './summary-report/form';
import Toast from '../../../services/notifications';
import { capitalize } from '@combotag/public-common/src/libs/utils';
import { applyAuth0AuthorizationHeader } from '@utils/http/view-models';
import { NETWORK_SETTINGS } from '../../../utils/settings/constants';

const gEvent = GoogleAnalyticsEvents();

export default ({ formable, httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor() {
                    super('analytics');
                    this.state = { reportHistory: getStoredReports(), onDownloadHistory: [] };
                }

                init(permissions, includePolicy, policyManagement, policyTogglePM, policyToggleDA, policyToggleOSC, policyToggleA) {
                    this.update(s => ({
                        ...s,
                        permissions: permissions.analytics,
                        includePolicy,
                        policyManagement,
                        policyTogglePM,
                        policyToggleDA,
                        policyToggleOSC,
                        policyToggleA,
                    }));
                    if (permissions.analytics) {
                        const data = {
                            includePolicy,
                            policyManagement,
                            policyTogglePM,
                            policyToggleDA,
                            policyToggleOSC,
                            policyToggleA,
                        };
                        this.getCampaigns_v2(data);
                        this.getSources(data);
                        this.getContents(data);
                        this.getMedium(data);
                        this.getAffiliates(data);
                        this.getRtiEventTypes(data);
                        this.getRtiEventNames(data);
                        this.getRtiUrls(data);
                        this.getCasDomains(data);
                        if (JSON.parse(window.localStorage.getItem(NETWORK_SETTINGS)).isAffiliateFraudEnabled) {
                            this.getCasAffiliates(data);
                        }
                        this.getSlpEmailDomains(data);
                        this.slpFilters();
                        formable(SummaryReport);
                    }
                }

                getReportForm(reportType) {
                    const type = capitalize(reportType);
                    return {
                        Form: this[`${type}Form`],
                    };
                }

                buildTable(type) {
                    return ReportTable(type);
                }

                loadHistoryReport(history) {
                    gEvent('loadHistoryReport', this.name);
                    decompress();
                    this.update(state => ({ ...state, ...{ report: history } }));
                }

                deleteStoredItemReports(historyItem) {
                    window.localStorage.setItem('summary', '');
                    const reportHistoryData = this.state.reportHistory.filter(item => item.id !== historyItem.id) || [];
                    reportHistoryData.forEach(saveReportToHistory);
                    this.update(state => ({ ...state, reportHistory: reportHistoryData }));
                }
            }
        ).from([
            httpRequest({
                name: 'history',
                type: 'post',
                preRequest: function ({ id, includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    gEvent('DownloadHistory', this.name);
                    this.state = { ...this.state, onDownloadHistory: [...this.state.onDownloadHistory, id], includePolicy, policyManagement };
                },
                updateState: function (data, params) {
                    return { onDownloadHistory: this.state.onDownloadHistory.filter(id => params.id !== id) };
                },
                postRequest: function (data = '') {
                    download(data);
                },
            }),
            formable(SummaryReport),
            httpRequest({
                name: 'summary_v2',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    gEvent('RunSummaryReport', this.name);
                    this.update({ report: {} });
                },
                type: 'post',
                postRequest: function (data) {
                    applyAuth0AuthorizationHeader.call(this);
                    saveReportToHistory(data);
                    const reportHistory = getStoredReports();
                    this.update({ reportHistory, report: data });
                },
                onError,
            }),
            httpRequest({
                name: 'getCampaigns_v2',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const campaignsList = data
                        .filter(campaign => campaign.campaign_name !== '')
                        .map(campaign => {
                            let label;
                            try {
                                label = decodeURI(campaign.campaign_name);
                            } catch {
                                label = campaign.campaign_name;
                            }
                            return {
                                value: campaign.campaign_id,
                                label: label,
                            };
                        });
                    campaignsList.unshift({ value: 'none', label: 'none' });
                    return { campaignsList };
                },
            }),
            httpRequest({
                name: 'getSources',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const sourcesList = data
                        .filter(({ source }) => source)
                        .map(({ source }) => ({
                            value: source,
                            label: source,
                        }));
                    sourcesList.unshift({ value: 'none', label: 'none' });
                    return { sourcesList };
                },
            }),
            httpRequest({
                name: 'getContents',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const contentList = data
                        .filter(({ content }) => content)
                        .map(({ content }) => ({
                            value: content,
                            label: content,
                        }));
                    contentList.unshift({ value: 'none', label: 'none' });
                    return { contentList };
                },
            }),
            httpRequest({
                name: 'getMedium',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const mediumList = data
                        .filter(({ medium }) => medium)
                        .map(({ medium }) => ({
                            value: medium,
                            label: medium,
                        }));
                    mediumList.unshift({ value: 'none', label: 'none' });
                    return { mediumList };
                },
            }),
            httpRequest({
                name: 'getAffiliates',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const affiliateList = data
                        .filter(({ affiliate }) => affiliate)
                        .map(({ affiliate }) => ({
                            value: affiliate,
                            label: affiliate,
                        }));
                    affiliateList.unshift({ value: 'none', label: 'none' });
                    return { affiliateList };
                },
            }),
            httpRequest({
                name: 'getRtiEventTypes',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const eventTypeList = data
                        .filter(({ eventType }) => eventType)
                        .map(({ eventType }) => ({
                            value: eventType,
                            label: eventType,
                        }));
                    eventTypeList.unshift({ value: 'none', label: 'none' });
                    return { eventTypeList };
                },
            }),
            httpRequest({
                name: 'getRtiEventNames',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const eventNameList = data
                        .filter(({ eventName }) => eventName)
                        .map(({ eventName }) => ({
                            value: eventName,
                            label: eventName,
                        }));
                    eventNameList.unshift({ value: 'none', label: 'none' });
                    return { eventNameList };
                },
            }),
            httpRequest({
                name: 'getRtiUrls',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const urlsList = data.filter(({ url }) => url).map(({ url }) => ({ value: url, label: url }));
                    urlsList.unshift({ value: 'none', label: 'none' });
                    return { urlsList };
                },
            }),
            httpRequest({
                name: 'getCasDomains',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const domainsList = data.filter(({ domain }) => domain).map(({ domain }) => ({ value: domain, label: domain }));
                    domainsList.unshift({ value: 'none', label: 'none' });
                    return { domainsList };
                },
            }),
            httpRequest({
                name: 'getCasAffiliates',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (data) {
                    const affiliatesList = data.filter(({ affiliate }) => affiliate).map(({ affiliate }) => ({ value: affiliate, label: affiliate }));
                    affiliatesList.unshift({ value: 'none', label: 'none' });
                    return { affiliatesList };
                },
            }),
            httpRequest({
                name: 'getSlpEmailDomains',
                type: 'post',
                preRequest: function ({ includePolicy, policyManagement }) {
                    applyAuth0AuthorizationHeader.call(this);
                    this.state = { ...this.state, includePolicy, policyManagement };
                },
                updateState: function (slpEmailDomains = []) {
                    return { slpEmailDomains };
                },
            }),
            httpRequest({
                name: 'slpFilters',
                baseRoute: 'api/v2/slp-dashboard',
                route: 'filters',
                type: 'get',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: function ({ threatTypes = [], domains = [] } = {}) {
                    return { threatTypes, slpDomains: domains };
                },
            }),
        ]);

function saveReportToHistory({ reportResults, reportParams, queryId, timezone, reportDate, columns }) {
    const reportHistoryArray = getStoredReports();

    reportHistoryArray.unshift({
        columns,
        queryId,
        reportResults: [{ ...reportResults[0], platformOrigin: 'Total' }, ...reportResults.slice(1)],
        timezone,
        reportDate,
        reportParams,
        id: Math.round(Math.random() * 1000),
    });

    const filterArray = reportHistoryArray.filter((r, i) => i < 5);
    window.localStorage.setItem('summary', compress(JSON.stringify(filterArray)));
    return filterArray;
}

function getStoredReports() {
    let reportHistoryData = [];
    try {
        reportHistoryData = JSON.parse(decompress(window.localStorage.getItem('summary') || '[]')) || [];
    } catch (e) {
        console.error(e);
    }
    return reportHistoryData;
}

function download(data) {
    const link = document.createElement('a');
    link.href = data.link;
    link.target = '_blank';
    //link.setAttribute('download', 'download');
    document.body.appendChild(link);
    link.click();
}

const onError = err => {
    let message;
    if (err && err.response && typeof err.response.data === 'string') {
        switch (err.response.data) {
            case 'REACHED_API_LIMIT':
                message = ContentManager.get('Error.ReachedAPILimit');
                break;
            default:
                message = err.response.data;
        }
    } else {
        message = (err && err.message) || 'sorry, something went wrong..';
    }
    Toast({ message });
};
