import React, { useRef, useState } from 'react';

import ExternalLinkIcon from '../../../../svgs/external-link/index';
import { HeatmapEnableSwitch } from './components';
import NoData from '../../../../widgets/no-data/index';
import ViewIcon from '../../../../svgs/view';
import moment from 'moment';

export default isAuthorized => {
    const columns = [
        'name',
        { id: 'dateCreated', notSearchable: true },
        { id: 'pageviews', notSearchable: true },
        { id: 'avgDuration', notSearchable: true },
        { id: 'actions', notSortable: true },
    ];

    return {
        headCellPadding: 'none',
        lineaCount: false,
        justifyHeaderContent: 'left',
        search: 'footer',
        pagination: true,
        sortable: true,
        showHeadersOnEmpty: true,
        RenderBody,
        RenderHead,
        RenderEmpty,
        columns,
        style: {
            root: { background: 'rgba(20, 23, 44, 0.5)' },
            head: { padding: 0 },
            headerRow: { padding: 0, backgroundColor: '#14172c', height: 40 },
            headerCell: { borderBottom: 'none', color: '#fe0072', fontFamily: 'Open Sans', letterSpacing: 0.12 },
            oddRow: { backgroundColor: '#21223c', height: 49 },
            row: { backgroundColor: '#383851', height: 49 },
            bodyCell: { border: 'none', fontSize: 12, padding: 7 },
            pagination: { bottom: 9 },
            tableWrapper: {
                /*background:'rgba(20, 23, 44, 0.5)',*/
                '& th:nth-child(1)': {
                    width: 170 + 140,
                    paddingLeft: 68,
                    paddingRight: 7,
                },
                '& td:nth-child(1)': {
                    display: 'inline-block',
                    width: 170 + 110,
                    paddingLeft: 68,
                    paddingRight: 7,
                    overflow: 'hidden',
                    lineHeight: '35px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
                '& th:nth-child(2), & td:nth-child(2)': {
                    width: 149 - 30,
                    paddingLeft: 0,
                    paddingRight: 7,
                },
                '& th:nth-child(3), & td:nth-child(3)': {
                    width: 154 - 40,
                    paddingLeft: 0,
                    paddingRight: 7,
                },
                '& th:nth-child(4), & td:nth-child(4)': {
                    width: 117,
                    paddingLeft: 0,
                    paddingRight: 7,
                },
            },
        },
    };
};

function RenderEmpty({ isEnabled, isEnabledLoading, toggleEnable }) {
    return (
        <NoData lineHeight={40} style={{ paddingTop: 80 }}>
            <div>NO HEATMAPS WERE FOUND {isEnabled ? 'YET' : ''}</div>
            <HeatmapEnableSwitch
                marginTop={30}
                isEnabled={isEnabled}
                toggleEnable={toggleEnable}
                isEnabledLoading={isEnabledLoading}
                progressContainerStyle={isEnabled ? { top: -18, left: 35.5 } : { top: -18, left: 10.5 }}
            />
            <div style={{ fontSize: 12.5, color: '#c9cce2' }}>
                {isEnabled ? (
                    <div>Heatmaps are being generated automatically every day.</div>
                ) : (
                    <div>By enabling this feature, CHEQ will be able to analyze and display users mouse activity on your site.</div>
                )}
            </div>
        </NoData>
    );
}

function RenderHead({ column, isEnabled, toggleEnable, isEnabledLoading, isAuthorized }) {
    const headCommonContentStyle = { fontSize: 12 };
    switch (column.id) {
        case 'name':
            return <div style={{ ...headCommonContentStyle }}>Name </div>;
        case 'dateCreated':
            return (
                <div style={{ ...headCommonContentStyle, lineHeight: '13px' }}>
                    Date
                    <br />
                    Created
                </div>
            );
        case 'pageviews':
            return <div style={{ ...headCommonContentStyle }}>Pageviews</div>;
        case 'avgDuration':
            return <div style={{ ...headCommonContentStyle }}>avg. Duration</div>;
        case 'actions':
            return isAuthorized('heatmaps', 'write') ? (
                <HeatmapEnableSwitch
                    marginTop={4}
                    fontSize={12}
                    isEnabled={isEnabled}
                    toggleEnable={toggleEnable}
                    isEnabledLoading={isEnabledLoading}
                    progressContainerStyle={isEnabled ? { top: -10, left: 35.5 } : { top: -10, left: 11 }}
                />
            ) : (
                <div style={{ width: column.width }} />
            );
        default:
            return <div style={{ width: column.width }} />;
    }
}

const millisecondsToAvgDurationFormat = milliseconds =>
    moment()
        .startOf('day')
        .add(milliseconds, 'milliseconds')
        .format('HH:mm:ss')
        .replace(/00\:(\d\d\:\d\d)/, '$1');

function RenderBody({ column, row, rowIndex, onQueryHeatmap }) {
    const [isHover, setIsHover] = useState(false);
    const pathnameDiv = useRef(null);
    const cellValue = row[column.id];
    switch (column.id) {
        case 'actions':
            return (
                <div
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => {
                        return onQueryHeatmap({
                            name: row.name,
                            date: row.lastDate,
                            dateCreated: row.dateCreated,
                            lastDate: row.lastDate,
                            possibilities: row.possibilities,
                            deviceType: row.possibilities[0].deviceType,
                            eventType: row.possibilities[0].eventType,
                            isValid: row.possibilities[0].isValid,
                        });
                    }}
                >
                    <div style={{ lineHeight: '1px', color: isHover ? '#dcdadb' : '#fe0072', display: 'inline-block', verticalAlign: 'text-top' }}>
                        <ViewIcon />
                    </div>
                    <div style={{ paddingLeft: 9, fontFamily: 'Open Sans', display: 'inline-block', lineHeight: '27px', verticalAlign: 'top' }}>View heatmap</div>
                </div>
            );
        case 'avgDuration':
            return <span>{millisecondsToAvgDurationFormat(cellValue)}</span>;
        case 'dateCreated':
            return <span>{cellValue.replace(/\-/g, '.')}</span>;
        case 'name':
            try {
                const urlObj = new URL(`http://${cellValue}`);
                return (
                    <div
                        style={{ position: 'relative', height: 34, width: 300, cursor: 'pointer', textDecoration: isHover ? 'underline' : 'none' }}
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                        onMouseDown={e => e.preventDefault()}
                    >
                        <a href={`http://${cellValue}`} target="_blank" style={{ color: 'inherit', textDecoration: isHover ? 'underline' : 'none' }} rel="noreferrer">
                            {isHover && (
                                <div style={{ position: 'absolute', top: 5, left: -21 }}>
                                    <ExternalLinkIcon />
                                </div>
                            )}
                            <div style={{ position: 'absolute', top: -6, textDecoration: 'inherit' }}>{urlObj.hostname}</div>
                            <div
                                style={{
                                    position: 'absolute',
                                    fontSize: 10,
                                    color: '#aaa8a8',
                                    top: 10,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    width: 290,
                                    textDecoration: 'inherit',
                                }}
                                ref={pathnameDiv}
                            >
                                {urlObj.pathname}
                            </div>
                        </a>
                    </div>
                );
            } catch (e) {
                return <span>{cellValue}</span>;
            }
        default:
            return <span>{cellValue}</span>;
    }
}
