export const getThreatTypesInfo = cm => {
    return [
        {
            title: cm.get('Spambot.Title'),
            content: cm.get('Spambot.Description'),
        },
        {
            title: cm.get('Scrapers.Title'),
            content: cm.get('Scrapers.Description'),
        },
        {
            title: cm.get('Automationtool.Title'),
            content: cm.get('Automationtool.Description'),
        },
        {
            title: cm.get('FrequencyCapping.Title'),
            content: cm.get('FrequencyCapping.Description'),
        },
        {
            title: cm.get('Abnormalratelimit.Title'),
            content: cm.get('Abnormalratelimit.Description'),
        },
        {
            title: cm.get('Excessiveratelimit.Title'),
            content: cm.get('Excessiveratelimit.Description'),
        },
        {
            title: cm.get('DisabledJS.Title'),
            content: cm.get('DisabledJS.Description'),
        },
        {
            title: cm.get('BehavioralAnomalies.Title'),
            content: cm.get('BehavioralAnomalies.Description'),
        },
        {
            title: cm.get('ClickFarm.Title'),
            content: cm.get('ClickFarm.Description'),
        },
        {
            title: cm.get('MaliciousBots.Title'),
            content: cm.get('MaliciousBots.Description'),
        },
        {
            title: cm.get('FalseRepresentation.Title'),
            content: cm.get('FalseRepresentation.Description'),
        },
        {
            title: cm.get('AccountTakeOver.Title'),
            content: cm.get('AccountTakeOver.Description'),
        },
        {
            title: cm.get('DataCenter.Title'),
            content: cm.get('DataCenter.Description'),
        },
        {
            title: cm.get('VPN.Title'),
            content: cm.get('VPN.Description'),
        },
        {
            title: cm.get('Proxy.Title'),
            content: cm.get('Proxy.Decription'),
        },
        {
            title: cm.get('DisabledCookies.Title'),
            content: cm.get('DisabledCookies.Description'),
        },
        {
            title: cm.get('ClickHijacking.Title'),
            content: cm.get('ClickHijacking.Description'),
        },
        {
            title: cm.get('NetworkAnomalies.Title'),
            content: cm.get('NetworkAnomalie.Description'),
        },
        {
            title: cm.get('KnownBots.Title'),
            content: cm.get('KnownBots.Description'),
        },
    ];
};

export const getThreatGroupsInfo = cm => {
    return [
        {
            title: cm.get('InvalidBotActivity.Title'),
            content: cm.get('InvalidBotActivity.Description'),
        },
        {
            title: cm.get('InvalidMaliciousActivity.Title'),
            content: cm.get('InvalidMaliciousActivity.Description'),
        },
        {
            title: cm.get('InvalidSuspiciousActivity.Title'),
            content: cm.get('InvalidSuspiciousActivity.Description'),
        },
        {
            title: cm.get('CustomRulesActivity.Title'),
            content: cm.get('CustomRulesActivity.Description'),
        },
    ];
};

export const colors = [
    ['rgba(234, 51, 133, 1)', 'rgba(252, 73, 153, 1)'],
    ['rgba(176, 41, 198, 1)', 'rgba(224, 83, 247, 1)'],
    ['rgba(105, 54, 251, 1)', 'rgba(147, 110, 255, 1)'],
    ['#C28DE5', '#C28DE5'],
];
