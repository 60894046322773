import React, { useMemo } from 'react';

import {DashboardItem, DashboardSection, DonutGraph, LegendColor} from '@widgets';

import { getPercentage, formatToK } from '@utils/dashboard';
import { useSelector } from 'react-redux';
import { useContentManager } from '@hooks';

import styles from './VisitsSummary.module.scss';

import cx from 'classnames';

import { useQuery } from 'react-query';
import * as api from '@services/api/dashboard';
import Trends from "./trends";
import {useSplit} from "../../../../../hooks";
import { constants } from '@utils/split.io';

export const VisitsSummary = ({ options }) => {
    const dashboardCM = useContentManager(cm => cm.dashboard);
    const { selectedPlatformsGroup, visiblePlatformsGroups = {} } = useSelector(state => state.dashboard);
    const { isOn: showTrends } = useSplit(constants.SPLIT_DASHBOARD_TRENDS);
    const { isOn: hideTrendsAllPlatforms } = useSplit(constants.SPLIT_DASHBOARD_TRENDS_HIDE_ALL_PLATFORMS);
    const { isError, isLoading, data: reason = [] } = useQuery([api.paidMarketingKeys.INVALID_VISITS_BY_REASON, options], () => api.getInvalidVisitsByReason(options));
    const queryOptions = {...options, platform: '', platformGroups: visiblePlatformsGroups};
    const { data: visitsSummaryForVisiblePlatformsGroups = [] } = useQuery([api.paidMarketingKeys.TOP_PLATFORMS_BY_TOTAL_VISITS, JSON.stringify(queryOptions)], () =>
        api.getTopPlatformsByTotalVisits(queryOptions)
    );

    let platforms = [];
    if (selectedPlatformsGroup?.group.toLowerCase().includes('all') && !selectedPlatformsGroup?.group.includes('ALL PROTECTED PLATFORMS')) {
        visitsSummaryForVisiblePlatformsGroups?.slice(1, 6).map(item => {
            platforms.push(item.name)
        });
        if (!platforms.includes('DIRECT'))
            platforms.push('DIRECT')
    }

    let trendsOptions = options;
    if (!trendsOptions.platform) {
        trendsOptions.platform = 'ALL PLATFORMS (17)'
    }
    if (platforms.length > 0) {
        trendsOptions = { ...trendsOptions, platforms}
    }
    const { isError: isErrorTrends, isLoading: isLoadingTrends, data: trends = [] } = useQuery([api.paidMarketingKeys.TRENDS, trendsOptions], () => api.getTrends(trendsOptions));

    const parsedData = reason?.[0] || {
        total: 0,
        invalid: 0,
        organic: {
            total: 0,
            invalid: 0,
        },
        paid: {
            total: 0,
            invalid: 0,
        },
        threatTypes: {
            paid: {},
            organic: {}
        }
    };

    const trendsData = trends?.[0] || {
        total:[],
        paid:[],
        direct:[],
        organic:[]
    }

    const values = useMemo(
        () => [
            {
                label: dashboardCM.get('Paid'),
                value: parsedData?.paid?.total,
                color: '#65C4CE',
                percentage: getPercentage(parsedData?.paid?.total, parsedData?.total),
                key: 'paid',
                title: dashboardCM.get('InvalidPaid.Title'),
                info: {
                    title: dashboardCM.get('InvalidPaid.Title'),
                    content: dashboardCM.get('InvalidPaid.Description'),
                },
                dataTestSelector: 'paidmarketing-invalid-paid-visits',
            },
            {
                label: dashboardCM.get('Organic'),
                value: parsedData?.organic?.total,
                color: '#43A5FF',
                percentage: getPercentage(parsedData?.organic?.total, parsedData?.total),
                key: 'organic',
                title: dashboardCM.get('InvalidOrganic.Title'),
                info: {
                    title: dashboardCM.get('InvalidOrganic.Title'),
                    content: dashboardCM.get('InvalidOrganic.Description'),
                },
                dataTestSelector: 'paidmarketing-invalid-organic-visits',
            },
        ],
        [parsedData]
    );

    const getOutOfTotalTemplate = (title, total) => {
        const totalValue = total && !isNaN(total) ? total.toLocaleString() : '';
        let suffix = '';

        switch (title) {
            case dashboardCM.get('TotalInvalid.Title'):
                suffix = 'Visits';
                break;
            case dashboardCM.get('InvalidPaid.Title'):
                suffix = 'PaidVisits';
                break;
            case dashboardCM.get('InvalidOrganic.Title'):
                suffix = 'OrganicVisits';
                break;
            default:
                return null;
        }

        return dashboardCM.get(`OutOfTotal${suffix}`).replace('{number}', totalValue);
    };

    const isAllPlatforms = selectedPlatformsGroup.group.toLowerCase().includes('all');
    const renderBody = () => {
       return showTrends ?
           (isAllPlatforms && hideTrendsAllPlatforms) ? (
               <div className={styles.root}>
                   <div className={styles.row}>
                       <DashboardItem
                           key="paidmarketing-total-visits"
                           data-test-selector="paidmarketing-total-visits"
                           isError={isError}
                           isLoading={isLoading}
                           className={styles.totalValues}
                           title={dashboardCM.get('TotalVisits.Title')}
                           info={{
                               title: dashboardCM.get('TotalVisits.Title'),
                               content: dashboardCM.get('TotalVisits.Description'),
                           }}
                       >
                           <div className={styles.wrapper}>
                               <DonutGraph total={parsedData.total} values={values} />
                               <div className={styles.values}>
                                   {values.map((v, i) => (
                                       <div className={styles.value} key={i}>
                                           <LegendColor color={v.color} />
                                           <span>{`${v.label} ${formatToK(v.value)} (${v.percentage}%)`}</span>
                                       </div>
                                   ))}
                               </div>
                           </div>
                       </DashboardItem>
                       <DashboardItem
                           key="paidmarketing-total-invalid-visits"
                           data-test-selector="paidmarketing-total-invalid-visits"
                           isError={isError}
                           isLoading={isLoading}
                           className={styles.item}
                           title={dashboardCM.get('TotalInvalid.Title')}
                           info={{
                               title: dashboardCM.get('TotalInvalid.Title'),
                               content: dashboardCM.get('TotalInvalid.Description'),
                           }}
                       >
                           <div className={styles.wrapper}>
                               <div className={styles.numbers}>
                                   <span className={styles.value}>{parsedData.invalid.toLocaleString()}</span>
                                   <span className={styles.percentage}>({getPercentage(parsedData.invalid, parsedData.total)}%)</span>
                               </div>
                               <div className={styles.outOfTotal}>
                                   <span className={styles.spacer}>{getOutOfTotalTemplate(dashboardCM.get('TotalInvalid.Title'), parsedData.total)}</span>
                               </div>
                           </div>
                       </DashboardItem>
                       {values.map((item, i) => {
                           if (selectedPlatformsGroup?.group === 'DIRECT' && item.label === dashboardCM.get('Paid')) return null;
                           return (
                               <DashboardItem
                                   key={item.dataTestSelector}
                                   data-test-selector={item.dataTestSelector}
                                   isError={isError}
                                   isLoading={isLoading}
                                   info={item.info}
                                   className={styles.item}
                                   title={item.title}
                               >
                                   <div className={styles.wrapper}>
                                    <span className={styles.color}>
                                        <LegendColor color={item.color} />
                                    </span>
                                       <div className={styles.numbers}>
                                           <span className={styles.value}>{parsedData[item.key].invalid.toLocaleString()}</span>
                                           <span className={styles.percentage}>({getPercentage(parsedData[item.key].invalid, parsedData[item.key].total)}%)</span>
                                       </div>
                                       <div className={styles.outOfTotal}>
                                           <span className={cx({ [styles.spacer]: !!item.color })}>{getOutOfTotalTemplate(item.title, item.value)}</span>
                                       </div>
                                   </div>
                               </DashboardItem>
                           );
                       })}
                   </div>
               </div>
           ) : (
        <div className={styles.root}>
            <div className={styles.items}>
                <div style={{display:"flex", flexFlow: 'column', width: "40%", marginRight: 20}}>
                    <div className={styles.row}>
                        <DashboardItem
                            key="paidmarketing-total-visits"
                            data-test-selector="paidmarketing-total-visits"
                            isError={isError}
                            isLoading={isLoading}
                            className={styles.totalValues}
                            title={dashboardCM.get('TotalVisits.Title')}
                            info={{
                                title: dashboardCM.get('TotalVisits.Title'),
                                content: dashboardCM.get('TotalVisits.Description'),
                            }}
                        >
                            <div className={styles.wrapper}>
                                <DonutGraph total={parsedData.total} values={values} />
                                <div className={styles.values}>
                                    {values.map((v, i) => (
                                        <div className={styles.value} key={i}>
                                            <LegendColor color={v.color} />
                                            <div>
                                                <div className={styles.label}>{`${v.label}`}</div>
                                                <div className={styles.flex}>{`${formatToK(v.value)} (${v.percentage}%)`}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </DashboardItem>
                        <DashboardItem
                            key="paidmarketing-total-invalid-visits"
                            data-test-selector="paidmarketing-total-invalid-visits"
                            isError={isError}
                            isLoading={isLoading}
                            className={styles.item}
                            title={dashboardCM.get('TotalInvalid.Title')}
                            info={{
                                title: dashboardCM.get('TotalInvalid.Title'),
                                content: dashboardCM.get('TotalInvalid.Description'),
                            }}
                        >
                            <div className={styles.wrapper}>
                                <div className={styles.numbers}>
                                    <span className={styles.value}>{parsedData.invalid.toLocaleString()}</span>
                                    <span className={styles.percentage}>({getPercentage(parsedData.invalid, parsedData.total)}%)</span>
                                </div>
                                <div className={styles.outOfTotal}>
                                    <span className={styles.spacer}>{getOutOfTotalTemplate(dashboardCM.get('TotalInvalid.Title'), parsedData.total)}</span>
                                </div>
                            </div>
                        </DashboardItem>
                    </div>
                    <div className={styles.row}>
                        {values.map((item, i) => {
                            if (selectedPlatformsGroup?.group === 'DIRECT' && item.label === dashboardCM.get('Paid')) return null;
                            return (
                                <DashboardItem
                                    key={item.dataTestSelector}
                                    data-test-selector={item.dataTestSelector}
                                    isError={isError}
                                    isLoading={isLoading}
                                    info={item.info}
                                    className={styles.item}
                                    title={item.title}
                                >
                                    <div className={styles.wrapper}>
                                    <span className={styles.color}>
                                        <LegendColor color={item.color} />
                                    </span>
                                        <div className={styles.numbers}>
                                            <span className={styles.value}>{parsedData[item.key].invalid.toLocaleString()}</span>
                                            <span className={styles.percentage}>({getPercentage(parsedData[item.key].invalid, parsedData[item.key].total)}%)</span>
                                        </div>
                                        <div className={styles.outOfTotal}>
                                            <span className={cx({ [styles.spacer]: !!item.color })}>{getOutOfTotalTemplate(item.title, item.value)}</span>
                                        </div>
                                    </div>
                                </DashboardItem>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.trends}>
                    <Trends dashboardCM={dashboardCM} data={trendsData} isLoading={isLoadingTrends} isError={isErrorTrends} className={styles.totalValues} dateOption={options.dateRange}/>
                </div>
            </div>
        </div>
        ) : <div className={styles.root}>
           <div className={styles.row}>
               <DashboardItem
                   key="paidmarketing-total-visits"
                   data-test-selector="paidmarketing-total-visits"
                   isError={isError}
                   isLoading={isLoading}
                   className={styles.totalValues}
                   title={dashboardCM.get('TotalVisits.Title')}
                   info={{
                       title: dashboardCM.get('TotalVisits.Title'),
                       content: dashboardCM.get('TotalVisits.Description'),
                   }}
               >
                   <div className={styles.wrapper}>
                       <DonutGraph total={parsedData.total} values={values} />
                       <div className={styles.values}>
                           {values.map((v, i) => (
                               <div className={styles.value} key={i}>
                                   <LegendColor color={v.color} />
                                   <span>{`${v.label} ${formatToK(v.value)} (${v.percentage}%)`}</span>
                               </div>
                           ))}
                       </div>
                   </div>
               </DashboardItem>
               <DashboardItem
                   key="paidmarketing-total-invalid-visits"
                   data-test-selector="paidmarketing-total-invalid-visits"
                   isError={isError}
                   isLoading={isLoading}
                   className={styles.item}
                   title={dashboardCM.get('TotalInvalid.Title')}
                   info={{
                       title: dashboardCM.get('TotalInvalid.Title'),
                       content: dashboardCM.get('TotalInvalid.Description'),
                   }}
               >
                   <div className={styles.wrapper}>
                       <div className={styles.numbers}>
                           <span className={styles.value}>{parsedData.invalid.toLocaleString()}</span>
                           <span className={styles.percentage}>({getPercentage(parsedData.invalid, parsedData.total)}%)</span>
                       </div>
                       <div className={styles.outOfTotal}>
                           <span className={styles.spacer}>{getOutOfTotalTemplate(dashboardCM.get('TotalInvalid.Title'), parsedData.total)}</span>
                       </div>
                   </div>
               </DashboardItem>
               {values.map((item, i) => {
                   if (selectedPlatformsGroup?.group === 'DIRECT' && item.label === dashboardCM.get('Paid')) return null;
                   return (
                       <DashboardItem
                           key={item.dataTestSelector}
                           data-test-selector={item.dataTestSelector}
                           isError={isError}
                           isLoading={isLoading}
                           info={item.info}
                           className={styles.item}
                           title={item.title}
                       >
                           <div className={styles.wrapper}>
                                    <span className={styles.color}>
                                        <LegendColor color={item.color} />
                                    </span>
                               <div className={styles.numbers}>
                                   <span className={styles.value}>{parsedData[item.key].invalid.toLocaleString()}</span>
                                   <span className={styles.percentage}>({getPercentage(parsedData[item.key].invalid, parsedData[item.key].total)}%)</span>
                               </div>
                               <div className={styles.outOfTotal}>
                                   <span className={cx({ [styles.spacer]: !!item.color })}>{getOutOfTotalTemplate(item.title, item.value)}</span>
                               </div>
                           </div>
                       </DashboardItem>
                   );
               })}
           </div>
       </div>
    }
    return (
        <DashboardSection title={dashboardCM.get('VisitsSummary').toUpperCase()} data-test-selector="visits-summary-section">
            {renderBody()}
        </DashboardSection>
    );
};

export default VisitsSummary;
