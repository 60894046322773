import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    icon: { fontSize: 40 },
    container: {
        position: 'fixed',
        right: 50,
        bottom: 50,
        cursor: 'pointer',
        color: theme.palette.custom.text.primary,
        '&:hover': {
            color: theme.palette.text.primary,
        }
    }
}))