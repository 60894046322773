import * as actions from '../actions/dashboard';
import * as rtiAPI from '@services/api/rti-dashboard';
import { getPixelGuardNetworkStatus } from '../../services/api';

const getDashboardSettings = (date = new Date()) => {
    return (dispatch, _, api) => {
        dispatch(actions.dashboardSettingsLoading());

        const realtimePromises = [
            api.realtime.getDateRanges(date),
            api.realtime.getPlatformsGroups(date),
            api.realtime.getChannels(date),
            api.realtime.getThreatGroups(),
            getPixelGuardNetworkStatus(),
        ];

        Promise.all(realtimePromises).then(
            responses => {
                const dashboardSettings = {
                    dateRanges: responses[0].data,
                    platformsGroups: responses[1].data,
                    channels: responses[2].data,
                    threatGroups: responses[3].data,
                    isPixelGuardIntegrated: responses[4].isPixelGuardIntegrated,
                };
                dispatch(actions.dashboardSettingsUpdated(dashboardSettings));
            },
            error => {
                dispatch(actions.dashboardRequestFailed(error));
            }
        );
    };
};

const getPotentialReplacedVisitsComponent =
    ({ dateRange = 'today', includePPCCrawlers = false }) =>
    async (dispatch, _, api) => {
        try {
            const data = await api.realtime.getPotentialReplacedVisitsComponent({ dateRange, includePPCCrawlers });
            dispatch(actions.getPotentialReplacedVisitsComponent(data));
        } catch (err) {
            dispatch(actions.dashboardRequestFailed(err));
        }
    };

const getCalculatedReplacedVisitsComponent =
    ({ dateRange = 'today', includePPCCrawlers = false }) =>
    async (dispatch, _, api) => {
        try {
            const data = await api.realtime.getCalculatedReplacedVisitsComponent({ dateRange, includePPCCrawlers });
            dispatch(actions.getCalculatedReplacedVisitsComponent(data));
        } catch (err) {
            dispatch(actions.dashboardRequestFailed(err));
        }
    };

const getBlockedEntitiesComponent =
    ({ dateRange = 'today', includePPCCrawlers = false }) =>
    async (dispatch, _, api) => {
        try {
            const data = await api.realtime.getBlockedEntitiesComponent({ dateRange, includePPCCrawlers });
            dispatch(actions.getBlockedEntitiesComponent(data));
        } catch (err) {
            dispatch(actions.dashboardRequestFailed(err));
        }
    };

const getRTIInvalidInsights = filterOptions => async dispatch => {
    try {
        const res = await rtiAPI.getVisitsInsights(filterOptions);
        dispatch(actions.getRTIInvalidInsights(res));
        dispatch(actions.rtiDashbordUpdated());
    } catch (err) {
        dispatch(actions.RTIDashboardError(err));
    }
};

const getRTIPageviews = filterOptions => async dispatch => {
    try {
        const res = await rtiAPI.getPageViews(filterOptions);
        dispatch(actions.getRTIPageviews(res));
    } catch (err) {
        dispatch(actions.RTIDashboardError(err));
    }
};

const getRTIVisitsSummary = filterOptions => async dispatch => {
    try {
        const res = await rtiAPI.getVisitsSummary(filterOptions);
        dispatch(actions.getRTIVisitsSummary(res));
    } catch (err) {
        dispatch(actions.RTIDashboardError(err));
    }
};

const getInvalidIps = () => async (dispatch, _, api) => {
    try {
        const { data } = await api.realtime.getInvalidIps(new Date());
        dispatch(actions.dashboardDataUpdated({ data: { invalidIps: data }, dataReady: false }));
    } catch (err) {
        dispatch(actions.dashboardRequestFailed(err));
    }
};

const getDashboardData = ({ dateRange = 'today', includePPCCrawlers = false }) => {
    return async dispatch => {
        const body = { dateRange, includePPCCrawlers };
        // dispatch(actions.dashboardDataLoading(body));
        // dispatch(getInvalidVisitsByCampaigns(body));
        // dispatch(getInvalidVisitsByCountries(body));
        // dispatch(getInvalidVisitsByReason(body));
        // dispatch(getInvalidVisitsByUrl(body));
        // dispatch(getInvalidVisitsByKeywords(body));
        dispatch(getPotentialReplacedVisitsComponent(body));
        dispatch(getCalculatedReplacedVisitsComponent(body));
        dispatch(getBlockedEntitiesComponent(body));
        dispatch(getInvalidIps());
    };
};

const getRTIDashboard = body => dispatch => {
    dispatch(actions.rtiDashboardLoading());
    dispatch(getRTIVisitsSummary(body));
    dispatch(getRTIInvalidInsights(body));
    dispatch(getRTIPageviews(body));
};

export { getDashboardSettings, getDashboardData, getRTIDashboard, getRTIPageviews };
