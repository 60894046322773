import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function ({
    items = [],
    RenderItem = render,
    onChange = console.log,
    selectedKey = 'value'
}) {
    const [selected, setSelected] = useState([]);
    useEffect(() => { onChange(selected); }, [selected.length]);
    const changeHandler = value => ({ target }) => setSelected(target.checked
        ? [...selected, value]
        : selected.filter(v => value !== v));

    return <FormGroup>
        {items.map((item, idx) => <RenderItem key={idx}
            checked={selected.includes(item[selectedKey])}
            onChange={changeHandler(item[selectedKey])}
            {...item} />)}
    </FormGroup>
}

const render = ({ label, ...props }) => <FormControlLabel
    control={<Checkbox {...props} />}
    label={label}
/>