import React, { useRef, useState, useEffect } from 'react';
import { useOnClickOutside } from '../../../../hooks';
import Checkbox from '../../../checkbox';
import arrowIcon from '../../../../assets/arrow.svg';
import arrowDisableIcon from '../../../../assets/arrow-disable.svg';
import searchIcon from '../../../../assets/search.svg';
import styles from './select.module.scss';
import Tooltip from '@components/containers/exportables/ui/tooltip';

export default ({
    cm,
    selector,
    type,
    label = '',
    selected,
    options,
    onSelect,
    placeholder = '',
    style = {},
    dropDownStyle = {},
    disabled = false,
    translateLabels = true,
    onFocus = () => {},
    error = false,
}) => {
    const container = useRef(null);
    const plainLabel = type === 'users' || type === 'channels' || !translateLabels;
    const [opened, setOpened] = useState(false);
    const [searchedValue, setSearchedValue] = useState('');
    useOnClickOutside(container, () => {
        setOpened(false);
    });

    useEffect(() => {
        if (searchedValue.length > 0) {
            setOpened(true);
        }
    }, [searchedValue]);

    const onSearch = e => setSearchedValue(e.target.value);

    let finalOptions = options;
    if (searchedValue.length > 0 && type === 'users') {
        finalOptions = options.filter(item => item.label.toLowerCase().includes(searchedValue.toLowerCase()));
    }

    const selectedRow = selected.map((item, index) => {
        return ` ${plainLabel ? item.label : cm.get(item.label)}${index !== selected.length - 1 ? ',' : ''}`;
    });

    return (
        <div ref={container} className={`${styles.container} ${type === 'channels' && styles.channels}`} data-test-selector={selector}>
            <div
                className={`${styles.selectInput} ${opened && styles.active} ${disabled && styles.disabledMode} ${error && styles.error}`}
                onClick={() => setOpened(prevOpened => !prevOpened)}
                style={style}
            >
                <div className={styles.label} style={{ color: disabled ? '#788397' : '#FFFFFF' }}>
                    {label ? `${label}:` : ''}
                    <Tooltip
                        component={
                            <div className={styles.listOfSelected}>
                                <div className={styles.selectedOneItem}>{selectedRow}</div>
                            </div>
                        }
                        title={selectedRow}
                    />
                </div>
                {selected.length === 0 && <div className={styles.placeholder}>{type === 'channels' ? 'Select Channel...' : placeholder?.length ? placeholder : 'Select...'}</div>}
                <img src={disabled ? arrowDisableIcon : arrowIcon} className={disabled ? styles.disableArrow : styles.tick} alt="icon" />
            </div>
            {opened && !disabled && (
                <div className={styles.dropdown} data-test-selector="exportables-filters-selectors-dropdown" style={dropDownStyle}>
                    <div className={styles.items}>
                        {type === 'users' && (
                            <div className={styles.searchBox}>
                                <img src={searchIcon} alt="search" />
                                <input
                                    className={styles.input}
                                    value={searchedValue}
                                    onChange={onSearch}
                                    placeholder="Search..."
                                    data-test-selector="exportables-filters-selectors-search"
                                    onFocus={onFocus}
                                />
                            </div>
                        )}
                        {finalOptions.map((item, index) => {
                            return (
                                <Checkbox
                                    selector={`exportables-filters-selectors-checkbox${index}`}
                                    key={index}
                                    checked={selected.some(s => s.value === item.value)}
                                    label={plainLabel ? item.label : cm.get(item.label)}
                                    onChange={checked => onSelect(item, type, checked)}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
