import React from "react";
import {Snackbar, SnackbarContent} from "@material-ui/core";
import SuccessIcon from "../../../components/containers/exportables/assets/check-circle-big.svg";
import CloseIcon from "../../../components/containers/exportables/assets/close.svg";
import styles from "./Snackbar.module.scss";

interface IProps {
    cm: any;
    open: boolean;
    onClose: () => void;
}
export default ({ cm, open, onClose }: IProps) => {

    const body = (
        <div className={styles.container} data-test-selector="snackbar-container">
            <img src={SuccessIcon} alt="success"/>
            <div className={styles.textContainer}>
                <div className={styles.label}>{cm.get('Success')}</div>
                <div className={styles.label}>{cm.get('SidebarContactUsSnackbarLabel')}</div>
            </div>
        </div>
    )
    const action = (
        <React.Fragment>
            <img src={CloseIcon} alt="close" onClick={onClose} className={styles.icon} data-test-selector="snackbar-close-button"/>
        </React.Fragment>
    );

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            action={action}
        >
            <SnackbarContent
                className={styles.root}
                message={body}
                action={action}/>
        </Snackbar>
    )
}