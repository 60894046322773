import React, { useState } from 'react';
import XylophoneList from '../xylophone-list';
import styles from './style.module.css';

export default function ({ height, width = 500, items = [], render = () => <div />, ...rest }) {
    const [selected, select] = useState({});
    const item = items.find(({ label }) => label === selected.label);

    return <div className={styles['container']} style={{ width, height }}>
        <XylophoneList height={height} width={items.length ? '50%' : '100%'} items={items} onClick={select} {...rest} />
        {selected && <div className={styles['details']} style={{ width: '50%', height }}>
            {item && render(item)}
        </div>}
    </div>
}