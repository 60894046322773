import ContentManager from '@services/content-manager';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const cmMapKeys = {
    dashboard: 'DashboardV2',
    analytics: 'Analytics',
    skewed: 'Skewed',
    accountManagment: 'AccountManagment',
};

/**
 * A hook to return specific content manager
 *
 * @typedef {Object} cm
 * @property {string} cm.dashboard
 * @property {string} cm.analytics
 * @property {string} cm.skewed
 * @property {string} cm.accountManagment
 *
 *
 * @param {function(cm):string} [cb] callback function
 *
 * @returns {ContentManager} content manager
 *
 * @example
 *
 * import { useContentManager } from '@hooks'
 *
 * const contentManager = useContentManger()
 */

export const useContentManager = cb => {
    const path = cb ? cb(cmMapKeys) : '';
    return ContentManager.by(path);
};
