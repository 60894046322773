import * as React from "react"

function UnblockIcon(props) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#prefix__clip0)">
                <path
                    d="M14.4 9.257h-2.057L10.8 13.886 7.714 4.629 6.171 9.257H4.114"
                    stroke="#fff"
                    strokeWidth={1.333}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <circle cx={9} cy={9} r={8.5} stroke="#fff" />
            <defs>
                <clipPath id="prefix__clip0">
                    <path
                        fill="#fff"
                        transform="translate(3.086 3.086)"
                        d="M0 0h12.343v12.343H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default UnblockIcon;
