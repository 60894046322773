import React from 'react';
import useStyle from './style';
import {Slider, Tooltip} from '@material-ui/core';



export default ({ formatLabel, title = 'HOURS', max, min, step, value = 0, onChange = console.log, ...props}) => {
    const {track, rail, thumb, container, containerTitle} = useStyle();
    const ValueLabelComponent = (props) =>{
        const { children, open, value } = props;
        const {tooltip} = useStyle();
        return (
            <Tooltip classes={{tooltip}} open={open} enterTouchDelay={0} placement="bottom" title={formatLabel(value)}>
                {children}
            </Tooltip>
        );
    }
    return <div className={container}>
        <div className={containerTitle}>{title}</div>
        <Slider
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={(e, value) => onChange(value)}
            classes={{track, rail, thumb}}
            ValueLabelComponent={ValueLabelComponent} {...props}/>
    </div>

}
