import { isAuthorized } from '../../../utils/permissions';
import { Navigate, Routes, Route } from 'react-router-dom';

import CheqUI from '@combotag/cheq-ui';
import React from 'react';
import SnackbarContainer from './SnackbarContainer';
import { routes } from '../../../routes/routes.config';
import styles from './style.module.css';
import widgets from '../../widgets';
import { useSelector } from 'react-redux';
import mixpanel from '@services/mixpanel';
import config from '../../../config';
import { useAuth0 } from '@auth0/auth0-react';
import { CHEQ_ACQUISITION_ROUTE, CHEQ_ANALYTICS_ROUTE, CHEQ_DEFEND, CHEQ_PRIVACY } from '../../../utils/common';

const { useActions } = CheqUI.React.Meiosis;
const { PrivateRoute } = widgets;

export default function Main() {
    const profile = useSelector(state => state.profile);
    const eligibleRoutes = routes.filter(({ models = [] }) => models.some(model => isAuthorized(profile.permissions, model, 'read')));
    const DefaultComponent = eligibleRoutes.find(route => route.isDefault);
    const { user } = useAuth0();
    const userMeta = user && user[`${window.location.origin}/meta`];

    window.onpopstate = e => {
        const object = {
            'Navigation type': 'Back',
        };
        switch (window.location.pathname) {
            case '/analytics':
                object['Page name'] = 'Analytics';
                break;
            case '/dashboard':
            case `/${CHEQ_ACQUISITION_ROUTE}/dashboard`:
            case `/${CHEQ_DEFEND}/dashboard`:
            case `${CHEQ_ANALYTICS_ROUTE}/dashboard`:
            case `/${CHEQ_PRIVACY}/settings`:
                object['Page name'] = 'Dashboard';
                break;
            case '/reports':
                object['Page name'] = 'Exportable';
                break;
            case '/account_management':
                object['Page name'] = 'Management';
                break;
            default:
                object['Page name'] = 'Dashboard';
                break;
        }

        mixpanel.postEvent('PageView', object);
    };

    return (
        <div className={styles.container}>
            <Routes>
                <Route to="/*" index element={<Navigate to={(DefaultComponent && DefaultComponent.path) || '/dashboard'} />} />
                {eligibleRoutes.map(({ route, path }) => {
                    return <React.Fragment key={path}>{route(userMeta?.networkId == config.outbrainNetworkId)}</React.Fragment>;
                })}
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
            <SnackbarContainer />
        </div>
    );
}
