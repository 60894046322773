import { FC, useEffect, useState } from 'react';
/* @ts-ignore */
import { useContentManager, useSplit } from '@hooks';
import styles from '../styles.module.scss';
import { HubSpotForm } from '../../../../../types/hubspot';
import ArrowIcon from '../../../../../assets/svgs/arrow.svg';
import ErrorIcon from '../../../../containers/exportables/assets/failed-triangle.svg';
import { HubSpotFormsList } from './forms-list';
import searchIcon from '../../../../../assets/svgs/search.svg';
import { Checkbox, Divider } from '@material-ui/core';
import { constants } from '../../../../../utils/split.io/constants';
import ToolTip from 'widgets/tool-tip';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';

type HubSpotFormsProps = {
    hubSpotForms: HubSpotForm[];
    updatedForms: HubSpotForm[];
    isFetchFormsError: boolean;
    initHubSpotForms: HubSpotForm[];
    isProtectAllNewFormsStatus: boolean;
    handleIsProtectAllNewForms: () => void;
    handleHubSpotForms: (forms: HubSpotForm[]) => void;
    setUpdatedForms: (forms: HubSpotForm[]) => void;
};

const RESULT_PER_PAGE = 50;

export const HubSpotForms: FC<HubSpotFormsProps> = props => {
    const { hubSpotForms, isProtectAllNewFormsStatus, handleIsProtectAllNewForms, handleHubSpotForms, updatedForms, setUpdatedForms, initHubSpotForms, isFetchFormsError } = props;
    const cm = useContentManager();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [chunkForms, setChunkForms] = useState<HubSpotForm[][]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { isOn: isProtectedNewFormOn } = useSplit(constants.PARADOME_HUBSPOT_PROTECT_NEW_FROM_FEATURE);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setSearchTerm(searchValue);
        }, 500);
        return () => clearTimeout(timerId);
    }, [searchValue]);

    useEffect(() => {
        handleOnSearch();
    }, [searchTerm]);

    useEffect(() => {
        if (hubSpotForms.length / RESULT_PER_PAGE < 1) {
            setChunkForms([hubSpotForms]);
        } else {
            setChunkForms(createChunkForms(hubSpotForms));
        }
        handleOnSearch();
    }, [hubSpotForms]);

    const handleOnSearch = () => {
        setSearchValue(searchTerm);
        const filteredForms = hubSpotForms?.filter(form => form?.name?.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase()));
        if (filteredForms.length / RESULT_PER_PAGE < 1) {
            setChunkForms([filteredForms]);
        } else {
            setChunkForms(createChunkForms(filteredForms));
        }
        setPageIndex(0);
    };

    const handleUpdateForm = (formId: number, checked: boolean) => {
        const foundedFormIndex = hubSpotForms.findIndex(form => form.id === formId);
        const newHubSpotForms = [...hubSpotForms];
        newHubSpotForms[foundedFormIndex] = { ...newHubSpotForms[foundedFormIndex], id: formId, checked };
        handleHubSpotForms(newHubSpotForms);
        const isFormedChangedInFormsInit = initHubSpotForms.some(form => form.id === formId && checked !== form.checked);
        const foundedUpdatedForm = updatedForms.find(form => form.id === formId);
        if (!foundedUpdatedForm && isFormedChangedInFormsInit) {
            setUpdatedForms([...updatedForms, { ...newHubSpotForms[foundedFormIndex], id: formId, checked }]);
        } else if (foundedUpdatedForm && !isFormedChangedInFormsInit) {
            const newUpdatedForms = updatedForms.filter(form => form.id !== formId);
            setUpdatedForms(newUpdatedForms);
        }
    };

    const checkedAllForms = (checkedState: boolean) => {
        const allChunksForms = chunkForms.flat();
        const checkedForms = hubSpotForms.map(form => {
            const isFormInChunk = allChunksForms.some(chunkForm => form.id === chunkForm.id);
            return isFormInChunk ? { ...form, checked: checkedState } : form;
        });
        const newUpdatedForms: any = checkedForms
            .map(checkedForm => {
                const foundedUpdatedForm = updatedForms.find(updatedForm => updatedForm.id === checkedForm.id && updatedForm.checked === checkedForm.checked);
                const foundedInitForm = initHubSpotForms.find(initForm => initForm.id === checkedForm.id && initForm.checked === checkedForm.checked);
                if ((!foundedInitForm && foundedUpdatedForm) || (!foundedInitForm && !foundedUpdatedForm)) {
                    return checkedForm;
                } else {
                    return null;
                }
            })
            .filter(form => form);
        setUpdatedForms(newUpdatedForms);
        handleHubSpotForms(checkedForms);
    };

    function createChunkForms(forms: HubSpotForm[]): HubSpotForm[][] {
        const result = [];
        for (let i = 0; i < forms.length; i += RESULT_PER_PAGE) {
            result.push(forms.slice(i, i + RESULT_PER_PAGE));
        }
        return result;
    }

    const onNextPageClick = () => {
        if (chunkForms[pageIndex + 1]) {
            setPageIndex(pageIndex + 1);
        }
    };

    const onPrevPageClick = () => {
        if (chunkForms[pageIndex - 1]) {
            setPageIndex(pageIndex - 1);
        }
    };

    return (
        <div>
            <div className={styles.formsHeaderContainer}>
                <div className={styles.formsTitleContainer}>
                    <span className={styles.mainTitle}>
                        {cm.get('HubSpotProtectedFormsTitle')} {'(' + hubSpotForms.reduce((acc, obj) => acc + (obj.checked ? 1 : 0), 0) + '/' + hubSpotForms.length + ')'}
                    </span>
                    {!isFetchFormsError && (
                        <div className={styles.subTitleContainer}>
                            <span className={styles.subTitle}>{cm.get('HubSpotSelectFromSubTitle')}</span>
                            {isProtectedNewFormOn ? (
                                <>
                                    <div className={styles.protectionNewFormContainer}>
                                        <Divider className={styles.protectionDivider} orientation="vertical" />
                                        <Checkbox checked={isProtectAllNewFormsStatus} onClick={() => handleIsProtectAllNewForms()} className={styles.protectCheckbox} />
                                        <span>{cm.get('HubSpotProtectFormTitle')}</span>
                                        <ToolTip content={cm.get('ProtectedAllNewFormsToolTip')}>
                                            <InfoIcon className={styles.icon} cursor="pointer" />
                                        </ToolTip>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.actionContainer}>
                    <div className={styles.inputContainer} data-test-selector="exportables-header-search-container">
                        <div>
                            <img src={searchIcon} alt="search" className={styles.searchIcon} width="15" height="15" />
                        </div>
                        <input value={searchValue} className={styles.searchInput} onChange={e => setSearchValue(e.target.value)} placeholder={cm.get('Search')} />
                    </div>
                </div>
            </div>
            {!isFetchFormsError ? (
                chunkForms[pageIndex]?.length ? (
                    <HubSpotFormsList hubSpotForms={chunkForms[pageIndex]} handleUpdateForm={handleUpdateForm} checkedAllForms={checkedAllForms} />
                ) : (
                    <></>
                )
            ) : (
                <div className={styles.fetchFormsErrorContainer}>
                    <div>
                        <img className={styles.errorIcon} src={ErrorIcon} alt="error" width={45} height={45} />
                    </div>
                    <span>{cm.get('BasicErrorFetchDataMessage')}</span>
                </div>
            )}
            {!isFetchFormsError ? (
                <div className={styles.paginationContainer}>
                    <span className={styles.resultText}>
                        {cm.get('Showing')} {RESULT_PER_PAGE} {cm.get('ResultPerPage')}
                    </span>
                    <div className={styles.pageControlContainer}>
                        <div onClick={onPrevPageClick} className={styles.pageNavigationButton}>
                            <img src={ArrowIcon} className={styles.prevIcon} alt="arrow" />
                        </div>
                        <div>{pageIndex + 1}</div>
                        <span className={styles.separatePageInfo}>/</span>
                        <span>{Math.ceil(chunkForms.flat().length / RESULT_PER_PAGE) || '1'}</span>
                        <div onClick={onNextPageClick} className={styles.pageNavigationButton}>
                            <img src={ArrowIcon} className={styles.nextIcon} alt="arrow" />
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
