import {makeStyles} from '@material-ui/core/styles'

export default makeStyles(theme => ({
    calenderDateNotAvailable: {
        disabled: 'true !important',
        backgroundColor: '#696969 !important',
        color: '#ffff !important'
    },
    calenderDate: {
        backgroundColor: theme.palette.custom.text.primary,
        fontSize: 18,
        color: '#fff',
    },
    calender: {
        zIndex: 10,
        border: 'none',
        width: 280,
        '& .react-calendar__tile': {
            '&:hover': {
                backgroundColor: '#0C1021',
            },
            '&:disabled': {
                backgroundColor: '#696969 !important',
            },
        },
        '& .react-calendar__tile--active':{
            backgroundColor: '#0C1021 !important',
            background: '#0C1021 !important',
        },
        backgroundColor: theme.palette.background.default,
        '& .react-calendar__tile--now': {
            backgroundColor: theme.palette.custom.text.primary
        },
        '& .react-calendar__navigation__label': {
            color: '#fff',
            fontSize: 18,
            '&:hover': {
                backgroundColor: '#0C1021 !important',
            },
            '&:focus': {
                backgroundColor: '#0C1021 !important',
            },

        },
        '& .react-calendar__year-view__months__month': {
            border: 'none !important',
            height: 70,
            width: 70,
            fontSize: 14,
            '&:hover': {
                color: '#EA3385'
            }
        },
        '& .react-calendar__navigation__prev2-button': {
            display: 'none'
        },
        '& .react-calendar__navigation__next2-button': {
            display: 'none'
        },
        '& .react-calendar__navigation__prev-button': {
            marginLeft: 10,
            color: '#fff !important',
            fontSize:20
        },
        '& .react-calendar__navigation__next-button': {
            marginRight: 10,
            backgroundColor: `#0C1021 !important`,
            color: '#fff !important',
            fontSize:20
        },
        '& .react-calendar__navigation__button': {
            '&:hover': {
                backgroundColor: 'none'
            }
        },
        '& .react-calendar__tile--hasActive': {
            backgroundColor: `#282A3F !important`,
            fontSize: 14
        },

        '& .react-calendar__month-view__days': {
            backgroundColor: '#fff',
        }
    }
}))