import { getApiInterceptions, RTIAPIkeys } from '@services/api/rti-dashboard';
import { useMemo } from 'react';
import { DashboardItem, OverflowTip, LegendColor, DonutGraph, ToolTip } from '@widgets';
import { formatToK, getPercentage } from '@utils/common';
import { eventsDisplayValues } from '@utils/dashboard/on-site-converion';

import { useQuery } from 'react-query';

import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import { parseAPIInterceptionsDonutValues, eventColors } from '@utils/dashboard/on-site-converion';

import { Divider, Table, TableRow, TableCell, TableBody, TableHead, TableContainer } from '@material-ui/core';

import styles from './api-interceptions.module.scss';
import { useContentManager } from '@hooks';

const ApiInterceptions = ({ options, isSettingsLoading }) => {
    const cm = useContentManager();
    const { isLoading, data = {}, isError } = useQuery([RTIAPIkeys.API_INTERCEPTIONS, options], () => getApiInterceptions(options));
    const { urls = [], summary = {}, events = [] } = data;

    const [topURL] = urls;

    const donutValues = useMemo(() => parseAPIInterceptionsDonutValues(events, cm, eventColors), [events]);

    return (
        <DashboardItem isError={isError} isLoading={isLoading || isSettingsLoading} title={cm.get('ApiIntegration')} data-test-selector="rti-api-interceptions">
            <div className={styles.content}>
                <div className={styles.summary}>
                    <div className={styles.title}>
                        <span>{cm.get('Summary')}</span>
                    </div>
                    <div className={styles.interceptionsDataRow}>
                        <span className={styles.value}>{summary?.total?.toLocaleString() || 0}</span>
                        <div className={styles.legend}>
                            <div className={styles.legendTitle}>{cm.get('APICalls')}</div>
                            <ToolTip content={cm.get('APICallsDescription')} title={cm.get('APICalls')}>
                                <InfoIcon className={styles.icon} cursor="pointer" />
                            </ToolTip>
                        </div>
                    </div>
                    <div className={styles.interceptionsDataRow}>
                        <span className={styles.value}>{summary?.invalid?.toLocaleString() || 0}</span>
                        <div className={styles.legend}>
                            <span className={styles.legendTitle}>{cm.get('RespondedInvalid')}</span>
                            <ToolTip content={cm.get('InvalidInterceptionsDescription')} title={cm.get('RespondedInvalid')}>
                                <InfoIcon className={styles.icon} cursor="pointer" />
                            </ToolTip>
                        </div>
                    </div>
                    <div className={styles.interceptionsDataRow}>
                        <span className={styles.value}>{summary?.uniqueInvalidVisitors?.toLocaleString() || 0}</span>
                        <div className={styles.legend}>
                            <span className={styles.legendTitle}>{cm.get('UniqueVisitors')}</span>
                            <ToolTip content={cm.get('UniqueVisitorsDescription')} title={cm.get('UniqueVisitors')}>
                                <InfoIcon className={styles.icon} cursor="pointer" />
                            </ToolTip>
                        </div>
                    </div>
                </div>
                <div className={styles.divider}>
                    <Divider orientation="vertical" style={{ background: 'black' }} />
                </div>
                <div className={styles.interceptionsEvents}>
                    <div className={styles.title}>{cm.get('EventsWithTheLargestNumberOfIntereptions')}</div>
                    <div className={styles.content}>
                        <div className={styles.eventsWrapper}>
                            <div className={styles.donut}>
                                <DonutGraph total={summary.invalid} values={donutValues} />
                            </div>
                            <div className={styles.events}>
                                {events?.map((event, i) => (
                                    <div className={styles.event} key={event.name + event.type}>
                                        <LegendColor color={eventColors[i]} />
                                        <ToolTip
                                            content={
                                                event.type !== 'other' && (
                                                    <>
                                                        <div>
                                                            <strong>{cm.get('EventType')}:</strong> {cm.get(eventsDisplayValues[event.type]?.label)}
                                                        </div>
                                                        <div>
                                                            <strong>{cm.get('EventName')}:</strong> {event.name}
                                                        </div>
                                                    </>
                                                )
                                            }
                                        >
                                            <div className={styles.label}>
                                                <div className={styles.type}>{cm.get(eventsDisplayValues[event.type]?.label)}</div>
                                                {event.type !== 'other' && event.name && (
                                                    <>
                                                        <span className={styles.seperator}>/</span>
                                                        <div className={styles.name}>{event.name}</div>
                                                    </>
                                                )}
                                            </div>
                                        </ToolTip>
                                        <div className={styles.values}>{`${formatToK(event.invalid)} (${getPercentage(event.invalid, summary.invalid)}%)`}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.divider}>
                    <Divider orientation="vertical" style={{ background: 'black' }} />
                </div>
                <div className={styles.intercetptionsTable}>
                    <div className={styles.title}>{cm.get('PagesWithTheLargestNumberOfInterceptions')}</div>
                    <TableContainer>
                        <Table dense stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="60%" variant="head">
                                        <div className={styles.cellContent}>
                                            <span>{cm.get('URL')}</span>
                                        </div>
                                    </TableCell>
                                    <TableCell width="30%" align="left" variant="head">
                                        <div className={styles.cellContent}>
                                            <div className={styles.legend}>
                                                <span>{cm.get('Interceptions')}</span>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell width="10%" variant="head">
                                        <div className={styles.cellContent}>{`% ${cm.get('OutOfTotal')} ${formatToK(summary.total)}`}</div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {urls?.map((row, i) => {
                                    return (
                                        <TableRow hover key={row.url}>
                                            <TableCell>
                                                <div className={styles.url}>
                                                    <OverflowTip>{row.url}</OverflowTip>
                                                </div>
                                            </TableCell>
                                            <TableCell align="left" className={styles.invalidPerPolicyVSTotal}>
                                                <div style={{ width: '100%' }}>
                                                    <div className={styles.bar} style={{ width: `${getPercentage(row.invalid, topURL.invalid)}%` }} />
                                                </div>
                                                <div className={styles.value}>
                                                    <span className={styles.total}>{row.invalid.toLocaleString()}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>{getPercentage(row.invalid, summary.total)}%</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </DashboardItem>
    );
};

export default ApiInterceptions;
