import React, { useLayoutEffect } from 'react';
import WordCloud from 'react-d3-cloud';
import Progress from '../progress/index';
import NoData from '../no-data/index';

export default ({
    isLoading,
    id,
    style = {},
    width = 300,
    height = 300,
    colors,
    fontSizeMapper = word => Math.log2(word.value) * 5,
    rotate = word => 0, //word.value % 360,
    RenderEmpty = props => <NoData {...props} />,
    data = [
        { text: 'Hey', value: 1000 },
        { text: 'lol', value: 200 },
        { text: 'first impression', value: 800 },
        { text: 'very cool', value: 1000000 },
        { text: 'duck', value: 10 },
    ],
    font = 'Open Sans',
    ...rest
}) => {
    if (!id) throw Error('WordCloud must have id property');

    useLayoutEffect(() => {
        const container = document.getElementById(id);
        if (container)
            Array.from(container.getElementsByTagName('text')).forEach((e, i) =>
                Object.assign(e.style, {
                    fill: colors[i % colors.length],
                })
            );
    }, [data.length]);

    return (
        <div id={id} style={{ textAlign: 'center', ...style, width, height }} {...rest}>
            {isLoading ? (
                <Progress style={{ opacity: 0.5, marginTop: 140 }} />
            ) : (
                <>
                    {!data.length ? (
                        <RenderEmpty height={350} />
                    ) : (
                        <WordCloud data={data} font={font} colors={colors} width={width} height={height} fontSizeMapper={fontSizeMapper} rotate={rotate} />
                    )}
                </>
            )}
        </div>
    );
};
