import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStitches } from '@stitches/react';

const { styled } = createStitches();

export const InputValue = styled('div', {
    alignItems: 'center',
    marginLeft: '0.3rem',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexGrow: 1,
    width: '90%',
    variants: {
        placeHolder: {
            true: {
                color: 'rgba(255, 255, 255, 0.2)',
            },
        },
        labelWidthStyle: {
            extended: {
                maxWidth: '11.5vw',
            },
            normal: {
                maxWidth: '9.5vw',
            },
        },
    },
});

export const Select = styled('div', {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    border: '1px solid #FFFFFF',
    padding: '0.4em',
    zIndex: 999999,
    variants: {
        activeStyle: {
            true: {
                border: '1px solid #ea3385',
                '[data-icon="chevron-up"]': {
                    color: '#ea3385',
                },
            },
        },
        borderRadius: {
            narrow: {
                borderRadius: '2px',
            },
        },
        width: {
            narrow: {
                width: '12rem',
            },
            wide: {
                width: '26rem',
            },
        },
        height: {
            narrow: {
                height: '28px',
            },
        },
        readonly: {
            true: {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                pointerEvents: 'none',
                cursor: 'not-allowed',
                [`& ${InputValue}`]: {
                    color: 'rgba(255, 255, 255, 0.2)',
                },
            },
        },
    },
});

export const Input = styled('div', {
    variants: {
        height: {
            narrow: {
                height: '25px',
            },
        },
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '2px',
    height: '2.2rem',
    cursor: 'pointer',
    padding: '0 0.5rem',
    whiteSpace: 'nowrap',
});

export const Label = styled('div', {
    fontSize: '14px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
});

export const Dropdown = styled('div', {
    maxHeight: '42vh',
    padding: '0.4em',
    overflowY: 'scroll',
    position: 'absolute',
    left: 0,
    zIndex: '11',
    backgroundColor: '#282a40',
    top: 'calc(100% - -8px)',
    width: '100%',
    border: 'solid 1px #FFFFFF',
    borderRadius: '2px',
    '&::-webkit-scrollbar': {
        width: '4px',
        display: 'block',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#8F8F8F',
    },
    '&::-webkit-scrollbar:vertical': {
        height: '2px',
    },
});

export const DropDownList = styled('div', {
    padding: '0.3rem 0.5rem',
    display: 'flex',
    flexFlow: 'column',
    gap: '1.1rem',
});

export const SingleDropDownItem = styled('span', {
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    variants: {
        disabled: {
            true: {
                cursor: 'not-allowed',
                color: 'rgba(255, 255, 255, 0.2)',
            },
        },
    },
});

export const SearchBox = styled('div', {
    display: 'flex',
    width: '100%',
    height: '24px',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '2px',
    padding: '3px 7px',
    position: 'relative',

    input: {
        marginLeft: '0.3rem',
        outline: 'none',
        border: 'none',
        width: '100%',
        backgroundColor: 'transparent',
        fontSize: '14px',
        textAlign: 'start',
        color: '#FFFFFF',
        '&::placeholder': {
            fontSize: '14px',
            color: 'rgba(255, 255, 255, 0.2)',
        },
    },
});

export const DropdownArrow = styled(FontAwesomeIcon, {
    variants: {
        readonly: {
            true: {
                color: 'rgba(255, 255, 255, 0.2)',
            },
        },
    },
});
