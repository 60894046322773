import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import CloseViewIcon from '../../../../svgs/close-view/index';
import DesktopIcon from '../../../../svgs/desktop/index';
import MobileIcon from '../../../../svgs/mobile/index';
import ClickIcon from '../../../../svgs/click/index';
import MouseMoveIcon from '../../../../svgs/mousemove/index';
import ScrollIcon from '../../../../svgs/scroll/index';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DatePicker from '../../../../widgets/date-piker/index';
import Progress from '../../../../widgets/progress/index';
import ValidUserIcon from '../../../../svgs/valid-user/index';
import InvalidUserIcon from '../../../../svgs/invalid-user/index';
import { TweenMax } from 'gsap';
import MobileFrame from '../../../../svgs/mobile-frame/index';
import Tooltip from '@material-ui/core/Tooltip';
import TapIcon from '../../../../svgs/tap/index';

const CloseHeatmapContainer = styled.div`
    right: 20px;
    top: 0px;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & .icon {
        padding-top: 8px;
        margin-right: 9px;
    }
    & .label {
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        display: inline-block;
    }
`;
export const CloseHeatmap = ({ onClose }) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <CloseHeatmapContainer onMouseDown={e => e.preventDefault()} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={onClose}>
            <div className="icon" style={{ color: isHover ? '#dcdadb' : '#fe0072' }}>
                <CloseViewIcon />
            </div>
            <div className="label">Close heatmap</div>
        </CloseHeatmapContainer>
    );
};

export const ViewHeatmapHeader = styled.div`
    padding: 0px;
    background-color: rgb(20, 23, 44);
    height: 40px;
    position: relative;
    z-index: 3;
`;

const HeatmapNameContainer = styled.div`
    margin-left: 27px;
    position: relative;
    height: 34px;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;

    & a:link {
        color: inherit;
        text-decoration: none;
    }

    & a:visited {
        color: inherit;
    }

    & a:hover {
        text-decoration: underline;
    }

    & .hostname {
        font-size: 12px;
        position: absolute;
        top: 5px;
        text-decoration: inherit;
    }
    & .pathname {
        position: absolute;
        font-size: 10px;
        color: #aaa8a8;
        text-overflow: ellipsis;
        overflow: hidden;
        top: 20px;
        width: 150px;
        text-decoration: inherit;
    }
`;

export const HeatmapName = ({ name }) => {
    const urlObj = new URL(`http://${name}`);
    return (
        <HeatmapNameContainer>
            <a href={`http://${name}`} target="_blank" rel="noreferrer">
                <div className="hostname">{urlObj.hostname}</div>
                <div className="pathname">{urlObj.pathname}</div>
            </a>
        </HeatmapNameContainer>
    );
};

const useStyle = makeStyles(() => ({
    tooltip: {
        backgroundColor: '#fe0072',
    },
}));

const HeatmapPickerContainer = styled.div`
    color: #fe0072;
    display: inline-block;
    vertical-align: top;
    & > div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    & > .option {
        width: 29px;
        height: 29px;
        margin: 5.5px;
        cursor: pointer;
    }

    & > .option:hover {
        color: white;
    }

    & > .label {
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0.12px;
        color: white;
        height: 40px;
        vertical-align: top;
        margin-right: 8px;
    }
    & > .selected {
        background: #282b3f;
        color: white;
        cursor: auto;
    }
    ${({ marginLeft }) =>
        marginLeft &&
        css`
            margin-left: ${marginLeft}px;
        `}

    & > .disabled {
        cursor: default;
        color: #656262;
    }
    & > .disabled:hover {
        cursor: default;
        color: #656262;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            & > .option:hover {
                color: inherit;
            }

            & > .selected:hover {
                color: white;
            }

            & > .option {
                cursor: default;
            }
        `}
`;

const renderMetricPicker = ({ metricName, tooltipName = metricName, onChange, selected, enabled = [], classes, icon }) => {
    const disabled = !enabled.includes(metricName);
    return (
        <Tooltip classes={classes} placement="bottom" title={tooltipName}>
            <div
                onClick={() => !disabled && selected !== metricName && onChange(metricName)}
                className={'option ' + (!disabled && selected === metricName ? 'selected' : '') + ' ' + (disabled ? 'disabled' : '')}
            >
                {icon}
            </div>
        </Tooltip>
    );
};

export const HeatmapDevicePicker = ({ onChangeDevice, selected, enabled }) => {
    const classes = useStyle();
    return (
        <HeatmapPickerContainer marginLeft={10}>
            <div className="label">Device</div>
            {renderMetricPicker({ metricName: 'desktop', onChange: onChangeDevice, selected, enabled, classes, icon: <DesktopIcon /> })}
            {renderMetricPicker({ metricName: 'mobile', onChange: onChangeDevice, selected, enabled, classes, icon: <MobileIcon /> })}
        </HeatmapPickerContainer>
    );
};

export const HeatmapUserPicker = ({ onChangeValid, selected, enabled }) => {
    const classes = useStyle();
    return (
        <HeatmapPickerContainer marginLeft={28}>
            <div className="label">Users</div>
            {renderMetricPicker({ metricName: 'valid', tooltipName: 'valid users', onChange: onChangeValid, selected, enabled, classes, icon: <ValidUserIcon /> })}
            {renderMetricPicker({ metricName: 'invalid', tooltipName: 'invalid users', onChange: onChangeValid, selected, enabled, classes, icon: <InvalidUserIcon /> })}
        </HeatmapPickerContainer>
    );
};

export const HeatmapEventPicker = ({ onChangeEvent, selected, enabled, deviceType }) => {
    const classes = useStyle();
    return (
        <HeatmapPickerContainer marginLeft={28}>
            <div className="label">Type</div>
            {deviceType === 'desktop' && (
                <>
                    {renderMetricPicker({ metricName: 'click', tooltipName: 'clicks', onChange: onChangeEvent, selected, enabled, classes, icon: <ClickIcon /> })}
                    {renderMetricPicker({ metricName: 'mousemove', tooltipName: 'movements', onChange: onChangeEvent, selected, enabled, classes, icon: <MouseMoveIcon /> })}
                </>
            )}
            {deviceType === 'mobile' && renderMetricPicker({ metricName: 'click', tooltipName: 'taps', onChange: onChangeEvent, selected, enabled, classes, icon: <TapIcon /> })}
            {renderMetricPicker({ metricName: 'scroll', tooltipName: 'scrolls', onChange: onChangeEvent, selected, enabled, classes, icon: <ScrollIcon /> })}
        </HeatmapPickerContainer>
    );
};

const HeatmapDatePickerContainer = styled.div`
    vertical-align: top;
    margin: 6px 0px 6px 32px;
    position: relative;
    display: inline-block;
    position: absolute;
    right: 175px;

    & .input {
        display: inline-flex;
        align-items: center;
        background: #282b3f;
        width: 92px;
        height: 24px;
        cursor: pointer;
        font-size: 12px;
        color: #fe0087;
    }

    & .date {
        margin-left: 8px;
    }

    & .date-picker-container {
        position: absolute;
        top: 34px;
        left: -131px;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            & .input {
                cursor: default;
            }
        `}
`;

const formatDate = date => moment(date).format('YYYY-MM-DD');

export const HeatmapDatePicker = ({ onChangeDate, date, disabled, minDate, maxDate }) => {
    const [isPickingDate, _setIsPickingDate] = useState(false);
    const datePickerRef = useRef(null);
    const isPickingDateRef = useRef(null);

    const setIsPickingDate = value => {
        isPickingDateRef.current = value;
        _setIsPickingDate(value);
    };

    const listenToCloseDatePicker = e => {
        if (isPickingDateRef.current && datePickerRef.current) {
            if (!document.elementsFromPoint(e.clientX, e.clientY).find(element => datePickerRef.current === element)) {
                setIsPickingDate(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('mousedown', listenToCloseDatePicker);
        return () => {
            window.removeEventListener('mousedown', listenToCloseDatePicker);
        };
    }, []);

    return (
        <HeatmapDatePickerContainer disabled={disabled}>
            <div className="input" onClick={() => !disabled && setIsPickingDate(!isPickingDate)}>
                <div className="date">{date.replace(/\-/g, '.')}</div>
                {isPickingDate ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </div>
            {!disabled && isPickingDate && (
                <div className="date-picker-container" ref={datePickerRef}>
                    <DatePicker
                        date={moment(date, 'YYYY-MM-DD').toDate()}
                        onChange={value => {
                            setIsPickingDate(false);
                            if (formatDate(value) !== formatDate(date)) {
                                onChangeDate(formatDate(value));
                            }
                        }}
                        minDate={moment(minDate, 'YYYY-MM-DD').toDate()}
                        maxDate={moment(maxDate, 'YYYY-MM-DD').toDate()}
                    />
                </div>
            )}
        </HeatmapDatePickerContainer>
    );
};

const HeatmapImageContainer = styled.div`
    height: 527px;
    width: 940px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    ${({ locationReady, deviceType }) =>
        locationReady &&
        deviceType === 'desktop' &&
        css`
            overflow-y: scroll;
        `}

    .error {
        font-size: 15px;
        letter-spacing: 1.5px;
    }

    .heatmap-image {
        width: 100%;
        top: 0px;
        position: absolute;
    }

    .mobile-frame {
        width: 256px;
        height: 528px;
        position: absolute;
        z-index: 1;
        top: 0px;
    }

    .mobile-frame svg {
        position: absolute;
        z-index: 2;
    }

    .mobile-screen {
        position: absolute;
        width: 236px;
        top: 41px;
        left: 16px;
        height: 470px;
        overflow-y: scroll;
        border-bottom-right-radius: 33px;
        border-bottom-left-radius: 33px;
        z-index: 3;
    }

    .mobile-top-panel {
        position: absolute;
        height: 25px;
        background: white;
        top: 15px;
        width: 234px;
        left: 16px;
        border-bottom: 1px solid #c1c1c1;
        opacity: 0.8;
        z-index: 1;
    }

    .mobile-scroll-hidder1 {
        width: 20px;
        height: 8px;
        background: black;
        z-index: 9;
        position: absolute;
        bottom: 24px;
        right: -4px;
        transform: rotate(120deg);
    }
    .mobile-scroll-hidder2 {
        width: 20px;
        height: 8px;
        background: black;
        z-index: 9;
        position: absolute;
        bottom: 18px;
        right: -4px;
        transform: rotate(120deg);
    }

    .mobile-scroll-hidder3 {
        width: 10px;
        height: 451px;
        background: black;
        z-index: 9;
        position: absolute;
        top: 41px;
        right: -4px;
    }
`;

export const HeatmapImage = ({ isLoading, isError, location, deviceType }) => {
    const image = useRef(null);

    useEffect(() => {
        if (image.current) {
            TweenMax.to(image.current, 1.5, { css: { opacity: 1 } });
        }
    });

    return (
        <HeatmapImageContainer locationReady={!!location} deviceType={deviceType}>
            {isLoading ? (
                <div>
                    <Progress />
                </div>
            ) : (
                <>
                    {isError ? (
                        <div className="error">{isError}</div>
                    ) : (
                        <>
                            {location && (
                                <>
                                    {deviceType === 'mobile' ? (
                                        <span className="mobile-frame" ref={image} style={{ opacity: 0 }}>
                                            <MobileFrame />
                                            <div className="mobile-top-panel" />
                                            <div className="mobile-screen">
                                                <img className="heatmap-image" src={location} />
                                            </div>
                                            <div className="mobile-scroll-hidder1" />
                                            <div className="mobile-scroll-hidder2" />
                                            <div className="mobile-scroll-hidder3" />
                                        </span>
                                    ) : (
                                        <img ref={image} style={{ opacity: 0 }} className="heatmap-image" src={location} />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </HeatmapImageContainer>
    );
};
