import * as actions from '@redux/actions/dashboard';

import { CqSelect, DarkSeparator, Toggler, ToolTip } from '@widgets';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContentManager, useSplit } from '@hooks';
import DatePicker from '@components/widgets/date-picker';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import { translateDate, policyToggleOffDatesInRange } from '@utils/dashboard';
import { isObject } from 'underscore';
import moment from 'moment';
import styles from './filter.module.scss';
import widgets from '@components/widgets';
import LastUpdated from './LastUpdated';
import { Skeleton } from '@material-ui/lab';
import { useQuery } from 'react-query';
import * as api from '@services/api/dashboard';
import { constants } from '@utils/split.io';
import { setPolicyToggleOptions } from '@redux/actions/dashboard';

const { Button } = widgets;

const Filter = ({ options, handleRefresh, state: defaultState }) => {
    const {
        visiblePlatformsGroups,
        selectedChannel,
        settings: { channels, dateRanges },
    } = useSelector(state => state.dashboard);
    const { isOn: showPolicyToggle } = useSplit(constants.SPLIT_POLICY_MANAGEMENT_TOGGLE);
    const { isOn: policyManagement } = useSplit(constants.SPLIT_POLICY_MANAGEMENT);

    const queryOptions = { ...options, platform: '', platformGroups: visiblePlatformsGroups };
    const { isFetching: isLoading } = useQuery([api.paidMarketingKeys.TOP_PLATFORMS_BY_TOTAL_VISITS, JSON.stringify(queryOptions)], () =>
        api.getTopPlatformsByTotalVisits(queryOptions)
    );

    const dashboardCM = useContentManager(cm => cm.dashboard);
    const dispatch = useDispatch();

    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [filterOptions, updateFilterOptions] = useState(defaultState);
    const [datePickerValue, setDatePickerValue] = useState();

    const {
        settings: { language },
    } = useSelector(state => state.profile.meta);

    const handleChannelSelect = e => {
        const selectedChannelValue = e.target.value;
        const selectedChannel = channels.find(c => c.value === selectedChannelValue);
        handleRefresh('Filter Click', { ...filterOptions, channel: selectedChannel.value, channelName: selectedChannel.label });
        dispatch(actions.setSelectedChannel(selectedChannel));
    };

    const handleRefreshClick = () => {
        handleRefresh('Dashboard Refresh button click', filterOptions);
    };

    const handleOnDateFilterChange = event => {
        if (event.target.value === 'Custom') {
            setDatePickerValue([]);
            setDatePickerOpen(true);
        }
        updateFilterOptions(prevState => ({
            ...prevState,
            dateRange: event.target.value,
        }));
    };

    const handleOnDatePickerChange = date => {
        if (date.length === 2) {
            const startDate = moment(date[0]).format('YYYY-MM-DD HH:mm');
            const endDate = moment(date[1]).format('YYYY-MM-DD HH:mm');
            updateFilterOptions(prevState => ({
                ...prevState,
                dateRange: { startDate, endDate },
            }));
        }
    };

    const handleOnDatePickerClose = date => {
        if (date.length <= 1)
            updateFilterOptions(prevState => ({
                ...prevState,
                dateRange: filterOptions.dateRange,
            }));
        setDatePickerOpen(false);
    };

    const selectChannelsList = useMemo(() => {
        let result = [];
        if (channels && channels.length > 1) {
            result = channels.map(f => {
                if (f.value === 'none') {
                    return { label: `${dashboardCM.get('AllChannels')} (${channels.length - 1})`, value: 'none' };
                }
                return f;
            });
        } else {
            result.push({ label: dashboardCM.get('NoChannels'), value: '' });
        }
        return result;
    }, [channels]);

    const dateRangeParser = (dateRanges, cm) =>
        dateRanges.map(({ label = '', value }) => {
            let labelValue = cm.get(`${label.replace(/ /g, '')}`);
            if (labelValue.startsWith('DashboardV2')) labelValue = labelValue.split('.')[1];
            return {
                value,
                label: labelValue,
            };
        });

    const handleIncludeCrawler = (e, checked) => {
        updateFilterOptions(prevState => ({
            ...prevState,
            includePPCCrawlers: checked,
        }));
    };
    const handleIncludePolicy = (e, checked) => {
        updateFilterOptions(prevState => ({
            ...prevState,
            policyToggleDA: checked,
            policyTogglePM: false,
        }));
        dispatch(setPolicyToggleOptions({ policyToggleDA: checked }));
    };

    useEffect(() => {
        const dateValue = translateDate(filterOptions.dateRange);
        setDatePickerValue(dateValue);
    }, [filterOptions]);

    useEffect(() => {
        defaultState && updateFilterOptions(defaultState);
    }, [defaultState]);
    useEffect(() => {
        updateFilterOptions(prevState => ({
            ...prevState,
            policyManagement,
            includePolicy: showPolicyToggle,
        }));
    }, [policyManagement, showPolicyToggle]);

    const isDirty = JSON.stringify(defaultState) !== JSON.stringify(filterOptions);

    return (
        <div className={styles.component}>
            <div className={styles.filterRow} data-test-selector="filters">
                {isLoading ? (
                    <div style={{ width: '100%' }}>
                        <Skeleton />
                        <Skeleton />
                    </div>
                ) : (
                    <>
                        <div className={styles.content}>
                            <CqSelect
                                data-test-selector="paidmarketing-channels-select"
                                isOutlined={selectedChannel.value !== 'none'}
                                disabled={selectChannelsList && selectChannelsList.length < 2}
                                value={selectedChannel.value}
                                options={selectChannelsList}
                                onChange={handleChannelSelect}
                                className={styles.select}
                            />
                            <DarkSeparator />
                            <span>{dashboardCM.get('ShowingDataFor')}</span>
                            <CqSelect
                                data-test-selector="paidmarketing-date-range-select"
                                value={isObject(filterOptions.dateRange) ? 'Custom' : filterOptions.dateRange}
                                options={dateRangeParser(dateRanges, dashboardCM) || []}
                                onChange={handleOnDateFilterChange}
                                className={styles.select}
                            />
                            <DatePicker
                                open={datePickerOpen}
                                value={datePickerValue || []}
                                locale={language}
                                options={{
                                    onChange: handleOnDatePickerChange,
                                    onClose: handleOnDatePickerClose,
                                }}
                                className={styles.inputContainer}
                            />
                            <LastUpdated update={defaultState} />
                            <DarkSeparator />
                            {filterOptions.includePolicy ? (
                                <Toggler
                                    data-test-selector="paidmarketing-known-bots-toggle"
                                    onChange={handleIncludePolicy}
                                    checked={filterOptions.policyToggleDA}
                                    label={
                                        <>
                                            <span>{dashboardCM.get('IncludePolicy')}</span>
                                            <ToolTip title={dashboardCM.get('IncludePolicy.Title')} content={dashboardCM.get('IncludePolicy.Description')}>
                                                <InfoIcon cursor="pointer" />
                                            </ToolTip>
                                        </>
                                    }
                                />
                            ) : (
                                <Toggler
                                    data-test-selector="paidmarketing-known-bots-toggle"
                                    onChange={handleIncludeCrawler}
                                    checked={filterOptions.includePPCCrawlers}
                                    label={
                                        <>
                                            <span>{dashboardCM.get('CrawlersToggler.Title')}</span>
                                            <ToolTip title={dashboardCM.get('CrawlersToggler.Title')} content={dashboardCM.get('CrawlersToggler.Description')}>
                                                <InfoIcon cursor="pointer" />
                                            </ToolTip>
                                        </>
                                    }
                                />
                            )}
                            {!filterOptions.policyToggleDA && <span className={styles.chosenDates}>{policyToggleOffDatesInRange(datePickerValue, dashboardCM)}</span>}
                        </div>
                        <div data-test-selector="rti-refresh-button" className={styles.button}>
                            <Button disabled={!isDirty} onClick={handleRefreshClick}>
                                {dashboardCM.get('Refresh')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export const getLastUpdatedTimespanText = dashboardCM => {
    const now = new Date();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    return `${dashboardCM.get('Updated')} ${minutes ? minutes : seconds} ${minutes ? dashboardCM.get('MinutesAgo') : dashboardCM.get('SecondsAgo')}`;
};

export default Filter;
