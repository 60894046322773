import {makeStyles} from '@material-ui/core'

export default makeStyles(theme => ({
    valueSelected: {
        color: '#fff !important',
    },
    closeButton: {
        alignSelf: 'flex-end',
        margin: 10,
        marginBottom: 0,
        cursor: 'pointer',
        '& svg': {
            width: 22,
            height: 22,
        }
    },
    containerTitle: {
        fontSize: 24,
        color: '#fff',
        marginBottom: 10,
        width: 352,
        textAlign: 'left',
    },
    periodTitle: {
        color: '#fff',
        marginBottom: 5,
        fontSize: 18,
        width: 352,
        textAlign: 'left',
    },
    container: {
        width: 411,
        height: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.36)'
    },
    submitBtn: {
        marginTop: 15,
        width: 118,
        height: 34,
        backgroundColor: '#383851',
        '& input': {
            fontSize: 24,
            width: '100%',
            height: '100%'
        }
    },
    expansionTitle: {
        fontSize: 24,
        color: theme.palette.custom.text.primary,
        width: '80%',
    },
    expansionPanel: {
        marginTop: '0px !important',
        marginBottom: '10px !important',
        width: '352px !important',
        height: '39px !important',

        backgroundColor: theme.palette.background.component,
        '& .Mui-expanded': {
            backgroundColor: '#0C1021',
        },
    },
    expansionPanelDetails: {
        padding: 0
    },
    summaryContent: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
    },
    expansionPanelSummary: {
        minHeight: '0px !important',
        padding: '0px !important',
        width: '100% !important',
        height: '100% !important',
        '& .MuiExpansionPanelSummary-root': {
            minHeight: 0,
        },

        '& .MuiExpansionPanelSummary-content': {
            alignItems: 'center',
            fontSize: 24.5,
            fontWeight: 100,
            margin: 0,
            width: '100%',
            height: '100%',
            display: 'flex',

        },
    },
    expansionPanelIcon: {
        paddingLeft: 10,
        paddingTop: 7,
        '& svg': {
            color: theme.palette.custom.text.primary,
        }
    },
}))