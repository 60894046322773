import React from 'react';
import CheqUI from '@combotag/cheq-ui';
import widgets from '../../../../widgets/index';
import tableStructure from './table-structure';
import { useContentManager } from '@hooks';
import * as permissions from '../../../../../utils/permissions'
import { useSelector } from 'react-redux';


const { useMeiosis, useActions } = CheqUI.React.Meiosis;
const { Table } = widgets;

const HeatmapsTable = () => {
    const profile = useSelector(state => state.profile)
    const { heatmaps: heatmapActions, profile: profileActions } = useActions();
    const { heatmaps, isLoading, isError, isEnabled, isEnabledLoading } = useMeiosis(heatmapActions.buildStream);
    const isAuthorized = (model, action) =>  permissions.isAuthorized(profile.permissions, model, action);
    const cm = useContentManager();

    return (
        <Table
            rows={heatmaps}
            isLoading={isLoading}
            isError={isError}
            isEnabled={isEnabled}
            toggleEnable={heatmapActions.toggleEnable}
            isEnabledLoading={isEnabledLoading}
            onQueryHeatmap={heatmapActions.queryHeatmap}
            isAuthorized={isAuthorized}
            {...tableStructure(isAuthorized)}
            cm={cm}
        />
    );
};

export default HeatmapsTable;
