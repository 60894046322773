import React, { useEffect, useState } from 'react';
import Button from '../button';

export default ({
    id = 'google-signin',
    clientId = '',
    scope = 'profile email',
    onsuccess = defualtSignIn,
    onfailure = console.error,
    Component = <Button style={{ width: 150 }}>Connect</Button>,
}) => {
    const [auth2, setAuth2] = useState();
    useEffect(() => {
        clientId &&
            setTimeout(() => {
                const { gapi } = window;
                gapi.load('auth2', () => setAuth2(gapi.auth2.init({ client_id: clientId, scope })));
            });
    }, [clientId]);

    return (
        <div
            id={id}
            style={{
                color: 'red',
                cursor: 'pointer',
            }}
            onClick={() => auth2 && auth2.grantOfflineAccess().then(onsuccess).catch(onfailure)}
        >
            {Component}
        </div>
    );
};

function defualtSignIn(googleUser) {
    const profile = googleUser.getBasicProfile();
    const { id_token } = googleUser.getAuthResponse();
}
