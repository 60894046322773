import { SetStateAction, useEffect, useState, Dispatch } from "react"

export default function useFetchMap<T = unknown, K=T>(callback: (p:any) => Promise<T>,params:any[]= [],
    mappingFunction: (t:T)=>K , sortFunction: any = null):
    [K | [], Dispatch<SetStateAction<K | []>>, boolean, any] {
    const [data, setData] = useState<K | []>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<any>()

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const data = (await callback([...params]));
                if (sortFunction && Array.isArray(data)) {
                    data.sort(sortFunction)
                }
                setData(mappingFunction(data))
                setError(null)
            } catch (_err) {
                let e: any = _err;
                setError(e.data);
            } finally {
                setIsLoading(false)
            }
        })()
    }, [])

    return [data, setData, isLoading, error]
}