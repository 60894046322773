import { httpRequest, methods } from '../http';
import { redirectUri } from 'components/containers/management/hubspot/integration';
import { GetHubSpotForms, HubSpotFieldsStatus, HubSpotOAuthStatus, HubSpotUpdatePayload, UpdateHubSpotFormsPayload } from '../../types/hubspot';
/* @ts-ignore */
import config from '@config';

const CHEQ_API_BASE_URL = `${config.baseAPI}/api/v2`;
const HUBSPOT_URL = config.hubspotUrl;

export async function connectHubSpotOAuth(code: string) {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_BASE_URL}/hubspot/v1/oauthIntegration`, method: methods.post, body: { code, redirectUri } });
    return response;
}

export async function disconnectHubSpotOAuth() {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_BASE_URL}/hubspot/v1/invalidate`, method: methods.delete });
    return response;
}

export async function getHubSpotOAuthStatus(): Promise<HubSpotOAuthStatus> {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_BASE_URL}/hubspot/v1/connection`, method: methods.get });
    return response;
}

export async function updateHubSpotFields(payload: HubSpotUpdatePayload) {
    const { response } = httpRequest({ baseURL: `${HUBSPOT_URL}/config/v1/udvConfig`, method: methods.put, body: payload });
    return response;
}

export async function getHubSpotFieldsStatus(): Promise<HubSpotFieldsStatus> {
    const { response } = httpRequest({ baseURL: `${HUBSPOT_URL}/config/v1/udvConfig`, method: methods.get });
    return response;
}

export async function getHubSpotForms(): Promise<GetHubSpotForms> {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_BASE_URL}/hubspot/v1/forms`, method: methods.get });
    return response;
}

export async function updateHubSpotForms(payload: UpdateHubSpotFormsPayload) {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_BASE_URL}/hubspot/v1/forms`, method: methods.put, body: payload });
    return response;
}

export async function generateAuthorizationUrl(): Promise<string | Error> {
    try {
        const { response } = httpRequest({ baseURL: `${HUBSPOT_URL}/oauth/v1/generateAuthorizationUrl`, method: methods.get });
        return response;
    } catch (error) {
        return error instanceof Error ? error : new Error(String(error));
    }
}
