import React from 'react';
import { useSelector } from 'react-redux';
import { DashboardSection } from '@widgets';
import { TopThreatType } from './top-threat-type';
import { PotentialReplacedVisits } from './potential-replaced-visits';
import { isOrganicPlatform } from '@utils/dashboard';
import styles from './style.module.scss';
import { CalculatedReplacedVisits } from './calculated-replaced-visits';
import { BlockedEntities } from './blocked-entities';
import widgets from '@components/widgets';
import { useContentManager } from '@hooks';
import { useQuery } from 'react-query';
import { getBlockedPixelGuardSummary } from '../../../../../services/api';
import * as api from '@services/api/dashboard';
import { useSplit } from '../../../../../hooks';
import { constants } from '../../../../../utils/split.io';
import { BlockedGooglePixels } from './blocked-google-pixels';
// TEMP *don't delete* - OLD PIXEL WIDGET IMPORTS
// import { BlockedPixels } from "./blocked-pixels";
// import { pixelGuard } from '@services/api';

const InvalidVisitsInsights = ({ options }) => {
    const dashboardCM = useContentManager(cm => cm.dashboard);

    const { selectedPlatformsGroup = {} } = useSelector(state => state.dashboard);

    const { isError, isLoading, data: reason = [] } = useQuery([api.paidMarketingKeys.INVALID_VISITS_BY_REASON, options], () => api.getInvalidVisitsByReason(options));

    const { data: blockedEntities = [], isLoading: isBlockedLoading } = useQuery(
        [api.paidMarketingKeys.BLOCKED_ENTITIES, options],
        () => api.getBlockedEntitiesComponent(options),
        { enabled: !options.platform?.toLowerCase().includes('all') }
    );

    const { data: { returnRate, results: calculatedReplacedVisits = [] } = {}, isLoading: isCalcualtedLoading } = useQuery(
        [api.paidMarketingKeys.CALCULATED_REPLACED_VISITS, options],
        () => api.getCalculatedReplacedVisitsComponent(options),
        { enabled: !options.platform?.toLowerCase().includes('all') }
    );

    const { isLoading: isPotentialLoading, data: { potentialTotals = {} } = {} } = useQuery([api.paidMarketingKeys.POTENTIAL_REPLACED_VISITS, options], () =>
        api.getPotentialReplacedVisitsComponent(options)
    );

    const { isOn: isGooglePixelGuardOn } = useSplit(constants.SPLIT_PARADOME_GOOGLE_PIXEL_GUARD);

    const showGooglePixelGuardDashboard = options.platform.includes('GOOGLE');

    const { isLoading: isBlockedGooglePixelsLoading, data: blockedGooglePixels = [] } = useQuery(
        ['GET_GOOGLE_PIXEL_GUARD', options],
        () => getBlockedPixelGuardSummary(options.dateRange, options.platform, options.channel),
        { enabled: showGooglePixelGuardDashboard }
    );

    const { services } = useSelector(state => state.settings);
    let currentService = services.filter(service => options.platform?.toLowerCase().includes(service.service));
    if (currentService.length === 0) {
        if (options.platform?.toLowerCase().includes('youtube')) {
            currentService = services.filter(service => service.service.toLowerCase().includes('google'));
        }
        if (options.platform?.toLowerCase().includes('instagram')) {
            currentService = services.filter(service => service.service.toLowerCase().includes('facebook'));
        }
    }

    const showBlockedGooglePixels =
        isGooglePixelGuardOn && showGooglePixelGuardDashboard && (blockedGooglePixels[0]?.blockedUsersCount || blockedGooglePixels[0]?.totalBlockedEventsCount);
    const showReplacedVisits = !('isAllPlatforms' in selectedPlatformsGroup) && selectedPlatformsGroup?.platforms?.some(platform => !isOrganicPlatform(platform));
    const potentialReplacedVisitsEnabled = showReplacedVisits && !selectedPlatformsGroup.protected;
    const calculatedReplacedVisitsEnabled = showReplacedVisits && selectedPlatformsGroup.protected;
    const calculatedTotals = calculatedReplacedVisits[0] ? calculatedReplacedVisits[0] : { blockedUsers: 0 };
    const blockedEntitiesTotals = blockedEntities[0] ? blockedEntities[0] : {};
    const potentialBlocked = potentialTotals.potential;
    const allInvalid = potentialTotals.allInvalid;
    const blockedUsers = calculatedTotals.blockedUsers;
    const blockedCookies = blockedEntitiesTotals.blockedCookies;
    const blockedIps = blockedEntitiesTotals.blockedIps;
    const blockedCBlocks = blockedEntitiesTotals.blockedCBlocks;
    const isShowCookies = blockedEntitiesTotals.isShowCookies;

    // TEMP *don't delete* - OLD PIXEL WIDGET DATA
    // const { data: pixelSettings = {} } = useQuery(['GET_PIXEL_SETTINGS'], () => pixelGuard.getSettings());
    // let showPixelGuardDashboard = pixelSettings.settings?.length > 0 && pixelSettings.settings[0].platforms.length > 0 &&
    //     pixelSettings.settings[0].platforms.includes(options.platform.toLowerCase());
    // if (pixelSettings.settings?.length > 0 && pixelSettings.settings[0].platforms.length > 0) {
    //     if ((options.platform.toLowerCase().includes('google') || options.platform.toLowerCase().includes('youtube'))
    //         && pixelSettings.settings[0].platforms.includes('google')) {
    //         showPixelGuardDashboard = true
    //     } else if (options.platform.toLowerCase().includes('instagram') && pixelSettings.settings[0].platforms.includes('facebook')) {
    //         showPixelGuardDashboard = true
    //     }
    // }
    //getBlockedPixels
    // const { isLoading: isBlockedPixelsLoading, data: blockedPixels = {} } = useQuery([api.paidMarketingKeys.BLOCKED_PIXELS, options], () =>
    //     api.getBlockedPixels(options), {enabled: showPixelGuardDashboard}
    // );
    // const { isLoading: isLoadingPageViews, data: pageViews = [] } = useQuery([api.paidMarketingKeys.PAGE_VIEWS, options], () => api.getPageViews(options), {enabled: showPixelGuardDashboard});
    // const { isOn: isPixelDashboardOpen } = useSplit(constants.SPLIT_PIXEL_GUARD_DASHBOARD);
    // const showBlockedPixels = isPixelDashboardOpen && showPixelGuardDashboard && blockedPixels.length > 0 && blockedPixels[0].blockedPixels > 0 && blockedPixels[0].blockedUsers > 0

    return (
        <DashboardSection title={dashboardCM.get('InvalidVisitsInsights').toUpperCase()} data-test-selector="invalid visits insights section">
            <div className={styles.invalidVisitsInsightsContainer}>
                <TopThreatType
                    isLoading={isLoading}
                    isError={isError}
                    dashboardCM={dashboardCM}
                    threatsByGroupsAndTrafficType={reason[0]?.threatTypes}
                    className={styles.topThreatTypeContainer}
                    options={options}
                />

                {potentialReplacedVisitsEnabled && false && (
                    <>
                        {!isPotentialLoading ? (
                            <PotentialReplacedVisits dashboardCM={dashboardCM} potentialBlocked={potentialBlocked} allInvalid={allInvalid} />
                        ) : (
                            <widgets.Progress />
                        )}
                    </>
                )}

                {calculatedReplacedVisitsEnabled && (
                    <>
                        <div className={styles.replacedVisitsWrapper}>
                            <BlockedEntities
                                dashboardCM={dashboardCM}
                                blockedCookies={blockedCookies}
                                blockedIps={blockedIps}
                                blockedCBlocks={blockedCBlocks}
                                isShowCookies={isShowCookies}
                                classNames={styles.blockedEntitiesContainer}
                                blockedUsers={blockedUsers}
                                isLoading={isBlockedLoading}
                                options={options}
                                blockingData={currentService}
                            />
                            <CalculatedReplacedVisits
                                isLoading={isCalcualtedLoading}
                                dashboardCM={dashboardCM}
                                blockedUsers={blockedUsers}
                                returnRate={returnRate}
                                options={options}
                                blockingData={currentService}
                            />
                            {showBlockedGooglePixels ? (
                                <BlockedGooglePixels
                                    dashboardCM={dashboardCM}
                                    isLoading={isBlockedGooglePixelsLoading}
                                    title={dashboardCM.get('GooglePixelGuardWidgetBlockedEventsTitle')}
                                    totalBlockedEventsCount={blockedGooglePixels[0]?.totalBlockedEventsCount}
                                    blockedUsersCount={blockedGooglePixels[0]?.blockedUsersCount}
                                />
                            ) : (
                                <></>
                            )}
                            {/* {showBlockedPixels && (
                                <BlockedPixels isLoading={isBlockedPixelsLoading && isLoadingPageViews}
                                    dashboardCM={dashboardCM}
                                    blockedPixels={blockedPixels[0].blockedPixels}
                                    blockedUsers={blockedPixels[0].blockedUsers}
                                    pageViews={pageViews[0]?.total || 0}
                                />
                            )} */}
                        </div>
                    </>
                )}
            </div>
        </DashboardSection>
    );
};

export default InvalidVisitsInsights;
