import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default ({ data = [],
    centerY = 55,
    centerX = 50,
    size = 60,
    legendPosition = 'top',
    height,
    tooltip = false,
    title,
    titleFix = 42,
	titleFontSize = 35,
    series = [{ type: 'pie', innerSize: `${70}%`, data }],
	fixPathOrder = false,
	tooltipFormatter = x => x,
	colors = [{ "linearGradient": {"x1": 0,"x2": 0,"y1": 0,"y2": 1},"stops": [[0,"#9250d5"],[   1,   "#fe0072" ] ] }, '#5545a0'],
    ...props
}) => {

	const [chartOptions,setChartOptions] = useState({
		credits: false,
		chart: {
			backgroundColor: '#383851',
			type: 'pie',
			height : height,
			events: {
				render: function () {
					const { title, legend } = this;
					title.attr({
						y: this.plotTop + this.plotHeight / 2 +
						title.getBBox().height / 2 -
						parseInt(title.styles.fontSize) + titleFix
					});
				},
                load: function() {
                    if(fixPathOrder){
                        var pathToMoveLast = this.renderer.box.querySelectorAll('*[class^="highcharts-point highcharts-color-"]');
                        if(pathToMoveLast && pathToMoveLast.length)
                            Array.from(pathToMoveLast).reverse().forEach((path,i) => path.parentNode.appendChild(path))
                    }
                    // const chart = this;
                    // chart.legend.allItems.forEach((item) => {
                    //     item.legendItem.parentGroup.on('mouseover', function(e) {
                    //     	chart.tooltip.refresh(item)
                    //         chart.renderer.refresh()
					//
                    //     })
					// 		.on('mouseout', function(e) {
                    //             chart.tooltip.hide(item)
                    //             chart.renderer.refresh()
					//
                    //         });
                    // })

                }
			}
		},
		title: {
			verticalAlign: 'middle',
			floating: true,
			text: title,
			style: {
				marginTop: '500px',
				color: 'white',
				fontSize:`${titleFontSize}px`,
			}
		},
		tooltip: {
			enabled: tooltip,
            formatter: tooltipFormatter
		},
		legend: {
			verticalAlign: legendPosition,
			itemStyle: {
				color: '#f1f2f6'
			}
		},
		series : series.map((s,idx)=>({...s, innerSize:'100%'})),
		plotOptions: {
			pie: {
				innerSize: '200%',
				animation : false,
				borderColor:null,
				borderWidth: 30,
				dataLabels: {
					enabled: false,
					distance: -170,
				},
				colors,
				center: [`${centerX}%`, `${centerY}%`],
				size: `${size}%`,
				showInLegend: true
			}
		}
	});

	useEffect(()=>{
		setChartOptions({
			series : series.map((s,idx)=>({...s, innerSize:'100%'})),
			title : {text : title}
		})
	},[data])
    return (
        <div {...props}>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </div>
    )
}

function fakeSeries(n) {
    return Array(n).fill().map(() => parseInt(Math.random() * 100));
}
