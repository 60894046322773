import { users as actions } from '../actions';
import { setChildrenTypes } from '@redux/actions/users';
const usersInitialState = {
    users: [],
    isLoading: false,
    childrenTypes: []
}


export default (state = usersInitialState, action) => {
    switch (action.type) {
        case actions.SET_IS_LOADING: return {...state, isLoading: action.payload};
        case actions.SET_USERS: return {...state, users: action.payload};
        case actions.SET_CHILDREN_TYPE: return {...state, childrenTypes: action.payload || []};
        case actions.REMOVE_USER: return {...state, users: state.users.filter(({id}) =>  id !== action.payload) || []};
        case actions.INVITE_USER: return {...state, users:[...state.users, action.payload]};
        case actions.EDIT_USER: {
            action.payload.userTypeName = action.payload.userType
            return { ...state, users: state.users.map(user => user.id !== action.payload.id ? user : {...user, ...action.payload}) }
        };
        case actions.HANDLE_USER_STATUS: {
            const {id,blocked} = action.payload
            const updatedUsers =  state.users.map(user=>{
                if (user.id === id){
                    return {...user,blocked}
                }
                return user
            })
            return {...state,users:updatedUsers}
        };
        default: return state;
    }
}