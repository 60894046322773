import React, { useRef } from 'react';
import * as Styles from './long-text-with-tooltip.styled';

type LongTextWithTooltipProps = {
    content: string;
    width?: number;
};

export const LongTextWithTooltip: React.FC<LongTextWithTooltipProps> = ({ content }) => {
    const textRef = useRef();

    return (
        <>
            {/* @ts-ignore */}
            <Styles.ElipsedSpan ref={textRef}>{content}</Styles.ElipsedSpan>
        </>
    );
};
