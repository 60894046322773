import { FC, useEffect, ReactNode } from 'react';
// @ts-ignore
import { useSplit } from '@hooks';
import { useLocation } from 'react-router-dom';

type IProps = {
    children: ReactNode;
    path: string;
    featureflagName: string;
};

const Paradome2Redirect: FC<IProps> = ({ path = '', children, featureflagName }) => {
    const location = useLocation();
    const { isOn: featureflagStatus } = useSplit(featureflagName);

    useEffect(() => {
        if (featureflagStatus) {
            window.location.href = `${window.location.origin}${path || location.pathname}`;
        }
    }, [featureflagStatus]);

    return <>{!featureflagStatus && <>{children}</>}</>;
};

export default Paradome2Redirect;