import React, {useState} from "react";
import cx from 'classnames';
import {Chip, TextField} from "@material-ui/core";
import styles from "./affiliates.module.scss";

const PatternMultiValues = ({ onAdd, onDelete, data = [], isFocusedPattern, onFocusPattern, patternRef }) => {
    const [text, setText] = useState('');

    const handleOnAdd = e => {
        e.stopPropagation();
        e.preventDefault();

        const { key } = e;

        if (key === 'Enter') {
            if (!data.includes(text)) {
                onAdd(text);
            }
            setText('');
        }
    };

    const handleOnDelete = (value, i) => () => {
        onDelete(value, i);
    };

    const handleOnBlur = () => {
        if (!data.includes(text)) {
            onAdd(text);
        }
        setText('');
    };

    return (
        <div className={styles.root} onBlur={handleOnBlur}>
            <div className={styles.chips} style={{ alignItems: 'center', width: '100%' }}>
                <span>
                    <TextField
                        ref={patternRef}
                        required
                        fullWidth
                        value={text}
                        variant={isFocusedPattern ? 'outlined' : 'filled'}
                        margin="dense"
                        data-test-selector={'affiliates-add-pattern-input'}
                        placeholder={'utm_medium=affiliate'}
                        classes={{ root: styles.inputRoot }}
                        onChange={e => setText(e.target.value)}
                        InputProps={{
                            onFocus: onFocusPattern,
                            disableUnderline: !isFocusedPattern,
                            readOnly: !isFocusedPattern,
                            classes: { inputMarginDense: cx(styles.inputMarginDense, { [styles.pointer]: !isFocusedPattern }) },
                            margin: 'dense',
                        }}
                        onKeyUp={handleOnAdd}
                    />
                </span>
                {data.map((value, i) => (
                    <Chip key={value + i}
                          label={value}
                          style={{ margin: '1px', fontSize:14, fontFamily: 'inherit', color: '#fff' }}
                          onDelete={handleOnDelete(value, i)} size="small"
                    />
                ))}
            </div>
        </div>
    );
}

export default PatternMultiValues;