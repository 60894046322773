import config from '@config';
import { httpRequest } from './http';
import { methods } from './helpers';
import { getSubDomain } from '@utils/common';

httpRequest.setDefaultHeader({ method: 'post', key: 'Content-Type', value: 'application/json' });
httpRequest.setDefaultHeader({ method: 'put', key: 'Content-Type', value: 'application/json' });

httpRequest.setDefaultHeader({ key: 'Accept', value: 'application/json' });
httpRequest.setDefaultHeader({ key: 'X-CHEQ-LOCATION', value: getSubDomain() });
httpRequest.setDefaultHeader({ key: 'Authorization', value: `Bearer ${localStorage.getItem('auth0Token')}` });

httpRequest.withCredentials = true;
httpRequest.baseURL = config.baseAPI;

export { httpRequest, methods };
