import React from 'react';

const MobileIcon = () =>
	<>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
			<path stroke="currentColor" style={{fill: 'none', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round'}} d="M7,2h10c1.1,0,2,0.9,2,2v16c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V4C5,2.9,5.9,2,7,2z"/>
			<line stroke="currentColor" style={{fill: 'none', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round'}} x1="12" y1="18" x2="12" y2="18"/>
		</svg>
	</>;

export default MobileIcon;


