import { parseDate, dateRange, convertDateToUnix } from '@utils/common';

import { dashboard as actions } from '../actions';

const initialState = {
    settings: {},
    filterOptions: {
        dateRange: {
            value: dateRange[0].value,
            format: parseDate(dateRange[0].details),
            unix: convertDateToUnix(parseDate(dateRange[0].details)),
        },
        includePolicy: false,
        policyManagement: false,
        policyToggleOSC: true,
    },
    isLoading: false,
    err: null,
};

export const rtiDashboard = (state = initialState, action) => {
    switch (action.type) {
        case actions.RTI_SET_FILTER: {
            const { payload: filterOptions } = action;
            return { ...state, filterOptions:{...state.filterOptions, ...filterOptions} };
        }
        case actions.SET_RTI_SETTINGS: {
            const { payload } = action;
            return { ...state, settings: { ...payload } };
        }
        default:
            return state;
    }
};
