import { httpRequest, methods } from '../http';

export type NetworkSettings = {
    isGaConnectorEnabled: 0 | 1;
    isAffiliateFraudEnabled: 0 | 1;
    pxgVersion: 0 | 1;
    dedicatedWLDomains: string;
    industryId?: number;
};

export type DedicatedWLDomainData = {
    id: number;
    cdn: string;
    cdnPath: string;
    dataLocation: 0 | 1;
    domain: string;
    locked: 0 | 1;
    server: string;
    status: 0 | 1;
    tainted: string;
};

export type DedicatedWLDomains = {
    dedicatedWLDomainData: DedicatedWLDomainData[];
};

export default class NetworksService {
    static getSettings = async () => {
        const { response }: { response: Promise<NetworkSettings> } = httpRequest({ method: methods.get, pathname: 'api/v2/network/get_settings' });
        return await response;
    };

    static getDedicatedWLDomains = async () => {
        const { response }: { response: Promise<DedicatedWLDomains> } = httpRequest({ method: methods.get, pathname: 'api/v2/wld/get_dedicated_wlDomains' });
        return await response;
    };
}
