import React from 'react';
import styles from './style.module.scss';
import { formatToK } from '@utils/dashboard';
import { DashboardItem } from '@widgets';

export const BlockedGooglePixels = ({ dashboardCM, isLoading, title, blockedUsersCount, totalBlockedEventsCount }) => {
    return (
        <DashboardItem
            isLoading={isLoading}
            data-test-selector="blocked google pixels"
            className={styles.calculatedReplacedVisitsContainer}
            title={<div className={styles.title}>{title}</div>}
            info={{ title, content: dashboardCM.get('GooglePixelGuardWidgetToolTip') }}
        >
            <div className={styles.content}>
                <div className={styles.replacedVisitsRow}>
                    <span className={styles.blockedUsersValue}>{formatToK(totalBlockedEventsCount)}</span>
                    &nbsp; &nbsp;
                    <span>{dashboardCM.get('GooglePixelGuardWidgetTotalBlockedEventsTitle')}</span>
                </div>
                <div className={styles.replacedVisitsRow}>
                    <span className={styles.blockedUsersValue}>{formatToK(blockedUsersCount)}</span>
                    &nbsp; &nbsp;
                    <span>{dashboardCM.get('GooglePixelGuardWidgetTotalBlockedUsersTitle')}</span>
                </div>
            </div>
        </DashboardItem>
    );
};
