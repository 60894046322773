import bcrypt from 'bcryptjs';

export const generatePassword = (str: string = '') => bcrypt.hashSync(str, 10);

export const formatErrorMessage = (err: string, cm: any) => {
    let error = ""

    switch (true) {
        case err.includes("already exists"):
            error = cm.get("UserAlreadyExistsError")
            break;
        case err.includes("String is too long"):
            error = cm.get("MaximumLengthError")
            break;
        case err.includes("validation for format email"):
            error = cm.get("InvalidCharactersError")
            break;
        default:
            error = cm.get("SomethingWentWrong")
            break;
    }
    return error;
}
