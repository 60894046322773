import React from "react";
import arrow from '../../../assets/arrow.svg';
import arrow_up from '../../../assets/arrow_up.svg';
import styles from './tableHeader.module.scss';

export default ({ headCells, order, orderBy, sortHandler }) => {

    const showArrow = (id) => {
        return id !== 'download' && id !== 'options'
    }

    let className = 'headCell';
    if (headCells[6].id === 'options') {
        className = 'scheduleHeadCell';
    }
   return (
       <div className={styles.container} data-test-selector="exportables-table-header-container">
        <div className={styles.wrapper}>
            {headCells.map(headCell => (
                showArrow(headCell.id) ? (
                    <div
                        data-test-selector={`exportables-table-header-${headCell.id}`}
                        key={headCell.id}
                        className={`${styles[className]} ${order === 'desc' && styles.rotate} ${orderBy === headCell.id && styles.pink}`}
                        role="tabpanel"
                        onClick={() => sortHandler(headCell.id)}
                    >
                        {headCell.label}
                        <div className={styles.sortIcons}>
                            {order && (orderBy === headCell.id) ? <img src={arrow_up} alt=""/> : <img src={arrow} alt=""/>}
                        </div>
                    </div>
                ) : (
                    <div key={headCell.id} className={styles.headCell}/>
                )
            ))}
        </div>
    </div>
   )
};