import React, {useEffect, useState} from 'react';
import Button from '../../../../../components/widgets/button';
import CheqUI from '@combotag/cheq-ui';
import ContentManager from '../../../../../services/content-manager';
import styles from './form.module.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const { Input } = CheqUI.React.Widgets;
const ENGLISH_REGEX = /^[A-Za-z0-9.!\\-_ ]+$/;

export default function ({ state, setState, setters, onCancel, onSubmit, name, description = '', isValid, title, id, jsonTags, tagsName }) {
    const cm = ContentManager.by('AccountManagement.Form.EditTag');
    const [nameError, setNameError] = useState(false);
    useEffect(() => {
        setState(s => ({ ...s, name, description }));
    }, [description, name, setState]);

    const isChange = (stateName, stateDescription) => stateDescription !== description || name !== stateName;

    const onClickSubmit = () => {
        if (!ENGLISH_REGEX.test(state.name)) {
            setNameError(true)
            return;
        }
        isChange(state.name, state.description) && onSubmit({ name: state.name, description: state.description, id, jsonTags });
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.title}>{title}</div>
                <HighlightOffIcon onClick={onCancel} className={styles.closeIcon} />
            </div>

            <div className={styles.inputItem}>
                <Input
                    styles={{ input: `${styles.input} ${nameError && styles.error}`, container: styles.inputContainer }}
                    placeholder={cm.get('TagName')}
                    onChange={setters.setName}
                    value={state.name}
                    defaultValue={name}
                />
                {nameError && <div className={styles.error}>Name must be letters, numbers and spaces</div>}
            </div>

            <textarea
                className={styles.descriptionInput}
                placeholder={cm.get('Description')}
                onChange={e => setters.setDescription(e.target.value)}
                value={state.description}
                defaultValue={description}
            />

            <div className={styles.buttonsContainer}>
                <div className={styles.button}>
                    <Button onClick={() => onClickSubmit()}>{cm.get('SAVE')}</Button>
                </div>
                <div className={styles.button}>
                    <Button onClick={onCancel}>{cm.get('CANCEL')}</Button>
                </div>
            </div>
        </div>
    );
}
