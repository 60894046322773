import React from 'react';
import styled, { css } from 'styled-components';
import Switch from '../../../../widgets/switch/index';

const Container = styled.div`
    font-size: 14px;
    & label {
        margin-right: 0px;
    }
    & .MuiSwitch-track::after {
        font-size: 11px;
        font-weight: bold;
    }
    ${({ marginTop = 0 }) => css`
        margin-top: ${marginTop}px;
    `}
`;

export const HeatmapEnableSwitch = ({ isEnabled, isEnabledLoading, toggleEnable, marginTop, fontSize = 14, progressContainerStyle }) => (
    <Container marginTop={marginTop} isEnabledLoading={isEnabledLoading}>
        <span style={{ marginRight: 10, fontSize, color: 'white' }}>Heatmap Recording</span>
        <Switch
            type="checkbox"
            checkedLabel={'On'}
            uncheckedLabel={'Off'}
            checked={isEnabled}
            value={'isHeatmapsEnabled'}
            onChange={() => toggleEnable({ toEnable: !isEnabled })}
            style={{ marginRight: 0 }}
            disabled={isEnabledLoading}
            isLoading={isEnabledLoading}
            progressContainerStyle={progressContainerStyle}
        />
    </Container>
);
