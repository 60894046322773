import Checkbox from '../../../../../widgets/checkbox';
import { IconWrapper } from '../../../../../widgets/icon-wrapper/index';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NoData from '../../../../../widgets/no-data/index';
import React from 'react';
import Table from '../../../../../widgets/table/index';
import styles from './style.module.scss';
import { useContentManager, useSplit } from '@hooks';
import Tooltip from '@components/widgets/tooltip';
import { constants as splitConstants } from '@utils/split.io';
import { useGetCampaigns } from '../../../../../../services/api/google';

const CampaignsTable = ({ toggleProtectCampaign, protectedCampaigns, selectedAccountId, onToggleCampaignsProtectAll, googleCustomers }) => {
    const { data: { campaigns = [] } = {}, isLoading, isError } = useGetCampaigns(selectedAccountId);
    const cm = useContentManager();
    const { isOn: isGoogleV2On } = useSplit(splitConstants.PARADOME_GOOGLE_V2);

    const isClientProtected = googleCustomers.some(gc => gc.isProtected && gc.clientId === selectedAccountId);
    const clientProtectedCampaigns = protectedCampaigns ? protectedCampaigns[selectedAccountId] : null;
    const areAllCampaignsProtected = isClientProtected && (!clientProtectedCampaigns || clientProtectedCampaigns.length === campaigns.length);

    return (
        <Table
            columns={['name', 'id', 'advertisingChannelType', 'actions']}
            RenderEmpty={() => (
                <NoData lineHeight={40} style={{ paddingTop: 80 }}>
                    <div>NO CAMPAIGNS WERE FOUND</div>
                </NoData>
            )}
            RenderHead={({ column }) => {
                switch (column.id) {
                    case 'name':
                        return <div>Campaign Name</div>;
                    case 'id':
                        return <div>Campaign ID</div>;
                    case 'advertisingChannelType':
                        return (
                            <div style={{ lineHeight: '15px' }}>
                                Adv.
                                <br />
                                Channel Type
                            </div>
                        );
                    case 'actions': {
                        const protectedCampaignsCount = isClientProtected ? protectedCampaigns[selectedAccountId]?.length || campaigns?.length : 0;

                        return (
                            <div className={styles['protected-header-cell-label']} style={{ lineHeight: '15px' }}>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <Checkbox checked={areAllCampaignsProtected} onClick={onToggleCampaignsProtectAll(selectedAccountId, areAllCampaignsProtected)} />
                                    </div>
                                    <div>
                                        <div>Protected</div>
                                        <div>
                                            <span>{protectedCampaignsCount}</span> / {campaigns.length}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }

                    default:
                        return <div style={{ width: column.width }} />;
                }
            }}
            RenderBody={({ column, row }) => {
                const cellValue = row[column.id];
                switch (column.id) {
                    case 'actions': {
                        const clientProtectedCampaigns = protectedCampaigns[row.clientId];
                        const isCampaignProtected =
                            isClientProtected &&
                            (!clientProtectedCampaigns || clientProtectedCampaigns.includes(row.id) || (clientProtectedCampaigns.includes('LABEL') && row.byLabel));

                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    disabled={protectedCampaigns[row.clientId] && protectedCampaigns[row.clientId].includes('LABEL')}
                                    checked={isCampaignProtected}
                                    onClick={() => toggleProtectCampaign(row.clientId, row.id, !isCampaignProtected, campaigns /*, clientProtectedCampaigns*/)}
                                />
                                {protectedCampaigns[row.clientId] && protectedCampaigns[row.clientId].includes('LABEL') && !isCampaignProtected && (
                                    <IconWrapper
                                        className={styles['info']}
                                        icon={<InfoOutlinedIcon style={{ height: 14, width: 14 }} />}
                                        tooltip={`In order to apply protection, you'll need to add a 'CHEQ' label for this campaign on your Google account`}
                                    />
                                )}
                            </div>
                        );
                    }
                    case 'advertisingChannelType':
                        return cellValue[0].toUpperCase() + cellValue.substring(1).toLowerCase();
                    case 'name':
                        return (
                            <Tooltip content={cellValue} key={column.id}>
                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: 'inherit' }}>{cellValue}</div>
                            </Tooltip>
                        );
                    default:
                        return <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: 'inherit' }}>{cellValue}</div>;
                }
            }}
            search={isGoogleV2On ? 'header' : 'footer'}
            pagination={true}
            sortable={false}
            rows={campaigns}
            isLoading={isLoading}
            isError={isError}
            lineaCount={false}
            rowsPerPage={6}
            style={{
                root: { background: 'rgba(20, 23, 44, 0.5)' },
                head: { padding: 0 },
                headerRow: { padding: 0, backgroundColor: '#14172c', height: 45 },
                headerCell: { borderBottom: 'none', color: '#fe0072', fontFamily: 'Open Sans', letterSpacing: 0.12, fontSize: 12 },
                oddRow: { backgroundColor: '#21223c', height: 41.5 },
                row: { backgroundColor: '#383851', height: 41.5 },
                bodyCell: { border: 'none', fontSize: 12, padding: 4 },
                pagination: { bottom: 9 },
                tableWrapper: {
                    '& th:nth-child(1)': {
                        width: 170,
                        paddingLeft: 40,
                        paddingRight: 7,
                    },
                    '& td:nth-child(1)': {
                        width: 170,
                        paddingLeft: 40,
                        paddingRight: 7,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        position: 'relative',
                    },
                    '& th:nth-child(2), & td:nth-child(2)': {
                        width: 80,
                        paddingLeft: 0,
                        paddingRight: 7,
                    },
                    '& th:nth-child(3), & td:nth-child(3)': {
                        width: 110,
                        paddingLeft: 0,
                        paddingRight: 7,
                    },
                    '& th:nth-child(4), & td:nth-child(4)': {
                        width: 60,
                        paddingLeft: 0,
                        paddingRight: 7,
                    },
                    '& th:nth-child(5), & td:nth-child(5)': {
                        width: 95,
                        paddingLeft: 0,
                        paddingRight: 7,
                    },
                    '& th:nth-child(6), & td:nth-child(6)': {
                        width: 110,
                        paddingLeft: 0,
                        paddingRight: 7,
                    },
                },
            }}
            cm={cm}
            headCellPadding={'none'}
        />
    );
};

export default CampaignsTable;
