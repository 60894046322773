import { applyMiddleware, createStore } from 'redux';

import api from '../services/api';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createRootReducer } from './reducers';
import thunk from 'redux-thunk';

const getMiddleWare = () => {
    const middleWare = [thunk.withExtraArgument(api)];
    return applyMiddleware(...middleWare);
};

const composeFunction = composeWithDevTools({ name: 'cheq4ppc' });

const rootReducer = createRootReducer();
export const store = createStore(rootReducer, composeFunction(getMiddleWare()));
