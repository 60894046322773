const parsePageViews = (data, platforms) => {
    const parseData = data.reduce(
        (acc, row) => {
            acc.total = acc.total + row.total;
            acc.invalid = acc.invalid + row.invalidClicks;

            if (row.platformOrigin === 'DIRECT' || row.platformOrigin.endsWith('ORGANIC')) {
                acc.organic.total = acc.organic.total + row.total;
                acc.organic.invalid = acc.organic.invalid + row.invalidClicks;
            } else {
                acc.paid.total = acc.paid.total + row.total;
                acc.paid.invalid = acc.paid.invalid + row.invalidClicks;
            }
            return acc;
        },
        {
            total: 0,
            invalid: 0,
            direct: {
                total: 0,
                invalid: 0,
            },
            organic: {
                total: 0,
                invalid: 0,
            },
            paid: {
                total: 0,
                invalid: 0,
            },
        }
    );
    return parseData;
};

const eventsDisplayValues = {
    subscribe: {
        color: '#B029C6',
        label: 'Subscribe',
    },
    form_submission: {
        color: '#4E2DAB',
        label: 'FormSubmission',
    },
    add_payment: {
        color: '#EA3385',
        label: 'AddPayment',
    },
    page_view: {
        color: '#760B88',
        label: 'PageView',
    },
    search: {
        color: '#EA3385',
        label: 'Search',
    },
    add_to_cart: {
        color: '#6936FB',
        label: 'AddToCart',
    },
    start_trail: {
        color: '#E36AA0',
        label: 'StartTrail',
    },
    registration: {
        color: '#EA3385',
        label: 'Registration',
    },
    add_to_wishlist: {
        color: '#4E2DAB',
        label: 'AddToWishlist',
    },
    purchase: {
        color: '#760B88',
        label: 'Purchase',
    },
    page_load: {
        color: '#EA3385',
        label: 'PageLoad',
    },
    custom: {
        color: '#4E2DAB',
        label: 'Custom',
    },
    other: {
        color: '#4E2DAB',
        label: 'Other',
    },
};

const eventColors = ['#EA3385', '#760B88', '#E36AA0', '#4E2DAB', '#6936FB', '#B029C6'];

const parseAPIInterceptionsDonutValues = (events, cm, eventsColors) => {
    return events.map(({ type, invalid: value }, i) => ({
        value,
        label: cm.get(eventsDisplayValues[type]?.label),
        color: eventsColors[i],
    }));
};

export { parsePageViews, parseAPIInterceptionsDonutValues, eventsDisplayValues, eventColors };
