import { makeStyles } from '@material-ui/core';
import React from 'react';

export default makeStyles(theme => ({
    container: {
        width: 900,
        minHeight: 522,
    },
    containerTitle: {
        fontSize: 18,
        padding: 10,
        textAlign: 'center',
        backgroundColor: 'rgb(20, 23, 44)',
    },
    protectionView: {
        backgroundColor: 'rgb(40, 43, 63)',
        position: 'relative',
        textAlign: 'center',
        padding: 15,
        minHeight: 450,
    },
    footer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '1.6rem',
    },
    button: {
        display: 'inline-block',
        margin: 20,
    },
    protectionFeatures: {
        display: 'inline-block',
        marginRight: 17,
        width: 200,
        miHeight: 371,
        textAlign: 'center',
        backgroundColor: '#383851',
    },
    protectionFeaturesHeader: {
        background: '#14172c',
        height: 40,
        fontSize: 12,
        fontFamily: 'Open Sans',
        letterSpacing: '.12px',
        color: '#fe0072',
        lineHeight: '40px',
    },
    protectedAccounts: {
        display: 'inline-block',
        position: 'relative',
        width: 650,
        height: 371,
        verticalAlign: 'top',
    },

    protectionFeaturesItem: {
        minHeight: '49px',
        lineHeight: '49px',
        textAlign: 'left',
        paddingLeft: 20,
        backgroundColor: '#21223c',
        // marginTop: '3px !important',
        '& .MuiExpansionPanelSummary-root': {
            padding: 0,
        },
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
        },
        '& .MuiExpansionPanelDetails-root': {
            padding: 0,
        },
    },
    protectionFeaturesLabel: {
        display: 'inline-block',
        width: 109,
        fontSize: 12,
    },
    protectionFeaturesItemListLabel: {
        fontSize: 10,
        fontFamily: 'Open Sans',
        lineHeight: '15px',
        marginBottom: 5,
        color: '#d8d8d8',
        width: 155,
    },
    protectionFeaturesItemListList: {
        fontSize: 12,

        height: 130,
        width: 150,
        resize: 'none',
        backgroundColor: '#282b3f',
        color: 'white',
        border: '1px solid rgb(20, 23, 44)',
        outline: 0,
    },
    info: {
        marginRight: '10px !important',
    },

    clientAccount: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 40,
        backgroundColor: 'rgb(56, 56, 81)',
        justifyContent: 'space-between',
    },
    accountTextField: {
        width: '100px !important',
        margin: '0px 20px 0px 5px',
        height: 30,
        '& input': {
            border: 'solid 0.5px #14172c',
            width: '100% !important',
            '&:focus': {
                backgroundColor: '#14172c',
            },
        },
    },
    addIcon: {
        position: 'absolute',
        zIndex: 1,
        top: 270,
        height: 40,
        width: 40,
        right: 7,
        color: '#fe0072',
        backgroundColor: '#14172c',
        '&:hover': {
            backgroundColor: '#14170d',
        },
    },
    clientIdInput: {
        backgroundColor: '#14172c',
        border: 'none',
        width: 100,
        height: 18,
        color: '#fe0072',
        '&:focus': {
            outline: 'none',
        },
    },
    pixelIdInput: {
        backgroundColor: '#14172c',
        border: 'none',
        width: 100,
        height: 18,
        color: '#fe0072',
        '&:focus': {
            outline: 'none',
        },
    },
}));
