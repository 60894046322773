import { httpRequest, methods } from '../http';
/* @ts-ignore */
import config from '@config';

const licenseEndpoint = config.licenseServiceBaseAPI;
const cheqAPIEndpoint = config.baseAPI;

class SettingsService {
    static getNetworkFeatures = async networkID => {
        const { response } = httpRequest({ baseURL: `${licenseEndpoint}/${networkID}`, method: methods.get });
        return await response;
    };

    static getNetworkServingDomain = async () => {
        const { response } = httpRequest({ baseURL: `${cheqAPIEndpoint}/settings/get_network_domain`, method: methods.post });
        return await response;
    };

    static setRtiSettings = async body => {
        const { response } = httpRequest({ method: methods.put, body, pathname: 'rti-dashboard/set_rti_settings' });
        return response;
    };

    static getRtiSettings = () => {
        const { response } = httpRequest({ method: methods.get, pathname: 'rti-dashboard/get_rti_settings' });
        return response;
    };
}

export default SettingsService;
