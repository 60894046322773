import React, { useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import Checkbox from '../checkbox/index';
import CheqUI from '@combotag/cheq-ui';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import Fab from '@material-ui/core/Fab';
import { IconWrapper } from '../icon-wrapper/index';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NoData from '../no-data/index';
import Table from '../table/index';
import styles from '../../containers/management/integrators/google/protection-management/style.module.scss';
import { useContentManager } from '@hooks';
import useStyles from './styles';
import widgets from '../index';
import { useSelector } from 'react-redux';
import * as permissions from '@utils/permissions';

const { Meiosis } = CheqUI.React;
const { useActions } = Meiosis;
const { Button, MultiSelectCheckbox, Switch } = widgets;

function renderHead({ column }) {
    switch (column.id) {
        case 'clientId':
            return <div>Pixel Id</div>;
        case 'name':
            return <div>CHEQ Tag</div>;
        case 'pixelId':
            return <div>Pixel Id</div>;
        case 'enable':
            return <div>Protected</div>;
        case 'type':
            return <div>Audience Type</div>;
        case 'actions':
            return <div>Actions</div>;
        default:
            return <div style={{ width: column.width }} />;
    }
}

const RenderBody = ({ column, row, newTag, classes, setNewTag, linkedTags, unlinkedTags, setRows, onAdd, onDelete, serviceName, platformTypes, onDisableTag, isAuthorized }) => {
    if (row.id === 'newTag') {
        const isDisabled =
            (!newTag.clientId && !newTag.pixelId) || !newTag.tagName || (newTag.type === 'yahooCom' && !newTag.projectId) || (serviceName === 'yahoo' && !newTag.type);
        const getTagsOptions = tag => {
            const linkedIds = linkedTags
                .filter(({ codeIdentifiers, ...rest }) => {
                    const { clientId, pixelId, type, projectId } = JSON.parse(codeIdentifiers);
                    return clientId === tag.clientId && pixelId === tag.pixelId && type === tag.type && projectId === tag.projectId;
                })
                .map(({ id }) => id);

            return [...unlinkedTags, ...linkedTags.filter(({ id }) => !linkedIds.includes(id))]
                .map(({ name, id }) => ({ label: name, value: id }))
                .filter(({ value, label }, idx, src) => idx === src.reduce((idxOf, curr, currIdx) => (idx !== -1 && curr.value === value ? currIdx : idxOf), -1))
                .sort((a, b) => (a.label < b.label ? -1 : 1));
        };

        const onInputChange = field => value => {
            setNewTag(t => {
                const projectId = field === 'type' && field !== 'projectId' && value !== 'yahooCom' ? '' : field === 'projectId' ? value : t.projectId;
                if (field === 'tag') {
                    const { label: tagName, value: tagId } = getTagsOptions(value).find(option => option.value === value.tagId) || {};
                    return { ...t, tagName, tagId, projectId };
                }
                const { label: tagName, value: tagId } = getTagsOptions({ ...t, [field]: value }).find(({ value }) => value === t.tagId) || {};
                return { ...t, [field]: value, tagName, tagId, projectId };
            });
        };

        switch (column.id) {
            case 'pixelId':
                return (
                    <input
                        type={'text'}
                        maxLength={'50'}
                        className={classes.clientIdInput}
                        value={newTag.pixelId || ''}
                        onChange={({ target: { value } }) => onInputChange('pixelId')(value)}
                    />
                );

            case 'clientId':
                return (
                    <input
                        type={'text'}
                        maxLength={'50'}
                        onChange={({ target: { value } }) => onInputChange('clientId')(value)}
                        className={classes.clientIdInput}
                        value={newTag.clientId || ''}
                    />
                );
            case 'projectId': {
                const disabled = newTag.type !== 'yahooCom';
                return (
                    <input
                        type={'text'}
                        maxLength={'50'}
                        onChange={({ target: { value } }) => onInputChange('projectId')(value)}
                        className={classes.clientIdInput}
                        value={newTag.projectId || ''}
                        disabled={disabled}
                        style={{ backgroundColor: disabled && '#aaaaaa' }}
                    />
                );
            }
            case 'type':
                return (
                    <MultiSelectCheckbox
                        onChange={({ value, label } = {}) => onInputChange('type')(value)}
                        styles={selectStyles(platformTypes)}
                        value={platformTypes.find(({ value }) => value === newTag.type)}
                        options={platformTypes}
                        placeholder={'Platform Type'}
                    />
                );
            case 'name': {
                return (
                    <MultiSelectCheckbox
                        isDisabled={!newTag.clientId && !newTag.pixelId}
                        onChange={({ value, label } = {}) => onInputChange('tag')({ tagId: value, tagName: label })}
                        styles={selectStyles(platformTypes, !newTag.clientId && !newTag.pixelId)}
                        value={newTag.tagName && { value: newTag.tagId, label: newTag.tagName }}
                        options={getTagsOptions(newTag)}
                        placeholder={'Audience Tags'}
                    />
                );
            }

            case 'actions': {
                return (
                    <div style={{ justifyContent: 'space-between' }}>
                        <DeleteForeverIcon
                            style={{ color: '#fe0072', cursor: 'pointer' }}
                            onClick={() => {
                                setNewTag({});
                                setRows(r => r.filter(({ id }) => id !== 'newTag'));
                            }}
                        />

                        <DoneOutlinedIcon
                            style={{ marginRight: 5, color: isDisabled ? 'gray' : '#fe0072', cursor: isDisabled ? 'default' : 'pointer' }}
                            onClick={() => {
                                if (!isDisabled) {
                                    onAdd({ serviceName, ...newTag });
                                    setNewTag({});
                                    setRows(r => r.filter(({ id }) => id !== 'newTag'));
                                }
                            }}
                        />
                    </div>
                );
            }
            case 'enable': {
                return <Checkbox checked={!!newTag.isProtected} onClick={event => setNewTag(t => ({ ...t, isProtected: event.target.checked }))} />;
            }
            default:
                return <div></div>;
        }
    }
    const cellValue = column.id === 'type' ? (platformTypes.find(({ value }) => value === row[column.id]) || {}).label : row[column.id];
    if (column.id === 'enable') {
        return (
            <Checkbox
                disabled={!isAuthorized('audience', 'write')}
                checked={!!row.isProtected}
                onClick={event => onDisableTag({ ...row, serviceName, isProtected: event.target.checked })}
            />
        );
    }
    if (column.id === 'actions' && row.id !== 'newTag') {
        return (
            <div style={{ justifyContent: 'space-between' }}>
                <DeleteForeverIcon style={{ color: '#fe0072', cursor: 'pointer' }} onClick={e => onDelete({ serviceName, ...row })} />
            </div>
        );
    }

    return <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: 100 }}>{cellValue}</div>;
};

export const ProtectionManager = ({
    serviceName,
    linkedTags = [],
    unlinkedTags = [],
    onDisableTag = console.log,
    onDisable = console.log,
    onAdd = console.log,
    onDelete = console.log,
    isEnabled,
    columns = ['enable', 'clientId', 'name', 'actions'],
    isError,
    RenderHead = renderHead,
    platformTypes,
    isLoading = false,
}) => {
    const actions = useActions();
    const classes = useStyles();
    const cm = useContentManager();
    const profile = useSelector(state => state.profile)
    const [rows, setRows] = useState([]);
    const [newTag, setNewTag] = useState({ type: serviceName });
    const isAuthorized = (model, action) => permissions.isAuthorized(profile.permissions, model, action);

    useEffect(() => {
        setRows(
            linkedTags
                .filter(tag => tag.serviceName === serviceName)
                .map(({ codeIdentifiers = [], isEnabled, ...rest }) => ({ ...rest, ...JSON.parse(codeIdentifiers), isProtected: !!isEnabled }))
        );
    }, [linkedTags, serviceName]);

    const handleOnAddTag = () => {
        setRows(r => [...r, { id: 'newTag' }]);
    };

    return (
        <div className={classes.container}>
            <div className={classes.containerTitle}>PROTECTION MANAGEMENT</div>
            <div className={classes.protectionView}>
                <div>
                    <div className={classes.protectionFeatures}>
                        <div className={classes.protectionFeaturesHeader}>Protection Features</div>
                        <ProtectionFeature
                            classes={classes}
                            title={'Audience Tag'}
                            tooltip={'Cheq will protect your campaigns with detargeting fraudulent users.'}
                            isEnabled={isEnabled}
                            onChange={isEnabled => onDisable({ serviceName, isEnabled })}
                        />
                    </div>
                    <div className={classes.protectedAccounts}>
                        {!isAuthorized('audience', 'write') ? (
                            <></>
                        ) : (
                            <Fab onClick={handleOnAddTag} disabled={!!rows.find(row => row.id === 'newTag')} className={classes.addIcon} color="primary" aria-label="add">
                                <AddIcon />
                            </Fab>
                        )}
                        <Table
                            name={serviceName}
                            columns={isAuthorized('audience', 'write') ? columns : columns.filter(col => col !== 'actions')}
                            RenderEmpty={() => (
                                <NoData lineHeight={40} style={{ paddingTop: 80 }}>
                                    <div>NO ACCOUNT WAS LINKED</div>
                                </NoData>
                            )}
                            RenderHead={RenderHead}
                            RenderBody={RenderBody}
                            search={'footer'}
                            pagination={true}
                            sortable={false}
                            rows={rows}
                            isLoading={isLoading}
                            isError={isError}
                            isLastPageByDefault={true}
                            lineaCount={false}
                            rowsPerPage={6}
                            style={tableStyles}
                            headCellPadding={'none'}
                            isAuthorized={isAuthorized}
                            cm={cm}
                            {...{ newTag, classes, setNewTag, unlinkedTags, setRows, onAdd, platformTypes, serviceName, onDisableTag, onDelete, linkedTags }}
                        />
                    </div>
                </div>

                <div className={classes.footer}>
                    <Button style={{ width: 105 }} onClick={actions.dialog.hide}>
                        Close
                    </Button>
                </div>
            </div>
        </div>
    );
};

const ProtectionFeature = ({ onChange, classes, title, tooltip = ' ', isEnabled }) => {
    const profile = useSelector(state => state.profile)
    return (
        <div className={classes.protectionFeaturesItem}>
            <div className={classes.protectionFeaturesLabel}>
                <IconWrapper className={classes.info} icon={<InfoOutlinedIcon style={{ height: 14, width: 14 }} />} tooltip={tooltip} />
                {title}
            </div>
            <div className={styles['protection-features-switch']}>
                <Switch
                    disabled={!permissions.isAuthorized(profile.permissions, 'audience', 'write')}
                    type="checkbox"
                    checkedLabel={'On'}
                    uncheckedLabel={'Off'}
                    checked={!!isEnabled}
                    onChange={onChange}
                    style={{ marginRight: 0 }}
                />
            </div>
        </div>
    );
};

const selectStyles = (options = [], isDisabled) => ({
    container: {
        width: 120,
        height: 20,
        border: 'none',
        marginBottom: 0,
        backgroundColor: '#14172c',
    },
    control: {
        backgroundColor: isDisabled ? '#aaaaaa' : '#14172c',
    },
    menu: { width: 130, zIndex: 10 },
    dropdownIndicator: {
        height: 18,
        width: 18,
        color: isDisabled ? '#aaaaaa' : '#fe0072',
    },
    placeholder: {
        color: isDisabled ? '#aaaaaa' : '#fe0072',
    },
});

const tableStyles = {
    root: { background: 'rgba(20, 23, 44, 0.5)' },
    head: { padding: 0 },
    headerRow: { padding: 0, backgroundColor: '#14172c', height: 40 },
    headerCell: { borderBottom: 'none', color: '#fe0072', fontFamily: 'Open Sans', letterSpacing: 0.12, fontSize: 12 },
    oddRow: { backgroundColor: '#21223c', height: 49 },
    row: { backgroundColor: '#383851', height: 49 },
    bodyCell: { border: 'none', fontSize: 12, padding: 7 },
    pagination: { bottom: 9 },
    tableWrapper: {
        height: '330px',
        zIndex: 1,
        /*background:'rgba(20, 23, 44, 0.5)',*/
        '& th': {
            width: '100px !important',
            paddingLeft: 10,
            paddingRight: 7,
        },
        '& td:nth-child(1)': {
            width: '100px !important',
            paddingLeft: 20,
            paddingRight: 7,
            overflow: 'hidden',
            lineHeight: '35px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        '& th:nth-child(2), & td:nth-child(2)': {
            width: '100px !important',
            paddingLeft: 0,
            paddingRight: 7,
        },
        '& th:nth-child(3), & td:nth-child(3)': {
            width: '100px !important',
            paddingLeft: 0,
            paddingRight: 7,
        },
        '& th:nth-child(4), & td:nth-child(4)': {
            width: '100px !important',
            paddingLeft: 0,
            paddingRight: 7,
        },
        '& th:nth-child(5), & td:nth-child(5)': {
            width: '100px !important',
            paddingLeft: 0,
            paddingRight: 7,
        },
    },
};
