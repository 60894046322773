import httpClient from '../ajax';
/* @ts-ignore */
import config from '@config';

const BASE_URL = config.cqAccountsPdBaseAPI;

const post = httpClient({ method: 'post', baseRoute: BASE_URL });
const put = httpClient({ method: 'put', baseRoute: BASE_URL });

export const saveGoogleUniversalPixel = async (pixel: any) => {
    return await put('/tags/google-universal', pixel);
};

export const deleteGoogleUniversalPixel = async (pixel: any) => {
    return await post('/tags/delete-google-universal', pixel);
};
