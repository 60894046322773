const toLocaleString = number => (!isNaN(number) ? Number(number).toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0);
const calcPercentage = (some, total) => toLocaleString((some / total) * 100);
const createTableHeader = columns =>
    Object.entries(columns).map(([label, title]) => {
        return {
            label,
            title,
        };
    });

const sumByKeyAndFields = (keys = [], data = [], fields = [], platforms = [], valuesToRemove = [], channel) => {
    if (!Array.isArray(data)) data = [data];
    return Object.values(
        data.reduce((acc, row) => {
            if (!platforms.includes(row.platformOrigin) || keys.every(key => valuesToRemove.includes(row[key])) || (row.channel !== channel.label && channel.label !== 'none'))
                return acc;
            const tempKey = keys.reduce((acc, key) => acc + row[key], '');
            if (!acc[tempKey]) acc[tempKey] = { ...row };
            else
                fields.forEach(field => {
                    acc[tempKey][field] = +acc[tempKey][field] + +row[field];
                });
            return acc;
        }, {})
    );
};

const parseInvalidUserActivityData = (data = {}, keys = [], platforms = [], channel) => {
    const fieldsToSum = ['invalidClicks', 'total'];
    const valuesToRemove = ['- EMPTY -'];

    data = sumByKeyAndFields(keys, data, fieldsToSum, platforms, valuesToRemove, channel);
    data = data.sort((a, b) => b.invalidClicks - a.invalidClicks);

    return data;
};

const viewAllGroups = {
    campaign: [
        { value: 'source', label: 'Source' },
        { value: 'campaign', label: 'Campaign' },
        { value: 'medium', label: 'Medium' },
        { value: 'platformOrigin', label: 'Platform Origin' },
    ],
    landingPages: [
        { value: 'url', label: 'URL' },
        { value: 'platformOrigin', label: 'Platform Origin' },
    ],
    term: [
        { value: 'keyword', label: 'Keyword' },
        { value: 'platformOrigin', label: 'Platform Origin' },
    ],
    country: [
        { value: 'country', label: 'Country' },
        { value: 'platformOrigin', label: 'Platform Origin' },
    ],
};

export { viewAllGroups, parseInvalidUserActivityData, toLocaleString, calcPercentage, createTableHeader };
