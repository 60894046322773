import mixpanel from 'mixpanel-browser';
import config from '@config';

class Mixpanel {
    init() {
        mixpanel.init(config.mixpanelToken, { debug: false });
    }

    login() {
        const { id, networkId, userRole, email, csmName, seName, account_type, operation_region } = JSON.parse(localStorage.getItem('meta') || '{}');
        const role = userRole === 'user_admin' || userRole === 'user_manager' ? 'Manager' : 'Viewer';
        const isCheqUser = userRole === 'internal_brand_admin';
        mixpanel.identify(id);
        mixpanel.people.set_once({
            'First Login': new Date().toISOString(),
        });
        // USER PROFILE PROPERTIES
        mixpanel.people.set({
            'Last login': new Date().toISOString(),
            $UserID: id,
            $NetworkID: networkId,
            $CheqUserFlag: isCheqUser ? 'Yes' : 'No',
            $Email: isCheqUser ? email : '',
            $AccountSE: seName,
            $AccountCS: csmName,
            $AccountStatus: account_type,
            $OperationRegion: operation_region,
        });
        mixpanel.people.increment('Number of logins');
        // EVENT SUPER PROPERTIES
        mixpanel.register({
            'User ID': id,
            'Network ID': networkId,
            'Cheq user flag': isCheqUser ? 'Yes' : 'No',
            'User role': role,
            Email: isCheqUser ? email : '',
            'Account SE': seName,
            'Account CS': csmName,
            'Account Status': account_type,
            'Operation Region': operation_region,
        });
        mixpanel.track('Login');
    }

    updateProducts() {
        let products = JSON.parse(localStorage.getItem('products')) || [];
        products = products.map(item => item.name);
        mixpanel.people?.set({
            $AccountProducts: products,
        });
        mixpanel.register({
            'Account products': products,
        });
    }

    postEvent(eventName, eventData) {
        mixpanel.track(eventName, eventData);
    }
}

export default new Mixpanel();
