import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '@components/containers/exportables/ui/table';
import NoData from '@components/containers/exportables/ui/no-data';
import TableRow from '@components/containers/exportables/ui/table/table-row/all-reports';
import { useContentManager } from '@hooks';
import { frequencyList, parseFilters, getReportList, getDefaultSelectedFilters } from '../main/utils';
import Main from '@components/containers/exportables/main';
import server from '../server';
import Modal from '@components/containers/exportables/ui/modal';
import config from '@config';
import { useSplit } from '../../../../hooks/use-split';
import { constants as splitConstants } from '../../../../utils/split.io';

const headCells = [
    { id: 'name', label: <span>Name</span> },
    { id: 'type', label: <span>Type</span> },
    { id: 'createdAt', label: <span>Date</span> },
    { id: 'createdBy', label: <span>Created By</span> },
    { id: 'frequency', label: <span>Frequency</span> },
    { id: 'status', label: <span>Status</span> },
    { id: 'download', label: '' },
];

export default () => {
    const [reports, setReports] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [reportData, setReportData] = useState({
        id: '',
        name: '',
        action: '',
    });
    const { selectedLicense } = useSelector(state => state.settings);
    const isAffiliateFraudEnabled = useSelector(state => state.settings.isAffiliateFraudEnabled);
    const cm = useContentManager();

    const { isOn: showComprehensiveDetectionReport } = useSplit(splitConstants.PARADOME_COMPREHENSIVE_DETECTION_REPORT);
    const { isOn: showExtendedResponseClientReport } = useSplit(splitConstants.PARADOME_EXTENDED_RESPONSE_CLIENT_REPORT);
    const { isOn: showExtendedResponseClientForMIS } = useSplit(splitConstants.PARADOME_EXTENDED_RESPONSE_CLIENT_FOR_MIS);
    const { isOn: limitExtendedReportsToOss } = useSplit(splitConstants.PARADOME_EXTENDED_RESPONSE_LIMIT_ONLY_TO_OSS);
    const { isOn: isNewSlpDashboardOn } = useSplit(splitConstants.PARADOME_NEW_SLP_DASHBOARD);

    const finalReportTypes = getReportList(
        selectedLicense,
        isAffiliateFraudEnabled,
        showComprehensiveDetectionReport,
        showExtendedResponseClientReport,
        showExtendedResponseClientForMIS,
        limitExtendedReportsToOss,
        isNewSlpDashboardOn
    );
    const filterFrequencyList = selectedLicense === 1 ? frequencyList.filter(item => item.id === 1) : frequencyList;

    const { networkId, dataLocationId = 1 } = JSON.parse(localStorage.getItem('meta') || '{}');
    const fetchReports = async ({ selected }) => {
        selected = getDefaultSelectedFilters(selected, finalReportTypes, filterFrequencyList);
        let params = parseFilters({ selected });
        if (params.length > 0) params = '?' + params;
        try {
            const skewedUrl = dataLocationId != 2 ? config.skewedAnalyticsReports : config.skewedAnalyticsReportsEU;
            const res = await server.get(`/reports/${networkId}${params}`, skewedUrl);
            setReports(res.data);
        } catch (e) {
            console.log(e && e.message);
        }
    };

    const onDelete = (id, name) => {
        setReportData({
            id,
            name,
            action: 'delete_on_demand',
        });
        setShowModal(true);
    };

    const onDeleteHandler = async () => {
        try {
            const skewedUrl = dataLocationId != 2 ? config.skewedAnalyticsOnDemandReports : config.skewedAnalyticsOnDemandReportsEU;
            await server.delete(`${reportData.id}`, skewedUrl);
            await fetchReports({});
        } catch (e) {
            console.log(e && e.message);
        }
    };

    return (
        <>
            <Main
                body={
                    reports && reports.length === 0 ? (
                        <NoData />
                    ) : (
                        <Table
                            cm={cm}
                            headCells={headCells}
                            reports={reports}
                            TableRow={TableRow}
                            reportMethods={{
                                onDelete,
                            }}
                        />
                    )
                }
                headerLabel="generated reports"
                headerButtonText="New Report"
                totalReports={reports.length}
                reports={reports}
                fetchReports={fetchReports}
            />
            {showModal && <Modal cm={cm} reportData={reportData} onApproveHandler={onDeleteHandler} onClose={() => setShowModal(!showModal)} />}
        </>
    );
};
