import React, { useEffect, useState } from 'react';

import Button from '../../../../widgets/button';
import CheqUI from '@combotag/cheq-ui';
import LinearProgress from '../../../../widgets/liniar-progress/index';
import SimpleTextField from '../../../../widgets/simple-text-field/index';
import { triggerSnackbarMessage } from '../../../../../redux/actions/snackbar';
import { useDispatch } from 'react-redux';

const { Meiosis } = CheqUI.React;
const { useActions, useMeiosis } = Meiosis;

const clientId = process.env.REACT_APP_SSO_CLIENT_ID || '228834305221-vpjpmr8rd5eaprijm5ipv9hliae551hh.apps.googleusercontent.com';

const SubmitGoogleAdsId = ({ next, jumpTo, styles, onCancel }) => {
    const dispatch = useDispatch();
    const actions = useActions();
    const sso = useMeiosis(actions.sso.buildStream);

    const [googleAdsId, setGoogleAdsId] = useState('');
    const [authoring, setAuthoring] = useState(false);
    const googleService = sso.services.google;
    const { inviteGoogleId, getNetwork, skipAdwords } = actions.sso;

    useEffect(() => {
        if (sso.ssos.google) {
            if (sso.ssos.google.status === 'PENDING') {
                next();
            }
            if (sso.ssos.google.status === 'MANAGED' || sso.ssos.google.refresh_token || sso.ssos.google.is_sso || sso.ssos.google.skippedAdwords) {
                jumpTo(2);
            }
        }
    });

    const initGoogleClient = (callback) => {
        if (window.google) {
            const client = window.google.accounts.oauth2.initCodeClient({
                client_id: clientId,
                scope: 'openid email profile https://www.googleapis.com/auth/adwords',
                callback: (tokenResponse) => {
                    callback(tokenResponse.code);
                },
                redirect_uri: process.env.REACT_APP_REDIRECT_URI,
            });

            client.requestCode();
        }
    };

    const getGoogleSsoCode = (callback) => {
        initGoogleClient(callback);
    };

    function initClient(callback) {
        if (window.google) {
            const client = window.google.accounts.oauth2.initCodeClient({
                client_id: clientId,
                scope: 'openid email profile https://www.googleapis.com/auth/adwords',
                callback: (tokenResponse) => {
                    callback(tokenResponse.code);
                },
                redirect_uri: process.env.REACT_APP_REDIRECT_URI,
            });

            client.requestCode();
        }
    }

    const getSsoCode = (callback) => {
        initClient(callback);
    };

    return (
        <div className={styles.content}>
            {(sso.requestInvite || authoring) && (
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            )}
            <div style={{ fontSize: '1.3rem' }}>
                In order to configure your account for automatic IP blocking, please submit your Google Ads account ID below.
                <br />
                It is located on the top right corner of your Google Ads account.
            </div>
            <div
                style={{ position: 'relative', top: 170, textDecoration: 'underline', cursor: 'pointer', color: '#fe0072', width: 250, fontSize: 14 }}
                id={'google-signin'}
                data-test-id={'googleSSOLink'}
                onClick={() => {
                    if (!authoring) {
                        setAuthoring(true);
                        getGoogleSsoCode((code) => {
                            return googleService.connect({ code })
                                .then(() => getNetwork())
                                .then(
                                    ssos =>
                                        !ssos.google.is_sso &&
                                        dispatch(triggerSnackbarMessage({ message: `We're sorry, You need to allow Cheq to manage your AdWords campaigns` }))
                                )
                                .catch(err => {
                                    console.error(err);
                                    dispatch(triggerSnackbarMessage({ message: `We're sorry, something went wrong with the authentication` }));
                                }).then(() => setAuthoring(false));
                        });
                    }
                }}
            >
                Or sign in with Google
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <SimpleTextField
                    disabled={authoring || sso.requestInvite}
                    containerStyle={{ textAlign: 'center', marginTop: 100, display: 'inline-block' }}
                    width={200}
                    height={40}
                    fontSize={19}
                    onChange={value => setGoogleAdsId(value.trim())}
                    value={googleAdsId}
                    placeholder={'123-456-7890'}
                    focusOnMount={true}
                    inputStyle={{ padding: '0 10px' }}
                    error={sso.errorInvite}
                    errorTop={true}
                />

                <div>
                    <img
                        alt="google setup account"
                        src="https://d364debm9gdlqk.cloudfront.net/google-setup/accountid2.png"
                        style={{ marginLeft: 90, width: 400, boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 15px 0px', borderRadius: 8 }}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 'auto', padding: '1.5rem 0' }}>
                <div style={{ textDecoration: 'underline', cursor: 'pointer', color: '#fe0072', zIndex: 10 }} onClick={() => !authoring && !sso.requestInvite && skipAdwords()}>
                    Skip to Audience blocking
                </div>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ width: 150 }}>
                        <Button onClick={onCancel} isLoading={sso.requestInvite || authoring}>
                            CANCEL
                        </Button>
                    </div>
                    <div style={{ width: 300 }}>
                        <Button
                            disabled={googleAdsId === ''}
                            isLoading={sso.requestInvite || authoring}
                            onClick={() => inviteGoogleId({ id: googleAdsId.replace(/-/g, '') }, dispatch)}
                            onEnter
                        >
                            SUBMIT GOOGLE ADS ID
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmitGoogleAdsId;
