import React, { useState, useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import countryList from 'country-list';
import { useQuery } from 'react-query';
import {getInvalidVisitsByCountries, paidMarketingKeys} from '@services/api/dashboard';
import { ScaleBar, DashboardItem, OverflowTip } from '@widgets';
import { calcPercentage, toLocaleString, parseInvalidUserActivityData } from '@utils/dashboard';
import { Table, TableRow, TableCell, TableHead, TableBody, TableContainer } from '@material-ui/core';
import styles from './countries.module.scss';
import { HeatMap } from '../heat-map';

const countryToLabel = country =>
    ({
        us: 'USA',
        gb: 'UK',
    }[country.toLowerCase()] || country);

const Countries = ({ limit = 10, selectedChannel, selectedPlatformsGroup, options, cm: dashboardCM }) => {
    const { data, isLoading, isError } = useQuery([paidMarketingKeys.INVALID_VISITS_BY_COUNTRY, options], () => getInvalidVisitsByCountries(options));

    const [columns] = useState({
        country: dashboardCM.get('Country'),
        invalidClicks: dashboardCM.get('InvalidVisits'),
        ofAllVisits: `% ${dashboardCM.get('OfAllVisits')}`,
    });

    const columnWidth = {
        country: 40,
        invalidClicks: 50,
        ofAllvisits: 10,
    };

    const parsedData = useMemo(() => {
        return parseInvalidUserActivityData(data, ['country'], selectedPlatformsGroup?.platforms, selectedChannel);
    }, [data, selectedPlatformsGroup, selectedChannel]);

    const [topLimit] = parsedData;

    const renderColumns = columns => {
        return (
            <TableRow>
                {Object.entries(columns).map(([key, label]) => (
                    <TableCell width={columnWidth[key] + '%'} key={'country' + key} variant="head">
                        {label}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    const renderRow = (row, index) => {
        const scaleValue = index === 0 ? 100 : calcPercentage(row['invalidClicks'], topLimit['invalidClicks']);
        const percentage = calcPercentage(row['invalidClicks'], row['total']);

        return (
            <TableRow hover key={`pm-country-row-${index}-${row.country}`}>
                <TableCell>
                    <div className={styles.country}>
                        <ReactCountryFlag countryCode={row.country === 'UK' ? 'GB' : row.country} svg style={{ fontSize: '1.8rem' }} />
                        <div className={styles.countryName}>
                            <OverflowTip>
                                {countryList.getName(row.country)?.length < 22 ? countryList.getName(row.country) : row.country === 'Z9' ? 'Unknown' : countryToLabel(row.country)}
                            </OverflowTip>
                        </div>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={styles.invalidClicks}>
                        <ScaleBar width={scaleValue} />
                        {toLocaleString(row.invalidClicks)}
                    </div>
                </TableCell>
                <TableCell>
                    <div className={styles.allVisits}>{percentage ? percentage + '%' : dashboardCM.get('NoDataToDisplay')}</div>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <>
            <DashboardItem
                isLoading={isLoading}
                isError={isError}
                skeletonClassName={styles.skeletonLoader}
                data-test-selector="paid-marketing-countries"
                key="countries"
                className={styles.countries}
                title={dashboardCM.get('Countries.Title')}
                info={{
                    title: dashboardCM.get('Countries.Title'),
                    content: dashboardCM.get('Countries.Description'),
                }}
            >
                <TableContainer style={{ padding: '12px' }}>
                    <Table>
                        <TableHead>{renderColumns(columns)}</TableHead>
                        <TableBody>{parsedData.slice(0, limit).map(renderRow)}</TableBody>
                    </Table>
                </TableContainer>
            </DashboardItem>
            <DashboardItem
                isLoading={isLoading}
                isError={isError}
                skeletonClassName={styles.skeletonLoader}
                data-test-selector="paid-marketing-heatmap"
                key="heatMap"
                className={styles.heatMap}
                title={dashboardCM.get('Heatmap.Title')}
                info={{
                    title: dashboardCM.get('Heatmap.Title'),
                    content: dashboardCM.get('Heatmap.Description'),
                }}
            >
                <HeatMap data={parsedData} dashboardCM={dashboardCM} />
            </DashboardItem>
        </>
    );
};

export {Countries};
