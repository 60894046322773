/**
 * this file is auto generated by chopin-scripts.
 * you can check the project package.json
 * to see how it was generated.
 */

import Accounts from './accounts';
import AccountsV2 from './accounts/indexV2.js';

export default {
    Accounts,
    AccountsV2,
};
