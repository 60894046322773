import React, { useState, useRef, useEffect } from 'react';
import Checkbox from '../../widgets/checkbox';
import Select, { components } from 'react-select';
import styles from './style.module.scss';

const VALUE_LIMIT = 2;

export const MultiSelectCheckbox = ({ onChange, passedValue, placeholder = '', isMulti, options = [], onInputChangeParent = undefined, loading, ...params }) => {
    const containerRef = useRef(null);
    const SELECT_ALL = 'Select All';
    const selectAllLabel = useRef(SELECT_ALL);
    const [searchInput, setSearchInput] = useState('');
    const allOption = { value: '*', label: selectAllLabel.current };
    const [isFocused, setIsFocused] = useState(false);
    const INPUT_CHANGE = 'input-change';
    const ENTER = 'ENTER';
    const [isMenuOpen, setMenuOpen] = useState(false);

    const searchTerm = ({ commaExists, input, label, value, split }) => {
        commaExists = commaExists ? commaExists : input.includes(',');
        split = split && commaExists ? split : input.split(',').map(Number);
        return !commaExists && label ? label.toLowerCase().includes(searchInput?.toLowerCase()) : split.includes(value);
    };
    const filterOptions = (options, input) => {
        const commaExists = input.includes(',');
        let split = [];
        if (commaExists) split = input.split(',').map(Number);
        return options?.filter(({ label, value }) => searchTerm({ commaExists, input, label, value, split }));
    };

    let filteredOptions = filterOptions(options, searchInput);
    let filteredSelectedOptions = filterOptions(passedValue, searchInput);
    const allSelected = filteredSelectedOptions?.length && filteredSelectedOptions?.length === filteredOptions?.length;
    const customFilterOption = ({ value, label }, input) => {
        return (value !== allOption.value && searchTerm({ value, input, label })) || (value === allOption.value && filteredOptions?.length > 0);
    };
    const onKeyDown = e => {
        if ((e.key === ' ' || e.key === ENTER) && !searchInput) e.preventDefault();
    };
    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsFocused(false);
            setMenuOpen(false);
            setSearchInput('');
            onInputChangeParent('');
        }
    };
    const { ref } = useComponentVisible(true, handleClickOutside, () => {});

    const onInputChange = (inputValue, event) => {
        if (event.action === INPUT_CHANGE) {
            setSearchInput(inputValue);
            if (onInputChangeParent) onInputChangeParent(inputValue);
        }
    };
    const handleChange = (newValue, actionMeta) => {
        const commaExists = searchInput.includes(',');
        const allSelected = filteredSelectedOptions?.length === filteredOptions?.length;
        const split = commaExists ? searchInput.split(',').map(Number) : [];
        if (actionMeta.option?.value == allOption.value) {
            if (!allSelected) {
                const selectedOp = [
                    ...(passedValue ?? []),
                    ...options.filter(
                        ({ label, value }) =>
                            searchTerm({ commaExists, input: searchInput, label, value, split }) && (passedValue ?? []).filter(opt => opt.label === label).length === 0
                    ),
                ];
                return onChange(selectedOp);
            } else {
                return onChange([]);
            }
        }
        return onChange(newValue);
    };

    return (
        <div className={styles.multiSelectWrapper} ref={ref}>
            <Select
                ref={containerRef}
                value={passedValue}
                inputValue={searchInput}
                onInputChange={onInputChange}
                onKeyDown={onKeyDown}
                options={options.length !== 0 ? [allOption, ...options] : []}
                onChange={handleChange}
                components={{
                    MultiValue: MultiValue,
                    Option: CustomOption(allSelected),
                    Input: Input(searchInput.length === 0),
                    MenuList: CustomMenuList,
                }}
                filterOption={customFilterOption}
                styles={customStyles(styles)}
                isMulti={true}
                isSearchable={false}
                closeMenuOnSelect={false}
                tabSelectsValue={false}
                backspaceRemovesValue={false}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
                isLoading={loading}
                onMenuInputFocus={() => {
                    setIsFocused(true);
                    setMenuOpen(true);
                }}
                onMenuClose={() => {
                    setMenuOpen(!isMenuOpen);
                }}
                {...{
                    menuIsOpen: isMenuOpen || undefined,
                    isFocused: isFocused || undefined,
                }}
            />
        </div>
    );
};

export default MultiSelectCheckbox;

const useComponentVisible = (initialIsVisible, handleClickOutsideCB, handleHideDropdownCB) => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = event => {
        handleHideDropdownCB(event);
    };

    const handleClickOutside = event => {
        handleClickOutsideCB(event);
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
};

const allOption = { value: '*', label: 'Select all' };

export const CustomMenuList = props => {
    const { onInputChange, inputValue, onMenuInputFocus } = props.selectProps;
    return (
        <div className={styles.customMenuList}>
            <input
                className={styles['search-input']}
                autoFocus={true}
                type="text"
                value={inputValue}
                onChange={e => {
                    onInputChange(e.currentTarget.value, {
                        action: 'input-change',
                        prevInputValue: '',
                    });
                    e.target.focus();
                }}
                onMouseDown={e => {
                    e.stopPropagation();

                    e.target.focus();
                }}
                onTouchEnd={e => {
                    e.stopPropagation();
                    e.target.focus();
                }}
                onFocus={onMenuInputFocus}
                placeholder="Search..."
            />
            <components.MenuList style={{ overflowY: 'none', width: 50 }} {...props} />
        </div>
    );
};

export const MultiValue = props => {
    const { index, getValue } = props;
    const hiddenLength = getValue().length - VALUE_LIMIT;

    return index < VALUE_LIMIT ? <components.MultiValue {...props} /> : index === VALUE_LIMIT ? <div className={styles.multiSelectSelected}>{`+${hiddenLength}`}</div> : null;
};

export const Input = inputEmpty => props =>
    (
        <>
            {inputEmpty ? (
                <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                    {props.children}
                </components.Input>
            ) : (
                <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                    {props.children}
                </components.Input>
            )}
        </>
    );

export const CustomOption = allSelected => props => {
    return (
        <components.Option {...props}>
            {props.selectProps.inputValue === allOption.value ? (
                <Checkbox
                    style={{ color: 'white !important', display: 'flex', gap: '6px', height: '18px' }}
                    className={styles.checkmark}
                    checked={allSelected}
                    label={props.label}
                    key={props.selectProps.inputValue}
                    onChange={() => {}}
                />
            ) : (
                <Checkbox
                    style={{ color: 'white !important', display: 'flex', gap: '6px', height: '18px' }}
                    className={styles.checkmark}
                    label={props.label}
                    key={props.selectProps.inputValue}
                    checked={props.isSelected || allSelected}
                    onChange={() => {}}
                />
            )}
        </components.Option>
    );
};

export function customStyles(styles = {}) {
    return {
        container: base => ({
            ...base,
            flex: 1,
            borderColor: '$white',
        }),
        control: base => ({
            ...base,
            backgroundColor: '#272a3d',
            maxHeight: '65px',
            borderRadius: 0,
            minHeight: '30px',
        }),
        valueContainer: base => ({
            ...base,
            maxHeight: '65px',
            minHeight: '30px',
            overflow: 'auto',
            backgroundColor: '#272a3d',
            padding: '0px 8px',
        }),
        option: styles => {
            return {
                ...styles,
                backgroundColor: '#272a3d',
                color: 'white !important',
                borderColor: '$white',
            };
        },
        menu: base => ({
            ...base,
            backgroundColor: '#272a3d',
            border: '1px solid white',
            color: 'white',
        }),
        indicatorsContainer: base => ({
            ...base,
            backgroundColor: '#272a3d',
            padding: 0,
        }),
        input: base => ({
            ...base,
            padding: 0,
            margin: 0,
            backgroundColor: 'transparent',
            ...(styles['search-input'] || {}),
            ...styles,
        }),
        clearIndicator: base => ({
            ...base,
            padding: 0,
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 0,
            border: 0,
        }),
        singleValue: base => ({
            ...base,
            color: 'white',
        }),
        multiValue: base => ({
            ...base,
            width: '150px',
            margin: 0,
            marginRight: 10,
            padding: 0,
            color: '$white',
            backgroundColor: '#525566',
            display: 'flex',
            justifyContent: 'space-between',
        }),
        multiValueLabel: base => ({
            ...base,
            padding: '0px 3px',
            color: 'white',
        }),
    };
}
