import httpClient from '../ajax';
import moment from 'moment';

class RealtimeService {
    endpoint = 'realtime';

    post = httpClient({ baseRoute: this.endpoint });

    get = httpClient({ method: 'get', baseRoute: this.endpoint });

    getUa = date => {
        return this.post('get_ua', { date: moment(date).toISOString() });
    };

    getInvalidIps = date => {
        return this.post('get_invalid_ips', { date: moment(date).toISOString() });
    };

    getBrowsers = date => {
        return this.post('get_browsers', { date: moment(date).toISOString() });
    };

    getDateRanges = date => {
        return this.post('get_date_ranges', { date: moment(date).toISOString() });
    };

    getPlatforms = date => {
        return this.post('get_platforms', { date: moment(date).toISOString() });
    };

    getPlatformsGroups = date => {
        return this.post('get_platforms_groups', { date: moment(date).toISOString() });
    };

    getThreatGroups = () => {
        return this.get('threat_groups');
    };

    getChannels = async date => {
        return this.post('get_channels', { date: moment(date).toISOString() });
    };

    getPotentialReplacedVisitsComponent = async body => {
        const res = await httpClient({ method: 'post' })('dashboard/get_potential_replaced_visits', body);
        const { data } = res;
        return data;
    };

    getCalculatedReplacedVisitsComponent = async body => {
        const res = await httpClient({ method: 'post' })('dashboard/get_calculated_replaced_visits', body);
        const { data } = res;
        return data;
    };

    getBlockedEntitiesComponent = async body => {
        const res = await httpClient({ method: 'post' })('dashboard/get_blocked_entities', body);
        const { data } = res;
        return data;
    };

    getComponents = async (dateRange = 'today', includePPCCrawlers = false) => {
        const postDto = {
            dateRange,
            includePPCCrawlers,
        };

        const response = await Promise.all([this.getComponentsUp(postDto), this.getComponentsDown(postDto)]);
        return response;
    };
}

export const realtimeService = new RealtimeService();

export default realtimeService;
