import { DeleteForever } from '@material-ui/icons';
import { FC } from 'react';
import styles from '../styles.module.scss';
import { Integration as IntegrationObject } from '../types';
import { showDialog } from '../../../../../widgets/dialog';
import classNames from 'classnames';

interface IProps {
    integration: IntegrationObject;
    handleDeleteIntegration: (iintegration: IntegrationObject) => void;
    cm: any;
    readonly: boolean;
}

const Integration: FC<IProps> = ({ integration, handleDeleteIntegration, cm, readonly }) => {
    const handleShowDeletePopup = async () => {
        const dialogContent = {
            title: cm.get('DeleteIntegration'),
            children: `${cm.get('DeleteIntegrationReassurance')} ${integration?.channel?.label}?`,
            userInteractive: { resolveText: cm.get('Yes'), rejectText: cm.get('Cancel') },
        };
        const confirmDialog = await showDialog(dialogContent);
        !!confirmDialog && handleDeleteIntegration(integration);
    };

    return (
        <div className={styles.row}>
            <div className={`${styles.dFlexCol} ${styles.mediumInput}`}>
                <span className={styles.label}>{cm.get('Channel')}</span>
                <div className={`${styles.textContainer} ${styles.name}`}>
                    <div className={styles.text}>{integration.channel.label}</div>
                </div>
            </div>
            <div className={`${styles.dFlexCol} ${styles.smallInput}`}>
                <span className={styles.label}>{cm.get('MeasurementId')}</span>
                <div className={styles.textContainer}>
                    <div className={styles.text}>{integration.measurementId}</div>
                </div>
            </div>
            <div className={classNames(styles.sideIcons, { [styles.disabled]: readonly })}>
                <DeleteForever fontSize="medium" style={{ cursor: 'pointer' }} onClick={handleShowDeletePopup} />
            </div>
        </div>
    );
};

export default Integration;
