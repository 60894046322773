import { BarGraph, DonutGraph, LegendColor, DashboardItem, ToolTip, InfoList } from '@widgets';
import React, { useMemo, useState } from 'react';

import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import { Menu } from './menu';
import RightArrow from '@components/svgs/right-arrow';
import classNames from 'classnames/bind';
import { formatToK, getPercentage, threatGroups } from '@utils/dashboard';
import { openAnalyticsWithPresetReportParams } from '@redux/thunks/analytics';
import { showDialog } from '@widgets/dialog';
import styles from './style.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateSummeryReportSettings } from '../../../../../redux/actions/analytics';

const cx = classNames.bind(styles);

const colors = [
    ['rgba(234, 51, 133, 1)', 'rgba(252, 73, 153, 1)'],
    ['rgba(176, 41, 198, 1)', 'rgba(224, 83, 247, 1)'],
    ['rgba(105, 54, 251, 1)', 'rgba(147, 110, 255, 1)'],
];

export const TopThreatType = ({ threatsByGroupsAndTrafficType, className, dashboardCM, isLoading, isError,options }) => {
    const dispatch = useDispatch();
    const naviagte = useNavigate();
    const { selectedLicense } = useSelector(state => state.settings);

    const [trafficType, setTrafficType] = useState('total');

    const total =
        threatsByGroupsAndTrafficType && threatsByGroupsAndTrafficType[trafficType] && threatsByGroupsAndTrafficType[trafficType].total
            ? threatsByGroupsAndTrafficType[trafficType].total
            : 0;

    const selectedThreatGroups = useMemo(() => {
        if (!threatsByGroupsAndTrafficType) {
            return [
                { threatTypes: { total: 0 }, threatGroup: dashboardCM.get('InvalidBotActivity.Title'), color: colors[0] },
                { threatTypes: { total: 0 }, threatGroup: dashboardCM.get('InvalidMaliciousActivity.Title'), color: colors[1] },
                { threatTypes: { total: 0 }, threatGroup: dashboardCM.get('InvalidSuspiciousActivity.Title'), color: colors[2] },
            ];
        }
        return [
            {
                threatTypes: { ...threatsByGroupsAndTrafficType[trafficType][threatGroups.BAD_BOT_ACTIVITY] },
                threatGroup: dashboardCM.get('InvalidBotActivity.Title'),
                color: colors[0],
            },
            {
                threatTypes: { ...threatsByGroupsAndTrafficType[trafficType][threatGroups.MALICIOUS_ACTIVITY] },
                threatGroup: dashboardCM.get('InvalidMaliciousActivity.Title'),
                color: colors[1],
            },
            {
                threatTypes: { ...threatsByGroupsAndTrafficType[trafficType][threatGroups.SUSPICIOUS_ACTIVITY] },
                threatGroup: dashboardCM.get('InvalidSuspiciousActivity.Title'),
                color: colors[2],
            },
        ];
    }, [dashboardCM, threatsByGroupsAndTrafficType, trafficType]);

    const selectedThreatTypes = useMemo(() => {
        return selectedThreatGroups.reduce((result, current) => {
            Object.entries(current.threatTypes).forEach(([key, value]) => {
                if (key === 'total') {
                    return;
                } else {
                    result.push({ threatType: key, value: value.invalidClicks, color: current.color, protected: value.protected });
                }
            });
            return result;
        }, []);
    }, [selectedThreatGroups]);

    const donutGraphValues = useMemo(
        () =>
            selectedThreatGroups.map(item => ({
                label: item.threatGroup,
                value: item.threatTypes.total,
                color: item.color[0],
            })),
        [selectedThreatGroups]
    );

    const barGraphValues = useMemo(
        () =>
            selectedThreatTypes.map(item => {
                return {
                    label: item.threatType,
                    value: item.value,
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        stops: [
                            [0, item.color[0]],
                            [1, item.color[1]],
                        ],
                    },
                    id: item.threatType,
                    protected: !!item.protected,
                };
            }),
        [selectedThreatTypes]
    );

    //Todo: enable logic after backend is resolved
    const withUnprotected = false; //Array.isArray(selectedThreatTypes) ? selectedThreatTypes.some(v => v.protected) && selectedThreatTypes.some(v => !v.protected) : false;

    const handleViewAllClick = () =>{
        dispatch(updateSummeryReportSettings({policyToggleA: options.policyTogglePM,includePolicy: options.includePolicy, policyManagement:options.policyManagement }));
        dispatch(
            openAnalyticsWithPresetReportParams(
                [
                    { value: 'threatType', label: 'Threat Type' },
                    { value: 'platformOrigin', label: 'Platform Origin' },
                ],
                naviagte,
                selectedLicense
            )
        );
    }

    const showThreatGroupsInfoDialog = async ({ title, children }) => {
        await showDialog({ title, children });
    };

    const showTopThreatTypeInfoDialog = async ({ title, children }) => {
        await showDialog({ title, children });
    };

    const getThreatGroupsDialogHandler = () =>
        showThreatGroupsInfoDialog({
            title: dashboardCM.get('InvalidVisitsInsights'),
            children: <InfoList data={getThreatGroupsInfo(dashboardCM)} />,
        });

    const getTopThreatTypeDialogHandler = () =>
        showTopThreatTypeInfoDialog({ title: dashboardCM.get('TopThreats.Title'), children: <InfoList data={getThreatTypesInfo(dashboardCM)} /> });

    return (
        <DashboardItem
            isLoading={isLoading}
            isError={isError}
            data-test-selector="top threat types"
            className={className}
            title={
                <div className={cx('titleWrapper')}>
                    <div>{dashboardCM.get('TopThreats.Title')}</div>
                    <Menu dashboardCM={dashboardCM} current={trafficType} onChange={setTrafficType} />
                </div>
            }
            action={{
                label: dashboardCM.get('ViewAll'),
                callBack: handleViewAllClick,
            }}
            info={{
                title: dashboardCM.get('TopThreats.Title'),
                content: dashboardCM.get('TopThreats.Description'),
                action: { label: dashboardCM.get('LearnMore'), handler: getTopThreatTypeDialogHandler },
            }}
        >
            <div className={cx('flex-row', 'paddedContainer', 'topThreatsContent')}>
                <div className={cx('barGraph')} data-test-selector="bar graph">
                    <BarGraph
                        cm={dashboardCM}
                        values={barGraphValues}
                        legend={donutGraphValues}
                        limit={8}
                        withHighlightedColumns={withUnprotected}
                        highlightedColumnsLabel={dashboardCM.get('TopThreats.NotProtectedThreats')}
                    />
                </div>
                <div className={cx('arrow')}>
                    <RightArrow />
                </div>
                <div className={cx('visits')} data-test-selector="donut graph">
                    <DonutGraph total={total} values={donutGraphValues} />
                </div>
                <div className={cx('threatGroups')} data-test-selector="threat groups table">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div className={cx('threatGroupsTitleContainer')}>
                                        <div className={styles.threatGroupsTitle}>{dashboardCM.get('ThreatGroups.Title')}</div>
                                        <ToolTip
                                            title={dashboardCM.get('ThreatGroups.Title')}
                                            content={dashboardCM.get('ThreatGroups.Description')}
                                            action={{
                                                label: dashboardCM.get('LearnMore'),
                                                handler: getThreatGroupsDialogHandler,
                                            }}
                                        >
                                            <InfoIcon data-test-selector="info-icon" />
                                        </ToolTip>
                                    </div>
                                </th>
                                <th>{dashboardCM.get('TopThreats.Title')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedThreatGroups.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td valign="top">
                                            <div className={cx('flex-row')} style={{ alignItems: 'flex-start' }}>
                                                <LegendColor color={item.color[0]} style={{ marginTop: '0.3rem' }} />
                                                <div className={cx('flex-column')}>
                                                    <div className={cx('flex-row', 'threatGroupTitle')}>{item.threatGroup}</div>
                                                    <div className={cx('flex-row')}>
                                                        {formatToK(item.threatTypes.total)} ({Math.round(getPercentage(item.threatTypes.total, total))}%)
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td valign="top">
                                            <ul>
                                                {Object.entries(item.threatTypes)
                                                    .filter(([k]) => k !== 'total')
                                                    .sort(([, vA], [, vB]) => {
                                                        if (!vB.invalidClicks || !vA.invalidClicks) return -1;
                                                        return vB.invalidClicks - vA.invalidClicks;
                                                    })
                                                    .slice(0, 3)
                                                    .map(([k, v], idx) => {
                                                        return (
                                                            <li key={idx} className={cx(!v.protected && withUnprotected ? 'unprotected' : 'topThreatRow')}>
                                                                <div className={cx('topThreatTitle')}>{dashboardCM.get(`${k.replace(/ /g, '')}.Title`)}</div>
                                                                <div>{v.invalidClicks.toLocaleString()}</div>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </DashboardItem>
    );
};

const getThreatTypesInfo = cm => {
    //ToDo: replace with values from ContentManager
    return [
        {
            title: cm.get('Spambot.Title'),
            content: cm.get('Spambot.Description'),
        },
        {
            title: cm.get('Scrapers.Title'),
            content: cm.get('Scrapers.Description'),
        },
        {
            title: cm.get('Automationtool.Title'),
            content: cm.get('Automationtool.Description'),
        },
        {
            title: cm.get('FrequencyCapping.Title'),
            content: cm.get('FrequencyCapping.Description'),
        },
        {
            title: cm.get('Abnormalratelimit.Title'),
            content: cm.get('Abnormalratelimit.Description'),
        },
        {
            title: cm.get('Excessiveratelimit.Title'),
            content: cm.get('Excessiveratelimit.Description'),
        },
        {
            title: cm.get('DisabledJS.Title'),
            content: cm.get('DisabledJS.Description'),
        },
        {
            title: cm.get('BehavioralAnomalies.Title'),
            content: cm.get('BehavioralAnomalies.Description'),
        },
        {
            title: cm.get('ClickFarm.Title'),
            content: cm.get('ClickFarm.Description'),
        },
        {
            title: cm.get('MaliciousBots.Title'),
            content: cm.get('MaliciousBots.Description'),
        },
        {
            title: cm.get('FalseRepresentation.Title'),
            content: cm.get('FalseRepresentation.Description'),
        },
        {
            title: cm.get('AccountTakeOver.Title'),
            content: cm.get('AccountTakeOver.Description'),
        },
        {
            title: cm.get('DataCenter.Title'),
            content: cm.get('DataCenter.Description'),
        },
        {
            title: cm.get('VPN.Title'),
            content: cm.get('VPN.Description'),
        },
        {
            title: cm.get('Proxy.Title'),
            content: cm.get('Proxy.Decription'),
        },
        {
            title: cm.get('DisabledCookies.Title'),
            content: cm.get('DisabledCookies.Description'),
        },
        {
            title: cm.get('ClickHijacking.Title'),
            content: cm.get('ClickHijacking.Description'),
        },
        {
            title: cm.get('NetworkAnomalies.Title'),
            content: cm.get('NetworkAnomalie.Description'),
        },
        {
            title: cm.get('KnownBots.Title'),
            content: cm.get('KnownBots.Description'),
        },
    ];
};

const getThreatGroupsInfo = cm => {
    return [
        {
            title: cm.get('InvalidBotActivity.Title'),
            content: cm.get('InvalidBotActivity.Description'),
        },
        {
            title: cm.get('InvalidMaliciousActivity.Title'),
            content: cm.get('InvalidMaliciousActivity.Description'),
        },
        {
            title: cm.get('InvalidSuspiciousActivity.Title'),
            content: cm.get('InvalidSuspiciousActivity.Description'),
        },
    ];
};
