/**
 * this file is auto generated by chopin-scripts.
 * you can check the project package.json
 * to see how it was generated.
 */

import analytics from './analytics';
import base from './base';
import clickTrueTags from './click-true-tags';
import dialog from './dialog';
import heatmaps from './heatmaps';
import profile from './profile';
import settings from './settings';
import realtime from './realtime';
import sso from './sso';
import ssoV2 from './sso-v2';
import transparency from './transparency';
import users from './users';

export default {
    analytics,
    base,
    clickTrueTags,
    dialog,
    //profile,
    realtime,
    sso,
    ssoV2,
    heatmaps,
    // transparency,
    settings,
    users,
};
