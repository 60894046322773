export const passwordRules = [
    {
        checkRule: password => /[A-Z]/.test(password),
        rule: 'password must contain at least one capital letter',
    },
    {
        checkRule: password => password.length >= 8,
        rule: 'password must be at least 8 characters long',
    },
    {
        checkRule: password => /[a-z]/.test(password),
        rule: 'password must contain at least one lowercase letter',
    },
    {
        checkRule: password => /[0-9]/.test(password),
        rule: 'password must contain at least one number',
    },
    {
        checkRule: password => /[!@#\$%\^\&*\)\(+=._-]/.test(password),
        rule: 'password must contain at least one special character',
    },
];

export const isValidPassword = (password = '') =>
    passwordRules.reduceRight((acc, { checkRule, rule }) => {
        if (!checkRule(password)) {
            acc.push(rule);
        }
        return acc;
    }, []);

export const getProducts = networkFeatures => {
    const activeProducts = networkFeatures.map(item => item.id);
    const hasOneLicense = activeProducts.length === 1;

    const products = [
        {
            value: 1,
            label: 'CustomerAcquisitionSecurity',
        },
        {
            value: 2,
            label: 'OnSiteSecurity',
        },
        {
            value: 3,
            label: 'MarketingIntelligenceSecurity',
        },
        {
            value: null,
            label: 'None',
            active: hasOneLicense ? false : true,
        },
    ];

    return products.map(item => {
        const active = item.value ? activeProducts.includes(item.value) : item.active;
        return {
            ...item,
            active,
        };
    });
};

