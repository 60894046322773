// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { rtiSetFilter } from '@redux/actions/rti-dashboard';

// UTILS
import { convertDateToUnix } from '@utils/common';
import mixpanel from '@services/mixpanel';

// STYLES
import styles from './RTIDashboard.module.scss';

//context
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import api from '@services/api';
import { useSplit } from '@hooks';
import { constants } from '@utils/split.io';

// COMPONENTS
import Filter from './components/filter';
import VisitsSummary from './components/visits-summary';
import VisitInsights from './components/visits-insights';
import Interceptions from './components/interceptions';
import Header from '../../../components/containers/header';
import { useContentManager } from '../../../hooks';


const OnSiteConversionDashboard = () => {
    const cm = useContentManager();
    const { filterOptions } = useSelector(state => state.rtiDashboard);
    const {
        data: settings = {},
        isLoading: isSettingsLoading,
    } = useQuery(['rti_settings'], api.settings.getRtiSettings);
    const { isOn: showPolicyToggle } = useSplit(constants.SPLIT_POLICY_MANAGEMENT_TOGGLE);
    const { isOn: policyManagement } = useSplit(constants.SPLIT_POLICY_MANAGEMENT);
    const [options, setOptions] = useState(filterOptions);

    const dispatch = useDispatch();
    useEffect(() => {
        setOptions(prevState => ({
            ...prevState,
            policyManagement,
            includePolicy: showPolicyToggle,
        }));
        dispatch(rtiSetFilter({
            policyManagement,
            includePolicy: showPolicyToggle,
        }));
    }, [policyManagement, showPolicyToggle]);

    const handleRefresh = state => {
        // setIsLoading(true);


        const { dateRange } = state;
        const unix = convertDateToUnix(dateRange.format);
        setOptions({ ...state, dateRange: { ...dateRange, unix } });
        dispatch(rtiSetFilter({ ...state, dateRange: { ...dateRange, unix } }));
    };

    if (performance.navigation.type === 1) {
        const object = {
            'Page name': 'Dashboard',
            'Navigation type': 'Refresh',
        };

        mixpanel.postEvent('PageView', object);
    }
    return (
        <>
            <Header title={cm.get('Dashboard')} />
            <div className={styles.root}>
                <Filter handleRefresh={handleRefresh} state={options} />

                <VisitsSummary options={{ ...options, ...options.dateRange.unix }}
                               isSettingsLoading={isSettingsLoading} />

                <VisitInsights options={{ ...options, ...options.dateRange.unix }}
                               isSettingsLoading={isSettingsLoading} />

                <Interceptions
                    isApiIntegration={settings.isApiIntegration}
                    isClientSideIntegration={settings.isClientSideIntegration}
                    options={{ ...options, ...options.dateRange.unix }}
                    isSettingsLoading={isSettingsLoading}
                />
            </div>
        </>
    );
};

export default OnSiteConversionDashboard;
