import React, { useCallback } from 'react';
import styles from './style.module.css';
import { useNavigate } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

const CustomTab = props => {
    const { value, current, label, icon } = props;
    const navigate = useNavigate();

    const move = useCallback(() => {
        navigate(value);
    });

    return (
        <ButtonBase component="div">
            <div onClick={move} className={cx('box', current === value ? 'selected' : '')}>
                <div className={styles.content_wrapper}>
                    <div>{icon}</div>
                    <div>{label}</div>
                </div>
            </div>
        </ButtonBase>
    );
};

export default CustomTab;
