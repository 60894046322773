import React, { useRef, useEffect } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import './style.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/blackboard.css';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/addon/display/placeholder';
import 'codemirror/mode/htmlembedded/htmlembedded';
import 'codemirror/addon/display/autorefresh';

export default ({ height, width, options, forceRefresh, ...props }) => {
    const e = useRef(null);
    useEffect(() => {
        e.current && e.current.setSize(width, height)
        e.current.refresh();
    }, [width, height, forceRefresh])

    return <CodeMirror
        editorDidMount={editor => { e.current = editor }}
        options={{
            theme: 'blackboard',
            ...options
        }}{...props} />
}