import { dashboardDisplayOptions, getVisiblePlatformsGroups } from '../../utils/dashboard';

import { dashboard as actions } from '../actions';
import moment from 'moment';

const dashboardInitialState = {
    data: {
        potentialReplacedVisits: {},
        calculatedReplacedVisits: {},
        blockedEntities: {},
        countries: [],
        keywords: [],
        campaigns: [],
        urls: [],
        reason: [],
    },
    settings: {
        platformsGroups: [],
        dateRanges: [],
        channels: [],
        threatGroups: [],
    },
    selectedDisplayOption: dashboardDisplayOptions.ALL_PLATFORMS,
    selectedPlatformsGroup: undefined,
    filterOptions: {
        dateRange: 'today',
        includePPCCrawlers: false,
        includePolicy: false,
        policyManagement: false,
        policyTogglePM: true,
        policyToggleDA: true,
    },
    policyToggleOptions: {
        policyTogglePM: true,
        policyToggleDA: true,
    },
    settingsLoading: true,
    settingsReady: false,
    selectedChannel: { value: 'none', label: 'none' },
    visiblePlatformsGroups: [],
    startDate: moment().subtract(14, 'days'),
    isBlockingMode: false,
    err: null,
};

export default (state = dashboardInitialState, action) => {
    switch (action.type) {
        case actions.DASHBOARD_SETTINGS_LOADING: {
            return { ...state, settingsLoading: true, err: null };
        }

        case actions.RTI_DASHBOARD_LOADING: {
            return { ...state, rti: { ...state.rti, isLoading: true, err: null } };
        }

        case actions.DASHBOARD_SETTINGS_UPDATED: {
            const { payload: settings } = action;
            const visiblePlatformsGroups = getVisiblePlatformsGroups(settings.platformsGroups, state.selectedDisplayOption, state.dashboardCM, settings.isPixelGuardIntegrated) || [];
            const [selectedPlatformsGroup] = visiblePlatformsGroups;
            const isBlockingMode = settings.platformsGroups && settings.platformsGroups.some(pg => pg.protected);
            return {
                ...state,
                settingsLoading: false,
                settingsReady: true,
                settings,
                isBlockingMode,
                visiblePlatformsGroups,
                selectedPlatformsGroup,
                err: null,
            };
        }

        case actions.DASHBOARD_DATA_LOADING: {
            const { payload: filterOptions } = action;
            return {
                ...state,
                filterOptions,
                err: null,
                potentialReplacedVisitsReady: false,
                calculatedReplacedVisitsReady: false,
                blockedEntitiesReady: false,
            };
        }

        case actions.DASHBOARD_DATA_UPDATED: {
            const {
                payload: { data, dataReady = true },
            } = action;
            return {
                ...state,
                dataLoading: false,
                dataReady,
                data: { ...state.data, ...data },
                filterOptions: { ...state.filterOptions },
            };
        }

        case actions.GET_POTENTIAL_REPLACED_VISITS_COMPONENT: {
            const { payload: potentialReplacedVisits } = action;
            return { ...state, potentialReplacedVisitsReady: true, data: { ...state.data, potentialReplacedVisits } };
        }

        case actions.GET_CALCULATED_REPLACED_VISITS_COMPONENT: {
            const { payload: res } = action;
            const { results: calculatedReplacedVisits, returnRate } = res;
            return {
                ...state,
                calculatedReplacedVisitsReady: true,
                data: { ...state.data, calculatedReplacedVisits, returnRate },
            };
        }

        case actions.GET_BLOCKED_ENTITIES_COMPONENT: {
            const { payload: blockedEntities } = action;
            return { ...state, blockedEntitiesReady: true, data: { ...state.data, blockedEntities } };
        }

        case actions.DASHBOARD_REQUEST_FAILED: {
            const { payload: err } = action;
            return { ...state, dataLoading: false, dataReady: false, dataDownReady: false, dataUpReady: false, err };
        }

        case actions.SET_DASHBOARD_DISPLAY_OPTION: {
            const { payload: settings } = action;
            const visiblePlatformsGroups = getVisiblePlatformsGroups(state.settings.platformsGroups, settings.selectedDisplayOption, state.dashboardCM, settings.isPixelGuardIntegrated) || [];
            const [selectedPlatformsGroup] = visiblePlatformsGroups;
            return { ...state, selectedDisplayOption: settings.selectedDisplayOption, visiblePlatformsGroups, selectedPlatformsGroup, err: null };
        }

        case actions.SET_FILTER_OPTIONS: {
            const { payload: filterOptions } = action;
            return { ...state, filterOptions: { ...state.filterOptions, ...filterOptions } };
        }
        case actions.SET_POLICY_TOGGLE_OPTIONS: {
            const { payload: policyToggleOptions } = action;
            return { ...state, policyToggleOptions: { ...state.policyToggleOptions, ...policyToggleOptions } };
        }

        case actions.SET_SELECTED_CHANNEL: {
            const { payload: selectedChannel } = action;
            return { ...state, selectedChannel };
        }

        case actions.RTI_DASHBOARD_UPDATED: {
            return { ...state, rti: { ...state.rti, isLoading: false } };
        }

        case actions.RTI_DASHBOARD_ERROR: {
            const { payload: err } = action;
            return { ...state, rti: { ...state.rti, err, isLoading: false } };
        }

        case actions.SET_SELECTED_PLATFORMS_GROUP: {
            const { payload: selectedPlatformsGroup } = action;
            return { ...state, selectedPlatformsGroup };
        }

        default:
            return state;
    }
};
