import React, { useEffect, useState } from 'react';

import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import IconButton from '@material-ui/core/IconButton';
import styles from './styles';
import { useTheme } from '@material-ui/core';

const TextField = ({ showIcon = true, className, style = {}, value = '', placeholder = 'Text Here', onSubmit = () => console.debug('icon clicked'), disabled }) => {
    const theme = useTheme();
    const { container, textField } = styles(theme);
    const [textInput, setTextInput] = useState(value);
    const [textChange, setTextChange] = useState(false);
    const [displayIcon, setDisplayIcon] = useState(false);

    useEffect(() => {
        setTextChange(value != textInput);
    }, [textInput, value]);

    useEffect(() => {
        setTextInput(value);
    }, [value]);

    return (
        <div
            className={`${container} ${className}`}
            onBlur={({ relatedTarget }) => {
                if (relatedTarget && relatedTarget.value === 'submitButton') onSubmit(textInput);
                else setTextInput(value);
                setDisplayIcon(false);
            }}
        >
            <div className={textField}>
                <input
                    disabled={disabled}
                    style={{ ...style }}
                    type={'number'}
                    placeholder={placeholder}
                    value={textInput}
                    onFocus={() => setDisplayIcon(true)}
                    onChange={({ target: { value = '' } = {} }) => setTextInput(value)}
                    required="required"
                />
                {showIcon && <Icon onSubmit={onSubmit} displayIcon={displayIcon} textChange={textChange} textInput={textInput} setDisplayIcon={setDisplayIcon} />}
            </div>
        </div>
    );
};

export default TextField;

function Icon({ textChange, displayIcon }) {
    return !displayIcon ? (
        <div style={{ width: 20, height: 20 }}></div>
    ) : (
        <IconButton style={{ padding: 0 }} value={'submitButton'} disabled={!textChange}>
            <DoneOutlinedIcon style={{ color: textChange ? '#fe0072' : 'inherit' }} />
        </IconButton>
    );
}
