import { useEffect } from 'react';

export const useOnClickOutside = (ref: any, handler: (event: Event) => void) => {
    useEffect(() => {
        const listener = (event: Event) => {
            if (ref.current?.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};
