import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './style';
import ContentManager from '../../../services/content-manager';
import Heatmaps from './heatmaps';
import styles from './fraudFlow.module.css';

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const tabPanels = [
    Heatmaps,
    // Heatmaps,
];

export default function () {
    const theme = useTheme();
    const [tab, setTab] = React.useState(0);
    const style = useStyles();
    const cm = ContentManager.by('ClickFlow');
    return (
        <div style={{ minHeight: '100%', textAlign: 'left', paddingLeft: 100 }}>
            <div className={styles.title}>{cm.get('Title')}</div>
            <div className={style.root}>
                <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} classes={{ indicator: style.tabIndicator }}>
                    <Tab classes={{ root: style.tabRoot }} label={'HEATMAPS'} {...a11yProps(0)} />
                    {/*<Tab classes={{root:style.tabRoot}} label={'RECORDINGS'}  {...a11yProps(1)} />*/}
                </Tabs>
                {tabPanels.map(
                    (TabPanel, idx) =>
                        idx === tab && (
                            <TabPanel key={idx} value={idx} index={idx} dir={theme.direction}>
                                <TabPanel />
                            </TabPanel>
                        )
                )}
            </div>
        </div>
    );
}
