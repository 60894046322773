import { FC, useEffect, useState } from 'react';
/* @ts-ignore */
import { useContentManager } from '@hooks';
import styles from './styles.module.scss';
import { MarketoConnector } from './connector';
import CompletedIcon from '../../exportables/assets/check-circle.svg';
import Progress from '../../../widgets/progress';
import { toast } from 'react-toastify';
import { MarketoFieldsValues, MarketoForm, MarketoPartialFieldsValues } from 'types/marketo';
import { connectMarketoOAuth, disconnectMarketoOAuth, getMarketoForms, isMarketoConnected, setMarketoForms } from 'services/api/marketo.service';
import { MarketoForms } from './forms';
import { CircularProgress, Divider } from '@material-ui/core';

const customSuccessToastStyle: React.CSSProperties = {
    background: '#F6FFED',
    color: '#237804',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '22px',
};

const customErrorToastStyle: React.CSSProperties = {
    background: '#FFF1F0',
    color: '#CF1322',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '22px',
};

export const Marketo: FC = () => {
    const cm = useContentManager();
    const { networkId } = JSON.parse(localStorage.getItem('meta') || '{}');
    const [isConnected, setIsconnected] = useState<boolean>(false);
    const [marketoFieldsValues, setMarketoFieldsValues] = useState<MarketoFieldsValues>({ clientId: '', clientSecret: '', munchkinId: '' });
    const [forms, setForms] = useState<MarketoForm[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [isGetFormsError, setIsGetFormsError] = useState<boolean>(false);
    const [updatedForms, setUpdatedForms] = useState<MarketoForm[]>([]);
    const [isUpdatedFormsLoading, setIsUpdatedFormsLoading] = useState<boolean>(false);
    const [initialFormsState, setInitialFormsState] = useState<MarketoForm[]>([]);
    const [marketoXSignature, setMarketoXSignature] = useState<string>('');

    const setDefaultMarketoFields = () => {
        setMarketoFieldsValues({ clientId: '', clientSecret: '', munchkinId: '' });
        setMarketoXSignature('');
    };

    const fetchForms = async () => {
        try {
            const res: MarketoForm[] = await getMarketoForms(networkId);
            setForms(res);
            setInitialFormsState(res);
        } catch (error) {
            setIsGetFormsError(true);
        }
    };

    const fetchMarketoFields = async () => {
        const marketoFields: MarketoPartialFieldsValues | null = await isMarketoConnected(networkId);
        if (marketoFields && Object.keys(marketoFields).length > 0) {
            setIsconnected(true);
            setMarketoFieldsValues({ clientId: marketoFields.clientId, clientSecret: 'secret', munchkinId: marketoFields.munchkinId });
            marketoFields.marketoXSignature && setMarketoXSignature(marketoFields.marketoXSignature);
            await fetchForms();
        } else {
            setDefaultMarketoFields();
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                await fetchMarketoFields();
            } catch (error) {
                setIsError(true);
                setIsGetFormsError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleConnect = async (marketoFieldsValues: MarketoFieldsValues) => {
        try {
            setLoading(true);
            await connectMarketoOAuth(marketoFieldsValues);
            await fetchMarketoFields();
            toast.success(cm.get('MarketoSuccessConnectMessage'), {
                style: customSuccessToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
            setIsconnected(true);
        } catch (error) {
            toast.error(cm.get('MarketoFailedConnectMessage'), {
                style: customErrorToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
            setDefaultMarketoFields();
        } finally {
            setLoading(false);
        }
    };

    const handleDisconnect = async () => {
        try {
            setLoading(true);
            await disconnectMarketoOAuth();
            setDefaultMarketoFields();
            setIsconnected(false);
            toast.success(cm.get('MarketoSuccessDisconnectMessage'), {
                style: customSuccessToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
        } catch (error) {
            toast.error(cm.get('MarketoFailedDisconnectMessage'), {
                style: customErrorToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSetMarketoForms = (forms: MarketoForm[]) => {
        setForms(forms);
    };

    const saveFormsChanges = async () => {
        try {
            if (forms.length) {
                setIsUpdatedFormsLoading(true);
                const formsWithoutIsProtected = forms
                    .filter(obj => obj.isProtected === true)
                    .map(obj => {
                        const { isProtected: _, ...rest } = obj;
                        return rest;
                    });
                await setMarketoForms(networkId, formsWithoutIsProtected);
                setInitialFormsState(forms);
                setUpdatedForms([]);
                setIsUpdatedFormsLoading(false);
                toast.success(cm.get('MarketoSuccessUpdateFormsMessage'), {
                    style: customSuccessToastStyle,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                });
            }
        } catch (error) {
            setIsUpdatedFormsLoading(false);
            toast.error(cm.get('MarketoFailedUpdateFormsMessage'), {
                style: customErrorToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
        }
    };

    const isSaveButtonDisabled = (!updatedForms.length && !isUpdatedFormsLoading) || JSON.stringify(forms) === JSON.stringify(initialFormsState);

    return (
        <div className={styles.container}>
            {isError ? (
                <span className={styles.errorContainer}>{cm.get('RuleVisitsLoadingErrorMessage')}</span>
            ) : loading ? (
                <div className={styles.mainLoading}>
                    <Progress size={65} thickness={2} />
                </div>
            ) : (
                <>
                    <div className={styles.titleContainer}>
                        <div className={styles.title}>
                            {cm.get('Marketo.header')}
                            {isConnected ? (
                                <div className={styles.connect}>
                                    <img className={styles.icon} src={CompletedIcon} alt="status-icon" width="12" height="12" /> {cm.get('Connected')}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className={styles.innerContainer}>
                        <MarketoConnector
                            isDisconnectLoading={loading}
                            isConnected={isConnected}
                            handleConnect={handleConnect}
                            handleDisconnect={handleDisconnect}
                            marketoFieldsValues={marketoFieldsValues}
                            setMarketoFieldsValues={setMarketoFieldsValues}
                            marketoXSignature={marketoXSignature}
                        />
                        {isConnected ? (
                            <>
                                <div className={styles.divider}>
                                    <Divider />
                                </div>
                                <div className={styles.formsContainer}>
                                    <MarketoForms
                                        isFetchFormsError={isGetFormsError}
                                        marketoForms={forms}
                                        handleSetMarketoForms={handleSetMarketoForms}
                                        updatedForms={updatedForms}
                                        setUpdatedForms={setUpdatedForms}
                                    />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    {!isGetFormsError && isConnected ? (
                        <div className={styles.saveFormsContainer}>
                            {isUpdatedFormsLoading && <span className={styles.saveFormsLoadingText}>{cm.get('SaveUpdatedMarketoFormsLoadingText')}</span>}
                            <div
                                className={`
                        ${styles.saveFormsButton}
                        ${isSaveButtonDisabled && styles.disabledSaveFormsButton}
                        ${isUpdatedFormsLoading && styles.loaderSaveFormsButton}
                        `}
                                onClick={() => !isSaveButtonDisabled && saveFormsChanges()}
                            >
                                {isUpdatedFormsLoading ? (
                                    <div style={{ color: '#788397', display: 'flex' }}>
                                        <CircularProgress size={14} color="inherit" />
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {cm.get('Save')}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
};
