import AudienceTagsForm from './forms/audience';
import Base from '../base';
import ContentManager from '../../../services/content-manager';
import EditTagForm from './forms/edit';
import { GoogleAnalyticsEvents } from '../../../services/google-analytics';
import Toast from '../../../services/notifications';
import { setClickTrueTags } from '@redux/actions/click-true-tags';
import { applyAuth0AuthorizationHeader } from '../../../utils/http/view-models';
import { getDashboardSettings, getSettings } from "../../../redux/thunks";

const gEvent = GoogleAnalyticsEvents();

export default ({ formable, tableable, httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor() {
                    super('clickTrueTags');
                    this.state = { tags: [], isTagLoading: [] };
                }

                init(permissions) {
                    this.update(s => ({ ...s, permissions: permissions.click_true_tag }));
                    if (permissions.click_true_tag) {
                        this.getAll();
                        this.getServicesList();
                    }
                }
            }
        ).from([
            formable({ ...AudienceTagsForm, name: 'audienceTags' }),
            formable({ ...EditTagForm, name: 'edit' }),

            httpRequest({
                name: 'getServicesList',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: (servicesList = []) => ({ servicesList: servicesList.map(service => ({ label: service, value: service })) }),
            }),
            httpRequest({
                name: 'getAll',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: function ({ data: tags = [] }) {
                    const tagsResult = tags.map(({ id, name, mainTag, description, jsonTags = [], tagHash, networkId = 0, protectionConfig, whiteLabelDomain }) => {
                        jsonTags = typeof jsonTags === 'string' ? JSON.parse(jsonTags) : jsonTags;
                        return {
                            id,
                            name,
                            mainTag,
                            description,
                            jsonTags: jsonTags.map(serviceTag => ({ ...serviceTag, text: (serviceTag.tags || []).join('\n') })),
                            tagHash,
                            networkId,
                            protectionConfig,
                            whiteLabelDomain,
                        };
                    });
                    this.reduxStore.dispatch(setClickTrueTags(tagsResult));
                    return {
                        tags: tagsResult,
                    };
                },
            }),
            httpRequest({
                name: 'edit',
                type: 'post',
                route: 'update_tag',
                onError,
                preRequest: function ({ id }) {
                    applyAuth0AuthorizationHeader.call(this);
                    gEvent('EditTag', this.name);
                    this.state.isTagLoading.push(id);
                },
                updateState: function (res, params) {
                    const data = res[0];
                    if (data.id) {
                        const updatedTags = this.state.tags.map(tag => (tag.id !== params.id ? tag : data));
                        this.reduxStore.dispatch(setClickTrueTags(updatedTags));
                        this.reduxStore.dispatch(getDashboardSettings());
                        this.reduxStore.dispatch(getSettings());
                        return {
                            ...this.state,
                            isTagLoading: this.state.isTagLoading.filter(tagId => tagId === data.id),
                            tags: updatedTags,
                        };
                    } else {
                        return this.state;
                    }
                },
                postRequest: () => {
                    Toast({ message: ContentManager.get('AccountManagement.Tab.Tags.SuccessfullyUpdatedAudienceTags') });
                },
            }),
            httpRequest({
                name: 'delete',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    gEvent('DeleteTag', this.name);
                },
                updateState: function (res, { id: _id }) {
                    const that = this;
                    return state => {
                        const updatedTags = state.tags.filter(({ id }) => _id !== id);
                        that.reduxStore.dispatch(setClickTrueTags(updatedTags));
                        return { ...state, tags: updatedTags };
                    };
                },
            }),
            httpRequest({
                name: 'create',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                    gEvent('CreateTag', this.name);
                },
                onError,
                updateState: function ( res) {
                    const updatedTags = [...(this.state.tags || []), {...res.data,whiteLabelDomain:res.whiteLabelDomain}];
                    this.reduxStore.dispatch(setClickTrueTags(updatedTags));
                    return {
                        tags: updatedTags,
                    };
                },
            }),
            httpRequest({
                name: 'getMainTag',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this);
                },
                updateState: mainTag => ({ mainTag }),
            }),
        ]);

const onError = err => {
    let message;
    if (err && err.response && typeof err.response.data === 'string') {
        switch (err.response.data) {
            case 'CLICK_TRUE_TAG_NAME_EXISTS':
                message = ContentManager.get('AccountManagement.Form.EditTag.NameExists');
                break;
            case 'CLICK_TRUE_TAG_NAME_TOO_SHORT':
                message = ContentManager.get('AccountManagement.Form.EditTag.MinLength.Name');
                break;
            case 'CLICK_TRUE_TAG_NAME_TOO_LONG':
                message = ContentManager.get('AccountManagement.Form.EditTag.MaxLength.Name');
                break;
            case 'CLICK_TRUE_TAG_DESCRIPTION_TOO_LONG':
                message = ContentManager.get('AccountManagement.Form.EditTag.MaxLength.Description');
                break;
            default:
                message = err.response.data;
        }
    } else {
        message = (err && err.message) || 'sorry, something went wrong..';
    }
    Toast({ message });
};
