import React from 'react';

export const LegendColor = ({ color, style }) => {
    const defaultStyle = {
        width: '14px',
        height: '14px',
        borderRadius: '2px',
        border: '0.5px solid #E6E6E6',
        marginRight: '10px',
        backgroundColor: color,
    };

    const combinedStyle = { ...defaultStyle, ...style };

    return <div style={combinedStyle}></div>;
};
