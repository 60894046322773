import httpClient from '../ajax';

class ProfileService {
    endpoint = 'profile';

    post = httpClient({ baseRoute: this.endpoint });

    getMeta = () => {
        return this.post('get_meta');
    };

    getContent = () => {
        return this.post('get_content')
    }
}

export const profileService = new ProfileService();

export default profileService;
