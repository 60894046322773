import React, {useState} from "react";
import styles from "./realtime.module.scss";
import EyeIcon from "../../../svgs/view";
import ClosedEyeIcon from "../../../svgs/close-view";
import {DeleteForever} from "@material-ui/icons";
import copy from "clipboard-copy";
import ToolTip from "../../../../widgets/tool-tip";
import CopyIcon from "../../../svgs/copy";

const ApiKeyItem = ({ cm, tags, index, item, onDeleteHandler }) => {
    const key = JSON.parse(item['protection_config']).apiKey;
    const [revealSecretKey, setRevealSecretKey] = useState(false);
    const [copied, setCopied] = useState(false);
    const Icon = revealSecretKey ? EyeIcon : ClosedEyeIcon;
    const secretKeyInputText = revealSecretKey ? key : key?.replaceAll(/./g, '●');

    const handleOnSecretKeyCopy = () => {
        copy(secretKeyInputText);
        setCopied(true);
    };

    return (
        <div key={index} className={styles.row} data-test-selector={`rti-settings-api-Key-${index}`}>
            <span className={styles.title}>{cm.get('Channel')}</span>
            <div className={styles.buttonGroupContainer}>
                <div className={`${styles.textContainer} ${styles.channel}`}>
                    <div className={`${styles.text} ${styles.channel}`}>{tags.filter(tag => tag.value === item.id)[0].label}</div>
                </div>
            </div>
            <span className={styles.title}>{cm.get('RTIApiKey')}</span>
            <div className={styles.buttonGroupContainer}>
                <div className={`${styles.textContainer} ${styles.apiKey}`}>
                    <div className={styles.text}>{secretKeyInputText}</div>
                    <div onClick={() => setRevealSecretKey(!revealSecretKey)} className={styles.icon}>
                        <Icon />
                    </div>
                </div>
                <DeleteForever fontSize="medium" style={{cursor: 'pointer'}} onClick={() => onDeleteHandler(item)}/>
            </div>
            {revealSecretKey && (
                <div className={styles.copyBtnContainer}>
                    <ToolTip content={copied ? cm.get('Copied') : cm.get('CopyToClipboard')}>
                        <div>
                            <CopyIcon onClick={handleOnSecretKeyCopy} className={styles.icon} />
                        </div>
                    </ToolTip>
                </div>
            )}
        </div>
    )
}

export default ApiKeyItem;