import { httpRequest, methods } from 'services/http';
import { GetAccountCampaignsResponse, GetHierarchyResponse } from './types';
import config from 'config';

const CHEQ_API_V2_BASE_GOOGLE_URL = `${config.baseAPI}/api/v2/google`;

export const getAccountCampaigns = async (googleAccountId: string, parentAccountId: string): Promise<GetAccountCampaignsResponse | Error> => {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_V2_BASE_GOOGLE_URL}/campaigns/${googleAccountId}?parent=${parentAccountId}`, method: methods.get });
    return response;
};

export const getHierarchy = async (): Promise<GetHierarchyResponse | Error> => {
    const { response } = httpRequest({ baseURL: `${CHEQ_API_V2_BASE_GOOGLE_URL}/get_hierarchy`, method: methods.get });
    return response;
};
