import React from 'react';
import {Base }from  '../base'

const platformTypes = [{label: 'Yahoo! Gemini', value: 'yahooCom'},{label: 'Yahoo! JP Search', value: 'yahooJapanSearch'},{label: 'Yahoo! JP Display', value: 'yahooJapanDisplay'}]

export function Yahoo() {
    return Base({
        RenderHead,
        serviceName: 'yahoo',
        columns:[
            'enable',
            {id: 'type', searchTransform: val => {
                const x = platformTypes.find(({value}) => value === val) || {};

                return x.label || val;
            }},
            'pixelId',
            'projectId',
            'name',
            'actions'
        ],
        platformTypes
    })
}

const RenderHead = ({ column }) => {
    switch (column.id) {
        case 'projectId':
            return <div>Project Id</div>;
        case 'name':
            return <div>CHEQ Tag</div>;
        case 'pixelId':
            return <div>Pixel Id</div>;
        case 'enable' :
            return <div>Protected</div>;
        case 'type' :
            return <div>Audience Type</div>;
        case 'actions' :
            return <div>Actions</div>;
        default:
            return <div style={{ width: column.width }}/>
    }
}