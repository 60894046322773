import Base from '../base';
import Toast from '../../../services/notifications';
import ContentManager from '../../../services/content-manager';
import { applyAuth0AuthorizationHeader } from '@utils/http/view-models';

export default ({ httpRequest }) =>
    ({ model }) =>
        model(
            class extends Base {
                constructor() {
                    super('settings');
                }

                init(permissions) {
                    if (permissions.CPC) {
                        // this.getSettings();
                        this.getIndustries();
                    }
                    return this;
                }
            }
        ).from([
            httpRequest({
                name: 'updateSettings',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this)
                },
                onError: e =>
                    e.response && e.response.status === 403
                        ? Toast({ message: ContentManager.get('AccountManagement.Tab.Settings.PermissionsError') })
                        : Toast({ message: ContentManager.get('AccountManagement.Tab.Settings.GeneralError') }),
                postRequest: function (data, params) {
                    this.update({ ...params });
                },
            }),

            httpRequest({
                name: 'getSettings',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this)
                },
                updateState: setting => ({ ...setting }),
            }),
            httpRequest({
                name: 'getIndustries',
                type: 'post',
                preRequest: function () {
                    applyAuth0AuthorizationHeader.call(this)
                },
                updateState: industries => ({ industries }),
            }),
        ]);
