import React from 'react';
import CheqUI from '@combotag/cheq-ui';
import widgets from '../../../widgets/index';
import thirdPartySvgs from '../../../svgs/third-parties/index';
import tableStructures from '../table-structures/index';
import { useTheme } from '@material-ui/core';
import { useContentManager } from '@hooks';
import * as permissions from '@utils/permissions';
import { useSelector } from 'react-redux';
import { useSplit } from '../../../../hooks';
import { constants as splitConstants } from '@utils/split.io';

const { useMeiosis, useActions } = CheqUI.React.Meiosis;

const { Table } = widgets;

export default () => {
    const { isOn: isGoogleV2On } = useSplit(splitConstants.PARADOME_GOOGLE_V2);

    const { RenderBody, RenderHead, columns = [], style } = isGoogleV2On ? tableStructures.AccountsV2 : tableStructures.Accounts;

    const profile = useSelector(state => state.profile);
    const actions = useActions();
    const cm = useContentManager();
    const settings = useMeiosis(s => (isGoogleV2On ? s.ssoV2.servicesSettings : s.sso.servicesSettings));
    const theme = useTheme();
    const sso = useMeiosis(isGoogleV2On ? actions.ssoV2.buildStream : actions.sso.buildStream);

    const s = merge(style, {
        head: { backgroundColor: theme.palette.background.default, border: 'none' },
        headCell: { justifyContent: 'left', border: 'none', fontSize: 12 },
        row: { backgroundColor: theme.palette.background.component },
        oddRow: { backgroundColor: '#21223c' },
        bodyCell: { border: 'none' },
    });

    return (
        <Table
            headCellPadding={'none'}
            columns={columns}
            rows={Object.keys(thirdPartySvgs)
                .map(n => n.toLowerCase())
                .sort(a => (a === 'google' || a === 'facebook' || a === 'pinterest' || a === 'bing' || a === 'yahoo' ? -1 : 1))
                .map(name => ({ name, settings: extractSettings(name, settings) }))}
            lineaCount={false}
            search={false}
            pagination={false}
            sortable={false}
            justifyHeaderContent="left"
            RenderHead={RenderHead}
            RenderBody={RenderBody}
            style={s}
            rowsPerPage={20}
            isLoading={!sso.ready}
            isAuthorized={(model, action) => permissions.isAuthorized(profile.permissions, model, action)}
            cm={cm}
        />
    );
};

const merge = (
    source,
    target,
    strategies = {
        object: (a, b, stgs) => merge(a, b, stgs),
        string: (a, b) => a || b,
        number: (a, b) => a + b,
        array: (a, b) => [...(Array.isArray(a) ? a : []), ...(Array.isArray(b) ? b : [])],
        def: (a, b) => a || b,
    }
) =>
    [...Object.entries(source), ...Object.entries(target).filter(([key]) => !Object.keys(source).includes(key))]
        .map(([key, val, strategy = a => a]) => {
            if (typeof val !== typeof target[key]) strategy = strategies.def;
            else if (Array.isArray(val)) strategy = strategies.array;
            else if (strategies[typeof val]) strategy = strategies[typeof val];
            else strategy = strategies.def;
            return [key, val, strategy];
        })
        .reduce(
            (acc, [key, val, strategy]) => ({
                ...acc,
                [key]: strategy(val, target[key], strategies),
            }),
            {}
        );

function extractSettings(name = '', settingsArray = []) {
    const s = settingsArray.find(({ service }) => service === name) || { settings: {} };
    return s.settings;
}
