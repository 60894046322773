import React from 'react';
import styles from './style.module.scss';
import { DashboardItem, StackedBar } from '@widgets';
import { formatToK } from '@utils/dashboard';

export const BlockedEntities = ({ isLoading, dashboardCM, blockedCookies, blockedIps, blockedCBlocks, blockedUsers, isShowCookies, classNames }) => {
    const isOnlyCookies = blockedIps + blockedCBlocks === 0;
    if (blockedCookies <= 1) {
        blockedCookies = blockedUsers;
    }
    if (!isShowCookies) {
        blockedCookies = 0;
    }

    const total = blockedCookies + blockedIps + blockedCBlocks || 0;

    const barObj = [
        {
            label: dashboardCM.get('BlockedEntities.BlockedCookies'),
            value: blockedCookies,
            percent: (blockedCookies / total) * 100,
            color: 'rgba(234, 51, 133, 1)',
        },
        {
            label: dashboardCM.get('BlockedEntities.BlockedIP'),
            value: blockedIps,
            percent: (blockedIps / total) * 100,
            color: 'rgba(176, 41, 198, 1)',
        },
        {
            label: dashboardCM.get('BlockedEntities.BlockedCBlock'),
            value: blockedCBlocks,
            percent: (blockedCBlocks / total) * 100,
            color: 'rgba(105, 54, 251, 1)',
        },
    ];

    return (
        <DashboardItem
            isLoading={isLoading}
            data-test-selector="blocked entities"
            className={classNames}
            title={<div className={styles.title}>{dashboardCM.get('BlockedEntities.Title')}</div>}
            info={{ title: dashboardCM.get('BlockedEntities.Title'), content: dashboardCM.get('BlockedEntities.Description') }}
        >
            <div className={styles.content}>
                <div className={styles.replacedVisitsRow}>
                    <span>{dashboardCM.get('BlockedEntities.Content')}</span>
                </div>
                <div className={styles.replacedVisitsRow}>
                    <span className={styles.blockedEntitiesValue}>{formatToK(total)}</span>
                    {isOnlyCookies && (
                        <>
                            &nbsp; &nbsp;
                            <span>{dashboardCM.get('BlockedEntities.BlockedCookies')}</span>
                        </>
                    )}
                </div>
                {!isOnlyCookies && (
                    <div className={styles.replacedVisitsRow}>
                        <StackedBar barObj={barObj} />
                    </div>
                )}
            </div>
        </DashboardItem>
    );
};
