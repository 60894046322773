import { HubSpotFieldsStatus, HubSpotFieldsValues } from '../../../../types/hubspot';

export const hubSpotFieldsOptions: HubSpotFieldsValues[] = [{ label: 'Phone', value: 'phone' }, { label: 'Email', value: 'email' }];

export const getParsedHubSpotFieldsToSelect = (fields: HubSpotFieldsStatus): HubSpotFieldsValues[] => {
    const selectedFields = [];
    fields?.isPhone && selectedFields.push(hubSpotFieldsOptions[0]);
    fields?.isEmail && selectedFields.push(hubSpotFieldsOptions[1]);
    return selectedFields;
};
