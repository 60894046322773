import React from 'react';

import { Switch, FormControlLabel } from '@material-ui/core';
import styles from './styles.module.scss';

export const Toggler = ({ onChange = () => '', checked, name = '', label = '', ...rest }) => {
    return (
        <div>
            <FormControlLabel
                classes={{ root: styles.switch }}
                control={
                    <Switch
                        classes={{
                            root: styles.root,
                            switchBase: styles.switchBase,
                            thumb: styles.thumb,
                            track: styles.track,
                            checked: styles.checked,
                            colorSecondary: styles.colorSecondary,
                        }}
                        {...{ onChange, checked, name }}
                        {...rest}
                    />
                }
                label={<span className={styles.label}>{label}</span>}
            />
        </div>
    );
};
