import PaidMarketingIcon from '../../assets/svgs/PaidMarketing.svg';
import PaidMarketingDisabledIcon from '../../assets/svgs/PaidMarketingDisabled.svg';
import OnSiteIcon from '../../assets/svgs/OnSite.svg';
import OnSiteDisabledIcon from '../../assets/svgs/OnSiteDisabled.svg';
import PrivacyIcon from '../../assets/svgs/Privacy.svg';
import PrivacyDisabledIcon from '../../assets/svgs/PrivacyDisabled.svg';
import DataAndAnalyticsIcon from '../../assets/svgs/DataAndAnalytics.svg';
import DataAndAnalyticsDisabledIcon from '../../assets/svgs/DataAndAnalyticsDisabled.svg';
import LeadProtectionIcon from '../../assets/svgs/LeadProtectionIcon.svg';
import LeadProtectionDisabledIcon from '../../assets/svgs/LeadProtectionDisabledIcon.svg';
import { package2SolutionMap, solution2PackageMap } from '../../components/containers/management/constants';
import { CHEQ_ACQUISITION_ROUTE, CHEQ_ANALYTICS_ROUTE, CHEQ_SLP_ROUTE, CHEQ_DEFEND } from '../../utils/common';

export const CHEQ = 'CHEQ';
export type Product = {
    id: number;
    name: string;
    description: string;
    icon: any;
    disabledIcon: any;
    enabled: boolean;
    defaultRoute: string;
};
export const productsList: Product[] = [
    {
        id: 1,
        name: 'CustomerAcquisitionSecurity',
        description: 'PaidMarketingTooltip',
        icon: PaidMarketingIcon,
        disabledIcon: PaidMarketingDisabledIcon,
        enabled: false,
        defaultRoute: `/${CHEQ_ACQUISITION_ROUTE}/dashboard`,
    },
    {
        id: 2,
        name: 'OnSiteSecurity',
        description: 'OnSiteConversionTooltip',
        icon: OnSiteIcon,
        disabledIcon: OnSiteDisabledIcon,
        enabled: false,
        defaultRoute: `/${CHEQ_DEFEND}/dashboard`,
    },
    {
        id: 8,
        name: 'LeadProtection',
        description: 'SlpTooltip',
        icon: LeadProtectionIcon,
        disabledIcon: LeadProtectionDisabledIcon,
        enabled: false,
        defaultRoute: `/${CHEQ_SLP_ROUTE}/dashboard`,
    },
    {
        id: 5,
        name: 'PrivacyComplianceEnforcement',
        description: 'PrivacyTooltip',
        icon: PrivacyIcon,
        disabledIcon: PrivacyDisabledIcon,
        enabled: false,
        defaultRoute: '',
    },
    {
        id: 3,
        name: 'MarketingIntelligenceSecurity',
        description: 'DataAndAnalyticsTooltip',
        icon: DataAndAnalyticsIcon,
        disabledIcon: DataAndAnalyticsDisabledIcon,
        enabled: false,
        defaultRoute: `/${CHEQ_ANALYTICS_ROUTE}/dashboard`,
    },
];

export const getProductsList = (networkFeatures: any) => {
    const activeProducts = networkFeatures.map((item: { id: number; name: string }) => item.id) || [];
    return productsList.map(item => {
        return {
            ...item,
            enabled: activeProducts.includes(item.id),
        };
    });
};

export const createSolutionListByPackages = (networkFeatures: { id: number; name: string }[]) => {
    const resultObj = {};
    networkFeatures?.forEach(item => {
        if (!resultObj[item.id]) {
            resultObj[item.id] = true;
            // get the solution's package and run for its solutions. put false value (for showing it grayed-out)
            const pack = solution2PackageMap[item.id];
            package2SolutionMap[pack]?.forEach((solution: number) => {
                if (!resultObj[solution]) {
                    resultObj[solution] = false;
                }
            });
        } else {
            // for the current soultion put it as true - enabled
            resultObj[item.id] = true;
        }
    });
    return resultObj;
};

export const getProductByPackage = (networkFeatures: { id: number; name: string }[]) => {
    const resultObj = createSolutionListByPackages(networkFeatures);
    return (
        productsList &&
        productsList.reduce((acc: Product[], item: Product) => {
            if (resultObj[item.id] !== undefined) {
                acc.push({
                    ...item,
                    enabled: resultObj[item.id],
                });
            }
            return acc;
        }, [])
    );
};
