import React from 'react';
import CancelIcon from "../../assets/close.svg";
import widgets from "../../../../widgets";
import { Fade } from "@material-ui/core";
import styles from "./modal.module.scss";
const { Button } = widgets;

export default ({ cm, onClose, reportData, onApproveHandler }) => {
    const { name, action } = reportData;
    let title, text, subText, buttonText;
    switch (action) {
        case "delete_on_demand":{
            title = cm.get('ConfirmDeleteReport');
            text = `${cm.get('AreYouSureYouWantToDelete')} ${name}`
            buttonText = cm.get("DeletePopupButton")
            break
        }
        case "delete": {
            title = cm.get("DeleteScheduleReport")
            text = `${cm.get("DeletePopupAreYouSureText")} ${name}?`
            subText = cm.get("DeletePopupText")
            buttonText = cm.get("DeletePopupButton")
            break
        }
        case "pause": {
            title = cm.get("PauseScheduleReport")
            text = `${cm.get("PausePopupThisWillStopText")} ${name} ${cm.get('PausePopupExecutionText')}`
            subText = cm.get("PausePopupAreYouSureText")
            buttonText = cm.get("PausePopupButton")
            break
        }
        case "resume": {
            title = cm.get("ResumeScheduleReport")
            text = `${cm.get("ResumePopupAreYouSureText")} ${name}?`
            buttonText = cm.get("ResumePopupButton")
            break
        }
        case "affiliate_management": {
            title = cm.get("AffSettingPopupTitle")
            text = cm.get("AffSettingsPopupText")
            subText = cm.get("AffSettingsPopupSubText")
            buttonText = cm.get("AffSettingsPopupButtonText")
        }
    }

    return (
        <div className={styles.modal} onClick={onClose} data-test-selector="exportables-modal-container">
            <div className={styles.container}>
                <div className={styles.title}>
                    {title}
                    <img src={CancelIcon} alt="close" onClick={onClose} data-test-selector="exportables-modal-close-icon"/>
                </div>
                <div className={styles.content}>
                    <div className={styles.label}>
                        {text}<br/>
                        {subText}
                    </div>
                    <div className={styles.actions}>
                        <div className={styles.cancel} onClick={onClose}>{cm.get("Cancel")}</div>
                        <Button onClick={onApproveHandler}
                                className={styles.button}
                                data-test-selector="exportables-modal-button"
                        >
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
