import React, { useState } from 'react';

import Codemirror from '../codemirror';
import ContentManager from '../../../services/content-manager';
import CopyIcon from '../../svgs/copy/index';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Tooltip from '../tool-tip';
import Zoom from '@material-ui/core/Zoom';
import copy from 'clipboard-copy';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    codemirror: { display: 'inline-block' },
    actions: {
        marginLeft: 25,
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        display: 'flex',
        marginBottom: 5,
        textAlign: 'left',
        fontSize: 14,
        width: 'auto',
    },
    additionalInfoTitle: {
        flexBasis: '9%',
        fontSize: 12,
    },
    infoIcon: {
        height: 14,
        width: 14,
        marginLeft: 10,
        cursor: 'pointer',
        paddingTop: 5,
        '& :hover': {
            color: '#fe0072',
        },
    },
    dFlexRow: {
        display: 'flex',
        alignItems: 'center',
    }
}));

function RenderCopyDefault({ copy, value }) {
    const cm = ContentManager.by('AccountManagement.Tab.Tags.Form');
    const [dataTip, setDataTip] = useState(cm.get('Info.CopyToClipboard'));
    const onCloseTp = e => setTimeout(() => setDataTip(cm.get('Info.CopyToClipboard')), 500);

    return (
        <Tooltip interactive TransitionComponent={Zoom} leaveDelay={0} onClose={onCloseTp} placement={'top'} title={dataTip}>
            <div>
                <CopyIcon data-test-id={dataTip} onClick={() => copy(value) && setDataTip(cm.get('Info.Copied'))} style={{ cursor: 'pointer', margin: 0 }} />
            </div>
        </Tooltip>
    );
}

const CheqTag = ({ RenderCopy = RenderCopyDefault, RenderNext, tag, forceRefresh, type = 'INVOCATION', servingDomain, licences }) => {
    const styles = useStyles();
    const { id, name, tagHash } = tag;
    const { title, tooltip } = getStrings(type);
    const additionalTagAttributes = [];

    if (type === 'INVOCATION' && (licences.onSiteConversionEnabled || (licences.paidMarketingEnabled && licences.onSiteConversionEnabled))) {
        additionalTagAttributes.push('data-jsonp="onCheqResponse"');
    }

    const tagString = getScript(type, id, name, servingDomain, additionalTagAttributes.join(' '), tagHash);
    const isTagAdditionalInfo = type.startsWith("TAG_")
    const height = isTagAdditionalInfo ? 32 : 65
    const width = isTagAdditionalInfo ? 260 : 600

    return (
        <div className={isTagAdditionalInfo ? styles.dFlexRow : ''}>
            <div className={`${styles.title} ${isTagAdditionalInfo ? styles.additionalInfoTitle : ''}`}>
                <div>{title}</div>
                {!isTagAdditionalInfo && <Tooltip title={tooltip}>
                    <InfoOutlined className={styles.infoIcon} />
                </Tooltip>}
            </div>
            <div style={{ display: 'flex' }}>
                <div className={styles.codemirror}>
                    <Codemirror
                        forceRefresh={forceRefresh}
                        height={height}
                        width={width}
                        options={{ readOnly: true, mode: 'markdown', display: 'autoRefresh' }}
                        value={tagString}
                    ></Codemirror>
                </div>
                <div className={styles.actions}>
                    <RenderCopy value={tagString} copy={copy} />
                    {RenderNext && <RenderNext />}
                </div>
            </div>
        </div >
    );
};

export default CheqTag;

const getStrings = type => {
    let strings = {};
    switch (type) {
        case "INVOCATION":
            strings = {
                tooltip: ContentManager.get('AccountManagement.Info.Tab.Tags.Form.Title.Invocation'),
                title: ContentManager.get('AccountManagement.Tab.Tags.Form.Title.Invocation'),
            }
            break;
        case "NO_SCRIPT":
            strings = {
                tooltip: ContentManager.get('AccountManagement.Info.Tab.Tags.Form.Title.NoScript'),
                title: ContentManager.get('AccountManagement.Tab.Tags.Form.Title.NoScript'),
            }
            break;
        case "TAG_ID":
            strings = {
                title: ContentManager.get('TagId'),
            }
            break;
        case "TAG_HASH":
            strings = {
                title: ContentManager.get('TagHash'),
            }
            break;
        default:
            break;
    }

    return strings
}

const getPrefix = dataLocationId => {
    switch (dataLocationId) {
        case 1: {
            return '';
        }
        case 2: {
            return 'eu';
        }
        default:
            return '';
    }
};

const getScript = (type, id, name, servingDomain, additianoalAttributesString, tagHash) => {
    const cdnDomain = prefix => `https://${prefix}${servingDomain?.cdn}.${servingDomain?.domain}`;
    const serverDomain = `https://${servingDomain?.server}.${servingDomain?.domain}`;
    const cdnPath = `${servingDomain?.cdnPath}`;
    const encodedName = encodeURIComponent(name);
    const prefix = getPrefix(servingDomain.dataLocation);
    const invocationTag = tagHash
        ? `<script async
                src='${cdnDomain(prefix)}${cdnPath}${tagHash}.js'
                data-ch='${name}' class='ct_clicktrue_${id}' ${additianoalAttributesString}>
            </script>`
        : `<script async
                src='${cdnDomain(prefix)}/clicktrue_invocation.js?id=${id}
            data-ch='${name}" class="ct_clicktrue_${id}" ${additianoalAttributesString}>
        </script>`;

    const noScriptTag = tagHash
        ? `<noscript>
        <iframe src='${serverDomain}/ns/${tagHash}.html?ch=${encodedName}'
            width='0' height='0' style='display:none'></iframe>
        </noscript> `
        : `<noscript>
    <iframe src="${serverDomain}/ct/ns.html?id=${id}&ch=${encodedName}"
        width='0' height='0' style='display:none'></iframe>
        </noscript> `;

    switch (type) {
        case 'INVOCATION':
            return invocationTag;
        case 'NO_SCRIPT':
            return noScriptTag;
        case 'TAG_ID':
            return `${id}`;
        case 'TAG_HASH':
            return `${tagHash}`;
        default:
            return null;
    }
};
