import React, { useState } from 'react';
import styles from './realtime.module.scss';
import widgets from '../../../widgets';
import { PlayArrow } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { ClickTrueTagsService } from '../../../../services/api';

const { MultiSelectCheckbox } = widgets;

const selectStyle = () => ({
    container: {
        marginBottom: 0,
        border: 'solid 1px #14172c',
        height: '36px',
        width: '20rem',
        marginLeft: 12,
        marginRight: 12,
        borderRadius: '4px !important',
    },
    placeholder: {
        fontSize: 15,
    },
});

const AddApiKey = ({ cm, tags, keys, fetchData }) => {
    const ids = keys.map(item => item.id);
    const options = tags.filter(tag => !ids.includes(tag.value) && tag.value !== 'none' && tag.value !== '');
    const [selectedChannel, setSelectedChannel] = useState({});
    const [error, setError] = useState(false);

    const onGenerateHandler = async () => {
        if (!selectedChannel.value) {
            setError(true);
            return;
        }

        await ClickTrueTagsService.generateApiKey(selectedChannel.value);
        fetchData();
    };

    return (
        <>
            <div className={styles.row}>
                <span className={styles.title}>{cm.get('Channel')}</span>
                <MultiSelectCheckbox
                    onChange={value => setSelectedChannel(value)}
                    styles={selectStyle()}
                    options={options}
                    value={selectedChannel}
                    placeholder={cm.get('Channel')}
                />
                <div className={styles.row} onClick={onGenerateHandler}>
                    <IconButton size="small" data-test-selector="rti-settings-add-api-key-button">
                        <PlayArrow className={styles.button} fontSize="small" />
                    </IconButton>
                    <div className={styles.button}>{cm.get('RTIGenerateApiKey')}</div>
                </div>
            </div>
            {error && <div className={styles.error}>Please select a channel</div>}
        </>
    );
};

export default AddApiKey;
