import React, { useState } from 'react';

import { ReactComponent as DoubleRightArrow } from '../../svgs/icons/double-right-arrow.svg';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import cx from 'classnames';
import styles from './tool-tip.module.scss';

export const ToolTip = ({ title, content, action, children, arrow }) => {
    const isInteractive = !!(action && action.handler);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleOnAction = () => {
        action.handler();
        handleClose();
    };

    const tooltipContent = content && (
        <>
            <div className={cx(styles.title, styles.text)}>{title}</div>
            <div className={styles.text}>{content}</div>
            {isInteractive && (
                <div className={styles.buttonContainer} onClick={handleOnAction}>
                    <div className={styles.button}>{action.label}</div>
                    <DoubleRightArrow />
                </div>
            )}
        </>
    );

    return (
        <MuiTooltip
            arrow={arrow}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            classes={{ tooltip: styles.root }}
            title={tooltipContent}
            placement="top"
            leaveDelay={300}
            interactive={isInteractive}
        >
            {children}
        </MuiTooltip>
    );
};

export default ToolTip;
