import ApproveGoogleAdsV2 from './ApproveGoogleAdsV2';
import Button from '../../../../widgets/button/index';
import CheqUI from '@combotag/cheq-ui';
import ProtectionManagementV2 from './protection-management/indexV2';
import React, { useEffect, useMemo, useState } from 'react';
import SubmitGoogleAdsIdV2 from './SubmitGoogleAdsIdV2';
import styles from './style.module.scss';
import widgets from '../../../../widgets';
import { useDispatch } from 'react-redux';
import { triggerSnackbarMessage } from '../../../../../redux/actions/snackbar';
import { useSplit } from '@hooks';
import { constants as splitConstants } from '@utils/split.io';

const { Widgets, Meiosis } = CheqUI.React;
const { Box } = Widgets;
const { useActions, useMeiosis } = Meiosis;
const { Stepper } = widgets;

export const GoogleIntegratorV2 = () => {
    const dispatch = useDispatch();
    const { isOn: isGoogleMultiMccOn } = useSplit(splitConstants.PARADOME_GOOGLE_MULTI_MCC);
    const actions = useActions();
    const ssoV2 = useMeiosis(actions.ssoV2.buildStream);
    const [isGoogleIntegrated, setIsGoogleIntegrated] = useState(false);

    const defaultStep = useMemo(() => {
        if (ssoV2.ssos.google) {
            const googleStatuses = ssoV2.ssos.google.statuses;
            if (googleStatuses && Object.values(googleStatuses).length && Object.values(googleStatuses).some(status => status === 'PENDING')) {
                return 1;
            }
            if (
                (googleStatuses && Object.values(googleStatuses).length && Object.values(googleStatuses).every(status => status === 'MANAGED')) ||
                ssoV2.ssos.google.refresh_token ||
                ssoV2.ssos.google.is_sso ||
                ssoV2.ssos.google.skippedAdwords
            ) {
                return 2;
            }
            return 0;
        }
    }, [ssoV2.ssos.google]);

    const isMccAccountConnected = useMemo(() => {
        if (ssoV2?.ssos?.google) return ssoV2.ssos.google.is_mcc && Object.values(ssoV2.ssos.google.statuses).every(status => status === 'MANAGED');
        return null;
    }, [ssoV2.ssos.google]);

    useEffect(() => {
        setIsGoogleIntegrated(ssoV2?.ssos?.google?.integrated);
    }, [ssoV2?.ssos?.google?.integrated]);

    return (
        <div className={styles.root}>
            <Stepper defaultSelected={defaultStep}>
                {({ next, jumpTo }) => (
                    <div subtitle={'Submit Google Ads ID'}>
                        <SubmitGoogleAdsIdV2
                            onSsoConnect={() => jumpTo(2)}
                            onSkipAdwards={() => jumpTo(2)}
                            onMccInvite={next}
                            styles={styles}
                            onCancel={() => {
                                actions.dialog.hide();
                            }}
                            isMccAccountConnected={isMccAccountConnected}
                        />
                    </div>
                )}
                {({ next, prev }) => {
                    return (
                        <div subtitle={'Approve Google Ads Access Request'}>
                            <ApproveGoogleAdsV2 onCancelInvitation={prev} onApproved={next} styles={styles} />
                        </div>
                    );
                }}
                {({ next, jumpTo }) => (
                    <div subtitle={'Protection Management'} handleStepClick={isMccAccountConnected && isGoogleMultiMccOn ? () => jumpTo(2) : null}>
                        {isGoogleIntegrated ? (
                            <ProtectionManagementV2 styles={styles} onCancel={actions.dialog.hide} onAddAccount={() => jumpTo(0)} onSave={() => next()} />
                        ) : (
                            <ProtectionManagementV2 styles={styles} onInvalidated={() => jumpTo(0)} onAddAccount={() => jumpTo(0)} onSave={() => next()} />
                        )}
                    </div>
                )}
                {() => (
                    <div style={{ padding: 15 }} subtitle={'Setup Completed'}>
                        <Box className={styles.completed}>SETUP COMPLETED!</Box>
                        <Button
                            style={{ width: 150 }}
                            onClick={() => {
                                actions.dialog.hide();
                                dispatch(triggerSnackbarMessage({ message: 'Protection management configuration successfully saved.' }));
                            }}
                        >
                            GOT IT!
                        </Button>
                    </div>
                )}
            </Stepper>
        </div>
    );
};
