import { FC, useState } from 'react';
/* @ts-ignore */
import { useContentManager } from '@hooks';
import styles from '../styles.module.scss';
import { Input } from '../components/input';
import { CircularProgress } from '@material-ui/core';
import { CustomModal } from '../../../exportables/ui/custom-modal';
import { MarketoFieldsValues } from 'types/marketo';

type MarketoConnectorProps = {
    isConnected: boolean;
    isDisconnectLoading: boolean;
    marketoFieldsValues: MarketoFieldsValues;
    setMarketoFieldsValues: React.Dispatch<React.SetStateAction<MarketoFieldsValues>>;
    marketoXSignature: string;
    handleConnect: (marketoFieldsValues: MarketoFieldsValues) => void;
    handleDisconnect: () => void;
};

export const MarketoConnector: FC<MarketoConnectorProps> = props => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const { isConnected, handleConnect, handleDisconnect, marketoFieldsValues, isDisconnectLoading, setMarketoFieldsValues, marketoXSignature } = props;
    const cm = useContentManager();

    const onChange = (e: string | number, whichInput: string) => {
        switch (whichInput) {
            case 'clientId':
                setMarketoFieldsValues((prevProps: MarketoFieldsValues) => ({
                    ...prevProps,
                    clientId: e.toString(),
                }));
                break;
            case 'clientSecret':
                setMarketoFieldsValues((prevProps: MarketoFieldsValues) => ({
                    ...prevProps,
                    clientSecret: e.toString(),
                }));
                break;
            case 'munchkinId':
                setMarketoFieldsValues((prevProps: MarketoFieldsValues) => ({
                    ...prevProps,
                    munchkinId: e.toString(),
                }));
                break;
            default:
                break;
        }
    };
    const isNotAllInputsFilled = Object.values(marketoFieldsValues).some((value: string) => value === '');

    return (
        <div className={`${styles.connectorContainer} ${marketoXSignature && styles.marketoXSignatureGap}`}>
            <div className={styles.inputsContainer}>
                <div className={styles.inputs}>
                    <div className={styles.enrichedFieldsTitle}>
                        <span>{cm.get('Marketo.clientId')}</span>
                        <Input
                            value={marketoFieldsValues.clientId}
                            placeholder={'Enter Client ID'}
                            testSelector={''}
                            onChange={e => onChange(e, 'clientId')}
                            disabled={isConnected}
                        />
                    </div>
                    <div className={styles.enrichedFieldsTitle}>
                        <span>{cm.get('Marketo.clientSecret')}</span>
                        <Input
                            value={marketoFieldsValues.clientSecret}
                            placeholder={'Enter Client Secret'}
                            defaultType={'password'}
                            testSelector={''}
                            onChange={e => onChange(e, 'clientSecret')}
                            disabled={isConnected}
                        />
                    </div>
                    <div className={styles.enrichedFieldsTitle}>
                        <span>{cm.get('Marketo.manchkinId')}</span>
                        <Input
                            value={marketoFieldsValues.munchkinId}
                            placeholder={'Enter Munchkin ID'}
                            testSelector={''}
                            onChange={e => onChange(e, 'munchkinId')}
                            disabled={isConnected}
                        />
                    </div>
                </div>
            </div>
            {isConnected ? (
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            className={`
                    ${styles.connectorButton}
                    ${styles.disconnectButton}
                    ${isDisconnectLoading && styles.disabledSaveDisconnectButton}`}
                            onClick={() => !isDisconnectLoading && setShowPopup(true)}
                        >
                            {isDisconnectLoading && (
                                <span style={{ color: '#788397' }}>
                                    <CircularProgress size={14} color="inherit" />
                                </span>
                            )}
                            <span className={`${styles.connectionText} ${isDisconnectLoading && styles.disconnectLoaderButtonText}`}>{cm.get('Disconnect').toUpperCase()}</span>
                        </div>
                        {isDisconnectLoading && <div className={styles.disconnectTextLoading}>{cm.get('DisconnectMarketoLoadingText')}</div>}
                    </div>
                    {marketoXSignature && (
                        <div className={styles.enrichedFieldsTitle}>
                            <span>{cm.get('Marketo.xSignature')}</span>
                            <Input value={marketoXSignature} onChange={() => {}} disabled={true} showEyeIcon={true} marketoXSignature={marketoXSignature} />
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className={`${styles.connectorButton} ${styles.connectButton} ${isNotAllInputsFilled && styles.disabledButton}`}
                    onClick={() => !isNotAllInputsFilled && handleConnect(marketoFieldsValues)}
                >
                    <span className={styles.connectionText}>{cm.get('Connect').toUpperCase()}</span>
                </div>
            )}
            {showPopup ? (
                <CustomModal
                    onClose={() => setShowPopup(false)}
                    title={cm.get('DisconnectMarketoModalTitle')}
                    description={cm.get('DisconnectMarketoModalDescription')}
                    onSubmitButtonText={cm.get('Yes')}
                    onCancelButtonText={cm.get('Cancel')}
                    onSubmit={handleDisconnect}
                />
            ) : (
                <></>
            )}
        </div>
    );
};
