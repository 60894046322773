import styles from './index.module.scss';
import {
    Table,
    TableHead,
    TableContainer,
} from '@material-ui/core';
import ArrowIcon from '../../../assets/svgs/arrow.svg';

import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    ColumnDef,
    SortingState,
    getSortedRowModel
} from '@tanstack/react-table';
import { FC, useState } from 'react';

type CustomTableProps = {
    data: object[];
    columns: ColumnDef<any, any>[];
    rowSubmit?: (data?: any) => void;
};

export const CustomTable: FC<CustomTableProps> = (props) => {
    const { data, columns, rowSubmit } = props;
    const [sorting, setSorting] = useState<SortingState>([]);
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting
        }
    });

    return (

        <TableContainer className={styles.container}>
            <Table className={styles.tableContent}>
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                const meta: any = header.column.columnDef.meta;
                                return (
                                    <th
                                        className={`${styles.headCell} ${header.column.getIsSorted() && styles.active} ${meta.isSortable && styles.clickable}`}
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                        <span className={styles.sortIcon}>
                                            {header.column.getIsSorted() ? (
                                                header.column.getIsSorted() === 'desc' ? (
                                                    <img src={ArrowIcon} alt="arrow" className={styles.iconUp} />
                                                ) : (
                                                    <img src={ArrowIcon} alt="arrow" />
                                                )
                                            ) : meta.isSortable && <img src={ArrowIcon} alt="arrow" />
                                            }
                                        </span>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </TableHead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} className={styles.row} onClick={() => rowSubmit && rowSubmit(row.original)}>
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <td key={cell.id} className={styles.tableData}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </TableContainer>
    );
};
