import { snackBar as types } from '../actions';

export const snackbar = (state = { message: '' }, action) => {
    switch (action.type) {
        case types.SNACKBAR_MESSAGE: {
            return { ...state, message: action.payload.message };
        }
        default:
            return state;
    }
};
