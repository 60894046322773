import { useQuery } from 'react-query';
import { queryOptions } from '../../components/containers/exportables/create-report/utils';
import skewed from '../../services/api/skewed.service';
import { campaignTypeNameConverter, getPlatformLabel } from '../../redux/thunks';
/**
 *
 * @param {boolean} settingsMis
 * @returns
 */
export const useGetFilters = settingsMis => {
    return useQuery({
        queryKey: ['getFilters', JSON.stringify(queryOptions)],
        queryFn: () => skewed.getCasFilterValues(queryOptions),
        enabled: settingsMis,
        select: mapFilters,
        cacheTime: 10000,
    });
};

export const mapFilters = res => {
    let eventTypeFilterList = Object.keys(res).reduce((newObject, key) => {
        const mappedKey = key;
        newObject[mappedKey] = res[mappedKey].map(filter => {
            if (typeof filter === 'string') {
                return {
                    value: filter,
                    label: key === 'platforms' ? getPlatformLabel(filter) : key === 'campaignType' ? campaignTypeNameConverter(filter) : filter,
                };
            } else {
                return {
                    value: filter.id,
                    label: filter.name,
                };
            }
        });
        return newObject;
    }, {});
    return { data: eventTypeFilterList };
};
