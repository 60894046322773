import * as api from '@services/api/rti-dashboard';
import { DashboardItem, ToolTip, OverflowTip } from '@widgets';
import { useContentManager } from '@hooks';
import { formatToK, getPercentage } from '@utils/common';
import { useMemo } from 'react';

import { useQuery } from 'react-query';

import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import { useSplit } from '@hooks';
import { constants } from '@utils/split.io';

import { Divider, Table, TableRow, TableCell, TableBody, TableHead, TableContainer } from '@material-ui/core';

import styles from './client-interceptions.module.scss';

const Interceptions = ({ options, isSettingsLoading }) => {
    const cm = useContentManager();
    const { isOn: isUniqueVisitors } = useSplit(constants.SPLIT_RTI_UNIQUE_VISITORS);

    const { isLoading, isError, data = { urls: [] } } = useQuery([api.RTIAPIkeys.CLIENT_INTERCEPTIONS, options], () => api.getClientSideInterceptions(options));

    const { urls = [], topURL = {} } = useMemo(() => {
        const urls = data?.urls?.sort((a, b) => b.total - a.total);
        const [topURL] = urls;
        return { urls, topURL };
    }, [data]);

    return (
        <DashboardItem
            isError={isError}
            isLoading={isLoading || isSettingsLoading}
            title={<div className={styles.title}>{cm.get('Client-SideIntegration')}</div>}
            data-test-selector="rti-client-side-interceptions"
        >
            <div className={styles.content}>
                <div className={styles.summary}>
                    <div className={styles.title}>
                        <span>{cm.get('Summary')}</span>
                    </div>

                    <div className={styles.interceptionsDataRow}>
                        <span className={styles.value}>{data?.invalid?.toLocaleString()}</span>
                        <div className={styles.legend}>
                            <div className={styles.legendTitle}>{cm.get('SignaledInvalid')}</div>
                            <ToolTip content={cm.get('InvalidInterceptionsDescription')} title={cm.get('SignaledInvalid')}>
                                <InfoIcon className={styles.icon} cursor="pointer" />
                            </ToolTip>
                        </div>
                    </div>
                    {isUniqueVisitors && (
                        <div className={styles.interceptionsDataRow}>
                            <span className={styles.value}>{data?.uniqueInvalidVisitors?.toLocaleString()}</span>
                            <div className={styles.legend}>
                                <div className={styles.legendTitle}>{cm.get('UniqueVisitors')}</div>
                                <ToolTip content={cm.get('UniqueVisitorsDescription')} title={cm.get('UniqueVisitors')}>
                                    <InfoIcon className={styles.icon} cursor="pointer" />
                                </ToolTip>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.divider}>
                    <Divider orientation="vertical" style={{ background: 'black' }} />
                </div>
                <div className={styles.intercetptionsTable}>
                    <div className={styles.title}>{cm.get('PagesWithTheLargestNumberOfInterceptions')}</div>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="50%" classes={{ stickyHeader: styles.tableHeaderCell }} variant="head">
                                        <div className={styles.cellContent}>
                                            <span>{cm.get('URL')}</span>
                                        </div>
                                    </TableCell>
                                    <TableCell width="40%" align="left" classes={{ stickyHeader: styles.tableHeaderCell }} variant="head">
                                        <div className={styles.cellContent}>
                                            <div className={styles.legend}>
                                                <span>{cm.get('Interceptions')}</span>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell width="10%" classes={{ stickyHeader: styles.tableHeaderCell }} variant="head">
                                        <div className={styles.cellContent}>{`% ${cm.get('OutOfTotal')} ${formatToK(data.invalid)}`}</div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {urls?.map((row, i) => {
                                    return (
                                        <TableRow hover key={row.url}>
                                            <TableCell>
                                                <div className={styles.url}>
                                                    <OverflowTip>{row.url}</OverflowTip>
                                                </div>
                                            </TableCell>
                                            <TableCell align="left" className={styles.invalidPerPolicyVSTotal}>
                                                <div style={{ width: '100%' }}>
                                                    <div className={styles.bar} style={{ width: `${getPercentage(row.total, topURL.total)}%` }} />
                                                </div>
                                                <div className={styles.value}>
                                                    <span className={styles.total}>{row.total.toLocaleString()}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>{getPercentage(row.total, data.invalid)}%</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </DashboardItem>
    );
};

export default Interceptions;
