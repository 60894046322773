import React from 'react'
import styles from './style.module.css';
import { useTheme } from '@material-ui/styles';
import Progress from '../progress/index';
import NoData from '../no-data/index';

export default ({
    isLoading,
    titleParser = defaultTitleParser,
    valueParser = defaultValueParser,
    lineHeight = 15,
    height = 350,
    paddingTop = 0,
    width = 500,
    style = {},
    name = 'category',
    limit = 5,
    colors = ['#fe0072', '#c92477', '#ba2d93', '#b826db', '#7d2aa0', '#6b1d95', '#4a1f74'],
    RenderEmpty = props=><NoData {...props}/>,
    data = [
        ['Advertising', 98],
        ['Customer', 45],
        ['Pricing', 31],
        ['Engine', 20],
        ['Transactions', 9],
        ['Awesome', 7],
        ['Cats', 6],
    ],
    maxVal = 100,
    ...props }) => {
    const theme = useTheme();
    const d = data.slice(0, limit);

    const [, max] = maxVal ? [, maxVal]
        : d.length ? d.reduce((a, b) => a[1] > b[1] ? a : b)
            : [, 0];

    return <div
        className={styles['container']}
        style={{
            textAlign:'center',
            ...style,
            height: height - paddingTop,
            width, backgroundColor: theme.palette.background.component,
        }}{...props} >
        {isLoading ? <Progress style={{opacity:0.5, marginTop:140}}/> :
        <div style={{padding : '42px 15px', textAlign:'left'}}>
        {d.length
            ? d.map(([label, value], idx) => <ScoreItem titleParser={titleParser} valueParser={valueParser} label={label} value={value} max={max} idx={idx + 1} key={idx} color={colors[idx]} lineHeight={lineHeight} />)
            : <RenderEmpty  height={260}/>
        }
        </div>
        }
    </div>
}
const ScoreItem = ({ value, max, label, idx, color, lineHeight, valueParser, titleParser }) => <div className={styles['item-container']}>
        <div className={styles['label-container']}><span>{titleParser(label)}</span></div>
        <div className={styles['line-container']}>
            <div className={`${styles['value-container']} ${styles['index-container']}`} style={{ color }}>0{idx}</div>
            <div className={styles['meter-background']} style={{ height: lineHeight }}>
                <div className={styles['meter-container']} style={{ height: lineHeight, width: `${(value / max * 100).toFixed(2)}%` }} />
                {/* <div className={styles['anti-meter-container']} style={{ width: calcMeter(100 - (value / max * 100), max) }} /> */}
            </div>
            <div className={styles['value-container']} >{valueParser(value)}</div>
        </div>
    </div>

const defaultValueParser = value => value;
const defaultTitleParser = title => title;

const calcMeter = (val, max) => Math.min(val, max) * 4