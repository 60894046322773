import theme from '../../../../../styles/theme';

export const style = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    tableWrapper: {
        width: '100%',
        overflow: 'hidden',
        flex: 1,
    },
    head: {
        top: 0,
        backgroundColor: '#14172c',
        position: 'sticky',
        maxWidth: '150',
        padding: '13px 5px 13px 5px',
        border: 'none',
        boxSizing: 'border-box',
    },
    headerRow: {
        backgroundColor: '#14172c',
        color: '#fff',
        position: 'sticky',
    },
    headCell: {
        cursor: 'pointer',
        position: 'sticky',
        color: '#fff',
        backgroundColor: theme.palette.background.default,
        top: 0,
        width: '1px',
        padding: '13px 5px 13px 5px',
        boxSizing: 'border-box',
    },
    tableBody: {
        overflowX: 'overlay',
        width: '100%',
        height: '100%',
        '&::-webkit-scrollbar': {
            display: 'block',
            width: '10px',
            height: '10px',
        },
        '&::-webkit-scrollbar:hover': {
            opacity: 0.8,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundImage: 'unset !important',
            backgroundColor: '#383851 !important',
            outline: '0',
            borderRadius: '1rem',
        },
    },
    row: {
        backgroundColor: '#383851',
        color: '#fff',
    },
    totalRow: {
        backgroundColor: 'rgb(56, 56, 68)',
        color: '#fff',
    },
    actions: {
        display: 'flex',
    },
    bodyCell: {
        padding: '14px 5px 14px 5px',
        borderBottom: '1px solid #282b3f',
        boxSizing: 'border-box',
    },
    tableFooter: {
        height: 51,
    },
    pagination: {
        bottom: 15,
    },
    search: {
        bottom: 13,
    },
};
