import React, {useState} from "react";
import {VisibilityOff, VisibilityOutlined} from "@material-ui/icons";
import styles from './input.module.scss';

export default ({ value, name, onChange, placeholder, showEyeIcon, testSelector }) => {
    const [showPass, setShowPass] = useState(false);
    const [type, setType] = useState(showEyeIcon ? 'password' : 'text')

    const onIconClicked = () => {
        setType(type === 'text' ? 'password' : 'text');
        setShowPass(!showPass)
    }

    return (
        <div className={styles.container}>
            <input className={`${styles.input} ${name === 'accessSecret' && styles.small}`}
                   data-test-selector={testSelector}
                   value={value}
                   name={name}
                   onChange={e => onChange(e)}
                   placeholder={placeholder}
                   type={type}
            />
            {showEyeIcon && (
                <div onClick={onIconClicked} className={styles.eyeIcon}>
                    {showPass ?  <VisibilityOutlined/> : <VisibilityOff/>}
                </div>
            )}
        </div>
    )
}