import { Products } from 'components/containers/management/constants';

export const CAS_REPORT_HISTORY_DEFAULT_COLUMNS = [
    'totalClicks',
    'validClicks',
    'invalidClicks',
    'badBotActivity',
    'maliciousActivity',
    'suspiciousActivity',
    'customRulesActivity',
    'invalidClickRate',
];

export const OSS_REPORT_HISTORY_DEFAULT_COLUMNS = [
    'eventType',
    'totalApiCalls',
    'validResponses',
    'invalidResponses',
    'maliciousActivity',
    'suspiciousActivity',
    'badBotActivity',
    'customRulesActivity',
    'invalidResponseRate',
];

export const SLP_REPORT_HISTORY_DEFAULT_COLUMNS = ['totalLeads', 'benignLeads', 'susipiciousLeads', 'maliciousLeads'];

export const getDefaultColumns = (license: number) => {
    if (license === Products.OSS) return OSS_REPORT_HISTORY_DEFAULT_COLUMNS;
    else if (license === Products.CAS) return CAS_REPORT_HISTORY_DEFAULT_COLUMNS;
    return SLP_REPORT_HISTORY_DEFAULT_COLUMNS;
};
