import React from 'react';
import CheqUI from '@combotag/cheq-ui';
import styles from './style.module.css';

const { Title : CuiTitle } = CheqUI.React.Widgets;

const Title = ({ style, ...props }) => <CuiTitle
    className={styles['container']} style={{ height: 30, marginTop: 25, ...style }}
    {...props} />

export default Title;