import {skewed as types} from '../actions';

const initialState = {
    selectedTab: undefined,
    currentReportsFilters: {
        type: [],
        users: [],
        status: [],
        frequency: [],
        searchValue: ''
    }
};

export const skewed = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_TAB: {
            const { payload: selectedTab } = action;
            return { ...state, selectedTab };
        }
        case types.SET_REPORT_FILTERS: {
            return { ...state, currentReportsFilters: action.payload}
        }
        default: {
            return state;
        }
    }
};
