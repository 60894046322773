import * as React from "react"

function BlockedIcon(props) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            style={{marginBottom: 0}}
        >
            <circle cx={9} cy={9} r={8.5} stroke="#fff" />
            <path
                d="M9 14.917s4.333-2.167 4.333-5.417V5.708L9 4.083 4.667 5.708V9.5C4.667 12.75 9 14.917 9 14.917z"
                stroke="#fff"
                strokeWidth={1.333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default BlockedIcon;
