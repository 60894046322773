import { useEffect, useLayoutEffect, useRef } from 'react';
import { TweenMax } from "gsap";

export default function (tweenEffect, on) {
    const tween = useRef(null);

    useEffect(() => () => tween.current && tween.current.kill(), []);
    useLayoutEffect(() => {
        tween.current = tweenEffect(TweenMax);
    }, on);
}
