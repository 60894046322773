import { httpRequest, methods } from '../http';
import { getTestConnectionFailedMessages } from '@utils/skewed';
class SkewedAnalytics {
    #onDemandEndpoint = 'skewed_analytics/on_demand_report';
    #schdeuleEndPoint = 'skewed_analytics/scheduled_report';
    #BASE_URL = 'api/v2/cas-dashboard';

    getOnDemandReports = params => {
        const { response } = httpRequest({ method: methods.get, pathname: this.#onDemandEndpoint, params });
        return response;
    };

    postOnDemandReports = body => {
        const { response } = httpRequest({ method: methods.post, pathname: this.#onDemandEndpoint, body });
        return response;
    };

    deleteOnDemandReport = params => {
        const { response } = httpRequest({ method: methods.delete, pathname: this.#onDemandEndpoint, params });
        return response;
    };

    deleteScheduledReport = () => {
        const { response } = httpRequest({ method: methods.delete, pathname: this.#schdeuleEndPoint });
        return response;
    };

    getScheduledReport = () => {
        const { response } = httpRequest({ method: methods.get, pathname: this.#schdeuleEndPoint });
        return response;
    };

    putScheduledReport = async body => {
        const { accessKey, accessSecret, bucket, cloudStorageProvider } = body;
        try {
            const { response: testConnection } = httpRequest({ method: methods.post, pathname: `${this.#schdeuleEndPoint}`, body: { accessKey, accessSecret, bucket } });
            await testConnection;
        } catch (err) {
            const { message } = err;
            throw getTestConnectionFailedMessages(cloudStorageProvider, message);
        }
        try {
            const { response } = httpRequest({ method: methods.put, pathname: this.#schdeuleEndPoint, body });
            const res = await response;
            return res;
        } catch (err) {
            throw err.message;
        }
    };

    getCasFilterValues = async ({ reqDates }) => {
        try {
            const { response } = await httpRequest({
                method: methods.post,
                pathname: `${this.#BASE_URL}/filters`,
                body: { startDate: reqDates[0], endDate: reqDates[1], isAffiliateProtectionOn: 1 },
            });
            return response;
        } catch (error) {
            return error;
        }
    };

    getCasFilterValuesWithSearchTerm = async ({ reqDates, filterName, queryString }) => {
        try {
            const { response } = httpRequest({
                method: methods.post,
                pathname: `${this.#BASE_URL}/filter-by-search-term?f=${filterName}&q=${queryString}`,
                body: { startDate: reqDates[0], endDate: reqDates[1] },
            });
            return response;
        } catch (error) {
            return error;
        }
    };

    testS3Connection = ({ accessKey = '', accessSecret = '', bucket = '' }) => {
        const { response } = httpRequest({ method: methods.post, pathname: `${this.#schdeuleEndPoint}`, body: { accessKey, accessSecret, bucket } });
        return response;
    };
}

const skewedService = new SkewedAnalytics();

export default skewedService;
