import AddApiButton from 'components/widgets/add-api-button';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddGoogleUniversalIntegration from './addIntegration';
import { ValueLabel } from 'types/types';
import styles from '../styles.module.scss';
import { DeleteForever } from '@material-ui/icons';
import { showDialog } from '../../../../../widgets/dialog';
import { deleteGoogleUniversalPixel } from '../../../../../services/api';
import { googleAnalyticsClient } from 'services/api/ga-client.service';
import { UaTrackingId } from 'types/types';
import Progress from 'components/widgets/progress';
import classNames from 'classnames';

interface IProps {
    cm: any;
    readonly: boolean;
}

const GoogleUniversalAnalytics: FC<IProps> = ({ cm, readonly }) => {
    const clickTrueTagsState: any = useSelector<any>(state => state.clickTrueTags);
    const [uaTrackingIds, setUaTrackingIds] = useState<UaTrackingId[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showCreateIntegration, setShowCreateIntegration] = useState(false);
    const [channelOptions, setChannelOptions] = useState<ValueLabel[]>([]);

    const handleDeletePixel = async (tagId: number, pixelId: string) => {
        await deleteGoogleUniversalPixel({ tagId, pixelId });
        setUaTrackingIds(uaTrackingIds.filter((uaTrackingId: UaTrackingId) => uaTrackingId.pixelId !== pixelId || tagId !== uaTrackingId.tagId));
    };

    const handleShowDeletePopup = async (uaTrackingId: UaTrackingId) => {
        const dialogContent = {
            title: cm.get('DeleteIntegration'),
            children: `${cm.get('DeleteIntegrationReassurance')} ${uaTrackingId?.tagLabel}?`,
            userInteractive: { resolveText: cm.get('Yes'), rejectText: cm.get('Cancel') },
        };
        const confirmDialog = await showDialog(dialogContent);
        !!confirmDialog && (await handleDeletePixel(uaTrackingId.tagId, uaTrackingId.pixelId));
    };

    useEffect(() => {
        googleAnalyticsClient.getGaUniversalPixels().then(res => {
            setUaTrackingIds(res);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (clickTrueTagsState?.tags?.length) {
            setChannelOptions(clickTrueTagsState.tags.map((tag: any) => ({ value: tag.id, label: tag.name })));
        }
    }, [clickTrueTagsState.tags]);

    return (
        <div className={styles.secretKey}>
            <div className={styles.createContainer}>
                <div className={classNames(styles.row, { [styles.disabled]: readonly })}>
                    <AddApiButton onClickHandler={() => setShowCreateIntegration(!showCreateIntegration)} text={cm.get('AddIntegration')} />
                </div>
                {showCreateIntegration && !isLoading && (
                    <AddGoogleUniversalIntegration cm={cm} channelOptions={channelOptions} uaTrackingIds={uaTrackingIds} setUaTrackingIds={setUaTrackingIds} />
                )}
            </div>
            {isLoading ? (
                <Progress />
            ) : (
                uaTrackingIds?.map((uaTrackingId: UaTrackingId, index) => {
                    return (
                        <div className={styles.row} key={index}>
                            <div className={`${styles.dFlexCol} ${styles.mediumInput}`}>
                                <span className={styles.label}>{cm.get('Channel')}</span>
                                <div className={`${styles.textContainer} ${styles.name}`}>
                                    <div className={styles.text}>{uaTrackingId.tagLabel}</div>
                                </div>
                            </div>
                            <div className={`${styles.dFlexCol} ${styles.smallInput}`}>
                                <span className={styles.label}>{cm.get('TrackingId')}</span>
                                <div className={styles.textContainer}>
                                    <div className={styles.text}>{uaTrackingId.pixelId}</div>
                                </div>
                            </div>
                            <div className={`${styles.dFlexCol} ${styles.smallInput}`}>
                                <span className={styles.label}>{cm.get('DimensionIndex')}</span>
                                <div className={styles.textContainer}>
                                    <div className={styles.text}>{uaTrackingId.dimensionIndex}</div>
                                </div>
                            </div>
                            <div className={classNames(styles.sideIcons, { [styles.disabled]: readonly })}>
                                <DeleteForever fontSize="medium" style={{ cursor: 'pointer' }} onClick={() => handleShowDeletePopup(uaTrackingId)} />
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
};

export default GoogleUniversalAnalytics;
