import { FC, useEffect, useState } from 'react';
import styles from './PixelGuard.module.scss';
import { pixelGuardService } from '../../../../services/api/pixelGuard.service';
import { PixelSettingsType } from './types';
import { CircularProgress, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
/* @ts-ignore */
import { useContentManager } from '@hooks';
import { AddCircleOutline } from '@material-ui/icons';
import List from './components/Items';
import AddPixel from './components/AddPixel/AddPixel';
/* @ts-ignore */
import mixpanel from '@services/mixpanel';

const PixelGuard: FC = () => {
    const cm = useContentManager();
    const [data, setData] = useState<PixelSettingsType[]>([]);
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const [selected, setSelected] = useState({});
    const [loading, setLoading] = useState(true);
    const [showAddItem, setShowAddItem] = useState(false);
    let { tags } = useSelector((state: { clickTrueTags: any }) => state.clickTrueTags);
    tags = tags.filter((item: any) => !selectedTags.includes(item.id));

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        pixelGuardService.getSettings().then(res => {
            setData(res.settings);
            setSelectedTags(res.settings.map((item: PixelSettingsType) => item.tagId));
            setLoading(false);
        });
    };

    const onGenerateHandler = async () => {
        setSelected({});
        await pixelGuardService.addPixelGuard(selected['value']);
        // if there are no pxg settings currently, add ceg feature to third_party_integrarions table, and remove pxg feature
        if (data.length === 0) {
            await Promise.all([pixelGuardService.enableFeature(), pixelGuardService.disableFeature(1)]);
        }
        mixpanel.postEvent('Pixel guard - generate parameters', { tagId: selected['value'] });
        await fetchSettings();
    };

    const onDelete = async (tagId: number) => {
        setLoading(true);
        pixelGuardService.deletePixel(tagId).then(async () => {
            // if there is only pxg settings currently, remove ceg feature from third_party_integrarions table
            if (data.length === 1) {
                await pixelGuardService.disableFeature(2);
            }
            mixpanel.postEvent('Pixel guard - delete parameters', { tagId });
            await fetchSettings();
        });
    };

    return (
        <div className={styles.root}>
            <div className={styles.title}>{cm.get('PixelGuardSettings')}</div>
            <div className={styles.content}>
                <div className={`${styles.row}`} onClick={() => setShowAddItem(!showAddItem)}>
                    <IconButton size="small" data-test-selector="add-pixel-button">
                        <AddCircleOutline className={styles.button} fontSize="small" />
                    </IconButton>
                    <div className={styles.button}>{cm.get('AddPixelGuard')}</div>
                </div>
                {showAddItem && <AddPixel cm={cm} selected={selected} setSelected={setSelected} tags={tags} onGenerateHandler={onGenerateHandler} />}
                {loading ? (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                ) : (
                    <List cm={cm} data={data} onDelete={onDelete} />
                )}
            </div>
        </div>
    );
};

export default PixelGuard;
