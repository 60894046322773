import axios from 'axios';
import config from '@config';
import { getSubDomain } from '@utils/common';

class Server {
    base_url = config.baseAPI;
    config = {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CHEQ-LOCATION': getSubDomain(),
            Authorization: `Bearer ${localStorage.getItem('auth0Token')}`,
        },
    };

    updateAuthHeader() {
        this.config.headers.Authorization = `Bearer ${localStorage.getItem('auth0Token')}`;
    }

    async get(url, base_url = this.base_url) {
        this.updateAuthHeader()
        return axios.get(`${base_url}${url}`, this.config).then(res => {
            return res;
        });
    }

    async post(url, body, base_url = this.base_url) {
        this.updateAuthHeader()
        return new Promise((resolve, reject) => {
            axios
                .post(`${base_url}/${url}`, body, this.config)
                .then(res => {
                    resolve(res);
                })
                .catch(e => {
                    reject(e.response?.data || { code: -1 });
                });
        });
    }

    async put(url, body, base_url = this.base_url) {
        this.updateAuthHeader()
        return new Promise((resolve, reject) => {
            axios
                .put(`${base_url}/${url}`, body, this.config)
                .then(res => {
                    resolve(res);
                })
                .catch(e => {
                    reject(e.response?.data || { code: -1 });
                });
        });
    }

    async delete(url, base_url = this.base_url) {
        this.updateAuthHeader()
        return axios.delete(`${base_url}/${url}`, this.config).then(res => {
            return res;
        });
    }
}

export default new Server();
