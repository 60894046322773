import countryList from 'country-list';
import { NETWORK_SETTINGS } from '../../../../../../utils/settings/constants';

export default {
    countriesOptions: Object.entries(countryList.getNameList())
        .map(([label, value]) => ({ value, label }))
        .reduce((arr, current) => [...arr, current], [{ value: 'none', label: 'none' }])
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    reportTypeOptions: [{ label: 'Summary', value: 'summary' }],
    deviceOptions: [
        { value: 'none', label: 'none' },
        { value: 'desktopLinux', label: 'Desktop Linux' },
        { value: 'desktopMac', label: 'Desktop Mac' },
        { value: 'desktopOthers', label: 'Desktop Others' },
        { value: 'desktopWindows', label: 'Desktop Windows' },
        { value: 'mobileAndroid', label: 'Mobile Android' },
        { value: 'mobileBlackberry', label: 'Mobile Blackberry' },
        { value: 'mobileiPhone', label: 'Mobile iPhone' },
        { value: 'mobileOthers', label: 'Mobile Others' },
        { value: 'mobileWindows', label: 'Mobile Windows' },
        { value: 'tabletAndroid', label: 'Tablet Android' },
        { value: 'tabletiPad', label: 'Tablet iPad' },
        { value: 'tabletOthers', label: 'Tablet Others' },
        { value: 'tabletWindows', label: 'Tablet Windows' },
    ],

    browserOptions: [
        { label: 'None', value: 'none' },
        { label: 'Chrome', value: 'chrome' },
        { label: 'Firefox', value: 'firefox' },
        { label: 'IE', value: 'ie' },
        { label: 'Opera', value: 'opera' },
        { label: 'Others', value: 'others' },
        { label: 'Safari', value: 'safari' },
    ],

    breakdownOptions: [
        { label: 'None', value: 'none' },
        { label: 'Hour', value: 'hour' },
        { label: 'Day', value: 'day' },
        { label: 'Week', value: 'week' },
        { label: 'Month', value: 'month' },
        { label: 'Year', value: 'year' },
    ],

    dateRangeOptions: [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Last7Days', value: 'last7Days' },
        { label: 'Last30Days', value: 'last30Days' },
        { label: 'Last3Months', value: 'last3Months' },
        { label: 'Last6Months', value: 'last6Months' },
        { label: 'Custom', value: 'Custom' },
    ],

    getGroupByOptions: (domainFilterOn, affiliateFilterOn) => {
        const groupByOptions = [
            { label: 'None', value: 'none' },
            { label: 'Source', value: 'source' },
            { label: 'Campaign', value: 'campaign' },
            { label: 'Content', value: 'content' },
            { label: 'Medium', value: 'medium' },
            { label: 'Keyword', value: 'keyword' },
            { label: 'Referrer', value: 'referrer' },
            { label: 'URL', value: 'url' },
            { label: 'Country', value: 'country' },
            { label: 'Device', value: 'device' },
            { label: 'Browser', value: 'browser' },
            { label: 'Channel', value: 'channel' },
            { label: 'ThreatType', value: 'threatType' },
            { label: 'platformOrigin', value: 'platformOrigin' },
            { label: 'ThreatGroup', value: 'threatGroup' },
            { label: 'CampaignType', value: 'campaignType' },
        ];

        if (domainFilterOn) {
            groupByOptions.splice(8, 0, { label: 'Domain', value: 'domain' });
        }
        if (affiliateFilterOn && JSON.parse(window.localStorage.getItem(NETWORK_SETTINGS)).isAffiliateFraudEnabled) {
            groupByOptions.push({ label: 'Affiliate', value: 'affiliate' });
        }

        return groupByOptions;
    },

    groupByOptionsAffiliate: [
        { label: 'None', value: 'none' },
        { label: 'Source', value: 'source' },
        { label: 'Campaign', value: 'campaign' },
        { label: 'Content', value: 'content' },
        { label: 'Medium', value: 'medium' },
        { label: 'URL', value: 'url' },
        { label: 'Referrer', value: 'referrer' },
        { label: 'ThreatType', value: 'threatType' },
        { label: 'platformOrigin', value: 'platformOrigin' },
    ],

    groupByOptionsRTI: [
        { label: 'None', value: 'none' },
        { label: 'ThreatType', value: 'threatType' },
        { label: 'ThreatGroup', value: 'threatGroup' },
        { label: 'EventName', value: 'eventName' },
        { label: 'EventType', value: 'eventType' },
        { label: 'URL', value: 'url' },
        { label: 'Country', value: 'country' },
        { label: 'platformOrigin', value: 'platformOrigin' },
    ],

    groupByOptionsSLP: [
        { label: 'None', value: 'none' },
        { label: 'ThreatType', value: 'threatType' },
        { label: 'Domain', value: 'domain' },
        { label: 'Country', value: 'country' },
        { label: 'EmailDomain', value: 'emailDomain' },
    ],
};
