import React, { useEffect, useState } from 'react';
import Brands from './brands';
import { useAuth0 } from '@auth0/auth0-react';
import widgets from '../../widgets';
import { useLocation, useSearchParams } from 'react-router-dom';

const { Progress } = widgets;

export default () => {
    const { isLoading, isAuthenticated, loginWithRedirect, user, logout } = useAuth0();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [isRedierectedFromZendesk, setIsRedierectedFromZendesk] = useState(!!searchParams.get('brand_id') && !!searchParams.get('return_to'));

    const getSearchParam = param => searchParams.get(param);

    const isInvitation = () => {
        const isLoginUrl = location.pathname.split('/')[1] === 'login';
        const organization = getSearchParam('organization');
        const invitation = getSearchParam('invitation');
        return isLoginUrl && !!organization && !!invitation;
    };

    const isLogout = () => location.pathname.split('/')[1] === 'logout';

    const invitation = async () => {
        const organization = getSearchParam('organization');
        const invitation = getSearchParam('invitation');

        if (!!organization && !!invitation) {
            await loginWithRedirect({
                organization,
                invitation,
            });
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (!window.sessionStorage.getItem('zendesk-external-link-redirect')) {
                    if (isRedierectedFromZendesk) {
                        window.sessionStorage.setItem('zendesk-external-link-redirect', searchParams.get('return_to'));
                        setIsRedierectedFromZendesk(false);
                    }
                }
            } catch (error) {
                console.error(`error on Zendesk external link - ${JSON.stringify(error)}`);
            }
        })();
    }, [searchParams, window.localStorage.getItem('zendesk')]);

    const loginToOrg = organization => loginWithRedirect({ organization });
    const getBrands = () => user[`${window.location.href}orgs`] || [];
    const oneOrgForUser = user && !user.org_id && getBrands().length === 1;

    if (isLoading) return <AppLoader />;
    if (isInvitation()) return invitation();
    if (isLogout()) return logout();
    if (isRedierectedFromZendesk) return <></>;
    return !isAuthenticated ? loginWithRedirect() : oneOrgForUser ? loginToOrg(getBrands()[0].id) : <Brands />;
};

const AppLoader = () => {
    return (
        <Progress
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
            }}
        />
    );
};
