import MultiSelectCheckbox from 'components/widgets/multi-select-checkbox';
import { FC, useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import _ from 'lodash';
import Tooltip from 'components/widgets/tooltip';
import { ReactComponent as InfoIcon } from '@components/svgs/icons/info.svg';
import AddApiButton from 'components/widgets/add-api-button';
import { ValueLabel } from 'types/types';
import { saveGoogleUniversalPixel } from '../../../../../services/api';
import { UaTrackingId } from 'types/types';

interface IProps {
    cm: any;
    channelOptions: ValueLabel[];
    uaTrackingIds: UaTrackingId[];
    setUaTrackingIds: (uaTrackingIds: UaTrackingId[]) => void;
}

const AddGoogleUniversalIntegration: FC<IProps> = ({ cm, channelOptions, uaTrackingIds, setUaTrackingIds }) => {
    const [trackingIdError, setTrackingIdError] = useState('');
    const [dimensionIndexError, setDimensionIndexIdError] = useState('');
    const [dimensionIndex, setDimensionIndex] = useState('');
    const [tagId, setTagId] = useState<ValueLabel>();
    const [trackingId, setTrackingId] = useState('');
    const [disableButton, setDisableButton] = useState(true);

    const isTrackingIdValid = () => {
        const isPixelIdExist = uaTrackingIds.some((trackId: UaTrackingId) => trackId.pixelId === trackingId && trackId.tagId === tagId?.value);
        if (trackingId.startsWith('UA-') && !isPixelIdExist) {
            trackingIdError.length && setTrackingIdError('');
            return true;
        } else if (isPixelIdExist) {
            setTrackingIdError(cm.get('GAInvalidExistsInput'));
            return false;
        } else {
            setTrackingIdError(cm.get('InvalidTrackingIdErrorMessage'));
            return false;
        }
    };

    const isDimensionIndexValid = () => {
        if (Number(dimensionIndex) >= 1 && Number(dimensionIndex) <= 200) {
            dimensionIndexError.length && setDimensionIndexIdError('');
            return true;
        } else {
            setDimensionIndexIdError(cm.get('InvalidDimensionIndexErrorMessage'));
            return false;
        }
    };

    const handleSubmit = () => {
        const trackingIdValidationPass = isTrackingIdValid();
        const dimensionIndexValidationPass = isDimensionIndexValid();
        if (tagId?.value && trackingIdValidationPass && dimensionIndexValidationPass) {
            const payload = { tagId: tagId.value, pixelId: trackingId, dimensionIndex: `dimension${dimensionIndex}` };
            saveGoogleUniversalPixel(payload).then(() => {
                payload.dimensionIndex = payload.dimensionIndex.replace('dimension', '');
                const newUaTrackingIds: UaTrackingId[] = [...uaTrackingIds, { ...payload, tagLabel: tagId.label }];
                setUaTrackingIds(newUaTrackingIds);
            });
        }
    };

    useEffect(() => {
        if (tagId?.value && trackingId.length && dimensionIndex.length) {
            setDisableButton(false);
        } else if ((!dimensionIndex.length || tagId || !trackingId.length) && !disableButton) {
            setDisableButton(true);
        }
    }, [dimensionIndex, tagId, trackingId]);

    const selectStyle = () => ({
        container: {
            border: 'none',
            width: '20rem',
            borderRadius: '4px'
        },
        control: {
            borderRadius: '4px'
        }
    });

    return (
        <div className={styles.row}>
            <div className={styles.dFlexCol}>
                <span className={styles.label}>{cm.get('Channel')}</span>
                <MultiSelectCheckbox
                    onChange={setTagId}
                    styles={selectStyle()}
                    options={channelOptions}
                    value={tagId}
                    isOpen={undefined}
                    asyncOptions={undefined}
                    isMulti={false}
                    isClearable={false}
                    isLoading={false}
                />
            </div>
            <div className={`${styles.dFlexCol} ${styles.smallInput}`}>
                <div className={`${styles.dFlexRow} ${styles.measurement}`}>
                    <span className={styles.label}>{cm.get('TrackingId')}</span>
                    <Tooltip content={cm.get('GoogleUniversalAnalyticsTrackingIdToolTip')} title={undefined} action={undefined} arrow={undefined}>
                        <InfoIcon />
                    </Tooltip>
                </div>
                <input className={styles.textContainer} name="trackingId" value={trackingId} onChange={(event => setTrackingId(event.target.value))} />
                {trackingIdError && <span className={styles.error}>{trackingIdError}</span>}
            </div>
            <div className={`${styles.dFlexCol} ${styles.smallInput}`}>
                <div className={`${styles.dFlexRow} ${styles.measurement}`}>
                    <span className={styles.label}>{cm.get('DimensionIndex')}</span>
                    <Tooltip content={cm.get('GoogleUniversalAnalyticsDimensionIndexToolTip')} title={undefined} action={undefined} arrow={undefined}>
                        <InfoIcon />
                    </Tooltip>
                </div>
                <input className={styles.textContainer} name="dimensionIndex" value={dimensionIndex} onChange={(event => setDimensionIndex(event.target.value))} />
                {dimensionIndexError && <span className={styles.error}>{dimensionIndexError}</span>}
            </div>
            <AddApiButton onClickHandler={handleSubmit} text={cm.get('Add')} className={`${disableButton ? styles.disabled : ''} ${styles.gaAddButton}`} />
        </div>);
};

export default AddGoogleUniversalIntegration;
