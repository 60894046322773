import { FC, useEffect, useState } from 'react';
/* @ts-ignore */
import { useContentManager } from '@hooks';
import styles from './styles.module.scss';
import { HubSpotConnector } from './connector';
import CompletedIcon from '../../exportables/assets/check-circle.svg';
import { GetHubSpotForms, HubSpotFieldsStatus, HubSpotFieldsValues, HubSpotForm, HubSpotUpdatePayload } from '../../../../types/hubspot';
import Progress from '../../../widgets/progress';
import { toast } from 'react-toastify';
import {
    connectHubSpotOAuth,
    disconnectHubSpotOAuth,
    getHubSpotFieldsStatus,
    getHubSpotForms,
    getHubSpotOAuthStatus,
    updateHubSpotFields,
    updateHubSpotForms,
} from '../../../../services/api/hubspot.service';
import { getParsedHubSpotFieldsToSelect } from './utils';
import { CircularProgress, Divider } from '@material-ui/core';
import { HubSpotForms } from './forms';
import { useGenerateAuthorizationUrl } from '../../../../hooks/use-generate-hubspot-authorization-url';

const customSuccessToastStyle: React.CSSProperties = {
    background: '#F6FFED',
    color: '#237804',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '22px',
};

const customErrorToastStyle: React.CSSProperties = {
    background: '#FFF1F0',
    color: '#CF1322',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '22px',
};

export const HubSpot: FC = () => {
    const cm = useContentManager();
    const [isConnected, setIsconnected] = useState<boolean>(false);
    const [selectedHubSpotFields, setSelectedHubSpotFields] = useState<HubSpotFieldsValues[]>([]);
    const [isRequestEnd, setIsRequestEnd] = useState<boolean>(true);
    const [initHubSpotForms, setInitHubSpotForms] = useState<HubSpotForm[]>([]);
    const [updatedForms, setUpdatedForms] = useState<HubSpotForm[]>([]);
    const [hubSpotForms, setHubSpotForms] = useState<HubSpotForm[]>([]);
    const [isFetchFormsError, setIsFetchFormsError] = useState<boolean>(false);
    const [initProtectAllNewForms, setInitProtectAllNewForms] = useState<boolean>(false);
    const [isProtectAllNewForms, setIsProtectAllNewForms] = useState<boolean>(false);
    const [isUpdatedFormsLoading, setIsUpdatedFormsLoading] = useState<boolean>(false);
    const [isDisconnectLoading, setIsDisconnectLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const { data: authorizationUrl } = useGenerateAuthorizationUrl();

    const fetchForms = async () => {
        try {
            const getForms: GetHubSpotForms = await getHubSpotForms();
            setHubSpotForms(getForms.forms);
            setInitHubSpotForms(getForms.forms);
            setIsProtectAllNewForms(getForms.isProtectAllNewForms);
            setInitProtectAllNewForms(getForms.isProtectAllNewForms);
        } catch (error) {
            setIsFetchFormsError(true);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const code: any = urlParams.get('code');
                const isConnected: boolean = !!(await getHubSpotOAuthStatus()).integrated;
                const hubSpotFields: HubSpotFieldsStatus = await getHubSpotFieldsStatus();
                isConnected && (await fetchForms());
                if (code && !isConnected) {
                    await connectToHubSpotWithCode(code, hubSpotFields);
                } else if (!isConnected && (hubSpotFields?.isEmail || hubSpotFields?.isPhone)) {
                    await updateHubSpotFields({ isEmail: false, isPhone: false });
                    setSelectedHubSpotFields([]);
                } else {
                    setIsconnected(isConnected);
                    setSelectedHubSpotFields(getParsedHubSpotFieldsToSelect(hubSpotFields));
                }
                setLoading(false);
            } catch (error) {
                setIsError(true);
            }
        };

        fetchData().then();
    }, []);

    const handleHubSpotForms = (forms: HubSpotForm[]) => {
        setHubSpotForms(forms);
    };

    const saveFormsChanges = async () => {
        try {
            if (isRequestEnd && (updatedForms.length || initProtectAllNewForms !== isProtectAllNewForms)) {
                setIsRequestEnd(false);
                setIsUpdatedFormsLoading(true);
                await updateHubSpotForms({ forms: updatedForms, isProtectAllNewForms });
                setInitHubSpotForms(hubSpotForms);
                setInitProtectAllNewForms(isProtectAllNewForms);
                setUpdatedForms([]);
                setIsUpdatedFormsLoading(false);
                setIsRequestEnd(true);
                toast.success(cm.get('HubSpotSuccessUpdateFormsMessage'), {
                    style: customSuccessToastStyle,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                });
            }
        } catch (error) {
            setIsUpdatedFormsLoading(false);
            setIsRequestEnd(true);
            toast.error(cm.get('HubSpotFailedUpdateFormsMessage'), {
                style: customErrorToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
        }
    };

    const connectToHubSpotWithCode = async (code: string, hubSpotCurrentFields: HubSpotFieldsStatus) => {
        try {
            await connectHubSpotOAuth(code);
            await fetchForms();
            setIsconnected(true);
            toast.success(cm.get('HubSpotSuccessConnectMessage'), {
                style: customSuccessToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
            setSelectedHubSpotFields(getParsedHubSpotFieldsToSelect(hubSpotCurrentFields));
        } catch (error) {
            await updateHubSpotFields({ isEmail: false, isPhone: false });
            setSelectedHubSpotFields([]);
            toast.error(cm.get('HubSpotFailedConnectMessage'), {
                style: customErrorToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
        }
    };

    const handleConnect = async (selectedHubSpotFields: HubSpotFieldsValues[]) => {
        try {
            if (isRequestEnd) {
                setLoading(true);
                const payload: HubSpotUpdatePayload = {
                    isEmail: selectedHubSpotFields.some(field => field.value === 'email'),
                    isPhone: selectedHubSpotFields.some(field => field.value === 'phone'),
                };
                await updateHubSpotFields(payload);
                if (authorizationUrl instanceof Error || authorizationUrl == undefined) {
                    throw authorizationUrl;
                }
                window.location.href = authorizationUrl;
            }
        } catch (error) {
            toast.error(cm.get('HubSpotFailedConnectMessage'), {
                style: customErrorToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
            setIsRequestEnd(true);
        }
    };

    const handleDisconnect = async () => {
        try {
            if (isRequestEnd) {
                setIsDisconnectLoading(true);
                setIsRequestEnd(false);
                await disconnectHubSpotOAuth();
                setSelectedHubSpotFields([]);
                setIsconnected(false);
                setIsRequestEnd(true);
                setIsDisconnectLoading(false);
                toast.success(cm.get('HubSpotSuccessDisconnectMessage'), {
                    style: customSuccessToastStyle,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                });
            }
        } catch (error) {
            toast.error(cm.get('HubSpotFailedDisconnectMessage'), {
                style: customErrorToastStyle,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
            setIsDisconnectLoading(false);
            setIsRequestEnd(true);
        }
    };

    const handleIsProtectAllNewForms = () => setIsProtectAllNewForms(!isProtectAllNewForms);

    const handleEdit = async (selectedHubSpotFields: HubSpotFieldsValues[]) => {
        const isEmail = selectedHubSpotFields.some(field => field.value === 'email');
        const isPhone = selectedHubSpotFields.some(field => field.value === 'phone');
        await updateHubSpotFields({ isEmail, isPhone });
        setSelectedHubSpotFields(selectedHubSpotFields);
    };

    return (
        <div className={styles.container}>
            {isError ? (
                <span className={styles.errorContainer}>{cm.get('RuleVisitsLoadingErrorMessage')}</span>
            ) : loading ? (
                <div className={styles.mainLoading}>
                    <Progress size={65} thickness={2} />
                </div>
            ) : (
                <>
                    <div className={styles.titleContainer}>
                        <div className={styles.title}>
                            {cm.get('HubSpotConnector')}
                            {isConnected ? (
                                <div className={styles.connect}>
                                    <img className={styles.icon} src={CompletedIcon} alt="status-icon" width="12" height="12" /> {cm.get('Connected')}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className={styles.innerContainer}>
                        <HubSpotConnector
                            isDisconnectLoading={isDisconnectLoading}
                            isConnected={isConnected}
                            handleConnect={handleConnect}
                            handleDisconnect={handleDisconnect}
                            handleEdit={handleEdit}
                            selectedHubSpotFields={selectedHubSpotFields}
                        />
                        {isConnected ? (
                            <>
                                <div className={styles.divider}>
                                    <Divider />
                                </div>
                                <div className={styles.formsContainer}>
                                    <HubSpotForms
                                        isFetchFormsError={isFetchFormsError}
                                        hubSpotForms={hubSpotForms}
                                        handleHubSpotForms={handleHubSpotForms}
                                        isProtectAllNewFormsStatus={isProtectAllNewForms}
                                        handleIsProtectAllNewForms={handleIsProtectAllNewForms}
                                        updatedForms={updatedForms}
                                        setUpdatedForms={setUpdatedForms}
                                        initHubSpotForms={initHubSpotForms}
                                    />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    {!isFetchFormsError && isConnected ? (
                        <div className={styles.saveFormsContainer}>
                            {isUpdatedFormsLoading && <span className={styles.saveFormsLoadingText}>{cm.get('SaveUpdatedHubSpotFormsLoadingText')}</span>}
                            <div
                                className={`
                        ${styles.saveFormsButton}
                        ${!updatedForms.length && initProtectAllNewForms === isProtectAllNewForms && !isUpdatedFormsLoading && styles.disabledSaveFormsButton}
                        ${isUpdatedFormsLoading && styles.loaderSaveFormsButton}
                        `}
                                onClick={saveFormsChanges}
                            >
                                {isUpdatedFormsLoading ? (
                                    <div style={{ color: '#788397', display: 'flex' }}>
                                        <CircularProgress size={14} color="inherit" />
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {cm.get('Save')}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
};
