import Containers from '../components/containers';
import { Route, Navigate } from 'react-router-dom';
import PaidMarketingDashboard from '../modules/paid-marketing/dashboard';
import RTIDashboard from '../modules/on-conversion/dashboard';
import LandingPage from '../multi-product/LandingPage';
import DADashboard from '../modules/data-analytics/dashboard';
import Brands from '../components/containers/authentication/brands';
import Paradome2Redirect from './Paradome2Redirect';
import Logout from '../view-models/models/logout';
import { constants as splitConstants } from '@utils/split.io';
import Outbrain from '../components/layouts/outbrain';
import { LEAD_PROTECTION, CHEQ_ACQUISITION_ROUTE, CHEQ_ANALYTICS_ROUTE, MIS_ROUTE, CHEQ_SLP_ROUTE, CAS_ROUTE, CHEQ_DEFEND, OSS_ROUTE } from '../utils/common';
const { Analytics, Management, Exportables } = Containers;

export const routes = [
    {
        title: 'Home',
        path: '/home',
        isDefault: true,
        route: () => <Route path="home" element={<LandingPage />} />,
        models: ['dashboard'],
    },
    {
        title: 'Logout',
        path: '/logout',
        isDefault: true,
        route: () => <Route path="logout" element={<Logout />} />,
        models: ['dashboard'],
    },
    {
        path: CHEQ_ACQUISITION_ROUTE,
        route: isOutbrainNetwork =>
            [`/${CAS_ROUTE}`, `/${CHEQ_ACQUISITION_ROUTE}`].map(path => (
                <Route key={CHEQ_ACQUISITION_ROUTE} path={path}>
                    <Route
                        path="dashboard"
                        element={
                            <Paradome2Redirect path={`/${CHEQ_ACQUISITION_ROUTE}/dashboard-v2`} featureflagName={splitConstants.SPLIT_PARADOME_NEW_PD_DASHBOARD}>
                                {!isOutbrainNetwork ? <PaidMarketingDashboard /> : <Outbrain />}
                            </Paradome2Redirect>
                        }
                    />
                    <Route path="reports" element={<Analytics />} />
                    <Route path="data-exports" element={<Exportables />} />
                    <Route
                        path="policy-management"
                        element={
                            <Paradome2Redirect path={`/${CHEQ_ACQUISITION_ROUTE}/policy-management`} featureflagName={splitConstants.SPLIT_PARADOME_POLICY_MANAGEMENT_FEATURE} />
                        }
                    />
                    <Route
                        path="settings"
                        element={
                            <Paradome2Redirect path={`/${CHEQ_ACQUISITION_ROUTE}/settings-v2`} featureflagName={splitConstants.PARADOME_CAS_SETTINGS_V2}>
                                {<Management />}
                            </Paradome2Redirect>
                        }
                    />
                    <Route index path="*" element={<Navigate to="/home" />} />
                </Route>
            )),
        isDefault: false,
        models: ['dashboard'],
    },
    {
        path: `/${CHEQ_DEFEND}`,
        route: () =>
            [`/${OSS_ROUTE}`, `/${CHEQ_DEFEND}`].map(path => (
                <Route key={path} path={path}>
                    <Route
                        path="dashboard"
                        element={
                            <Paradome2Redirect path={`/${CHEQ_DEFEND}/dashboard-v2`} featureflagName={splitConstants.SPLIT_PARADOME_NEW_RTI_DASHBOARD}>
                                <RTIDashboard />
                            </Paradome2Redirect>
                        }
                    />
                    <Route path="reports" element={<Analytics />} />
                    <Route path="data-exports" element={<Exportables />} />
                    <Route
                        path="policy-management"
                        element={<Paradome2Redirect path={`/${CHEQ_DEFEND}/policy-management`} featureflagName={splitConstants.SPLIT_PARADOME_POLICY_MANAGEMENT_FEATURE} />}
                    />
                    <Route
                        path="settings"
                        element={
                            <Paradome2Redirect path={`/${CHEQ_DEFEND}/settings-v2`} featureflagName={splitConstants.PARADOME_SLP_SETTINGS_V2}>
                                {<Management />}
                            </Paradome2Redirect>
                        }
                    />
                    <Route index path="*" element={<Navigate to="/home" />} />
                </Route>
            )),
        isDefault: false,
        models: ['dashboard'],
    },
    {
        path: `/${MIS_ROUTE}`,
        route: () =>
            [`/${MIS_ROUTE}`, `/${CHEQ_ANALYTICS_ROUTE}`].map(path => (
                <Route key={MIS_ROUTE} path={path}>
                    <Route path="dashboard" element={<DADashboard />} />
                    <Route path="reports" element={<Analytics />} />
                    <Route path="data-exports" element={<Exportables />} />
                    <Route path="settings" element={<Management />} />
                    <Route index path="*" element={<Navigate to="/home" />} />
                </Route>
            )),
        isDefault: false,
        models: ['dashboard'],
    },
    {
        path: '/privacy-compliance-enforcement',
        route: () => (
            <Route path="privacy-compliance-enforcement">
                <Route
                    path="dashboard"
                    element={<Paradome2Redirect path="/ensighten/login?cheq=true" featureflagName={splitConstants.SPLIT_PARADOME_POLICY_MANAGEMENT_FEATURE} />}
                />
            </Route>
        ),
        isDefault: false,
        models: ['dashboard'],
    },
    {
        path: '/brands',
        route: () => <Route path="/brands" element={<Brands />} />,
        isDefault: false,
        models: ['dashboard'],
    },
    {
        path: `/${LEAD_PROTECTION}`,
        route: () =>
            [`/${LEAD_PROTECTION}`, `/${CHEQ_SLP_ROUTE}`].map(path => (
                <Route key={LEAD_PROTECTION} path={path}>
                    <Route
                        path="dashboard"
                        element={<Paradome2Redirect path={`/${CHEQ_SLP_ROUTE}/dashboard`} featureflagName={splitConstants.PARADOME_NEW_SLP_DASHBOARD}></Paradome2Redirect>}
                    />
                    <Route path="reports" element={<Analytics />} />
                    <Route path="data-exports" element={<Exportables />} />
                    <Route
                        path="policy-management"
                        element={<Paradome2Redirect path={`/${CHEQ_SLP_ROUTE}/policy-management`} featureflagName={splitConstants.PARADOME_NEW_SLP_DASHBOARD} />}
                    />
                    <Route
                        path="settings"
                        element={
                            <Paradome2Redirect path={`/${CHEQ_SLP_ROUTE}/settings-v2`} featureflagName={splitConstants.PARADOME_SLP_SETTINGS_V2}>
                                {<Management />}
                            </Paradome2Redirect>
                        }
                    />
                    <Route index path="*" element={<Navigate to="/home" />} />
                </Route>
            )),
        isDefault: false,
        models: ['dashboard'],
    },
];
