import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDashboardSettings } from '../redux/thunks/dashboard';
import mixpanel from '@services/mixpanel';
import { useLicenses } from '@hooks';
const StartupContext = createContext(null);

const StartupProvider = ({ children, isAuthenticated }) => {
    if (isAuthenticated) {
        const dispatch = useDispatch();
        const [isFetchedOnSiteFeatures, setIsFetchedOnSiteFeatures] = useState(false);
        const [isFetchedPaidMarketingFeatures, setIsFetchedPaidMarketingFeatures] = useState(false);
        const [isFetchedDataAndAnalyticsFeatures, setIsFetchedDataAndAnalyticsFeatures] = useState(false);

        const licenses = useLicenses();

        useEffect(() => {
            // mixpanel.init();
            mixpanel.updateProducts();
        }, []);

        useEffect(() => {
            if (licenses.hasOnSiteConversion && !isFetchedOnSiteFeatures) {
                setIsFetchedOnSiteFeatures(true);
            }

            if (licenses.hasPaidMarketing && !isFetchedPaidMarketingFeatures) {
                dispatch(getDashboardSettings());
                setIsFetchedPaidMarketingFeatures(true);
            }

            if (licenses.hasDataAndAnalytics && !isFetchedDataAndAnalyticsFeatures) {
                dispatch(getDashboardSettings());
                setIsFetchedDataAndAnalyticsFeatures(true);
            }
        }, [licenses]);

        return <StartupContext.Provider value={{}}>{children} </StartupContext.Provider>;
    } else {
        return <div>No Authenticated</div>;
    }
};

export default StartupProvider;
