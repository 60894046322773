import { profile as types } from '.';

export const setProfileData = data => {
    return {
        type: types.SET_PROFILE_DATA,
        payload: data,
    };
};

export const setDefaultProduct = data => {
    return {
        type: types.SET_DEFAULT_PRODUCT,
        payload: data,
    };
};


export const setMeta = data => {
    return {
        type: types.SET_META,
        payload: data,
    };
};

export const setContent = data => {
    return {
        type: types.SET_CONTENT,
        payload: data,
    };
};
export const setPermissions = data => {
    return {
        type: types.SET_PERMISSIONS,
        payload: data,
    };
};

export const contentIsLoading = data => {
    return {
        type: types.CONTENT_LOADING,
        payload: data,
    };
};
export const setUserProfile = data => {
    return {
        type: types.UPDATE_USER_PROFILE,
        payload: data,
    };
};
export const setFirstLogin = data => {
    return {
        type: types.SET_FIRST_LOGIN,
        payload: data,
    };
};

export const setName = data => {
    return {
        type: types.SET_NAME,
        payload: data,
    };
};
export const changePasswordLoading = data => {
    return {
        type: types.CHANGE_PASSWORD_IS_LOADING,
        payload: data,
    };
};

export const setUsername = data => {
    return {
        type: types.SET_USERNAME,
        payload: data,
    };
};

export const setLanguage = data => {
    return {
        type: types.SET_LANGUAGE,
        payload: data,
    };
};

export const setTimezone = data => {
    return {
        type: types.SET_TIMEZONE,
        payload: data,
    };
};

export const setUerProfileLoading = data => {
    return{
        type: types.SET_USER_PROFILE_IS_LOADING,
        payload: data
    }
}

