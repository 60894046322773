import React, { useEffect } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoData from '../no-data/index';
import Progress from '../progress/index';
import { useTheme } from '@material-ui/styles';

// (37deg, #9250d5 0%, #fe0072);
const gradiant = {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [
        [0, '#9250d5'],
        [1, '#fe0072'],
    ],
};

const bySeries = [
    { color: '#5545a0', boarder: false },
    { color: gradiant, boarder: false },
];

const options = {
    credits: false,
    chart: {
        backgroundColor: '#383851',
        type: 'column',
        events: {
            render: function () {
                const pushValidColumnBottomBy = pushBy => {
                    var indexesThatShouldBePushed = [15, 18, 20, 22, 25, 28, 30, 32];
                    var validPaths = this.renderer.box.querySelectorAll('.highcharts-series.highcharts-series-0.highcharts-column-series.highcharts-tracker > path');
                    var invalidPaths = this.renderer.box.querySelectorAll('.highcharts-series.highcharts-series-1.highcharts-column-series.highcharts-tracker > path');
                    validPaths.forEach((path, pIdx) => {
                        var dArray = path.getAttribute('d').split(/\s/);

                        if (invalidPaths[pIdx].getBoundingClientRect().height === 0) {
                            //if invalid path is empty, we do not extend the valid column bottom
                            return;
                        }

                        const modifiedDAttribute = dArray
                            .map((dValue, idx, refDArray) => {
                                if (indexesThatShouldBePushed.includes(idx)) {
                                    return /*dValue;*/ parseFloat(dValue) + pushBy;
                                } else {
                                    return dValue;
                                }
                            })
                            .join(' ');
                        path.setAttribute('d', modifiedDAttribute);
                    });
                };

                pushValidColumnBottomBy(8);
            },
        },
    },
    title: { text: '' },
    legend: {
        verticalAlign: 'top',
        itemStyle: {
            color: '#f1f2f6',
        },
    },
    series: null,
    plotOptions: {
        series: {
            events: {
                legendItemClick: () => false,
            },
            borderWidth: 0,
            animation: false,
        },
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false,
            },
        },
    },
    xAxis: {
        lineWidth: 0,
        tickWidth: 0,
        min: 0,
        gridLineWidth: 0,
        stackLabels: {
            enabled: true,
        },

        labels: {
            style: {
                color: '#f1f2f6',
            },
        },
        categories: null /*.slice(0,5)*/,
    },
    yAxis: {
        top: 50,
        title: '',
        gridLineWidth: 0,
        labels: {
            style: {
                color: '#f1f2f6',
            },
        },
    },
};

export default class ChartBars extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    static defaultProps = {
        data: [],
    };

    componentDidMount() {
        // var chart = this.refs.chartRef.chart;
        // var rects = chart.renderer.box.querySelectorAll('rect.highcharts-point');
        // rects.forEach(rect=>{
        //     rect.setAttribute('ry',6.5);
        //     rect.setAttribute('rx',6.5);
        //     const yValue = parseInt(rect.getAttribute('y'))-15;
        // 	yValues.push(yValue)
        //     rect.setAttribute('y',yValue);
        // })
    }

    componentDidUpdate() {
        // var chart = this.refs.chartRef.chart;
        // var rects = chart.renderer.box.querySelectorAll('rect.highcharts-point');
        // rects.forEach((rect,idx)=>{
        //    rect.setAttribute('y',yValues[idx]);
        // })
    }

    render() {
        const { data, categories, isloading, formatter = x => x } = this.props;
        const series = data.map((item, idx) => ({ ...item, ...bySeries[idx] })); /*.map(s=>({...s, data :  s.data.slice(0,5)}));*/

        options.series = series.map((s, idx) => {
            if (!idx) {
                Object.assign(s, {
                    borderRadiusTopLeft: '50%',
                    borderRadiusTopRight: '50%',
                    borderRadiusBottomLeft: '50%',
                    borderRadiusBottomRight: '50%',
                    pointWidth: 12,
                });
            } else {
                Object.assign(s, {
                    borderRadiusTopLeft: '50%',
                    borderRadiusTopRight: '50%',
                    borderRadiusBottomLeft: '50%',
                    borderRadiusBottomRight: '50%',
                    pointWidth: 12,
                });
            }
            return s;
        });
        options.xAxis.categories = categories;

        options.tooltip = {};
        options.tooltip.formatter = formatter;

        const hasData = categories.length && series.length && series[0].data.length;
        return (
            <div
                style={{
                    verticalAlign: 'top',
                    height: 400,
                    background: '#383851',
                    textAlign: 'center',
                }}
            >
                {isloading ? (
                    <Progress style={{ opacity: 0.5, marginTop: 160 }} />
                ) : (
                    <>
                        {hasData /*should do this in wrapper*/ ? (
                            <HighchartsReact ref={'chartRef'} highcharts={Highcharts} options={options} allowChartUpdate={true} immutable={false} />
                        ) : (
                            <NoData lineHeight={400} />
                        )}
                    </>
                )}
            </div>
        );
    }
}

// export default ({
//     data = [],
//     categories,
//     ...props }) => {
//
//     useEffect(()=>{
//
//     },[])
//
//     const series = data.map((item, idx) => ({ ...item, ...bySeries[idx] }));
//
//
//
//
// 	const options = {
//         credits: false,
//         chart: {
//             backgroundColor: '#383851',
//             type: 'column',
//
//         },
//         title: { text: '' },
//         legend: {
//             verticalAlign: 'top',
//             itemStyle: {
//                 color: '#f1f2f6'
//             }
//         },
//         series: series.map((s, idx) => {
//             if (!idx) Object.assign(s, {
//                 borderRadiusTopLeft: '40%',
//                 borderRadiusTopRight: '40%',
//             });
//             return s;
//         }),
//         plotOptions: {
//             series: {
//                 borderWidth: 0,
//                 animation : false,
//             },
//             column: {
//                 stacking: 'normal',
//                 dataLabels: {
//                     enabled: false
//                 }
//             }
//         },
//         xAxis: {
//             lineWidth :0,
// 	        tickWidth: 0,
//             min: 0,
//             gridLineWidth: 0,
//             stackLabels: {
//                 enabled: true,
//             },
//             labels: {
//                 style: {
//                     color: '#f1f2f6'
//                     //  color: 'red'
//                 }
//             },
//             categories
//         },
//         yAxis: {
//             title: '',
//             gridLineWidth: 0,
//             labels: {
//                 style: {
//                     color: '#f1f2f6'
//                 }
//             }
//         }
//     }
//
//     return (
//         <div {...props}>
//             <HighchartsReact
//                 highcharts={Highcharts}
//                 options={options}
//                 allowChartUpdate={true}
//                 immutable={false}
//             />
//         </div>
//     )
// }

function fakeSeries(n) {
    return Array(n)
        .fill()
        .map(() => parseInt(Math.random() * 100));
}
