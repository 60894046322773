import { ModelRuleSet } from '@combotag/public-common/dist/libs/AppValidator';

export default new ModelRuleSet()
    .add('dateRange', r => r)
    .add('breakdownBy', r => r)
    .add('groupBy', r => r)
    .add('country', r => r)
    .add('device', r => r)
    .add('browser', r => r)
    .add('source', r => r)
    .add('campaign', r => r)
    .add('includePPCCrawlers', r => r)
    .add('platformOrigin', r => r)
    .add('reportType', r => r.required())
    .add('includePolicy', r => r)
    .add('policyManagement', r => r)
    .add('policyToggleA', r => r);
